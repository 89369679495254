/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")

  .controller(
    "DashboardCtrl",
    function (
      $scope,
      $element,
      $filter,
      ApiSrv,
      ChartOptionSrv,
      Auth,
      Locale,
      UtilSrv,
      HeaderSrv,
      me
    ) {
      $scope.compare = {
        day: {},
        sevenDay: {},
      }

      $scope.chartOption = ChartOptionSrv.lineChartOption
      $scope.chartOption.yaxis.min = 0
      $scope.chartOption.legend = {
        container: angular.element("#legendContainer", $element),
        noColumns: 0,
      }

      const theDay = moment().subtract(1, "days").startOf("day")
      $scope.account = me
      me.canAccessAllStores().then((bool) => ($scope.canAccessAllStores = bool))

      return HeaderSrv.fetchCurrentCompany()
        .then((company) =>
          ApiSrv.getCompanyCountData({
            companyId: company._id,
            from: moment(theDay).subtract(3, "weeks").format("YYYYMMDD0000"),
            to: moment(theDay).add(1, "days").startOf("day").format("YYYYMMDD0000"),
            sampling: "1d",
            entrance: true,
          })
        )
        .then(function (response) {
          let left, left1, left2
          const resData = _.map(response.data, function (raw) {
            raw["localeDate"] = moment(moment.utc(raw.statisticFor).format("YYYY-MM-DD"))
            return raw
          })

          const _getWeekBeforeCount = function (countDatas, refDay, amount) {
            const dateCountData = _.find(countDatas, (raw) =>
              raw.localeDate.startOf("day").isSame(moment(refDay).subtract(amount, "weeks"))
            )
            return dateCountData != null ? dateCountData.count : undefined
          }

          $scope.yesterdayCount =
            (left = _getWeekBeforeCount(resData, theDay, 0)) != null ? left : 0
          $scope.aWeekBeforeCount =
            (left1 = _getWeekBeforeCount(resData, theDay, 1)) != null ? left1 : 0
          $scope.twoWeeksBeforeCount =
            (left2 = _getWeekBeforeCount(resData, theDay, 2)) != null ? left2 : 0

          const _getDefaultData = function (start, length) {
            let idx = 0
            length++

            return {
              label: "",
              lines: {
                fill: false,
              },
              data: (() => {
                const result = []
                while ((length -= 1)) {
                  result.push([moment(start).add(idx++, "days"), 0])
                }
                return result
              })(),
            }
          }

          $scope.weeklyChartData = [
            _.merge(_getDefaultData(moment(theDay).subtract(1, "weeks").add(1, "days"), 7), {
              label: Locale.string("This day"),
              lines: {
                fill: true,
              },
            }),

            _.merge(_getDefaultData(moment(theDay).subtract(1, "weeks").add(1, "days"), 7), {
              label: Locale.string("1 week before"),
            }),

            _.merge(_getDefaultData(moment(theDay).subtract(1, "weeks").add(1, "days"), 7), {
              label: Locale.string("2 weeks before"),
            }),
          ]

          $scope.sevenDaysCount = 0
          $scope.aSevenDayBeforeCount = 0
          $scope.twoSevenDaysBeforeCount = 0

          const aWeekAgoDate = moment(theDay).subtract(1, "weeks")
          const twoWeekAgoDate = moment(theDay).subtract(2, "weeks")
          const threeWeekAgoDate = moment(theDay).subtract(3, "weeks")
          _.forEach(resData, function (raw) {
            let idx
            if (raw.localeDate.isAfter(aWeekAgoDate)) {
              $scope.sevenDaysCount += raw.count
              idx = raw.localeDate.diff(moment(aWeekAgoDate).add(1, "days"), "day")
              return ($scope.weeklyChartData[0].data[idx][1] = raw.count)
            } else if (raw.localeDate.isAfter(twoWeekAgoDate)) {
              $scope.aSevenDayBeforeCount += raw.count
              idx = raw.localeDate.diff(moment(twoWeekAgoDate).add(1, "days"), "day")
              return ($scope.weeklyChartData[1].data[idx][1] = raw.count)
            } else if (raw.localeDate.isAfter(threeWeekAgoDate)) {
              $scope.twoSevenDaysBeforeCount += raw.count
              idx = raw.localeDate.diff(moment(threeWeekAgoDate).add(1, "days"), "day")
              return ($scope.weeklyChartData[2].data[idx][1] = raw.count)
            }
          })

          return ($scope.compare = {
            day: {
              aWeekBefore: UtilSrv.getComparisonResult(
                $scope.aWeekBeforeCount,
                $scope.yesterdayCount
              ),
              twoWeeksBefore: UtilSrv.getComparisonResult(
                $scope.twoWeeksBeforeCount,
                $scope.yesterdayCount
              ),
            },
            sevenDay: {
              aSevenDayBefore: UtilSrv.getComparisonResult(
                $scope.aSevenDayBeforeCount,
                $scope.sevenDaysCount
              ),
              twoSevenDaysBefore: UtilSrv.getComparisonResult(
                $scope.twoSevenDaysBeforeCount,
                $scope.sevenDaysCount
              ),
            },
          })
        })

      /*
  ApiSrv
  .getCompanyCompareYesterday(options)
  .then (response) ->
    vm.compare.yesterday = response

  ApiSrv
  .getCompanyCompareLastWeekly(options)
  .then (response) ->
    vm.compare.lastweek = response

  ApiSrv
  .getCompanyWeeklyCount()
  .then (response) ->
    console.info response
    *weekly.count = response
  */
    }
  )

//  ApiSrv
//  .getCompanyExpectations()
//  .then (response) ->
//    $scope.expectation = response
