/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

import Promise from "bluebird"

angular
  .module("uCountitUiApp")
  .controller(
    "ProfileCtrl",
    function ($rootScope, $scope, $filter, Auth, ApiSrv, AccountSrv, Locale, me, Account) {
      $scope.itemName = Locale.string("Account")
      $scope.inputDlgTitle = "Update"
      let original = null

      $scope.me = me
      $scope.storeList = []
      $scope.cameraList = []

      $scope.form = {
        provider: "local",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        favorite: {
          cameras: [],
          stores: [],
        },
        lastLoginedAt: null,
        language: "",
        locale: {
          region: "",
          datetime: "",
          firstDayOfWeek: "",
          nameSequence: "",
          number: "",
        },
      }

      $scope.favoriteStores = []
      $scope.favoriteCameras = []
      $scope.languageList = Locale.getLangList()
      $scope.localeList = Locale.getLocaleList()
      $scope.datetimeList = Locale.getDateTimeOptions()
      $scope.weekdayList = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
      $scope.nameSequenceList = Locale.getNameOptions()
      $scope.numberFormatList = Locale.getNumberOptions()
      $scope.Locale = Locale
      $scope.currentTime = moment()
      $scope.message = {}

      $scope.canSubmit = function () {
        if (!original) {
          return
        }
        $scope.form.language = $scope.selectedLang != null ? $scope.selectedLang.lang : undefined

        $scope.form.locale = { region: $scope.selectedLocale }
        if ($scope.customLocale) {
          $scope.form.locale.datetime = $scope.custom.datetime
          $scope.form.locale.firstDayOfWeek = $scope.custom.firstDayOfWeek
          $scope.form.locale.nameSequence = $scope.custom.nameSequence
          $scope.form.locale.number = $scope.custom.number
        } else {
          $scope.form.locale.datetime = ""
          $scope.form.locale.firstDayOfWeek = ""
          $scope.form.locale.nameSequence = ""
          $scope.form.locale.number = ""
        }

        if ($scope.form.role.group === "agency" || $scope.form.role.group === "space") {
          $scope.form.defaultCompany =
            $scope.selectedCompany != null ? $scope.selectedCompany._id : undefined
        }
        return $scope.form_create.$valid && !angular.equals($scope.form, original)
      }

      $scope.submitForm = () => $scope.updateItem($scope.form)

      $scope.updateItem = function (item) {
        if (!item.password) {
          delete item.password
        }

        return AccountSrv.update(
          { id: item._id },
          item,
          function (sobj, res) {
            $scope.message = {
              isSuccess: true,
              value: Locale.string("msg_success_update_account"),
            }
            original = angular.copy($scope.form)
            return Promise.all([
              ApiSrv.getFavoriteStores({ isCache: false }).catch((err) => []),
              ApiSrv.getFavoriteCameras({ isCache: false }).catch((err) => []),
              Auth.flushCurrentAccount(),
            ])
              .spread(function (stores, cameras, me) {
                Locale.setup(me.locale)
                return Locale.setLanguage($scope.selectedLang.code)
              })
              .catch((err) => console.error(err))
          },
          function (error) {
            if (error.data.err != null) {
              return ($scope.message = {
                isSuccess: false,
                value: error.data.err,
              })
            }
          }
        )
      }

      const _buildFavoriteStore = function () {
        $scope.storeList = _.sortBy($scope.storeList, ["companyName", "name"])
        $scope.selectedStore = $scope.storeList[0]
        return ($scope.form.favorite.stores = _.map(
          $scope.favoriteStores,
          (element) => element._id
        ))
      }

      const _buildFavoriteCamera = function () {
        $scope.cameraList = _.sortBy($scope.cameraList, ["storeName", "name"])
        $scope.selectedCamera = $scope.cameraList[0]
        return ($scope.form.favorite.cameras = _.map(
          $scope.favoriteCameras,
          (element) => element._id
        ))
      }

      $scope.addFavoriteStore = function (id) {
        const find = _.find($scope.storeList, { _id: id })
        if (!find) {
          return false
        }

        $scope.favoriteStores.push(find)
        _.remove($scope.storeList, function (store) {
          if (!store) {
            return false
          }
          return store._id === find._id
        })

        return _buildFavoriteStore()
      }

      $scope.removeFavoriteStore = function (item) {
        $scope.storeList.push(item)
        _.remove($scope.favoriteStores, (store) => store._id === item._id)

        return _buildFavoriteStore()
      }

      $scope.addFavoriteCamera = function (id) {
        const find = _.find($scope.cameraList, { _id: id })
        if (!find) {
          return false
        }

        $scope.favoriteCameras.push(find)
        _.remove($scope.cameraList, function (camera) {
          if (!camera) {
            return
          }
          return camera._id === find._id
        })

        return _buildFavoriteCamera()
      }

      $scope.removeFavoriteCamera = function (item) {
        $scope.cameraList.push(item)
        _.remove($scope.favoriteCameras, (camera) => camera._id === item._id)

        return _buildFavoriteCamera()
      }

      async.series(
        [
          (callback) =>
            ApiSrv.getAllCompany().then(
              function (res) {
                $scope.companyList = _.map(res, (element) => ({
                  _id: element._id,
                  name: element.name,
                }))

                return callback(null)
              },
              (err) => callback(err)
            ),
          (callback) =>
            ApiSrv.getAllStore().then(
              function (res) {
                $scope.storeList = _.map(res, (element) => ({
                  _id: element._id,
                  name: element.name,
                  companyName: __guard__(
                    _.find($scope.companyList, { _id: element._companyId }),
                    (x) => x.name
                  ),
                }))

                return callback(null)
              },
              (err) => callback(err)
            ),

          (callback) =>
            ApiSrv.getAllCamera().then(
              function (res) {
                $scope.cameraList = _.map(res, function (element) {
                  const store = _.find($scope.storeList, { _id: element._storeId })
                  if (!store) {
                    return false
                  }

                  return {
                    _id: element._id,
                    name: element.name,
                    _storeId: element._storeId,
                    storeName: store.name,
                  }
                })

                return callback(null)
              },
              (err) => callback(err)
            ),
        ],
        (err, result) =>
          ApiSrv.getAccount({ id: "me", isCache: false }).then(function (res) {
            let id
            if (!res) {
              return
            }
            $scope.form = angular.copy(res.data)

            $scope.selectedLang = _.find($scope.languageList, { lang: res.language })
            $scope.selectedLocale =
              (res.locale != null ? res.locale.region : undefined) || Locale.isSetup().region
            setSubLocales(res.locale)
            $scope.customLocale = (res.locale != null ? res.locale.datetime : undefined)
              ? true
              : false

            if (res.companies[0] != null) {
              const companyId = res.defaultCompany || res.companies[0]._companyId
              $scope.selectedCompany = _.find($scope.companyList, { _id: companyId })
            }

            if ($scope.form.role.orgs.length > 0) {
              $scope.affiliation = {}
              switch ($scope.form.role.group) {
                case Account.STORE:
                case Account.COMPANY:
                  ApiSrv.getAllCompany().then(function (res) {
                    if (!res) {
                      return
                    }
                    $scope.affiliation.name = `${res.map((r) => r.name).join(", ")}`
                    return ($scope.affiliation.role = "company")
                  })
                  break
                case Account.AGENCY:
                  ApiSrv.getAllAgency().then(function (res) {
                    if (!res) {
                      return
                    }
                    $scope.affiliation.name = `${res.map((r) => r.name).join(", ")}`
                    return ($scope.affiliation.role = "agency")
                  })
                  break
                case Account.SPACE:
                  ApiSrv.getSpace({ id: $scope.form.role.orgs[0] }).then(function (res) {
                    if (!res) {
                      return
                    }
                    $scope.affiliation.name = `${res.name}`
                    return ($scope.affiliation.role = "space")
                  })
                  break
              }
            }

            if ($scope.form_create != null) {
              $scope.form_create.$setPristine()
            }
            $scope.message = {}

            original = angular.copy($scope.form)

            $scope.selectedStore = $scope.storeList[0]
            $scope.selectedCamera = $scope.cameraList[0]
            for (id of Array.from(res.favorite.stores)) {
              $scope.addFavoriteStore(id)
            }
            return (() => {
              const result1 = []
              for (id of Array.from(res.favorite.cameras)) {
                result1.push($scope.addFavoriteCamera(id))
              }
              return result1
            })()
          })
      )

      $scope.filteredStores = (keyword) =>
        $filter("filter")($scope.storeList, function (item) {
          const str = new RegExp(`.*${keyword}.*`, "i")
          return (
            __guard__(item != null ? item.companyName : undefined, (x) => x.match(str)) != null ||
            __guard__(item != null ? item.name : undefined, (x1) => x1.match(str)) != null
          )
        })

      $scope.filteredCameras = (keyword) =>
        $filter("filter")($scope.cameraList, function (item) {
          const str = new RegExp(`.*${keyword}.*`, "i")
          return (
            __guard__(item != null ? item.storeName : undefined, (x) => x.match(str)) != null ||
            __guard__(item != null ? item.name : undefined, (x1) => x1.match(str)) != null
          )
        })

      $scope.getCompanyNameOfStore = (store) =>
        store.companyName != null ? store.companyName : "-"

      $scope.getStoreNameOfCamera = (camera) => (camera.storeName != null ? camera.storeName : "-")

      var setSubLocales = function (locale) {
        locale = $scope.localeList[$scope.selectedLocale.toLowerCase()]
        if (!locale) {
          locale = Locale.getDefaultLocale()
        }

        $scope.custom = {
          datetime: locale.datetime,
          firstDayOfWeek: locale.firstDayOfWeek,
          nameSequence: locale.nameSequence,
          number: locale.number,
        }
      }

      $scope.changeLocale = (item) => setSubLocales(item.value)

      $scope.getDateTimeName = (item) => item.value.string

      $scope.makeDateTimeString = (option) =>
        Locale.dateTime($scope.currentTime, "fullDate", option) +
        "<br>" +
        Locale.dateTime($scope.currentTime, "time", option)

      $scope.checkCustomLocale = function (customState) {
        if (customState === false) {
          return setSubLocales()
        }
      }

      $scope.getRoleType = function (type) {
        switch (type) {
          case Account.DEV_ADMIN:
            return Locale.string("dev_admin")
          case Account.SPACE:
            return Locale.string("system")
          case Account.AGENCY:
          case Account.COMPANY:
          case Account.STORE:
            return Locale.string(type)
          default:
            return Locale.string("store")
        }
      }

      return $scope.$on("localizeResourcesUpdated-language", function () {
        if ($scope.message.value.length > 0) {
          return ($scope.message = {
            isSuccess: true,
            value: Locale.string("msg_success_update_account"),
          })
        }
      })
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
