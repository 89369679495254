/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

import Promise from "bluebird"
import {
  isVisibleCamera,
  isVisibleStore,
  isFitFunctionalityState,
} from "../../components/header/org-state"
import Company from "../../lib/org/company"
import Store from "../../lib/org/store"
import Config from "../../../config/environment"

angular
  .module("uCountitUiApp")
  .controller(
    "NavCtrl",
    function (
      $rootScope,
      $scope,
      $location,
      $state,
      $window,
      Auth,
      BrandSrv,
      VersionSrv,
      CustomUISrv,
      $transitions,
      hotkeys,
      HeaderSrv,
      ApiSrv,
      RestApi,
      Locale,
      ServiceTokenSrv
    ) {
      const nav_shortcuts = [
        {
          combo: ["ctrl+shift+v", "0"],
          description: "Overview Page",
          callback() {
            return goState("app.overview")
          },
        },
        {
          combo: ["ctrl+shift+e", "-"],
          description: "Premium Report Page",
          callback() {
            return goState("app.premiumReport")
          },
        },
        {
          combo: ["ctrl+shift+f", "1"],
          description: "Footfall Page",
          callback() {
            return goState("app.footfall")
          },
        },
        {
          combo: ["ctrl+shift+z", "2"],
          description: "Zone Traffic Page",
          callback() {
            return goState("app.zonetraffic")
          },
        },
        {
          combo: ["ctrl+shift+c", "3"],
          description: "Occupancy Count Page",
          callback() {
            return goState("app.occupancy")
          },
        },
        {
          combo: ["ctrl+shift+m", "4"],
          description: "Flow Map Page",
          callback() {
            return goState("app.directionmap")
          },
        },
        {
          combo: ["ctrl+shift+h", "5"],
          description: "Heatmap Page",
          callback() {
            return goState("app.heatmap")
          },
        },
        {
          combo: ["ctrl+shift+g", "6"],
          description: "Gender/Age Page",
          callback() {
            return goState("app.genderAge")
          },
        },

        {
          combo: ["ctrl+shift+s"],
          description: "Live Sensors Page",
          callback() {
            return goState("app.sensor.list")
          },
        },
        {
          combo: ["ctrl+shift+d"],
          description: "Data Download",
          callback() {
            return goState("app.datadownload")
          },
        },

        {
          combo: ["shift+alt+c"],
          description: "Admin > Company Page",
          callback() {
            return goState("app.admin.company")
          },
        },
        {
          combo: ["shift+alt+t"],
          description: "Admin > Stores Page",
          callback() {
            return goState("app.admin.store")
          },
        },
        {
          combo: ["shift+alt+s"],
          description: "Admin > Sensors Page",
          callback() {
            return goState("app.admin.sensor")
          },
        },

        {
          combo: ["ctrl+alt+c"],
          description: "System > Configuration Page",
          callback() {
            return goState("app.system.configuration")
          },
        },
        {
          combo: ["ctrl+alt+a"],
          description: "System > Sensor Activity",
          callback() {
            return goState("app.system.activity-log")
          },
        },
        {
          combo: ["ctrl+alt+r"],
          description: "System > Report Status",
          callback() {
            return goState("app.system.report-status")
          },
        },
        {
          combo: ["ctrl+alt+b"],
          description: "System > Rebuild Status",
          callback() {
            return goState("app.system.rebuild-status")
          },
        },
        {
          combo: ["ctrl+alt+o"],
          description: "System > Data Operation",
          callback() {
            return goState("app.system.data-operation")
          },
        },
      ]

      let isNoComapny = false
      $scope.hasAccountOccupancyDashboard = false
      const init = function () {
        $scope.brand = BrandSrv.getBrand()
        return Auth.fetchCurrentAccount()
          .then(function (acc) {
            $scope.acc = acc
            $scope.getCurrentAccount = () => acc
            ApiSrv.getCrGroup()
              .then(function (crgroups) {
                $scope.hasAccountOccupancyDashboard = crgroups.length > 0
              })
              .catch(function (_err) {
                $scope.hasAccountOccupancyDashboard = false
              })
            return HeaderSrv.fetchCurrentCompany().then(function (company) {
              $scope.isHideOverviewMenu = company.isHideOverviewMenu.bind(company, acc)
              $scope.isHideStandardReportMenu = company.isHideStandardReportMenu.bind(company, acc)
              $scope.isHideCamerasMenu = company.isHideSensorsMenu.bind(company, acc)
              return ($scope.isHideAdminMenu = company.isHideAdminMenu.bind(company, acc))
            })
          })
          .catch(function (err) {
            if (err.message && err.message == "No Company") {
              isNoComapny = true
            }
          })
      }

      init()

      $scope.isLocalgrey = Config.isLocalgrey
      $scope.$state = $state
      $scope.storeParams = {}
      $scope.CustomUISrv = CustomUISrv
      $scope.hasStore = false
      $scope.servicetoken = ServiceTokenSrv.getToken()

      const createGetContentParams = (stores, sensors, curOrgs) =>
        function (stateName) {
          const [company, store, camera] = Array.from(curOrgs)
          let org = company
          const promise = camera.getFunctionality().then(function (functionality) {
            if (isFitFunctionalityState(functionality, stateName) && isVisibleCamera(stateName)) {
              return (org = camera)
            } else {
              return store.getFunctionality().then(function (functionality) {
                if (
                  isFitFunctionalityState(functionality, stateName) &&
                  isVisibleStore(stateName)
                ) {
                  return (org = store)
                } else {
                  return Promise.each(
                    stores.filter((s) => s.orgType === "store"),
                    (s) =>
                      s
                        .getFunctionality()
                        .then(function (functionality) {
                          if (
                            isFitFunctionalityState(functionality, stateName) &&
                            isVisibleStore(stateName)
                          ) {
                            org = s
                            return Promise.reject(new Error("success"))
                          }
                          return Promise.resolve()
                        })
                        .catch(function (err) {
                          if (err.message !== "success") {
                            console.error(err)
                          }
                        })
                  )
                }
              })
            }
          })

          return promise.then(() => ({
            id: org._id,
            orgType: org.orgType,
          }))
        }

      var goState = function (menu) {
        if (menu.indexOf("app.admin") >= 0) {
          if (!$scope.isVisibleMenu("admin")) {
            return
          }
        } else if (menu.indexOf("app.system") >= 0) {
          if (!$scope.isVisibleMenu("system")) {
            return
          }
        } else if (menu.indexOf("v2") >= 0) {
          if (!$scope.isVisibleMenu("system")) {
            return
          }
        } else {
          if (!$scope.hasFunctionality(menu)) {
            return
          }
        }
        $state.go(menu, $scope.getContentsParams(menu))
      }

      $scope.goMenuPage = (contentsType) => $rootScope.$broadcast("changeNav", contentsType)

      $scope.showHotkeys = () => hotkeys.toggleCheatSheet()

      $scope.showAdminMenu = function () {
        if ($scope.acc.gteAdmin()) {
          return true
        }
        // INFO UCNT-4239 agency role cannot access admin menu if agency org disallows admin menu.
        // agency.navMenu.admin : default value is ture even if the value does not exist.
        if (($scope.brand.navMenu != null ? $scope.brand.navMenu.admin : undefined) === false) {
          return false
        }
        if (isNoComapny) return true
        else
          return (
            $scope.isHideCamerasMenu != null && !$scope.isHideAdminMenu() && !$scope.servicetoken
          )
      }

      $scope.hasFunctionality = () => false
      $scope.isVisibleMenu = function (item) {
        switch (item) {
          case "footfall":
          case "zonetraffic":
          case "heatmap":
          case "genderAge":
          case "sales":
          case "queue":
          case "dashboardQueue":
          case "dashboardFace":
            return $scope.brand.navMenu[item]
          case "occupancy":
            return (
              $scope.acc.isDeveloper() ||
              ($scope.brand.navMenu[item] && $scope.hasFunctionality("app.occupancy"))
            )
          case "directionmap":
          case "flowmap":
            return (
              $scope.acc.isDeveloper() ||
              ($scope.brand.navMenu[item] && $scope.hasFunctionality("app.directionmap"))
            )
          case "path-tree":
            if (
              !$scope.hasFunctionality("app.path-tree") &&
              $scope.hasFunctionality("app.tracking-tree")
            ) {
              return false
            }
            return $scope.brand.navMenu[item]
          case "tracking-tree":
            if (
              $scope.hasFunctionality("app.path-tree") &&
              !$scope.hasFunctionality("app.tracking-tree")
            ) {
              return false
            }
            return $scope.brand.navMenu[item]
          case "sensor":
            return !(typeof $scope.isHideCamerasMenu === "function"
              ? $scope.isHideCamerasMenu()
              : undefined)
          case "admin":
            return $scope.showAdminMenu()
          case "admin.agency":
            return $scope.acc.gteAgency()
          case "admin.restfulapi":
            return $scope.acc.gteAdmin()
          case "app.datadownload":
          case "admin.s3fileapi":
            return $scope.acc.gteAdmin() && $scope.brand.navMenu[item]
          case "system":
            return $scope.acc.gteAdmin() && !$scope.servicetoken
          case "system.operation":
          case "system.data-operation":
          case "admin.event":
            return $scope.acc.isDeveloper() && $scope.brand.navMenu[item]
          case "system.report-status":
            return $scope.brand.navMenu["app.premiumReport"]
          case "system.posdriver":
            return $scope.brand.navMenu["sales"]
          case "app.standardReport":
            return !(typeof $scope.isHideStandardReportMenu === "function"
              ? $scope.isHideStandardReportMenu()
              : undefined)
          case "app.overview":
            return !(typeof $scope.isHideOverviewMenu === "function"
              ? $scope.isHideOverviewMenu()
              : undefined)
          case "app.premiumReport":
            return $scope.brand.navMenu[item] && $scope.hasFunctionality("app.premiumReport")
          case "v2":
            return $scope.acc.isDeveloper() && $scope.brand.navMenu[item]
          case "occupancyDashboard":
            return $scope.hasAccountOccupancyDashboard && $scope.hasCompanyOccupancyDashboard
          default:
            return true
        }
      }

      $scope.getTitle = function (type) {
        if (Locale.getLanguage() === "ko-kr") {
          switch (type) {
            case "footfall":
              return "RetailCount"
            case "zonetraffic":
              return "RetailTraffic"
            case "occupancy":
              return "RetailOccupancy"
            case "flowmap":
              return "RetailFlow"
            case "heatmap":
              return "RetailHeatmap"
            case "path-tree":
              return "PathTree"
            case "gender":
            case "agegroup":
            case "genderAge":
              return "RetailFace"
          }
        }

        return ""
      }

      $scope.openSite = function (site) {
        let url = "/v2"
        switch (site) {
          case "contents":
            url += "/contents"
            break
          case "admin":
            url += "/admin"
            break
          case "occupancy-dashboard":
            {
              const companyId = $scope.getCurrentCompany() ? $scope.getCurrentCompany()._id : ""
              const storeId = $scope.getCurrentStore() ? $scope.getCurrentStore()._id : ""
              url += `/room-indicator?companyId=${companyId}&storeId=${storeId}`
            }
            break
          case "occupancy-dashboard/settings":
            url += "/room-indicator/settings"
            break
        }
        $window.open(url, "_blank")
        return true
      }

      let functionalityCache = new Map()
      const createNavParams = function () {
        const contentParamsCache = new Map()
        // const getKey = (org, state) => org._id + state.name
        return HeaderSrv.fetchCurrentCompany().then(function (company) {
          if (!company) {
            $scope.getContentsParams = null
            return Promise.resolve()
          }

          return Promise.all([
            HeaderSrv.fetchCurrentStore(),
            HeaderSrv.fetchAllStore(),
            HeaderSrv.fetchCurrentSensor(),
            HeaderSrv.fetchAllSensor(),
          ]).spread(async function (store, stores, sensor, sensors) {
            $scope.hasStore = stores.filter((s) => s.orgType == Store.ORG_TYPE).length > 0
            const comf = await company.getFunctionality()
            const getContentParams = createGetContentParams(stores, sensors, [
              company,
              store,
              sensor,
            ])
            for (const state of $state.get()) {
              const param = await getContentParams(state.name)
              contentParamsCache.set(state.name, param)
            }
            // create links for state
            $scope.getContentsParams = function (stateName) {
              if (contentParamsCache.has(stateName)) {
                return contentParamsCache.get(stateName)
              }
              return {}
            }
            // disable link for state (it works for only company)
            $scope.hasFunctionality = function (stateName) {
              if (functionalityCache.has(stateName)) {
                return functionalityCache.get(stateName)
              }
              const hasFunctionality = isFitFunctionalityState(comf, stateName)
              functionalityCache.set(stateName, hasFunctionality)
              return hasFunctionality
            }

            $scope.getCurrentCompany = () => company
            $scope.getCurrentStore = () => (store.orgType === Store.ORG_TYPE ? store : null)
          })
        })
      }

      createNavParams()

      $scope.hasCompanyOccupancyDashboard = false
      const updateOccupancyDashboard = (isInitial = false) => {
        if (!isInitial && !$scope.hasAccountOccupancyDashboard) return

        return HeaderSrv.fetchCurrentCompany().then(function (company) {
          ApiSrv.getCrGroupOfCompany({ id: company._id })
            .then(function (crgroups) {
              $scope.hasCompanyOccupancyDashboard = crgroups.length > 0
            })
            .catch(function (_err) {
              $scope.hasCompanyOccupancyDashboard = false
            })
        })
      }
      updateOccupancyDashboard(true)

      HeaderSrv.emitter.addListener("changed", function (org) {
        if (org.orgType === Company.ORG_TYPE) {
          functionalityCache = new Map()
          updateOccupancyDashboard()
        }
        return createNavParams().catch(console.error)
      })

      VersionSrv.getVersions().then(function (versions) {
        if (versions) {
          return ($scope.deployVersion = versions["uCountIt"].version)
        }
        return ""
      })

      return nav_shortcuts.forEach(hotkeys.bindTo($scope).add)
    }
  )
