import React, { useState, useMemo } from "react"
import StoreSelectField from "../../../../components/react/StoreSelectField"
import SelectField from "../../../../components/react/SelectField"
import { useFormApi } from "informed"
import propTypes from "prop-types"
import { connect } from "react-redux"
import { createAsync } from "../../../../ducks/lib/redux-async"
import { kStandardLabels } from "../../../../components/counter-label"

const labelTable = kStandardLabels.map((label) => ({
  _id: label._id,
  name: label.name,
}))

const StoreFootfallSourceParameter = (props) => {
  const formApi = useFormApi()
  const [store, setStore] = useState()
  const { isPending, isFulfilled, data, samplings } = props

  const handleChange = (option) => {
    setStore(option.data)
  }

  if (!store) {
    const storeId = formApi.getValue("source.parameter.storeId")
    if (storeId) {
      if (isFulfilled) {
        const store = data.find((o) => o._id == storeId)
        setStore(store)
      }
    }
  }

  const renderLabels = () => {
    const labels = useMemo(
      () =>
        labelTable
          .concat((store ? store.labels || [] : []).sort((a, b) => a.name < b.name))
          .map((l) => ({ label: l.name, value: l._id })),
      [store]
    )
    return (
      <div className={`form-group ${error && error.labelId ? "has-error" : null}`}>
        <label className="control-label col-sm-3">Label</label>
        <div className="col-sm-9">
          <SelectField loading={isPending} field="source.parameter.labelId" options={labels} />
          {error && error.labelId ? <span className="help-block">{error.labelId}</span> : null}
        </div>
      </div>
    )
  }

  const { error } = props
  return (
    <>
      <div className={`form-group ${error && error.storeId ? "has-error" : null}`}>
        <label className="control-label col-sm-3">Store</label>
        <div className="col-sm-9">
          <StoreSelectField
            loading={isPending}
            onChange={handleChange}
            field="source.parameter.storeId"
          />
          {error && error.storeId ? <span className="help-block">{error.storeId}</span> : null}
        </div>
      </div>
      {renderLabels()}
      <div className={`form-group ${error && error.sampling ? "has-error" : null}`}>
        <label className="control-label col-sm-3">Sampling</label>
        <div className="col-sm-9">
          <SelectField
            loading={isPending}
            field="source.parameter.sampling"
            options={Object.keys(samplings).map((k) => ({
              label: samplings[k],
              value: samplings[k],
            }))}
          />
          {error && error.sampling ? <span className="help-block">{error.sampling}</span> : null}
        </div>
      </div>
    </>
  )
}

StoreFootfallSourceParameter.propTypes = {
  samplings: propTypes.object,
  isFulfilled: propTypes.bool,
  isPending: propTypes.bool,
  isRejected: propTypes.bool,
  error: propTypes.object,
  data: propTypes.array,
}

import {
  selectors as StoreSelectors,
  actions as StoreActions,
} from "../../../../ducks/modules/store/model"

export default connect(
  (state, ownProps) => {
    const Model = StoreSelectors.model(state)
    const data = Model.all().toModelArray()

    return {
      ...ownProps,
      isPending: StoreSelectors.index.isPending(state)(),
      isFulfilled: StoreSelectors.index.isFulfilled(state)(),
      isRejected: StoreSelectors.index.isRejected(state)(),
      data,
    }
  },
  (dispatch, ownProps) => {
    return {
      ...ownProps,
      actionFn: () => dispatch(StoreActions.index.request()),
    }
  }
)(createAsync({ Pending: StoreFootfallSourceParameter, Fulfilled: StoreFootfallSourceParameter }))
