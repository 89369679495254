/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import Promise from "bluebird"
import _ from "lodash"
import angular from "angular"

import { BrowserTokenModel } from "common-library/token-model"
import axios from "axios"
import * as jose from "jose"

const tokenModel = new BrowserTokenModel()

angular
  .module("uCountitUiApp")
  .factory(
    "Auth",
    function (
      $document,
      $rootScope,
      $http,
      $cookieStore,
      $q,
      $cookies,
      deviceDetector,
      Locale,
      RestApi,
      ServiceTokenSrv
    ) {
      // Do interval renewal
      const renewal = function () {
        const token = tokenModel.getToken()
        if (!token) {
          return
        }

        const decoded = jose.decodeJwt(token)
        if (!decoded) {
          return
        }
        // If expired_at is less than 30 minute left then renew token
        if (decoded.exp - Date.now().valueOf() / 1000 > 60 * 30) {
          return
        }
        return axios
          .get("/auth/local/renew", {
            header: {
              "Content-Type": "application/json",
              Authoirzation: `Bearer ${tokenModel.getToken()}`,
            },
          })
          .then(function (res) {
            if (res.status === 200) {
              return tokenModel.setToken(res.data.token)
            }
          })
          .catch((err) => console.warn(err))
      }

      setInterval(renewal, 600000) // 10m
      renewal()

      let currentAccount = null
      const fetchCurrentAccount = function (cache) {
        if (cache == null) {
          cache = true
        }
        if (cache && currentAccount) {
          return currentAccount
        }
        let account = null

        const servicetokenData = ServiceTokenSrv.getDecoded()
        if (servicetokenData) {
          account = { _id: servicetokenData._accountId }
        } else {
          const tokenData = tokenModel.getData()
          if (!tokenData || !tokenData.account || !tokenData.account._id) {
            return Promise.reject(new Error("Not logged"))
          }
          ;({ account } = tokenData)
        }

        return RestApi.Account.get(account._id, cache).then(function (account) {
          currentAccount = Promise.resolve(account)
          return currentAccount
        })
      }

      let myAccountSettings = {}
      const _clientInfo = function (key, value, atCookie) {
        if (value !== undefined) {
          myAccountSettings[key] = value
          if (atCookie === true) {
            return localStorage.setItem(key, JSON.stringify(value))
          }
        } else {
          if (atCookie) {
            const cookieValue = localStorage.getItem(key)
            if (cookieValue) {
              try {
                return JSON.parse(cookieValue)
              } catch (error) {
                return undefined
              }
            } else {
              return myAccountSettings[key]
            }
          } else {
            return myAccountSettings[key]
          }
        }
      }

      return {
        /*
    Authenticate account and save token
    @param  {Object}   account     - login info
    @param  {Function} callback - optional
    @return {Promise}
    */
        login(account, callback) {
          const deferred = $q.defer()
          const userAgent = {
            browser: deviceDetector.browser,
            //      browser_version: deviceDetector.browser_version
            device: deviceDetector.device,
            os: deviceDetector.os,
          }
          //      os_version: deviceDetector.os_version

          $http
            .post("/auth/local", {
              email: account.email,
              password: account.password,
              userAgent,
            })
            .success(function (data) {
              tokenModel.setToken(data.token)
              const tokenData = tokenModel.getData()
              if (!tokenData || !tokenData.account || !tokenData.account._id) {
                return Promise.reject(new Error("Not logged"))
              }
              ;({ account } = tokenData)
              return RestApi.Account.get(account._id, false)
                .then(function (acc) {
                  if (callback) {
                    callback()
                  }
                  currentAccount = acc
                  return deferred.resolve(currentAccount)
                })
                .catch(function (err) {
                  if (callback) {
                    callback(err)
                  }
                  return deferred.reject(err)
                })
            })
            .error((err) => {
              this.logout()
              deferred.reject(err)
              return typeof callback === "function" ? callback(err) : undefined
            })

          return deferred.promise
        },

        /*
    Delete access token and account info
    @param  {Function}
    */
        logout() {
          RestApi.invalidate()
          tokenModel.removeToken()
          $cookieStore.remove("token_status")
          currentAccount = null
          myAccountSettings = {}
          return ($rootScope.globalConfig = {
            apipath: "/api/1.0",
            haveBrandByAccount: false,
          })
        },

        fetchCurrentAccount,
        flushCurrentAccount() {
          return fetchCurrentAccount(false)
        },

        // there is no helper and it's not used anymore
        // updateCompany(item) {
        //   const company = _.find(helper.companies, { _companyId: item._id })
        //   if (company) {
        //     _.merge(company.db, item)
        //     return helper.fetchCompanies(false)
        //   }
        // },

        getToken: tokenModel.getToken.bind(tokenModel), // for backward compatibility
        clientInfo(key, value, atCookie) {
          if (atCookie == null) {
            atCookie = true
          }
          return _clientInfo(key, value, atCookie)
        },

        addFunctions(scope) {
          return _.merge(
            scope,
            _.pick(this, [
              "isLoggedIn",
              "isLoggedInAsync",
              "isSpace",
              "isAgency",
              "isAdmin",
              "isCompany",
              "isStore",
              "isUser",
              "aboveCompany",
              "aboveAgency",
              "aboveSpace",
              "isHideOverviewMenu",
              "isHideStandardReportMenu",
              "isHideAdminMenu",
              "isHideCamerasMenu",
              "getCurrentAccount",
            ])
          )
        },
      }
    }
  )
