/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("ucContentsCleaner", () => ({
    templateUrl: "app/admin/camera/contents-cleaner/contents-cleaner.html",
    controller: "ucContentsCleanerCtrl",
    controllerAs: "vm",

    scope: {
      isOpen: "=",
      selectedItem: "=",
    },
  }))
