/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular.module("uCountitUiApp").config(($stateProvider) =>
  $stateProvider
    .state("app.system", {
      abstract: true,
      url: "/system",
      template: "<ui-view></ui-view>",
    })
    .state("app.system.configuration", {
      url: "/configuration",
      templateUrl: "app/system/configuration/iot.html",
      controller: "IoTCtrl",
    })
    .state("app.system.monitoring", {
      url: "/monitoring",
      templateUrl: "app/system/monitoring/datalog.html",
      controller: "DataLogCtrl",
    })
    .state("app.system.report-status", {
      url: "/report-status",
      templateUrl: "app/system/report-status/report-status.html",
      controller: "ReportStatusCtrl",
    })
    .state("app.system.rebuild-status", {
      url: "/rebuild-status",
      templateUrl: "app/system/rebuild-status/rebuild-status.html",
      controller: "RebuildStatusCtrl",
    })
    .state("app.system.information", {
      url: "/information",
      templateUrl: "app/system/information/info.html",
      controller: "InfomationCtrl",
    })
    .state("app.system.notification", {
      url: "/notification",
      templateUrl: "app/system/notification/notification.html",
      controller: "NotificationCtrl",
    })
    .state("app.system.posdriver", {
      url: "/posdriver",
      templateUrl: "app/system/posdriver/posdriver.html",
      controller: "PosDriverCtrl",
    })
    .state("app.system.operation", {
      url: "/operation",
      templateUrl: "app/system/operation/operation.html",
      controller: "OperationCtrl",
    })
    .state("app.system.data-operation", {
      url: "/data-operation",
      templateUrl: "app/system/data-operation/data-operation.html",
      controller: "DataOperationCtrl",
    })
    .state("app.system.bgworker", {
      url: "/bgworker",
      templateUrl: "app/system/bgworker/bgworker.html",
    })
    .state("app.system.recording", {
      url: "/recording",
      templateUrl: "app/system/recording/recording.html",
    })
)

require("./activity-log")
