/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")
  .controller("AgeGroupSelectorCtrl", function ($scope, Locale, NgTableParams, ngDialog, $timeout) {
    $scope.onApply = angular.isDefined($scope.onApply) ? $scope.onApply : Promise.resolve()

    $scope.KAgeGroupName = {
      STANDARD: "standard",
      DECADE: "decade",
      KRGROUP: "krgroup",
      CUSTOM: "custom",
    }

    $scope.ageGroupList = [
      { name: Locale.string("Standard"), value: $scope.KAgeGroupName.STANDARD },
      { name: Locale.string("By Decade"), value: $scope.KAgeGroupName.DECADE },
      { name: Locale.string("KR Group"), value: $scope.KAgeGroupName.KRGROUP },
      { name: Locale.string("Custom"), value: $scope.KAgeGroupName.CUSTOM },
    ]

    $scope.ageGroupValues = {
      [$scope.KAgeGroupName.STANDARD]: [
        [0, 17],
        [18, 29],
        [30, 44],
        [45, 64],
        [65, null],
      ],
      [$scope.KAgeGroupName.DECADE]: [
        [0, 9],
        [10, 19],
        [20, 29],
        [30, 39],
        [40, 49],
        [50, 59],
        [60, 69],
        [70, null],
      ],
      [$scope.KAgeGroupName.KRGROUP]: [
        [0, 12],
        [13, 18],
        [19, 29],
        [30, 39],
        [40, 49],
        [50, 59],
        [60, null],
      ],
      [$scope.KAgeGroupName.CUSTOM]: [
        [0, 17],
        [18, 29],
        [30, 44],
        [45, 64],
        [65, null],
      ],
    }

    $scope.MIN_AGE = 9
    $scope.MAX_AGE = 80

    let originalAgeGroup = null

    $scope.ageGroupSelector = { selected: $scope.KAgeGroupName.STANDARD }
    $scope.alerts = []

    $scope.hasError = function (index) {
      const error =
        ($scope.form_ageGroup[`age-${index}`] != null
          ? $scope.form_ageGroup[`age-${index}`].$error
          : undefined) != null
          ? $scope.form_ageGroup[`age-${index}`] != null
            ? $scope.form_ageGroup[`age-${index}`].$error
            : undefined
          : {}
      return Object.keys(error).length > 0
    }

    $scope.getErrorMessage = function (index) {
      const error =
        ($scope.form_ageGroup[`age-${index}`] != null
          ? $scope.form_ageGroup[`age-${index}`].$error
          : undefined) != null
          ? $scope.form_ageGroup[`age-${index}`] != null
            ? $scope.form_ageGroup[`age-${index}`].$error
            : undefined
          : {}
      switch (false) {
        case !error.required:
          return "Enter age"
        case !error.min:
        case !error.max:
          return "Enter between 9 ~ 79"
        case !error.age:
          return "Enter valid age"
        default:
          return ""
      }
    }

    const isAscending = function (arr) {
      let left, left1
      return __range__(0, arr.length, false).map(
        (i) =>
          ((left = arr[i - 1]) != null ? left : 0) < arr[i] &&
          arr[i] < ((left1 = arr[i + 1]) != null ? left1 : Infinity)
      )
    }

    const validateAgeGroup = function () {
      const ageGroup = $scope.ageGroupValues.custom.map((v) => v[1]).slice(0, -1)
      const asc = isAscending(ageGroup)
      return (() => {
        const result = []
        for (
          let i = 0, end = ageGroup.length, asc1 = 0 <= end;
          asc1 ? i < end : i > end;
          asc1 ? i++ : i--
        ) {
          if ($scope.form_ageGroup[`age-${i}`] != null) {
            $scope.form_ageGroup[`age-${i}`].$setDirty()
          }
          result.push(
            $scope.form_ageGroup[`age-${i}`] != null
              ? $scope.form_ageGroup[`age-${i}`].$setValidity("age", asc[i] != null ? asc[i] : true)
              : undefined
          )
        }
        return result
      })()
    }

    $scope.addAgeGroupRow = function () {
      $scope.ageGroupValues.custom.push([null, null])
      return $timeout(() => validateAgeGroup(), 50)
    }

    $scope.deleteAgeGroupRow = function (index) {
      $scope.ageGroupValues.custom[index + 1][0] = $scope.ageGroupValues.custom[index - 1][1] + 1
      $scope.ageGroupValues.custom.splice(index, 1)
      return $timeout(() => validateAgeGroup(), 50)
    }

    $scope.editAgeGroupRow = function (value, index) {
      $scope.ageGroupValues.custom[index + 1][0] = value != null ? value + 1 : null
      return validateAgeGroup()
    }

    $scope.changeAgeGroup = () => $scope.tableParams.reload()

    const reloadData = function ($defer, params) {
      // let res
      const name = $scope.ageGroupSelector.selected
      const value = $scope.ageGroupValues[name]

      $scope.ageGroup.name = name
      $scope.ageGroup.value = value

      // return (res = $defer.resolve(value))
      return $defer.resolve(value)
    }

    $scope.isChangedAgeGroup = () =>
      !_.isEqual(originalAgeGroup, {
        name: $scope.ageGroupSelector.selected,
        value: $scope.ageGroupValues[$scope.ageGroupSelector.selected],
      })

    $scope.isValidateAgeGroup = function () {
      const value = $scope.ageGroupValues[$scope.ageGroupSelector.selected]
      const ageGroup = value.map((v) => v[1]).slice(0, -1)
      for (
        let i = 0, end = ageGroup.length, asc = 0 <= end;
        asc ? i < end : i > end;
        asc ? i++ : i--
      ) {
        var error =
          ($scope.form_ageGroup[`age-${i}`] != null
            ? $scope.form_ageGroup[`age-${i}`].$error
            : undefined) != null
            ? $scope.form_ageGroup[`age-${i}`] != null
              ? $scope.form_ageGroup[`age-${i}`].$error
              : undefined
            : {}
        if (Object.keys(error).length > 0) {
          return false
        }
      }

      return true
    }

    $scope.applyAgeGroup = function () {
      if ($scope.applyState === "PENDING") {
        return
      }

      return ngDialog
        .openConfirm({
          template: "components/popup/popup-confirm.html",
          data: {
            title: "Are you sure you want to apply this age group to all stores?",
          },
          closeByEscape: true,
          closeByDocument: true,
          showClose: false,
          className: "ngdialog-theme-default popup-confirm",
          controller: "ucPopupConfirmCtrl",
        })
        .then(function () {
          $scope.applyState = "PENDING"

          return $scope
            .onApply()
            .then(function () {
              $scope.applyState = "FULFILLED"
              addAlert({ type: "success", msg: "Success" })
              return setOriginalAgeGroup()
            })
            .catch(function (err) {
              $scope.applyState = "REJECTED"
              return addAlert({ type: "danger", msg: "Fail" })
            })
        })
    }

    var addAlert = function ({ type, msg }) {
      const length = $scope.alerts.push({ type, msg })
      return $timeout(() => $scope.closeAlert(length - 1), 3000)
    }

    $scope.closeAlert = function (index) {
      if ($scope.alerts.length > index) {
        return $scope.alerts.splice(index, 1)
      }
    }

    var setOriginalAgeGroup = () =>
      (originalAgeGroup = {
        name: $scope.ageGroupSelector.selected,
        value: _.cloneDeep($scope.ageGroupValues[$scope.ageGroupSelector.selected]),
      })

    const setCustomAgeGroup = function (ageGroup) {
      if ($scope.ageGroupSelector.selected === $scope.KAgeGroupName.CUSTOM) {
        return ($scope.ageGroupValues.custom = ageGroup.value)
      } else {
        return ($scope.ageGroupValues.custom = _.cloneDeep($scope.ageGroupValues.standard))
      }
    }

    const init = function (ageGroup) {
      $scope.applyState = ""
      $scope.ageGroupSelector.selected =
        ageGroup.name != null ? ageGroup.name : $scope.KAgeGroupName.STANDARD

      setCustomAgeGroup(ageGroup)
      setOriginalAgeGroup()

      return ($scope.tableParams = new NgTableParams({}, { counts: [], getData: reloadData }))
    }

    return $scope.$watch("ageGroup", function (ageGroup) {
      if (ageGroup == null) {
        return
      }

      return init(ageGroup)
    })
  })

function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
