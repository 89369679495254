/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

const DEFAULT_GRIDSIZE_W = 4
const DEFAULT_GRIDSIZE_H = 3
let globalStats = {}
let globalGridSize = {
  width: DEFAULT_GRIDSIZE_W,
  height: DEFAULT_GRIDSIZE_H,
}

export var findStatId = function (stat) {
  for (var key in stat) {
    var value = stat[key]
    if (value.id) {
      return value.id
    }
  }
}

export var getGlobalStats = () => globalStats

export var setGlobalStats = function (stats = null) {
  if (!stats) {
    return (globalStats = {})
  } else {
    return (() => {
      const result = []
      for (var mapname in stats) {
        result.push((globalStats[mapname] = stats[mapname]))
      }
      return result
    })()
  }
}

export var getGridSize = () => globalGridSize

export var changeGridSize = function (state, type) {
  if (type == null) {
    type = "both"
  }
  const alpha = (() => {
    switch (false) {
      case !(globalGridSize > 30):
        return 5
      case !(globalGridSize > 20):
        return 3
      case !(globalGridSize > 10):
        return 2
      default:
        return 1
    }
  })()

  if (state === "up") {
    if (type === "horizontal" || type === "both") {
      globalGridSize.width += alpha
    }
    if (type === "vertical" || type === "both") {
      return (globalGridSize.height += alpha)
    }
  } else if (state === "down") {
    if (type === "horizontal" || type === "both") {
      if (globalGridSize.width > 1) {
        globalGridSize.width -= alpha
      }
    }
    if (type === "vertical" || type === "both") {
      if (globalGridSize.height > 1) {
        return (globalGridSize.height -= alpha)
      }
    }
  } else {
    return (globalGridSize = {
      width: DEFAULT_GRIDSIZE_W,
      height: DEFAULT_GRIDSIZE_H,
    })
  }
}
