/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory("DateTimeSrv", function ($q, ApiSrv) {
    const getLocalTimeByTimezone = function (timezone) {
      const today = timezone ? moment().tz(timezone) : moment()

      return today
    }

    const getLocalTimeByOffset = function (offset) {
      const today = offset ? moment().utcOffset(offset) : moment()

      return today
    }

    const getDateTimeConfiguration = function (today, options) {
      const dateTime = today != null ? today : moment().startOf("day")

      const startDate =
        (options != null ? options.startDate : undefined) != null
          ? options != null
            ? options.startDate
            : undefined
          : 7
      const endDate =
        (options != null ? options.endDate : undefined) != null
          ? options != null
            ? options.endDate
            : undefined
          : 1

      const dt = {
        startDate: moment(dateTime).subtract(startDate, "day").startOf("day"),
        endDate: moment(dateTime).subtract(endDate, "day").endOf("day"),
      }

      const calenderMaxDate = moment(dateTime).endOf("day")
      const calenderRange = {
        Today: [moment(dateTime).startOf("day"), moment(dateTime).endOf("day")],
        Yesterday: [
          moment(dateTime).subtract(1, "day").startOf("day"),
          moment(dateTime).subtract(1, "day").endOf("day"),
        ],
        "Last 7 Days": [
          moment(dateTime).subtract(1, "weeks"),
          moment(dateTime).subtract(1, "day").endOf("day"),
        ],
        "Last 30 Days": [
          moment(dateTime).subtract(1, "months"),
          moment(dateTime).subtract(1, "day").endOf("day"),
        ],
        "Last Week": [
          moment(dateTime).subtract(1, "weeks").startOf("week"),
          moment(dateTime).subtract(1, "weeks").endOf("week"),
        ],
        "Last Month": [
          moment(dateTime).subtract(1, "month").startOf("month"),
          moment(dateTime).subtract(1, "month").endOf("month"),
        ],
      }

      return {
        dt: dt,
        maxDate: calenderMaxDate,
        ranges: calenderRange,
      }
    }

    const getTimezoneofStores = function (stores) {
      let timezone
      for (var store of Array.from(stores)) {
        if (!(store != null ? store.timezone : undefined)) {
          timezone = undefined
          break
        }

        if (!timezone) {
          ;({ timezone } = store)
        }
        if (timezone === !store.timezone) {
          timezone = undefined
          break
        }
      }

      return timezone
    }

    const getThisYearConfiguration = function () {
      const dateTime = getLocalTimeByTimezone()

      const dt = {
        startDate: moment(dateTime).startOf("year"),
        endDate: moment(dateTime).endOf("day"),
      }

      const calenderMaxDate = moment(dateTime).add(1, "day")

      return {
        dt: dt,
        maxDate: calenderMaxDate,
      }
    }

    return {
      getLocalTimeByTimezone,
      getLocalTimeByOffset,
      getDateTimeConfiguration,
      getTimezoneofStores,
      getThisYearConfiguration,
    }
  })
