/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")
  .controller(
    "FaceLogCtrl",
    function (
      $scope,
      $element,
      ApiSrv,
      SamplingSrv,
      Locale,
      usSpinnerService,
      FaceDeviceSrv,
      NgTableParams
    ) {
      let params = {}
      let gridData = []

      const kDateFormat = "YYYY-MM-DD"

      $scope.quota = 0
      $scope.header = {
        date: Locale.string("Date"),
        drop: "Drop by no face",
        dropSchedule: "Drop by business hours",
        dropLimit: "Drop by quota",
        usage: "Usage",
        quota: "Quota",
      }
      $scope.tableParams = new NgTableParams({}, { data: [] })

      const cameraId = $scope.ngDialogData

      const getDefaultData = function (from, to) {
        const data = []
        const start = moment.utc(from)
        const end = moment.utc(to)

        while (start < end) {
          data.push({
            localtime: SamplingSrv["day"].getFormatDate(start.format()),
            unixtime: start.unix(),
            drop: 0,
            dropSchedule: 0,
            dropLimit: 0,
            usage: 0,
          })
          start.add(1, "day")
        }

        return data
      }

      const showLoading = function () {
        angular.element(".splash").show()
        usSpinnerService.spin("spinner")
        return angular.element(".spinner").css("position", "absolute")
      }

      const hideLoading = function () {
        angular.element(".splash").hide()
        return usSpinnerService.stop("spinner")
      }

      const getQuota = (opt) =>
        FaceDeviceSrv.get({ id: opt.id, _: Date.now() }, function (res) {
          if (res.quota) {
            return ($scope.quota = parseInt(res.quota.split(" per ")[0]))
          }
        })

      $scope.loadMore = function () {
        params = _.assign({}, params, {
          from: moment(params.from).subtract(1, "day").format(kDateFormat),
          to: params.from,
        })

        return loadData(params)
      }

      var loadData = function (opt) {
        showLoading()
        return ApiSrv.getFacelog(opt)
          .then(function (res) {
            let row
            const data = getDefaultData(opt.from, opt.to)
            for (row of Array.from(res.data)) {
              var find = _.find(data, (d) => d.unixtime === moment.utc(row.localtime).unix())
              if (find) {
                find.drop = row.drop
                find.dropSchedule = row.dropSchedule
                find.dropLimit = row.dropLimit
                find.usage = row.usage
              }
            }

            for (row of Array.from(data)) {
              gridData.push(row)
            }

            gridData = _.sortBy(gridData, "unixtime")
            $scope.tableParams = new NgTableParams({ count: 100 }, { counts: [], data: gridData })

            return hideLoading()
          })
          .catch((err) => hideLoading())
      }

      const init = () =>
        setTimeout(function () {
          params = {
            id: cameraId,
            from: moment().subtract(1, "day").startOf("day").format(kDateFormat),
            to: moment().add(1, "day").startOf("day").format(kDateFormat),
            sampling: "day",
          }

          loadData(params)
          return getQuota(params)
        }, 100)

      return init()
    }
  )
