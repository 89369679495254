/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")

  .controller(
    "directionConfigCtrl",
    function (
      $scope,
      $element,
      $window,
      Direction,
      Locale,
      usSpinnerService,
      ngDialog,
      hotkeys,
      DirectionUtil
    ) {
      let load
      const MAX_DIRECTION = 4
      const errorImgeUrl = "/assets/images/no-image.svg"

      const formDirectionConfig = angular.element(document.getElementsByName("formDirectionConfig"))
      $scope.selectedItem = $scope.selectedItem != null ? $scope.selectedItem : {}

      angular.element($window).on("resize", () => load())

      let originDirections = null
      $scope.directions = null

      $scope.shortcuts = [
        {
          combo: "d",
          description: "Delete selected line",
          callback() {
            return $scope.$broadcast("gate_delete")
          },
        },
        {
          combo: "e",
          description: "Edit selected line",
          callback() {
            return $scope.$broadcast("gate_edit")
          },
        },
        {
          combo: "s",
          description: "Save editing line",
          callback() {
            return $scope.$broadcast("gate_save")
          },
        },
        {
          combo: "l",
          description: "Adding new linked direction",
          callback() {
            return $scope.$broadcast("gate_add_linked_dir")
          },
        },
        {
          combo: "a",
          description: "Adding new line",
          callback() {
            return $scope.$broadcast("gate_add")
          },
        },
        {
          combo: ["c", "escape"],
          description: "Cancel editing line",
          callback() {
            return $scope.$broadcast("gate_cancel")
          },
        },
      ]

      const showLoading = function () {
        angular.element("#directionSplash").show()
        return usSpinnerService.spin("directionSpinner")
      }

      const hideLoading = function () {
        angular.element("#directionSplash").hide()
        return usSpinnerService.stop("directionSpinner")
      }

      $scope.canSubmitDirection = function () {
        if (!$scope.isOpen) {
          return
        }
        return (
          $scope.formDirectionConfig.$valid && !angular.equals($scope.directions, originDirections)
        )
      }

      $scope.submitForm = function () {
        $scope.errorMessage = ""

        const dirs = $scope.directionSrv.transform2Meta($scope.directions)
        const data = {
          mode: $scope.mode,
          directions: dirs,
        }
        return $scope.directionSrv.set(data).then(
          function (res) {
            originDirections = _.cloneDeep($scope.directions)
            return $scope.$emit("direction_config_complete")
          },
          (error) => ($scope.errorMessage = Locale.string("msg_error_update"))
        )
      }

      $scope.$watch(
        () =>
          JSON.stringify({
            isOpen: $scope.isOpen,
            selectedItem: $scope.selectedItem != null ? $scope.selectedItem._id : undefined,
          }),
        () => load()
      )

      return (load = function () {
        if (!$scope.isOpen) {
          return
        }

        showLoading()
        $scope.errorMessage = ""
        let snapshotRatio = 9 / 16

        const _convertDirectionCoors = function (res) {
          const saved = $scope.directionSrv.transform2Pixel(res)
          const maxDir = Math.max(saved.length, MAX_DIRECTION)
          return __range__(0, maxDir, false).map((i) =>
            saved[i] != null ? saved[i] : DirectionUtil.getDefaultItem(i + 1)
          )
        }

        const _getDirectionmapMode = function (mode, use) {
          if (mode != null ? mode.length : undefined) {
            return mode
          }
          if (use.flowmap) {
            return "flowmap"
          }
          if (use.directionmap) {
            return "direction"
          }
          //INFO: sub directions of flowmap is flowdirection return "flowdirection"
          console.warn("unknown direction mode", mode, use)
          return ""
        }

        const _initDirection = function (ratio, url) {
          $scope.ImgHeight = window.innerHeight * 0.75
          $scope.ImgWidth = $scope.ImgHeight / ratio
          const formWidth = formDirectionConfig.width()

          if ($scope.ImgWidth > formWidth) {
            $scope.ImgWidth = formWidth
            $scope.ImgHeight = $scope.ImgWidth * ratio
          }

          $scope.directionSrv = new Direction(
            $scope.selectedItem._id,
            $scope.ImgWidth,
            $scope.ImgHeight
          )
          return $scope.directionSrv.get().then(function (res) {
            $scope.mode = _getDirectionmapMode(
              res.mode,
              $scope.selectedItem != null ? $scope.selectedItem.functions.use : undefined
            )
            originDirections = _convertDirectionCoors(res.directions)
            $scope.directions = _.cloneDeep(originDirections)
            $scope.snapshotUrl = url
            return hideLoading()
          })
        }

        const image = new Image()
        image.onload = function () {
          snapshotRatio = this.height / this.width
          return _initDirection(snapshotRatio, $scope.selectedItem.snapshotUrl)
        }
        image.onerror = () => _initDirection(snapshotRatio, errorImgeUrl)
        image.src = $scope.selectedItem.snapshotUrl
        return $scope.shortcuts.forEach(hotkeys.bindTo($scope).add)
      })
    }
  )

function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
