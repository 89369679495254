/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

import { getColors } from "../util/colors"

angular
  .module("uCountitUiApp")

  .factory("DrawZone", function () {
    let DrawZone
    const kDEFAULT_MINSTAYTIME = 3
    const kDEFAULT_GATEDIRECTION = "io"
    const kDEFAULT_GATEBOUNDARY = 0.75
    const ITEM = {
      TRACKINGZONE: "trackingzone",
      STAFFZONE: "staffzone",
      COUNTINGAREA: "countingarea",
      GATEZONE: "gatezone",
    }

    return (DrawZone = (function () {
      let _regularPolygonPoints = undefined
      let _moveCenterObject = undefined
      let _transformCoordinateToPixel = undefined
      let _transformPixelToCoordinate = undefined
      let _transformDrawZone = undefined
      let _transformZone = undefined
      let _randomID = undefined
      DrawZone = class DrawZone {
        static initClass() {
          _regularPolygonPoints = function (sideCount, radius) {
            const sweep = (Math.PI * 2) / sideCount
            const cx = radius
            const cy = radius
            const points = []
            let i = 0
            while (i < sideCount) {
              var x = cx + radius * Math.cos(i * sweep)
              var y = cy + radius * Math.sin(i * sweep)
              points.push({
                x,
                y,
              })
              i++
            }
            return points
          }

          _moveCenterObject = function (obj) {
            const left = _.minBy(obj, "x").x
            const top = _.minBy(obj, "y").y
            const right = _.maxBy(obj, "x").x
            const bottom = _.maxBy(obj, "y").y
            const center = {
              x: 65535 / 2 - (right - left) / 2,
              y: 65535 / 2 - (bottom - top) / 2,
            }

            const points = obj.map((n) => ({
              x: n.x - left + center.x,
              y: n.y - top + center.y,
            }))
            return points
          }

          _transformCoordinateToPixel = (x, resolution) => (x * resolution) / 65535
          //INFO Math.round causes the precision error on inverse function. CAUTION!!!
          //   x: 133, r:80, 133*80/65535 = 0.16235599298
          // inverse function
          //   round(0 * 65535 / 80) => 0 !== 133 Failure!
          //   round(0.16235599298 * 65535 / 80) => 133 == 133 Success!

          _transformPixelToCoordinate = (x, resolution) => {
            // INFO UCNT-5531 강제로 마지막 픽셀을 65535로 만들어 줌
            return x + 2 >= resolution ? 65535 : Math.round((x * 65535) / resolution)
          }

          _transformDrawZone = function (data, index, width, height, itemName) {
            let left, points, top
            const transformPoints = data.points.map((n) => ({
              x: _transformCoordinateToPixel(n.x, width),
              y: _transformCoordinateToPixel(n.y, height),
            }))
            if (transformPoints.length) {
              left = _.minBy(transformPoints, "x").x
              top = _.minBy(transformPoints, "y").y
              points = transformPoints.map((n) => ({
                x: n.x - left,
                y: n.y - top,
              }))
            }

            const name = _.isEmpty(data != null ? data.name : undefined)
              ? `${itemName}${index}`
              : data.name

            const drawZone = {
              active: data.active,
              color: data.color,
              name,
              left: left != null ? left : 0,
              top: top != null ? top : 0,
              points: points != null ? points : [],
              rgbcolor: `rgb(${data.color})`,
              id: _randomID(),
              _id: data._id,
            }

            if (itemName === ITEM.STAFFZONE) {
              drawZone.minStayTime = data.minStayTime ?? kDEFAULT_MINSTAYTIME
            } else if (itemName === ITEM.GATEZONE) {
              drawZone.direction = data.direction ?? kDEFAULT_GATEDIRECTION
              drawZone.gateBoundary = data.gateBoundary ?? kDEFAULT_GATEBOUNDARY
            }

            return drawZone
          }

          _transformZone = function (data, width, height, itemName) {
            let points, transformPoints
            if (data.points.length) {
              points = data.points.map((n) => ({
                x: n.x + data.left,
                y: n.y + data.top,
              }))
              transformPoints = points.map((n) => ({
                x: _transformPixelToCoordinate(n.x, width),
                y: _transformPixelToCoordinate(n.y, height),
              }))
            }

            const zone = {
              active: data.active,
              name: data.name,
              color: data.color,
              points: transformPoints != null ? transformPoints : [],
              _id: data._id ? data._id : undefined,
            }

            if (itemName === ITEM.STAFFZONE) {
              zone.minStayTime = data.minStayTime
            } else if (itemName === ITEM.GATEZONE) {
              zone.direction = data.direction
              zone.gateBoundary = data.gateBoundary
            }
            return zone
          }

          _randomID = function () {
            const min = 1
            const max = 999999
            return `zone${Math.floor(Math.random() * (max - min + 1)) + min}`
          }
        }
        constructor(bgWidth, bgHeight, itemName) {
          this.bgWidth = bgWidth
          this.bgHeight = bgHeight
          this.itemName = itemName
        }

        setBackgroundSize(width, height) {
          this.bgWidth = width
          return (this.bgHeight = height)
        }

        transformDrawZone(zone, index) {
          return _transformDrawZone(zone, index, this.bgWidth, this.bgHeight, this.itemName)
        }

        transformDrawZones(zones) {
          if (!zones) {
            return []
          }
          return zones.map((zone, index) =>
            _transformDrawZone(zone, index, this.bgWidth, this.bgHeight, this.itemName)
          )
        }

        transformZone(zone) {
          return _transformZone(zone, this.bgWidth, this.bgHeight)
        }

        transformZones(drawZones) {
          if (!drawZones) {
            return []
          }
          return drawZones.map((zone) =>
            _transformZone(zone, this.bgWidth, this.bgHeight, this.itemName)
          )
        }

        getDefaultDrawZone(index) {
          const newZone = {
            active: false,
            color: getColors(index, this.itemName),
            name: "",
            points: [],
            id: _randomID(),
          }
          newZone.rgbcolor = `rgb(${newZone.color})`

          if (this.itemName === ITEM.STAFFZONE) {
            newZone.minStayTime = kDEFAULT_MINSTAYTIME
          } else if (this.itemName === ITEM.GATEZONE) {
            newZone.direction = kDEFAULT_GATEDIRECTION
            newZone.gateBoundary = kDEFAULT_GATEBOUNDARY
          }

          return newZone
        }

        getHexagonZone(index) {
          if (index == null) {
            index = 0
          }
          const radius = 65535 / 3
          const hxPoints = _regularPolygonPoints(6, radius)
          const points = _moveCenterObject(hxPoints)
          return _.merge(this.getDefaultDrawZone(index), {
            active: true,
            name: `${this.itemName}${index + 1}`,
            points,
          })
        }
      }
      DrawZone.initClass()
      return DrawZone
    })())
  })
