/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory("CameraNoteSrv", ($rootScope, $resource) =>
    $resource(
      $rootScope.globalConfig.apipath + "/camera/:id/note/:nid",
      {
        id: "@id",
        nid: "@nid",
      },
      {
        update: {
          method: "PUT",
        },
        get: {
          isArray: true,
        },
      }
    )
  )
  .controller(
    "CameraNoteCtrl",
    function ($scope, $element, $window, Locale, NgTableParams, CameraNoteSrv) {
      $scope.cameraId = $scope.ngDialogData._id

      $scope.header = {
        date: Locale.string("Date"),
        who: Locale.string("Who"),
        note: Locale.string("Note"),
      }

      $scope.addItem = function () {
        $scope.isAdding = true
        const blank = { at: new Date().toISOString(), note: "" }
        blank.isEditing = true
        $scope.tableParams.settings().data.unshift(blank)
        $scope.tableParams.sorting($scope.tableParams.sorting())
        $scope.tableParams.page(1)
        return $scope.tableParams.reload()
      }

      $scope.removeItem = function (item) {
        if (!$window.confirm(Locale.string("msg_confirm_remove"))) {
          return
        }
        return CameraNoteSrv.delete({ id: $scope.cameraId, nid: item._id }, (sobj, res) =>
          $scope.reload()
        )
      }

      $scope.updateItem = function (item) {
        if ((item != null ? item._id : undefined) != null) {
          // update
          CameraNoteSrv.update({ id: $scope.cameraId, nid: item._id }, item, (sobj, res) =>
            $scope.reload()
          )
        } else {
          // create
          CameraNoteSrv.save({ id: $scope.cameraId }, item, (sobj, res) => $scope.reload())
        }
        return (item.isEditing = false)
      }

      $scope.enableEdit = (item) => (item.isEditing = true)

      $scope.cancelEdit = function (item) {
        item.isEditing = false
        if ((item != null ? item._id : undefined) != null) {
          item.note = _.find($scope.originalData, { _id: item._id }).note
          return $scope.tableParams.reload()
        } else {
          // cancel new item
          $scope.isAdding = false
          return $scope.tableParams.settings({
            data: angular.copy($scope.originalData),
          })
        }
      }

      $scope.reload = () =>
        CameraNoteSrv.get({ id: $scope.cameraId, _: new Date().getTime() }, function (data, res) {
          $scope.originalData = angular.copy(data)
          $scope.tableParams = new NgTableParams(
            {
              count: 10,
              sorting: { at: "desc" },
            },
            {
              counts: [5, 10, 25, 50],
              total: data.length,
              data,
            }
          )
          return ($scope.isAdding = false)
        })

      return $scope.reload()
    }
  )
