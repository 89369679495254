/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

import { fabric } from "fabric"

angular.module("uCountitUiApp").directive("directionmapChart", () => ({
  restrict: "E",
  replace: true,

  template: [
    "<div>",
    '<div class="directionmap-chart-container">',
    '<canvas id="directionmapCanvas"></canvas>',
    "</div>",
    '<div class="directionmap-info-container">',
    `<draw-mdi \
data-width="width" \
data-height="height" \
data-type="iconCode">`,
    "</draw-mdi>",
    "</div>",
    "</div>",
  ].join(""),

  scope: {
    width: "=",
    height: "=",
    option: "=",
    data: "=",
  },

  controller($scope, $element, $window, Direction) {
    const canvasElement = $element[0].querySelector("#directionmapCanvas")
    const fCanvasObj = new fabric.Canvas(canvasElement, {
      selection: false,
      preserveObjectStacking: true,
    })
    canvasElement.fabric = fCanvasObj
    const directionSrv = new Direction()
    $scope.transformOption = null
    $scope.iconCode = "\u{F01D0}"

    const CURVE_WEIGHT = 1.5
    const MAX_ARROW = 30
    const MIN_ARROW = 2

    const getFontSize = function (width, type) {
      if (type === "I") {
        if (width > 768) {
          return 16
        } else if (width > 320) {
          return 12
        } else {
          return 10
        }
      } else {
        if (width > 768) {
          return 13
        } else if (width > 320) {
          return 11
        } else {
          return 9
        }
      }
    }

    const _getCenter = function (gate) {
      const first = _.first(gate)
      const last = _.last(gate)

      return { x: (first.x + last.x) / 2, y: (first.y + last.y) / 2 }
    }

    const _generateGate = function (gateInfo) {
      const gate = []
      gateInfo.points.forEach((pt) =>
        gate.push({
          x: pt.x + gateInfo.left,
          y: pt.y + gateInfo.top,
        })
      )

      return gate
    }

    const _getCubicBezierAngle = function (start, control1, control2, end, t) {
      const tangentX =
        3 * Math.pow(1 - t, 2) * (control1.x - start.x) +
        6 * (1 - t) * t * (control2.x - control1.x) +
        3 * Math.pow(t, 2) * (end.x - control2.x)
      const tangentY =
        3 * Math.pow(1 - t, 2) * (control1.y - start.y) +
        6 * (1 - t) * t * (control2.y - control1.y) +
        3 * Math.pow(t, 2) * (end.y - control2.y)

      return Math.atan2(tangentY, tangentX) * (180 / Math.PI)
    }

    const _getDirectionPos = function (pa, pb, l, dir) {
      if (dir == null) {
        dir = "left"
      }
      const paa = {} // target pa+dx
      const pbb = {} // target pb+dx

      const dx = {} // delta vector of pa(pb) paa(pbb) vector
      const r = {} // rotation vector of pa pb vector

      if (dir === "left") {
        r.x = pb.y - pa.y
        r.y = -(pb.x - pa.x)
      } else {
        r.x = -(pb.y - pa.y)
        r.y = pb.x - pa.x
      }
      const L = Math.sqrt(r.x * r.x + r.y * r.y)

      dx.x = (l * r.x) / L
      dx.y = (l * r.y) / L

      paa.x = pa.x + dx.x
      paa.y = pa.y + dx.y
      pbb.x = pb.x + dx.x
      pbb.y = pb.y + dx.y

      return [paa, pbb]
    }

    const _getTextOriginType = function (type, radian) {
      if (radian > Math.PI * 2) {
        radian = (radian % Math.PI) * 2
      }
      if (radian < 0) {
        radian = radian + Math.PI * 2
      }

      if (type === "I") {
        if (0 <= radian && radian < Math.PI / 2) {
          return { x: "center", y: "top" }
        } else if (Math.PI / 2 <= radian && radian < Math.PI) {
          return { x: "right", y: "center" }
        } else if (Math.PI <= radian && radian < (Math.PI * 3) / 2) {
          return { x: "center", y: "bottom" }
        } else if ((Math.PI * 3) / 2 <= radian && radian < Math.PI * 2) {
          return { x: "left", y: "center" }
        }
      } else {
        if (0 <= radian && radian < Math.PI / 2) {
          return { x: "right", y: "bottom" }
        } else if (Math.PI / 2 <= radian && radian < Math.PI) {
          return { x: "left", y: "bottom" }
        } else if (Math.PI <= radian && radian < (Math.PI * 3) / 2) {
          return { x: "left", y: "top" }
        } else if ((Math.PI * 3) / 2 <= radian && radian < Math.PI * 2) {
          return { x: "right", y: "top" }
        }
      }
    }

    const drawCurvedArrow = function (inLine, outLine, color, size) {
      let x1, x2, y1, y2
      const shaft = _getDirectionPos(_.first(outLine), _.last(outLine), size)
      const mp1 = _getCenter(inLine)
      const mp2 = _getCenter(shaft)
      const mmp = _getCenter([mp1, mp2])

      const l1delx = inLine[1].x - inLine[0].x
      const l1dely = inLine[1].y - inLine[0].y
      if (l1delx === 0) {
        x1 = mmp.x
        y1 = mp1.y
      } else if (l1dely === 0) {
        x1 = mp1.x
        y1 = mmp.y
      } else {
        const m1 = l1dely / l1delx
        const n1 = -1 / m1
        x1 = (m1 * mmp.x - mmp.y - n1 * mp1.x + mp1.y) / (m1 - n1)
        y1 = n1 * (x1 - mp1.x) + mp1.y
      }
      x1 = mp1.x + (x1 - mp1.x) * CURVE_WEIGHT
      y1 = mp1.y + (y1 - mp1.y) * CURVE_WEIGHT
      const ctp1 = { x: x1, y: y1 }

      const l2delx = shaft[1].x - shaft[0].x
      const l2dely = shaft[1].y - shaft[0].y
      if (l2delx === 0) {
        x2 = mmp.x
        y2 = mp2.y
      } else if (l2dely === 0) {
        x2 = mp2.x
        y2 = mmp.y
      } else {
        const m2 = l2dely / l2delx
        const n2 = -1 / m2
        x2 = (m2 * mmp.x - mmp.y - n2 * mp2.x + mp2.y) / (m2 - n2)
        y2 = n2 * (x2 - mp2.x) + mp2.y
      }
      x2 = mp2.x + (x2 - mp2.x) * CURVE_WEIGHT
      y2 = mp2.y + (y2 - mp2.y) * CURVE_WEIGHT
      const ctp2 = { x: x2, y: y2 }

      const path = new fabric.Path(
        `M ${mp1.x} ${mp1.y} C ${ctp1.x} ${ctp1.y}, ${ctp2.x} ${ctp2.y}, ${mp2.x} ${mp2.y}`,
        {
          fill: "",
          stroke: color,
          strokeWidth: size,
          opacity: 1,
          originX: "center",
          originY: "center",
          selectable: false,
          objectCaching: false,
        }
      )
      fCanvasObj.add(path)

      const endingDegree = _getCubicBezierAngle(mp1, ctp1, ctp2, mp2, 0.99)
      const head = new fabric.Path(`M 0 0 L 0 -${size} L ${size} 0 L 0 ${size} L 0 0 Z`, {
        fill: color,
        left: mp2.x,
        top: mp2.y,
        originX: "left",
        originY: "center",
        angle: endingDegree,
        selectable: false,
        objectCaching: false,
      })
      return fCanvasObj.add(head)
    }

    const drawCountingLine = function (points, color) {
      const line = new fabric.Polyline(points, {
        stroke: color,
        strokeWidth: 2,
        strokeDashArray: [5, 5],
        opacity: 0.5,
        selectable: false,
        objectCaching: false,
      })
      return fCanvasObj.add(line)
    }

    const drawText = function (type, line, options) {
      let line2, name
      const fontSize = getFontSize($scope.width, type)
      const lastP = _.last(line)
      const firstP = _.first(line)
      const radian = Math.atan2(lastP.y - firstP.y, lastP.x - firstP.x)
      const origin = _getTextOriginType(type, radian)

      if (type === "I") {
        //in
        name = new fabric.Text(options.name, {
          fontSize,
          fontFamily: "roboto",
          fontWeight: 600,
          stroke: "gray",
          strokeWidth: 1,
          fill: "white",
          textAlign: "center",
          originX: "center",
          originY: "center",
          objectCaching: false,
        })
        fCanvasObj.add(name)

        const bound = name.getBoundingRect()
        const distance = Math.max(bound.width, bound.height) / 2
        line2 = _getDirectionPos(firstP, lastP, distance, "right")
        const pt = _getCenter(line2)
        name.left = pt.x
        return (name.top = pt.y)
      } else {
        //out
        line2 = _getDirectionPos(firstP, lastP, options.size)
        const mp = _getCenter(line2)
        const px = mp.x - options.size * Math.cos(radian)
        const py = mp.y - options.size * Math.sin(radian)
        const str = `${options.name}${options.ratio}%`
        name = new fabric.Text(str, {
          fontSize,
          fontFamily: "roboto",
          fontWeight: 800,
          stroke: "gray",
          strokeWidth: 1,
          fill: "white",
          textAlign: "center",
          originX: origin.x,
          originY: origin.y,
          left: px,
          top: py,
          superscript: { size: 0.7, baseline: -0.35 },
          objectCaching: false,
        })
        fCanvasObj.add(name)

        return name.setSuperscript(0, 1)
      }
    }

    const drawDirections = function () {
      fCanvasObj.clear()

      if (!$scope.data) {
        return
      }
      if (!$scope.transformOption) {
        return
      }

      const drawedInGate = []
      $scope.data.forEach(function (dir) {
        if (_.isNull(dir.ratio)) {
          return
        }

        const option = _.find($scope.transformOption, (opt) => opt.name === dir.name)

        const l1 = _generateGate(option.gates[0])
        const l2 = _generateGate(option.gates[dir.gateIdx])
        const size = Math.round((dir.thickness * (MAX_ARROW - MIN_ARROW)) / 100) + MIN_ARROW

        drawCurvedArrow(l1, l2, dir.chartColor, size)

        if (_.indexOf(drawedInGate, option.name) < 0) {
          if (dir.showCountingLine) {
            drawCountingLine(l1, dir.chartColor)
          }
          drawText("I", l1, { name: dir.name })
          drawedInGate.push(option.name)
        }

        if (dir.showCountingLine) {
          drawCountingLine(l2, dir.chartColor)
        }
        const labelInfo = {
          name: dir.idxCode,
          ratio: dir.ratio,
          size,
        }
        return drawText("O", l2, labelInfo)
      })

      fCanvasObj.getObjects("text").forEach((obj) => obj.bringToFront())
      return fCanvasObj.renderAll()
    }

    $scope.$watch("data", function (data) {
      if (!$scope.width && !$scope.height) {
        return
      }
      if ($scope.option && !$scope.transformOption) {
        $scope.transformOption = directionSrv.transform2Pixel($scope.option.directions)
      }

      return drawDirections()
    })

    return $scope.$watch(
      () =>
        JSON.stringify({
          width: $scope.width,
          height: $scope.height,
        }),
      function () {
        if (!$scope.width && !$scope.height) {
          return
        }

        directionSrv.setBackgroundSize($scope.width, $scope.height)
        fCanvasObj.setWidth($scope.width)
        fCanvasObj.setHeight($scope.height)
        $window.devicePixelRatio = 2
        if ($scope.option) {
          $scope.transformOption = directionSrv.transform2Pixel($scope.option.directions)
          return drawDirections()
        }
      }
    )
  },
}))
