import { BrowserTokenModel } from "common-library/token-model"

const tokenModel = new BrowserTokenModel()

export const authInterceptor = ($q, ServiceTokenSrv) => {
  return {
    request: (config) => {
      config.headers = config.headers || {}
      if (config.url.substring(0, 4) != "http") {
        const servicetoken = ServiceTokenSrv.getToken()
        if (servicetoken) {
          config.headers.ServiceToken = "Bearer " + servicetoken
          return config
        }
        if (tokenModel.getToken()) {
          config.headers.Authorization = "Bearer " + tokenModel.getToken()
        }
      }

      return config
    },

    response: (response) => response,

    // Intercept 401s and redirect you to login
    responseError: (response) => {
      const originalResponse = response
      if (!response.status && !(response.response && response.response.status))
        return $q.reject(response)

      if (response.response) response = response.response
      if (response.status == 401) {
        if (window.location.pathname != "/login") {
          window.location.replace("/login")
        }
      }
      return $q.reject(originalResponse)
    },
  }
}
