/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular.module("uCountitUiApp").config(($stateProvider) =>
  $stateProvider
    .state("app.stores", {
      abstract: true,
      url: "/stores",
      template: "<div ui-view></div>",
    })
    .state("app.stores.list", {
      url: "/list",
      templateUrl: "app/store/list/storelist.html",
      controller: "StoreViewCtrl",
    })
    .state("app.stores.comparison", {
      url: "/comparison",
      templateUrl: "app/store/comparison/storecomparison.html",
      controller: "StoreComparisonCtrl",
    })
)
