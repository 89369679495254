"use strict"

import Promise from "bluebird"
import { clone } from "../class-utils"

function StoreGroup(storeGroupData, Api) {
  this.Api = Api
  this.data = storeGroupData
  this.orgType = StoreGroup.ORG_TYPE
  this.data = storeGroupData
  for (const k in storeGroupData) {
    this[k] = storeGroupData[k]
  }

  this.functionality = null

  this.storeChangeHandler = (id, store) => {
    if (this.storeList.indexOf(id) >= 0) {
      this.Api.Store.removeListener("change", this.storeChangeHandler)
      this.functionality = null
    }
  }

  return this
}

StoreGroup.prototype.clone = function () {
  return clone(this)
}

StoreGroup.prototype.getFunctionality = function (defaultFunctionality = {}) {
  let ret
  if (this.functionality == null) {
    ret = this.functionality = Promise.reduce(
      this.data.storeList,
      (functionality, storeId) => {
        return this.Api.Store.get(storeId)
          .then((store) => store.getFunctionality(functionality))
          .catch((err) => functionality)
      },
      {}
    ).then((functionality) => {
      this.Api.Store.on("change", this.storeChangeHandler)
      return functionality
    })
  } else {
    ret = this.functionality
  }

  return ret.then((functionality) => Object.assign({}, defaultFunctionality, functionality))
}

StoreGroup.prototype.thumbnailUrl = function () {
  return `/api/1.0/store/group/${this.data._id}/thumbnail`
}
StoreGroup.ORG_TYPE = "storegroup"

export default StoreGroup
