export const getCompanyId = (acc = null) => {
  let companyId
  if (!companyId)
    companyId =
      sessionStorage.getItem("header_current_company") ||
      localStorage.getItem("header_current_company")
  if (!companyId && acc && acc.defaultCompany) companyId = acc.defaultCompany

  return companyId
}

export const setCompanyId = (companyId) => {
  if (companyId) {
    localStorage.setItem("header_current_company", companyId)
    sessionStorage.setItem("header_current_company", companyId)
  }
}

export const getStoreId = () => {
  return (
    sessionStorage.getItem("header_current_store") || localStorage.getItem("header_current_store")
  )
}

export const setStoreId = (storeId) => {
  if (storeId) {
    localStorage.setItem("header_current_store", storeId)
    sessionStorage.setItem("header_current_store", storeId)
  }
}

export const getSensorId = () => {
  return (
    sessionStorage.getItem("header_current_camera") || localStorage.getItem("header_current_camera")
  )
}

export const setSensorId = (cameraId) => {
  if (cameraId) {
    localStorage.setItem("header_current_camera", cameraId)
    sessionStorage.setItem("header_current_camera", cameraId)
  }
}
