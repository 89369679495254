import React from "react"
import FormEventContainer from "./FormEvent"
import Async from "react-async"
import propTypes from "prop-types"
import axios from "axios"

const EditEvent = (props) => {
  const handleSubmit = (data) => {
    if (props.onSubmit) props.onSubmit(data)
  }

  return (
    <section className="panel panel-default">
      <div className="panel-heading">
        <strong>
          <span className="glyphicon glyphicon-th" />
          &nbsp; Edit {props.initialValues.name}
        </strong>
        <a className="btn-util pull-right" href="/admin/event">
          <i className="mdi mdi-window-close" />
        </a>
      </div>
      <div className="panel-body">
        {props.error ? (
          <div className="col-xs-12">
            <div className="alert alert-danger" role="alert">
              {props.error.message}
            </div>
          </div>
        ) : null}
        <div className="col-xs-12">
          <FormEventContainer initialValues={props.initialValues} onSubmit={handleSubmit} />
        </div>
      </div>
    </section>
  )
}

EditEvent.propTypes = {
  onSubmit: propTypes.func,
  error: propTypes.object,
  disableSubmit: propTypes.bool,
  initialValues: propTypes.object,
}

export const EditEventAsync = (props) => {
  const patchEvent = ([data]) => {
    return axios.patch(`/api/1.0/event/${props.initialValues._id}`, data)
  }

  const { initialValues } = props

  return (
    <Async deferFn={patchEvent} defer={true} onResolve={() => props.onResolve && props.onResolve()}>
      {({ isLoading, data, error, run }) => {
        return (
          <EditEvent
            key={props.initialValues._id}
            initialValues={initialValues}
            onSubmit={run}
            disableSubmit={isLoading}
            error={error}
          />
        )
      }}
    </Async>
  )
}

EditEventAsync.propTypes = {
  onResolve: propTypes.func,
  initialValues: propTypes.object.isRequired,
}

export default EditEventAsync
