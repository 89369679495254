import React from "react"
import StoreSelectField from "../../../../components/react/StoreSelectField"
import propTypes from "prop-types"

const StoreSourceParameter = ({ error }) => {
  return (
    <div className={`form-group ${error ? "has-error" : null}`}>
      <label className="control-label col-sm-3">Store</label>
      <div className="col-sm-9">
        <StoreSelectField field="source.parameter.storeId" />
        {error && error.storeId ? <span className="help-block">{error.storeId}</span> : null}
      </div>
    </div>
  )
}

StoreSourceParameter.propTypes = {
  error: propTypes.object,
}

export default StoreSourceParameter
