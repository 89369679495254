/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

// import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")

  .controller("ucPopupAlertCtrl", function ($scope) {
    const vm = ($scope.vm = this)

    vm.ok = () => $scope.closeThisDialog()
  })
