/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"
import alasql from "alasql"

angular
  .module("uCountitUiApp")

  .factory(
    "ExportSrv",
    function ($rootScope, $resource, $q, $cacheFactory, Auth, uiGridExporterService) {
      const getStandardExportFileName = function (options) {
        if (!options.contentname || !options.exportFormat) {
          return
        }

        const _getFileExtention = function (dataFormat) {
          switch (dataFormat.toLowerCase()) {
            case "csv":
              return "csv"
            case "excel":
              return "xlsx"
            default:
              return dataFormat
          }
        }

        const extention = _getFileExtention(options.exportFormat)

        return `uCountit_(${options.contentname})_(${
          options.companyname != null ? options.companyname : ""
        })_(${options.storename != null ? options.storename : ""})_(${
          options.cameraname != null ? options.cameraname : ""
        })_ \
(${options.sampling != null ? options.sampling : ""})_(${
          options.startdate != null ? options.startdate : ""
        })_(${options.enddate != null ? options.enddate : ""}).${extention}`
      }

      const exportCsv = function (fileName, header, dataArray) {
        if (!header) {
          return
        }
        let csvHeader = ""
        header.forEach((headerAttr) => (csvHeader += `${headerAttr},`))
        csvHeader = csvHeader.slice(0, -1) + "\n"

        let csvData = ""
        dataArray.forEach(function (data) {
          header.forEach((key) => (csvData += `${data[key] != null ? data[key] : ""},`))
          return (csvData = csvData.slice(0, -1) + "\n")
        })
        csvData = csvHeader + csvData

        return uiGridExporterService.downloadFile(fileName, csvData, true)
      }

      const exportFile = function (data, dataInfo) {
        const filename = getStandardExportFileName(dataInfo)

        switch (dataInfo.exportFormat.toLowerCase()) {
          case "csv":
            return exportCsv(filename, dataInfo.header, data)
          case "excel":
            var headers = {}
            dataInfo.header.forEach((header) => (headers[header] = header))
            var excelData = [headers].concat(data)
            return alasql('SELECT * INTO XLSX("' + filename + '",{headers: false}) FROM ?', [
              excelData,
            ])

          default:
            return console.error(`unsupported file format [${dataInfo.exportFormat}]`)
        }
      }

      return {
        getStandardExportFileName,
        exportCsv,
        exportFile,
      }
    }
  )
