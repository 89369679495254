/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("summaryStore", () => ({
    templateUrl: "components/summary/summary-store.html",
    restrict: "EA",

    scope: {
      data: "=",
    },

    controller: "SummaryStoresCtrl",
  }))
  .directive("summaryCamera", () => ({
    templateUrl: "components/summary/summary-camera.html",
    restrict: "EA",

    scope: {
      data: "=",
    },

    controller: "SummaryCamerasCtrl",
  }))
