import React, { useState } from "react"
import { Text } from "informed"
import Select from "react-select"
import propTypes from "prop-types"

export const StoreFootfallTriggerParameter = ({ error }) => {
  const options = [
    {
      label: "AND",
      value: "and",
    },
    {
      label: "OR",
      value: "or",
    },
  ]
  const [value, setValue] = useState(options[0])
  return (
    <>
      <div className="form-group">
        <label className="col-sm-2 control-label" />
        <div className="col-sm-10">
          <Select
            onChange={(option) => {
              setValue(option)
            }}
            value={value}
            options={options}
          />
        </div>
      </div>
      {value.value == "and" ? (
        <div className="form-group">
          <label className="col-sm-2 control-label">AND</label>
          <div className="col-sm-10">
            <div className="form-group">
              <label className="control-label col-sm-3">Greater Than</label>
              <div className="col-sm-9">
                <Text
                  className="form-control"
                  type="number"
                  min="0"
                  field="trigger.parameter.cond.and.greater"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-3">Less Than</label>
              <div className="col-sm-9">
                <Text
                  className="form-control"
                  type="number"
                  min="0"
                  field="trigger.parameter.cond.and.less"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {value.value == "or" ? (
        <div className="form-group">
          <label className="col-sm-2 control-label">OR</label>
          <div className="col-sm-10">
            <div className="form-group">
              <label className="control-label col-sm-3">Less Than</label>
              <div className="col-sm-9">
                <Text
                  className="form-control"
                  type="number"
                  min="0"
                  field="trigger.parameter.cond.or.less"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-3">Greater Than</label>
              <div className="col-sm-9">
                <Text
                  className="form-control"
                  type="number"
                  min="0"
                  field="trigger.parameter.cond.or.greater"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

StoreFootfallTriggerParameter.propTypes = {
  error: propTypes.object,
}

export default StoreFootfallTriggerParameter
