/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS201: Simplify complex destructure assignments
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import Promise from "bluebird"
import { kStandardLabels } from "../../../components/counter-label"
import env from "../../../../config/environment"

angular
  .module("uCountitUiApp")

  .controller(
    "DataOperationCtrl",
    function (
      $rootScope,
      $scope,
      $filter,
      $window,
      Auth,
      DateTimeSrv,
      ApiSrv,
      Locale,
      DataOperationSrv,
      usSpinnerService,
      CameraConfigSrv,
      HeaderSrv,
      me,
      IotAdminSrv,
      $timeout
    ) {
      let allStores = []
      let allCameras = []

      const kConfirmMsg = Locale.string("msg_confirm_remove")
      const kAllSensors = Locale.string("All Sensors")
      const kAllStores = Locale.string("All Stores")
      const kMismatchedMsg = Locale.string("msg_mismatch_sensor")
      const kDiffAccessKeyMsg = Locale.string("msg_diff_accesskey")
      const kDiffLevel = Locale.string("msg_diff_level")
      const kDeleteConfirm = Locale.string("msg_delete_confirm")
      const kErrorDeleteConfirm = Locale.string("msg_error_delete_confirm")
      const kDateFormat = "YYYY-MM-DD"
      const kTimeFormat = "YYYY-MM-DD HH:00"
      const kPrefixNew = "[NEW]"

      let startDate = null
      let endDate = null
      const maxDate = moment().endOf("day")
      $scope.isLocalgrey = env.isLocalgrey

      $scope.configurations = {
        company: {
          active: true,
          displayName: "Company",
          exportName: "Company",
        },
        store: {
          active: true,
          displayName: "Store",
          exportName: "Store",
        },
        storeGroup: {
          active: true,
          displayName: "Store Group",
          exportName: "StoreGroup",
        },
        camera: {
          active: true,
          displayName: "Camera",
          exportName: "Camera",
        },
        counter: {
          active: true,
          displayName: "Counter",
          exportName: "Counter",
        },
        queue: {
          active: true,
          displayName: "Queue",
          exportName: "Queue",
        },
        dwell: {
          active: true,
          displayName: "Dwell",
          exportName: "Dwell",
        },
        zone: {
          active: true,
          displayName: "Zone",
          exportName: "Zone",
        },
        flow: {
          active: true,
          displayName: "Flow",
          exportName: "Flow",
        },
        direction: {
          active: true,
          displayName: "Direction",
          exportName: "Direction",
        },
        companyParam: {
          active: true,
          displayName: "Company Param",
          exportName: "CompanyParam",
        },
      }

      $scope.contents = {
        counter: {
          active: false,
          disabled: false,
          orgType: "sensor",
          displayName: "Footfall",
          exportName: "Footfall",
          operations: ["copy", "delete", "exportdata", "estimator"],
        },
        trackcounter: {
          active: false,
          disabled: false,
          orgType: "sensor",
          displayName: "Track Counter",
          rebuildName: "trackcounter",
          operations: ["rebuild"],
        },
        // TODO: implementating facecounter
        // facecounter: {
        //   active: false,
        //   disabled: false,
        //   displayName: "Face Counter",
        //   rebuildName: "facecounter",
        //   operations: ["rebuild"],
        // },

        face: {
          active: false,
          disabled: false,
          orgType: "sensor",
          displayName: "Gender/Age",
          exportName: "Face",
          operations: ["copy", "delete", "exportdata", "estimator", "scale"],
        },
        heatmap: {
          active: false,
          disabled: false,
          orgType: "sensor",
          displayName: "Traffic Heatmap",
          rebuildName: "trafficmap",
          operations: ["copy", "delete", "estimator", "scale", "rebuild"],
        },
        dwellmap: {
          active: false,
          disabled: false,
          orgType: "sensor",
          displayName: "Dwell Heatmap",
          rebuildName: "dwellmap",
          operations: ["copy", "delete", "estimator", "scale", "rebuild"],
        },
        roiheatmap: {
          active: false,
          disabled: false,
          orgType: "sensor",
          displayName: "Zone Traffic",
          exportName: "Roimap",
          rebuildName: "zonetraffic",
          operations: ["copy", "delete", "exportdata", "estimator", "scale", "rebuild"],
        },
        directionmap: {
          active: false,
          disabled: false,
          orgType: "sensor",
          displayName: "Direction Map",
          exportName: "Directionmap",
          rebuildName: "directionmap",
          operations: ["exportdata", "rebuild"],
        },
        flowmap: {
          active: false,
          disabled: false,
          orgType: "sensor",
          displayName: "Flow Map",
          exportName: "Flowmap",
          rebuildName: "flowmap",
          operations: ["copy", "delete", "exportdata", "estimator", "scale", "rebuild"],
        },
        trackingtree: {
          active: false,
          disabled: false,
          orgType: "sensor",
          sensorModel: "panorama",
          displayName: "Tracking Tree",
          exportName: "TrackingTree",
          rebuildName: "trackingtree",
          operations: ["delete", "rebuild"],
        },
        occupancy: {
          active: false,
          disabled: false,
          orgType: "sensor",
          displayName: "Occupancy",
          exportName: "OccupancyCount",
          operations: ["copy", "delete", "exportdata", "estimator", "scale"],
        },
        snapshot: {
          active: false,
          disabled: false,
          orgType: "sensor",
          displayName: "Snapshot",
          exportName: "Snapshot",
          operations: ["copy", "delete", "exportdata"],
        },
        heatmapImg: {
          active: false,
          disabled: false,
          orgType: "sensor",
          displayName: "Heatmap Image",
          exportName: "HeatmapImg",
          operations: ["exportdata"],
        },
        queue: {
          active: false,
          disabled: false,
          orgType: "sensor",
          displayName: "Queue",
          exportName: "QueueBasic",
          operations: ["exportdata"],
        },
        facestat: {
          active: false,
          disabled: false,
          orgType: "sensor",
          displayName: "Face Stat",
          exportName: "FaceStat",
          operations: ["estimator"],
        },
        facegroupstat: {
          active: false,
          disabled: false,
          orgType: "store",
          displayName: "Face Group Stat",
          exportName: "FaceGroupStat",
          operations: ["estimator"],
        },
      }

      $scope.isAllDate = false
      $scope.isAllConfigurations = { checked: true }
      $scope.isAllContents = { checked: true }
      $scope.isFlagOnly = { value: false }
      $scope.isForceUpdate = { value: true }
      $scope.isRebuildExport = { value: true }
      $scope.useRefLabel = { value: false }

      $scope.operations = [
        { name: Locale.string("Copy"), value: "copy" },
        // { name: Locale.string('Move'), value: 'move' }
        { name: Locale.string("Delete"), value: "delete" },
        { name: Locale.string("Delete S3"), value: "deleteS3" },
        { name: Locale.string("Export Data"), value: "exportdata" },
        { name: Locale.string("Estimate"), value: "estimator" },
        { name: Locale.string("Scale"), value: "scale" },
        { name: Locale.string("Rebuild"), value: "rebuild" },
      ]

      if ($scope.isLocalgrey) {
        delete $scope.configurations.queue
        delete $scope.contents.face
        delete $scope.contents.queue
        $scope.operations = $scope.operations.filter(
          (op) => ["deleteS3", "exportdata"].indexOf(op.value) < 0
        )
      }

      $scope.operation = { selected: $scope.operations[6] }

      $scope.src = {
        company: null,
        store: null,
        camera: null,
      }

      $scope.dst = {
        company: null,
        store: null,
        camera: null,
      }

      $scope.estimatorFactor = {
        face: "1.0,1.0",
        other: "1.0",
      }

      $scope.refLabel = {
        company: null,
        store: null,
        label: null,
      }

      $scope.logMessage = ""
      $scope.creatingSensor = false

      const getCameraUsn = function (accessKey) {
        if (accessKey == null) {
          return null
        }
        return _.last(accessKey.split("-"))
      }

      $scope.changeUseTime = function (useTimePicker) {
        $scope.setDateTimePicker(useTimePicker)
        return updatePickerValue(startDate, endDate)
      }

      var updatePickerValue = function (start, end) {
        let format, sampling
        const input = $('input[name="datetime"]')

        if (!start || !end) {
          $timeout(function () {
            input.val("")
            startDate = null
            return (endDate = null)
          })
          return
        }

        if ($scope.useTime) {
          format = kTimeFormat
          sampling = "hour"
        } else {
          format = kDateFormat
          sampling = "day"
        }

        return $timeout(function () {
          input.val(start.format(format) + " - " + end.format(format))
          startDate = start.clone().startOf(sampling)
          return (endDate = end.clone().startOf(sampling))
        })
      }

      $scope.setDateTimePicker = function (useTimePicker) {
        if (useTimePicker == null) {
          useTimePicker = false
        }
        const options = {
          locale: {
            format: useTimePicker ? kTimeFormat : kDateFormat,
            cancelLabel: "Clear",
          },
          maxDate,
          timePicker: useTimePicker,
          timePicker24Hour: useTimePicker,
          timePickerIncrement: 60,
          autoUpdateInput: false,
        }

        $('input[name="datetime"]').daterangepicker(options)

        $('input[name="datetime"]').on("apply.daterangepicker", (ev, picker) =>
          updatePickerValue(picker.startDate, picker.endDate)
        )
        $('input[name="datetime"]').on("cancel.daterangepicker", (ev, picker) =>
          updatePickerValue()
        )
      }

      $scope.changeCompany = function (company, type) {
        if (!company) {
          return
        }

        $scope[type].company = company
        let stores = _.filter(allStores, (store) => store._companyId === company._id)
        stores = $filter("orderBy")(stores, ["name"])

        const myStores = { name: kAllStores, isAll: true, _companyId: company.id }
        stores.unshift(myStores)

        const filteredAllStores = (keyword) =>
          $filter("filter")(stores, function (store) {
            const str = new RegExp(`.*${keyword}.*`, "i")
            return __guard__(store != null ? store.name : undefined, (x) => x.match(str)) != null
          })

        $scope[type].filteredAllStores = filteredAllStores
        return $scope.changeStore(stores[0], type)
      }

      $scope.changeStore = function (store, type) {
        const cameras = loadCamerasOfStore(store, type)
        return $scope.changeCamera(cameras[0], type)
      }

      $scope.changeCamera = function (camera, type) {
        $scope[type].camera = camera
        clearMessage()
        compareAccessKey()

        if (type === "dst" || $scope.isOperation("exportdata")) {
          return
        }

        _.forEach($scope.contents, function (content) {
          content.active = false
          return (content.disabled = true)
        })

        if (!camera) {
          return
        }

        const operation = $scope.operation.selected.value

        return _.forEach($scope.contents, function (content, name) {
          const isAllowOperation = content.operations.includes(operation)
          content.active =
            isAllowOperation &&
            camera.functions.use[name] &&
            (content.sensorModel ? camera.model == content.sensorModel : true)

          if (
            (!$scope.isOperation("rebuild") &&
              name === "flowmap" &&
              camera.functions.use["directionmap"]) ||
            ($scope.isOperation("estimator") &&
              name === "facestat" &&
              camera.functions.use["edgeFace"])
          ) {
            content.active = true
          }

          if (camera.isAll && (name === "facegroupstat") & camera.functions.use["edgeFace"]) {
            content.active = true
          }

          content.disabled = !content.active
          return true
        })
      }

      $scope.changeCommand = function () {
        if ($scope.isOperation("exportdata")) {
          $scope.isAllConfigurations.checked = true
          $scope.isAllContents.checked = true

          _.forEach($scope.contents, function (content) {
            content.active = false
            content.disabled = false
            return true
          })

          return _.forEach($scope.configurations, function (config) {
            config.active = false
            return true
          })
        } else {
          return $scope.changeCamera($scope.src.camera, "src")
        }
      }

      $scope.changeRefCompany = function (company) {
        $scope.refLabel.company = company

        let stores = _.filter(allStores, (store) => store._companyId === company._id)
        stores = $filter("orderBy")(stores, ["name"])

        $scope.filteredRefAllStores = (keyword) =>
          $filter("filter")(stores, function (store) {
            const str = new RegExp(`.*${keyword}.*`, "i")
            return __guard__(store != null ? store.name : undefined, (x) => x.match(str)) != null
          })

        return $scope.changeRefStore(stores[0])
      }

      $scope.changeRefStore = function (store) {
        $scope.refLabel.store = store
        $scope.refLabel.label = kStandardLabels[0]

        let storeLabels = kStandardLabels
        const activeLabels =
          store != null ? store.labels.filter((label) => label.active) : undefined
        storeLabels = storeLabels.concat(activeLabels)

        return ($scope.filteredAllLabels = (keyword) =>
          $filter("filter")(storeLabels, function (label) {
            const str = new RegExp(`.*${keyword}.*`, "i")
            return label.name.match(str) != null
          }))
      }

      const getStoreFunctionality = function (cameras) {
        const functionality = {}
        Object.keys($scope.contents).forEach((content) => (functionality[content] = false))
        cameras.forEach((camera) =>
          _.forEach(
            camera.functions != null ? camera.functions.use : undefined,
            function (value, content) {
              if (value) {
                return (functionality[content] = true)
              }
            }
          )
        )

        return {
          use: functionality,
        }
      }

      $scope.isOperation = (operation) => $scope.operation.selected.value === operation

      $scope.isShowWarningMessage = function () {
        if (!$scope.isOperation("copy")) {
          return false
        }
        return !(
          ($scope.src.camera != null ? $scope.src.camera.isAll : undefined) &&
          ($scope.dst.camera != null ? $scope.dst.camera.isAll : undefined) &&
          $scope.useVirtualCamera
        )
      }

      $scope.canSubmit = function (formName) {
        let canSubmit = true
        let canDate = false
        let isSelectedContents = _.some($scope.contents, { active: true })

        if ($scope.isOperation("exportdata")) {
          isSelectedContents =
            isSelectedContents ||
            _.some($scope.configurations, { active: true }) ||
            $scope.isAllConfigurations.checked ||
            $scope.isAllContents.checked
        }

        if ($scope.isOperation("deleteS3")) {
          isSelectedContents = true
        }

        if ($scope.isOperation("copy") || $scope.isOperation("move")) {
          if (
            ($scope.src.camera != null ? $scope.src.camera._id : undefined) ===
            ($scope.dst.camera != null ? $scope.dst.camera._id : undefined)
          ) {
            canSubmit = false
          }
          if (
            ($scope.src.camera != null ? $scope.src.camera.isAll : undefined) &&
            !($scope.dst.camera != null ? $scope.dst.camera.isAll : undefined)
          ) {
            canSubmit = false
          }
          if (
            !($scope.src.camera != null ? $scope.src.camera.isAll : undefined) &&
            ($scope.dst.camera != null ? $scope.dst.camera.isAll : undefined)
          ) {
            canSubmit = false
          }
        }

        if (
          $scope.isOperation("exportdata") ||
          $scope.isOperation("estimator") ||
          $scope.isOperation("deleteS3") ||
          $scope.isOperation("scale")
        ) {
          if ($scope.isAllDate) {
            canSubmit = false
          }
        }

        if ($scope.isAllDate || (startDate && endDate)) {
          canDate = true
        }

        return $scope[formName].$valid && canSubmit && isSelectedContents && canDate
      }

      const getMismatchedCameras = function () {
        if ($scope.src.camera == null || $scope.dst.camera == null) {
          return
        }

        const srcCameras = $scope.src.camera.isAll
          ? $scope.src.filteredAllCameras("").filter((c) => !c.isAll)
          : [$scope.src.camera]

        const dstCameras = $scope.dst.camera.isAll
          ? $scope.dst.filteredAllCameras("").filter((c) => !c.isAll)
          : [$scope.dst.camera]

        const srcUsns = srcCameras.map((camera) => getCameraUsn(camera.accessKey))
        const dstUsns = dstCameras.map((camera) => getCameraUsn(camera.accessKey))
        const usns = _.pullAllWith(srcUsns, dstUsns, function (a, b) {
          if (a == null || b == null) {
            return false
          }
          return b.includes(a)
        })

        return srcCameras
          .filter(function (camera) {
            const usn = getCameraUsn(camera.accessKey)
            return Array.from(usns).includes(usn)
          })
          .map((camera) => camera.name)
      }

      $scope.checkMatchedInfoMessage = () => ($scope.matchMessages = matchedMessage())

      var matchedMessage = function () {
        if ($scope.src.camera == null || $scope.dst.camera == null) {
          return
        }

        const srcCameras = $scope.src.camera.isAll
          ? $scope.src.filteredAllCameras("").filter((c) => !c.isAll)
          : [$scope.src.camera]

        const dstCameras = $scope.dst.camera.isAll
          ? $scope.dst.filteredAllCameras("").filter((c) => !c.isAll)
          : [$scope.dst.camera]

        const messages = []
        srcCameras.forEach(function (src) {
          const match = _.find(dstCameras, function (dst) {
            if (dst.accessKey == null || src.accessKey == null) {
              return false
            }
            return getCameraUsn(dst.accessKey).includes(getCameraUsn(src.accessKey))
          })
          if ($scope.useVirtualCamera) {
            return messages.push({
              src: src.name,
              dst:
                (match != null ? match.name : undefined) != null
                  ? match != null
                    ? match.name
                    : undefined
                  : `${kPrefixNew} ${src.name} - Virtual_#`,
            })
          } else {
            return messages.push({
              src: src.name,
              dst:
                (match != null ? match.name : undefined) != null
                  ? match != null
                    ? match.name
                    : undefined
                  : "",
            })
          }
        })

        return messages
      }

      var compareAccessKey = function () {
        let names
        if ($scope.src.camera == null || $scope.dst.camera == null) {
          return
        }

        if ($scope.src.camera.isAll && $scope.dst.camera.isAll) {
          names = getMismatchedCameras()
          if (names.length > 0) {
            setWarningMessage(kMismatchedMsg)
            $scope.isMismatchCameras = true
          } else {
            setWarningMessage("")
            $scope.isMismatchCameras = false
          }

          return ($scope.matchMessages = matchedMessage())
        } else if ($scope.src.camera.isAll) {
          return setWarningMessage(kDiffLevel)
        } else if ($scope.dst.camera.isAll) {
          return setWarningMessage(kDiffLevel)
        } else {
          names = getMismatchedCameras()
          if (names.length > 0) {
            return setWarningMessage(kDiffAccessKeyMsg)
          } else {
            return setWarningMessage("")
          }
        }
      }

      var setWarningMessage = (message) => ($scope.warningMessage = message)

      $scope.submitForm = function () {
        let from, name, opt, to
        if ($scope.isOperation("delete") && $scope.isAllDate) {
          name = $scope.src.camera.isAll ? $scope.src.store.name : $scope.src.camera.name
          const input = $window.prompt(`${kDeleteConfirm} "${name}"`)
          if (input !== name) {
            $scope.errorMessage = input != null ? kErrorDeleteConfirm : ""
            return
          } else {
            $scope.errorMessage = ""
          }
        } else {
          if (!$window.confirm(kConfirmMsg)) {
            return
          }
        }

        showLoading()

        if ($scope.isOperation("exportdata")) {
          from = moment(makeFrom())
          to = moment(makeTo())

          const dates = []
          while (from.isBefore(to)) {
            dates.push(from.format(kDateFormat))
            from.add(1, "day")
          }

          return Promise.mapSeries(dates, function (date) {
            const opt = {
              topic: `exportdata/${getExportList("contents").join(",")}/company/${
                $scope.src.company._id
              }`,
              sortDate: date,
              payload: {
                configurations: getExportList("configurations").join(","),
              },
            }

            return ApiSrv.addCommandQueue(opt)
              .then(function () {
                addInfoMessage({ status: "Success", src: `${date}, 1D`, count: 1 })
                if (isEndOfWeek(moment(date))) {
                  return addInfoMessage({
                    status: "Success",
                    src: `${moment(date).format("YYYYMM[W]WW")}, 1W`,
                    count: 1,
                  })
                }
              })
              .catch((err) =>
                addInfoMessage({ status: "Failure", src: date, count: 1, description: err.data })
              )
          }).then(() => hideLoading())
        } else if ($scope.isOperation("deleteS3")) {
          opt = {
            companyId: $scope.src.company._id,
            from: moment(makeFrom()).format(kDateFormat),
            to: moment(makeTo()).format(kDateFormat),
          }

          return ApiSrv.deleteS3Folder(opt)
            .then((res) => addInfoMessage({ status: "Success", count: res.count }))
            .catch((err) => addInfoMessage({ status: "Failure", description: err.data }))
            .then(() => hideLoading())
        } else if ($scope.isOperation("rebuild")) {
          const { timezone } = $scope.src.store
          const contentsList = _.filter($scope.contents, (c) => c.active)

          return Promise.map(contentsList, function (contents) {
            opt = {
              org: {
                type: "",
                _id: "",
              },
              content: contents.rebuildName,
              from: moment.tz(makeFrom(), timezone).format(),
              to: moment.tz(makeTo(), timezone).format(),
            }

            if ($scope.src.store.isAll) {
              opt.org.type = "company"
              opt.org._id = $scope.src.company._id
              ;({ name } = $scope.src.company)
            } else if ($scope.src.camera.isAll) {
              opt.org.type = "store"
              opt.org._id = $scope.src.store._id
              ;({ name } = $scope.src.store)
            } else {
              opt.org.type = "camera"
              opt.org._id = $scope.src.camera._id
              ;({ name } = $scope.src.camera)
            }

            return ApiSrv.createRebuildStatus(opt)
              .then((res) =>
                addInfoMessage({
                  status: "Success",
                  contents: contents.displayName,
                  src: name,
                  count: 1,
                })
              )
              .catch(function (err) {
                console.error("Rebuild: ", err)
                return addInfoMessage({
                  status: "Failure",
                  contents: contents.displayName,
                  src: name,
                  description: err.data,
                })
              })
          }).then(() => hideLoading())
        } else {
          return getSrcAndDstCameras()
            .then(function (...args) {
              const [srcCameras, dstCameras] = Array.from(args[0])
              return Promise.mapSeries(srcCameras, function (srcCamera, idx) {
                const params = makeCameraRequestParams(srcCamera, dstCameras[idx])
                return Promise.map(
                  params,
                  function (param) {
                    let promise
                    if ($scope.isOperation("estimator")) {
                      promise = ApiSrv.estimateContentsData(param)
                    } else if ($scope.isOperation("scale")) {
                      promise = ApiSrv.estimateContentsData(param)
                    } else {
                      promise = DataOperationSrv.update(param).$promise
                    }

                    return promise
                      .then(function (res) {
                        addInfoMessage({
                          status: "Success",
                          contents: param.contents,
                          src: srcCamera.name,
                          dst: dstCameras[idx] != null ? dstCameras[idx].name : undefined,
                          count: res.count,
                          description: res.description,
                          assign: res.assign,
                        })

                        if (
                          $scope.isOperation("estimator") &&
                          $scope.useTime &&
                          moment(param.to).isSame(moment(), "day")
                        ) {
                          return IotAdminSrv.Group("operation").update(
                            { uid: srcCamera.accessKey, command: "reconnectiot" },
                            {}
                          ).$promise
                        }
                      })
                      .catch((err) =>
                        addInfoMessage({
                          status: "Failure",
                          contents: param.contents,
                          src: srcCamera.name,
                          dst: dstCameras[idx] != null ? dstCameras[idx].name : undefined,
                          description: err.data,
                        })
                      )
                  },
                  { concurrency: 2 }
                )
              }).then(() => {
                const params = makeStoreRequestParams(srcCameras, dstCameras)
                return Promise.map(
                  params,
                  function (param) {
                    const { srcCamera, dstCamera, srcStore, dstStore, ...rest } = param
                    const payload = { ...rest, srcId: srcCamera._id, dstId: dstCamera?._id }

                    let promise

                    if ($scope.isOperation("estimator")) {
                      promise = ApiSrv.estimateContentsData(payload)
                    } else if ($scope.isOperation("scale")) {
                      promise = ApiSrv.estimateContentsData(payload)
                    } else {
                      promise = DataOperationSrv.update(payload).$promise
                    }

                    return promise
                      .then(function (res) {
                        addInfoMessage({
                          status: "Success",
                          contents: param.contents,
                          src: srcStore?.name || srcCamera.name,
                          dst: dstStore?.name || dstCamera?.name,
                          count: res.count,
                          description: res.description,
                          assign: res.assign,
                        })
                      })
                      .catch((err) =>
                        addInfoMessage({
                          status: "Failure",
                          contents: param.contents,
                          src: srcStore?.name || srcCamera.name,
                          dst: dstStore?.name || dstCamera?.name,
                          description: err.data,
                        })
                      )
                  },
                  { concurrency: 2 }
                )
              })
            })
            .then(function () {
              hideLoading()
              return ApiSrv.getAllCamera({ isCache: false })
            })
            .then(function (cameras) {
              allCameras = cameras
              setFunctionality(allCameras)
              loadCamerasOfStore($scope.dst.store, "dst")
              return compareAccessKey()
            })
        }
      }

      var getSrcAndDstCameras = function () {
        const srcCameraList = []
        const dstCameraList = []

        return new Promise(function (resolve, reject) {
          let srcCameras
          if ($scope.isOperation("copy")) {
            if ($scope.src.camera.isAll) {
              const mismatchedCameras = []
              srcCameras = $scope.src.filteredAllCameras("").filter((c) => !c.isAll)
              const dstCameras = $scope.dst.filteredAllCameras("").filter((c) => !c.isAll)
              srcCameras.forEach(function (src) {
                const dstIdx = _.findIndex(dstCameras, function (dst) {
                  if (dst.accessKey == null || src.accessKey == null) {
                    return false
                  }
                  return getCameraUsn(dst.accessKey).includes(getCameraUsn(src.accessKey))
                })
                if (dstIdx >= 0) {
                  srcCameraList.push(src)
                  return dstCameraList.push(dstCameras[dstIdx])
                } else {
                  return mismatchedCameras.push(src)
                }
              })

              if ($scope.useVirtualCamera) {
                return Promise.mapSeries(mismatchedCameras, (camera) =>
                  createVirtualCamera(camera._id, $scope.dst.store._id)
                    .then(function (virtualCamera) {
                      srcCameraList.push(camera)
                      return dstCameraList.push(virtualCamera)
                    })
                    .catch(function (err) {})
                ).then(() => resolve([srcCameraList, dstCameraList]))
              } else {
                return resolve([srcCameraList, dstCameraList])
              }
            } else {
              srcCameraList.push($scope.src.camera)
              dstCameraList.push($scope.dst.camera)
              return resolve([srcCameraList, dstCameraList])
            }
          } else {
            if ($scope.src.camera.isAll) {
              srcCameras = $scope.src.filteredAllCameras("").filter((c) => !c.isAll)
              srcCameras.forEach((camera) => srcCameraList.push(camera))
              return resolve([srcCameraList, dstCameraList])
            } else {
              srcCameraList.push($scope.src.camera)
              return resolve([srcCameraList, dstCameraList])
            }
          }
        })
      }

      var createVirtualCamera = (cameraId, storeId) =>
        new Promise(function (resolve, reject) {
          const opt = {
            id: cameraId,
            item: "virtual",
            storeId,
          }

          return CameraConfigSrv.set(
            opt,
            {},
            function (camera) {
              $scope.errorMessage = ""
              return resolve(camera)
            },
            function (err) {
              $scope.errorMessage = "Failed to create a virtual sensor. " + err.data
              return reject(err)
            }
          )
        })

      $scope.showCreateVirtualSensor = function () {
        if (!$scope.isOperation("copy")) {
          return false
        }

        if (
          !($scope.src.camera != null ? $scope.src.camera.isAll : undefined) &&
          ($scope.dst.camera != null ? $scope.dst.camera.isAll : undefined)
        ) {
          const dstCameras = $scope.dst.filteredAllCameras("").filter((c) => !c.isAll)
          const find = dstCameras.find(function (camera) {
            if (camera.accessKey == null) {
              return false
            }
            return getCameraUsn(camera.accessKey).includes(
              getCameraUsn($scope.src.camera.accessKey)
            )
          })

          if (find) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      }

      $scope.confirmCreateVirtualCamera = function () {
        if (
          $scope.dst.store == null ||
          ($scope.src.camera != null ? $scope.src.camera.isAll : undefined)
        ) {
          return
        }
        if ($scope.creatingSensor) {
          return
        }

        $scope.creatingSensor = true
        let virtualCamera = null
        clearMessage()

        return createVirtualCamera($scope.src.camera._id, $scope.dst.store._id)
          .then(function (camera) {
            virtualCamera = camera
            return ApiSrv.getAllCamera({ isCache: false })
          })
          .then(function (cameras) {
            allCameras = cameras
            setFunctionality(allCameras)
            cameras = loadCamerasOfStore($scope.dst.store, "dst")
            virtualCamera = cameras.find((camera) => camera.accessKey === virtualCamera.accessKey)
            $scope.changeCamera(virtualCamera, "dst")
            return virtualCamera
          })
          .then((camera) => ($scope.creatingSensor = false))
          .catch((err) => ($scope.creatingSensor = false))
      }

      var loadCamerasOfStore = function (store, type) {
        let cameras
        if (store != null ? store.isAll : undefined) {
          cameras = _.filter(
            allCameras,
            (camera) => camera._companyId === (store != null ? store._companyId : undefined)
          )
        } else {
          cameras = _.filter(
            allCameras,
            (camera) => camera._storeId === (store != null ? store._id : undefined)
          )
        }
        cameras = $filter("orderBy")(cameras, ["name"])

        if (store) {
          const allSensors = Object.assign({}, store, {
            name: kAllSensors,
            isAll: true,
            accessKey: "",
          })
          allSensors.functions = getStoreFunctionality(cameras)
          cameras.unshift(allSensors)
        }

        const filteredAllCameras = (keyword) =>
          $filter("filter")(cameras, function (camera) {
            const str = new RegExp(`.*${keyword}.*`, "i")
            return __guard__(camera != null ? camera.name : undefined, (x) => x.match(str)) != null
          })

        $scope[type].store = store
        $scope[type].filteredAllCameras = filteredAllCameras

        return cameras
      }

      var makeFrom = function () {
        const format = $scope.useTime ? kTimeFormat : kDateFormat
        if ($scope.isAllDate) {
          return null
        } else {
          return startDate.clone().format(format)
        }
      }

      var makeTo = function () {
        const format = $scope.useTime ? kTimeFormat : kDateFormat
        if ($scope.isAllDate) {
          return maxDate.clone().add(1, "day").format(format)
        } else {
          const sampling = $scope.useTime ? "hour" : "day"
          return endDate.clone().add(1, sampling).format(format)
        }
      }

      const makeCameraRequestParams = function (srcCamera, dstCamera) {
        const params = []

        _.forEach($scope.contents, function (content, name) {
          if (content.orgType !== "sensor") return
          let skip = !content.active || !srcCamera.functions.use[name]
          if (
            (!$scope.isOperation("rebuild") &&
              name === "flowmap" &&
              srcCamera.functions.use["directionmap"]) ||
            ($scope.isOperation("estimator") &&
              name === "facestat" &&
              srcCamera.functions.use["edgeFace"])
          ) {
            skip = !content.active
          }

          if (skip) {
            return
          }

          const param = {
            contents: name,
            operation: $scope.operation.selected.value,
            from: makeFrom(),
            to: makeTo(),
            srcId: srcCamera._id,
            dstId: dstCamera != null ? dstCamera._id : undefined,
            isRebuildExport: $scope.isRebuildExport.value,
          }

          if ($scope.isOperation("estimator") || $scope.isOperation("scale")) {
            param.factor = getEstimatorFactor(name)
            param.flagOnly = $scope.isFlagOnly.value
            param.command = $scope.isOperation("estimator") ? "EWS" : "MWS"

            if ($scope.isOperation("estimator")) {
              param.forceUpdate = $scope.isForceUpdate.value
              if ($scope.useRefLabel.value && $scope.refLabel.label) {
                param.labelId = $scope.refLabel.label._id
                param.storeId = $scope.refLabel.store._id
              }
            }
          }

          return params.push(param)
        })

        return params
      }

      const makeStoreRequestParams = function (srcCamera) {
        const params = []
        const camerasByStore = _.groupBy(srcCamera, "_storeId")

        _.forEach($scope.contents, function (content, name) {
          if (content.orgType !== "store") return

          if (name === "facegroupstat" && content.active && $scope.isOperation("estimator")) {
            _.forEach(camerasByStore, function (cameras, storeId) {
              const edgeFaceCamera = cameras.find((camera) => camera.functions.use["edgeFace"])
              const store = allStores.find((store) => store._id === storeId)
              if (edgeFaceCamera) {
                const param = {
                  contents: name,
                  operation: $scope.operation.selected.value,
                  from: makeFrom(),
                  to: makeTo(),
                  srcCamera: edgeFaceCamera,
                  isRebuildExport: $scope.isRebuildExport.value,
                  srcStore: store,
                  command: "EWS",
                  forceUpdate: $scope.isForceUpdate.value,
                  factor: getEstimatorFactor(name),
                  flagOnly: $scope.isFlagOnly.value,
                }

                if ($scope.useRefLabel.value && $scope.refLabel.label) {
                  param.labelId = $scope.refLabel.label._id
                  param.storeId = $scope.refLabel.store._id
                }

                params.push(param)
              }
            })
          }
        })

        return params
      }

      var getEstimatorFactor = function (contentName) {
        if (contentName === "face") {
          return $scope.estimatorFactor.face
        } else {
          return $scope.estimatorFactor.other
        }
      }

      $scope.groupByCompany = (company) => company.agencyName

      $scope.setTextStyle = function (srcCameraName, dstCameraName) {
        if (dstCameraName.includes(kPrefixNew)) {
          return "text-primary-bold"
        } else {
          if (srcCameraName === dstCameraName) {
            return ""
          } else {
            return "text-danger-bold"
          }
        }
      }

      var addInfoMessage = function (...args) {
        let obj = args[0],
          { status, contents, src, dst } = obj,
          val = obj.count,
          count = val != null ? val : 0,
          val1 = obj.description,
          description = val1 != null ? val1 : ""
        if ($scope.isOperation("estimator") || $scope.isOperation("scale")) {
          count = "-"
        }

        if (obj?.assign) {
          const assign = obj.assign
          const links = {
            bgWorker: `<a href="/system/bgworker">Background Worker</a>`,
          }
          const link = links[assign]
          if (link) {
            description = `Operation has been assigned to ${link}.`
          }
        }
        return $scope.infoMessages.unshift({ status, contents, src, dst, count, description })
      }

      var clearMessage = function () {
        $scope.infoMessages = []
        return ($scope.errorMessage = "")
      }

      var showLoading = function () {
        $scope.fetching = true
        angular.element(".splash").show()
        usSpinnerService.spin("spinner")
        return clearMessage()
      }

      var hideLoading = function () {
        $scope.fetching = false
        angular.element(".splash").hide()
        return usSpinnerService.stop("spinner")
      }

      var setFunctionality = (cameras) =>
        cameras.forEach(function (camera) {
          camera.functions.use.roiheatmap = camera.functions.use.zonetraffic
          if (camera.functions.use.flowmap) {
            camera.functions.use.directionmap = false
          }
          return (camera.functions.use.snapshot = true)
        })

      var getExportList = function (itemName) {
        const isAll =
          itemName === "configurations"
            ? $scope.isAllConfigurations.checked
            : $scope.isAllContents.checked
        if (isAll) {
          return ["all"]
        }

        const res = []
        _.forEach($scope[itemName], function (item) {
          if (!item.active) {
            return
          }
          return res.push(item.exportName)
        })

        return res
      }

      var isEndOfWeek = (date) => date.isoWeekday() === date.clone().endOf("isoweek").isoWeekday()

      const isLinkedFootfall = (camera) => camera.isVirtual && camera.model === "footfall"

      $scope.isShowContentsOption = function (content) {
        let bool = content.operations.includes($scope.operation.selected.value)
        if ($scope.isOperation("exportdata")) {
          bool = bool && !$scope.isAllContents.checked
        }
        return bool
      }

      const loadAllCameras = () =>
        Promise.all([
          ApiSrv.getAllAgency({ isCache: false }),
          ApiSrv.getAllCompany({ isCache: false }),
          ApiSrv.getAllStore({ isCache: false }),
          ApiSrv.getAllCamera({ isCache: false }),
          HeaderSrv.fetchCurrentCompany(),
        ]).spread(function (agencies, companies, stores, cameras, currentCompany) {
          stores = stores.map((s) => _.cloneDeep(s.data))
          agencies = agencies.map((s) => _.cloneDeep(s))
          companies = companies.map((s) => _.cloneDeep(s.data))
          cameras = cameras.map((s) => _.cloneDeep(s.data))

          companies.forEach(function (company) {
            const agency = _.find(agencies, { _id: company._agencyId })
            return (company.agencyName = agency.name)
          })

          companies = $filter("orderBy")(companies, ["agencyName", "name"])
          $scope.src.filteredAllCompanies = $scope.dst.filteredAllCompanies = (keyword) =>
            $filter("filter")(companies, function (company) {
              const str = new RegExp(`.*${keyword}.*`, "i")
              return (
                __guard__(company != null ? company.name : undefined, (x) => x.match(str)) != null
              )
            })

          allStores = stores
          allCameras = cameras.filter((camera) => !isLinkedFootfall(camera))
          setFunctionality(allCameras)

          const idx = _.findIndex(companies, (company) => company._id === currentCompany._id)
          $scope.changeCompany(companies[idx], "src")
          $scope.changeCompany(companies[idx], "dst")

          return $scope.changeRefCompany(companies[idx])
        })
      const init = function () {
        clearMessage()
        $scope.setDateTimePicker()
        return loadAllCameras()
      }

      $scope.me = me
      if (!me.gteDevAdmin()) {
        return
      }
      return init()
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
