/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular.module("uCountitUiApp").directive("drawMdi", () => ({
  restrict: "E",

  template: [
    '<div class="canvas-container">',
    '<canvas id="drawMDICanvas"></canvas>',
    "</div>",
  ].join(""),

  scope: {
    width: "=",
    height: "=",
    type: "=",
  },

  controller($scope, $element) {
    const canvas = $element[0].querySelector("#drawMDICanvas")
    const ctx = canvas.getContext("2d")
    const MIN_WIDTH = 496

    const getIconCode = function (type) {
      switch (type) {
        case "pass":
          return "\u{F046E}"
        case "dwell":
          return "\u{F0152}"
        default:
          return type
      }
    }

    const drawMDI = function (type) {
      const icon = getIconCode(type)
      const textSize = $scope.width > MIN_WIDTH ? 32 : 16
      const x = $scope.width * 0.05
      const y = $scope.height * 0.095
      ctx.fillStyle = "white"
      ctx.strokeStyle = "black"
      ctx.lineWidth = $scope.width > MIN_WIDTH ? 2 : 1
      ctx.font = `${textSize}px Material Design Icons`
      ctx.strokeText(icon, x, y)
      return ctx.fillText(icon, x, y)
    }

    $scope.$watch("type", function (newType) {
      if (!$scope.width && !$scope.height) {
        return
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height)
      return drawMDI(newType)
    })

    return $scope.$watchGroup(["width", "height"], function (newValues, oldValues) {
      if (newValues[0] !== oldValues[0] || newValues[1] !== oldValues[1]) {
        canvas.width = newValues[0]
        canvas.height = newValues[1]
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        return drawMDI($scope.type)
      }
    })
  },
}))
