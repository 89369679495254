/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("gridExport", (GridExportSrv) => ({
    templateUrl: "components/exportgrid/exportgrid.html",

    scope: {
      gridData: "=",
      exportAll: "=",
      comment: "=?gridComment",
    },

    link(scope, element, ettrs) {
      scope.isComment = scope.comment != null ? true : false
      scope.$watch("gridData.minRowsToShow", (val) => (scope.gridHeight = val * 30 + 45))

      scope.$watch("gridData.data", function (newValue, oldValue) {
        if (scope.gridData.data) {
          return (scope.gridLength = scope.gridData.data.length)
        }
      })

      return (scope.gridExport = (type) => GridExportSrv.gridExport(scope.gridData, type))
    },
  }))
