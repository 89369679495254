/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("orgIcon", () => ({
    restrict: "EA",

    scope: {
      orgIcon: "@",
      orgIconStyle: "@",
    },

    link(scope, element, attrs) {
      const addOrgIcon = function (role, style) {
        if (style == null) {
          style = ""
        }
        if (scope.className) {
          element.removeClass(scope.className)
        }

        const _getClassName = function (role) {
          switch (role) {
            case "dev_admin":
              return "mdi-security"
            case "space":
              return "mdi-web"
            case "agency":
              return "mdi-bank"
            case "company":
              return "mdi-domain"
            case "store":
              return "mdi-store"
            case "camera":
              return "mdi-camera"
          }
        }

        scope.className = `mdi ${_getClassName(role)}`
        if (style) {
          scope.className += ` ${style}`
        }
        return element.addClass(scope.className)
      }

      addOrgIcon(scope.orgIcon, scope.orgIconStyle)

      return scope.$watch(
        () => [scope.orgIcon, scope.orgIconStyle],
        (value) => addOrgIcon(scope.orgIcon, scope.orgIconStyle),
        true
      )
    },
  }))
