/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS202: Simplify dynamic range loops
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import Promise from "bluebird"

angular
  .module("uCountitUiApp")
  .controller(
    "OccupancyCtrl",
    function (
      $scope,
      $element,
      $stateParams,
      $state,
      $filter,
      SelectorSrv,
      DateTimeSrv,
      ChartOptionSrv,
      GridOptionSrv,
      ApiSrv,
      Locale,
      SamplingSrv,
      usSpinnerService,
      HeaderSrv
    ) {
      // validTime is true after initializing datetime picker
      $scope.validTime = false
      $scope.isCamera = false
      $scope.analysisTrackWorkerStatus = {}

      $scope.searchOptions = {
        storeId: $stateParams.id,
        sampling: "hour",
        content: "occupancy",
      }
      $scope.shortTermList = ["24H", "72H"]
      $scope.samplingList = [] //[{ key: "10m", sampling: "10m", title: "By 10 Minute"}]

      const chartOption = ChartOptionSrv.lineChartOption
      chartOption.yaxis.min = 0
      chartOption.legend = {
        container: angular.element("#legendContainer", $element),
        noColumns: 0,
        labelFormatter(label, series) {
          return label
        },
      }

      $scope.chartOption = chartOption

      $scope.gridOptions = _.merge(
        {
          onRegisterApi(gridApi) {
            return (this.gridApi = gridApi)
          },
          getGridApi() {
            return this.gridApi
          },
        },
        GridOptionSrv.gridExportOption
      )

      const showLoading = function () {
        angular.element(".splash").show()
        usSpinnerService.spin("spinner")
        return angular.element(".spinner").css("position", "fixed")
      }

      const hideLoading = function () {
        angular.element(".splash").hide()
        return usSpinnerService.stop("spinner")
      }

      const isValidPeriod = function (start, end, sampling) {
        const limitDate = SamplingSrv[sampling].getLimitDate()
        const diffNum = moment(end).diff(start, limitDate.key)

        if (diffNum > limitDate.value) {
          return false
        } else {
          return true
        }
      }

      const getDefaultData = function (key, start, end, sampling) {
        const samplingStart = moment.utc(start).startOf(sampling)
        const samplingEnd = moment.utc(end).startOf(sampling)

        const nSeconds = SamplingSrv[sampling].seconds
        const dataLength = SamplingSrv.diffCount(samplingEnd, samplingStart, sampling)
        let data = []
        data = __guard__(__range__(0, dataLength, false), (x) =>
          x.map((i) => [moment(samplingStart).add(i * nSeconds, "seconds"), null])
        )

        return {
          key,
          label: `${$scope.isCamera ? $scope.camera.name : $scope.store.name}`,
          data,
          startDate: start,
          endDate: end,
          sampling,
        }
      }

      const loadOccupancyData = (dataSet, opt) => {
        const promiseData = $scope.isCamera
          ? ApiSrv.getOccupancyOfCamera(opt)
          : ApiSrv.getOccupancyOfStore(opt)
        return promiseData.then((rs) => {
          rs?.data.forEach((item) => {
            const key = `${opt.org}/${opt.id}`
            let setIdx = _.findIndex(dataSet, { key: key })
            if (setIdx < 0) {
              // new
              dataSet.push(getDefaultData(key, opt.from, opt.to, opt.sampling))
              setIdx = dataSet.length - 1
            }

            //INFO itemt.at is utc time, it needs to convert to dbtime
            const idx = SamplingSrv.diffCount(moment(item.at).utc(true), opt.from, opt.sampling)
            if (dataSet[setIdx].data[idx]) {
              return (dataSet[setIdx].data[idx][1] = item.count)
            }
          })
        })
      }

      let prevOptions = {}

      const makeExporCsvFilename = function (startDate, endDate) {
        const period = startDate.format("YYYYMMDD") + "-" + endDate.format("YYYYMMDD")
        $scope.gridOptions.filename = $scope.isCamera
          ? `${$scope.store.name}_${$scope.contentCameras[0].name}_${period}`
          : `${$scope.store.name}_all_${period}`

        return ($scope.gridOptions.exporterCsvFilename = $scope.gridOptions.filename + ".csv")
      }

      const setChart = function (dataSet, opt) {
        const dataLength = _.first(dataSet).data.length

        if (dataLength <= 6 * 24) {
          chartOption.xaxis.minTickSize = [1, "hour"]
          chartOption.xaxis.tickFormatter = function (val) {
            const dbtime = moment.utc(val)
            return `${Locale.dateTime(dbtime, "hour")}`
          }
          chartOption.lines.lineWidth = 3
          chartOption.points.show = true
        } else {
          chartOption.xaxis.minTickSize = [1, "day"]
          chartOption.xaxis.tickFormatter = function (val) {
            const dbtime = moment.utc(val)
            return `${Locale.dateTime(dbtime, "day")}`
          }
          chartOption.lines.lineWidth = 1
          chartOption.points.show = false
        }

        $scope.chartData = dataSet
      }

      const setGrid = function (dataSet, opt) {
        const showGridSet = []
        const gridSet = []
        const columnSet = []
        const timeFieldName = ["10m", "hour"].includes(opt.sampling)
          ? Locale.string("time from")
          : Locale.string("time")

        columnSet.push({
          field: "time",
          minWidth: 180,
          cellClass: "text-center",
          displayName: timeFieldName,
        })
        columnSet.push({
          field: "name",
          minWidth: 150,
          cellClass: "text-left",
          displayName: Locale.string("Name"),
        })
        columnSet.push({
          field: "count",
          minWidth: 100,
          cellClass: "text-center",
          displayName: Locale.string("Occupancy Count"),
        })

        const samplingFormat = SamplingSrv[opt.sampling].getFormat()
        const isoFormat = SamplingSrv[opt.sampling].getIsoDayFormat()
        $scope.gridOptions.columnDefs = columnSet

        const { data } = _.first(dataSet)
        for (
          let i = 0, end1 = data.length - 1, asc = 0 <= end1;
          asc ? i <= end1 : i >= end1;
          asc ? i++ : i--
        ) {
          var showTimerange, timerange
          var currentDate = moment(data[i][0])
          var count = data[i][1]
          switch (opt.sampling) {
            case "10m":
              showTimerange = Locale.dateTime(currentDate, isoFormat)
              timerange = Locale.dateTime(currentDate, isoFormat)
              break
            case "hour":
            case "day":
              showTimerange = Locale.dateTime(currentDate, samplingFormat)
              timerange = Locale.dateTime(currentDate, isoFormat)
              break
            default:
              throw new Error("not supported date")
          }

          var gridItem = {}
          gridItem["name"] = $scope.store.name + ($scope.isCamera ? " > " + $scope.camera.name : "")
          gridItem["count"] = count != null ? count : ""

          showGridSet.push(_.merge({}, gridItem, { time: showTimerange }))
          gridSet.push(_.merge({}, gridItem, { time: timerange }))
        }
        $scope.gridOptions.data = showGridSet
        $scope.gridOptions.exportData = gridSet
        if (showGridSet.length > 0) {
          return ($scope.isGridData = true)
        }
      }

      const load = function () {
        if ($scope.contentCameras.length === 0) {
          return
        }

        const { sampling } = $scope.searchOptions
        const startDate = moment($scope.searchOptions.dt.startDate)
        const endDate = moment($scope.searchOptions.dt.endDate)

        makeExporCsvFilename(startDate, endDate)

        const options = {
          org: $stateParams.orgType,
          id: $scope.isCamera ? $scope.camera._id : $scope.store._id,
          from: startDate.format(),
          to: endDate.add(1, "days").startOf("day").format(),
          //sampling: samplingKey(sampling) # 'hour'->'1h'
          sampling: "10m",
        }

        if (_.isEqual(prevOptions, options)) {
          return
        }
        if (!isValidPeriod(startDate, endDate, sampling)) {
          return
        }

        prevOptions = options

        showLoading()

        let dataSet = []

        return loadOccupancyData(dataSet, options)
          .then(function () {
            let isNoData = true
            dataSet.forEach((row) =>
              row.data.forEach(function (item) {
                if (item[1] != null) {
                  return (isNoData = false)
                }
              })
            )

            if (isNoData) {
              dataSet = []
              $scope.chartData = dataSet
              $scope.isGridData = false
              hideLoading()
              return
            }

            setChart(dataSet)

            setGrid(dataSet, options)

            return hideLoading()
          })
          .catch((err) => hideLoading())
      }

      $scope.reload = function (fromInit) {
        if (fromInit == null) {
          fromInit = false
        }
        if (fromInit) {
          $scope.validTime = true
        }
        return load()
      }

      $scope.getCameraName = (label) => label.split("\n")[0]

      $scope.getChartColor = function (data) {
        if (!data.color) {
          return
        }

        return {
          border: `5px solid ${data.color}`,
        }
      }

      const init = function () {
        if ($stateParams.orgType === "sensor") {
          $scope.isCamera = true
        }

        return HeaderSrv.fetchCurrentCompany()
          .then((company) => SelectorSrv.getOrganizationInfo($stateParams.orgType, $stateParams.id))
          .then(function (orgInfo) {
            $scope.store = orgInfo.store
            $scope.camera = orgInfo.sensor
            $scope.storetimezone = orgInfo.store.timezone
            $scope.contentCameras =
              orgInfo.sensors != null
                ? orgInfo.sensors.filter((camera) =>
                    __guard__(
                      camera.functions != null ? camera.functions.use : undefined,
                      (x) => x.occupancy
                    )
                  )
                : undefined
            $scope.contentCameras = $filter("orderBy")($scope.contentCameras, "name")

            return Promise.map(
              $scope.contentCameras,
              (camera) => (camera.storeName = $scope.store.name)
            )
          })
          .then(() => $scope.$broadcast("init-range-search", $scope.storetimezone))
          .catch(function (err) {
            console.error(err)
            return $state.go("default")
          })
      }

      return init()
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
