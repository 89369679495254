/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS202: Simplify dynamic range loops
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .filter(
    "propsFilter",
    () =>
      function (items, props) {
        let out = []
        if (angular.isArray(items)) {
          const keys = Object.keys(props)
          items.forEach(function (item) {
            let itemMatches = false

            for (
              let i = 0, end = keys.length, asc = 0 <= end;
              asc ? i < end : i > end;
              asc ? i++ : i--
            ) {
              var prop = keys[i]
              var text = props[prop].toLowerCase()
              if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                itemMatches = true
                break
              }
            }

            if (itemMatches) {
              return out.push(item)
            }
          })
        } else {
          out = items
        }

        return out
      }
  )
