/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")

  .controller(
    "FirmwareCtrl",
    function ($rootScope, $scope, $resource, $window, Auth, Locale, Upload, NgTableParams, me) {
      if (me.isStore()) {
        return
      }
      $scope.header = {
        name: Locale.string("Name"),
        date: Locale.string("Date"),
        size: Locale.string("Size"),
      }

      $scope.me = me
      const config = $rootScope.globalConfig
      const firmwareUrl = `${config.apipath}/iot/firmware`

      const Firmware = function (node) {
        let path = firmwareUrl + "/:uid" + (node != null ? `/${node}` : "")
        path += `?_=${Date.now()}`
        return $resource(path, {}, { update: { method: "PUT" } })
      }

      $scope.listFiles = () =>
        Firmware().query({ _: Date.now() }, function (res) {
          $scope.tableParams = new NgTableParams(
            { count: 10, sorting: { name: "desc" } },
            { counts: [5, 10, 25], data: res, filterDelay: 0 }
          )
          return ($scope.originalData = angular.copy(res))
        })

      $scope.editFile = (item) => (item.isEditing = true)

      $scope.updateFile = function (item) {
        item.isEditing = false

        return Firmware().update({ uid: item.name }, { description: item.description }, (err) =>
          $scope.reload()
        )
      }

      $scope.cancelFile = function (item) {
        item.isEditing = false
        item.description = _.find($scope.originalData, { _id: item._id }).description
        return $scope.tableParams.reload()
      }

      $scope.removeFile = function (item) {
        if (!$window.confirm(Locale.string("msg_confirm_remove"))) {
          return
        }
        return Firmware().delete({ uid: item.name }, (err) => $scope.reload())
      }

      $scope.onClickFile = function (evt) {
        evt.preventDefault()
        location.href = evt.currentTarget.href

        return null
      }

      const _uploadFile = function (callback) {
        if ($scope.uploadFile) {
          return Upload.upload({
            url: firmwareUrl,
            file: $scope.uploadFile,
          }).then(
            (
              res // success
            ) => callback(),
            function (res) {
              // fail
              const err = { status: res.status, message: `Fail to upload firmware ${res.data}` }
              return callback(err)
            },
            function (evt) {} // progress evt.loaded
          )
        } else {
          return callback()
        }
      }

      $scope.submitForm = () =>
        _uploadFile(function (err) {
          if (err) {
            if (err.status === 409) {
              $scope.errorMessage = Locale.string("msg_error_firmware_duplicated")
            }
            console.info("err", err)
          } else {
            $scope.errorMessage = ""
          }

          return $scope.reload()
        })

      $scope.reload = function () {
        if ($scope.uploadFile) {
          delete $scope.uploadFile
        }
        return $scope.listFiles()
      }

      return $scope.reload()
    }
  )
