"use strict"

module.exports = (maxValue, orgRates, opt) => {
  const _isFloat = (n) => n % 1 !== 0

  var rates
  if (opt == null) {
    opt = "raw"
  }
  if (_isFloat(maxValue)) {
    throw new Error("maxValue must be integer!")
  }
  if (!orgRates.length) {
    console.warn("rates array must not be empty!", maxValue, orgRates)
    throw new Error("rates array must not be empty!")
  }
  if (orgRates.length == 1) {
    return [maxValue]
  }

  const vsum = orgRates.reduce((p, c) => Number(p) + Number(c))
  if (!vsum) return orgRates.map((r) => 0)

  if (opt === "100%") {
    if (vsum !== 100) {
      throw new Error("sum of percentage array must be 100!")
    }
    rates = orgRates.map(function (v) {
      return v / 100
    })
  } else if (opt === "raw") {
    rates = orgRates.map(function (v) {
      return v / vsum
    })
  }
  let frates = rates.map(function (v, idx) {
    let i = 1
    const d = maxValue * v
    if (0 < d && d < 1) {
      // noop
    } else {
      i = Math.round(d)
    }
    return {
      idx: idx,
      value: i,
      r: d,
    }
  })
  const sum = frates.map((f) => f.value).reduce((p, c) => p + c)
  let dx = sum - maxValue
  while (dx !== 0) {
    frates = frates.sort(function (a, b) {
      a.err = a.value === 0 ? Infinity : Math.abs(a.r / a.value)
      b.err = b.value === 0 ? Infinity : Math.abs(b.r / b.value)
      return a.err - b.err
    })
    const delta = dx > 0 ? -1 : +1
    frates[0].value += delta
    frates[0].r += delta
    dx += delta
  }
  frates.sort((a, b) => a.idx - b.idx)
  return frates.map((d) => d.value)
}
