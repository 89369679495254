export const getFootfallFeature = function (f) {
  if (!f.counter) return ""
  if (f.trackcounter) return "tFF"
  if (f.metacounter) return "mFF"
  if (f.facecounter) return "fFF"
  return "FF"
}

export const getSourceTypeFromFeature = function (f) {
  if (f == "tFF") return "CA"
  if (f == "fFF") return "FC"
  // else "FF" or "mFF"
  return "VCA"
}
