/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("accountGroup", () => ({
    templateUrl: "app/admin/account/group/account-group.html",
    controller: "AccountGroupCtrl",
    controllerAs: "vm",

    scope: {
      showSelectedOrganization: "=",
    },
  }))
