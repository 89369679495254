/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS104: Avoid inline assignments
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

angular
  .module("uCountitUiApp")
  .directive(
    "salesOverview",
    (
      $compile,
      $filter,
      ApiSrv,
      ChartOptionSrv,
      Locale,
      SamplingSrv,
      UtilSrv,
      usSpinnerService,
      StoreSalesSrv
    ) => ({
      restrict: "E",
      templateUrl: "components/overview/sales/sales-overview.html",

      scope: {
        orgType: "=",
        orgId: "=",
        referenceDate: "=",
      },

      replace: true,

      link(scope, element, attrs) {
        scope.barChartOption = _.merge({}, ChartOptionSrv.reportBarChartOption, {
          yaxis: {
            min: null,
          },
          legend: {
            show: false,
          },
          grid: {
            aboveData: true,
          },
        })

        scope.lineChartOption = _.merge({}, ChartOptionSrv.contentChartOption, {
          yaxis: {
            min: null,
            autoscaleMargin: 0.02,
          },
          series: {
            lines: {
              show: true,
            },
            points: {
              radius: 0.5,
              fill: true,
              show: true,
            },
            shadowSize: 0,
          },
        })

        scope.dayOfTheWeekChartData = []
        scope.threeMonthChartData = []

        const requestDateFormat = "YYYYMMDD"
        let tomorrow = ""
        let lastWeekAgo = ""
        let twoWeeksAgo = ""
        let threeMonthsAgo = ""
        const loadingQueue = []

        const showLoading = function (key) {
          if (key) {
            loadingQueue.push(key)
          }
          angular.element(".splash").show()
          return usSpinnerService.spin("spinner")
        }

        const hideLoading = function (key) {
          if (key) {
            _.remove(loadingQueue, (raw) => raw === key)
            if (loadingQueue.length) {
              return
            }
          }

          angular.element(".splash").hide()
          return usSpinnerService.stop("spinner")
        }

        const setBarChartOptions = function () {
          const dataLength =
            scope.dayOfTheWeekChartData[0] != null
              ? scope.dayOfTheWeekChartData[0].data.length
              : undefined
          const xaxisTicks = __range__(0, dataLength, false).map((num) => [
            num,
            moment.utc(lastWeekAgo, requestDateFormat).add(num, "day"),
          ])
          scope.barChartOption.xaxis.ticks = (() =>
            xaxisTicks.map((ticks) => [ticks[0], moment.utc(ticks[1]).format("ddd")]))()

          return (scope.barChartOption.tooltip.content = function (label, x, y, item) {
            let left, left1
            const twoWeeksAgoData = scope.dayOfTheWeekChartData[0].data
            const oneWeekAgoData = scope.dayOfTheWeekChartData[1].data
            const tooltipDateFormat = SamplingSrv.day.getFormat()
            const dataIdx = item.dataIndex
            const date = xaxisTicks[dataIdx][1]
            const oneWeekBeforeTime = Locale.dateTime(
              moment.utc(date).subtract(1, "week"),
              tooltipDateFormat
            )
            const datetime = Locale.dateTime(moment.utc(date), tooltipDateFormat)
            const lastWeekValue =
              (left = $filter("number")(twoWeeksAgoData[dataIdx][1], 0)) != null
                ? left
                : Locale.string("No Data")
            const curValue =
              (left1 = $filter("number")(oneWeekAgoData[dataIdx][1], 0)) != null
                ? left1
                : Locale.string("No Data")

            return [
              `<svg width=10 height=10><rect width=5 height=10 fill=${scope.dayOfTheWeekChartData[0].color}></svg>`,
              `${oneWeekBeforeTime} : <b>${lastWeekValue}</b><br>`,
              `<svg width=10 height=10><rect width=5 height=10 fill=${scope.dayOfTheWeekChartData[1].color}></svg>`,
              `${datetime} : <b>${curValue}</b>`,
            ].join("")
          })
        }

        const drawAvgLineOnChart = function () {
          const avgLines = []
          let avgValue = 0
          scope.dayOfTheWeekChartData.forEach(function (value, idx) {
            const sum = _.sumBy(value.data, (n) => n[1])
            avgValue = (sum / value.data.length).toFixed(1)
            return avgLines.push({
              color: value.color,
              lineWidth: 1,
              yaxis: {
                from: avgValue,
                to: avgValue,
              },
            })
          })

          const maxVal = _.maxBy(
            scope.dayOfTheWeekChartData.map((value) => _.maxBy(value.data, (o) => o[1])[1])
          )

          scope.barChartOption.yaxis.ticks = [[0, "0"], [avgValue], [maxVal]]

          return (scope.barChartOption.grid.markings = avgLines)
        }

        const setLineChartOptions = function (from, to) {
          const sampling = "month"
          const tickSize = SamplingSrv[sampling].getTickSize(from, to)
          const minTickSize = SamplingSrv[sampling].getMinTickSize()

          const maxVal = _.maxBy(
            scope.threeMonthChartData.map((value) => _.maxBy(value.data, (o) => o[1])[1])
          )

          scope.lineChartOption.yaxis.ticks = [[0, "0"], [maxVal / 2, ""], [maxVal]]

          scope.lineChartOption.xaxis.tickFormatter = (val, axis) =>
            Locale.dateTime(moment.utc(val), "month")

          scope.lineChartOption.xaxis.tickSize = [tickSize, sampling]
          scope.lineChartOption.xaxis.minTickSize = [minTickSize, sampling]
          scope.lineChartOption.tooltip.content = function (label, x, y) {
            const tooltipDateFormat = SamplingSrv.day.getFormat()
            const datetime = Locale.dateTime(moment.utc(x), tooltipDateFormat)

            return [
              `<svg width=10 height=10><rect width=5 height=10 fill=${scope.threeMonthChartData[0].color}></svg>`,
              `${datetime} : <b>${$filter("number")(y, 0)}</b>`,
            ].join("")
          }

          return
        }

        const drawStartMonthLineOnChart = function (from, to) {
          const startLines = []
          let i = 1

          for (;;) {
            var date = moment.utc(from).startOf("month").add(i, "month")

            if (date.isAfter(to)) {
              break
            }
            var startLine = {
              color: "#ddd",
              lineWidth: 1,
              xaxis: {
                from: date,
                to: date,
              },
            }

            startLines.push(startLine)
            i++
          }

          return (scope.lineChartOption.grid.markings = startLines)
        }

        const drawNoDataOnChart = function (list) {
          if (list == null && !(list != null ? list.length : undefined)) {
            return
          }

          const markings = angular.copy(scope.lineChartOption.grid.markings)
          const noDataLines = []
          list.forEach(function (data, idx) {
            if (data.noData) {
              return noDataLines.push({
                color: "#ededed",
                xaxis: {
                  from: moment(data.date).startOf("day").subtract(1, "day"),
                  to: moment(data.date).startOf("day").add(1, "day"),
                },
              })
            }
          })

          return (scope.lineChartOption.grid.markings = markings.concat(noDataLines))
        }

        const initData = function () {
          scope.dayOfTheWeekChartData = []
          scope.threeMonthChartData = []
          scope.oneWeekAgoCount = Locale.string("No Data")
          scope.refDateCount = Locale.string("No Data")
          return (scope.showComparisonRatio = false)
        }

        const updateData = function () {
          showLoading("sales_data")

          const options = (() => {
            if (scope.orgType === "store") {
              return { storeId: scope.orgId }
            } else if (scope.orgType === "storegroup") {
              return { storeGroupId: scope.orgId }
            } else if (scope.orgType === "company") {
              return { companyId: scope.orgId }
            }
          })()

          const lastWeekOptions = _.merge({}, options, { from: lastWeekAgo, to: tomorrow })
          const twoWeeksAgoOptions = _.merge({}, options, { from: twoWeeksAgo, to: lastWeekAgo })
          const threeMonthsAgoOptions = _.merge({}, options, { from: threeMonthsAgo, to: tomorrow })

          return async.parallel(
            {
              lastWeekAgoData(callback) {
                return StoreSalesSrv.getSalesdata(lastWeekOptions, function (err, sales) {
                  if (err) {
                    callback(err)
                  }
                  return callback(null, sales)
                })
              },
              twoWeeksAgoData(callback) {
                return StoreSalesSrv.getSalesdata(twoWeeksAgoOptions, function (err, sales) {
                  if (err) {
                    callback(err)
                  }
                  return callback(null, sales)
                })
              },
              threeMonthsAgoData(callback) {
                return StoreSalesSrv.getSalesdata(threeMonthsAgoOptions, function (err, sales) {
                  if (err) {
                    callback(err)
                  }
                  return callback(null, sales)
                })
              },
            },
            function (err, results) {
              if (err || !results.threeMonthsAgoData.length) {
                hideLoading("sales_data")
                initData()
                return
              }

              const lastWeekAgoData = UtilSrv.generateData(
                lastWeekAgo,
                tomorrow,
                results.lastWeekAgoData,
                "day",
                "localtime",
                "sales",
                requestDateFormat,
                "localtime"
              )
              const twoWeeksAgoData = UtilSrv.generateData(
                twoWeeksAgo,
                lastWeekAgo,
                results.twoWeeksAgoData,
                "day",
                "localtime",
                "sales",
                requestDateFormat,
                "localtime"
              )
              const threeMonthsAgoData = UtilSrv.generateData(
                threeMonthsAgo,
                tomorrow,
                results.threeMonthsAgoData,
                "day",
                "localtime",
                "sales",
                requestDateFormat,
                "localtime"
              )

              // 맨위 카운트 영역
              const lastWeekCnt = __guard__(_.last(twoWeeksAgoData), (x) => x.count)
              const refDateCnt = __guard__(_.last(lastWeekAgoData), (x1) => x1.count)

              scope.oneWeekAgoCount = $filter("number")(lastWeekCnt, 0) || Locale.string("No Data")
              scope.refDateCount = $filter("number")(refDateCnt, 0) || Locale.string("No Data")
              scope.showComparisonRatio =
                typeof lastWeekCnt === "number" && typeof refDateCnt === "number"
              scope.compareRatio = UtilSrv.getComparisonResult(
                lastWeekCnt,
                refDateCnt
              ).comparisonRatio

              // Day Of The Week Chart 영역
              const twoWeekAgoList = []
              const oneWeekAgoList = []
              ;[0, 1, 2, 3, 4, 5, 6].forEach(function (idx) {
                twoWeekAgoList.push([idx, twoWeeksAgoData[idx].count])
                return oneWeekAgoList.push([idx, lastWeekAgoData[idx].count])
              })

              const dayOfTheWeekChartData = []
              if (
                (results.twoWeeksAgoData != null ? results.twoWeeksAgoData.length : undefined) &&
                (results.lastWeekAgoData != null ? results.lastWeekAgoData.length : undefined)
              ) {
                dayOfTheWeekChartData.push({
                  label: `${Locale.dateTime(
                    moment(twoWeeksAgo, requestDateFormat),
                    "date"
                  )} ~ ${Locale.dateTime(
                    moment(lastWeekAgo, requestDateFormat).subtract(1, "days"),
                    "date"
                  )}`,
                  data: twoWeekAgoList,
                  color: "#c3c3c3",
                  bars: { order: 0 },
                })

                dayOfTheWeekChartData.push({
                  label: `${Locale.dateTime(
                    moment(lastWeekAgo, requestDateFormat),
                    "date"
                  )} ~ ${Locale.dateTime(moment(scope.referenceDate), "date")}`,
                  data: oneWeekAgoList,
                  color: "#3caec9",
                  bars: { order: 1 },
                })

                scope.dayOfTheWeekChartData = dayOfTheWeekChartData

                setBarChartOptions()
                drawAvgLineOnChart()
              } else {
                scope.dayOfTheWeekChartData = []
              }

              // Last 3 Month 영역
              const threeMonthChartList = []
              threeMonthsAgoData.forEach((value) =>
                threeMonthChartList.push([moment.utc(value.date), value.count])
              )

              const threeMonthChartData = []
              threeMonthChartData.push({
                label: Locale.string("Last 12 Weeks"),
                data: threeMonthChartList,
                color: "#15b0d6",
              })

              scope.threeMonthChartData = threeMonthChartData
              const from = moment(threeMonthsAgo, requestDateFormat)
              const to = scope.referenceDate
              setLineChartOptions(from, to)
              drawStartMonthLineOnChart(from, to)
              drawNoDataOnChart(threeMonthsAgoData)

              return hideLoading("sales_data")
            }
          )
        }

        scope.$watch("referenceDate", function (newDate) {
          if (newDate) {
            lastWeekAgo = moment(newDate)
              .subtract(1, "week")
              .add(1, "day")
              .format(requestDateFormat)
            twoWeeksAgo = moment(newDate)
              .subtract(2, "week")
              .add(1, "day")
              .format(requestDateFormat)
            tomorrow = moment(newDate).add(1, "day").format(requestDateFormat)
            threeMonthsAgo = moment(newDate)
              .subtract(12, "week")
              .add(1, "day")
              .format(requestDateFormat)

            scope.oneWeekAgoStr = Locale.dateTime(moment(newDate).subtract(1, "week"), "date")
            scope.refDateStr = Locale.dateTime(moment(newDate, requestDateFormat), "date")

            return updateData()
          }
        })

        return (scope.isNoData = (data) => data && data === Locale.string("no data"))
      },
    })
  )

function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
