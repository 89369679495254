/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

angular
  .module("uCountitUiApp")
  .directive(
    "agegroupOverview",
    ($compile, $filter, Face, Locale, usSpinnerService, HeaderSrv, ApiSrv) => ({
      restrict: "E",
      templateUrl: "components/overview/agegroup/agegroup-overview.html",

      scope: {
        orgType: "=",
        orgId: "=",
        referenceDate: "=",
        useFaceCountOnly: "=",
        viewTemplate: "=?", // default ""
      },

      replace: true,

      link(scope, element, attrs) {
        let refDate = null
        let tomorrow = null
        let lastWeekAgo = null
        let twoWeeksAgo = null
        const loadingQueue = []
        scope.ageTypeList = []
        let unit = scope.useFaceCountOnly ? "%" : ""

        const horizontalBarOption = {
          legend: {
            show: false,
            noColumns: 3,
          },
          xaxis: {
            position: "top",
          },
          yaxis: {
            ticks: [],
          },
          series: {
            bars: { show: true },
          },
          bars: {
            align: "center",
            barWidth: 0.5,
            horizontal: true,
            lineWidth: 1,
            fill: 1,
          },
          grid: {
            show: true,
            borderWidth: { top: 0, right: 0, bottom: 0, left: 2 },
            color: "#c3c3c3",
            hoverable: true,
          },
          tooltip: true,
          tooltipOpts: {
            content(label, x) {
              return `${label} : ${x}${unit}`
            },
            defaultTheme: false,
          },
        }

        scope.isNumber = (num) => !isNaN(parseFloat(num)) && isFinite(num)

        scope.getColumnWidth = function () {
          const width = 100 / scope.ageTypeList.length
          if (!Number.isFinite(width)) {
            return
          }
          return { width: `${width}%` }
        }

        const showLoading = function (key) {
          if (key) {
            loadingQueue.push(key)
          }
          angular.element(".splash").show()
          return usSpinnerService.spin("spinner")
        }

        const hideLoading = function (key) {
          if (key) {
            _.remove(loadingQueue, (raw) => raw === key)
            if (loadingQueue.length) {
              return
            }
          }

          angular.element(".splash").hide()
          return usSpinnerService.stop("spinner")
        }

        const initData = function () {
          scope.weeklyChartData = []
          scope.weeklyData = angular.copy(scope.ageTypeList)
          scope.refDateChartData = []
          return (scope.refDateData = angular.copy(scope.ageTypeList))
        }

        const setBarChartOptions = function (chartData) {
          const _getMaxAgeValue = function (data) {
            const maxAgeGroup = _.maxBy(data, (n) => n.data[0][0])

            return __guard__(
              __guard__(maxAgeGroup != null ? maxAgeGroup.data : undefined, (x1) => x1[0]),
              (x) => x[0]
            ) != null
              ? __guard__(
                  __guard__(maxAgeGroup != null ? maxAgeGroup.data : undefined, (x1) => x1[0]),
                  (x) => x[0]
                )
              : 0
          }

          unit = scope.useFaceCountOnly ? "%" : ""
          const maxCnt = _getMaxAgeValue(chartData)
          const chartOption = _.merge({}, horizontalBarOption)
          chartOption.xaxis.ticks = [
            [0, "0"],
            [maxCnt / 2, ""],
            [maxCnt, $filter("number")(maxCnt) + unit],
          ]
          chartOption.tooltipOpts.content = function (label, x, y, item) {
            let contents = ""
            chartData.forEach(
              (chart, idx) =>
                (contents += [
                  `<svg width=10 height=10><rect width=5 height=10 fill=${chart.color}></svg>`,
                  `${Locale.string(chart.label)} : `,
                  `<b>${$filter("number")(chart.data[0][0]) + unit}</b><br>`,
                ].join(""))
            )

            return contents
          }

          return chartOption
        }

        const updateData = function () {
          showLoading("age_data")

          const options = {
            id: scope.orgId,
            selectedSampling: "day",
            orgType: scope.orgType,
          }

          const DT12Format = "YYYYMMDD0000"
          async.parallel(
            {
              ageGroupData(callback) {
                const option = _.merge({}, options, { from: lastWeekAgo, to: tomorrow })
                return Face.getAgeGroupData(option, scope.ageTypeList, scope.useFaceCountOnly)
                  .then((counter) => callback(null, counter))
                  .catch((err) => callback(err))
              },
              weeklyStayData(callback) {
                if (scope.viewTemplate !== "pathtree") {
                  return callback(null, null)
                }
                const option = {
                  orgType: scope.orgType,
                  id: scope.orgId,
                  sampling: "1d",
                  from: lastWeekAgo.format(DT12Format),
                  to: tomorrow.format(DT12Format),
                }
                ApiSrv.getPathTreeStayData(option)
                  .then((d) => callback(null, d))
                  .catch((err) => callback(err))
              },
              refStayData(callback) {
                if (scope.viewTemplate !== "pathtree") {
                  return callback(null, null)
                }
                const option = {
                  orgType: scope.orgType,
                  id: scope.orgId,
                  sampling: "1d",
                  from: refDate.format(DT12Format),
                  to: tomorrow.format(DT12Format),
                }
                ApiSrv.getPathTreeStayData(option)
                  .then((d) => callback(null, d))
                  .catch((err) => callback(err))
              },
            },
            function (err, results) {
              const ageData = results?.ageGroupData
              if (err || !ageData?.length) {
                hideLoading("age_data")
                initData()
                return
              }
              const weeklyData = angular.copy(scope.ageTypeList)
              const refDateData = angular.copy(scope.ageTypeList)
              const dataLength = ageData.length
              const typeLength = scope.ageTypeList.length
              let weeklyChartData = []
              let refDateChartData = []
              let validDataCount = 0

              const _makeAgeChart = function (res, usePercent) {
                const chartData = []
                res.forEach(function (ageType, idx) {
                  const count = usePercent ? ageType.percent : ageType.count
                  return chartData.push({
                    label: ageType.text,
                    data: [[count, typeLength - idx]],
                    color: ageType.color,
                  })
                })

                return chartData
              }

              __range__(0, dataLength, false).forEach(function (idx) {
                const aData = ageData[idx]
                let total = 0

                weeklyData.forEach(function (ageType, i) {
                  const key = ageType.name

                  if (scope.isNumber(aData != null ? aData.percent[key] : undefined)) {
                    ageType.avgTotal += aData != null ? aData.percent[key] : undefined
                  }
                  if (scope.isNumber(aData[key])) {
                    ageType.total += aData[key]
                    return (total += aData[key])
                  }
                })

                if (total > 0) {
                  return (validDataCount += 1)
                }
              })

              if (validDataCount > 0) {
                weeklyData.forEach(function (ageType, idx) {
                  ageType.count = parseFloat((ageType.total / validDataCount).toFixed(1))
                  ageType.percent = parseFloat((ageType.avgTotal / validDataCount).toFixed(1))

                  if (results.weeklyStayData) {
                    const weeklyStay = results.weeklyStayData?.data?.find(
                      (d) => d.at === lastWeekAgo.format(DT12Format)
                    )
                    const sec = weeklyStay?.data?.ageGroup[idx].stayTime || 0
                    ageType.stayTime = Locale.duration(sec, "short")
                  }
                })

                weeklyChartData = _makeAgeChart(weeklyData, scope.useFaceCountOnly)
              }
              scope.horizontalWeeklyBarOption = setBarChartOptions(weeklyChartData)

              const refData = _.last(ageData)

              refDateData.forEach(function (ageType, idx) {
                const key = ageType.name
                ageType.count = refData[key]
                ageType.percent = refData.percent[key]
                if (results.refStayData) {
                  const refStay = results.refStayData?.data?.find(
                    (d) => d.at === refDate.format(DT12Format)
                  )
                  const sec = refStay?.data?.ageGroup[idx].stayTime || 0
                  ageType.stayTime = Locale.duration(sec, "short")
                }
              })

              if ((refData != null ? refData.total : undefined) > 0) {
                refDateChartData = _makeAgeChart(refDateData, scope.useFaceCountOnly)
              }
              scope.horizontalRefDateBarOption = setBarChartOptions(refDateChartData)

              scope.weeklyData = weeklyData
              scope.weeklyChartData = weeklyChartData
              scope.refDateData = refDateData
              scope.refDateChartData = refDateChartData

              return hideLoading("age_data")
            }
          )
        }

        scope.$watch("referenceDate", function (newDate) {
          if (newDate) {
            lastWeekAgo = moment(newDate).subtract(1, "week").add(1, "day")
            twoWeeksAgo = moment(newDate).subtract(2, "week").add(1, "day")
            refDate = moment(newDate)
            tomorrow = moment(newDate).add(1, "day")

            scope.oneWeekAgoStr = Locale.dateTime(moment(newDate).subtract(1, "week"), "date")
            scope.refDateStr = Locale.dateTime(moment(newDate), "date")

            scope.twoWeeksAgoRangeStr = `${Locale.dateTime(moment(twoWeeksAgo), "date")} ~ ${
              scope.oneWeekAgoStr
            }`
            scope.oneWeekAgoRangeStr = `${Locale.dateTime(moment(lastWeekAgo), "date")} ~ ${
              scope.refDateStr
            }`

            if (scope.ageTypeList.length === 0) {
              return HeaderSrv.fetchCurrentCompany().then(function (company) {
                scope.ageTypeList = Face.makeAgeTypeList(company.ageGroup.value)
                return updateData()
              })
            } else {
              return updateData()
            }
          }
        })

        return
      },
    })
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
