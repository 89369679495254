/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("trackingzoneConfig", () => ({
    templateUrl: "components/trackingzone/trackingzone-config.html",
    controller: "trackingzoneConfigCtrl",

    scope: {
      isOpen: "=",
      selectedItem: "=",
    },
  }))
  .directive("countingareaConfig", () => ({
    templateUrl: "components/trackingzone/countingarea-config.html",
    controller: "countingareaConfigCtrl",

    scope: {
      isOpen: "=",
      selectedItem: "=",
    },
  }))
