/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

angular
  .module("uCountitUiApp")

  .factory("StoreSrv", function ($q, ApiSrv, DateTimeSrv, Auth, RestApi) {
    const getStoreCountDataByTimezone = function (id, timezone) {
      const deffered = $q.defer()

      const dateTime = DateTimeSrv.getLocalTimeByTimezone(timezone)

      const params = {
        id,
        entrance: true,
        from: moment(dateTime).subtract(2, "days").format("YYYYMMDD0000"),
        to: moment(dateTime).add(1, "days").format("YYYYMMDD0000"),
        sampling: "1d",
      }

      ApiSrv.getStoreCountData(params)
        .then(function (response) {
          const today = moment(dateTime).format("YYYYMMDD")
          const yesterday = moment(dateTime).subtract(1, "days").format("YYYYMMDD")
          const desterday = moment(dateTime).subtract(2, "days").format("YYYYMMDD")
          const result = {
            today: 0,
            yesterday: 0,
            desterday: 0,
          }

          if (!response.data) {
            return deffered.resolve(result)
          }

          response.data.forEach(function (raw) {
            switch (moment.utc(raw.statisticFor).format("YYYYMMDD")) {
              case today:
                return (result.today = raw.count)
              case yesterday:
                return (result.yesterday = raw.count)
              case desterday:
                return (result.desterday = raw.count)
            }
          })

          return deffered.resolve(result)
        })
        .catch((err) => deffered.reject(err))

      return deffered.promise
    }

    const getCompareYesterday = function (options) {
      let companies, params
      const deffered = $q.defer()
      if (options.company) {
        companies = [
          {
            _id: options.company._companyId,
            name: options.company.name,
          },
        ]
        params = { companyId: options.company._companyId }
      } else {
        params = {}
      }

      async.waterfall(
        [
          function (callback) {
            if (companies) {
              return callback(null, companies)
            }

            return ApiSrv.getAllCompany().then((response) => callback(null, response))
          },

          (companies, callback) =>
            async.parallel(
              {
                stores(cback) {
                  return ApiSrv.getAllStore(params)
                    .then(function (stores) {
                      for (var store of Array.from(stores)) {
                        store.company = _.find(companies, { _id: store._companyId })
                      }
                      return cback(null, stores)
                    })
                    .catch(function (err) {
                      if (err.status === 404) {
                        return cback(null, [])
                      }
                      return cback(err)
                    })
                },

                groups(cback) {
                  return ApiSrv.getAllStoreGroup(params)
                    .then(function (groups) {
                      for (var group of Array.from(groups)) {
                        group.company = _.find(companies, { _id: group._companyId })
                        group.group = true
                      }
                      return cback(null, groups)
                    })
                    .catch(function (err) {
                      if (err.status === 404) {
                        return cback(null, [])
                      }
                      return cback(err)
                    })
                },
              },
              (err, results) => callback(err, results.stores, results.groups)
            ),
        ],
        (err, stores, groups) =>
          async.parallel(
            {
              storeCounts(callback) {
                return async.each(
                  stores,
                  (store, callback) =>
                    store
                      .getFunctionality()
                      .then(function (functionalities) {
                        store.hasCounter = functionalities.counter
                        if (store.hasCounter) {
                          return getStoreCountDataByTimezone(
                            store._id,
                            store != null ? store.timezone : undefined
                          ).then(function (res) {
                            store.count = res
                            return callback()
                          })
                        } else {
                          return callback()
                        }
                      })
                      .catch((err) => callback(err)),
                  (err) => callback(err, stores)
                )
              },
              groupCounts(callback) {
                return async.each(
                  groups,
                  function (group, callback) {
                    group.timezone = DateTimeSrv.getTimezoneofStores(group.storeList)
                    params = { id: group._id }
                    params.dateTime = DateTimeSrv.getLocalTimeByTimezone(group.timezone)
                    group.active = true
                    return group
                      .getFunctionality()
                      .then(function (functionalities) {
                        group.hasCounter = functionalities.counter
                        if (group.hasCounter) {
                          return ApiSrv.getStoreGroupCompareYesterday(params).then(function (
                            response
                          ) {
                            group.count = response
                            return callback()
                          })
                        } else {
                          return callback()
                        }
                      })
                      .catch((err) => callback(err))
                  },
                  (err) => callback(err, groups)
                )
              },
            },
            function (err, result) {
              if (err) {
                return deffered.reject(err)
              }
              const list =
                result.groupCounts != null
                  ? result.groupCounts.concat(result.storeCounts)
                  : undefined
              return deffered.resolve(list)
            }
          )
      )

      return deffered.promise
    }

    return {
      getCompareYesterday,
    }
  })
