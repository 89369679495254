/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("ageGroupSelector", () => ({
    restrict: "E",
    templateUrl: "components/age-group-selector/age-group-selector.html",
    controller: "AgeGroupSelectorCtrl",

    scope: {
      isCreate: "=",
      ageGroup: "=",
      canApply: "=",
      onApply: "=?",
    },
  }))
