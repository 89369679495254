// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../../.yarn/__virtual__/html-loader-virtual-ce9d30f266/0/cache/html-loader-npm-4.1.0-8e88fdeeb9-d544cecb05.zip/node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("/assets/favicons/android-chrome-192x192.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/assets/favicons/manifest.json", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/assets/favicons/apple-touch-icon-114x114.png", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/assets/favicons/apple-touch-icon-120x120.png", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/assets/favicons/apple-touch-icon-144x144.png", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("/assets/favicons/apple-touch-icon-152x152.png", import.meta.url);
var ___HTML_LOADER_IMPORT_6___ = new URL("/assets/favicons/apple-touch-icon-180x180.png", import.meta.url);
var ___HTML_LOADER_IMPORT_7___ = new URL("/assets/favicons/apple-touch-icon-57x57.png", import.meta.url);
var ___HTML_LOADER_IMPORT_8___ = new URL("/assets/favicons/apple-touch-icon-60x60.png", import.meta.url);
var ___HTML_LOADER_IMPORT_9___ = new URL("/assets/favicons/apple-touch-icon-72x72.png", import.meta.url);
var ___HTML_LOADER_IMPORT_10___ = new URL("/assets/favicons/apple-touch-icon-76x76.png", import.meta.url);
var ___HTML_LOADER_IMPORT_11___ = new URL("/assets/favicons/favicon-16x16.png", import.meta.url);
var ___HTML_LOADER_IMPORT_12___ = new URL("/assets/favicons/favicon-230x230.png", import.meta.url);
var ___HTML_LOADER_IMPORT_13___ = new URL("/assets/favicons/favicon-32x32.png", import.meta.url);
var ___HTML_LOADER_IMPORT_14___ = new URL("/assets/favicons/favicon-96x96.png", import.meta.url);
var ___HTML_LOADER_IMPORT_15___ = new URL("/assets/favicons/favicon.ico", import.meta.url);
var ___HTML_LOADER_IMPORT_16___ = new URL("/assets/favicons/mstile-144x144.png", import.meta.url);
var ___HTML_LOADER_IMPORT_17___ = new URL("/assets/favicons/browserconfig.xml", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var ___HTML_LOADER_REPLACEMENT_5___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_5___);
var ___HTML_LOADER_REPLACEMENT_6___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_6___);
var ___HTML_LOADER_REPLACEMENT_7___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_7___);
var ___HTML_LOADER_REPLACEMENT_8___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_8___);
var ___HTML_LOADER_REPLACEMENT_9___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_9___);
var ___HTML_LOADER_REPLACEMENT_10___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_10___);
var ___HTML_LOADER_REPLACEMENT_11___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_11___);
var ___HTML_LOADER_REPLACEMENT_12___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_12___);
var ___HTML_LOADER_REPLACEMENT_13___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_13___);
var ___HTML_LOADER_REPLACEMENT_14___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_14___);
var ___HTML_LOADER_REPLACEMENT_15___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_15___);
var ___HTML_LOADER_REPLACEMENT_16___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_16___);
var ___HTML_LOADER_REPLACEMENT_17___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_17___);
var code = "<link rel=\"icon\" type=\"image/png\" sizes=\"192x192\" href=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\">\n<link rel=\"manifest\" href=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\">\n<meta name=\"mobile-web-app-capable\" content=\"yes\">\n<link rel=\"apple-touch-icon\" sizes=\"114x114\" href=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\">\n<link rel=\"apple-touch-icon\" sizes=\"120x120\" href=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\">\n<link rel=\"apple-touch-icon\" sizes=\"144x144\" href=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\">\n<link rel=\"apple-touch-icon\" sizes=\"152x152\" href=\"" + ___HTML_LOADER_REPLACEMENT_5___ + "\">\n<link rel=\"apple-touch-icon\" sizes=\"180x180\" href=\"" + ___HTML_LOADER_REPLACEMENT_6___ + "\">\n<link rel=\"apple-touch-icon\" sizes=\"57x57\" href=\"" + ___HTML_LOADER_REPLACEMENT_7___ + "\">\n<link rel=\"apple-touch-icon\" sizes=\"60x60\" href=\"" + ___HTML_LOADER_REPLACEMENT_8___ + "\">\n<link rel=\"apple-touch-icon\" sizes=\"72x72\" href=\"" + ___HTML_LOADER_REPLACEMENT_9___ + "\">\n<link rel=\"apple-touch-icon\" sizes=\"76x76\" href=\"" + ___HTML_LOADER_REPLACEMENT_10___ + "\">\n<meta name=\"apple-mobile-web-app-capable\" content=\"yes\">\n<link rel=\"icon\" type=\"image/png\" sizes=\"16x16\" href=\"" + ___HTML_LOADER_REPLACEMENT_11___ + "\">\n<link rel=\"icon\" type=\"image/png\" sizes=\"230x230\" href=\"" + ___HTML_LOADER_REPLACEMENT_12___ + "\">\n<link rel=\"icon\" type=\"image/png\" sizes=\"32x32\" href=\"" + ___HTML_LOADER_REPLACEMENT_13___ + "\">\n<link rel=\"icon\" type=\"image/png\" sizes=\"96x96\" href=\"" + ___HTML_LOADER_REPLACEMENT_14___ + "\">\n<link rel=\"shortcut icon\" href=\"" + ___HTML_LOADER_REPLACEMENT_15___ + "\">\n<meta name=\"msapplication-TileColor\" content=\"#FFFFFF\">\n<meta name=\"msapplication-TileImage\" content=\"" + ___HTML_LOADER_REPLACEMENT_16___ + "\">\n<meta name=\"msapplication-config\" content=\"" + ___HTML_LOADER_REPLACEMENT_17___ + "\">\n";
// Exports
export default code;