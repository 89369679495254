/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import Promise from "bluebird"
import moment from "moment-timezone"

angular
  .module("uCountitUiApp")

  .controller(
    "RebuildStatusCtrl",
    function (
      $rootScope,
      $cookieStore,
      $scope,
      $filter,
      $window,
      $interval,
      ApiSrv,
      Locale,
      NgTableParams,
      me,
      usSpinnerService,
      ngDialog
    ) {
      $scope.me = me
      if (!me.gteSpace()) {
        return
      }

      const kAutoRefreshTime = 10 * 1000 // 10 sec
      const kDateFormat = "YYYY-MM-DD HH:mm"

      let cachePageData = {}
      let intervalIdOfProgress = null
      let intervalIdOfStatus = null

      $scope.companies = []
      $scope.stores = []
      $scope.cameras = []
      $scope.Items = []
      $scope.totalCount = 0
      $scope.errMessage = ""

      $scope.header = {
        type: Locale.string("Org"),
        name: Locale.string("Name"),
        content: Locale.string("Content"),
        from: Locale.string("From") + "(<=)",
        to: Locale.string("To") + "(<)",
        status: Locale.string("Status"),
        startAt: Locale.string("Start At"),
        processingTime: Locale.string("Processing Time"),
        ops: Locale.string("Ops"),
      }

      $scope.page = {
        curPage: 1,
        displayCount: 30,
        maxPageSize: 5,
      }

      $scope.reload = function () {
        cachePageData = {}
        return $scope.changePage()
      }

      $scope.load = () =>
        Promise.all([
          ApiSrv.getAllCompany({ isCache: true }),
          ApiSrv.getAllStore({ isCache: true }),
          ApiSrv.getAllCamera({ isCache: true }),
        ])
          .spread(function (companies, stores, cameras) {
            $scope.companies = companies
            $scope.stores = stores
            $scope.cameras = cameras
            poolRebuildStatus()
            return pollRebuildProgress()
          })
          .catch((err) => console.error("[Load Error]: ", err))

      $scope.changePage = function () {
        if (cachePageData[$scope.page.curPage]) {
          return ($scope.data = cachePageData[$scope.page.curPage])
        } else {
          return Promise.all([
            ApiSrv.getRebuildStatusCount(),
            ApiSrv.getRebuildStatus({ page: $scope.page.curPage, limit: $scope.page.displayCount }),
          ])
            .spread(function ({ count }, data) {
              $scope.totalCount = count
              return ($scope.data = cachePageData[$scope.page.curPage] = data.map(changeFormat))
            })
            .catch((err) => console.error("[changePage Error]: ", err))
        }
      }

      $scope.deleteRebuildStatus = function (item) {
        if (!$window.confirm(Locale.string("msg_confirm_remove"))) {
          return
        }

        return ApiSrv.deleteRebuildStatus({ id: item._id })
          .then(() => $scope.reload())
          .catch(function (err) {
            console.error("[Delete rebuild status Error]: ", err)
            return ($scope.errMessage = err.statusText)
          })
      }

      $scope.cancelRebuildStatus = function (item) {
        if (!$window.confirm(Locale.string("msg_confirm_remove"))) {
          return
        }

        return ApiSrv.cancelRebuildStatus({ id: item._id })
          .then(() => $scope.reload())
          .catch(function (err) {
            console.error("[Cancel rebuild status Error]: ", err)
            return ($scope.errMessage = err.statusText)
          })
      }

      $scope.clearErrMessage = () => ($scope.errMessage = "")

      const makeOrgName = function (org) {
        let company, store
        if (org.type === "camera") {
          const camera = $scope.cameras.find((c) => c._id === org._id)
          if (!camera) {
            return "Deleted Sensor"
          }
          store = $scope.stores.find((s) => s._id === camera._storeId)
          if (!store) {
            return `Deleted Store > ${camera.name}`
          }
          company = $scope.companies.find((c) => c._id === store._companyId)
          if (!company) {
            return `Deleted Company > ${store.name} > ${camera.name}`
          }
          return `${company.name} > ${store.name} > ${camera.name}`
        } else if (org.type === "store") {
          store = $scope.stores.find((s) => s._id === org._id)
          if (!store) {
            return "Deleted Store"
          }
          company = $scope.companies.find((c) => c._id === store._companyId)
          if (!company) {
            return `Deleted Company > ${store.name}`
          }
          return `${company.name} > ${store.name}`
        } else {
          company = $scope.companies.find((c) => c._id === org._id)
          if (!company) {
            return "Deleted Company"
          }
          return `${company.name}`
        }
      }

      var changeFormat = (item) =>
        Object.assign({}, item, {
          org: Object.assign({}, item.org, {
            type: _.capitalize(item.org.type),
          }),
          name: makeOrgName(item.org),
          from: moment.utc(item.from).format(kDateFormat),
          to: moment.utc(item.to).format(kDateFormat),
          content: _.capitalize(item.content),
          startAt: item.startAt ? moment(item.startAt).format(kDateFormat) : "-",
          processingTime:
            item.processingTime >= 0
              ? moment.utc(item.processingTime * 1000).format("HH:mm:ss")
              : "-",
          status: _.capitalize(item.status),
        })

      const makeProgressMsg = function (cpu, mem, progress) {
        const { total } = progress.reduce(
          function (acc, p) {
            acc.total.count += p.total.count
            acc.total.done += p.total.done
            return acc
          },
          { total: { count: 0, done: 0 } }
        )
        const percentage = total.count === 0 ? 0 : parseInt((total.done / total.count) * 100)

        return [
          `Progress: ${total.done}/${total.count}(${percentage}%)`,
          `Cpu: ${parseInt(cpu)}%`,
          `Mem: ${parseInt(mem.usedMemPercentage)}%`,
        ].join(", ")
      }

      const getRebuildProgress = () =>
        ApiSrv.getRebuildProgress()
          .then(
            ({ cpu, mem, progress }) => ($scope.progressMsg = makeProgressMsg(cpu, mem, progress))
          )
          .catch((err) => console.error("[Cancel rebuild status Error]: ", err))

      var poolRebuildStatus = function () {
        $scope.reload()
        return (intervalIdOfStatus = $interval($scope.reload, kAutoRefreshTime))
      }

      var pollRebuildProgress = function () {
        getRebuildProgress()
        return (intervalIdOfProgress = $interval(getRebuildProgress, kAutoRefreshTime))
      }

      $scope.$on("$destroy", function () {
        if (intervalIdOfStatus) {
          $interval.cancel(intervalIdOfStatus)
        }
        if (intervalIdOfProgress) {
          return $interval.cancel(intervalIdOfProgress)
        }
      })

      return $scope.load()
    }
  )
