import angular from "angular"
import Sensor from "../../../lib/org/sensor"

angular
  .module("uCountitUiApp")
  .controller("TrackingTreeCtrl", ($scope, usSpinnerService, HeaderSrv) => {
    // This page just pass Organization information to iframe
    return (async () => {
      const sensor = await HeaderSrv.fetchCurrentSensor()
      if (sensor.orgType == Sensor.ORG_TYPE) {
        if (!sensor.functions.use.trackingtree) {
          return
        }
        usSpinnerService.spin("spinner")
        $scope.onLoad = () => {
          usSpinnerService.stop("spinner")
        }
        $scope.trackingTreeSrc = `/v2/embed/contents/tracking-tree/sensors/${sensor._id}`
      }
    })()
  })
