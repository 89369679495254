"use strict"

import env from "../../../config/environment"

// eslint-disable-next-line no-undef
angular
  .module("uCountitUiApp")
  .controller("DataDownloadCtrl", ($scope, $filter, HeaderSrv, ApiSrv, Locale, $timeout) => {
    $scope.isLocalgrey = env.isLocalgrey
    const dateLimit = 6
    $scope.maxPeriodMsg = `${Locale.string("Max Period")}: ${dateLimit} ${Locale.string("Months")}`

    $scope.weeklyFormatMsg = `${Locale.string("Weekly")} ${Locale.string(
      "format"
    )}: ${Locale.string("Start date")}(${Locale.string("Monday")}) - ${Locale.string(
      "End date"
    )}(${Locale.string("Sunday")})`

    $scope.contentsList = [
      { name: Locale.string("All"), exportValue: "all", value: "all" },
      { name: Locale.string("Footfall"), exportValue: "Footfall", value: "counter" },
      { name: Locale.string("Zone Traffic"), exportValue: "Roimap", value: "zonetraffic" },
      { name: Locale.string("Occupancy"), exportValue: "OccupancyCount", value: "occupancy" },
      { name: Locale.string("Flow Map"), exportValue: "Flowmap", value: "flowmap" },
    ]
    if (!$scope.isLocalgrey) {
      $scope.contentsList.push({
        name: Locale.string("Gender/Age"),
        exportValue: "Face",
        value: "face",
      })
    }
    for (const item of [
      { name: Locale.string("Heatmap Image"), exportValue: "HeatmapImg", value: "heatmapimg" },
      { name: Locale.string("Snapshot"), exportValue: "Snapshot", value: "snapshot" },
    ]) {
      $scope.contentsList.push(item)
    }

    $scope.contents = { selected: $scope.contentsList[0] }
    $scope.company = { selected: null, functionality: {} }
    $scope.isValidDate = true
    $scope.sampling = "week"

    let startDate = moment().startOf("isoweek").add(-1, "week").startOf("day")
    let endDate = startDate.clone().add(6, "day")

    $scope.setDateTimePicker = () => {
      const options = {
        selectRange: $scope.sampling,
        startDate,
        endDate,
        dateLimit: { months: dateLimit },
        minDate: "2010-01-01",
        maxDate: moment().startOf("day").add(-1, "day"),
        locale: { format: "YYYY-MM-DD" },
        showDropdowns: true,
        showWeekNumbers: true,
      }

      $('input[name="datetime"]').daterangepicker2(options, (start, end) => {
        startDate = start
        endDate = end

        $scope.clearCheckedList(true)
        validateDate(true)
      })
    }

    const validateDate = (needApply = false) => {
      if ($scope.sampling == "week") {
        if (startDate.isoWeekday() != 1 || endDate.isoWeekday() != 7) {
          $scope.isValidDate = false
          $scope.errorMsg = $scope.weeklyFormatMsg
          if (needApply) $scope.$apply()
          return
        }
      }

      $scope.errorMsg = ""
      $scope.isValidDate = true
      if (needApply) $scope.$apply()
    }

    $scope.groupByCompany = (company) => {
      return company.agency.name
    }

    $scope.canSubmit = (form) => {
      return $scope[form].$valid && $scope.isChecked && !$scope.disableSubmit && $scope.isValidDate
    }

    $scope.clearCheckedList = (needApply = false) => {
      $scope.rows = []
      $scope.isChecked = false
      if (needApply) $scope.$apply()
    }

    $scope.changeRadio = () => {
      $scope.clearCheckedList()
      validateDate()
      grayOutContents($scope.company.functionality)
    }

    $scope.changeCompany = async (company) => {
      if (!company.exportData || !company.exportData.active) {
        $scope.errorMsg = Locale.string("msg_not_support_data_download")
        $scope.disableSubmit = true
      } else {
        $scope.errorMsg = ""
        $scope.disableSubmit = false
      }

      $scope.clearCheckedList()

      try {
        const functionality = await $scope.company.selected.getFunctionality()
        $scope.company.functionality = functionality
        $scope.company.functionality.heatmapimg = functionality.heatmap || functionality.dwellmap
        $scope.company.functionality.snapshot = true
        grayOutContents($scope.company.functionality)
      } catch (error) {
        $scope.company.functionality = {}
      }
    }

    $scope.submitForm = () => {
      $scope.isDownloading = true
      $scope.errorMsg = ""

      const params = {
        companyId: $scope.company.selected._id,
        from: startDate.format("YYYY-MM-DD"),
        to: endDate.clone().add(1, "day").format("YYYY-MM-DD"),
        contents: $scope.contents.selected.exportValue,
        sampling: $scope.sampling,
      }

      let url = "/api/1.0/datadownload?" + $.param(params)
      downloadFile(url)

      $timeout(() => {
        $scope.isDownloading = false
      }, 3000)
    }

    const downloadFile = (url) => {
      // eslint-disable-next-line no-undef
      const link = angular.element("<a/>")
      link
        .attr({
          href: url,
          target: "_self",
        })[0]
        .click()
    }

    $scope.checkContents = () => {
      if ($scope.disableSubmit || $scope.isChecking || !$scope.isValidDate) return
      $scope.isChecking = true

      ApiSrv.checkDataDownload({
        companyId: $scope.company.selected._id,
        from: startDate.format("YYYY-MM-DD"),
        to: endDate.clone().add(1, "day").format("YYYY-MM-DD"),
        contents: "all",
        sampling: $scope.sampling,
      })
        .then((res) => {
          $scope.rows = checkFunctionality(res.data)
          if ($scope.rows.length == 0) {
            $scope.checkResult = {
              msg: Locale.string("Ok"),
              isSuccess: true,
            }
          } else {
            $scope.checkResult = {
              msg: Locale.string("msg_warning_check_download"),
              isSuccess: false,
            }
          }

          $scope.isChecked = true
          $scope.isChecking = false
        })
        .catch((err) => {
          $scope.errorMsg = err.message || err.statusText
          $scope.isChecking = false
        })
    }

    const checkFunctionality = (rows) => {
      const functionality = $scope.company.functionality

      return rows.filter((row) => {
        const contentsList = Object.keys(row)
        for (const contents of contentsList) {
          if (contents == "datetime") continue
          if ($scope.sampling == "day" && (contents == "heatmapimg" || contents == "snapshot"))
            continue

          if (functionality[contents] && !row[contents]) return true
        }
      })
    }

    $scope.showColumn = (contents) => {
      const functionality = $scope.company.functionality
      const selectedContents = $scope.contents.selected.value
      if (!selectedContents || !functionality) return false

      if ($scope.sampling == "day" && (contents == "heatmapimg" || contents == "snapshot"))
        return false

      const active = functionality[contents]
      return selectedContents == "all" ? active : active && contents == selectedContents
    }

    const grayOutContents = (functionality) => {
      $scope.contentsList.forEach((contents) => {
        contents.grayOut = !functionality[contents.value]

        if (contents.value == "all") contents.grayOut = false
        if ($scope.sampling == "day") {
          if (contents.value == "heatmapimg" || contents.value == "snapshot")
            contents.grayOut = true
        }
      })
    }

    $scope.getLocaleDatetime = (datetime) => {
      return Locale.dateTime(datetime)
    }

    const init = async () => {
      try {
        $scope.setDateTimePicker()

        let companies = await HeaderSrv.fetchAllCompany()
        companies = $filter("orderBy")(companies, ["name"])
        $scope.filteredAllCompanies = (keyword) => {
          return $filter("filter")(companies, (company) => {
            const str = new RegExp(`.*${keyword}.*`, "i")
            return company.name.match(str) != null
          })
        }

        $scope.company.selected = await HeaderSrv.fetchCurrentCompany()
        $scope.changeCompany($scope.company.selected)
      } catch (error) {
        console.error(error)
      }
    }

    init()
  })
