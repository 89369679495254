import angular from "angular"
import { react2angular } from "react2angular"
import { createApp } from "../../../components/react"
import ActivityLogPage from "./ActivityLogPage"

angular
  .module("uCountitUiApp")
  .component(
    "activityLogPage",
    react2angular(
      createApp(ActivityLogPage),
      [],
      ["$state", "$stateParams", "$transitions", "$http"]
    )
  )

angular.module("uCountitUiApp").config(($stateProvider) => {
  $stateProvider.state("app.system.activity-log", {
    url: "/activity-log?storeId&startDate&endDate",
    params: {
      storeId: {
        dynamic: true,
      },
      startDate: {
        dynamic: true,
      },
      endDate: {
        dynamic: true,
      },
    },
    template: "<section><activity-log-page/></section>",
  })
})
