import _ from "lodash"

export const createCrud = ($http, path) => {
  const cacheOptions = (cache, options) => {
    if (!cache) {
      return _.merge(
        {
          params: {
            _: Date.now(),
          },
        },
        options
      )
    }
    return options
  }

  return {
    index(cache = true, options = {}) {
      return Promise.resolve(
        $http
          .get(path, {
            json: true,
            ...cacheOptions(cache, options),
          })
          .then((response) => response.data)
      )
    },
    get(id, cache = true, options = {}) {
      if (!id) return Promise.reject(new Error("No id assigned"))
      return Promise.resolve(
        $http
          .get(`${path}/${id}`, {
            json: true,
            ...cacheOptions(cache, options),
          })
          .then((response) => response.data)
      )
    },
    create(value, options = {}) {
      return Promise.resolve(
        $http
          .post(path, value, {
            json: true,
            ...options,
          })
          .then((response) => response.data)
      )
    },
    update(id, value, options = {}) {
      if (!id) return Promise.reject(new Error("No id assigned"))
      return Promise.resolve(
        $http
          .put(`${path}/${id}`, value, {
            json: true,
            ...options,
          })
          .then((response) => response.data)
      )
    },
    delete(id, options = {}) {
      if (!id) return Promise.reject(new Error("No id assigned"))
      return Promise.resolve(
        $http
          .delete(`${path}/${id}`, {
            json: true,
            ...options,
          })
          .then((response) => response.data)
      )
    },
  }
}

export default createCrud
