/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory("DataOperationSrv", function ($resource) {
    const url =
      "/api/1.0/dataOperation/:contents?" +
      "operation=:operation&from=:from&to=:to&srcId=:srcId&dstId=:dstId&isRebuildExport=:isRebuildExport"

    return $resource(
      url,
      {
        contents: "@contents",
        operation: "@operation",
        from: "@from",
        to: "@to",
        srcId: "@srcId",
        dstId: "@dstId",
        isRebuildExport: "@isRebuildExport",
      },
      {
        update: {
          method: "PUT",
        },
      }
    )
  })
