import config from "../../../config/environment"
const brand = config.brand ? config.brand : "ucountit"

const req = require.context("!raw-loader!./", true, /terms_(.+)_(.+).txt$/)
const exp = {}
req.keys().forEach((key) => {
  const match = key.match(/terms_(.+)_(.+).txt$/)
  if (!match || match[1] != brand) return
  exp[match[2]] = req(key)
})
export default exp
