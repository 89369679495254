import React, { useState } from "react"
import { useFieldApi } from "informed"
import SelectField from "../../../../components/react/SelectField"
import CompanySelectField from "../../../../components/react/CompanySelectField.jsx"
import StoreSelectField from "../../../../components/react/StoreSelectField"
import SensorSelectField from "../../../../components/react/SensorSelectField"
import propTypes from "prop-types"

const SensorOrganizationFilterParameter = ({ error }) => {
  const fieldApi = useFieldApi("trigger.parameter.orgType")
  const [orgType, setOrgType] = useState(fieldApi.getValue())
  if (!orgType) {
    if (fieldApi.getValue()) setOrgType(fieldApi.getValue())
  }
  const renderOrgSelector = () => {
    if (orgType == "company") {
      return <CompanySelectField field="trigger.parameter.orgId" />
    } else if (orgType == "store") {
      return <StoreSelectField field="trigger.parameter.orgId" />
    } else if (orgType == "sensor") {
      return <SensorSelectField field="trigger.parameter.orgId" />
    } else {
      return null
    }
  }
  const handleOrgTypeChange = (type) => {
    setOrgType(type.value)
  }
  return (
    <>
      <div className={`form-group ${error && error.method ? "has-error" : null}`}>
        <label className="control-label col-sm-3">Organization Type</label>
        <div className="col-sm-9">
          <SelectField
            onChange={handleOrgTypeChange}
            field="trigger.parameter.orgType"
            options={[
              { label: "Company", value: "company" },
              {
                label: "Store",
                value: "store",
              },
              {
                label: "Sensor",
                value: "sensor",
              },
            ]}
          />
          {error && error.orgType ? <span className="help-block">{error.orgType}</span> : null}
        </div>
      </div>
      {orgType ? (
        <div className="form-group">
          <label className="control-label col-sm-3">Organization</label>
          <div className="col-sm-9">{renderOrgSelector()}</div>
        </div>
      ) : null}
    </>
  )
}

SensorOrganizationFilterParameter.propTypes = {
  error: propTypes.object,
}

export default SensorOrganizationFilterParameter
