/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

import env from "../../../config/environment"

angular
  .module("uCountitUiApp")
  .controller(
    "StoreGroupOverviewCtrl",
    function (
      $scope,
      $state,
      $stateParams,
      DateTimeSrv,
      SelectorSrv,
      Auth,
      HeaderSrv,
      me,
      UtilSrv
    ) {
      $scope.me = me
      HeaderSrv.fetchCurrentCompany().then((company) => ($scope.company = company))

      $scope.orgType = $stateParams.orgType
      $scope.orgId = $stateParams.id
      $scope.pageOptions = {
        sampling: "day",
        referenceDay: undefined,
        content: "overview",
      }

      $scope.useFootfall = false
      $scope.useFace = false
      $scope.useHeatmap = false
      $scope.useSales = false

      $scope.displayName = UtilSrv.getDisplayStoreGroupName

      const init = () =>
        SelectorSrv.getOrganizationInfo($stateParams.orgType, $stateParams.id)
          .then(function (orgInfo) {
            const { isLocalgrey } = env
            $scope.currentOrg = orgInfo.store
            $scope.timezone = orgInfo.timezone
            $scope.stores = orgInfo.stores

            $scope.useFootfall = orgInfo.features.footfall
            $scope.useFace = orgInfo.features.face && !isLocalgrey
            $scope.useHeatmap = orgInfo.features.heatmap || orgInfo.features.dwellmap
            $scope.useSales = orgInfo.features.sales && !isLocalgrey
            $scope.useFaceCountOnly = orgInfo.useFaceCountOnly && !isLocalgrey

            $scope.noFeatures = true
            return _.forEach(orgInfo.features, function (feature) {
              if (feature === true) {
                return ($scope.noFeatures = false)
              }
            })
          })
          .then(
            () => $scope.$broadcast("init-date-search", $scope.timezone),

            function (err) {
              if (err) {
                console.error(err)
              }
              return $state.go("default")
            }
          )

      return angular.element(() => init())
    }
  )
