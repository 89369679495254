/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

const App = angular.module("uCountitUiApp")
const Promise = require("bluebird")

App.controller(
  "AccountGroupCtrl",
  function (
    $scope,
    $uibModal,
    $filter,
    $window,
    Auth,
    ApiSrv,
    Locale,
    NgTableParams,
    AccountGroupSrv,
    HeaderSrv
  ) {
    const vm = this
    $scope.vm = vm
    const { me } = $scope.$parent
    vm.me = me
    vm.header = {
      company: Locale.string("Company"),
      groups: Locale.string("Account Group"),
      members: Locale.string("Members"),
      stores: Locale.string("Stores"),
    }

    vm.searchKeywords = ""
    vm.Items = ""
    vm.orderItems = ""

    vm.enterKeyword = function (keyword) {
      vm.searchKeywords = keyword
      return $scope.tableReload()
    }

    vm.hasDAccountGroupTable = {}
    const hasDAccountGroup = (accountGroup) =>
      me
        .hasDAccountGroupId(accountGroup._id)
        .then((bool) => (vm.hasDAccountGroupTable[accountGroup._id] = bool))

    $scope.dataReload = (isCache) =>
      async.waterfall(
        [
          (callback) =>
            ApiSrv.getAllCompany().then(
              function (res) {
                $scope.companies = _.map(res, (element) => ({
                  _id: element._id,
                  name: element.name,
                }))
                return callback(null, $scope.companies)
              },
              (err) => callback(err)
            ),
        ],
        function (err, companies) {
          if (err) {
            console.error(`getAllCompany Error: ${err}`)
            // $defer is invalid
            //$defer.reject(err)
            return
          }

          return ApiSrv.getAllAccountGroup({ isCache })
            .then((res) =>
              HeaderSrv.fetchCurrentCompany()
                .then(function (company) {
                  const accountGroups = $filter("filter")(res, function (group) {
                    if (company) {
                      return (
                        $scope.showSelectedOrganization === false ||
                        company._companyId === group._companyId
                      )
                    }
                  })

                  _.forEach(
                    accountGroups,
                    (group) =>
                      (group.companyName = __guard__(
                        _.find(companies, { _id: group._companyId }),
                        (x) => x.name
                      ))
                  )
                  vm.Items = accountGroups
                  vm.orderItems = ""
                  return Promise.all(accountGroups.map((ag) => hasDAccountGroup(ag)))
                })
                .then(() => $scope.tableReload())
            )
            .catch(function (err) {
              console.error(`getAllAccountGroup Error: ${err}`)
              return Promise.reject(err)
            })
        }
      )

    $scope.$watch("showSelectedOrganization", (newData) => $scope.dataReload(true))

    $scope.$on("reload_accountgroup", () => $scope.dataReload(false))

    const _getData = function ($defer, params) {
      if (vm.orderItems === "") {
        vm.orderItems = vm.Items
      }
      const sorting = params.sorting()
      for (var key in sorting) {
        var reverse = sorting[key] === "desc"
        vm.orderItems = $filter("orderBy")(vm.orderItems, key, reverse)
      }
      vm.filterItems = $filter("filter")(vm.orderItems, function (val) {
        const str = new RegExp(`.*${vm.searchKeywords}.*`, "i")

        return (
          __guard__(val != null ? val.companyName : undefined, (x) => x.match(str)) != null ||
          (val != null ? val.name.match(str) : undefined) != null
        )
      })
      params.total(vm.filterItems.length)

      const start = (params.page() - 1) * params.count()
      const end = start + params.count()
      const resultItems = vm.filterItems.slice(start, end)
      return $defer.resolve(resultItems)
    }

    $scope.tableReload = () => vm.tableParams.reload()

    $scope.openForm = (group) =>
      $uibModal.open({
        animation: false,
        templateUrl: "app/admin/account/group/group-form.html",
        controller: "AccountGroupFormCtrl",
        backdrop: "static",
        windowClass: "modal-window",
        scope: $scope,
      })

    vm.removeGroup = function (item) {
      if ($window.confirm(Locale.string("msg_confirm_remove_group"))) {
        return AccountGroupSrv.remove(
          { id: item._id },
          (res) => $scope.dataReload(false),
          function (err) {
            console.error(`removeGroup Error: ${err}`)
            throw err
          }
        )
      }
    }

    vm.tableParams = new NgTableParams(
      { page: 1, count: 25, sorting: { name: "asc", companyName: "asc" } },
      { counts: [10, 25, 50, 100], getData: _getData }
    )
    $scope.dataReload()
  }
)

App.controller(
  "AccountGroupFormCtrl",
  function ($scope, $uibModalInstance, $window, orderByFilter, AccountGroupSrv) {
    $scope.companies = orderByFilter($scope.companies, "name")
    $scope.form = {}

    if ($scope.currentGroup) {
      $scope.form.name = $scope.currentGroup.name
      $scope.form.defaultPermission = $scope.currentGroup.defaultPermission
      //    $scope.form.$setDirty()
    } else {
      $scope.form.selectedCompany = _.first($scope.companies)
      $scope.form.defaultPermission = "none"
    }

    const original = angular.copy($scope.form)

    $scope.canSubmit = () => $scope.form_create.$valid && !angular.equals($scope.form, original)

    $scope.cancel = () => $uibModalInstance.dismiss("cancel")

    $scope.add = () =>
      AccountGroupSrv.save(
        _.merge($scope.form, {
          companyId:
            $scope.form.selectedCompany != null ? $scope.form.selectedCompany._id : undefined,
        }),
        function (group, res) {
          $scope.dataReload(false)
          return $uibModalInstance.close("success")
        },
        function (err) {
          if (err.status === 409) {
            return console.warn("Duplicate Error")
          } else {
            return console.warn("internal Error")
          }
        }
      )

    return ($scope.modify = function () {
      const params = angular.copy($scope.form)
      params.id = $scope.currentGroup._id

      return AccountGroupSrv.update(
        params,
        function (group, res) {
          $uibModalInstance.close("success")
          $scope.currentGroup.defaultPermission = params.defaultPermission
          return ($scope.currentGroup.name = params.name)
        },

        function (err) {
          if (err.status === 409) {
            return console.warn("Duplicate Error")
          } else {
            return console.warn("internal Error")
          }
        }
      )
    })
  }
)

App.controller(
  "AccountGroupDetailCtrl",
  function (
    $scope,
    $stateParams,
    $uibModal,
    $window,
    Locale,
    AccountGroupSrv,
    NgTableParams,
    ApiSrv
  ) {
    const vm = this
    vm.companyId = $stateParams.companyId
    vm.groupId = $stateParams.groupId
    vm.header = {
      account: Locale.string("Account Name"),
      email: Locale.string("Email Address"),
    }

    vm.back = () => $window.history.back()

    const _getData = function ($defer, params) {
      AccountGroupSrv.get(
        {
          id: vm.groupId,
          _: Date.now(),
        },
        function (group) {
          vm.currentGroup = group
          _reloadAccount()
          return AccountGroupSrv.getMember(
            {
              id: vm.groupId,
              _: Date.now(),
            },
            function (members) {
              if (members != null) {
                members.forEach((item) => (item["fullName"] = Locale.fullName(item)))
              }
              return $defer.resolve(_.sortBy(members, ["firstName", "lastName"]))
            }
          )
        },
        function (err) {
          console.error(`Get account group error: ${err}`)
          throw err
        }
      )
    }

    var _reloadAccount = () =>
      ApiSrv.getAllAccount({ isCache: false }).then(
        function (accounts) {
          const ableAccounts = _.reduce(
            accounts,
            function (result, item) {
              if (
                item.role.orgs.indexOf(vm.companyId) >= 0 &&
                (item.role != null ? item.role.group : undefined) === "store" &&
                (vm.currentGroup.accountList != null
                  ? vm.currentGroup.accountList.indexOf(item._id)
                  : undefined) < 0
              ) {
                result.push({
                  _id: item._id,
                  email: item.email,
                  name: Locale.fullName(item),
                })
              }
              return result
            },
            []
          )

          vm.ableAccounts = _.sortBy(ableAccounts, "name")
          if (!_.isEmpty(vm.ableAccounts)) {
            return (vm.selectedAccount = vm.ableAccounts[0])
          }
        },

        (err) => {
          throw err
        }
      )

    vm.addMember = function () {
      if (!vm.selectedAccount) {
        return
      }
      return AccountGroupSrv.addMember(
        {
          id: vm.groupId,
          accountId: vm.selectedAccount._id,
        },
        function (res) {
          _.remove(vm.ableAccounts, (item) => vm.selectedAccount === item)
          vm.selectedAccount = _.first(vm.ableAccounts)

          return vm.tableParams.reload()
        },
        function (err) {
          throw err
        }
      )
    }

    vm.removeMember = function (accountId) {
      if (!accountId) {
        return
      }
      return AccountGroupSrv.removeMember(
        {
          id: vm.groupId,
          accountId,
        },
        (res) => vm.tableParams.reload()
      )
    }

    vm.editGroup = function () {
      $scope.currentGroup = _.merge(vm.currentGroup, { companyName: vm.company.name })

      return $uibModal.open({
        animation: false,
        templateUrl: "app/admin/account/group/group-form.html",
        controller: "AccountGroupFormCtrl",
        backdrop: "static",
        windowClass: "modal-window",
        scope: $scope,
      })
    }

    return async.waterfall(
      [
        (callback) =>
          ApiSrv.getCompany({ id: vm.companyId }).then(
            (res) => callback(null, res),
            (err) => callback(err)
          ),
      ],
      function (err, company) {
        if (err) {
          console.error(`getCompany Error: ${err}`)
          return
        }

        vm.company = company
        return (vm.tableParams = new NgTableParams({}, { counts: [], getData: _getData }))
      }
    )
  }
)

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
