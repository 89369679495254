/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular.module("uCountitUiApp").config(($stateProvider) =>
  $stateProvider.state("app.intro", {
    url: "/intro",
    templateUrl: "app/intro/intro.html",
    controller($scope, BrandSrv) {
      $scope.brand = BrandSrv.getBrand()
      if (!$scope.brand.isCustom) {
        return ($scope.emblemClassName = "official-emblem")
      }
    },
  })
)
