/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import _ from "lodash"
import angular from "angular"
import alasql from "alasql"

angular
  .module("uCountitUiApp")
  .controller(
    "StoreQueueCtrl",
    function (
      $scope,
      $filter,
      $stateParams,
      $q,
      ApiSrv,
      Auth,
      DateTimeSrv,
      Locale,
      ChartOptionSrv,
      NgTableParams,
      SamplingSrv,
      QueueSrv,
      uiGridExporterService,
      HeaderSrv
    ) {
      let sampling
      const storeId = $stateParams.id
      let storeTz = "UTC"
      let currentStore = {}
      $scope.queueLines = []
      const dateFormat = "YYYYMMDD" // time format : request option, date
      const SAMPLING = {
        HOUR: "hour",
        DAY: "day",
      }
      let validTime = false

      $scope.pageOptions = {
        storeId: storeId,
        gateId: undefined,
        sampling: SAMPLING.DAY,
        calendarSampling: SAMPLING.DAY,
      }

      $scope.pickerOptions = {
        sampling: $scope.pageOptions.sampling,
        rangeMaxDate: moment(),
      }

      $scope.header = {
        timeFrom: Locale.string("Time From"),
        queueLineOrder: Locale.string("Queue Line"),
        checkOutCnt: Locale.string("Check out"),
        avgQueueCnt: Locale.string("Average Queue Length"),
        avgWaitTime: Locale.string("Average Waiting Time (sec)"),
        walkOffCnt: Locale.string("Walk-off"),
      }

      $scope.samplingString = "days"
      $scope.shortTermList = ["24H", "7D"]
      $scope.samplingList = (() => {
        const result = []
        for (var key in SAMPLING) {
          sampling = SAMPLING[key]
          result.push({ key: sampling, sampling, title: `By ${sampling}` })
        }
        return result
      })()
      // Locale.string 'By Hour'
      // Locale.string 'By Month'
      $scope.chartTypes = [
        {
          key: "checkout",
          selected: true,
          title: Locale.string("Check out"),
          color: ChartOptionSrv.chartColorList[0],
          yaxis: 2,
        },
        {
          key: "walkoff",
          selected: true,
          title: Locale.string("Walk-off"),
          color: ChartOptionSrv.chartColorList[1],
          yaxis: 1,
        },
        {
          key: "avgQueue",
          selected: true,
          title: Locale.string("Average Queue Length"),
          color: ChartOptionSrv.chartColorList[2],
          yaxis: 3,
        },
        {
          key: "avgWait",
          selected: true,
          title: Locale.string("Average Waiting Time (sec)"),
          color: ChartOptionSrv.chartColorList[3],
          yaxis: 4,
        },
      ]

      $scope.chartData = []

      $scope.chartOption = _.merge(ChartOptionSrv.contentLineChartOption, {
        yaxes: [
          {
            axisLabel: $scope.chartTypes[1].title,
            axisLabelUseCanvas: true,
            position: "left",
          },
          {
            axisLabel: $scope.chartTypes[0].title,
            axisLabelUseCanvas: true,
            position: "left",
          },
          {
            axisLabel: $scope.chartTypes[2].title,
            axisLabelUseCanvas: true,
            position: "right",
          },
          {
            axisLabel: $scope.chartTypes[3].title,
            axisLabelUseCanvas: true,
            position: "right",
          },
        ],
      })

      const setChartOptions = function () {
        ;({ sampling } = $scope.pageOptions)
        const { dt } = $scope.pageOptions
        const tickSize = SamplingSrv[sampling].getTickSize(dt.startDate, dt.endDate)
        const minTickSize = SamplingSrv[sampling].getMinTickSize()
        const tooltipDateFormat = SamplingSrv[sampling].getFormat()

        $scope.chartOption.tooltip.content = function (label, x, y, item) {
          const dateTime = Locale.dateTime(moment.utc(x), tooltipDateFormat)
          const dataIdx = _.findIndex($scope.chartData, (row) => row.label === label)

          const tooltipContents = [
            `<b>${dateTime}</b>`,
            `<svg width=12 height=12><rect width=9 height=9 y=3 fill=${
              $scope.chartData[dataIdx].color
            }></svg> \
${label}: <b>${$filter("number")(y)}</b>`,
          ].join("<br>")

          return tooltipContents
        }

        $scope.chartOption.xaxis.tickFormatter = tickFormatterFunc
        $scope.chartOption.xaxis.tickSize = [tickSize, sampling]
        return ($scope.chartOption.xaxis.minTickSize = [minTickSize, sampling])
      }

      var tickFormatterFunc = function (val, axis) {
        ;({ sampling } = $scope.pageOptions)
        const date = moment.utc(val)
        return Locale.dateTime(date, SamplingSrv[sampling].getTickFormat(val))
      }

      const requestOptions = function () {
        const dt = angular.copy($scope.pageOptions.dt)
        ;({ sampling } = $scope.pageOptions)

        // dt.startDate always browser local time so use date part only
        const start = moment.tz(dt.startDate.format("YYYY-MM-DD"), storeTz).format()
        const end = moment.tz(dt.endDate.format("YYYY-MM-DD"), storeTz).add(1, "d").format()

        return {
          storeId,
          gateId: $scope.selectedGate.rowId,
          sampling: SamplingSrv[sampling].getRequestOption(),
          from: start,
          to: end,
        }
      }

      $scope.changeLine = function (line) {
        $scope.selectedQueueLine = line
        return $scope.drawChart()
      }

      $scope.drawChart = function () {
        const line = $scope.selectedQueueLine

        const chartData = []
        for (var type of Array.from($scope.chartTypes)) {
          if (type.selected) {
            chartData.push({
              label: type.title,
              data: line.chartList[type.key],
              color: type.color,
              yaxis: type.yaxis,
            })
          }
        }

        $scope.chartData = chartData
        return setChartOptions()
      }

      const turnOffLineIfAllDataisZero = (qLines) =>
        (() => {
          const result1 = []
          for (var type of Array.from($scope.chartTypes)) {
            var allZero = true
            for (var qline of Array.from(qLines)) {
              for (var d of Array.from(qline.chartList[type.key])) {
                if (d[1]) {
                  allZero = false
                  break
                }
              }
            }
            if (allZero) {
              var ct = _.find($scope.chartTypes, ["key", type.key])
              result1.push((ct.selected = false))
            } else {
              result1.push(undefined)
            }
          }
          return result1
        })()

      // ISOTime '2015-04-10T15:15:59+09:00' -> DBTime '2015-04-10T15:15:59+00:00'
      const ISOTimetoDBTime = (tm) => moment.utc(tm.unix() * 1000 + tm.utcOffset() * 60000)

      const getQueueData = function () {
        if (!validTime || !($scope.gates != null ? $scope.gates.length : undefined)) {
          return
        }

        const params = requestOptions()
        ;({ sampling } = $scope.pageOptions)

        $scope.gridData = []
        $scope.chartData = []
        $scope.tableParams = new NgTableParams({}, { data: [] })

        //params._ = Date.now() if params
        return QueueSrv.getQueueData(params).then(
          function (res) {
            if (!(res != null ? res.length : undefined)) {
              return
            }

            $scope.queueLines.forEach(function (queueLine, idx) {
              queueLine.chartList = {}
              return Array.from($scope.chartTypes).map(
                (chart) => (queueLine.chartList[chart.key] = [])
              )
            })

            const _insertNullIfNotContinuous = function (chart, dateTime) {
              const last = _.last(chart)
              if (last != null && dateTime.diff(last[0]) > moment.duration(1, sampling)) {
                return chart.push([dateTime.clone().subtract(1, sampling), null])
              }
            }

            res.forEach(function (o) {
              const dateTime = ISOTimetoDBTime(moment(o.startTime).tz(storeTz).startOf(sampling))

              o.storeId = storeId
              o.fromTime = SamplingSrv[sampling].getFormatDate(dateTime)
              o.isoDate = SamplingSrv[sampling].getIsoDayDate(dateTime)
              const queueLine = _.find($scope.queueLines, ["rowId", o.queueLineId])
              o.queueLineOrder = queueLine.order

              for (var type of Array.from($scope.chartTypes)) {
                _insertNullIfNotContinuous(queueLine.chartList[type.key], dateTime)
              }

              queueLine.chartList["checkout"].push([dateTime, o.checkoutCnt])
              queueLine.chartList["walkoff"].push([dateTime, o.walkoffCnt])
              queueLine.chartList["avgQueue"].push([dateTime, o.averQCnt.toFixed(1)])
              return queueLine.chartList["avgWait"].push([dateTime, o.averWait.toFixed(1)])
            })

            turnOffLineIfAllDataisZero($scope.queueLines)

            $scope.gridData = res

            const pageRowsArr = [20, 50, 100]
            $scope.tableParams = new NgTableParams(
              {
                count: pageRowsArr[0],
                sorting: {
                  isoDate: "asc",
                  queueLineOrder: "asc",
                },
              },
              { counts: pageRowsArr, data: $scope.gridData }
            )

            return $scope.drawChart()
          },

          (err) => console.error(err)
        )
      }

      HeaderSrv.fetchCurrentCompany().then(
        (company) =>
          ($scope.export = function (type) {
            const { dt } = $scope.pageOptions
            ;({ sampling } = $scope.pageOptions)
            const companyName = company.name

            const period = `${dt.startDate.format(dateFormat)}~${dt.endDate.format(dateFormat)}`
            let fileName = `[queue]_[${companyName}]_[${currentStore.name}]_[${period}]_[${sampling}]`

            switch (type) {
              case "csv":
                var csvData =
                  "isoDate,storeId,gateId,queueLineOrder,checkoutCnt,walkoffCnt,averQCnt,averWait\n"
                _.forEach(
                  $scope.gridData,
                  (row) =>
                    (csvData += [
                      `${row.isoDate}`,
                      `${row.storeId}`,
                      `${row.gateId}`,
                      `${row.queueLineOrder}`,
                      `${row.checkoutCnt}`,
                      `${row.walkoffCnt}`,
                      `${row.averQCnt}`,
                      `${row.averWait}\n`,
                    ].join(","))
                )
                fileName += ".csv"
                return uiGridExporterService.downloadFile(fileName, csvData, true)
              case "excel":
                fileName += ".xlsx"
                var headerInfo = {
                  isoDate: "isoDate",
                  storeId: "storeId",
                  gateId: "gateId",
                  queueLineOrder: "queueLineOrder",
                  checkoutCnt: "checkoutCnt",
                  walkoffCnt: "walkoffCnt",
                  averQCnt: "averQCnt",
                  averWait: "averWait",
                }
                var excelData = [headerInfo].concat($scope.gridData)
                return alasql('SELECT * INTO XLSX("' + fileName + '",{headers: false}) FROM ?', [
                  excelData,
                ])
              default:
                return false
            }
          })
      )

      $scope.getQueueDataOfGate = function (gate) {
        $scope.selectedGate = gate

        $scope.queueLines = []
        return QueueSrv.getQueueLinesOfGate({ storeId, gateId: gate.rowId, _: Date.now() }).then(
          function (res) {
            $scope.queueLines = res
            if ($scope.queueLines.length > 0) {
              $scope.selectedQueueLine = $scope.queueLines[0]
            }

            return getQueueData()
          },
          (err) => console.error(err)
        )
      }

      $scope.reload = function (fromInit) {
        if (fromInit == null) {
          fromInit = false
        }
        if (fromInit) {
          validTime = true
        }
        return getQueueData()
      }

      const init = function () {
        $scope.gates = undefined
        $scope.selectedGate = null
        return $q
          .all([
            ApiSrv.getStore({ id: storeId }),
            QueueSrv.Gate().query({ storeId, _: Date.now() }).$promise,
          ])
          .then(
            function (res) {
              ;[currentStore, $scope.gates] = Array.from(res)
              storeTz =
                (currentStore != null ? currentStore.timezone : undefined) != null
                  ? currentStore != null
                    ? currentStore.timezone
                    : undefined
                  : "UTC"
              $scope.$broadcast("init-range-search", storeTz)

              if ($scope.gates != null ? $scope.gates.length : undefined) {
                return $scope.getQueueDataOfGate($scope.gates[0])
              }
            },
            (err) => console.error("storequeue", err)
          )
      }

      return init()
    }
  )
