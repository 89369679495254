/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular.module("uCountitUiApp").factory("rangePickerCacheSrv", function () {
  const getCachedTimeByTimezone = function (timezone, key) {
    let startDate = sessionStorage.getItem(
      `${key}:startDate` || localStorage.getItem(`${key}:startDate`)
    )
    let endDate = sessionStorage.getItem(`${key}:endDate` || localStorage.getItem(`${key}:endDate`))
    const sampling = sessionStorage.getItem(
      `${key}:sampling` || localStorage.getItem(`${key}:sampling`)
    )

    if (!startDate || !endDate || !sampling) {
      return null
    }

    if (timezone) {
      startDate = moment(startDate).tz(timezone, true)
      endDate = moment(endDate).tz(timezone, true).endOf("day")
    } else {
      startDate = moment(startDate)
      endDate = moment(endDate).endOf("day")
    }

    return {
      dt: {
        startDate,
        endDate,
      },
      sampling,
    }
  }

  const getCachedTimeByOffset = function (offset, key) {
    let startDate = sessionStorage.getItem(
      `${key}:startDate` || localStorage.getItem(`${key}:startDate`)
    )
    let endDate = sessionStorage.getItem(`${key}:endDate` || localStorage.getItem(`${key}:endDate`))
    const sampling = sessionStorage.getItem(
      `${key}:sampling` || localStorage.getItem(`${key}:sampling`)
    )

    if (!startDate || !endDate || !sampling) {
      return null
    }

    if (offset) {
      startDate = moment(startDate).utcOffset(offset, true)
      endDate = moment(endDate).utcOffset(offset, true).endOf("day")
    } else {
      startDate = moment(startDate)
      endDate = moment(endDate).endOf("day")
    }

    return {
      dt: {
        startDate,
        endDate,
      },
      sampling,
    }
  }

  const putCacheTime = function (dt, sampling, key) {
    localStorage.setItem(`${key}:startDate`, dt.startDate.format("YYYY-MM-DD"))
    sessionStorage.setItem(`${key}:startDate`, dt.startDate.format("YYYY-MM-DD"))
    localStorage.setItem(`${key}:endDate`, dt.endDate.format("YYYY-MM-DD"))
    sessionStorage.setItem(`${key}:endDate`, dt.endDate.format("YYYY-MM-DD"))
    localStorage.setItem(`${key}:sampling`, sampling)
    return sessionStorage.setItem(`${key}:sampling`, sampling)
  }

  return {
    getCachedTimeByTimezone,
    getCachedTimeByOffset,
    putCacheTime,
  }
})
