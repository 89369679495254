/** @jsx jsx */
"use strict"

// TODO: Migrate JSON Schema
// https://github.com/rjsf-team/react-jsonschema-form

import React from "react" // eslint-disable-line no-unused-vars
import _ from "lodash"
import { useAsync, IfPending, IfRejected, IfFulfilled } from "react-async"
import propTypes from "prop-types"
import { Form, Text, Checkbox } from "informed"
import { css, jsx } from "@emotion/react"
import Select from "../../../components/react/SelectField"
import CompanySourceParameter from "./sources/CompanySourceParameter"
import StoreSourceParameter from "./sources/StoreSourceParameter"
import StoreFootfallSourceParameter from "./sources/StoreFootfallSourceParameter"
import StoreFootfallTriggerParameter from "./triggers/StoreFootfallTriggerParameter"
import AtTriggerParameter from "./triggers/AtTriggerParameter"
import EmailActionParameter from "./actions/EmailActionParameter"
import SensorContentWebhookParameter from "./sources/SensorContentWebhookParameter"
import SensorOrganizationFilterParameter from "./triggers/SensorOrganizationFilterParameter"
import WebhookActionParameter from "./actions/WebhookActionParameter"
import axios from "axios"
import UcLoader from "../../../components/loaders/UcLoader"
import { Validator } from "jsonschema"

export const FormEvent = (props) => {
  const handleSubmit = (formValue) => {
    if (props.onSubmit) props.onSubmit(formValue)
  }

  const renderSourceParameter = (source, formState) => {
    if (!formState.values.source) return null
    const kind = formState.values.source.kind
    const error = _.get(formState.errors, "source.parameter")
    switch (kind) {
      case "CompanySource":
        return <CompanySourceParameter error={error} />
      case "StoreSource":
        return <StoreSourceParameter error={error} />
      case "FootfallSource":
      case "StoreFootfallSource":
        return <StoreFootfallSourceParameter error={error} samplings={source.samplings} />
      case "SensorContentWebhookSource":
        return <SensorContentWebhookParameter error={error} />
      default:
        return null
    }
  }

  const renderTriggerParameter = (trigger, formState) => {
    if (!formState.values.trigger) return null
    const kind = formState.values.trigger.kind
    const error = _.get(formState.errors, "trigger.parameter")
    switch (kind) {
      case "StoreFootfallTrigger":
        return <StoreFootfallTriggerParameter error={error} />
      case "HeartbeatAtTrigger":
      case "TofAbnormalAtTrigger":
        return <AtTriggerParameter error={error} />
      case "SensorOrganizationFilterTrigger":
        return <SensorOrganizationFilterParameter error={error} />
      default:
        return null
    }
  }

  const renderActionParameter = (action, formState) => {
    if (!formState.values.action) return null
    const kind = formState.values.action.kind
    const error = _.get(formState.errors, "action.parameter")
    switch (kind) {
      case "FootfallEmailAction":
      case "AbnormalEmailAction":
      case "TofEmailAction":
        return <EmailActionParameter error={error} />
      case "OccupancyWebhookAction":
        return <WebhookActionParameter error={error} />
      case "SensorWebhookAction":
        return <WebhookActionParameter error={error} />
    }
  }

  const createRequiredValidator = (msg) => (value) => {
    return !value ? msg : undefined
  }

  const isIOCompatibility = (output, input) => {
    if (_.isArray(input)) {
      return input.indexOf(output) >= 0
    } else {
      return input == "any" || input == output
    }
  }

  const formValidate = (formState) => {
    const { SourceModules, TriggerModules, ActionModules } = props.modules
    if (!(formState.source && formState.trigger && formState.action)) return undefined

    const source = SourceModules.find((sourceModule) => sourceModule.Kind == formState.source.kind)
    const trigger = TriggerModules.find(
      (triggerModule) => triggerModule.Kind == formState.trigger.kind
    )
    const action = ActionModules.find((actionModule) => actionModule.Kind == formState.action.kind)
    if (!source || !trigger || !action) return "Required field is not found"

    let ret = {
      name: undefined,
      isActive: undefined,
    }
    if (!isIOCompatibility(source.Kind, trigger.SourceType)) {
      ret = {
        source: {
          kind: `Source is no compatibility with ${trigger.Kind}`,
        },
        trigger: {
          kind: `Trigger is no compatibility with ${source.Kind}`,
        },
      }
    }
    if (!isIOCompatibility(trigger.Kind, action.TriggerType)) {
      ret = {
        ...ret,
        action: {
          kind: `Action is no compatibility with ${trigger.Kind}`,
        },
      }
    }
    const validateParameter = (module, formValue, path) => {
      const v = new Validator()
      if (!formValue[path]["parameter"]) formValue[path]["parameter"] = {}
      try {
        const schema = _.clone(module.Schema)
        if (schema.definitions) {
          Object.keys(schema.definitions).forEach((k) => {
            const definition = _.clone(schema.definitions[k])
            definition.id = `/${k}`
            v.addSchema(definition, definition.id)
          })
          delete schema.definitions
        }
        const validated = v.validate(formValue[path]["parameter"], schema)
        if (!validated.valid) {
          return {
            [path]: {
              parameter: validated.errors.reduce((obj, err) => {
                obj[err.argument] = err.message
                return obj
              }, {}),
            },
          }
        } else {
          return {
            [path]: {
              kind: undefined,
              parameter: Object.keys(formValue[path]["parameter"]).reduce((param, k) => {
                param[k] = undefined
                return param
              }, {}),
            },
          }
        }
      } catch (err) {
        console.error(err.message)
        console.error(err)
      }
    }

    ret = _.merge(
      ret,
      validateParameter(source, formState, "source"),
      validateParameter(trigger, formState, "trigger"),
      validateParameter(action, formState, "action")
    )

    return ret
  }

  const { SourceModules, TriggerModules, ActionModules } = props.modules

  return (
    <Form
      className="form-horizontal"
      initialValues={props.initialValues}
      validateFields={formValidate}
      onSubmit={handleSubmit}
    >
      {({ formState, formApi }) => {
        let source
        const sourceOptions = SourceModules.map((sm) => ({ label: sm.Kind, value: sm.Kind }))
        if (formState.values.source) {
          source = SourceModules.find((s) => s.Kind == formState.values.source.kind)
        }
        const triggerOptions = TriggerModules.map((tm) => {
          let isDisabled = false
          if (source && !isIOCompatibility(source.Kind, tm.SourceType)) {
            isDisabled = true
          }
          return {
            label: tm.Kind,
            value: tm.Kind,
            isDisabled,
          }
        })

        let trigger
        if (formState.values.trigger) {
          trigger = TriggerModules.find((t) => t.Kind == formState.values.trigger.kind)
        }
        const actionOptions = ActionModules.map((am) => {
          let isDisabled = false
          if (trigger && !isIOCompatibility(trigger.Kind, am.TriggerType)) {
            isDisabled = true
          }
          return { label: am.Kind, value: am.Kind, isDisabled }
        })
        let action
        if (formState.values.action) {
          action = ActionModules.find((a) => a.Kind.indexOf(formState.values.action.kind) >= 0)
        }

        return (
          <>
            <div className="form-group">
              <label className="control-label col-sm-2">Name</label>
              <div className="col-sm-10">
                <Text className="form-control" field="name" required />
              </div>
            </div>
            <div className={`form-group ${formApi.getError("source.kind") ? "has-error" : null}`}>
              <label className="control-label col-sm-2">Source</label>
              <div className="col-sm-10">
                <Select
                  field="source.kind"
                  validate={createRequiredValidator("Source is required")}
                  options={sourceOptions}
                />
                {formApi.getError("source.kind") ? (
                  <span className="help-block">{formApi.getError("source.kind")}</span>
                ) : null}
              </div>
            </div>
            {renderSourceParameter(source, formState)}
            <div className={`form-group ${formApi.getError("trigger.kind") ? "has-error" : null}`}>
              <label className="control-label col-sm-2">Trigger</label>
              <div className="col-sm-10">
                <Select
                  field="trigger.kind"
                  validate={createRequiredValidator("Trigger is required")}
                  options={triggerOptions}
                />
                {formApi.getError("trigger.kind") ? (
                  <span className="help-block">{formApi.getError("trigger.kind")}</span>
                ) : null}
              </div>
            </div>
            {renderTriggerParameter(trigger, formState)}
            <div className={`form-group ${formApi.getError("action.kind") ? "has-error" : null}`}>
              <label className="control-label col-sm-2">Action</label>
              <div className="col-sm-10">
                <Select
                  field="action.kind"
                  validate={createRequiredValidator("Action is required")}
                  options={actionOptions}
                />
                {formApi.getError("action.kind") ? (
                  <span className="help-block">{formApi.getError("action.kind")}</span>
                ) : null}
              </div>
            </div>
            {renderActionParameter(action, formState)}
            <div className="form-group">
              <label className="control-label col-sm-2">Active</label>
              <div
                className="col-sm-10"
                css={css`
                  padding-top: 5px;
                `}
              >
                <Checkbox
                  field="isActive"
                  initialValue={
                    !props.initialValues || props.initialValues.isActive == undefined
                      ? true
                      : undefined
                  }
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-12 text-right">
                <button
                  type="submit"
                  disabled={props.disableSubmit || !formState.dirty}
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </div>
          </>
        )
      }}
    </Form>
  )
}

FormEvent.propTypes = {
  initialValues: propTypes.object,
  modules: propTypes.object,
  onSubmit: propTypes.func,
}

const EVENT_MODULE_URL = "/api/1.0/event/modules"
const fetchModules = () => axios.get(EVENT_MODULE_URL).then((res) => res.data)
export const FormEventContainer = (props) => {
  const state = useAsync({ promiseFn: fetchModules })
  return (
    <>
      <IfPending state={state}>
        <UcLoader />
      </IfPending>
      <IfRejected state={state}>{(error) => `${error.message}`}</IfRejected>
      <IfFulfilled state={state}>{(data) => <FormEvent {...props} modules={data} />}</IfFulfilled>
    </>
  )
}
FormEventContainer.propTypes = {
  ...FormEvent.propTypes,
}

export default FormEventContainer
