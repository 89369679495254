/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  // TODO remove date-container
  .directive(
    "alwaysTop",
    ($window, $timeout) => (scope, element) =>
      $timeout(function () {
        const setSize = function () {
          const pageWidth = element.width()
          const pageHeight = angular.element(".date-container", element).height()
          angular.element(".date-container", element).css("width", pageWidth)
          return angular.element(".blank", element).css("height", pageHeight)
        }

        element.on("resize", () => setSize())
        setSize()

        return angular.element($window).bind("scroll", function () {
          const page = angular.element(".page", element.parent().parent())
          const padding = 15
          if (this.pageYOffset > padding) {
            return page.css("padding-top", 0)
          } else {
            return page.css("padding-top", padding)
          }
        })
      })
  )
  .directive(
    "pickerAlwaysTop",
    ($window, $timeout) => (scope, element) =>
      $timeout(function () {
        const setSize = function () {
          const pageWidth = element.width()
          const pageHeight = angular.element(".date-select-container", element).height()
          angular.element(".date-select-container", element).css("width", pageWidth)
          return angular.element(".blank", element).css("height", pageHeight)
        }

        element.on("resize", () => setSize())
        setSize()

        return angular.element($window).bind("scroll", function () {
          const page = angular.element(".page", element.parent().parent())
          const padding = 15
          if (this.pageYOffset > padding) {
            return page.css("padding-top", 0)
          } else {
            return page.css("padding-top", padding)
          }
        })
      })
  )
