/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS201: Simplify complex destructure assignments
 * DS202: Simplify dynamic range loops
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

import { kStandardLabels, getLabelName } from "../../../components/counter-label"
import env from "../../../../config/environment"
import BizSchedule from "common-library/BizSchedule"
import { getColors } from "../../../components/util/colors"

angular
  .module("uCountitUiApp")
  .controller(
    "CameraDetailCtrl",
    function (
      $scope,
      $state,
      $stateParams,
      $location,
      $element,
      ApiSrv,
      Auth,
      UtilSrv,
      usSpinnerService,
      Locale,
      SamplingSrv,
      HeaderSrv
    ) {
      const SAMPLING = {
        HOUR: "hour",
        DAY: "day",
        MONTH: "month",
      }

      HeaderSrv.fetchCurrentCompany().then((company) => ($scope.companyName = company.name))
      $scope.currentPage = "footfall"
      $scope.validTime = false
      $scope.heatmapReady = false
      $scope.setHeatmapReady = (val) => ($scope.heatmapReady = val)

      $scope.go = function (stateName) {
        let stateParams
        const org = Auth.getCurrentStore()
        if (stateName === "app.camera.list") {
          stateParams = {
            orgType: org.orgType,
            id: org._id,
          }
        } else {
          stateParams = { id: $scope.currentCamera != null ? $scope.currentCamera._id : undefined }
        }

        return $state.go(stateName, stateParams)
      }

      $scope.isBH = true
      $scope.title = {
        counters: Locale.string("Sensor Counters"),
        liveheatmap: Locale.string("Live") + " / " + Locale.string("Heatmap"),
      }

      $scope.pageOptions = {
        cameraId: $stateParams.id,
        sampling: SAMPLING.DAY,
        counter: {
          with: null,
        },
        content: "footfall",
      }

      $scope.labelCounterDesc = Locale.string("msg_labelcounter_desc")

      const getDefaultData = function (start, end, sampling) {
        const data = []

        /*
    if (sampling is 'week')
      samp = 'isoWeek'
    else
      samp = sampling
    */
        const samp = sampling

        const samplingStart = moment(start).startOf(samp)
        const samplingEnd = moment(end).startOf(samp)

        let dataLength = samplingEnd.diff(samplingStart, sampling)
        if (sampling === "week" || sampling === "month") {
          dataLength++
        }

        for (
          let i = 0, end1 = dataLength, asc = 0 <= end1;
          asc ? i < end1 : i > end1;
          asc ? i++ : i--
        ) {
          if (i === 0 && samplingStart < start) {
            data.push([start, null])
          } else {
            var newDate = moment(start).startOf(samp).add(i, sampling)
            if (newDate < end) {
              data.push([newDate, null])
            }
          }
        }

        return {
          data: data,
          totalCount: 0,
          startDate: start,
          endDate: end,
          sampling: sampling,
        }
      }

      const loadingQueue = []
      const showLoading = function (key) {
        if (key) {
          loadingQueue.push(key)
        }
        angular.element(".splash").show()
        usSpinnerService.spin("spinner")
        return angular.element(".spinner").css("position", "fixed")
      }

      const hideLoading = function (key) {
        if (key) {
          _.remove(loadingQueue, (raw) => raw === key)
          if (loadingQueue.length) {
            return
          }
        }

        angular.element(".splash").hide()
        usSpinnerService.stop("spinner")
        return $scope.$apply()
      }

      const samplingKey = function (val) {
        switch (val) {
          case "hour":
            return "1h"
          case "day":
            return "1d"
          case "week":
            return "1w"
          case "month":
            return "1M"
          default:
            return "1d"
        }
      }

      const isValidPeriod = function (start, end, sampling) {
        const limitDate = SamplingSrv[sampling].getLimitDate()
        const diffNum = moment(end).diff(start, limitDate.key)

        if (diffNum > limitDate.value) {
          return false
        } else {
          return true
        }
      }

      $scope.showLoading = showLoading
      $scope.hideLoading = hideLoading
      $scope.samplingKey = samplingKey
      $scope.getDefaultData = getDefaultData
      $scope.isValidPeriod = isValidPeriod

      $scope.select = function (idx) {
        $scope.selectedTab = idx
        if ($scope.validTime) {
          return $scope.reloadChart()
        }
      }

      $scope.reloadChart = function (fromInit) {
        if (fromInit == null) {
          fromInit = false
        }
        if (fromInit) {
          $scope.validTime = true
        }
        return $scope.$broadcast("reload_chart")
      }

      const convertMinutesToHHmm = function (minutes) {
        const hour = parseInt(minutes / 60)
          .toString()
          .padStart(2, "0")
        const min = (minutes % 60).toString().padStart(2, "0")
        return `${hour}:${min}`
      }

      const createBizHoursText = (bh) =>
        `${convertMinutesToHHmm(bh.open)} ~ ${convertMinutesToHHmm(bh.close)}`

      const init = () =>
        ApiSrv.getCamera({
          id: $stateParams.id,
        }).then(function (response) {
          $scope.currentCamera = response

          if (!$scope.currentCamera) {
            return $location.path("/sensor")
          }

          return Promise.all([
            ApiSrv.getStore({ id: response._storeId }),
            ApiSrv.getStoreBizSchedule({ id: response._storeId }),
          ]).then(function (...args) {
            const [store, schedule] = Array.from(args[0])
            $scope.currentStore = store
            $scope.bs = new BizSchedule(schedule, schedule.timezone)
            $scope.storeName = store.name
            $scope.exportFilename = `${$scope.storeName}_${$scope.currentCamera.name}_heatmap.csv`
            $scope.isActiveBH =
              (store.schedule != null ? store.schedule.businessHours.active : undefined) != null
                ? store.schedule != null
                  ? store.schedule.businessHours.active
                  : undefined
                : false

            if ($scope.isActiveBH) {
              $scope.bh = schedule.bizHours.find((bh) => bh.active)
              $scope.strBizHours = createBizHoursText($scope.bh)
              $scope.midnight = $scope.bh.overnight
            } else {
              $scope.isBH = false
            }

            $scope.$broadcast("init-range-search", store != null ? store.timezone : undefined)
            return $scope.setHeatmapReady(true)
          })
        })

      $scope.isInitComplete = init()

      return $scope.$on("heatmapImg_complete", function (event, error) {
        $scope.heatmap_error = error != null
        return hideLoading()
      })
    }
  )
  .controller(
    "CameraCounterCtrl",
    function (
      $scope,
      $filter,
      $element,
      $q,
      $location,
      ChartOptionSrv,
      GridOptionSrv,
      ApiSrv,
      Locale,
      Auth,
      SamplingSrv
    ) {
      const legendEle = angular.element("#counterLegend")
      $scope.countList = []
      const chartOption = _.merge(
        {
          yaxis: { min: 0 },
        },
        ChartOptionSrv.lineChartOption
      )
      const miniChartOption = ChartOptionSrv.miniLineChartOption

      const gridOption = _.merge(
        {
          onRegisterApi(gridApi) {
            return (this.gridApi = gridApi)
          },
          getGridApi() {
            return this.gridApi
          },
        },
        GridOptionSrv.gridExportOption
      )

      const getCameraCountData = function (chartData, options) {
        const deffered = $q.defer()
        const localStartDate = moment.utc(options.from, "YYYYMMDDHHmm")
        const localEndDate = moment.utc(options.to, "YYYYMMDDHHmm")
        const splitJobLength = 30 // 1 month = 30day
        const splitJobCount = Math.ceil(localEndDate.diff(localStartDate, "day") / splitJobLength)

        async.times(
          splitJobCount,
          function (n, callback) {
            const start = moment(localStartDate).add(n * splitJobLength, "days")
            let end = moment(start).add(splitJobLength, "days")
            if (end.isAfter(localEndDate)) {
              end = localEndDate
            }
            options.from = start.format("YYYYMMDDHHmm")
            options.to = end.format("YYYYMMDDHHmm")

            return ApiSrv.getCountOfCamera(options).then((response) =>
              callback(
                null,
                !__guard__(response != null ? response.data : undefined, (x) => x.length)
                  ? null
                  : response
              )
            )
          },
          function (error, responses) {
            if (error) {
              return deffered.reject(error)
            }

            let validResponseCount = 0
            for (var res of Array.from(responses)) {
              if (!res || chartData.length <= 0) {
                continue
              }
              validResponseCount += 1
              _.forEach(res.data, function (raw) {
                // chartDataIndex, countKey
                let dataIdxs = []
                if (chartData.length >= 1) {
                  for (const i in chartData) {
                    const n = chartData[i]
                    let countKey = "count"
                    if (n.key.includes(raw.counterId)) {
                      if (n.key.endsWith("-Net")) {
                        countKey = "netVisits"
                      }
                      dataIdxs.push([i, countKey])
                    }
                  }
                  if (dataIdxs.length == 0) {
                    return deffered.resolve(chartData)
                  }
                }

                for (const [dataIdx, countKey] of dataIdxs) {
                  const selectedData = chartData[dataIdx] != null ? chartData[dataIdx] : chartData
                  const { sampling } = selectedData
                  const idx = moment
                    .utc(raw.statisticFor)
                    .startOf(sampling)
                    .diff(moment(localStartDate).startOf(sampling), sampling)

                  if (selectedData.data[idx] && raw[countKey] != null) {
                    if (sampling === "hour") {
                      selectedData.data[idx][1] = raw[countKey]
                      if (selectedData.prevDay !== moment.utc(raw.statisticFor).date()) {
                        selectedData.prevCount = 0
                      }

                      selectedData.data[idx][1] -= selectedData.prevCount

                      if (selectedData.data[idx][1] && selectedData.data[idx][1] < 0) {
                        selectedData.data[idx][1] = 0
                      }
                      selectedData.totalCount += selectedData.data[idx][1]
                    } else {
                      selectedData.data[idx][1] += raw[countKey]
                      selectedData.totalCount += raw[countKey]
                    }

                    selectedData.prevCount = raw[countKey] ?? 0
                    selectedData.prevDay = moment.utc(raw.statisticFor).date()
                  }
                }
              })
            }
            if (!validResponseCount) {
              chartData.data = null
            }
            return deffered.resolve(chartData)
          }
        )

        return deffered.promise
      }

      const setChart = function (dataSet) {
        const dataList = []

        $scope.countList.forEach((data, idx) => {
          if (dataSet.has(data.key)) {
            const cData = angular.copy(data)
            cData.lines = { fill: false }
            cData.highlightColor = cData.color ? cData.color : ChartOptionSrv.chartColorList[idx]

            dataList.push(cData)
          }
        })

        _.merge(chartOption, {
          legend: {
            container: legendEle,
            noColumns: 0,
          },
        })

        if (!dataList.length) {
          legendEle.addClass("invisible")
        } else {
          dataList[0].lines.fill = true
          if (legendEle.hasClass("invisible")) {
            legendEle.removeClass("invisible")
          }
        }

        $scope.chartOption = chartOption
        $scope.chartData = dataList
      }

      const selectChart = function (countData) {
        if ($scope.pageOptions.counter.with.has(countData.key)) {
          $scope.pageOptions.counter.with.delete(countData.key)
        } else {
          $scope.pageOptions.counter.with.add(countData.key)
        }

        if ($scope.pageOptions.counter.with.size === 0) {
          $location.search("counterComparison", "none")
        } else if (
          $scope.countList.every((data) => $scope.pageOptions.counter.with.has(data.key))
        ) {
          $location.search("counterComparison", "all")
        } else {
          $location.search("counterComparison", [...$scope.pageOptions.counter.with].join(","))
        }

        return setChart($scope.pageOptions.counter.with)
      }

      const isEmptyRow = function (dataSet, idx) {
        let result = true
        _.forEach(dataSet, function (data) {
          if (!_.isNull(data.data[idx][1])) {
            return (result = false)
          }
        })

        return result
      }

      let options = {}

      const showWeather = (sampling) =>
        __guard__(
          $scope.currentStore != null ? $scope.currentStore.weather : undefined,
          (x) => x.locationId
        ) != null &&
        sampling !== "month" &&
        !env.isLocalgrey

      const getWeatherData = function (from, to, sampling) {
        if (!showWeather(sampling)) {
          return Promise.resolve([])
        }

        const option = {
          locationId: $scope.currentStore.weather.locationId,
          from,
          to,
          language: Locale.getLanguage().split("-")[0],
        }
        if (sampling === "day") {
          option.sampling = sampling
        }

        return ApiSrv.getWeatherObservationSafeData(option)
      }

      const getDisplayLabelName = function (counter) {
        const name = getLabelName(counter)
        if (name === "No Label") {
          return ""
        }

        const sLabel = _.find(kStandardLabels, ["name", name])
        if (sLabel) {
          return `(${Locale.string(sLabel.i18nKey)})`
        } else {
          return `(${name})`
        }
      }

      const load = function () {
        let closeHour, filename
        if ($scope.selectedTab !== 1) {
          return
        }
        $scope.setHeatmapReady(false)
        const { sampling } = $scope.pageOptions
        const startDate = moment($scope.pageOptions.dt.startDate)
        const endDate = moment($scope.pageOptions.dt.endDate)

        const period = startDate.format("YYYYMMDD") + "-" + endDate.format("YYYYMMDD")
        gridOption.filename = filename =
          $scope.storeName + "_" + $scope.currentCamera.name + "_" + period
        gridOption.exporterCsvFilename = filename + ".csv"
        gridOption.exporterPdfFilename = filename + ".pdf"

        const newOptions = {
          id: $scope.pageOptions.cameraId,
          from: startDate.format("YYYYMMDDHHmm"),
          to: endDate.add(1, "days").startOf("day").format("YYYYMMDDHHmm"),
          sampling: $scope.samplingKey(sampling),
          entrance: "all",
          item: "counter",
          bh: !$scope.isActiveBH ? true : $scope.isBH,
        }

        if ($scope.midnight && $scope.isBH && sampling === "hour") {
          closeHour = Math.ceil($scope.bh.close / 60)
          newOptions.to = endDate.add(closeHour, "hour").format("YYYYMMDDHHmm")
        }

        if (_.isEqual(options, newOptions)) {
          return
        }
        if (!$scope.isValidPeriod(startDate, endDate, sampling)) {
          return
        }

        options = newOptions

        const dataSet = []
        const gridSet = []
        const showGridSet = []
        let weather = {}
        const timeFieldName =
          sampling === "hour" ? Locale.string("time from") : Locale.string("time")
        const columnSet = [
          {
            name: "time",
            field: "time",
            minWidth: 150,
            cellClass: "text-center",
            displayName: timeFieldName,
          },
        ]

        $scope.showLoading("counter")
        $scope.chartNoDataStr = "no data"
        return async.waterfall(
          [
            (callback) =>
              ApiSrv.getFootfallsConfigOfCamera(
                $scope.pageOptions.cameraId,
                $scope.currentStore._id,
                {
                  from: startDate.format("YYYYMMDDHHmm"),
                  to: endDate.format("YYYYMMDDHHmm"),
                }
              )
                .then((footfalls) => callback(null, footfalls))
                .catch((err) => callback(err)),

            function (footfalls, callback) {
              footfalls = _.cloneDeep(footfalls)
              let counter

              const countDataSet = {}
              let counters = []
              let colorIdx = 0
              for (var footfall of Array.from(footfalls.reverse())) {
                const additionalCounters = []
                for (const counter of footfall.counters) {
                  if (counter.netVisits?.active) {
                    const newCounter = _.clone(counter)
                    newCounter._id = `${counter._id}-Net`
                    newCounter.name = `${counter.name}.NetVisits`
                    additionalCounters.push(newCounter)
                  }
                }
                footfall.counters = footfall.counters.concat(additionalCounters)
                for (counter of Array.from(footfall.counters)) {
                  if (!countDataSet[counter._id]) {
                    const countColor = getColors(colorIdx++, "chart")
                    countDataSet[counter._id] = _.merge(
                      {
                        key: counter._id,
                        label: `${counter.name}${getDisplayLabelName(counter)}`,
                        color: `rgb(${countColor})`,
                      },
                      $scope.getDefaultData(startDate, endDate, sampling)
                    )
                  }
                }
                counters = counters.concat(footfall.counters)
              }

              return Promise.all([
                getWeatherData(options.from, options.to, sampling),
                getCameraCountData(Object.values(countDataSet), options),
              ]).then(function (...args) {
                const [_weather, response] = Array.from(args[0])
                if (!response) {
                  if (_.findIndex(counters, ["active", true]) < 0) {
                    $scope.chartNoDataStr = "inactive counter"
                  }
                  return callback(null, dataSet)
                }

                if (response.data === null) {
                  return callback(null, dataSet)
                }

                weather = _weather
                const kMinWidth = 100
                const inactiveStr = Locale.string("inactive")

                _.forEach(response, function (responseData) {
                  const counterIdx = _.findIndex(counters, (n) => n._id === responseData.key)
                  if (counterIdx < 0) {
                    return
                  }
                  if (!counters[counterIdx].active && responseData.totalCount === 0) {
                    return
                  }

                  if (!counters[counterIdx].active) {
                    responseData.label = counters[counterIdx].name + "(" + inactiveStr + ")"
                  }

                  if ($scope.midnight && $scope.isBH && sampling === "hour") {
                    closeHour = Math.ceil($scope.bh.close / 60)
                    for (
                      let start = responseData.data.length - 1, i = start, asc = start <= 0;
                      asc ? i <= 0 : i >= 0;
                      asc ? i++ : i--
                    ) {
                      var data = responseData.data[i]
                      if (startDate.isSame(data[0], "day") && data[0].hour() < closeHour) {
                        responseData.totalCount -= data[1]
                        responseData.data.splice(i, 1)
                      }
                    }
                  }

                  dataSet.push(responseData)

                  // for column definition
                  return columnSet.push({
                    name: counters[counterIdx]._id,
                    field: counters[counterIdx]._id,
                    minWidth: kMinWidth,
                    displayName: `${counters[counterIdx].name}${getDisplayLabelName(
                      counters[counterIdx]
                    )}`,
                    cellClass: "text-right",
                    cellFilter: "number : 0",
                    exporterPdfAlign: "right",
                  })
                })

                const selectedCounters = $location.search().counterComparison

                if (selectedCounters && typeof selectedCounters === "string") {
                  if (selectedCounters === "none") {
                    $scope.pageOptions.counter.with = new Set()
                  } else if (selectedCounters === "all") {
                    $scope.pageOptions.counter.with = new Set(dataSet.map((data) => data.key))
                  } else {
                    $scope.pageOptions.counter.with = new Set(selectedCounters.split(","))
                  }
                } else {
                  $scope.pageOptions.counter.with = new Set(dataSet.map((data) => data.key))
                }

                if (weather.hasData) {
                  columnSet.push({
                    name: "weather",
                    field: "weather",
                    minWidth: kMinWidth,
                    cellClass: "text-center",
                    displayName: Locale.string("Weather"),
                  })
                  columnSet.push({
                    name: "rain",
                    field: "rain",
                    minWidth: kMinWidth,
                    cellClass: "text-right",
                    displayName: Locale.string("Precipitation") + "(mm)",
                  })
                  columnSet.push({
                    name: "minTemp",
                    field: "minTemp",
                    minWidth: kMinWidth,
                    cellClass: "text-right",
                    displayName: Locale.string("Min Temperature") + "(℃)",
                  })
                  columnSet.push({
                    name: "maxTemp",
                    field: "maxTemp",
                    minWidth: kMinWidth,
                    cellClass: "text-right",
                    displayName: Locale.string("Max Temperature") + "(℃)",
                  })
                  columnSet.push({
                    name: "pm25",
                    field: "pm25",
                    minWidth: kMinWidth,
                    cellClass: "text-right",
                    displayName: Locale.string("PM2.5") + "(㎍/m³)",
                  })
                  columnSet.push({
                    name: "pm10",
                    field: "pm10",
                    minWidth: kMinWidth,
                    cellClass: "text-right",
                    displayName: Locale.string("PM10") + "(㎍/m³)",
                  })
                }

                gridOption.columnDefs = columnSet

                return callback(null, dataSet)
              })
            },
          ],
          function (err, dataSet) {
            if (err) {
              console.warn({ err })
            }
            $scope.isGridData = false

            const _resetUIData = function () {
              $scope.gridOptions.data = []
              $scope.countList = []
              setChart($scope.pageOptions.counter.with)
              return $scope.hideLoading("counter")
            }

            if (!dataSet.length) {
              _resetUIData()
              return
            }

            const dataLength = _.first(dataSet).data.length
            if (dataLength > 70) {
              chartOption.lines.lineWidth = 1
              chartOption.points.show = false
            } else {
              chartOption.lines.lineWidth = 3
              chartOption.points.show = true
            }

            const samplingTime = sampling === "hour" && dataLength > 70 ? "day" : sampling

            chartOption.xaxis.minTickSize = [1, samplingTime]
            chartOption.xaxis.tickFormatter = function (val) {
              const dbtime = moment.utc(val)
              if (samplingTime === "day") {
                dbtime.startOf("day")
              }
              let label = `${Locale.dateTime(dbtime, samplingTime)}`

              if (weather.hasData) {
                const w = weather[samplingTime][dbtime.format()]
                if (w != null) {
                  const min =
                    (w.temperature != null ? w.temperature.min : undefined) != null
                      ? `${w.temperature.min}°`
                      : "-"
                  const max =
                    (w.temperature != null ? w.temperature.max : undefined) != null
                      ? `${w.temperature.max}°`
                      : "-"
                  label += `\n${w.weatherSymbol}` + ` ${min}/${max}`
                }
              }
              return label
            }

            if (sampling === "hour" && $scope.isBH) {
              chartOption.grid.markings = ChartOptionSrv.createCustomMarkingsFunc($scope.bs)
            } else {
              chartOption.grid.markings = []
            }

            $scope.countList = dataSet
            setChart($scope.pageOptions.counter.with)

            const samplingFormat = SamplingSrv[sampling].getFormat()
            const isoFormat = SamplingSrv[sampling].getIsoDayFormat()
            // make grid data
            for (
              var i = 0, end1 = dataLength - 1, asc = 0 <= end1;
              asc ? i <= end1 : i >= end1;
              asc ? i++ : i--
            ) {
              var showTimerange, timerange
              var end
              if (isEmptyRow(dataSet, i)) {
                continue
              }

              var currentDate = moment(_.first(dataSet).data[i][0])
              switch (sampling) {
                case "hour":
                  if ($scope.isBH && !$scope.bs.isOpen(currentDate, true)) {
                    continue
                  }

                  showTimerange = Locale.dateTime(currentDate, samplingFormat)
                  timerange = Locale.dateTime(currentDate, isoFormat)
                  break
                case "day":
                  showTimerange = Locale.dateTime(currentDate, samplingFormat)
                  timerange = Locale.dateTime(currentDate, isoFormat)
                  break
                case "week":
                case "month":
                  if (i === dataLength - 1) {
                    end = moment(endDate).subtract(1, "days")
                  } else {
                    end = moment(_.first(dataSet).data[i + 1][0]).subtract(1, "days")
                  }
                  showTimerange = `${Locale.dateTime(
                    currentDate,
                    samplingFormat
                  )} ~ ${Locale.dateTime(end, samplingFormat)}`
                  timerange = `${Locale.dateTime(currentDate, isoFormat)} ~ ${Locale.dateTime(
                    end,
                    isoFormat
                  )}`
                  break
              }

              var gridItem = { time: timerange }
              var showGridItem = { time: showTimerange }

              _.forEach(dataSet, function (data) {
                gridItem[data.key] = data.data[i][1]
                showGridItem[data.key] = data.data[i][1]

                if (weather.hasData) {
                  const w = weather[sampling][data.data[i][0].format()]
                  showGridItem["weather"] = gridItem["weather"] =
                    (w != null ? w.weatherText : undefined) != null
                      ? w != null
                        ? w.weatherText
                        : undefined
                      : ""
                  showGridItem["minTemp"] = gridItem["minTemp"] =
                    (w != null ? w.temperature.min : undefined) != null
                      ? w != null
                        ? w.temperature.min
                        : undefined
                      : ""
                  showGridItem["maxTemp"] = gridItem["maxTemp"] =
                    (w != null ? w.temperature.max : undefined) != null
                      ? w != null
                        ? w.temperature.max
                        : undefined
                      : ""
                  showGridItem["rain"] = gridItem["rain"] =
                    (w != null ? w.rain : undefined) != null ? (w != null ? w.rain : undefined) : ""
                  showGridItem["pm25"] = gridItem["pm25"] =
                    __guard__(
                      __guard__(w != null ? w.pm : undefined, (x1) => x1[25]),
                      (x) => x.value
                    ) != null
                      ? __guard__(
                          __guard__(w != null ? w.pm : undefined, (x1) => x1[25]),
                          (x) => x.value
                        )
                      : ""
                  return (showGridItem["pm10"] = gridItem["pm10"] =
                    __guard__(
                      __guard__(w != null ? w.pm : undefined, (x3) => x3[10]),
                      (x2) => x2.value
                    ) != null
                      ? __guard__(
                          __guard__(w != null ? w.pm : undefined, (x3) => x3[10]),
                          (x2) => x2.value
                        )
                      : "")
                }
              })

              gridSet.push(gridItem)
              showGridSet.push(showGridItem)
            }

            $scope.gridOptions.data = showGridSet
            $scope.gridOptions.exportData = gridSet
            if (gridSet.length > 0) {
              $scope.isGridData = true
            }

            gridOption.exporterPdfHeader = {
              text: "Camera Name : " + $scope.currentCamera.name + " (Labels Data)",
              style: "headerStyle",
            }

            gridOption.exporterPdfCustomFormatter = function (docDefinition) {
              docDefinition.content[0].table.widths = GridOptionSrv.getColumnWidths(
                gridOption.columnDefs.length
              )
              return docDefinition
            }

            return $scope.hideLoading("counter")
          }
        )
      }

      $scope.$parent.isInitComplete.then(function () {
        load()
        return $scope.$on("reload_chart", () => load())
      })

      $scope.chartOption = chartOption
      $scope.miniChartOption = miniChartOption
      $scope.selectChart = selectChart
      $scope.gridOptions = gridOption

      return ($scope.selectAllCountBy = function (selection) {
        if (selection) {
          $scope.pageOptions.counter.with = new Set($scope.countList.map((data) => data.key))
          $location.search("counterComparison", "all")
        } else {
          $scope.pageOptions.counter.with = new Set()
          $location.search("counterComparison", "none")
        }

        return setChart($scope.pageOptions.counter.with)
      })
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
