export const createProxy = (obj) =>
  new Proxy(obj, {
    get: function (target, prop, receiver) {
      if (prop != "data" && prop in target.data) {
        return target.data[prop]
      } else {
        return Reflect.get(...arguments)
      }
    },
    set: function (target, prop, value, receiver) {
      if (prop != "data" && prop in target.data) {
        return (target.data[prop] = value)
      } else {
        return Reflect.set(...arguments)
      }
    },
  })

export const clone = (obj) => {
  return Object.assign(Object.create(Object.getPrototypeOf(obj)), obj)
}
