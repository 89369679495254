/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular.module("uCountitUiApp").config(($stateProvider) =>
  $stateProvider
    .state("app.admin", {
      abstract: true,
      url: "/admin",
      template: "<div ui-view></div>",
    })
    .state("app.admin.account", {
      url: "/account",
      templateUrl: "app/admin/account/account.html",
      controller: "AccountCtrl",
    })
    .state("app.admin.account.servicetoken", {
      url: "/:accountId/servicetoken",
      templateUrl: "app/admin/account/servicetoken/index.html",
      controller: "AccountServiceTokenCtrl",
    })
    .state("app.admin.accountgroup", {
      url: "/company/:companyId/account/group/:groupId",
      templateUrl: "app/admin/account/group/group-detail.html",
      controller: "AccountGroupDetailCtrl",
      controllerAs: "vm",
    })
    .state("app.admin.space", {
      url: "/space",
      templateUrl: "app/admin/space/space.html",
      controller: "SpaceCtrl",
    })
    .state("app.admin.agency", {
      url: "/agency",
      templateUrl: "app/admin/agency/agency.html",
      controller: "AgencyCtrl",
    })
    .state("app.admin.company", {
      url: "/company",
      templateUrl: "app/admin/company/company.html",
      controller: "CompanyCtrl",
    })
    .state("app.admin.store", {
      url: "/store",
      templateUrl: "app/admin/store/store.html",
      controller: "StoreCtrl",
    })
    .state("app.admin.storeAccess", {
      url: "/store/:storeId/access",
      templateUrl: "app/admin/store/store-access.html",
      controller: "StoreAccessCtrl",
    })
    .state("app.admin.storegroup", {
      url: "/company/:companyId/storegroup/:groupId",
      templateUrl: "app/admin/store/group/group-detail.html",
      controller: "StoreGroupManageCtrl",
      controllerAs: "vm",
    })
    .state("app.admin.storegroupAccess", {
      url: "/company/:companyId/store/group/:groupId/access",
      templateUrl: "app/admin/store/group/group-access.html",
      controller: "StoreGroupAccessCtrl",
      controllerAs: "vm",
    })
    .state("app.admin.sensor", {
      url: "/sensor",
      templateUrl: "app/admin/camera/camera.html",
      controller: "CameraCtrl",
    })
    .state("app.admin.statistic", {
      url: "/statistics",
      templateUrl: "app/admin/servicestats/servicestats.html",
      controller: "ServciceStatsCtrl",
    })
    .state("app.admin.resources", {
      url: "/resources",
      templateUrl: "app/admin/firmware/firmware.html",
      controller: "FirmwareCtrl",
    })
)

require("./restfulapi")
require("./s3fileapi")
require("./event")
