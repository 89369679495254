/** @jsx jsx */

import { connect } from "react-redux"
import { createAsync } from "../../ducks/lib/redux-async"
import { jsx } from "@emotion/react"
import Select from "react-select"
import propTypes from "prop-types"

import {
  selectors as CompanySelector,
  actions as CompanyActions,
} from "../../ducks/modules/company/model"

const CompanySelectFieldAsync = ({
  field,
  onChange,
  isPending,
  isFulfilled,
  isRejected,
  data,
  err,
  value,
  ...rest
}) => {
  const handleChange = (option) => {
    if (onChange) onChange(option)
  }

  const options = data ? data.map((d) => ({ value: d._id, label: d.name, data: d })) : []
  const optionValue = value ? options.find((o) => o.value == value) : null

  return (
    <Select
      {...rest}
      value={optionValue}
      loading={isPending}
      onChange={handleChange}
      field={field}
      options={options}
    />
  )
}

CompanySelectFieldAsync.propTypes = {
  ...Select.propTypes,
  isPending: propTypes.bool,
  isFulfilled: propTypes.bool,
  isRejected: propTypes.bool,
  data: propTypes.array.isRequired,
}

export const CompanySelectField = connect(
  (state, ownProps) => {
    const Model = CompanySelector.model(state)
    const data = Model.all(state).toModelArray()
    return {
      ...ownProps,
      isPending: CompanySelector.index.isPending(state)(),
      isFulfilled: CompanySelector.index.isFulfilled(state)(),
      isRejected: CompanySelector.index.isRejected(state)(),
      data,
    }
  },
  (dispatch, ownProps) => {
    return {
      actionFn: () => {
        dispatch(CompanyActions.index.request())
      },
    }
  }
)(
  createAsync({
    Pending: CompanySelectFieldAsync,
    Fulfilled: CompanySelectFieldAsync,
  })
)

export default CompanySelectField
