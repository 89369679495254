/** @jsx jsx */
import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo, useCallback } from "react" // eslint-disable-line no-unused-vars
import { css, jsx } from "@emotion/react"
import axios from "axios"
import { useAsync } from "react-async"
import Sensor from "../../lib/org/sensor"
import Company from "../../lib/org/company"
import Store from "../../lib/org/store"
import ReactSelect from "react-select"

const fetchStores = async ([{ companyId }]) => {
  const response = await axios.get(`/api/1.0/store?companyId=${companyId}`)
  if (response.data) {
    return response.data.map((d) => new Store(d)).sort((a, b) => a.name.localeCompare(b.name))
  } else {
    throw new Error("No store")
  }
}

const fetchSensors = async ([{ storeId }]) => {
  const response = await axios.get(`/api/1.0/camera?storeId=${storeId}`)
  if (response.data) {
    return response.data.map((d) => new Sensor(d)).sort((a, b) => a.name.localeCompare(b.name))
  } else {
    throw new Error("No sensor")
  }
}

const fetchAgencies = async () => {
  const response = await axios.get("/api/1.0/agency")
  if (response.data) {
    return response.data
  } else {
    throw new Error("No Agency")
  }
}

const fetchCompanies = async () => {
  const response = await axios.get("/api/1.0/company")
  if (response.data) {
    return response.data.map((d) => new Company(d)).sort((a, b) => a.name.localeCompare(b.name))
  } else {
    throw new Error("No company")
  }
}

const getDefaultOrg = (type, id, orgs) => {
  if (id) {
    const foundOrg = orgs.find((c) => c._id == id)
    if (foundOrg) {
      return foundOrg
    }
  }

  let localId =
    sessionStorage.getItem(`header_current_${type}`) ||
    localStorage.getItem(`header_current_${type}`)
  if (localId) {
    const foundOrg = orgs.find((c) => c._id == localId)
    if (foundOrg) {
      return foundOrg
    }
  }
  if (orgs.length > 0) {
    return orgs[0]
  }

  return null // No organication exists
}

const setDefaultOrg = (type, org) => {
  localStorage.setItem(`header_current_${type}`, org._id)
  sessionStorage.setItem(`header_current_${type}`, org._id)
}

const getMdiName = (orgType) => {
  switch (orgType) {
    case "company":
      return "domain"
    case "store-group":
      return "map-marker"
    case "store":
      return "store"
    case "sensor":
      return "camera"
    default:
      return ""
  }
}

const Selector = ({ options, selected, loading, onChange, placeholder }) => {
  const [selectedOption, setSelectedOption] = useState(null)
  useEffect(() => {
    if (selected) {
      setSelectedOption({
        label: (
          <span>
            <i className={`mdi mdi-${getMdiName(selected.value.orgType)} mdi-18px`} />{" "}
            {selected.label}
          </span>
        ),
        value: selected.value,
      })
    } else {
      setSelectedOption(null)
    }
  }, [selected])

  const handleChange = (opt) => {
    if (onChange) {
      onChange(opt.value)
    }
  }

  return (
    <ReactSelect
      options={options}
      placeholder={placeholder}
      isDisabled={options.length == 0}
      value={selectedOption}
      isLoading={loading}
      onChange={handleChange}
    />
  )
}

Selector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  selected: PropTypes.object,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
}

export const OrganizationSelector = ({
  selectCompany,
  selectStore,
  selectSensor,
  defaultCompanyId,
  defaultStoreId,
  defaultSensorId,
  onChangeCompany,
  onChangeStore,
  onChangeSensor,
  ...props
}) => {
  const {
    data: agenciesData,
    isPending: agencyPending,
    isSettled: agencySettled,
  } = useAsync({
    promiseFn: fetchAgencies,
  })
  const {
    data: companiesData,
    isPending: companyPending,
    isSettled: companySettled,
  } = useAsync({
    promiseFn: fetchCompanies,
  })
  const {
    data: storesData,
    run: runStore,
    isPending: storePending,
    isSettled: storeSettled,
  } = useAsync({
    deferFn: fetchStores,
  })
  const {
    data: sensorsData,
    run: runSensor,
    isPending: sensorPending,
    isSettled: sensorSettled,
  } = useAsync({
    deferFn: fetchSensors,
  })
  const [visibleSensor, setVisibleSensor] = useState(selectSensor)
  const [companies, setCompanies] = useState([])
  const [stores, setStores] = useState([])
  const [sensors, setSensors] = useState([])
  const [company, setCompany] = useState(null)
  const [store, setStore] = useState(null)
  const [sensor, setSensor] = useState(null)

  useEffect(() => {
    if (companiesData) {
      const company = getDefaultOrg("company", defaultCompanyId, companiesData)
      setCompanies(companiesData)
      setCompany(company)
    } else {
      setCompanies([])
      setCompany(null)
    }
  }, [companiesData])
  useEffect(() => {
    if (company) {
      if (selectStore || selectSensor) {
        runStore({ companyId: company._id })
      } else if (selectCompany) {
        setDefaultOrg("company", company)
        if (onChangeCompany) {
          onChangeCompany(company)
        }
      }
    } else {
      setStores([])
      setStore(null)
    }
  }, [company, selectStore, selectSensor])

  useEffect(() => {
    if (storesData) {
      let nextStores
      if (selectCompany) {
        const allStores = company.clone()
        allStores.name = "All Stores"
        nextStores = [allStores].concat(storesData)
      } else {
        nextStores = storesData
      }
      setStores(nextStores)
      const store = getDefaultOrg("store", defaultStoreId, nextStores)
      setStore(store)
    } else {
      setStores([])
      setStore(null)
    }
  }, [storesData, selectCompany])
  useEffect(() => {
    if (store) {
      if (store.orgType == "company") {
        if (selectCompany) {
          setDefaultOrg("company", company)
          if (onChangeCompany) {
            onChangeCompany(company)
          }
        }
        setVisibleSensor(false)
        setSensors([])
        setSensor(null)
      } else {
        if (selectSensor) {
          setVisibleSensor(true)
          runSensor({ storeId: store._id })
        } else if (selectStore) {
          setDefaultOrg("company", company)
          setDefaultOrg("store", store)
          if (onChangeStore) {
            onChangeStore(company, store)
          }
        }
      }
    } else {
      setSensors([])
      setSensor(null)
    }
  }, [store, selectCompany, selectStore, selectSensor, onChangeStore, onChangeCompany])

  useEffect(() => {
    if (sensorsData) {
      let nextSensors
      if (selectStore) {
        const allSensors = store.clone()
        allSensors.name = "All Sensors"
        nextSensors = [allSensors].concat(sensorsData)
      } else {
        nextSensors = sensorsData
      }
      setSensors(nextSensors)
      const sensor = getDefaultOrg("sensor", defaultSensorId, nextSensors)
      setSensor(sensor)
    } else {
      setSensors([])
      setSensor(null)
    }
  }, [sensorsData])
  useEffect(() => {
    if (sensor) {
      if (["store", "store-group"].indexOf(sensor.orgType) >= 0) {
        if (selectStore) {
          setDefaultOrg("company", company)
          setDefaultOrg("store", store)
          if (onChangeStore) {
            onChangeStore(company, store)
          }
        }
      } else {
        if (selectSensor) {
          setDefaultOrg("company", company)
          setDefaultOrg("store", store)
          setDefaultOrg("sensor", sensor)
          if (onChangeSensor) {
            onChangeSensor(company, store, sensor)
          }
        }
      }
    }
  }, [sensor, selectSensor, onChangeSensor])

  const handleChangeCompany = (value) => {
    setCompany(value)
  }
  const handleChangeStore = (value) => {
    setStore(value)
  }
  const handleChangeSensor = (value) => {
    setSensor(value)
  }

  const selectContainer = css`
    & {
      width: 170px;
      display: inline-block;
    }
  `
  const chevronStyle = css`
    & {
      padding-bottom: 6px;
    }
  `
  const makeOption = useCallback((org) => {
    if (org) {
      return {
        label: org.name,
        value: org,
      }
    }
  }, [])
  const companyOptions = useMemo(() => {
    const companyOptions = []
    if (agenciesData && companies) {
      for (const agency of agenciesData) {
        companyOptions.push({
          label: agency.name,
          options: companies
            .filter((c) => c._agencyId == agency._id)
            .map((c) => ({ label: c.name, value: c })),
        })
      }
    }

    return companyOptions
  }, [agenciesData, companies])

  return (
    <div {...props}>
      {!selectCompany && !selectStore && !selectSensor ? null : (
        <div css={selectContainer}>
          <Selector
            options={companyOptions}
            selected={makeOption(company)}
            loading={companyPending || agencyPending}
            placeholder={
              agencySettled && companySettled && companies.length == 0 ? "No Company" : ""
            }
            onChange={handleChangeCompany}
          />
        </div>
      )}
      {!selectStore && !selectSensor ? null : (
        <>
          <span>
            <i css={chevronStyle} className="mdi mdi-chevron-right" />
          </span>
          <div css={selectContainer}>
            <Selector
              options={stores ? stores.map(makeOption) : []}
              selected={makeOption(store)}
              loading={storePending}
              placeholder={storeSettled && stores.length == 0 ? "No Store" : ""}
              onChange={handleChangeStore}
            />
          </div>
        </>
      )}
      {!visibleSensor ? null : (
        <>
          <span>
            <i css={chevronStyle} className="mdi mdi-chevron-right" />
          </span>
          <div css={selectContainer}>
            <Selector
              options={sensors ? sensors.map(makeOption) : []}
              selected={makeOption(sensor)}
              loading={sensorPending}
              placeholder={sensorSettled && sensors.length == 0 ? "No Sensor" : ""}
              onChange={handleChangeSensor}
            />
          </div>
        </>
      )}
    </div>
  )
}

OrganizationSelector.propTypes = {
  defaultCompanyId: PropTypes.string,
  defaultStoreId: PropTypes.string,
  defaultSensorId: PropTypes.string,
  selectCompany: PropTypes.bool,
  selectStore: PropTypes.bool,
  selectSensor: PropTypes.bool,
  onChangeCompany: PropTypes.func,
  onChangeStore: PropTypes.func,
  onChangeSensor: PropTypes.func,
}

export default OrganizationSelector
