/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory("CameraHealthSrv", ($rootScope, $resource) =>
    $resource(
      $rootScope.globalConfig.apipath + "/camera/:id/health",
      { id: "@id" },
      {
        get: {
          isArray: true,
        },
      }
    )
  )
  .controller(
    "CameraHealthCtrl",
    function ($scope, $element, $window, Locale, NgTableParams, CameraHealthSrv) {
      $scope.cameraId = $scope.ngDialogData._id
      $scope.header = {
        date: Locale.string("Date"),
        log: Locale.string("Log"),
      }

      $scope.reload = function () {
        const from = moment().subtract(7, "day")
        $scope.from = from.toDate()
        return CameraHealthSrv.get(
          { id: $scope.cameraId, from: from.format("YYYMMDDHHmm"), _: Date.now() },
          (data) =>
            ($scope.tableParams = new NgTableParams(
              {
                count: 10,
                sorting: { at: "desc" },
              },
              {
                counts: [10, 25, 50],
                total: data.length,
                data,
              }
            ))
        )
      }

      return $scope.reload()
    }
  )
