/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .controller(
    "PosDriverCtrl",
    function ($rootScope, $scope, $resource, Auth, Locale, Upload, NgTableParams, me) {
      $scope.me = me
      if (!me.gteSpace()) {
        return
      }
      $scope.header = {
        name: Locale.string("Name"),
        date: Locale.string("Date"),
        size: Locale.string("Size"),
      }

      const config = $rootScope.globalConfig
      const posDriverUrl = `${config.apipath}/sales/posdriver`

      const PosDriver = function (node) {
        const path = posDriverUrl + (node != null ? `/${node}` : "")
        return $resource(
          path,
          { _: Date.now() },
          {
            get: {
              method: "GET",
              isArray: true,
            },
            update: {
              method: "PUT",
            },
          }
        )
      }

      $scope.listFiles = () =>
        PosDriver().get(
          {},
          (res) =>
            ($scope.tableParams = new NgTableParams(
              { count: 10 },
              { counts: [5, 10, 25], data: res, filterDelay: 0 }
            ))
        )

      $scope.removeFile = (file) => PosDriver().delete(file, (err) => $scope.reload())

      const _uploadFile = function (callback) {
        if ($scope.uploadFile) {
          return Upload.upload({
            url: posDriverUrl,
            file: $scope.uploadFile,
          }).then(
            (
              res // success
            ) => callback(),
            function (res) {
              // fail
              const err = { status: res.status, message: `Fail to upload posDriver ${res.data}` }
              return callback(err)
            },
            function (evt) {} // progress evt.loaded
          )
        } else {
          return callback()
        }
      }

      $scope.submitForm = () =>
        _uploadFile(function (err) {
          if (err) {
            console.error("err", err)
          }
          return $scope.reload()
        })

      $scope.reload = function () {
        if ($scope.uploadFile) {
          delete $scope.uploadFile
        }
        return $scope.listFiles()
      }

      return $scope.reload()
    }
  )
