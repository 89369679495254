"use strict"

export const HeaderStates = {
  "app.overview": {
    orgScope: "store",
    functionality: [],
  },
  "app.report": {
    orgScope: "store",
    functionality: [],
  },
  "app.footfall": {
    orgScope: "sensor",
    functionality: ["counter"],
  },
  "app.genderAge": {
    orgScope: "sensor",
    functionality: ["face", "rtFace"],
  },
  "app.queue": {
    orgScope: "store",
    functionality: ["queue"],
  },
  "app.sales": {
    orgScope: "store",
    functionality: ["sales"],
  },
  "app.heatmap": {
    orgScope: "sensor",
    functionality: ["heatmap", "dwellmap"],
  },
  "app.tracking-tree": {
    orgScope: "sensor",
    functionality: ["trackingtree"],
  },
  "app.path-tree": {
    orgScope: "store",
    functionality: ["pathtree"],
  },
  "app.sensor.list": {
    orgScope: "store",
    functionality: [],
  },
  "app.dashboard.queue": {
    orgScope: "store",
    functionality: ["queue"],
  },
  "app.zonetraffic": {
    orgScope: "sensor",
    functionality: ["zonetraffic"],
  },
  "app.occupancy": {
    orgScope: "sensor",
    functionality: ["occupancy"],
  },
  "app.directionmap": {
    orgScope: "sensor",
    functionality: ["directionmap"],
  },
  "app.admin.sensor": {
    orgScope: "store",
    functionality: [],
  },
  "app.admin.store": {
    orgScope: "store",
    functionality: [],
  },
  "app.system.configuration": {
    orgScope: "store",
    functionality: [],
  },
  "app.dashboard.face": {
    orgScope: "sensor",
    functionality: ["rtFace"],
  },
  "app.premiumReport": {
    orgScope: "company",
    functionality: ["report"],
  },
  "app.sitemap": {
    orgScope: "company",
    functionality: [],
  },
  "app.datadownload": {
    orgScope: "company",
    functionality: [],
  },
  "app.admin": {
    orgScope: "company",
    functionality: [],
  },
  "app.intro": {
    orgScope: "company",
    functionality: [],
  },
}

const HeaderStatesNames = Object.keys(HeaderStates)
  .sort((a, b) => a.localeCompare(b))
  .reverse()

export const isFitFunctionalityState = (functionality, stateName) => {
  let state
  for (const name of HeaderStatesNames) {
    if (stateName.includes(name)) {
      state = HeaderStates[name]
      break
    }
  }
  if (!state || state.functionality.length <= 0) return true
  return state.functionality.reduce((prev, cur) => {
    return prev || functionality[cur]
  }, false)
}

const createIsVisible = (orgs) => (stateName) => {
  let headerState
  for (const name of HeaderStatesNames) {
    if (stateName.includes(name)) {
      headerState = HeaderStates[name]
      break
    }
  }
  if (headerState) {
    const res = orgs.reduce((prev, cur) => {
      return prev || headerState.orgScope == cur
    }, false)
    return res
  } else return false
}

export const isVisibleCompany = createIsVisible(["company", "store", "sensor"])
export const isVisibleStore = createIsVisible(["store", "sensor"])
export const isVisibleCamera = createIsVisible(["sensor"])
