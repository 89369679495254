"use strict"

import { EnvStage } from "common-library/ucountit-env"
import { createApiUrls } from "./apiurls"
import { Required, Optional } from "./Environment"

const hostname = "aging.retailanalysis.cloud"
const config: EnvStage<Required, Optional> = {
  port: 19500,
  host: [hostname],
  api: Object.assign(createApiUrls(hostname), {
    rebuildurl: "http://172.17.0.1:19620/api/v1",
  }),

  web: {
    queueurl: "http://q-" + hostname,
    faceurl: "/facedashboard",
    tunnelUrl: "http://tunnel.retailanalysis.cloud",
  },

  log: {
    analyticTrackingId: "G-3CQJ3PD9PW",
  },

  s3: {
    static: {
      bucket: "ucount-it.stage",
      destination: hostname + "/static",
      region: "us-west-1",
      cloudfront: "",
    },
    firmware: {
      bucket: "ucount-it.stage",
      destination: hostname + "/firmware",
      region: "us-west-1",
    },
    sales: {
      bucket: "ucount-it.stage",
      region: "us-west-1",
      destination: hostname + "/sales",
    },
    face: {
      bucket: "ucount-it.stage",
      destination: hostname + "/faces",
      region: "us-west-1",
    },
    customAgency: {
      bucket: "ucount-it.stage",
      region: "us-west-1",
      destination: hostname + "/customAgency",
    },
  },

  email: {
    from: "notification@ucountit.com",
  },

  cloudFront: {
    bucket: "ucount-it.static",
    region: "us-west-1",
    prefix: "ui-dev",
    basePath: "https://cdn.retailanalysis.cloud",
  },

  sqs: {
    sales: {
      region: "us-west-1",
      requestParsingRawQueue:
        "https://sqs.us-west-1.amazonaws.com/163170497538/SalesRequestParsingRawQueue-dev",
      confirmWaitingdataQueue:
        "https://sqs.us-west-1.amazonaws.com/163170497538/SalesConfirmWaitingdataQueue-dev",
    },
  },

  brand: "aging",

  redis: {
    host: "172.17.0.1",
    port: "6379",
  },
}

export default config
