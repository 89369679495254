/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .controller("favoriteCtrl", function ($scope, Auth) {
    $scope.stores = []
    $scope.cameras = []
    $scope.selectStoreItem = function (idx) {
      if ($scope.stores.indexOf(true) === idx) {
        return ($scope.stores[idx] = false)
      }
      $scope.stores = __range__(0, $scope.favoriteStores.length, true).map((i) => false)
      return ($scope.stores[idx] = true)
    }

    return ($scope.selectCameraItem = function (idx) {
      if ($scope.cameras.indexOf(true) === idx) {
        return ($scope.cameras[idx] = false)
      }
      $scope.cameras = __range__(0, $scope.favoriteCameras.length, true).map((i) => false)
      return ($scope.cameras[idx] = true)
    })
  })

function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
