/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

import Promise from "bluebird"
import {
  isVisibleCompany,
  isVisibleStore,
  isVisibleCamera,
  HeaderStates,
  isFitFunctionalityState,
} from "./org-state"

// import crypto from "crypto"
import _ from "lodash"
import Company from "../../lib/org/company"
import Store from "../../lib/org/store"
import StoreGroup from "../../lib/org/store-group"
import Sensor from "../../lib/org/sensor"
import escapeRegexp from "escape-string-regexp"

let g_lastState = null
angular
  .module("uCountitUiApp")
  .controller(
    "HeaderCtrl",
    function (
      $rootScope,
      $scope,
      $location,
      $state,
      $transitions,
      $timeout,
      $filter,
      $http,
      Auth,
      ApiSrv,
      BrandSrv,
      Terms,
      CustomUISrv,
      Locale,
      HeaderSrv,
      $stateParams,
      ServiceTokenSrv
    ) {
      const { me } = $scope.$parent
      $scope.servicetoken = ServiceTokenSrv.getToken()
      $scope.CustomUISrv = CustomUISrv
      $scope.cameraSelector = { selected: null }
      $scope.storeSelector = { selected: null }
      $scope.companySelector = { selected: null }
      $scope.$stateParams = $stateParams

      $scope.timeList = {
        store: {
          icon: "mdi-store",
          currentDateTime: "",
        },
        browser: {
          icon: "mdi-monitor",
          currentDateTime: "",
        },
      }
      $scope.changedTime = null

      const setupClock = function () {
        const browserTime = moment()
        const storeTime = (
          $scope.storeSelector.selected != null ? $scope.storeSelector.selected.timezone : undefined
        )
          ? moment().tz($scope.storeSelector.selected.timezone)
          : browserTime
        $scope.timeList.browser.currentDateTime = Locale.dateTime(browserTime, "llll")
        return ($scope.timeList.store.currentDateTime = Locale.dateTime(storeTime, "llll"))
      }
      $rootScope.$on("localizeResourcesUpdated", setupClock)

      const initSelector = function (state, params) {
        let promise
        const { id } = params
        const { orgType } = params
        if (id === null || orgType === null) {
          return Promise.resolve()
        }

        if (orgType === Company.ORG_TYPE) {
          HeaderSrv.setCompanyId(id)
          HeaderSrv.setStoreId(id)
          HeaderSrv.setSensorId(id)
          promise = Promise.resolve()
        } else if (orgType === StoreGroup.ORG_TYPE) {
          HeaderSrv.setStoreId(id)
          HeaderSrv.setSensorId(id)
          promise = ApiSrv.getStoreGroup({ id }).then((storeGroup) =>
            HeaderSrv.setCompanyId(storeGroup._companyId)
          )
        } else if (orgType === Store.ORG_TYPE) {
          HeaderSrv.setStoreId(id)
          HeaderSrv.setSensorId(id)
          promise = ApiSrv.getStore({ id }).then((store) =>
            HeaderSrv.setCompanyId(store._companyId)
          )
        } else if (orgType === Sensor.ORG_TYPE) {
          HeaderSrv.setSensorId(id)
          promise = ApiSrv.getCamera({ id }).then(function (sensor) {
            HeaderSrv.setStoreId(sensor._storeId)
            return HeaderSrv.setCompanyId(sensor._companyId)
          })
        } else {
          promise = Promise.resolve()
        }

        return promise.then(() =>
          reloadCurrentCompany(state)
            .then(() => reloadCurrentStore(state))
            .then(() => reloadCurrentCamera(state))
            .catch(function (err) {
              console.info(err.toString())
              return console.error(err)
            })
        )
      }

      HeaderSrv.emitter.addListener("reload", () =>
        setImmediate(() => initSelector($state.current.name, $stateParams))
      )

      const everyStateInit = function (state, params, force) {
        if (force == null) {
          force = false
        }
        $scope.myName = Locale.fullName(me)
        $scope.myLanguage = me.language
        $scope.role = me.role.group
        if (force || (g_lastState !== state && HeaderStates[state])) {
          g_lastState = $state.current.name
          return initSelector(state, params)
        } else {
          return Promise.resolve()
        }
      }

      const init = function () {
        $scope.selectedTime = $scope.timeList.browser
        $scope.disableTimeSelector = true
        return everyStateInit($state.current.name, $stateParams, true)
          .then(() =>
            $transitions.onStart({}, (trans) => everyStateInit(trans.to().name, trans.params()))
          )
          .catch((err) => console.warn(err.toString()))
      }

      init()
      $scope.getCompanyAgencyName = function (company) {
        if (company.agency) {
          return company.agency.name
        } else {
          return "None?"
        }
      }

      $scope.visibleCompanySelector = () => isVisibleCompany($state.current.name)

      $scope.visibleStoreSelector = () => isVisibleStore($state.current.name)

      $scope.visibleCameraSelector = () =>
        isVisibleCamera($state.current.name) &&
        ($scope.storeSelector.selected != null
          ? $scope.storeSelector.selected.orgType
          : undefined) === "store"

      const nonParamStatePattern = /^app\.(admin|system)\./
      const reload = function () {
        let org
        if ($scope.visibleCameraSelector()) {
          org = $scope.cameraSelector.selected
        } else if ($scope.visibleStoreSelector()) {
          org = $scope.storeSelector.selected
        } else {
          org = $scope.companySelector.selected
        }

        const options = {}
        if (nonParamStatePattern.test($state.current.name)) {
          options.reload = true
        }
        return $state.go($state.current.name, { id: org._id, orgType: org.orgType }, options)
      }

      $scope.changeCompany = function (company) {
        if (HeaderSrv.getCompanyId() === company._id) {
          return Promise.resolve()
        }
        HeaderSrv.setCompanyId(company._id)
        HeaderSrv.setStoreId(company._id)
        HeaderSrv.setSensorId(company._id)
        return reloadCurrentCompany()
          .then(() => reloadCurrentStore())
          .then(() => reloadCurrentCamera())
          .then(function () {
            HeaderSrv.emitter.emit("changed", company)
            return reload()
          })
          .catch((err) => console.error(err))
      }

      $scope.changeStore = function (store) {
        if (HeaderSrv.getStoreId() === store._id) {
          return Promise.resolve()
        }
        $scope.changedTime = null
        HeaderSrv.setStoreId(store._id)
        HeaderSrv.setSensorId(store._id)
        return reloadCurrentStore()
          .then(function (store) {
            let promise = Promise.resolve()
            const companyId = HeaderSrv.getCompanyId()
            if (companyId !== store._companyId) {
              HeaderSrv.setCompanyId(store._companyId)
              promise = reloadCurrentCompany()
            }
            return promise
              .then(() => reloadCurrentCamera())
              .then(function () {
                HeaderSrv.emitter.emit("changed", store)
                return reload()
              })
          })
          .catch((err) => console.error(err))
      }

      $scope.changeCamera = function (camera) {
        if (HeaderSrv.getSensorId() === camera._id) {
          return Promise.resolve()
        }
        HeaderSrv.setSensorId(camera._id)
        return reloadCurrentCamera()
          .then(function (camera) {
            const promises = []
            const companyId = HeaderSrv.getCompanyId()
            if (companyId !== camera._companyId) {
              HeaderSrv.setCompanyId(companyId)
              promises.push(reloadCurrentCompany())
            }
            const storeId = HeaderSrv.getStoreId()
            if (storeId !== camera._storeId) {
              HeaderSrv.setStoreId(storeId)
              promises.push(reloadCurrentStore())
            }
            return Promise.all(promises).then(function () {
              HeaderSrv.emitter.emit("changed", camera)
              return reload()
            })
          })
          .catch((err) => console.error(err))
      }

      $scope.changeTime = function (time) {
        $scope.changedTime = time
        return ($scope.selectedTime = time)
      }

      // TODO: Move to HeaderSrv
      const fetchAll = (fetchFunc, idFunc, state = $state.current.name) =>
        Promise.map(fetchFunc(), (s) =>
          s
            .getFunctionality()
            .then(function (functionality) {
              s.grayOut = !isFitFunctionalityState(functionality, state)
              if (me.isStore() || me.isCompany()) {
                if (s.orgType == "sensor" && s.model == "footfall" && s.isVirtual) {
                  s.grayOut = true
                }
              }
              return s
            })
            .catch(function (err) {
              console.warn("getFunctionality", s, err)
              return Promise.reject(err)
            })
        )
          .then((orgs) => _.sortBy(orgs, ["grayOut"]))
          .then(function (orgs) {
            const id = idFunc()
            let current = orgs.find((org) => org._id === id)
            if (!current) {
              current = orgs[0]
            }
            return [current, orgs]
          })

      const fetchCompanySelectorData = (state) =>
        Auth.fetchCurrentAccount().then((me) =>
          fetchAll(HeaderSrv.fetchAllCompany, () => HeaderSrv.getCompanyId(me), state)
        )
      const fetchStoreSelectorData = (state) =>
        fetchAll(HeaderSrv.fetchAllStore, HeaderSrv.getStoreId, state)
      const fetchCameraSelectorData = (state) =>
        fetchAll(HeaderSrv.fetchAllSensor, HeaderSrv.getSensorId, state)

      var reloadCurrentCompany = (state) =>
        fetchCompanySelectorData(state)
          .catch((err) => console.info(err.message))
          .spread(function (curCompany, allCompanies) {
            $scope.sortedCompanies = $filter("orderBy")(allCompanies, ["agency.name", "name"])
            $scope.filteredCompanyList = (keyword) =>
              $filter("filter")($scope.sortedCompanies, function (comp) {
                const str = new RegExp(`.*${escapeRegexp(keyword)}.*`, "i")
                return (
                  __guard__(
                    __guard__(comp != null ? comp.agency : undefined, (x1) => x1.name),
                    (x) => x.match(str)
                  ) != null ||
                  __guard__(comp != null ? comp.name : undefined, (x2) => x2.match(str)) != null
                )
              })

            $scope.companySelector.selected = curCompany
            return curCompany
          })
          .catch((err) => console.error(err.toString()))

      var reloadCurrentStore = (state) =>
        fetchStoreSelectorData(state)
          .spread(function (curStore, allStores) {
            $scope.filteredAllStores = (keyword) =>
              $filter("filter")(allStores, function (store) {
                const str = new RegExp(`.*${escapeRegexp(keyword)}.*`, "i")
                return (
                  __guard__(store != null ? store.name : undefined, (x) => x.match(str)) != null
                )
              })
            // if there is only one store which is always 'My Stores'
            if (allStores && allStores.length === 1) {
              $scope.sortedStores = []
            } else {
              $scope.sortedStores = allStores
            }

            if (curStore.orgType === "store" && $scope.visibleStoreSelector()) {
              $scope.selectedTime = $scope.changedTime ? $scope.changedTime : $scope.timeList.store
              $scope.disableTimeSelector = false
            } else {
              $scope.selectedTime = $scope.timeList.browser
              $scope.disableTimeSelector = true
            }

            $scope.storeSelector.selected = curStore
            setupClock()
            return curStore
          })
          .catch((err) => console.error(err.toString()))

      var reloadCurrentCamera = (state) =>
        fetchCameraSelectorData(state).spread(function (curCamera, allCameras) {
          $scope.filteredAllCameras = (keyword) =>
            $filter("filter")(allCameras, function (camera) {
              // UCNT-5420 Hide Virtual Footfall Sensor from selector.
              if (!me.gteAdmin() && camera.model == "footfall") {
                return false
              }

              const str = new RegExp(`.*${escapeRegexp(keyword)}.*`, "i")
              return (
                __guard__(camera != null ? camera.name : undefined, (x) => x.match(str)) != null
              )
            })
          // if there is only one camera which is always 'All Cameras'
          if (allCameras && allCameras.length === 1) {
            $scope.sortedCameras = []
          } else {
            $scope.sortedCameras = allCameras
          }

          $scope.cameraSelector.selected = curCamera
          return curCamera
        })

      $scope.logout = function () {
        Auth.logout()
        ApiSrv.clearCache()
        // Angular Service, Factory를 다시 init하게 하려면 페이지를 리로드하게 하는 수 밖에는 없다.
        return location.replace("/login")
      }

      $scope.$on(
        "localizeResourcesUpdated-language",
        () => ($scope.myLanguage = Locale.getLanguage().split("-")[0])
      )

      return $scope.$on("localizeResourcesUpdated", () =>
        Auth.fetchCurrentAccount().then((account) => ($scope.myName = Locale.fullName(account)))
      )
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
