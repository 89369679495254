import EventEmitter from "events"
import _ from "lodash"

export class Directory extends EventEmitter {
  constructor(options) {
    super()
    this.setMaxListeners(512)
    this.data = {}
    this.idFunc = options.idFunc
    if (!this.idFunc) this.idFunc = (v) => v._id
    this.indexFunc = options.index
    this.indexQueryFunc = options.indexQuery
    this.getFunc = options.get
    this.updateFunc = options.update
    this.deleteFunc = options.delete
    this.createFunc = options.create
    this.mapFunc = options.map
    if (this.mapFunc == null) this.mapFunc = (a) => a

    this.indexPromise = null
    this.getPromise = {}
    this.getTimeout = {}
  }

  setIndex(values, now) {
    if (!this.data) this.data = {}
    values.forEach((v) => this.setEntry(this.idFunc(v), v, now))
  }

  index(cache = true, options = null) {
    let ret
    let params = null
    if (options && options.params) {
      params = options.params
      delete options.params
    }
    if (cache && this.indexPromise) {
      ret = this.indexPromise
    } else {
      ret = this.indexPromise = this.indexFunc(false, options).then((data) => {
        data = data.map(this.mapFunc)
        const now = Date.now()
        this.setIndex(data, now)
        return data
      })
    }

    return ret.then((data) => {
      if (params && Object.keys(params).length > 0) data = this.indexQueryFunc(data, params)
      // Shallow copy
      data = _.clone(data)
      for (const i in data) {
        data[i] = _.clone(data[i])
      }
      return data
    })
  }

  get(id, cache = true, options = {}) {
    if (!id) return Promise.reject("directory.get: No id assigned")
    if (!(cache && this.getPromise[id])) {
      this.getPromise[id] = this.getFunc(id, false, options).then((data) => {
        data = this.mapFunc(data)
        const now = Date.now()
        this.setEntry(id, data, now)
        if (this.indexPromise) {
          this.indexPromise = this.index(true).then((index) => {
            const idx = index.findIndex((a) => id == this.idFunc(a))
            if (idx >= 0) {
              index[idx] = data
            }
            return index
          })
        }
        return data
      })
    }

    return this.getPromise[id].then((item) => _.clone(item))
  }

  create(value, options = null) {
    return this.createFunc(value, options).then(() => {
      return this.index(false)
    })
  }

  update(id, value, options = null) {
    return this.updateFunc(id, value, options).then(() => {
      return this.get(id, false)
    })
  }

  delete(id, options = null) {
    return this.deleteFunc(id).then(() => {
      this.deleteEntry(id)
    })
  }

  deleteEntry(id) {
    this.emit("change", id, this.data[id])
    window.clearTimeout(this.getTimeout[id])
    this.data[id] = null
    this.getPromise[id] = null
    delete this.data[id]
  }

  setEntry(id, value, now) {
    this.data[id] = value
    this.getPromise[id] = Promise.resolve(value)
    this.data[id]._expireAt = now + 300000 // Specific data expire At
    window.clearTimeout(this.getTimeout[id])
    this.emit("change", id, this.data[id])
    this.getTimeout[id] = window.setTimeout(() => {
      this.data[id] = null
      this.getPromise[id] = null
      this.indexPromise = null
    }, 300000)
  }

  getEntry(id) {
    return this.data[id]
  }

  clear() {
    this.data = {}
  }
}

export default Directory
