/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

angular
  .module("uCountitUiApp")
  .directive(
    "storeListOverview",
    (
      $compile,
      $filter,
      ApiSrv,
      Auth,
      Face,
      StoreSalesSrv,
      usSpinnerService,
      SelectorSrv,
      HeaderSrv
    ) => ({
      restrict: "E",
      templateUrl: "components/overview/storelist/storelist-overview.html",

      scope: {
        storeList: "=",
        useFootfall: "=",
        useSales: "=",
        useFace: "=",
        referenceDate: "=",
      },

      replace: true,

      link(scope, element, attrs) {
        scope.ageTypeList = []
        let favoriteStores = []
        let tomorrow = null
        let referenceDate = null
        const loadingQueue = []

        scope.row = "name"

        HeaderSrv.fetchCurrentCompany().then(
          (company) => (scope.ageTypeList = Face.makeAgeTypeList(company.ageGroup.value))
        )

        const isNumber = (num) => !isNaN(parseFloat(num)) && isFinite(num)

        const showLoading = function (key) {
          if (key) {
            loadingQueue.push(key)
          }
          angular.element(".splash").show()
          return usSpinnerService.spin("spinner")
        }

        const hideLoading = function (key) {
          if (key) {
            _.remove(loadingQueue, (raw) => raw === key)
            if (loadingQueue.length) {
              return
            }
          }

          angular.element(".splash").hide()
          return usSpinnerService.stop("spinner")
        }

        const getFavoriteStores = (stores) =>
          ApiSrv.getFavoriteStores().then(function (response) {
            favoriteStores = response
            return stores.forEach(function (store, idx) {
              const favoriteIdx = _.findIndex(favoriteStores, { _id: store._id })
              return (store.favorite = favoriteIdx > -1 ? true : false)
            })
          })

        let removeIdxs = []
        const getContentsValue = function (store, callback) {
          const options = {
            id: store._id,
            orgType: "store",
          }

          const footfallOps = _.merge({}, options, {
            sampling: "1d",
            entrance: "true",
            from: moment(referenceDate).format("YYYYMMDD0000"),
            to: moment(tomorrow).format("YYYYMMDD0000"),
          })

          const faceOps = _.merge({}, options, {
            selectedSampling: "day",
            from: referenceDate,
            to: tomorrow,
          })

          const salesOps = {
            storeId: store._id,
            from: moment(referenceDate).format("YYYYMMDD"),
            to: moment(tomorrow).format("YYYYMMDD"),
          }

          return SelectorSrv.getOrganizationInfo(
            "store",
            store != null ? store._id : undefined
          ).then(function (orgInfo) {
            const storeFeatures = orgInfo.features
            store.useFaceCountOnly = orgInfo.useFaceCountOnly

            return async.parallel(
              {
                footfall(callback) {
                  if (scope.useFootfall) {
                    return ApiSrv.getContentsData(footfallOps)
                      .then((response) => callback(null, response))
                      .catch((err) => callback(err))
                  } else {
                    return callback(null)
                  }
                },
                sales(callback) {
                  if (scope.useSales) {
                    return StoreSalesSrv.getSalesdata(salesOps, function (err, sales) {
                      if (err) {
                        return callback(err)
                      }
                      return callback(null, sales)
                    })
                  } else {
                    return callback(null)
                  }
                },
                gender(callback) {
                  if (scope.useFace) {
                    return Face.getGenderData(faceOps, store.useFaceCountOnly).then(
                      (response) => callback(null, response),
                      (err) => callback(err)
                    )
                  } else {
                    return callback(null)
                  }
                },
                agegroup(callback) {
                  if (scope.useFace) {
                    return Face.getAgeGroupData(
                      faceOps,
                      scope.ageTypeList,
                      store.useFaceCountOnly
                    ).then(
                      (response) => callback(null, response),
                      (err) => callback(err)
                    )
                  } else {
                    return callback(null)
                  }
                },
              },
              function (err, results) {
                if (err) {
                  return callback(err)
                }

                const _isNoFeature = (features) => !_.includes(features, true)

                if (_isNoFeature(storeFeatures)) {
                  const idx = scope.storeList.indexOf(store)
                  removeIdxs.push(idx)
                } else {
                  store.noData = {
                    footfall: false,
                    female: false,
                    male: false,
                    percentfemale: false,
                    percentmale: false,
                    sales: false,
                  }

                  const ageGroup = {}
                  scope.ageTypeList.forEach((ageType) => (ageGroup[ageType.name] = false))
                  _.merge(store.noData, ageGroup)

                  if (scope.useFootfall) {
                    const footfallData = results.footfall.data
                    store.hasFootfall = storeFeatures != null ? storeFeatures.footfall : undefined
                    if (store.hasFootfall) {
                      if (
                        typeof __guard__(
                          footfallData != null ? footfallData[0] : undefined,
                          (x) => x.count
                        ) === "number"
                      ) {
                        store.footfall = __guard__(
                          footfallData != null ? footfallData[0] : undefined,
                          (x1) => x1.count
                        )
                      } else {
                        store.noData.footfall = true
                        store.footfall = "-"
                      }
                    } else {
                      store.footfall = "-"
                    }
                  }

                  if (scope.useFace) {
                    const genderData = results.gender != null ? results.gender[0] : undefined
                    const ageGroupData = results.agegroup != null ? results.agegroup[0] : undefined
                    const genderPercent = genderData != null ? genderData.percent : undefined
                    const ageGroupPercent = ageGroupData != null ? ageGroupData.percent : undefined

                    store.hasFace = storeFeatures != null ? storeFeatures.face : undefined
                    Face.genderList.forEach(function (gender) {
                      const key = gender.name

                      if (store.hasFace) {
                        const count = genderData != null ? genderData[key] : undefined
                        const percent = genderPercent != null ? genderPercent[key] : undefined
                        if (isNumber(count) && isNumber(percent)) {
                          store[key] = `${$filter("number")(count)}`
                          store[`percent${key}`] = `${$filter("number")(percent, 1)}%`
                        } else {
                          store[key] = count
                          store[`percent${key}`] = percent

                          if (store[key] !== "-" && store[`percent${key}`] !== "-") {
                            store[key] = ""
                            store[`percent${key}`] = ""
                            store.noData[key] = true
                            store.noData[`percent${key}`] = true
                          }
                        }

                        if (store.useFaceCountOnly) {
                          store[key] = ""
                          return (store.noData[key] = false)
                        }
                      } else {
                        store[key] = ""
                        return (store[`percent${key}`] = "")
                      }
                    })

                    scope.ageTypeList.forEach(function (ageType) {
                      const key = ageType.name

                      if (store.hasFace) {
                        const count = ageGroupData != null ? ageGroupData[key] : undefined
                        const percent = ageGroupPercent != null ? ageGroupPercent[key] : undefined
                        if (isNumber(count) && isNumber(percent)) {
                          store[key] = `${$filter("number")(count)}`
                          store[`percent${key}`] = `${$filter("number")(percent, 1)}%`
                        } else {
                          store[key] = count
                          store[`percent${key}`] = percent

                          if (store[key] !== "-" && store[`percent${key}`] !== "-") {
                            store[key] = ""
                            store[`percent${key}`] = ""
                            store.noData[key] = true
                            store.noData[`percent${key}`] = true
                          }
                        }

                        if (store.useFaceCountOnly) {
                          store[key] = ""
                          return (store.noData[key] = false)
                        }
                      } else {
                        store[key] = ""
                        return (store[`percent${key}`] = "")
                      }
                    })
                  }

                  if (scope.useSales) {
                    store.hasSales = store.sales != null ? store.sales.active : undefined
                    const salesData = results.sales
                    if (store.hasSales) {
                      if (
                        typeof (salesData[0] != null ? salesData[0].sales : undefined) === "number"
                      ) {
                        store.salesValue = salesData[0] != null ? salesData[0].sales : undefined
                      } else {
                        store.noData.sales = true
                        store.salesValue = "-"
                      }
                    } else {
                      ;("-")
                    }
                  }
                }

                return callback(null)
              }
            )
          })
        }

        const updateData = function () {
          if (!scope.storeList || !scope.storeList.length) {
            return
          }

          showLoading("store_list")

          removeIdxs = []

          return async.each(scope.storeList, getContentsValue, function (err) {
            if (err) {
              hideLoading("store_list")
              console.error(err)
              return
            }

            removeIdxs.forEach((i) => scope.storeList.splice(i, 1))

            scope.order(scope.row, true)

            return hideLoading("store_list")
          })
        }

        scope.order = function (rowName, force) {
          if (force == null) {
            force = false
          }
          if (scope.row === rowName && !force) {
            return
          }
          scope.row = rowName
          return (scope.storeList = $filter("orderBy")(scope.storeList, rowName))
        }

        scope.$watch("storeList", function (stores) {
          if (stores != null ? stores.length : undefined) {
            return getFavoriteStores(stores)
          }
        })

        return scope.$watch("referenceDate", function (newDate) {
          const sampling = "day"
          if (newDate) {
            referenceDate = moment(newDate).startOf(sampling)
            tomorrow = moment(newDate).add(1, sampling).startOf(sampling)
            if (scope.storeList) {
              return updateData()
            }
          }
        })
      },
    })
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
