import angular, { IScope } from "angular"
import DbApi from "common-library/types/DbApi"
import _ from "lodash"
import env from "../../../config/environment"

import Face = DbApi.Face

type EngineKindValue = {
  name: string
  value: Face.EngineKind
  depends: ("face" | "edgeFace")[]
  conflicts: ("face" | "edgeFace")[]
}

type ProcessorValue = { name: string; value: Face.CloudProcessor | Face.EdgeProcessor }

const EngineKinds: EngineKindValue[] = [
  { name: "Dual Engine", value: "dual", depends: ["face"], conflicts: ["edgeFace"] },
  { name: "Edge Engine", value: "edge", depends: ["edgeFace"], conflicts: [] },
]

const CloudProcessors: ProcessorValue[] = !env.isLocalgrey
  ? [
      { name: "Face++", value: "face++" },
      { name: "Amazon", value: "awsface" },
    ]
  : []

const EdgeProcessors: ProcessorValue[] = [
  {
    name: "Edge",
    value: "edge",
  },
  ...CloudProcessors,
]

interface DirectiveScope {
  engine: Face.Engine
  edgeOnly?: boolean
  normalOnly?: boolean
  isCreate?: boolean
  dirty?: boolean
}

interface ControllerScope extends DirectiveScope, IScope {
  engines: EngineKindValue[]
}

angular.module("uCountitUiApp").directive("faceForm", () => ({
  restrict: "E",
  templateUrl: "components/face/FaceForm.html",
  scope: {
    data: "=",
    dirty: "=",

    onChange: "=",
    targetOrg: "=", /// 'sensor' or 'company'
    disable: "=",
    noPersonalData: "=",

    edgeOnly: "=", /// display form for edgeFace sensor
    normalOnly: "=", /// display form for non-edgeFace sensor
    isCreate: "=",
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controller: ($scope: ControllerScope, Auth: any) => {
    Auth.fetchCurrentAccount().then((me: unknown) => {
      $scope.me = me
      $scope.$apply()
    })
    $scope.formValue = {}
    $scope.CloudProcessors = CloudProcessors
    $scope.EdgeProcessors = EdgeProcessors

    $scope.isCompanyForm = () => $scope.targetOrg == "company"
    $scope.isSensorForm = () => $scope.targetOrg == "sensor"
    $scope.engines = EngineKinds

    $scope.$watch("targetOrg", () => {
      $scope.targetOrg = $scope.targetOrg ?? "sensor"
      $scope.roleMark = $scope.isCompanyForm() ? ["system", "sc"] : ["agency", "sc"]
    })

    $scope.$watchGroup(["edgeOnly", "normalOnly"], () => {
      updateEngines()
      data2form()
    })

    const updateEngines = () => {
      $scope.engines = EngineKinds.filter((e) => {
        if ($scope.edgeOnly) {
          return e.depends.find((s) => s === "edgeFace")
        }
        if ($scope.normalOnly) {
          return e.depends.find((s) => s === "face")
        }

        return true
      })
    }

    const data2form = () => {
      updateEngines()
      $scope.formValue.useThrottling = $scope.data?.useThrottling ?? false
      $scope.formValue.retentionPeriod = $scope.data?.retentionPeriod ?? 7
      $scope.formValue.limitCount = $scope.data?.quota ? parseInt($scope.data.quota) : 1000

      $scope.formValue.engine = {}
      $scope.formValue.engine.kind =
        $scope.engines.find((k) => k.value == $scope.data?.engine?.kind) || $scope.engines[0]

      if ($scope.formValue.engine.kind.value == "dual") {
        $scope.formValue.engine.dual = {}
        $scope.formValue.engine.dual.gender =
          CloudProcessors.find((p) => p.value == $scope.data?.engine?.dual?.gender) ??
          CloudProcessors[0]
        $scope.formValue.engine.dual.age =
          CloudProcessors.find((p) => p.value == $scope.data?.engine?.dual?.age) ??
          CloudProcessors[0]
      }

      if ($scope.formValue.engine.kind.value == "edge") {
        $scope.formValue.engine.edge = {}
        $scope.formValue.engine.edge.gender =
          EdgeProcessors.find((p) => p.value == $scope.data?.engine?.edge?.gender) ??
          EdgeProcessors[0]
        $scope.formValue.engine.edge.age =
          EdgeProcessors.find((p) => p.value == $scope.data?.engine?.edge?.age) ?? EdgeProcessors[0]
      }

      return true
    }

    $scope.originalData = null
    $scope.$watch(
      "data",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (newVals: any, oldVals) => {
        if (newVals != null && $scope.originalData == null) {
          $scope.originalData = { ...newVals }
        }
        if (angular.equals(newVals, oldVals) && newVals != null) {
          return
        }
        if (newVals != null && oldVals != null) {
          newVals.$dirty = !angular.equals(newVals, $scope.originalData)
        }

        data2form()
      },
      true
    )

    $scope.$watch(
      "formValue",
      (newVals, oldVals) => {
        if (angular.equals(newVals, oldVals)) {
          return
        }
        $scope.handleChange()
      },
      true
    )

    // form2data
    $scope.handleChange = () => {
      const form = $scope.formValue
      if (form.engine.kind.value == "dual") {
        form.engine.dual = {
          gender: form.engine.dual?.gender ?? CloudProcessors[0],
          age: form.engine.dual?.age ?? CloudProcessors[0],
        }
      } else if (form.engine.kind.value == "edge") {
        form.engine.edge = {
          gender: form.engine.edge?.gender ?? EdgeProcessors[0],
          age: form.engine.edge?.age ?? EdgeProcessors[0],
        }
      }

      const data: {
        engine: Face.Engine
        useThrottling: boolean
        retentionPeriod: number
        quota: string
      } = {
        engine: {
          kind: form.engine.kind.value,
        },
        useThrottling: form.useThrottling ?? false,
        retentionPeriod: form.retentionPeriod ?? false,
        quota: form.limitCount == null ? "1000 per days" : `${form.limitCount} per days`,
      }

      if (data.engine.kind == "dual") {
        data.engine.dual = {
          gender: form.engine.dual?.gender.value,
          age: form.engine.dual?.age.value,
        }
      } else if ($scope.data.engine?.dual) {
        data.engine.dual = $scope.data.engine.dual
      }

      if (data.engine.kind == "edge") {
        data.engine.edge = {
          gender: form.engine.edge?.gender.value,
          age: form.engine.edge?.age.value,
        }
      } else if ($scope.data.engine?.edge) {
        data.engine.edge = $scope.data.engine.edge
      }

      if ($scope.onChange) {
        $scope.onChange(data)
      }
    }
  },
}))
