/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

import { getColors } from "../util/colors"

angular
  .module("uCountitUiApp")

  .factory("Direction", function (ApiSrv) {
    let Direction

    return (Direction = (function () {
      let kMaxResoultion = undefined
      let _transformCoordinateToPixel = undefined
      let _transformPixelToCoordinate = undefined
      let _transform2Pixel = undefined
      let _transform2Object = undefined
      Direction = class Direction {
        static initClass() {
          kMaxResoultion = 65535
          _transformCoordinateToPixel = (x, resolution) => (x * resolution) / kMaxResoultion

          _transformPixelToCoordinate = (x, resolution) =>
            Math.round((x * kMaxResoultion) / resolution)

          _transform2Pixel = function (item, width, height) {
            const pixelItem = _.cloneDeep(item)
            pixelItem.rgbcolor = `rgb(${pixelItem.color})`
            pixelItem.gates.forEach(function (gate) {
              let left = kMaxResoultion
              let top = kMaxResoultion
              gate.points.forEach(function (p) {
                p.x = _transformCoordinateToPixel(p.x, width)
                p.y = _transformCoordinateToPixel(p.y, height)
                if (p.x < left) {
                  left = p.x
                }
                if (p.y < top) {
                  return (top = p.y)
                }
              })
              gate.points.forEach(function (p) {
                p.x = p.x - left
                return (p.y = p.y - top)
              })

              gate.left = left < kMaxResoultion ? left : 0
              return (gate.top = top < kMaxResoultion ? top : 0)
            })

            return pixelItem
          }

          _transform2Object = function (pixelItem, width, height) {
            const item = _.cloneDeep(pixelItem)
            item.gates.forEach(function (gate) {
              gate.points.forEach(function (p) {
                p.x = _transformPixelToCoordinate(p.x + gate.left, width)
                return (p.y = _transformPixelToCoordinate(p.y + gate.top, height))
              })
              gate.left = 0
              return (gate.top = 0)
            })

            return item
          }
        }
        constructor(cameraID, bgWidth, bgHeight) {
          this.cameraID = cameraID
          this.bgWidth = bgWidth
          this.bgHeight = bgHeight
        }

        setBackgroundSize(width, height) {
          this.bgWidth = width
          return (this.bgHeight = height)
        }

        get(isCache) {
          if (isCache == null) {
            isCache = false
          }
          return ApiSrv.getDirectionConfigOfCamera({ id: this.cameraID, isCache })
            .then(function (res) {
              if ((res != null ? res.directions : undefined) == null) {
                res = {
                  mode: "",
                  directions: [],
                }
              }
              return res
            })
            .catch((err) => ({}))
        }

        set(data) {
          return ApiSrv.setDirectionConfigOfCamera({ id: this.cameraID }, data)
        }

        // transform origianl coordinate to fabric cooridinate
        transform2Pixel(items) {
          if (!items) {
            return []
          }
          return items.map((item) => _transform2Pixel(item, this.bgWidth, this.bgHeight))
        }

        // transform fabric coordinate to VCA Meta cooridinate
        transform2Meta(pixelItems) {
          if (!pixelItems) {
            return []
          }
          return pixelItems.map((item) => _transform2Object(item, this.bgWidth, this.bgHeight))
        }
      }
      Direction.initClass()
      return Direction
    })())
  })
  .factory("DirectionUtil", function () {
    const getDefaultItem = function (idx) {
      const color = getColors(idx, "zone traffic")
      return {
        idx,
        active: false,
        name: `P${idx}`,
        color,
        rgbcolor: `rgb(${color})`,
        gates: [],
      }
    }

    const getDefaultGate = function (idx, inout) {
      if (inout == null) {
        inout = "O"
      }
      return {
        idx,
        direction: "R",
        inout,
        linked: false,
        points: [
          { x: 0, y: 0 },
          { x: 100, y: 0 },
        ],
      }
    }

    return {
      getDefaultItem,
      getDefaultGate,
    }
  })
