/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

// import Promise from "bluebird"

angular
  .module("uCountitUiApp")

  .controller(
    "CameraViewCtrl",
    function (
      $scope,
      $filter,
      $state,
      $stateParams,
      $timeout,
      ApiSrv,
      Auth,
      CameraSrv,
      IotAdminSrv,
      NgTableParams,
      HeaderSrv,
      SelectorSrv
    ) {
      const vm = this
      $scope.vm = vm
      const { me } = $scope.$parent
      $scope.acc = me
      $scope.isCameras = true
      $scope.tile = []
      // filter
      $scope.searchKeywords = ""
      $scope.row = ["-active", "offline", "-count.today"]

      const sortInit = () => $scope.order($scope.row)

      const loadItems = function (items) {
        $scope.Items = items

        const _getData = function ($defer, params) {
          const sorting = $scope.row
          const count = params.count()
          $scope.pagecount = count

          $scope.filterItems = $filter("filter")($scope.Items, function (val) {
            const str = new RegExp(`.*${$scope.searchKeywords}.*`, "i")
            //val?.store?.name?.match(str)? or
            return __guard__(val != null ? val.name : undefined, (x) => x.match(str)) != null
          })

          params.total($scope.filterItems.length)
          const orderItems = $filter("orderBy")($scope.filterItems, sorting)
          const start = (params.page() - 1) * params.count()
          const end = start + params.count()
          $scope.currentPageItems = orderItems.slice(start, end)
          return $defer.resolve($scope.currentPageItems)
        }

        return ($scope.tableParams = new NgTableParams(
          { count: $scope.pagecount != null ? $scope.pagecount : 25 },
          { counts: [10, 25, 50, 100], getData: _getData }
        ))
      }

      $scope.enterKeyword = function (keyword) {
        $scope.searchKeywords = keyword
        return loadItems($scope.Items)
      }

      $scope.order = function (rowName) {
        if (!$scope.currentPageItems || $scope.currentPageItems.length < 1) {
          return
        }
        $scope.row = rowName
        return loadItems($scope.Items)
      }

      $scope.selectTileItem = function (idx, camera) {
        if (camera.isVirtual || !camera.accessKey) {
          return
        }
        if ($scope.tile.indexOf(true) === idx) {
          return ($scope.tile[idx] = false)
        }
        $scope.tile = __range__(0, vm.cameras.length, true).map((i) => false)
        return ($scope.tile[idx] = true)
      }

      const init = function () {
        let orgInfo = {}

        const _setCamera = function (camera, callback) {
          if ($stateParams.orgType === "store") {
            camera.store = orgInfo.store
          } else {
            camera.store = _.find(orgInfo.stores, { _id: camera._storeId })
          }

          return IotAdminSrv.Device().get(
            { uid: camera.accessKey, _: Date.now() },
            function (res) {
              camera.iotdevice = res != null ? res.data : undefined
              if (camera.isVirtual) {
                camera.offline = false
              } else {
                camera.offline =
                  !__guard__(res != null ? res.data : undefined, (x) => x.online) != null
                    ? !__guard__(res != null ? res.data : undefined, (x) => x.online)
                    : true
              }
              camera.offset = __guard__(
                __guard__(
                  __guard__(
                    __guard__(res != null ? res.data : undefined, (x4) => x4.description),
                    (x3) => x3.properties
                  ),
                  (x2) => x2.timezone
                ),
                (x1) => x1.offset
              )
              return callback(null)
            },
            (err) => callback(err)
          )
        }

        return SelectorSrv.getOrganizationInfo($stateParams.orgType, $stateParams.id, false)
          .then((res) =>
            HeaderSrv.fetchCurrentCompany().then(function (company) {
              orgInfo = res
              orgInfo.sensors =
                orgInfo.sensors != null
                  ? orgInfo.sensors.filter((c) => c.accessKey && !c.isVirtual)
                  : undefined
              if (!(orgInfo.sensors != null ? orgInfo.sensors.length : undefined)) {
                $scope.isCameras = false
              }
              return async.each(orgInfo.sensors, _setCamera, function (err, data) {
                if (err) {
                  console.error(err)
                  return
                }
                vm.cameras = orgInfo.sensors
                vm.company = company
                loadItems(vm.cameras)
                vm.blockSnapshot = company.isBlockedSnapshot(me)

                return sortInit()
              })
            })
          )
          .catch(function (err) {
            console.error(err)
            return $state.go("default")
          })
      }

      return init()
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
