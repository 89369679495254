/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular.module("uCountitUiApp").directive("zonetrafficChart", () => ({
  restrict: "E",
  replace: true,
  templateUrl: "app/content/zonetraffic/zonetraffic-chart.html",

  scope: {
    width: "=",
    height: "=",
    option: "=",
    data: "=",
  },

  controller($scope, $element) {
    const NODATA = "-"
    $scope.isNotAllData = false
    $scope.chartStyle = {}
    $scope.ready = false
    $scope.iconCode = "\u{F05C6}"

    const checkIsNotAllData = function (data) {
      if (_.isEmpty(data)) {
        return ($scope.isNotAllData = true)
      } else {
        return ($scope.isNotAllData = false)
      }
    }

    $scope.getZoneTrafficColor = function (zt) {
      if (!zt.color) {
        return
      }

      return {
        border: `5px solid ${zt.color}`,
      }
    }

    $scope.showNumber = function (val) {
      const parseVal = parseFloat(val)
      if (isNaN(parseVal)) {
        return NODATA
      } else {
        return parseVal
      }
    }

    $scope.$watch("data", (data) => checkIsNotAllData(data))

    return $scope.$watch(
      () =>
        JSON.stringify({
          width: $scope.width,
          height: $scope.height,
        }),
      function () {
        if (!$scope.width && !$scope.height) {
          return
        }

        $scope.ready = true
        return ($scope.chartStyle = {
          width: $scope.width + "px",
          height: $scope.height + "px",
          display: $scope.width < 360 ? "none" : "initial",
        })
      }
    )
  },
}))
