/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("i18n", function (Locale) {
    let i18nDirective
    return (i18nDirective = {
      restrict: "EA",
      updateText(ele, input, placeholder) {
        let result = undefined

        if (input === "i18n-placeholder") {
          result = Locale.string(placeholder)
          return ele.attr("placeholder", result)
        } else if (input.length >= 1) {
          result = Locale.string(input)
          ele.text(result)
          if ((ele.context != null ? ele.context.value : undefined) != null) {
            return (ele.context.value = result)
          }
        }
      },

      link(scope, ele, attrs) {
        scope.$on("localizeResourcesUpdated-language", () =>
          i18nDirective.updateText(ele, attrs.i18n, attrs.placeholder)
        )

        return attrs.$observe("i18n", (value) =>
          i18nDirective.updateText(ele, value, attrs.placeholder)
        )
      },
    })
  })
