"use strict"

import React, { useMemo } from "react"
import { useTable } from "react-table"
import propTypes from "prop-types"
import axios from "axios"
import Async from "react-async"

const ListEvent = ({ data, run, reload }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "_id",
        show: false,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Source",
        accessor: (c) => c.source.kind,
      },
      {
        Header: "Trigger",
        accessor: (c) => c.trigger.kind,
      },
      {
        Header: "Action",
        accessor: (c) => c.action.kind,
      },
      {
        Header: "Active",
        accessor: (c) => c.isActive,
        Cell: (c) => (c.cell.value ? <i className="mdi mdi-check" /> : null),
      },
      {
        Header: () => (
          <a ui-sref="app.admin.event.new" href="/admin/event/new">
            <i className="mdi mdi-playlist-plus mdi-18px" />
            &nbsp;
            <span>Add</span>
          </a>
        ),
        accessor: "_id",
        Cell: (c) => (
          <div>
            <a
              data-ui-sref={`app.admin.event.edit('${c.cell.value}')`}
              href={`/admin/event/${c.cell.value}/edit`}
            >
              <i className="mdi mdi-cog" />
            </a>
            <a href="#">
              <i
                className="mdi mdi-delete"
                onClick={() => {
                  if (window.confirm("Are you sure?")) {
                    run([c.cell.value]).then(reload)
                  }
                }}
              />
            </a>
          </div>
        ),
      },
    ],
    [run, data]
  )
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({ columns, data })

  return (
    <section className="panel panel-default">
      <div className="panel-heading">
        <strong>
          <span className="glyphicon glyphicon-th" />
          &nbsp; Event List
        </strong>
      </div>
      <div className="panel-body">
        <table {...getTableProps({ className: "table uc-base-table" })}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={`event-header-tr-${i}`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th key={`event-header-th-${i}`} {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {rows.map(
              (row, i) =>
                prepareRow(row) || (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          key={`event-cell-${cell.column.name}-${cell.row.values._id}`}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
    </section>
  )
}

ListEvent.propTypes = {
  reload: propTypes.func,
  run: propTypes.func,
  data: propTypes.array,
}

export const ListEventAsync = (props) => {
  const deleteEvent = ([eventId]) => {
    return axios.delete(`/api/1.0/event/${eventId}`)
  }
  const data = useMemo(() => props.data, [])
  const { reload } = props

  return (
    <Async deferFn={deleteEvent}>
      {(props) => <ListEvent {...props} reload={reload} data={data} />}
    </Async>
  )
}

ListEventAsync.propTypes = {
  data: propTypes.array.isRequired,
  reload: propTypes.func.isRequired,
}

export default ListEventAsync
