/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

angular
  .module("uCountitUiApp")
  .controller(
    "StoreSalesCtrl",
    function (
      $scope,
      $stateParams,
      $location,
      $filter,
      ApiSrv,
      ChartOptionSrv,
      DateTimeSrv,
      usSpinnerService,
      NgTableParams,
      ngDialog,
      SamplingSrv,
      StoreSalesSrv,
      Locale,
      ExportSrv
    ) {
      let key, sampling
      $scope.useSales = undefined
      $scope.orgType = $stateParams.orgType
      if ($scope.orgType === "store") {
        $scope.storeId = $stateParams.id
      } else if ($scope.orgType === "storegroup") {
        $scope.storeGroupId = $stateParams.id
      } else if ($scope.orgType === "company") {
        $scope.companyId = $stateParams.id
      }
      $scope.header = {
        date: Locale.string("Date"),
        footfall: Locale.string("Footfall"),
        tr: Locale.string("TR"),
        trFull: Locale.string("Transaction"),
        cr: Locale.string("CR"),
        crFull: Locale.string("Conversion Rate"),
        sales: Locale.string("Sales"),
        unit: Locale.string("Unit"),
        atv: Locale.string("ATV"),
        atvFull: Locale.string("Average Transaction Value"),
        upt: Locale.string("UPT"),
        uptFull: Locale.string("Unit Per Transaction"),
      }

      $scope.chartData = []
      $scope.chartOption = _.merge({}, ChartOptionSrv.barChartOption, {
        yaxes: [
          {
            position: "left",
          },
          {
            position: "right",
          },
        ],
        xaxis: {
          autoscaleMargin: 0.1,
        },
        series: {
          bars: {
            barWidth: 0.3,
            lineWidth: 1,
          },
        },
      })

      $scope.SAMPLING = {
        DAY: "day",
        WEEK: "week",
        MONTH: "month",
      }

      $scope.samplingList = (() => {
        const result = []
        for (key in $scope.SAMPLING) {
          sampling = $scope.SAMPLING[key]
          result.push({ key: sampling, sampling, title: `By ${sampling}` })
        }
        return result
      })()
      $scope.shortTermList = ["7D", "4W", "1M", "6M", "12M"]

      $scope.pageOptions = {
        storeId: $scope.storeId,
        storeGroupId: $scope.storeGroupId,
        companyId: $scope.companyId,
        sampling: $scope.SAMPLING.DAY,
        calendarSampling: $scope.SAMPLING.DAY,
      }

      let validTime = false

      const TABLEPARAM = {
        DAY: {
          sampling: $scope.SAMPLING.DAY,
          data: null,
          count: 14,
          counts: [7, 14, 50, 100],
        },
        WEEK: {
          sampling: $scope.SAMPLING.WEEK,
          data: null,
          count: 12,
          counts: [4, 12, 50, 100],
        },
        MONTH: {
          sampling: $scope.SAMPLING.MONTH,
          data: null,
          count: 12,
          counts: [12, 24, 100],
        },
      }

      const tableParam = function (unit) {
        key = unit.toLowerCase()
        switch (key) {
          case "day":
          case "days":
          case "d":
            return TABLEPARAM.DAY
          case "week":
          case "weeks":
          case "w":
            return TABLEPARAM.WEEK
          case "month":
          case "months":
          case "m":
            return TABLEPARAM.MONTH
          default:
            return TABLEPARAM.DAY
        }
      }

      const generateChartData = function (start, end, list, dateFieldName, countFieldName) {
        ;({ sampling } = $scope.pageOptions)
        const dataLength = end.diff(start, sampling)
        const dateFormat = {
          hour: "YYYYMMDDHHmm",
          day: "YYYYMMDD0000",
          week: "YYYYMMDD0000",
          month: "YYYYMM",
        }[sampling.toLowerCase() || "day"]

        const dataSet = __range__(0, dataLength, true).map(function (i) {
          let count
          const curDate = moment(start).add(i, sampling).startOf(sampling)
          const formatCurDate = moment(curDate).format(dateFormat)
          const findIdx = _.findIndex(list, function (o) {
            const dateTime = moment(o[dateFieldName], "YYYY-MM-DD").format(dateFormat)
            return dateTime === formatCurDate
          })

          if (
            countFieldName === "conversionRate" &&
            (list[findIdx] != null ? list[findIdx]["validFootfall"] : undefined) === false
          ) {
            count = 0
          } else {
            count = (list[findIdx] != null ? list[findIdx][countFieldName] : undefined) || 0
          }

          return [curDate, count]
        })

        return dataSet
      }

      const setChartData = function (list) {
        if (!list || !(list != null ? list.length : undefined)) {
          $scope.chartData = []
          return
        }

        const start = $scope.pageOptions.dt.startDate
        const end = $scope.pageOptions.dt.endDate
        ;({ sampling } = $scope.pageOptions)
        let dataLength = end.diff(start, sampling)
        dataLength++

        const crData = generateChartData(start, end, list, "localtime", "conversionRate")
        const salesData = generateChartData(start, end, list, "localtime", "sales")

        const crList = crData.map((crObj, i) => [i, crObj[1]])
        const salesList = salesData.map((salesObj, j) => [j, salesObj[1]])

        const chartData = []

        chartData.push({
          label: Locale.string("CR"),
          data: crList,
          color: ChartOptionSrv.chartColorList[3],
          bars: {
            order: 0,
            zero: false,
          },
          yaxis: 1,
        })

        chartData.push({
          label: Locale.string("Sales"),
          data: salesList,
          color: ChartOptionSrv.chartColorList[1],
          bars: {
            order: 1,
            zero: false,
          },
          yaxis: 2,
        })

        $scope.chartData = chartData

        const divisor = parseInt(dataLength / 10)
        const ticks = crData.map(function (d, idx) {
          const dateFormat = SamplingSrv[sampling].getTickFormat(d[0], false)
          if (dataLength >= 30) {
            if (idx % divisor === 0) {
              return [idx, Locale.dateTime(moment(d[0]), dateFormat)]
            }
          } else {
            return [idx, Locale.dateTime(moment(d[0]), dateFormat)]
          }
        })

        $scope.chartOption.xaxis.ticks = ticks

        const tooltipDateFormat = SamplingSrv[sampling].getFormat()

        $scope.chartOption.tooltip.content = function (label, x, y, item) {
          const dataIdx = item.dataIndex

          if (dataIdx < 0) {
            return
          }
          const localtime = Locale.dateTime(moment(crData[dataIdx][0]), tooltipDateFormat)
          const crValue = crList[dataIdx][1] ? $filter("number")(crList[dataIdx][1], 2) : "No Data"
          const salesValue = salesList[dataIdx][1]
            ? $filter("number")(salesList[dataIdx][1], 2)
            : "No Data"

          return [
            `<b>${localtime}</b><br/>`,
            `<svg width=10 height=10><rect width=5 height=10 fill=${$scope.chartData[0].color}></svg>`,
            `${Locale.string("CR")} : ${crValue}<br/>`,
            `<svg width=10 height=10><rect width=5 height=10 fill=${$scope.chartData[1].color}></svg>`,
            `${Locale.string("Sales")} : ${salesValue}`,
          ].join("")
        }

        return ($scope.chartOption.xaxis.autoscaleMargin =
          ChartOptionSrv.setBarChartMargin(dataLength))
      }

      const mergeSalesdata = function (sampling, originData) {
        let left
        originData =
          (left = originData != null ? originData : TABLEPARAM.DAY.data) != null ? left : []

        const _getEmptyObject = () => ({
          sales: 0,
          transaction: 0,
          unit: 0,
          validFootfall: true,
        })
        let lastDate = moment("1970-01-01")
        const samplingSet = {}
        originData.forEach(function (data) {
          key = moment(data.localtime).startOf(sampling).format("YYYY-MM-DD")
          if (!samplingSet[key]) {
            samplingSet[key] = _getEmptyObject()
          }

          samplingSet[key].sales += data.sales
          samplingSet[key].transaction += data.transaction
          samplingSet[key].unit += data.unit
          if (samplingSet[key].validFootfall) {
            samplingSet[key].validFootfall = data.validFootfall != null ? data.validFootfall : false
          }
          if (data.footfall) {
            if (!samplingSet[key].footfall) {
              samplingSet[key].footfall = 0
            }
            samplingSet[key].footfall += data.footfall
          }

          if (moment(data.localtime).isAfter(lastDate)) {
            return (lastDate = moment(data.localtime))
          }
        })

        const result = []
        for (key in samplingSet) {
          var item = samplingSet[key]
          item.localtime = key
          var samplingFormat = SamplingSrv[sampling].getFormat()
          if (sampling === $scope.SAMPLING.WEEK) {
            item.dateString = `${Locale.dateTime(moment(key), samplingFormat)} ~ \
${Locale.dateTime(moment(moment(key).endOf(sampling)), samplingFormat)}`
          } else {
            item.dateString = `${Locale.dateTime(moment(key), samplingFormat)}`
          }

          item.atv = item.sales / item.transaction
          item.upt = item.unit / item.transaction
          item.conversionRate = (item.transaction / item.footfall) * 100 || null

          result.push(item)
        }
        return result
      }

      const reloadTabledata = function (sampling) {
        if ($scope.salesDataTableParams) {
          $scope.salesDataTableParams.data = []
        }
        const param = tableParam(sampling)
        if (!param.data) {
          param.data = mergeSalesdata(param.sampling)
        }
        $scope.salesDataTableParams = new NgTableParams(
          {
            count: param.count,
            sorting: { localtime: "asc" },
          },
          { counts: param.counts, data: param.data }
        )
        $scope.downloadable = param.data.length > 0

        return setChartData(param.data)
      }

      const loadSalesdata = function (newDateRange) {
        if (!$scope.pageOptions.dt || !validTime) {
          return
        }

        $scope.dateChange = true
        const DATEFORMAT = "YYYYMMDD"
        return StoreSalesSrv.getSalesdata(
          {
            storeId: $scope.storeId,
            storeGroupId: $scope.storeGroupId,
            companyId: $scope.companyId,
            from: moment(newDateRange.startDate).format(DATEFORMAT),
            to: moment(newDateRange.endDate).add(1, "days").format(DATEFORMAT),
          },
          function (err, salesdataList) {
            TABLEPARAM.DAY.data = err ? [] : mergeSalesdata($scope.SAMPLING.DAY, salesdataList)
            TABLEPARAM.WEEK.data = null
            TABLEPARAM.MONTH.data = null
            reloadTabledata($scope.pageOptions.sampling)
            return ($scope.dateChange = false)
          }
        )
      }

      $scope.fileUpload = function () {
        const _destoryWaitingdata = function (workId, result, callback) {
          $scope.rawFile = null
          const params = {
            workId,
            result,
          }
          return StoreSalesSrv.destroy(params, function (err, data) {
            if (err) {
              console.error(err)
            }
            return callback(err, data)
          })
        }

        if (!$scope.rawFile) {
          return
        }
        return ngDialog
          .openConfirm({
            template: "app/content/sales/addsales.html",
            className: "ngdialog-theme-default addsales",
            controller: "AddSalesCtrl",
            showClose: false,
            scope: $scope,
          })
          .then(
            (value) =>
              _destoryWaitingdata(value, "ok", (err, data) => loadSalesdata($scope.pageOptions.dt)),
            (reason) => _destoryWaitingdata(reason, "cancel", function (err, data) {})
          )
      }

      $scope.salesdataExport = function (exportFormat) {
        const _getDateString = function (sampling, localtime) {
          switch (sampling) {
            case $scope.SAMPLING.MONTH:
              return `${Locale.dateTime(moment(localtime), "YYYY-MM")}`
            case $scope.SAMPLING.WEEK:
              return `${Locale.dateTime(moment(localtime), "YYYY-MM-DD(ddd)")} ~ \
${Locale.dateTime(moment(localtime).endOf(sampling), "YYYY-MM-DD(ddd)")}`
            default:
              return `${Locale.dateTime(moment(localtime), "YYYY-MM-DD(ddd)")}`
          }
        }

        ;({ sampling } = $scope.pageOptions)
        const param = tableParam(sampling)
        if (!param.data) {
          param.data = mergeSalesdata(sampling)
        }
        const salesList = _.orderBy(param.data, "localtime", "asc")
        const csvDataList = _.map(salesList, function (item) {
          let left
          const csvData = {
            [Locale.string("Date")]: _getDateString(sampling, item.localtime),
            [Locale.string("Footfall")]: `${item.footfall != null ? item.footfall : ""}`,
            [Locale.string("TR")]: `${item.transaction}`,
            [Locale.string("CR")]: `${
              (left = item.conversionRate != null ? item.conversionRate.toFixed(4) : undefined) !=
              null
                ? left
                : ""
            }`,
            [Locale.string("Sales")]: `${item.sales.toFixed(2)}`,
            [Locale.string("Units")]: `${item.unit}`,
            [Locale.string("ATV")]: `${item.atv.toFixed(2)}`,
            [Locale.string("UPT")]: `${item.upt.toFixed(2)}`,
          }
          return csvData
        })

        const DATEFORMAT = "YYYYMMDD"
        return ExportSrv.exportFile(csvDataList, {
          contentname: "Sales",
          companyname: $scope.company.name,
          storename: $scope.store.name,
          startdate: `${moment($scope.pageOptions.dt.startDate).format(DATEFORMAT)}`,
          enddate: `${moment($scope.pageOptions.dt.endDate).format(DATEFORMAT)}`,
          sampling: `${sampling}`,
          exportFormat,
          header: [
            `${Locale.string("Date")}`,
            `${Locale.string("Footfall")}`,
            `${Locale.string("TR")}`,
            `${Locale.string("CR")}`,
            `${Locale.string("Sales")}`,
            `${Locale.string("Units")}`,
            `${Locale.string("ATV")}`,
            `${Locale.string("UPT")}`,
          ],
        })
      }

      $scope.reload = function (fromInit) {
        if (fromInit == null) {
          fromInit = false
        }
        if (fromInit) {
          validTime = true
        }
        return loadSalesdata($scope.pageOptions.dt)
      }

      const init = () =>
        async.waterfall(
          [
            function (callback) {
              if ($scope.orgType === "store") {
                return ApiSrv.getStore({ id: $scope.storeId }).then((store) =>
                  callback(null, { name: store.name, data: [store] })
                )
              } else if ($scope.orgType === "storegroup") {
                const stores = []
                const storeLimit = 5
                return ApiSrv.getStoreGroup({ id: $scope.storeGroupId }).then((storeGroup) =>
                  async.eachLimit(
                    storeGroup.storeList,
                    storeLimit,
                    (sId, next) =>
                      ApiSrv.getStore({ id: sId }).then(function (store) {
                        stores.push(store)
                        return next()
                      }),
                    (err) => callback(null, { name: storeGroup.naem, data: stores })
                  )
                )
              } else if ($scope.orgType === "company") {
                return ApiSrv.getAllStore({ companyId: $scope.companyId }).then((stores) =>
                  callback(null, { name: "AllStores", data: stores })
                )
              } else {
                return callback("no store")
              }
            },
            function (storesInfo, callback) {
              let useSales = false
              let mainStore = null
              let minOffset = 10000
              const defaultTimezone = "UTC"
              storesInfo.data.forEach(function (store) {
                if (!useSales) {
                  useSales = store.sales != null ? store.sales.active : undefined
                }
                if (!store.timezone) {
                  store.timezone = defaultTimezone
                }
                if (!mainStore) {
                  return (mainStore = store)
                } else {
                  const sOffset = moment.tz(store.timezone).utcOffset()
                  if (minOffset > sOffset) {
                    mainStore = store
                    return (minOffset = sOffset)
                  }
                }
              })
              if (!mainStore) {
                return callback("no store")
              }

              return ApiSrv.getCompany({ id: mainStore._companyId }).then(function (company) {
                if (!(company.sales != null ? company.sales.active : undefined)) {
                  return callback("sales disabled")
                }
                $scope.company = company
                mainStore.name = storesInfo.name
                $scope.useSales = useSales
                return callback(null, mainStore)
              })
            },
          ],
          function (err, store) {
            if (err) {
              return $location.path("/stores/list")
            }
            $scope.store = store
            return $scope.$broadcast("init-range-search", store.timezone)
          }
        )

      return init()
    }
  )

function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
