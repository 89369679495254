/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

angular
  .module("uCountitUiApp")

  .factory("CameraSrv", function ($q, ApiSrv, IotAdminSrv, DateTimeSrv) {
    const getCompareYesterday = function (options) {
      let params
      const deffered = $q.defer()
      if (options.company) {
        params = { companyId: options.company._companyId }
      } else if (options.store) {
        params = { storeId: options.store._storeId }
      } else {
        params = {}
      }

      async.waterfall(
        [
          (callback) =>
            ApiSrv.getAllStore(params).then(function (res) {
              const stores = _.map(res, (element) => ({
                _id: element._id,
                name: element.name,
                labels: element.labels,
                timezone: element.timezone || "",
              }))
              return callback(null, stores)
            }),

          (stores, callback) =>
            ApiSrv.getAllCamera(params).then(function (response) {
              for (var camera of Array.from(response)) {
                camera.store = _.find(stores, { _id: camera._storeId })
              }
              return callback(null, response)
            }),
        ],
        (err, cameras) =>
          async.each(
            cameras,
            (camera, callback) =>
              IotAdminSrv.Device().get({ uid: camera.accessKey, _: Date.now() }, function (res) {
                camera.offline =
                  !__guard__(res != null ? res.data : undefined, (x) => x.online) != null
                    ? !__guard__(res != null ? res.data : undefined, (x) => x.online)
                    : true
                camera.offset = __guard__(
                  __guard__(
                    __guard__(
                      __guard__(res != null ? res.data : undefined, (x4) => x4.description),
                      (x3) => x3.properties
                    ),
                    (x2) => x2.timezone
                  ),
                  (x1) => x1.offset
                )
                return callback()
              }),
            (err) => deffered.resolve(cameras)
          )
      )

      return deffered.promise
    }

    return {
      getCompareYesterday,
    }
  })

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
