/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory("Terms", function ($rootScope, $uibModal, Auth) {
    const _open = function (options, callback) {
      const scope = $rootScope.$new()
      scope.options = options

      const modalInstance = $uibModal.open({
        animation: false,
        templateUrl: "components/terms/terms.html",
        controller: "TermsCtrl",
        keyboard: false,
        backdrop: "static",
        scope,
      })

      return modalInstance.result.then(
        () => callback(null),
        () => (typeof callback === "function" ? callback("decline") : undefined)
      )
    }

    const show = function (options) {
      options.agreemode = false
      return _open(options)
    }

    const checkAgreeTerm = function (options, callback) {
      options.agreemode = true
      return Auth.fetchCurrentAccount().then(function (me) {
        if (
          me &&
          (!__guard__(me != null ? me.terms : undefined, (x) => x.length) ||
            !__guard__(me != null ? me.terms[0] : undefined, (x1) => x1.agreement))
        ) {
          return _open(options, (err) =>
            typeof callback === "function" ? callback(err) : undefined
          )
        } else {
          return typeof callback === "function" ? callback(null) : undefined
        }
      })
    }

    return {
      show,
      checkAgreeTerm,
    }
  })

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
