/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("premiumReportSetting", () => ({
    restrict: "E",
    templateUrl: "app/admin/company/premium-report/premium-report-setting.html",
    controller: "PremiumReportSettingCtrl",

    scope: {
      isOpen: "=",
      selectedItem: "=",
      storeList: "=",
      onApply: "=",
    },
  }))
