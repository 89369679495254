/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"
import async from "async"

const heatmapCommon = require("../../heatmappanel/heatmapCommon")
const mng = require("common-library/product-core/heatmapData")

angular
  .module("uCountitUiApp")
  .directive("heatmapOverview", ($compile, $filter, ApiSrv, ChartOptionSrv, Locale, UtilSrv) => ({
    restrict: "E",
    templateUrl: "components/overview/heatmap/heatmap-overview.html",

    scope: {
      storetimezone: "=",
      cameras: "=cameras",
      referenceDate: "=",
      heatmapWeight: "=",
      heatmapStats: "=",
      showType: "=",
    },

    replace: true,

    link(scope, element, attrs) {
      scope.TYPE = {
        PASS: "pass",
        DWELL: "dwell",
        DUAL: "dual",
      }
      scope.validTime = false
      scope.heatmapList = []

      const heatmapCameras = []
      heatmapCommon.changeGridSize("init")

      const getCamera = (camera, callback) =>
        ApiSrv.getCamera({ id: camera._id }).then(
          function (cam) {
            heatmapCameras.push(cam)
            return callback(null)
          },
          (err) => callback(err)
        )

      scope.$watch("storetimezone", function (timezone) {
        if (timezone) {
          return (scope.validTime = true)
        }
      })

      const getHeatmapList = function (showType) {
        switch (showType) {
          case scope.TYPE.PASS:
          case scope.TYPE.DWELL:
            return [`${showType}`]
          case scope.TYPE.DUAL:
            return [scope.TYPE.PASS, scope.TYPE.DWELL]
          default:
            return []
        }
      }

      scope.$watch("cameras", function (cameras) {
        if (cameras.length) {
          scope.heatmapList = getHeatmapList(scope.showType)
          scope.displayClass =
            cameras.length === 1 || scope.showType === scope.TYPE.DUAL ? "col-sm-12" : "col-sm-6"
          cameras =
            cameras != null
              ? cameras.filter(
                  (camera) =>
                    __guard__(
                      camera.functions != null ? camera.functions.use : undefined,
                      (x) => x.heatmap
                    ) ||
                    __guard__(
                      camera.functions != null ? camera.functions.use : undefined,
                      (x1) => x1.dwellmap
                    )
                )
              : undefined

          return async.each(cameras, getCamera, function (err, data) {
            if (err) {
              console.error(err)
              return
            }

            scope.heatmapCameras = heatmapCameras
            return scope.$apply()
          })
        }
      })

      scope.$watch("referenceDate", function (newDate) {
        if (newDate) {
          return (scope.param = {
            start: moment(newDate).startOf("day"),
            end: moment(newDate).endOf("day"),
            sampling: "day",
          })
        }
      })

      const totalStatsList = {}

      const calcTotalStats = function (stats) {
        let id
        if (!(id = heatmapCommon.findStatId(stats))) {
          return
        }

        if (!totalStatsList[id]) {
          totalStatsList[id] = {}
        }
        for (var mapname in stats) {
          totalStatsList[id][mapname] = stats[mapname]
        }
        const tstats = []
        scope.heatmapCameras.map(function (cam) {
          if (totalStatsList[cam.accessKey] != null) {
            return tstats.push(totalStatsList[cam.accessKey])
          }
        })

        const gstats = mng.calcTotalStats(stats, tstats)
        heatmapCommon.setGlobalStats(gstats)

        return gstats
      }

      return scope.$on("heatmap_stats", (event, stats) =>
        scope.$broadcast("heatmap_stats_global", calcTotalStats(stats))
      )
    },
  }))

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
