/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS202: Simplify dynamic range loops
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

import Promise from "bluebird"
import { getColors } from "../../../components/util/colors"
import env from "../../../../config/environment"
const heatmapCommon = require("../../../components/heatmappanel/heatmapCommon")
const mng = require("common-library/product-core/heatmapData")

angular
  .module("uCountitUiApp")
  .controller(
    "ZoneTrafficCtrl",
    function (
      $scope,
      $element,
      $stateParams,
      $state,
      $filter,
      $q,
      $compile,
      UtilSrv,
      SelectorSrv,
      DateTimeSrv,
      ChartOptionSrv,
      GridOptionSrv,
      ApiSrv,
      Locale,
      SamplingSrv,
      Heatmaptag,
      usSpinnerService,
      HeaderSrv
    ) {
      const SAMPLING = {
        HOUR: "hour",
        DAY: "day",
        MONTH: "month",
      }

      $scope.validTime = false
      $scope.isCamera = false
      $scope.analysisTrackWorkerStatus = {}

      $scope.pageOptions = {
        storeId: $stateParams.id,
        sampling: SAMPLING.DAY,
        displayOption: {
          showData: "dwell",
        },
        zone: {
          with: null,
        },
        content: "zonetraffic",
      }

      const chartOption = ChartOptionSrv.lineChartOption
      chartOption.yaxis.min = 0
      chartOption.legend = {
        container: angular.element("#legendContainer", $element),
        noColumns: 0,
        labelFormatter(label, series) {
          setMiniChartColor(label, series.color)
          return label
        },
      }

      $scope.chartOption = chartOption
      $scope.miniChartOption = ChartOptionSrv.miniLineChartOption

      const gridOption = _.merge(
        {
          onRegisterApi(gridApi) {
            return (this.gridApi = gridApi)
          },
          getGridApi() {
            return this.gridApi
          },
        },
        GridOptionSrv.gridExportOption
      )

      $scope.gridOptions = gridOption
      $scope.countList = []
      $scope.curTimeBarIdx = null
      $scope.ztChartData = []
      $scope.ztChartOption = {}
      $scope.backgroundInfo = {}
      $scope.gridComment = `ts(n-1) < ${Locale.string("time")} <= ts(n)`

      const orgTimeSegments = [{ name: "~10" }, { name: "~30" }, { name: "~60" }, { name: "60~" }]

      const samplingKey = function (val) {
        switch (val) {
          case "hour":
            return "1h"
          case "day":
            return "1d"
          case "week":
            return "1w"
          case "month":
            return "1M"
          default:
            return "1d"
        }
      }

      const showLoading = function () {
        angular.element(".splash").show()
        usSpinnerService.spin("spinner")
        return angular.element(".spinner").css("position", "fixed")
      }

      const hideLoading = function () {
        angular.element(".splash").hide()
        return usSpinnerService.stop("spinner")
      }

      const isValidPeriod = function (start, end, sampling) {
        const limitDate = SamplingSrv[sampling].getLimitDate()
        const diffNum = moment(end).diff(start, limitDate.key)

        if (diffNum > limitDate.value) {
          return false
        } else {
          return true
        }
      }

      const getDefaultData = function (start, end, sampling) {
        let dbEnd, dbStart
        const data = []
        const dwellTimes = []
        const timeSegments = []

        //INFO UCNT-4703 use dbtime to fix monthly date bug
        const samplingStart = (dbStart = start.clone().utc(true).startOf(sampling))
        const samplingEnd = (dbEnd = end.clone().utc(true).startOf(sampling))

        let dataLength = samplingEnd.diff(samplingStart, sampling)
        if (sampling === "week" || sampling === "month") {
          dataLength++
        }

        for (
          let i = 0, end1 = dataLength, asc = 0 <= end1;
          asc ? i < end1 : i > end1;
          asc ? i++ : i--
        ) {
          if (i === 0 && samplingStart < start) {
            data.push([dbStart, null])
            dwellTimes.push(null)
            timeSegments.push(null)
          } else {
            var newDate = moment(dbStart).startOf(sampling).add(i, sampling)
            if (newDate < end) {
              data.push([newDate, null])
              dwellTimes.push(null)
              timeSegments.push(null)
            }
          }
        }

        return {
          data,
          dwellTimes,
          totalCount: 0,
          startDate: dbStart,
          endDate: dbEnd,
          sampling,
          timeSegments,
        }
      }

      const getStoreCountData = function (chartData, options) {
        const deffered = $q.defer()
        const localStartDate = moment.utc(options.from, "YYYYMMDDHHmm")
        const localEndDate = moment.utc(options.to, "YYYYMMDDHHmm")
        const splitJobLength = 30
        const splitJobCount = Math.ceil(localEndDate.diff(localStartDate, "day") / splitJobLength)

        async.times(
          splitJobCount,
          function (n, callback) {
            const start = moment(localStartDate).add(n * splitJobLength, "days")
            let end = moment(start).add(splitJobLength, "days")
            if (end.isAfter(localEndDate)) {
              end = localEndDate
            }
            options.from = start.format("YYYYMMDDHHmm")
            options.to = end.format("YYYYMMDDHHmm")

            return ApiSrv.getCountOfStore(options).then((response) =>
              callback(
                null,
                !__guard__(response != null ? response.data : undefined, (x) => x.length)
                  ? null
                  : response
              )
            )
          },
          function (error, responses) {
            if (error) {
              return deffered.reject(error)
            }

            for (var res of Array.from(responses)) {
              if (!res) {
                continue
              }
              _.forEach(res.data, function (raw) {
                let dataIdx
                if (chartData.length >= 1) {
                  dataIdx = _.findIndex(chartData, function (n) {
                    if (!n.key) {
                      return 0
                    }
                    return n.key === raw.labelId
                  })
                  if (dataIdx < 0) {
                    return deffered.resolve(chartData)
                  }
                }
                const selectedData = chartData[dataIdx] != null ? chartData[dataIdx] : chartData
                const { sampling } = selectedData
                const idx = moment
                  .utc(raw.statisticFor)
                  .startOf(sampling)
                  .diff(moment(localStartDate).startOf(sampling), sampling)

                if (selectedData.data[idx]) {
                  if (sampling === "hour") {
                    selectedData.data[idx][1] = raw.count
                    if (selectedData.beforeDay !== moment.utc(raw.statisticFor).date()) {
                      selectedData.beforeCount = 0
                    }

                    selectedData.data[idx][1] -= selectedData.beforeCount
                    selectedData.beforeCount = raw.count
                    selectedData.beforeDay = moment.utc(raw.statisticFor).date()

                    if (selectedData.data[idx][1] < 0) {
                      selectedData.data[idx][1] = 0
                    }
                    return (selectedData.totalCount += selectedData.data[idx][1])
                  } else {
                    selectedData.data[idx][1] += raw.count
                    return (selectedData.totalCount += raw.count)
                  }
                }
              })
            }

            return deffered.resolve(chartData)
          }
        )

        return deffered.promise
      }

      const getRoiHeatmapData = function (dataSet, options, startDate, sampling) {
        let timeGroup = []
        return ApiSrv.getRoiHeatmapOfCamera(options).then(function (data) {
          if (__guard__(data[0] != null ? data[0].timeGroup : undefined, (x) => x.length)) {
            timeGroup = Object.assign(data[0].timeGroup)
          } else if (
            __guard__(
              __guard__(data[0] != null ? data[0].zones[0] : undefined, (x2) => x2.timeGroup),
              (x1) => x1.length
            )
          ) {
            timeGroup = Object.assign(data[0].zones[0].timeGroup)
          }

          const startDbDate = moment
            .parseZone(startDate.format("YYYYMMDD"), "YYYYMMDD")
            .startOf(sampling)
          data.forEach((item) =>
            item.zones.forEach(function (zone) {
              const dataIdx = _.findIndex(
                dataSet,
                (d) => d.key === `${item._cameraId}/${zone._zoneId}`
              )
              if (dataIdx < 0) {
                return
              }

              //INFO UCNT-4703 localtime causes date bug for monthly data
              const idx = moment
                .parseZone(item.datetime)
                .startOf(sampling)
                .diff(startDbDate, sampling)

              if (dataSet[dataIdx].data[idx]) {
                dataSet[dataIdx].data[idx][1] = zone.count
                dataSet[dataIdx].dwellTimes[idx] = _.round(zone.average, 1)
                dataSet[dataIdx].timeSegments[idx] = zone.group
                return (dataSet[dataIdx].totalCount += zone.count)
              }
            })
          )
          return timeGroup
        })
      }

      let prevOptions = {}

      const displayTimeFormat = function (val) {
        let n, unit
        if (val >= 60) {
          n = val / 60
          unit = Locale.string("symbol_min")
        } else {
          n = val
          unit = Locale.string("symbol_sec")
        }

        return `${parseFloat(n.toFixed(1))}${unit}`
      }

      const setNewTimeGroup = function (timeGroup) {
        let newTimeGroup = _.merge({}, orgTimeSegments)
        if (timeGroup.length > 2) {
          let ntg = timeGroup
          const last = { name: `${displayTimeFormat(ntg[ntg.length - 2])}~` }
          ntg = ntg.slice(0, +-2 + 1 || undefined).map((tg, i, arr) => {
            if (i == 0) {
              return { name: `~${displayTimeFormat(tg)}` }
            } else {
              return { name: `${displayTimeFormat(arr[i - 1])}~${displayTimeFormat(tg)}` }
            }
          })
          ntg.push(last)
          newTimeGroup = ntg
        }
        return newTimeGroup
      }

      const checkNoData = (dataSet) =>
        _.isEmpty(dataSet) ||
        _.every(dataSet != null ? dataSet.data : undefined, (d) => _.isNull(d[1]))

      const sortGridData = function (dataArr) {
        if (_.isEmpty(dataArr)) {
          return dataArr
        }

        const sortedArr = _.sortBy(dataArr, ["storeName", "sensorName", "zoneName", "date"])

        return _.map(sortedArr, (d) => _.omit(d, ["date"]))
      }

      const showWeather = (sampling) =>
        __guard__($scope.store != null ? $scope.store.weather : undefined, (x) => x.locationId) !=
          null &&
        sampling !== "month" &&
        !env.isLocalgrey

      const getWeatherData = function (from, to, sampling) {
        if (!showWeather(sampling)) {
          return Promise.resolve([])
        }

        const option = {
          locationId: $scope.store.weather.locationId,
          from,
          to,
          language: Locale.getLanguage().split("-")[0],
        }
        if (sampling === "day") {
          option.sampling = sampling
        }

        return ApiSrv.getWeatherObservationSafeData(option)
      }

      const isBetweenPeriod = function (hmtag, startDate, endDate) {
        const start = startDate.clone().utc(true)
        const end = endDate.clone().utc(true)

        return start.isBefore(hmtag.to) && end.isAfter(hmtag.from)
      }

      const load = function () {
        let promise
        if ($scope.ztCameras.length === 0) {
          return
        }

        let newTimeGroup = []
        const { sampling } = $scope.pageOptions
        const startDate = moment($scope.pageOptions.dt.startDate)
        const endDate = moment($scope.pageOptions.dt.endDate)

        const period = startDate.format("YYYYMMDD") + "-" + endDate.format("YYYYMMDD")
        gridOption.filename = $scope.isCamera
          ? `${$scope.store.name}_${$scope.ztCameras[0].name}_${period}`
          : `${$scope.store.name}_${period}`
        gridOption.exporterCsvFilename = gridOption.filename + ".csv"

        const options = {
          from: startDate.format("YYYYMMDDHHmm"),
          to: endDate.add(1, "days").startOf("day").format("YYYYMMDDHHmm"),
          sampling: samplingKey(sampling),
        }

        if (_.isEqual(prevOptions, options)) {
          return
        }
        if (!isValidPeriod(startDate, endDate, sampling)) {
          return
        }

        prevOptions = options

        showLoading()

        let dataSet = []
        let colorIdx = 0
        $scope.pageOptions.zone.with = []
        let idx = 0

        const entranceColor = $scope.isCamera
          ? getColors(1, "standardlabel")
          : getColors(colorIdx++)
        const entranceData = _.merge(
          {
            key: "Entrance",
            label: `${$scope.store.name}\n(Entrance)`,
            tagname: "Entrance",
            color: `rgb(${entranceColor})`,
          },
          getDefaultData(startDate, endDate, sampling)
        )

        let newOptions = _.merge({}, options, { id: $scope.store._id, entrance: true })

        if ($scope.useFootfall) {
          promise = getStoreCountData(entranceData, newOptions).then((result) =>
            dataSet.push(result)
          )
        } else {
          promise = Promise.resolve()
        }

        return promise
          .then(() =>
            Promise.map($scope.ztCameras, function (camera) {
              newOptions = _.merge({}, options, {
                id: camera._id,
                sampling: options.sampling.toUpperCase(),
              })

              camera.tags.forEach(function (hmtag) {
                if (!isBetweenPeriod(hmtag, startDate, endDate)) {
                  return
                }

                return hmtag.heatmaptags
                  .filter((tag) => tag.active)
                  .forEach(function (tag, idx) {
                    const chartColor = $scope.isCamera ? tag.color : getColors(colorIdx++)
                    return dataSet.push(
                      _.merge(
                        {
                          key: `${camera._id}/${tag._id}`,
                          label: `${camera.name}\n(${tag.name})`,
                          tagname: tag.name,
                          color: `rgb(${chartColor})`,
                        },
                        getDefaultData(startDate, endDate, sampling)
                      )
                    )
                  })
              })

              return getRoiHeatmapData(dataSet, newOptions, startDate, sampling).then(function (
                tg
              ) {
                if (tg != null ? tg.length : undefined) {
                  return (newTimeGroup = tg)
                }
              })
            })
          )
          .then(() => getWeatherData(options.from, options.to, sampling))
          .then(function (weather) {
            let asc, end1
            let isNoData = true
            dataSet.forEach(function (row, i) {
              if (!checkNoData(row)) {
                isNoData = false
                return $scope.pageOptions.zone.with.push(i++)
              }
            })

            if (isNoData) {
              dataSet = []
              $scope.chartData = dataSet
              $scope.isGridData = false
              $scope.countList = []
              $scope.pageOptions.zone.with = null
              hideLoading()
              return
            }

            $scope.countList = dataSet
            setChart($scope.pageOptions.zone.with)
            setZtChartData()

            const dataLength = _.first(dataSet).data.length
            if (dataLength > 70) {
              chartOption.lines.lineWidth = 1
              chartOption.points.show = false
            } else {
              chartOption.lines.lineWidth = 3
              chartOption.points.show = true
            }

            const samplingTime = sampling === "hour" && dataLength > 70 ? "day" : sampling

            chartOption.xaxis.minTickSize = [1, samplingTime]
            chartOption.xaxis.tickFormatter = function (val) {
              const dbtime = moment.utc(val)
              if (samplingTime === "day") {
                dbtime.startOf("day")
              }
              let label = `${Locale.dateTime(dbtime, samplingTime)}`

              if (weather.hasData) {
                const w = weather[samplingTime][dbtime.format()]
                if (w != null) {
                  const min =
                    (w.temperature != null ? w.temperature.min : undefined) != null
                      ? `${w.temperature.min}°`
                      : "-"
                  const max =
                    (w.temperature != null ? w.temperature.max : undefined) != null
                      ? `${w.temperature.max}°`
                      : "-"
                  label += `\n${w.weatherSymbol}` + ` ${min}/${max}`
                }
              }
              return label
            }

            const showGridSet = []
            const gridSet = []
            const columnSet = []
            const timeFieldName =
              sampling === "hour" ? Locale.string("time from") : Locale.string("time")

            columnSet.push({
              field: "time",
              minWidth: 180,
              cellClass: "text-center",
              displayName: timeFieldName,
            })
            columnSet.push({
              field: "storeName",
              minWidth: 150,
              cellClass: "text-left",
              displayName: Locale.string("Store Name"),
            })
            columnSet.push({
              field: "sensorName",
              minWidth: 150,
              cellClass: "text-left",
              displayName: Locale.string("Sensor Name"),
            })
            columnSet.push({
              field: "zoneName",
              minWidth: 150,
              cellClass: "text-left",
              displayName: Locale.string("Zone Name"),
            })
            columnSet.push({
              field: "entrance",
              minWidth: 110,
              cellClass: "text-right",
              displayName: Locale.string("Store Entrance"),
            })
            columnSet.push({
              field: "drawRate",
              minWidth: 110,
              cellClass: "text-right",
              displayName: Locale.string("draw rate") + "(%)",
            })
            columnSet.push({
              field: "zoneCount",
              minWidth: 100,
              cellClass: "text-right",
              displayName: Locale.string("Zone Count"),
            })
            columnSet.push({
              field: "zoneAvg",
              minWidth: 140,
              cellClass: "text-right",
              displayName: `${Locale.string("Zone Average")}(${Locale.string("sec")})`,
            })

            newTimeGroup = setNewTimeGroup(newTimeGroup)
            for (
              idx = 0, end1 = newTimeGroup.length, asc = 0 <= end1;
              asc ? idx < end1 : idx > end1;
              asc ? idx++ : idx--
            ) {
              columnSet.push({
                field: `timeSegment-${idx}`,
                displayName: newTimeGroup[idx].name,
                minWidth: 68,
                cellClass: "text-right",
              })
            }

            if (weather.hasData) {
              columnSet.push({
                name: "weather",
                field: "weather",
                minWidth: 120,
                cellClass: "text-left",
                displayName: Locale.string("Weather"),
              })
            }

            const samplingFormat = SamplingSrv[sampling].getFormat()
            const isoFormat = SamplingSrv[sampling].getIsoDayFormat()
            gridOption.columnDefs = columnSet

            for (
              let i = 0, end2 = dataLength - 1, asc1 = 0 <= end2;
              asc1 ? i <= end2 : i >= end2;
              asc1 ? i++ : i--
            ) {
              var showTimerange, timerange
              var end
              var currentDate = moment(dataSet[0].data[i][0])
              switch (sampling) {
                case "hour":
                case "day":
                  showTimerange = Locale.dateTime(currentDate, samplingFormat)
                  timerange = Locale.dateTime(currentDate, isoFormat)
                  break
                case "week":
                case "month":
                  if (i === dataLength - 1) {
                    end = moment(endDate).subtract(1, "days")
                  } else {
                    end = moment(dataSet[0].data[i + 1][0]).subtract(1, "days")
                  }

                  showTimerange = `${Locale.dateTime(
                    currentDate,
                    samplingFormat
                  )} ~ ${Locale.dateTime(end, samplingFormat)}`
                  timerange = `${Locale.dateTime(currentDate, isoFormat)} ~ ${Locale.dateTime(
                    end,
                    isoFormat
                  )}`
                  break
              }

              for (
                var j = 0, end3 = dataSet.length, asc2 = 0 <= end3;
                asc2 ? j < end3 : j > end3;
                asc2 ? j++ : j--
              ) {
                var asc3, end4
                if (j === 0 && dataSet[j].key === "Entrance") {
                  continue
                }

                var entrance = $scope.useFootfall ? dataSet[0].data[i][1] : null
                var item = dataSet[j]
                if (_.isNull(entrance) && _.isNull(item.data[i][1])) {
                  continue
                }

                var gridItem = {}
                gridItem["date"] = currentDate
                gridItem["entrance"] = entrance != null ? entrance : ""
                gridItem["storeName"] = $scope.store.name
                gridItem["sensorName"] = $scope.getCameraName(item.label)
                gridItem["zoneName"] = item.tagname
                gridItem["drawRate"] =
                  !entrance || !item.data[i][1]
                    ? ""
                    : _.round((item.data[i][1] / entrance) * 100, 1)
                gridItem["zoneCount"] = item.data[i][1] != null ? item.data[i][1] : ""
                gridItem["zoneAvg"] = item.dwellTimes[i] != null ? item.dwellTimes[i] : ""

                for (
                  idx = 0, end4 = newTimeGroup.length, asc3 = 0 <= end4;
                  asc3 ? idx < end4 : idx > end4;
                  asc3 ? idx++ : idx--
                ) {
                  gridItem[`timeSegment-${idx}`] =
                    (item.timeSegments[i] != null ? item.timeSegments[i][idx] : undefined) != null
                      ? item.timeSegments[i] != null
                        ? item.timeSegments[i][idx]
                        : undefined
                      : ""
                }

                if (weather.hasData) {
                  var wSummary
                  var w = weather[sampling][item.data[i][0].format()]
                  if (w != null) {
                    var min =
                      (w.temperature != null ? w.temperature.min : undefined) != null
                        ? `${w.temperature.min}°`
                        : "-"
                    var max =
                      (w.temperature != null ? w.temperature.max : undefined) != null
                        ? `${w.temperature.max}°`
                        : "-"
                    wSummary = `${w.weatherSymbol}` + ` ${min}/${max}`
                    if (w.rain > 0 || [4, 5].includes(w.weatherCode)) {
                      wSummary += `  ${w.rain}mm`
                    }
                  }
                  gridItem["weather"] = wSummary != null ? wSummary : ""
                }

                showGridSet.push(_.merge({}, gridItem, { time: showTimerange }))
                gridSet.push(_.merge({}, gridItem, { time: timerange }))
              }
            }

            $scope.gridOptions.data = sortGridData(showGridSet)
            $scope.gridOptions.exportData = sortGridData(gridSet)
            if (showGridSet.length > 0) {
              $scope.isGridData = true
            }
            $scope.ztChartOption.timeGroup = newTimeGroup

            return hideLoading()
          })
          .catch(function (err) {
            console.error(err)
            return hideLoading()
          })
      }

      $scope.reload = function (fromInit) {
        if (fromInit == null) {
          fromInit = false
        }
        if (fromInit) {
          $scope.validTime = true
        }

        $scope.heatmapParam = {
          start: $scope.pageOptions.dt.startDate,
          end: $scope.pageOptions.dt.endDate,
          sampling: $scope.pageOptions.sampling,
        }
        return load()
      }

      $scope.selectChart = function (idx) {
        if (_.findIndex($scope.pageOptions.zone.with, (n) => n === idx) >= 0) {
          _.remove($scope.pageOptions.zone.with, (n) => n === idx)
        } else {
          $scope.pageOptions.zone.with.push(idx)
        }

        setChart($scope.pageOptions.zone.with)
        return setZtChartData($scope.curTimeBarIdx)
      }

      $scope.getMiniChartStyle = function (index, countData) {
        if ($scope.pageOptions.zone.with.indexOf(index) >= 0) {
          if (["Entrance"].indexOf(countData.key) >= 0) {
            return "select-chart-on-standard"
          } else {
            return "select-chart-on"
          }
        } else {
          return ""
        }
      }

      $scope.getMiniChartLabel = function (index, countData) {
        if (["Entrance"].indexOf(countData.key) >= 0) {
          return "mini-chart-label-standard"
        } else {
          return "mini-chart-label"
        }
      }

      var setMiniChartColor = function (label, color) {
        const idx = _.findIndex($scope.countList, { label })
        if (idx >= 0) {
          return ($scope.countList[idx].color = color)
        }
      }

      var setChart = function (dataList) {
        const dataSet = []
        _.forEach(dataList, function (idx, n) {
          if (!$scope.countList[idx]) {
            return
          }

          const data = angular.copy($scope.countList[idx])
          data["lines"] = { fill: n === 0 }

          return dataSet.push(data)
        })

        return ($scope.chartData = dataSet)
      }

      $scope.getCameraName = (label) => label.split("\n")[0]

      $scope.getChartColor = function (data) {
        if (!data.color) {
          return
        }

        return {
          border: `5px solid ${data.color}`,
        }
      }

      $scope.selectAllCountBy = function (selection) {
        if (selection) {
          $scope.pageOptions.zone.with = _.range($scope.countList.length)
        } else {
          $scope.pageOptions.zone.with = []
        }

        setChart($scope.pageOptions.zone.with)
        return setZtChartData($scope.curTimeBarIdx)
      }

      const fetchTrackWorkerStatus = (company) =>
        ApiSrv.getTrackStatus({ ops: "analysisTrackWorker" })
          .then(function (status) {
            if (status.start == null || status.end != null) {
              return
            }
            if (
              (status.start.data != null ? status.start.data.companyId : undefined) !==
                company._id ||
              (status.start.data != null ? status.start.data.contents : undefined) !== "zoneTraffic"
            ) {
              return
            }

            $scope.analysisTrackWorkerStatus.start = { at: status.start.at }

            if (status.running != null) {
              return ($scope.analysisTrackWorkerStatus.running = {
                at: status.running.at,
                progress: `${status.running.data.done}/${status.running.data.count}`,
              })
            }
          })
          .catch((err) => console.error(err))

      const init = function () {
        heatmapCommon.changeGridSize("init")

        if ($stateParams.orgType === "sensor") {
          $scope.isCamera = true
        }

        return HeaderSrv.fetchCurrentCompany()
          .then((company) => fetchTrackWorkerStatus(company))
          .then(() => SelectorSrv.getOrganizationInfo($stateParams.orgType, $stateParams.id))
          .then(function (orgInfo) {
            $scope.store = orgInfo.store
            $scope.storetimezone = orgInfo.timezone
            $scope.heatmapWeight = orgInfo.store.heatmapWeight
            $scope.heatmapStats = orgInfo.store.stats

            $scope.ztCameras =
              orgInfo.sensors != null
                ? orgInfo.sensors.filter((camera) =>
                    __guard__(
                      camera.functions != null ? camera.functions.use : undefined,
                      (x) => x.zonetraffic
                    )
                  )
                : undefined
            $scope.ztCameras = $filter("orderBy")($scope.ztCameras, "name")
            $scope.useFootfall = orgInfo.features.footfall

            return Promise.map($scope.ztCameras, function (camera) {
              camera.storeName = $scope.store.name

              const heatmaptag = new Heatmaptag(camera._id)
              return heatmaptag.getTags({ all: true }).then((tags) => (camera.tags = tags))
            })
          })
          .then(function () {
            if ($stateParams.orgType === "sensor") {
              return SelectorSrv.getStore($scope.store.id).then(
                (storeInfo) => ($scope.useFootfall = storeInfo.features.footfall)
              )
            } else {
              return Promise.resolve()
            }
          })
          .then(() => $scope.$broadcast("init-range-search", $scope.storetimezone))
          .catch(function (err) {
            console.error(err)
            return $state.go("default")
          })
      }

      init()

      var setZtChartData = function (index = null) {
        const dataList = _.filter($scope.chartData, (i) => i.key.toLowerCase() !== "entrance")
        if (_.isEmpty(dataList)) {
          return ($scope.ztChartData = [])
        }

        const { sampling } = $scope.pageOptions
        if (_.isNull(index)) {
          const dataLength = dataList[0].data.length
          if (sampling === "hour" && dataLength > 12) {
            index = () => 12 + Math.floor((dataLength - 12) / 24) * 24 //get last noon
          } else {
            index = dataLength - 1
          }
        }
        $scope.curTimeBarIdx = index
        $scope.ztChartOption.dateTime = Locale.dateTime(
          dataList[0].data[index] != null ? dataList[0].data[index][0] : undefined,
          SamplingSrv[sampling].getIsoFormat()
        )

        const chartData = []
        dataList.forEach((item) =>
          chartData.push({
            key: item.key,
            sensorName: $scope.getCameraName(item.label),
            zoneName: item.tagname,
            color: item.color,
            zoneCount: __guard__(item.data != null ? item.data[index] : undefined, (x) => x[1]),
            zoneAvg: item.dwellTimes != null ? item.dwellTimes[index] : undefined,
            timeSegments: item.timeSegments != null ? item.timeSegments[index] : undefined,
          })
        )

        return ($scope.ztChartData = chartData)
      }

      $scope.$on("heatmap_timebar_update", (e, index) => setZtChartData(index))

      const totalStatsList = {}

      const calcTotalStats = function (stats) {
        let id
        if (!(id = heatmapCommon.findStatId(stats))) {
          return
        }

        if (!totalStatsList[id]) {
          totalStatsList[id] = {}
        }
        for (var mapname in stats) {
          totalStatsList[id][mapname] = stats[mapname]
        }
        const tstats = []
        $scope.ztCameras.map(function (cam) {
          if (totalStatsList[cam.accessKey] != null) {
            return tstats.push(totalStatsList[cam.accessKey])
          }
        })

        const gstats = mng.calcTotalStats(stats, tstats)
        heatmapCommon.setGlobalStats(gstats)

        return gstats
      }

      $scope.$on("heatmap_stats", (event, stats) =>
        $scope.$broadcast("heatmap_stats_global", calcTotalStats(stats))
      )

      return ($scope.isUseDwellmap = (camera) =>
        __guard__(
          __guard__(camera != null ? camera.functions : undefined, (x1) => x1.use),
          (x) => x.dwellmap
        ))
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
