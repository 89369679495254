/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory("SamplingSrv", function ($rootScope, Locale) {
    const SamplingSrv = {}

    class Sampling {
      constructor(requestOption, format, compareUnit, pageRowType, barWidth) {
        this.requestOption = requestOption
        this.format = format
        this.compareUnit = compareUnit
        this.pageRowType = pageRowType
        this.barWidth = barWidth
        this.isoFormat = "YYYY-MM-DD"
        this.isoDayFormat = "YYYY-MM-DD(ddd)"
        this.tickSize = 1
        this.minTickSize = 1
        this.tickFormat = this.format
      }

      getRequestOption() {
        return this.requestOption
      }
      getFormat() {
        return this.format
      }
      getIsoFormat() {
        return this.isoFormat
      }
      getIsoDayFormat() {
        return this.isoDayFormat
      }
      getCompareUnit() {
        return this.compareUnit
      }
      getPageRowType() {
        return this.pageRowType
      }
      getTickSize() {
        return this.tickSize
      }
      getMinTickSize() {
        return this.minTickSize
      }
      getBarWidth() {
        return this.barWidth
      }
      getTickFormat() {
        return this.tickFormat
      }
      getFormatDate(date) {
        return Locale.dateTime(date, this.format)
      }
      getIsoDate(date) {
        return Locale.dateTime(date, this.isoFormat)
      }
      getIsoDayDate(date) {
        return Locale.dateTime(date, this.isoDayFormat)
      }
    }

    class Hour extends Sampling {
      constructor() {
        super()
        this.requestOption = "1h"
        this.format = "fullDateTime"
        this.compareUnit = "day"
        this.pageRowType = [12, 24, 100]
        this.barWidth = 60 * 60 * 200
        this.tickSize = 12
        this.minTickSize = 12
        this.isoFormat = "YYYY-MM-DD HH:mm"
        this.isoDayFormat = "YYYY-MM-DD(ddd) HH:mm"
        this.tickFormat = "hour"
        this.seconds = 3600
      }

      getAbbriation() {
        return "h"
      }

      getTickFormat(date) {
        date = moment.utc(date)

        if (date.format("HHmm") === "0000") {
          this.tickFormat = "simpleDay"
        } else {
          this.tickFormat = "hour"
        }

        return this.tickFormat
      }

      getLimitDate() {
        return {
          key: "month",
          value: 2,
        }
      }
    }

    // 10 minutes
    class M10 extends Hour {
      constructor() {
        super()
        this.requestOption = "10m"
        this.seconds = 600
        this.format = "fullDHM"
      }

      getAbbriation() {
        return "10m"
      }

      getLimitDate() {
        return {
          key: "month",
          value: 2,
        }
      }
    }

    class Day extends Sampling {
      constructor() {
        super()
        this.requestOption = "1d"
        this.format = "fullDate"
        this.compareUnit = "months"
        this.pageRowType = [7, 14, 50, 100]
        this.barWidth = 60 * 60 * 24 * 200
        this.tickFormat = "simpleDay"
        this.seconds = 86400
      }

      getAbbriation() {
        return "d"
      }

      getTickSize(startDate, endDate) {
        if (startDate && endDate) {
          this.tickSize = endDate.diff(startDate, "day") > 14 ? 3 : 1
        }

        return this.tickSize
      }

      getLimitDate() {
        return {
          key: "month",
          value: 24,
        }
      }
    }

    class Week extends Sampling {
      constructor() {
        super()
        this.requestOption = "1w"
        this.format = "fullDate"
        this.compareUnit = "months"
        this.pageRowType = [4, 12, 50, 100]
        this.barWidth = 60 * 60 * 24 * 7 * 200
        this.tickFormat = "simpleDay"
        this.seconds = 7 * 86400
      }

      getAbbriation() {
        return "w"
      }

      getLimitDate() {
        return {
          key: "year",
          value: 1,
        }
      }

      getTickFormat() {
        return this.tickFormat
      }
    }

    class Month extends Sampling {
      constructor() {
        super()
        this.requestOption = "1M"
        this.format = "month"
        this.compareUnit = "years"
        this.pageRowType = [12, 24, 36, 100]
        this.barWidth = 60 * 60 * 24 * 30 * 200
        this.seconds = 30 * 86400
        this.isoFormat = "YYYY-MM"
      }

      getAbbriation() {
        return "m"
      }

      getTickFormat(date, useUTC) {
        if (useUTC == null) {
          useUTC = true
        }
        if (useUTC) {
          date = moment.utc(date)
        } else {
          date = moment(date)
        }

        if (date.format("M") === "1") {
          this.tickFormat = "YYYY"
        } else {
          this.tickFormat = "MMM"
        }

        return this.tickFormat
      }

      getLimitDate() {
        return {
          key: "year",
          value: 3,
        }
      }
    }

    class Year extends Sampling {
      constructor() {
        super()
        this.requestOption = "1y"
        this.format = "year"
        this.compareUnit = "years"
        this.pageRowType = [5, 10, 15]
        this.barWidth = 60 * 60 * 24 * 30 * 12 * 200
        this.seconds = 365 * 86400
        this.isoFormat = "YYYY"
      }

      getAbbriation() {
        return "y"
      }

      getLimitDate() {
        return {
          key: "year",
          value: 10,
        }
      }
    }

    const getAbbriation = (sampling) => SamplingSrv[sampling].getAbbriation()

    const getSamplingKey = (unit) =>
      ({
        h: "hour",
        d: "day",
        w: "week",
        m: "month",
        y: "year",
      }[unit.toLowerCase() || "day"])

    const getViewModeFormat = (view) =>
      ({
        hour: "LT",
        date: "ll",
        month: "MMM YYYY",
        year: "YYYY",
      }[view.toLowerCase() || "date"])

    // return (a - b) / sampling
    const diffCount = function (a, b, sampling) {
      const nSeconds = SamplingSrv[sampling].seconds
      return nSeconds < 3600 ? a.diff(b, "seconds") / nSeconds : a.diff(b, sampling)
    }

    SamplingSrv["10m"] = new M10()
    SamplingSrv.hour = new Hour()
    SamplingSrv.day = new Day()
    SamplingSrv.week = new Week()
    SamplingSrv.month = new Month()
    SamplingSrv.year = new Year()
    SamplingSrv.getAbbriation = getAbbriation
    SamplingSrv.getSamplingKey = getSamplingKey
    SamplingSrv.getViewModeFormat = getViewModeFormat
    SamplingSrv.diffCount = diffCount

    return SamplingSrv
  })
