/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

import { TRANSLATIONS, TRANSLATION_NAMES } from "common-library/i18n"
import * as LocaleList from "common-library/locale-list"

function lang2TranslationName(language) {
  let [lang, region] = language.split("-")
  if (region) {
    region = region.toUpperCase()
  }
  lang = lang.toLowerCase()
  const locale = `${lang}-${region}`
  let langName = TRANSLATION_NAMES.find((name) => locale == name)
  if (!langName) {
    langName = TRANSLATION_NAMES.find((name) => lang.startsWith(name)) ?? "en-US"
  }

  return langName
}

angular
  .module("uCountitUiApp")

  .factory("Locale", function ($rootScope, $window, DateTimeFormat) {
    const config = {
      language: {
        code: "",
      },
      locale: undefined,
    }

    const languageCode = function () {
      if (!config.language.code) {
        // window.navigator.userLanguage is IE only, and window.navigator.language for the rest.
        config.language.code = $window.navigator.userLanguage || $window.navigator.language
      }

      return config.language.code
    }

    const _initLanguageResources = function () {
      const code = languageCode()
      config.language.dictionary = TRANSLATIONS[code]
      return $rootScope.$broadcast("localizeResourcesUpdated-language")
    }

    return {
      getDefaultLocale() {
        return { region: "en-GB", ...LocaleList.LOCALE["en-gb"] }
      },

      isSetup() {
        return config.locale || this.getDefaultLocale()
      },

      setup(valueObj) {
        let localeObj
        if (!valueObj || !LocaleList.LOCALE[valueObj.region.toLowerCase()]) {
          valueObj = this.getDefaultLocale()
        }

        if (valueObj.datetime) {
          localeObj = valueObj
        } else if (valueObj.region) {
          localeObj = LocaleList.LOCALE[valueObj.region.toLowerCase()]
        } else {
          throw new Error("Locale.setup: Invalid valueObj")
        }

        if (config.locale && config.locale.datetime !== localeObj.datetime) {
          moment.locale(localeObj.datetime)
        }

        config.locale = localeObj
        return $rootScope.$broadcast("localizeResourcesUpdated")
      },

      getDefaultLanguage() {
        return "en-US"
      },

      getLanguage() {
        return config.language.code || this.getDefaultLanguage()
      },

      setLanguage(value) {
        const langName = lang2TranslationName(value)

        if (TRANSLATIONS[langName] && langName !== config.language.code) {
          config.language.code = langName
          return _initLanguageResources()
        }
      },

      dateTime(time, format, localeID = null) {
        if (format == null) {
          format = "fullDate"
        }
        if (time == null) {
          return config.locale != null ? config.locale.datetime : undefined
        }

        const theTime = moment(time)
        if (!localeID) {
          localeID = config.locale.datetime
        }
        const token = DateTimeFormat[localeID][format] ? DateTimeFormat[localeID][format] : format
        return theTime.locale(localeID).format(token)
      },

      //TODO: implement duration locale function
      //      ex) 4분 30초, 4min 30sec...
      duration(seconds, format, localeID = null) {
        if (!localeID) {
          localeID = config.locale.datetime
        }
        let hUnit = "h"
        let mUnit = "m"
        let sUnit = "s"
        if (localeID == "ko-kr") {
          hUnit = "시"
          mUnit = "분"
          sUnit = "초"
        }
        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds % 3600) / 60)
        const sec = seconds % 60
        let hstr = ""
        let mstr = ""
        let sstr = ""
        if (hours > 0) hstr += `${hours}${hUnit}`
        if (hours > 0 || minutes > 0) mstr += `${minutes}${mUnit}`
        sstr += `${sec}${sUnit}`
        if (format == "full") {
          return `${hstr} ${mstr} ${sstr}` // full
        }
        // short
        return hstr.length > 0 ? `${hstr} ${mstr}` : mstr.length ? `${mstr} ${sstr}` : sstr
      },

      firstDayOfWeek() {
        return config.locale != null ? config.locale.firstDayOfWeek : undefined
      },

      fullName(name, order) {
        if (!config.locale) {
          return
        }
        if (name == null) {
          return config.locale.nameSequence
        }

        if (!order) {
          order = config.locale.nameSequence
        }
        const fullName =
          order === "eastern"
            ? `${name != null ? name.lastName : undefined} ${
                name != null ? name.firstName : undefined
              }`
            : `${name != null ? name.firstName : undefined} ${
                name != null ? name.lastName : undefined
              }`
        return fullName
      },

      number(number, format) {
        if (number == null) {
          return config.locale.number
        }

        if (!format) {
          format = config.locale.number
        }
        const option = LocaleList.NUMBER_OPTIONS[format]
        return Number(number).toLocaleString(option.localeID)
      },

      string(str) {
        let result = undefined

        if (config.language && config.language.dictionary && str) {
          const strLowerCase = str.toLowerCase()
          if (config.language.dictionary[strLowerCase]) {
            result = config.language.dictionary[strLowerCase]
          } else {
            result = str
          }
        } else {
          result = str
        }
        return result
      },

      getMultiLanguages(key) {
        key = key.toLowerCase()
        if (!config.language.dictionary[key]) {
          return
        }

        const result = []
        for (const lang in TRANSLATIONS) {
          result.push(TRANSLATIONS[lang][key])
        }

        return _.uniq(result)
      },

      getLangList: () => LocaleList.LANG,
      getLocaleList: () => LocaleList.LOCALE,
      getDateTimeOptions: () => LocaleList.DATE_TIME_OPTIONS,
      getNameOptions: () => LocaleList.NAME_OPTIONS,
      getNumberOptions: () => LocaleList.NUMBER_OPTIONS,
    }
  })
