/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS202: Simplify dynamic range loops
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")

  .controller(
    "AdReportFormCtrl",
    function (
      $scope,
      $uibModalInstance,
      $window,
      currentReport,
      reportList,
      Locale,
      ngDialog,
      storeList,
      uiGridConstants,
      companyEmailPremiumReportStatus
    ) {
      $scope.form = {}
      $scope.layoutList = [
        { name: Locale.string("portrait"), value: "portrait" },
        { name: Locale.string("landscape"), value: "landscape" },
      ]
      $scope.weekdayList = [
        { name: Locale.string("monday"), value: "mon" },
        { name: Locale.string("tuesday"), value: "tue" },
        { name: Locale.string("wednesday"), value: "wed" },
        { name: Locale.string("thursday"), value: "thu" },
        { name: Locale.string("friday"), value: "fri" },
        { name: Locale.string("saturday"), value: "sat" },
        { name: Locale.string("sunday"), value: "sun" },
      ]
      $scope.EmailsStr = Locale.string("Emails")
      $scope.invalidNameChars = "@"
      const REGEXP_INVALID_NAME_CHARS = /@/
      $scope.TYPE = {
        EXCEL: "excel",
        POWERBI: "powerbi",
      }
      const defaultReportOptions = {
        name: "주간 리포트",
        period: "weekly",
        day: "mon",
      }

      const MAX_ROWS = 6
      let original = {
        type: $scope.TYPE.EXCEL,
        name: defaultReportOptions.name,
        url: "",
        sheetcount: 1,
        layout: $scope.layoutList[0].value,
        period: defaultReportOptions.period,
        reportday: defaultReportOptions.day,
        emails: "",
        companyFlag: true,
        storeFlag: true,
        storeEmails: [],
        excelType: 1,
      }
      let orgStoreEmails = []
      const defaultGridOptions = {
        enableColumnMenus: false,
        columnDefs: [
          { name: "name", field: "name", width: "40%", displayName: Locale.string("store name") },
          {
            name: "emails",
            field: "emails",
            width: "60%",
            cellTemplate:
              '<div class="ui-grid-cell-contents"><input type="text" name="storeEmails" class="form-control custom-input" data-ng-model="row.entity.emails" maxlength="1024" placeholder=" e.g. first@example.com;second@example.com" data-ng-disabled="row.entity.disableEmails" /></div>',
            displayName: Locale.string("emails"),
          },
        ],
        rowHeight: 40,
        minRowsToShow: MAX_ROWS,
        enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
        enableVerticalScrollbar: uiGridConstants.scrollbars.WHEN_NEEDED,
      }

      $scope.gridOptions = null
      $scope.companyEmailPremiumReportStatus = companyEmailPremiumReportStatus

      const convertStoreEmailsTable2Form = function (tableData) {
        const formData = []
        tableData.forEach(function (row) {
          if (!_.isEmpty(row.emails)) {
            return formData.push(row)
          }
        })

        return formData
      }

      const convertStoreEmailsForm2Table = function (formData) {
        const tableData = storeList.map(function (store) {
          const info = _.find(formData, { storeId: store._id })
          return {
            storeId: store._id,
            name: store.name,
            emails:
              (info != null ? info.emails : undefined) != null
                ? info != null
                  ? info.emails
                  : undefined
                : "",
            disableEmails: !companyEmailPremiumReportStatus,
          }
        })

        return tableData
      }

      const getIndexOfReportName = function () {
        const regexp = new RegExp(`^${defaultReportOptions.name}(\\s*|-\\d+)$`)
        let index = -1

        reportList
          .map((r) => r.name)
          .forEach(function (name) {
            const result = name.match(regexp)
            if (!result) {
              return
            }

            const number = result[1] === "" ? 0 : Math.abs(result[1])
            if (number > index) {
              return (index = number)
            }
          })

        return index
      }

      const init = function () {
        const gridOptions = _.cloneDeep(defaultGridOptions)
        if (storeList.length < MAX_ROWS) {
          gridOptions.minRowsToShow = storeList.length
          gridOptions.enableVerticalScrollbar = uiGridConstants.scrollbars.NEVER
        }

        if (currentReport) {
          $scope.currentReport = currentReport
          $scope.form.type = $scope.currentReport.type
          $scope.form.id = $scope.currentReport._id
          $scope.form.name = $scope.currentReport.name
          $scope.form.url = $scope.currentReport.url
          $scope.form.sheetcount = $scope.currentReport.sheetcount
          $scope.form.layout = $scope.currentReport.layout
          $scope.form.period = $scope.currentReport.period
          $scope.form.reportday = $scope.currentReport.reportday
          $scope.form.emails = $scope.currentReport.emails

          $scope.form.excelName = $scope.currentReport.excelName
          $scope.form.excelContent = ""

          $scope.form.companyFlag =
            ($scope.currentReport != null ? $scope.currentReport.companyFlag : undefined) != null
              ? $scope.currentReport != null
                ? $scope.currentReport.companyFlag
                : undefined
              : true
          $scope.form.storeFlag =
            ($scope.currentReport != null ? $scope.currentReport.storeFlag : undefined) != null
              ? $scope.currentReport != null
                ? $scope.currentReport.storeFlag
                : undefined
              : true
          $scope.form.storeEmails =
            ($scope.currentReport != null ? $scope.currentReport.storeEmails : undefined) != null
              ? $scope.currentReport != null
                ? $scope.currentReport.storeEmails
                : undefined
              : []
        } else {
          const index = getIndexOfReportName()

          $scope.form.type = $scope.TYPE.EXCEL
          $scope.form.name =
            index >= 0 ? `${defaultReportOptions.name}-${index + 1}` : defaultReportOptions.name
          $scope.form.id = ""
          $scope.form.url = ""
          $scope.form.sheetcount = 1
          $scope.form.layout = $scope.layoutList[0].value
          $scope.form.period = defaultReportOptions.period
          $scope.form.reportday = defaultReportOptions.day
          $scope.form.emails = ""

          $scope.form.excelName = ""
          $scope.form.excelContent = ""

          $scope.form.companyFlag = true
          $scope.form.storeFlag = true
          $scope.form.storeEmails = []
          $scope.form.excelType = 1
        }

        gridOptions.data = convertStoreEmailsForm2Table($scope.form.storeEmails)
        $scope.gridOptions = gridOptions
        original = angular.copy($scope.form)
        return (orgStoreEmails = angular.copy($scope.gridOptions.data))
      }

      init()

      const _isDuplicate = function (name) {
        const hasReportName = _.find(reportList, (i) => i.name === name)

        if (hasReportName) {
          const isOrginalName =
            name === original.name && $scope.currentReport != null ? true : false
          return !isOrginalName
        }
        return false
      }

      $scope.checkValidName = function () {
        //$scope.$apply()
        //duplicate name
        if (_isDuplicate($scope.form.name)) {
          $scope.adReportForm.reportName.$setValidity("duplicate", false)
        } else {
          $scope.adReportForm.reportName.$setValidity("duplicate", true)
        }

        //invalid name character('@')
        if ($scope.form.name && $scope.form.name.match(REGEXP_INVALID_NAME_CHARS)) {
          return $scope.adReportForm.reportName.$setValidity("invalid", false)
        } else {
          return $scope.adReportForm.reportName.$setValidity("invalid", true)
        }
      }

      $scope.cancel = () => $uibModalInstance.dismiss("cancel")

      $scope.add = function () {
        if ($scope.form.type === $scope.TYPE.POWERBI) {
          $scope.form.companyFlag = true
        }
        $scope.form.storeEmails = convertStoreEmailsTable2Form($scope.gridOptions.data)
        return $uibModalInstance.close({
          action: "add",
          data: $scope.form,
        })
      }

      $scope.modify = function () {
        if ($scope.form.type === $scope.TYPE.POWERBI) {
          $scope.form.companyFlag = true
        }
        $scope.form.storeEmails = convertStoreEmailsTable2Form($scope.gridOptions.data)
        return $uibModalInstance.close({
          action: "modify",
          data: $scope.form,
          original,
        })
      }

      const _isEqualFormData = () =>
        angular.equals($scope.form, original) &&
        angular.equals($scope.gridOptions.data, orgStoreEmails)

      $scope.canSubmit = function () {
        if ($scope.form.type === $scope.TYPE.POWERBI) {
          return $scope.adReportForm.$valid && !_isEqualFormData()
        } else {
          return (
            $scope.form.name &&
            $scope.form.excelName &&
            !_isEqualFormData() &&
            $scope.adReportForm.reportName.$valid
          )
        }
      }

      $scope.ab2base64 = function (buf) {
        let binary = ""
        const bytes = new Uint8Array(buf)
        const len = bytes.byteLength
        for (let i = 0, end = len, asc = 0 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
          binary += String.fromCharCode(bytes[i])
        }

        return window.btoa(binary)
      }

      return ($scope.excelSeletFile = function (file) {
        if (!file) {
          return
        }
        if (file != null ? file.$error : undefined) {
          ngDialog.open({
            template: "components/popup/popup-alert.html",
            data: {
              title: Locale.string("Select File"),
              message: Locale.string("The file type is not supported") + `\n(${file.name})`,
            },
            closeByEscape: true,
            closeByDocument: true,
            showClose: false,
            className: "ngdialog-theme-default popup-alert",
            controller: "ucPopupAlertCtrl",
          })
        }

        const reader = new FileReader()
        reader.onload = (
          e // e -> target, loaded, total,
        ) =>
          //   target -> FileReader(result(content),error)
          $scope.$apply(function () {
            $scope.form.excelName = file.name
            const ss = $scope.ab2base64(reader.result)
            return ($scope.form.excelContent = ss)
          })
        //reader.onerror = ->
        //  deffered.reject reader.error

        return reader.readAsArrayBuffer(file)
      })
    }
  )
