/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular.module("uCountitUiApp").config(($stateProvider) =>
  $stateProvider.state("app.datadownload", {
    url: "/datadownload/company/:id",
    templateUrl: "app/datadownload/datadownload.html",
    controller: "DataDownloadCtrl",
  })
)
