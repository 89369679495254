/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("noDataCover", ($compile) => ({
    restrict: "E",
    replace: true,

    template: [
      '<div class="no-data-box">',
      '<div class="no-data-container">',
      '<div class="align-wrapper">',
      '<div class="center-wrapper">',
      '<span data-i18n="No Data"></span>',
      "</div>",
      "</div>",
      "</div>",
      "</div>",
    ].join(""),
  }))
