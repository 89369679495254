"use static"

import Promise from "bluebird"
import angular from "angular"
import _ from "lodash"
import moment from "moment"

const S3FileApiListCtrl = ($scope, $http, ApiSrv, RestApi, NgTableParams) => {
  const fetchS3FileApiKeys = () => {
    return Promise.all([
      ApiSrv.getAllAgency(),
      RestApi.Company.index(),
      $http.get(`/api/1.0/s3fileapi`).then((res) => res.data),
    ])
      .spread((agencies, companies, data) => {
        return data
          .map((d) => {
            const iatHumanize = moment(d.createdAt).from(moment.now())

            let organization
            if (d.orgType == "company") {
              organization = companies.find((c) => c._id == d.organization)
              if (!organization) return null
              organization.agency = agencies.find((a) => a._id == organization._agencyId)
            } else return Promise.reject(new Error("Invalid restfulapi key"))

            return {
              ...d,
              iatHumanize,
              organization,
            }
          })
          .filter((d) => d)
      })
      .catch((err) => {
        console.error(err)
        return Promise.reject(err)
      })
  }

  const getData = (params) => {
    return fetchS3FileApiKeys().then((data) => {
      const sorting = params.sorting()
      const keys = Object.keys(sorting)

      let sortBy, orderBy
      if (keys && keys.length > 0) {
        sortBy = keys[0]
        orderBy = sorting[sortBy]
      }
      if (sortBy && orderBy) data = _.orderBy(data, sortBy, orderBy)

      return data
    })
  }
  $scope.tableParam = new NgTableParams({ count: 25 }, { counts: [10, 25, 50, 100], getData })
  $scope.reload = (cache = true) => {
    $scope.tableParam.reload()
  }
  $scope.delete = (row) => {
    const answer = confirm(
      `Delete key for ${row.organization.agency.name} > ${row.organization.name}.\n Are you sure?`
    )
    if (answer)
      return $http.delete(`/api/1.0/s3fileapi/${row._id}`).then(() => $scope.reload(false))
    else return Promise.resolve()
  }
  $scope.alertMessage = (msg) => {
    $scope.alerts.push({
      type: "error",
      msg: msg,
    })
    const length = $scope.alerts.length - 1
    setTimeout(() => $scope.closeAlert(length), 3000)
  }

  $scope.alerts = []
  $scope.closeAlert = (index) => {
    if ($scope.alerts.length > index) $scope.alerts.splice(index, 1)
  }
}

angular.module("uCountitUiApp").controller("S3FileApiListCtrl", S3FileApiListCtrl)
