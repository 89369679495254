import React from "react"
import { Text } from "informed"
import propTypes from "prop-types"

const HeartbeatAtTriggerParameter = ({ error }) => {
  return (
    <>
      <div className="form-group">
        <label className="control-label col-sm-3">Live Limit</label>
        <div className="col-sm-9">
          <Text
            field="trigger.parameter.liveLimit"
            className="form-control"
            required
            type="number"
            initialValue={3600}
            min="0"
          />
        </div>
      </div>
      <div className="form-group">
        <label className="control-label col-sm-3">Retention Time</label>
        <div className="col-sm-9">
          <Text
            className="form-control"
            field="trigger.parameter.retentionTime"
            required
            type="number"
            initialValue={3600}
            min="0"
          />
        </div>
      </div>
    </>
  )
}

HeartbeatAtTriggerParameter.propTypes = {
  error: propTypes.object,
}

export default HeartbeatAtTriggerParameter
