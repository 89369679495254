"use strict"

import angular from "angular"
import _ from "lodash"

import { fabric } from "fabric"
import { getFontSize, setFontCenterPosition, getBlankZone } from "../../components/util/fontutils"
import PassingLine from "./PassingLine"

angular
  .module("uCountitUiApp")
  .directive("countingareaEditor", function () {
    return {
      restrict: "E",
      template: [
        '<div class="tag-container" style="position:relative;">',
        '<canvas id="countingareaCanvas"></canvas>',
        "</div>",
      ].join(""),
      controller: "countingareaEditorCtrl",
      controllerAs: "vm",
      scope: {
        // area list
        areaList: "=",
        // area command : "add","edit", "editline","clear","save","cancel"
        areaCmd: "=",
        width: "=",
        height: "=",
        // background image
        snapshotUrl: "=",
      },
    }
  })
  .controller("countingareaEditorCtrl", function ($scope, $element, $compile, Locale, hotkeys) {
    var kCanvasName = "#countingareaCanvas"
    $scope.canvas = new fabric.Canvas($element[0].querySelector(kCanvasName), {
      selection: false,
      preserveObjectStacking: true,
    })
    $scope.coCircles = []
    $scope.coPolygon = null
    $scope.coLineTags = []
    $scope.coPassingLine = null

    $scope.editArea = null

    $scope.skipReload = false

    const kPointActiveColor = "#5c5b5b"
    const kPointNormalColor = "#ffffff"
    const kLineTagActiveColor = "#5c0000"
    const kLineTagNormalColor = "#ff0000"

    const removeEditPoints = function () {
      _.forEach($scope.coCircles, (o) => $scope.canvas.remove(o))
      $scope.coCircles = []
    }

    const removeEditComponents = () => {
      removeEditPoints()
      $scope.canvas.remove($scope.coPolygon)
      $scope.coPolygon = null

      _.forEach($scope.coLineTags, (o) => $scope.canvas.remove(o))
      $scope.coLineTags = []
      $scope.canvas.remove($scope.coPassingLine)
      $scope.coPassingLine = null
    }

    // if you are editing any area, restore it
    const resetEditMode = () => {
      if ($scope.editArea) {
        removeEditComponents()
        drawStaticArea(_.find($scope.areaList, ["zone.id", $scope.editArea.zone.id]))
        $scope.editArea = null
      }
      //setModificationFlag(false)
    }

    const createEditPoints = (zone) => {
      const _getMidPoint = (p1, p2) => {
        return { x: (p1.x + p2.x) / 2, y: (p1.y + p2.y) / 2 }
      }
      var points = []
      zone.points.forEach((point, index) => {
        point.state = "real"
        point.zonePointIndex = index
        points.push(point)

        var next = (index + 1) % zone.points.length
        const midPoint = _getMidPoint(point, zone.points[next])
        midPoint.state = "virtual"
        points.push(midPoint)
      })
      return points
    }

    const createLineTagPoints = (zone) => {
      const _getMidPoint = (p1, p2) => {
        return { x: (p1.x + p2.x) / 2, y: (p1.y + p2.y) / 2 }
      }
      var points = []
      zone.points.forEach((point, index) => {
        var next = (index + 1) % zone.points.length
        const midPoint = _getMidPoint(point, zone.points[next])
        points.push(midPoint)
      })
      return points
    }

    const getPassingLinePoints = (zone, line) => {
      const points = zone.points.map((p) => {
        return new fabric.Point(p.x + zone.left, p.y + zone.top)
      })
      var pts = []
      line.forEach((v, i) => {
        if (i === 0) {
          pts.push(points[v])
        }
        var next = (v + 1) % points.length
        pts.push(points[next])
      })
      return pts
    }

    const drawStaticArea = ({ zone, line }) => {
      if (_.isEmpty(zone.points)) {
        return
      }
      const polygon = new fabric.Polygon(zone.points, {
        left: zone.left,
        top: zone.top,
        fill: "transparent",
        stroke: zone.rgbcolor,
        strokeWidth: 2,
        strokeDashArray: !zone.active ? [5, 5] : [],
      })
      const text = new fabric.Text(zone.name ? zone.name : "", {
        fontSize: getFontSize($scope.width) + 2,
        fontFamily: "monospace",
        fontWeight: 600,
        stroke: zone.rgbcolor,
        strokeWidth: 1,
        fill: "white",
      })
      setFontCenterPosition(polygon, text)

      const linePoints = getPassingLinePoints(zone, line)
      const passingLine = new fabric.Polyline(linePoints, {
        strokeWidth: 8,
        fill: "transparent",
        stroke: zone.rgbcolor,
        strokeDashArray: !zone.active ? [5, 5] : [4, 2],
      })

      const group = new fabric.Group([passingLine, polygon, text], {
        hasControls: false,
        selectable: false,
        opacity: !zone.active ? 0.3 : 1,
        id: zone.id,
      })
      $scope.canvas.add(group)
    }

    const reloadList = (areas = []) => {
      // skip this routine on changing areaList by saving current tag
      if ($scope.skipReload) {
        $scope.skipReload = false
        return
      }

      $scope.canvas.clear()
      $scope.editArea = null
      _.forEach(areas, (tag) => {
        drawStaticArea(tag)
      })
    }

    const setModificationFlag = function (flag) {
      $scope.canvas.discardActiveObject().renderAll()
      $scope.canvas.forEachObject(function (obj) {
        obj.selectable = flag
        obj.evented = flag
      })
    }

    const findCavaObjectById = (id) => {
      return _.find($scope.canvas.getObjects(), ["id", id])
    }

    const removeCavaObjectById = (id) => {
      $scope.canvas.remove(findCavaObjectById(id))
    }

    // const createCircles = zone => {
    //   let coCircles = []
    //   _.forEach(createEditPoints(zone), (point, index) => {
    //     const circle = new fabric.Circle({
    //       left: zone.left + point.x,
    //       top: zone.top + point.y,
    //       radius: 5,
    //       fill: kPointNormalColor,
    //       stroke: "#333333",
    //       originX: "center",
    //       originY: "center",
    //       hasBorders: false,
    //       hasControls: false,
    //       hoverCursor: "pointer",
    //       state: point.state,
    //       opacity: point.state === "real" ? 1 : 0.3,
    //       zonePointIndex: point.zonePointIndex,
    //       circleIndex: index
    //     })
    //     coCircles.push(circle)
    //     $scope.canvas.add(circle)
    //   })
    //   return coCircles
    // }

    const drawEditArea = function ({ zone, line }) {
      $scope.coPolygon = new fabric.Polygon(zone.points, {
        left: zone.left,
        top: zone.top,
        fill: "transparent",
        stroke: zone.rgbcolor,
        strokeWidth: 2,
        objectCaching: false,
        hasControls: false,
        id: zone.id,
      })
      $scope.canvas.add($scope.coPolygon)

      let coCircles = []
      _.forEach(createEditPoints(zone), (point, index) => {
        const circle = new fabric.Circle({
          left: zone.left + point.x,
          top: zone.top + point.y,
          radius: 5,
          fill: kPointNormalColor,
          stroke: "#333333",
          originX: "center",
          originY: "center",
          hasBorders: false,
          hasControls: false,
          hoverCursor: "pointer",
          state: point.state,
          opacity: point.state === "real" ? 1 : 0.3,
          zonePointIndex: point.zonePointIndex,
          circleIndex: index,
        })
        coCircles.push(circle)
        $scope.canvas.add(circle)
      })
      $scope.coCircles = coCircles
    }

    const drawEditLineTag = function ({ zone }) {
      let coLineTags = []
      _.forEach(createLineTagPoints(zone), (point, index) => {
        const circle = new fabric.Circle({
          left: zone.left + point.x,
          top: zone.top + point.y,
          radius: 8,
          fill: kLineTagNormalColor,
          stroke: "#333333",
          originX: "center",
          originY: "center",
          hasBorders: false,
          hasControls: false,
          selectable: false,
          hoverCursor: "pointer",
          opacity: 1,
          lineIndex: index,
        })
        coLineTags.push(circle)
        $scope.canvas.add(circle)
      })
      $scope.coLineTags = coLineTags
    }

    const removeDeleteBtn = function () {
      if ($element.find(".deleteBtn").length) {
        $element.find(".deleteBtn").remove()
      }
    }

    const makeDeleteBtn = function (x, y, w) {
      var btnLeft, btnTop, widthadjust
      btnLeft = x
      btnTop = y - 13
      widthadjust = w / 2
      btnLeft = widthadjust + btnLeft
      return $element
        .find(".tag-container")
        .append(
          $compile(
            [
              '<p class="deleteBtn" title="' +
                Locale.string("delete") +
                '" style="color:black;position:absolute;top:' +
                btnTop +
                "px;left:" +
                btnLeft +
                'px;cursor:pointer;font-size:16px;" data-ng-click="deleteSelectedPoint()">❎</p>',
            ].join("")
          )($scope)
        )
    }

    $scope.deleteSelectedPoint = () => {
      const selected = $scope.canvas.getActiveObject()
      if (!selected || $scope.editArea.zone.points.length <= 3) {
        return
      }
      $scope.editArea.line = removeLineTag(selected.zonePointIndex)
      $scope.editArea.zone.points.splice(selected.zonePointIndex, 1)
      $scope.editArea.zone = resyncZonePosition($scope.editArea.zone)
      removeEditComponents()
      drawEditArea($scope.editArea)
    }

    const isEditZoneMode = function () {
      return $scope.areaCmd?.mode === "edit" || $scope.areaCmd?.mode === "add"
    }

    const isEditLineMode = function () {
      return $scope.areaCmd?.mode === "editline"
    }

    const resyncZonePosition = (orgZone) => {
      let zone = _.cloneDeep(orgZone)

      // find left & top
      const cxy = zone.points.reduce(
        (p, c) => {
          if (c.x < p.x) p.x = c.x
          if (c.y < p.y) p.y = c.y
          return p
        },
        { x: 9999, y: 9999 }
      )

      // repositioning
      zone.left += cxy.x
      zone.top += cxy.y
      zone.points = zone.points.map((pt) => ({ x: pt.x - cxy.x, y: pt.y - cxy.y }))
      return zone
    }

    const bringToFrontCanvas = (obj) => {
      $scope.canvas.bringToFront(obj)
      if (isEditZoneMode() && obj.type === "polygon") {
        $scope.coCircles.forEach(function (point) {
          $scope.canvas.bringToFront(point)
        })
      }
      $scope.canvas.renderAll()
    }

    const togglePassingLine = (idx) => {
      let newline = $scope.editArea.line.slice()
      if (newline.includes(idx)) {
        newline = _.pull(newline, idx)
      } else {
        newline.push(idx)
      }

      const pl = new PassingLine(newline, $scope.editArea.zone.points.length)
      if (!pl.isArranged()) {
        return [false, $scope.editArea.line]
      }
      return [true, pl.getValues()]
    }

    const removeLineTag = (zonePointIndex) => {
      const pl = new PassingLine($scope.editArea.line, $scope.editArea.zone.points.length)
      return pl.removePoint(zonePointIndex).getValues()
    }

    const addLineTag = (zonePointIndex) => {
      const pl = new PassingLine($scope.editArea.line, $scope.editArea.zone.points.length)
      return pl.addPoint(zonePointIndex).getValues()
    }

    //###############################
    // on changing external variables
    $scope.$watch("areaList", (list) => reloadList(list), true)

    $scope.$watchGroup(["width", "height", "snapshotUrl"], function () {
      $scope.canvas.setWidth($scope.width)
      $scope.canvas.setHeight($scope.height)
      if ($scope.snapshotUrl) {
        let canvas = $element.find(kCanvasName)
        canvas.css({
          width: $scope.width,
          height: $scope.height,
          background: "url(" + $scope.snapshotUrl + ")",
          "background-size": $scope.width + "px " + $scope.height + "px",
        })
      }
    })

    $scope.execAreaCmd = (opt) => {
      if (!opt) {
        return
      }
      let zoneInfo, index, blankZone
      switch (opt?.mode) {
        case "add":
          resetEditMode()
          // new area from $scope.areaList[opt.index]
          $scope.editArea = _.cloneDeep($scope.areaList[opt.index])

          blankZone = getBlankZone(
            opt.index,
            $scope.width,
            $scope.height,
            $scope.editArea.zone?.name
          )
          zoneInfo = _.merge($scope.editArea.zone, _.pick(blankZone, ["left", "top", "points"]))
          $scope.editArea = _.assign({}, $scope.areaList[opt.index], {
            zone: zoneInfo,
            line: [],
          })
          drawEditArea($scope.editArea)
          break
        case "edit":
          resetEditMode()
          $scope.editArea = _.cloneDeep($scope.areaList[opt.index])
          removeCavaObjectById($scope.editArea.zone.id)
          drawEditArea($scope.editArea)
          break
        case "editline":
          resetEditMode()
          $scope.editArea = _.cloneDeep($scope.areaList[opt.index])
          $scope.canvas.remove(findCavaObjectById($scope.editArea.zone.id))
          drawStaticArea($scope.editArea)
          drawEditLineTag($scope.editArea)
          break
        case "save":
          index = $scope.areaCmd.index
          if (!_.isEqual($scope.editArea, $scope.areaList[index])) {
            //$scope.skipReload = true
            $scope.areaList[index] = $scope.editArea
          }
          resetEditMode()
          $scope.editArea = null
          $scope.areaCmd = null
          setModificationFlag(false)
          break
        case "cancel":
          removeEditComponents()
          drawStaticArea($scope.areaList[$scope.areaCmd.index])
          $scope.editArea = null
          $scope.areaCmd = null
          setModificationFlag(false)
          break
        // case "clear":
        //   _.assign($scope.areaList[opt.index], getDefaultArea(zoneSrv, opt.index))
        //   $scope.areaCmd = null
      }
    }

    $scope.$watch("areaCmd", $scope.execAreaCmd, true)

    //#################
    // on object events
    $scope.canvas.on("mouse:down", ({ target }) => {
      if (!target) {
        return
      }
      if (isEditZoneMode() && target.type === "circle") {
        bringToFrontCanvas(target)
      } else if (isEditLineMode() && target.type === "circle") {
        let ok, newLine
        ;[ok, newLine] = togglePassingLine(target.lineIndex)
        if (ok) {
          $scope.editArea.line = newLine
          removeCavaObjectById($scope.editArea.zone.id)
          drawStaticArea($scope.editArea)
          $scope.coLineTags.forEach((point) => {
            $scope.canvas.bringToFront(point)
          })
        }
      }
    })

    $scope.canvas.on("mouse:up", function ({ target }) {})

    $scope.canvas.on("mouse:over", ({ target }) => {
      if (target?.type === "circle") {
        let color = null
        if (isEditZoneMode()) {
          color = kPointActiveColor
        } else if (isEditLineMode()) {
          color = kLineTagActiveColor
        }
        if (color != null) {
          target.set("fill", color)
          $scope.canvas.renderAll()
        }
      }
    })

    $scope.canvas.on("mouse:out", ({ target }) => {
      if (target?.type === "circle") {
        let color = null
        if (isEditZoneMode()) {
          color = kPointNormalColor
        } else if (isEditLineMode()) {
          color = kLineTagNormalColor
        }
        if (color != null) {
          target.set("fill", color)
          $scope.canvas.renderAll()
        }
      }
    })

    $scope.canvas.on("object:modified", function ({ target }) {
      if (!isEditZoneMode()) {
        // if not edit mode, move the whole area
        // let index = _.findIndex($scope.areaList, (n) => n.id === target.id)
        // if (index < 0) {
        //   return
        // }
        // let area = _.cloneDeep($scope.areaList[index])
        // area.zone.left = target.left
        // area.zone.top = target.top
        // $scope.areaList[index] = area
        return
      }

      if (target.type === "circle") {
        $scope.editArea.zone = resyncZonePosition($scope.editArea.zone)
        removeEditComponents()
        drawEditArea($scope.editArea)
      } else if (target.type === "polygon") {
        $scope.editArea.zone.left = target.left
        $scope.editArea.zone.top = target.top
        removeEditComponents()
        drawEditArea($scope.editArea)
        // $scope.canvas.setActiveObject($scope.coPolygon)
      }
    })

    $scope.canvas.on("object:moving", function ({ target }) {
      if (!isEditZoneMode() || !target.canvas) {
        return
      }
      const CW = target.canvas.width
      const CH = target.canvas.height
      target.setCoords()

      if (target.type === "circle") {
        removeDeleteBtn()
        const curCenter = target.getCenterPoint()
        const x = Math.min(Math.max(0, curCenter.x), CW - 2)
        const y = Math.min(Math.max(0, curCenter.y), CH - 2)
        // move circle position
        target.setPositionByOrigin(new fabric.Point(x, y), "center", "center")
        const newPoint = {
          x: x - $scope.coPolygon.left,
          y: y - $scope.coPolygon.top,
        }
        if (target.state === "real") {
          $scope.canvas.remove(_.nth($scope.coCircles, target.circleIndex - 1))
          $scope.canvas.remove(_.nth($scope.coCircles, target.circleIndex + 1))
          // change the position of the coPolygon point
          $scope.coPolygon.points[target.zonePointIndex] = newPoint
        } else if (target.state === "virtual") {
          $scope.coCircles[target.circleIndex].state = "real"
          // insert new point in zone
          // TODO: update $scope.editArea.line with target.circleIndex
          const zIndex = Math.floor(target.circleIndex / 2) + 1
          $scope.editArea.line = addLineTag(zIndex)
          $scope.editArea.zone.points.splice(zIndex, 0, newPoint)

          // add new real point in polygon
          // INFO: $scope.editArea.zone.points shares $scope.coPolygon.points
          // $scope.coPolygon.points.splice(zIndex, 0, newPoint)

          //recalculate coCircles Index
          let rCount = 0
          for (const i in $scope.coCircles) {
            $scope.coCircles[i].circleIndex = i
            $scope.coCircles[i].zonePointIndex =
              $scope.coCircles[i].state == "real" ? rCount++ : null
          }
        }
      } else {
        // polygon
        const bound = target.getBoundingRect(true)
        target.left = Math.min(Math.max(0, bound.left), CW - bound.width)
        target.top = Math.min(Math.max(0, bound.top), CH - bound.height)

        removeEditPoints()
      }
    })

    const seletctObject = function (target) {
      if (!isEditZoneMode()) {
        return
      }
      removeDeleteBtn()
      if (target?.type === "circle" && target?.state === "real") {
        return makeDeleteBtn(target.oCoords.mt.x, target.oCoords.mt.y, target.width)
      }
    }

    $scope.canvas.on("selection:created", function (e) {
      return seletctObject(e.target)
    })

    $scope.canvas.on("selection:updated", function (e) {
      return seletctObject(e.target)
    })

    $scope.canvas.on("selection:cleared", function (e) {
      return seletctObject(e.target)
    })

    $scope.shortcuts = [
      {
        combo: "s",
        description: "Save the editing zone",
        callback: () => {
          if ($scope.editArea) {
            removeDeleteBtn()
            $scope.execAreaCmd({ mode: "save", index: $scope.editArea.index })
          }
        },
      },
      {
        combo: ["c", "escape"],
        description: "Canel the editing zone",
        callback: () => {
          if ($scope.editArea) {
            removeDeleteBtn()
            $scope.execAreaCmd({ mode: "cancel", index: $scope.editArea.index })
          }
        },
      },
    ]
    $scope.shortcuts.forEach(hotkeys.bindTo($scope).add)
  })
