/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"
import { namePattern, groupPattern } from "common-library/validation"

angular
  .module("uCountitUiApp")

  // name filter of the account group and store group
  .directive("nameFilter", () => ({
    restrict: "A",
    require: "ngModel",

    scope: { nameFilter: "@" },

    link(scope, _element, _attrs, ctrl) {
      const regExp = {
        groupName: groupPattern,
        filename: namePattern,
      }
      // These are the characters that should not be included in the filename.
      // Refer to https://en.wikipedia.org/wiki/Filename#Reserved_characters_and_words
      function test(value) {
        let valid = regExp[scope.nameFilter].test(value)
        if (!value) {
          valid = true
        }
        ctrl.$setValidity("nameFilter", valid)
        return value
      }

      ctrl.$parsers.unshift(test)
      ctrl.$formatters.unshift(test)
    },
  }))
