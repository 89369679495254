import React from "react"
import propTypes from "prop-types"
import { SelectField } from "../../../../components/react/SelectField"

const SensorContentWebhookParameter = ({ error }) => {
  return (
    <div className={`form-group ${error ? "has-error" : null}`}>
      <label className="control-label col-sm-3">Content</label>
      <div className="col-sm-9">
        <SelectField
          field="source.parameter.content"
          options={[
            { label: "Occupancy", value: "occupancy" },
            { label: "Face", value: "face" },
          ]}
        />
      </div>
    </div>
  )
}

SensorContentWebhookParameter.propTypes = {
  error: propTypes.object,
}

export default SensorContentWebhookParameter
