import React from "react"
import propTypes from "prop-types"
import MultipleEmailField from "../../../../components/react/MultipleEmailField"

const EmailActionParameter = (props) => {
  const { error } = props

  return (
    <div className={`form-group ${error && error.receivers ? "has-error" : null}`}>
      <label className="control-label col-sm-3">Receivers</label>
      <div className="col-sm-9">
        <MultipleEmailField field="action.parameter.receivers" />
        {error && error.receivers ? <span className="help-block">{error.receivers}</span> : null}
      </div>
    </div>
  )
}

EmailActionParameter.propTypes = {
  error: propTypes.object,
}

export default EmailActionParameter
