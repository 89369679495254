/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

angular
  .module("uCountitUiApp")

  .factory("SelectorSrv", function ($q, Auth, ApiSrv, DateTimeSrv, StoreGroupSrv, HeaderSrv) {
    const getUseFaceCountOnly = function (features, stores) {
      if (!features.face) {
        return false
      }
      if (!features.footfall) {
        return true
      }

      let checkNoneSeedCounter = false
      _.forEach(stores, function (store) {
        if ((store.seedCounterOfGA ?? "None") === "None") {
          checkNoneSeedCounter = true
          return false
        }
      })

      return checkNoneSeedCounter
    }

    const getFeatures = function (cameras) {
      if (cameras == null) {
        cameras = []
      }
      const features = {
        footfall: false,
        heatmap: false,
        dwellmap: false,
        face: false,
        sales: false,
        queue: false,
      }

      if (cameras != null) {
        cameras.forEach(function (cam) {
          if (cam.functions.use.counter) {
            features.footfall = true
          }
          if (cam.functions.use.heatmap) {
            features.heatmap = true
          }
          if (cam.functions.use.dwellmap) {
            features.dwellmap = true
          }
          if (cam.functions.use.face) {
            features.face = true
          }
          if (cam.functions.use.rtFace) {
            features.rtFace = true
          }
          if (cam.functions.use.queue) {
            return (features.queue = true)
          }
        })
      }
      return HeaderSrv.fetchCurrentCompany().then(function (company) {
        features.sales = company.sales != null ? company.sales.active : undefined
        return features
      })
    }

    const getOrganizationByCamera = function (cameraId, cache) {
      if (cache == null) {
        cache = true
      }
      const deferred = $q.defer()

      async.waterfall(
        [
          (callback) =>
            ApiSrv.getCamera({ id: cameraId, isCache: cache }).then(
              (camera) => callback(null, camera),
              (err) => callback(err)
            ),
          (camera, callback) =>
            ApiSrv.getStore({ id: camera._storeId, isCache: cache }).then(
              (store) =>
                getFeatures([camera]).then((features) =>
                  callback(null, {
                    store,
                    sensor: camera,
                    sensors: [camera],
                    timezone: store.timezone,
                    features,
                    useFaceCountOnly: camera.seedCounterOfGA === "None",
                  })
                ),
              (err) => callback(err)
            ),
        ],
        function (err, result) {
          if (err) {
            deferred.reject(err)
          }
          return deferred.resolve(result)
        }
      )
      return deferred.promise
    }

    const hasStoreSales = (stores) =>
      stores != null
        ? stores.reduce(
            (p, store) =>
              p ||
              (__guard__(store != null ? store.sales : undefined, (x) => x.active) &&
                __guard__(store != null ? store.sales : undefined, (x1) => x1.driverName) !==
                  "NONE"),
            false
          )
        : undefined

    const getOrganizationByStore = function (storeId, cache) {
      if (cache == null) {
        cache = true
      }
      const deferred = $q.defer()

      async.parallel(
        {
          store(callback) {
            return ApiSrv.getStore({ id: storeId, isCache: cache }).then(
              (store) => callback(null, store),
              (err) => callback(err)
            )
          },
          cameras(callback) {
            return ApiSrv.getCameraOfStore({ id: storeId, isCache: cache }).then(
              (cameras) => callback(null, cameras),
              (err) => callback(err)
            )
          },
        },
        function (err, results) {
          if (err) {
            deferred.reject(err)
          }

          return getFeatures(results.cameras).then(function (features) {
            features.sales = features.sales && hasStoreSales([results.store])
            return deferred.resolve({
              store: results.store,
              sensors: results.cameras,
              timezone: results.store != null ? results.store.timezone : undefined,
              features,
              useFaceCountOnly: getUseFaceCountOnly(features, [results.store]),
            })
          })
        }
      )

      return deferred.promise
    }

    const getOrganizationByStoreGroup = function (storeGroupId, cache) {
      if (cache == null) {
        cache = true
      }
      const deferred = $q.defer()

      async.parallel(
        {
          storeGroup(callback) {
            return ApiSrv.getStoreGroup({ id: storeGroupId, isCache: cache }).then(
              (group) => callback(null, group),
              (err) => callback(err)
            )
          },
          stores(callback) {
            return StoreGroupSrv.getMember(
              { id: storeGroupId },
              (stores) => callback(null, stores),
              function (err) {
                if (err.status === 404) {
                  return callback(null, [])
                }
                return callback(err)
              }
            )
          },
          cameras(callback) {
            return ApiSrv.getCameraOfStoreGroup({ id: storeGroupId, isCache: cache }).then(
              (cameras) => callback(null, cameras),
              (err) => callback(err)
            )
          },
        },
        function (err, results) {
          if (err) {
            deferred.reject(err)
          }

          return getFeatures(results.cameras).then(function (features) {
            features.sales = features.sales && hasStoreSales(results.stores)
            return deferred.resolve({
              store: results.storeGroup,
              stores: results.stores,
              sensors: results.cameras,
              timezone: DateTimeSrv.getTimezoneofStores(results.stores),
              features,
              useFaceCountOnly: getUseFaceCountOnly(features, results.stores),
            })
          })
        }
      )

      return deferred.promise
    }

    const getOrganizationByCompany = function (companyId, cache) {
      if (cache == null) {
        cache = true
      }
      const deferred = $q.defer()
      async.parallel(
        {
          company(callback) {
            return ApiSrv.getCompany({ id: companyId, isCache: cache }).then(
              (company) => callback(null, company),
              (err) => callback(err)
            )
          },
          stores(callback) {
            return ApiSrv.getAllStore({ companyId, isCache: cache }).then(
              (stores) => callback(null, stores),
              (err) => callback(err)
            )
          },
          cameras(callback) {
            return ApiSrv.getAllCamera({ companyId, isCache: cache }).then(
              (cameras) => callback(null, cameras),
              (err) => callback(err)
            )
          },
        },
        function (err, results) {
          if (err) {
            deferred.reject(err)
          }

          return getFeatures(results.cameras).then((features) =>
            deferred.resolve({
              store: results.company,
              stores: results.stores,
              sensors: results.cameras,
              timezone: DateTimeSrv.getTimezoneofStores(results.stores),
              features,
              useFaceCountOnly: getUseFaceCountOnly(features, results.stores),
            })
          )
        }
      )

      return deferred.promise
    }

    const getOrganizationInfo = function (orgType, id, isCache) {
      if (isCache == null) {
        isCache = true
      }
      const deferred = $q.defer()

      if (!["sensor", "store", "storegroup", "company"].includes(orgType)) {
        deferred.reject("Not Supported Organization Type.")
      }

      let getOrganization = null
      switch (orgType) {
        case "storegroup":
          getOrganization = getOrganizationByStoreGroup(id, isCache)
          break
        case "company":
          getOrganization = getOrganizationByCompany(id, isCache)
          break
        case "sensor":
          getOrganization = getOrganizationByCamera(id, isCache)
          break
        default:
          getOrganization = getOrganizationByStore(id, isCache)
      }

      getOrganization.then(
        (results) => deferred.resolve(results),
        (err) => deferred.reject(err)
      )

      return deferred.promise
    }

    return {
      getOrganizationInfo,
      getStore: getOrganizationByStore,
      getStoreGroup: getOrganizationByStoreGroup,
      getCompany: getOrganizationByCompany,
    }
  })

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
