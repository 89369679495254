/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

angular
  .module("uCountitUiApp")

  .directive("favoriteStores", (Auth, ApiSrv) => ({
    restrict: "EA",
    controller: "favoriteCtrl",

    template: [
      '<div class="row tile-view favorite">',
      "<div data-ng-repeat=\"store in favoriteStores | orderBy: 'name'\">",
      '<div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">',
      '<article class="photo-box">',
      '<div class="photo-container" data-ng-class="{\'select\':stores[$index]}" data-ng-click="selectStoreItem($index)">',
      '<div class="stretchy-wrapper">',
      "<img data-ng-src=\"{{store.thumbnailUrl('xlarge')}}\" >",
      "</div>",
      '<div class="tit" title="{{store.company.name}}"><i class="mdi mdi-domain mdi-14px mdi-gray"></i> {{store.company.name}}</div>',
      '<div class="sub-tit" title="{{store.name}}"><i class="mdi mdi-store mdi-18px"></i> {{store.name}}</div>',
      "</div>",
      "</article>",
      "</div>",
      '<div data-ng-if="stores[$index]">',
      '<div data-summary-store class="col-lg-12 col-md-12 col-sm-12 col-xs-12 summary" data-data="store">',
      "</div>",
      "</div>",
      "</div>",
    ].join(""),

    link(scope) {
      return async.waterfall(
        [
          (callback) =>
            ApiSrv.getAllCompany().then(
              (response) => callback(null, response),
              (err) => callback(err)
            ),
        ],
        (err, companies) =>
          ApiSrv.getFavoriteStores().then(function (response) {
            scope.favoriteStores = []
            return (() => {
              const result = []
              for (var store of Array.from(response)) {
                store.company = _.find(companies, { _id: store._companyId })
                if (store.company) {
                  result.push(scope.favoriteStores.push(store))
                } else {
                  result.push(undefined)
                }
              }
              return result
            })()
          })
      )
    },
  }))
  .directive("favoriteCameras", (ApiSrv, Auth) => ({
    restrict: "EA",
    controller: "favoriteCtrl",

    template: [
      '<div class="row tile-view favorite">',
      "<div data-ng-repeat=\"camera in favoriteCameras | orderBy: ['store.name', 'name']\">",
      '<div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">',
      '<article class="photo-box" data-ng-model="selected">',
      '<div class="photo-container" data-ng-class="{\'select\':cameras[$index]}" data-ng-click="selectCameraItem($index, favoriteCameras.length)">',
      '<div class="stretchy-wrapper">',
      "<img data-ng-src=\"{{camera.thumbnailUrl('xlarge')}}\" />",
      "</div>",
      '<div class="tit" title="{{camera.store.name}}"><i class="mdi mdi-store mdi-16px mdi-gray"></i> {{camera.store.name}}</div>',
      '<div class="sub-tit" title="{{camera.name}}"><i class="mdi mdi-camera mdi-18px"></i> {{camera.name}}</div>',
      "</div>",
      "</article>",
      "</div>",
      '<div data-ng-if="cameras[$index]">',
      '<div data-summary-camera class="col-lg-12 col-md-12 col-sm-12 col-xs-12 summary" data-data="camera">',
      "</div>",
      "</div>",
      "</div>",
      "</div>",
    ].join(""),

    link(scope) {
      return async.waterfall(
        [
          (callback) =>
            ApiSrv.getAllStore().then(
              (response) => callback(null, response),
              (err) => callback(err)
            ),
        ],
        (err, stores) =>
          ApiSrv.getFavoriteCameras().then(function (response) {
            scope.favoriteCameras = []
            return (() => {
              const result = []
              for (var camera of Array.from(response)) {
                camera.store = _.find(stores, { _id: camera._storeId })
                if (camera.store) {
                  result.push(scope.favoriteCameras.push(camera))
                } else {
                  result.push(undefined)
                }
              }
              return result
            })()
          })
      )
    },
  }))
  .directive("imgSrc", (Auth) => ({
    restrict: "EA",

    scope: {
      imgSrc: "=",
      imgSize: "=",
    },

    controller($scope, $element, $cookieStore) {
      return $scope.$watch("imgSrc", function (imgSrc) {
        if (!imgSrc) {
          return
        }
        $element.attr("src", `${imgSrc}/${$scope.imgSize}`)
        return $element.bind("error", () => $element.attr("src", "/assets/images/no-image.svg"))
      })
    },
  }))
