/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

import env from "../../../../config/environment"

angular
  .module("uCountitUiApp")
  .controller(
    "StoreReportCtrl",
    function (
      $scope,
      $state,
      $stateParams,
      $element,
      ApiSrv,
      DateTimeSrv,
      SelectorSrv,
      usSpinnerService
    ) {
      $scope.isLocalgrey = env.isLocalgrey

      $scope.currentPage = "report"
      $scope.options = { id: $stateParams.id }
      $scope.validTime = false
      $scope.maxMonth = 6
      $scope.pageOptions = {
        sampling: "day",
        referenceDay: undefined,
        content: "report",
      }

      $scope.useFootfall = false
      $scope.useFace = false
      $scope.useHeatmap = false
      $scope.useSales = false
      $scope.useFaceCountOnly = false

      $scope.isNumber = (num) => !isNaN(parseFloat(num)) && isFinite(num)

      const loadingQueue = []
      let firstLoading = false
      $scope.showLoading = function (key) {
        if (!firstLoading) {
          return
        }
        if (key) {
          loadingQueue.push(key)
        }
        angular.element(".splash").show()
        usSpinnerService.spin("spinner")
        return angular.element(".spinner").css("position", "fixed")
      }

      $scope.hideLoading = function (key) {
        if (key) {
          _.remove(loadingQueue, (raw) => raw === key)
          if (loadingQueue.length) {
            return
          }
        }

        firstLoading = true

        angular.element(".splash").hide()
        return usSpinnerService.stop("spinner")
      }

      $scope.reload = function (fromInit) {
        if (fromInit == null) {
          fromInit = false
        }
        if (fromInit) {
          $scope.validTime = true
        }
        const datetime = moment($scope.pageOptions.referenceDay)
          .endOf("day")
          .format("YYYY-MM-DDTHH:00:00")
        const cacheTime = moment().add(1, "hours").startOf("hour").format()
        $scope.todayString = moment(datetime).format("YYYYMMDD0000")
        $scope.yesterdayString = moment(datetime).subtract(1, "days").format("YYYYMMDD0000")
        $scope.desterdayString = moment(datetime).subtract(2, "days").format("YYYYMMDD0000")
        $scope.aWeekAgoString = moment(datetime)
          .subtract(1, "week")
          .add(1, "days")
          .format("YYYYMMDD0000")
        $scope.tomorrowString = moment(datetime).add(1, "days").format("YYYYMMDD0000")
        $scope.sevenDaysAgoString = moment(datetime)
          .subtract(7, "days")
          .add(1, "days")
          .format("YYYYMMDD0000")
        $scope.thirdyDaysAgoString = moment(datetime)
          .subtract(30, "days")
          .add(1, "days")
          .format("YYYYMMDD0000")
        $scope.sixMonthsAgoString = moment(datetime)
          .subtract(26, "weeks")
          .add(1, "days")
          .format("YYYYMMDD0000")
        $scope.maxMonthAgoString = moment(datetime)
          .subtract($scope.maxMonth, "months")
          .format("YYYYMMDD0000")

        $scope.thisWeekString = moment(datetime).startOf("isoWeek").format("YYYYMMDD0000")
        $scope.lastWeekString = moment(datetime)
          .startOf("isoWeek")
          .subtract(1, "weeks")
          .format("YYYYMMDD0000")
        $scope.blastWeeksAgoString = moment(datetime)
          .startOf("isoWeek")
          .subtract(2, "weeks")
          .format("YYYYMMDD0000")

        $scope.options = {
          id: $stateParams.id,
          datetime: datetime,
          cacheTime: cacheTime,
        }

        return $scope.$broadcast("SET_DATETIME")
      }

      const init = () =>
        SelectorSrv.getOrganizationInfo($stateParams.orgType, $stateParams.id)
          .then(function (orgInfo) {
            const isLocalgrey = env.isLocalgrey
            $scope.currentStore = orgInfo.store
            $scope.timezone = orgInfo.timezone
            $scope.cameras = orgInfo.sensors
            $scope.useFootfall = orgInfo.features.footfall
            $scope.useFace = orgInfo.features.face && !isLocalgrey
            $scope.useHeatmap = orgInfo.features.heatmap || orgInfo.features.dwellmap
            $scope.useSales = orgInfo.features.sales && !isLocalgrey
            return ($scope.useFaceCountOnly = orgInfo.useFaceCountOnly && !isLocalgrey)
          })
          .then(
            () => $scope.$broadcast("init-date-search", $scope.timezone),
            function (err) {
              if (err) {
                console.error(err)
              }
              return $state.go("default")
            }
          )

      return angular.element(() => init())
    }
  )
  .controller(
    "StoreEntranceThirdyCtrl",
    function ($scope, $element, $filter, ApiSrv, ChartOptionSrv, Locale) {
      const vm = ($scope.vm = this)
      vm.chartOption = angular.copy(ChartOptionSrv.daysLineChart)

      $scope.$on("SET_DATETIME", function () {
        if (!$scope.validTime) {
          return
        }
        const options = {
          from: $scope.thirdyDaysAgoString,
          to: $scope.tomorrowString,
          sampling: "1d",
          entrance: true,
        }

        $scope.showLoading("StoreEntranceThirdyCtrl")

        return ApiSrv.getCountOfStore(_.merge({}, $scope.options, options)).then(function (
          response
        ) {
          vm.chartData = []

          if (!__guard__(response != null ? response.data : undefined, (x) => x.length)) {
            $scope.hideLoading("StoreEntranceThirdyCtrl")
            return
          }

          const chartData = {
            lines: {
              fill: true,
            },
            label: Locale.string("Last 30 days"),
            data: __range__(0, 30, false).map((i) => [i, 0]),
          }

          vm.chartOption.xaxis.ticks = (() =>
            _.map(
              _.filter(__range__(0, 30, false), (n) => n % 3 === 0),
              (day) => [
                day,
                Locale.dateTime(moment.utc(options.from, "YYYYMMDD0000").add(day, "days"), "date"),
              ]
            ))()

          vm.chartOption.tooltip.content = function (label, x, y) {
            const datetime = moment.utc(options.from, "YYYYMMDD0000").add(x, "days")
            return `<b>${label}</b><br/> \
${Locale.string("Date")}: ${Locale.dateTime(datetime, "fullDate")}<br/> \
${Locale.string("Count")}: ${$filter("number")(y)}`
          }

          vm.chartOption.legend = {
            container: angular.element("#legend-thirdy", $element),
            noColumns: 0,
          }

          response.data.forEach(
            (raw) =>
              (chartData.data[
                moment
                  .utc(raw.statisticFor)
                  .startOf("day")
                  .diff(moment.utc(options.from, "YYYYMMDD0000"), "days")
              ][1] = raw.count)
          )

          vm.chartData.push(chartData)
          return $scope.hideLoading("StoreEntranceThirdyCtrl")
        })
      })

      let startFlag = false

      return $scope.$on("localizeResourcesUpdated", function () {
        if (startFlag) {
          $scope.$broadcast("SET_DATETIME")
        }
        return (startFlag = true)
      })
    }
  )
  .controller(
    "StoreEntranceSevenCtrl",
    function ($scope, $element, $filter, ApiSrv, ChartOptionSrv, Locale) {
      const vm = ($scope.vm = this)
      vm.chartOption = angular.copy(ChartOptionSrv.daysLineChart)

      $scope.$on("SET_DATETIME", function () {
        if (!$scope.validTime) {
          return
        }
        const options = {
          from: $scope.sevenDaysAgoString,
          to: $scope.tomorrowString,
          sampling: "1d",
          entrance: true,
        }

        $scope.showLoading("StoreEntranceSevenCtrl")

        return ApiSrv.getCountOfStore(_.merge({}, $scope.options, options)).then(function (
          response
        ) {
          let i
          vm.chartData = []

          if (!__guard__(response != null ? response.data : undefined, (x) => x.length)) {
            $scope.hideLoading("StoreEntranceSevenCtrl")
            return
          }

          const chartData = {
            lines: {
              fill: true,
            },
            label: Locale.string("Last 7 days"),
            data: (() => {
              const result = []
              for (i = 0; i < 7; i++) {
                result.push([i, 0])
              }
              return result
            })(),
          }

          response.data.forEach(
            (raw) =>
              (chartData.data[
                moment
                  .utc(raw.statisticFor)
                  .startOf("day")
                  .diff(moment.utc(options.from, "YYYYMMDD0000"), "days")
              ][1] = raw.count)
          )
          vm.chartData.push(chartData)

          vm.chartOption.xaxis.ticks = (() => {
            const result1 = []

            for (i = 0; i < 7; i++) {
              result1.push([
                i,
                Locale.dateTime(moment.utc(options.from, "YYYYMMDD0000").add(i, "days"), "date"),
              ])
            }
            return result1
          })()

          vm.chartOption.tooltip.content = function (label, x, y) {
            const datetime = moment.utc(options.from, "YYYYMMDD0000").add(x, "days")
            return `<b>${label}</b><br/> \
${Locale.string("Date")}: ${Locale.dateTime(datetime, "fullDate")}<br/> \
${Locale.string("Count")}: ${$filter("number")(y)}`
          }

          vm.chartOption.legend = {
            container: angular.element("#legend-seven", $element),
            noColumns: 0,
          }

          return $scope.hideLoading("StoreEntranceSevenCtrl")
        })
      })

      let startFlag = false

      return $scope.$on("localizeResourcesUpdated", function () {
        if (startFlag) {
          $scope.$broadcast("SET_DATETIME")
        }
        return (startFlag = true)
      })
    }
  )
  .controller(
    "StoreEntranceSixMonthCtrl",
    function ($scope, $filter, ApiSrv, ChartOptionSrv, Locale) {
      const vm = ($scope.vm = this)
      vm.chartOption = angular.copy(ChartOptionSrv.daysLineChart)

      $scope.$on("SET_DATETIME", function () {
        if (!$scope.validTime) {
          return
        }
        const options = {
          from: $scope.sixMonthsAgoString,
          to: $scope.tomorrowString,
          sampling: "1w",
          entrance: true,
        }

        vm.chartOption.xaxis.ticks = (() =>
          _.map(
            _.filter(__range__(0, 27, false), (n) => n % 3 === 0 || n === 26),
            (day) => [
              day,
              Locale.dateTime(
                moment.utc(options.from, "YYYYMMDD0000").endOf("week").add(day, "weeks"),
                "date"
              ),
            ]
          ))()

        vm.chartOption.tooltip.content = function (label, x, y) {
          const datetime = moment.utc(options.from, "YYYYMMDD0000").endOf("week").add(x, "weeks")
          return `<b>${label}</b><br/> \
${Locale.string("Date")}: ${Locale.dateTime(datetime, "fullDate")}<br/> \
${Locale.string("Count")}: ${$filter("number")(y)}`
        }

        $scope.showLoading("StoreEntranceSixMonthCtrl")

        return ApiSrv.getCountOfStore(_.merge({}, $scope.options, options)).then(function (
          response
        ) {
          vm.chartData = []

          if (!__guard__(response != null ? response.data : undefined, (x) => x.length)) {
            $scope.hideLoading("StoreEntranceSixMonthCtrl")
            return
          }

          const chartData = {
            lines: {
              fill: true,
            },
            label: Locale.string("Last 6 months"),
            data: __range__(0, 27, false).map((i) => [i, 0]),
          }

          response.data.forEach(
            (raw) =>
              (chartData.data[
                moment
                  .utc(raw.statisticFor)
                  .startOf("week")
                  .diff(moment.utc(options.from, "YYYYMMDD0000").startOf("week"), "weeks")
              ][1] = raw.count)
          )

          vm.chartData.push(chartData)
          return $scope.hideLoading("StoreEntranceSixMonthCtrl")
        })
      })

      let startFlag = false

      return $scope.$on("localizeResourcesUpdated", function () {
        if (startFlag) {
          $scope.$broadcast("SET_DATETIME")
        }
        return (startFlag = true)
      })
    }
  )
  .controller(
    "StoreEntranceDailyCtrl",
    function ($scope, $element, $filter, ApiSrv, ChartOptionSrv, Locale) {
      const vm = ($scope.vm = this)
      vm.chartOption = ChartOptionSrv.dailyLineChart
      let lastHour = 24

      const getDefaultData = function () {
        let i
        return [
          {
            label: Locale.string("1 week before"),
            lines: {
              fill: true,
            },
            beforeValue: 0,
            data: (() => {
              const result = []
              for (i = 0; i < 24; i++) {
                result.push([i, 0])
              }
              return result
            })(),
          },
          {
            label: Locale.string("1 day before"),
            lines: {
              fill: true,
            },
            beforeValue: 0,
            data: (() => {
              const result1 = []
              for (i = 0; i < 24; i++) {
                result1.push([i, 0])
              }
              return result1
            })(),
          },
          {
            label: moment($scope.todayString, "YYYYMMDD0000").format("ll"),
            beforeValue: 0,
            data: (() => {
              const result2 = []
              for (i = 0; i < 24; i++) {
                result2.push([i, 0])
              }
              return result2
            })(),
          },
        ]
      }

      $scope.$on("SET_DATETIME", function () {
        if (!$scope.validTime) {
          return
        }
        const options = {
          from: $scope.aWeekAgoString,
          to: $scope.tomorrowString,
          sampling: "1h",
          entrance: true,
        }

        $scope.showLoading("StoreEntranceDailyCtrl")

        return ApiSrv.getCountOfStore(_.merge({}, $scope.options, options)).then(function (
          response
        ) {
          vm.chartData = []

          if (!__guard__(response != null ? response.data : undefined, (x) => x.length)) {
            $scope.hideLoading("StoreEntranceDailyCtrl")
            return
          }

          const chartData = getDefaultData()
          _.forEach(response.data, function (raw) {
            let selectedChartData
            const hour = moment.utc(raw.statisticFor).hours()
            switch (moment.utc(raw.statisticFor).format("YYYYMMDD0000")) {
              case $scope.todayString:
                selectedChartData = chartData[2]
                break
              case $scope.yesterdayString:
                selectedChartData = chartData[1]
                break
              case $scope.aWeekAgoString:
                selectedChartData = chartData[0]
                break
              default:
                return
            }

            const count = raw.count - selectedChartData.beforeValue
            selectedChartData.data[hour] = [hour, count]
            return (selectedChartData.beforeValue = raw.count)
          })

          lastHour = moment.utc(__guard__(_.last(response.data), (x1) => x1.statisticFor)).hour()
          chartData[2].data = _.filter(chartData[2].data, (raw) => raw[0] <= lastHour)

          vm.chartData = chartData
          vm.chartOption.tooltip.content = function (label, x, y) {
            const startDatetime = moment.utc(options.from, "YYYYMMDD0000").add(x, "hours")
            return `<b>${label}</b><br/> \
${Locale.string("time from")}: ${Locale.dateTime(startDatetime, "hour")}<br/> \
${Locale.string("Count")}: ${$filter("number")(y)}`
          }
          vm.chartOption.legend = {
            container: angular.element("#legend-daily", $element),
            noColumns: 0,
          }

          return $scope.hideLoading("StoreEntranceDailyCtrl")
        })
      })

      let startFlag = false

      return $scope.$on("localizeResourcesUpdated", function () {
        if (startFlag) {
          $scope.$broadcast("SET_DATETIME")
        }
        return (startFlag = true)
      })
    }
  )
  .controller("StoreInflowDailyCtrl", function ($scope, $element, ApiSrv, ChartOptionSrv, Locale) {
    $scope.vm = this
    $scope.chartOption = ChartOptionSrv.dailyLineChart
    delete $scope.chartOption.yaxis.min

    const getDefaultData = function () {
      let i
      return [
        {
          label: Locale.string("1 week before"),
          lines: {
            fill: true,
          },
          beforeValue: 0,
          data: (() => {
            const result = []
            for (i = 0; i < 24; i++) {
              result.push([i, 0])
            }
            return result
          })(),
        },
        {
          label: Locale.string("1 day before"),
          lines: {
            fill: true,
          },
          beforeValue: 0,
          data: (() => {
            const result1 = []
            for (i = 0; i < 24; i++) {
              result1.push([i, 0])
            }
            return result1
          })(),
        },
        {
          label: moment($scope.todayString, "YYYYMMDD0000").format("ll"),
          beforeValue: 0,
          data: (() => {
            const result2 = []
            for (i = 0; i < 24; i++) {
              result2.push([i, 0])
            }
            return result2
          })(),
        },
      ]
    }

    $scope.$on("SET_DATETIME", function () {
      if (!$scope.validTime) {
        return
      }
      const options = {
        from: $scope.aWeekAgoString,
        to: $scope.tomorrowString,
        sampling: "1h",
        entrance: true,
      }

      let lastHour = 24
      let exitLastHour = 24

      $scope.showLoading("StoreInflowDailyCtrl")

      return async.waterfall(
        [
          (callback) =>
            ApiSrv.getCountOfStore(_.merge({}, $scope.options, options)).then(function (response) {
              if (!__guard__(response != null ? response.data : undefined, (x) => x.length)) {
                callback("nodata")
              }

              const entranceData = getDefaultData()
              _.forEach(response.data, function (raw) {
                let selectedChartData
                const hour = moment.utc(raw.statisticFor).hours()
                switch (moment.utc(raw.statisticFor).format("YYYYMMDD0000")) {
                  case $scope.todayString:
                    selectedChartData = entranceData[2]
                    break
                  case $scope.yesterdayString:
                    selectedChartData = entranceData[1]
                    break
                  case $scope.aWeekAgoString:
                    selectedChartData = entranceData[0]
                    break
                  default:
                    return
                }

                const count = raw.count - selectedChartData.beforeValue
                selectedChartData.data[hour] = [hour, count]
                return (selectedChartData.beforeValue = raw.count)
              })

              lastHour = moment
                .utc(__guard__(_.last(response.data), (x1) => x1.statisticFor))
                .hour()
              return callback(null, entranceData)
            }),

          function (entranceData, callback) {
            options.entrance = false
            options.exit = true

            return ApiSrv.getCountOfStore(_.merge({}, $scope.options, options)).then(function (
              response
            ) {
              if (!__guard__(response != null ? response.data : undefined, (x) => x.length)) {
                return callback("nodata")
              }

              const exitData = getDefaultData()
              _.forEach(response.data, function (raw) {
                let selectedChartData
                const hour = moment.utc(raw.statisticFor).hours()
                switch (moment.utc(raw.statisticFor).format("YYYYMMDD0000")) {
                  case $scope.todayString:
                    selectedChartData = exitData[2]
                    break
                  case $scope.yesterdayString:
                    selectedChartData = exitData[1]
                    break
                  case $scope.aWeekAgoString:
                    selectedChartData = exitData[0]
                    break
                  default:
                    return
                }

                const count = raw.count - selectedChartData.beforeValue
                selectedChartData.data[hour] = [hour, count]
                return (selectedChartData.beforeValue = raw.count)
              })

              const inflowData = getDefaultData()

              _.forEach(exitData, (obj, index) =>
                _.forEach(obj.data, function (extRaw, idx) {
                  const entRaw = entranceData[index].data[idx]
                  if (entRaw[0] !== extRaw[0] || entRaw[0] !== idx) {
                    return callback(new Error("Mismatch entrance data and exit data"))
                  }

                  return (inflowData[index].data[idx][1] = entRaw[1] - extRaw[1])
                })
              )

              exitLastHour = moment
                .utc(__guard__(_.last(response.data), (x1) => x1.statisticFor))
                .hour()
              if (lastHour < exitLastHour) {
                lastHour = exitLastHour
              }

              return callback(null, inflowData)
            })
          },
        ],
        function (err, result) {
          if (err) {
            $scope.chartData = []
            $scope.hideLoading("StoreInflowDailyCtrl")
            return err
          }

          result[2].data = _.filter(result[2].data, (raw) => raw[0] <= lastHour)

          $scope.chartOption.legend = {
            container: angular.element("#legendContainer", $element),
            noColumns: 0,
          }

          $scope.chartData = result
          return $scope.hideLoading("StoreInflowDailyCtrl")
        }
      )
    })

    let startFlag = false

    return $scope.$on("localizeResourcesUpdated", function () {
      if (startFlag) {
        $scope.$broadcast("SET_DATETIME")
      }
      return (startFlag = true)
    })
  })
  .controller("StoreEntranceWeeklyCtrl", function ($scope, ApiSrv, ChartOptionSrv) {
    const vm = ($scope.vm = this)
    vm.chartOption = ChartOptionSrv.weeklyLineChart

    $scope.$on("SET_DATETIME", function () {
      if (!$scope.validTime) {
        return
      }
      $scope.showLoading("StoreEntranceWeeklyCtrl")
      return ApiSrv.getStoreWeeklyCount($scope.options).then(function (response) {
        vm.chartData = []

        if (!__guard__(response != null ? response.data : undefined, (x) => x.length)) {
          $scope.hideLoading("StoreEntranceWeeklyCtrl")
          return
        }

        if (!response || !response.length) {
          if (vm.chartData) {
            response = angular.copy(vm.chartData)
          }

          for (var eachData of Array.from(response)) {
            eachData.data = [1, 2, 3, 4, 5, 6].map((i) => [i, 0])
          }
        }

        vm.chartData = response

        return $scope.hideLoading("StoreEntranceWeeklyCtrl")
      })
    })

    let startFlag = false
    return $scope.$on("localizeResourcesUpdated", function () {
      if (startFlag) {
        $scope.$broadcast("SET_DATETIME")
      }
      return (startFlag = true)
    })
  })
  .controller(
    "StoreLabelWeeklyCtrl",
    function ($scope, $element, $filter, ApiSrv, ChartOptionSrv, UtilSrv, Locale) {
      const vm = ($scope.vm = this)
      vm.chartBarOption = angular.copy(ChartOptionSrv.sevenDaysBarChart)
      vm.chartPieOption = ChartOptionSrv.weeklyPipChart

      $scope.$on("SET_DATETIME", function () {
        let i
        if (!$scope.validTime) {
          return
        }
        const options = {
          from: $scope.sevenDaysAgoString,
          to: $scope.tomorrowString,
          item: "label",
          sampling: "1d",
          entrance: "all",
        }

        vm.chartBarOption.xaxis.ticks = (() => {
          let j
          const result = []
          for (j = 0, i = j; j < 7; j++, i = j) {
            result.push([
              i,
              Locale.dateTime(moment.utc(options.from, "YYYYMMDD0000").add(i, "days"), "date"),
            ])
          }
          return result
        })()

        $scope.showLoading("StoreLabelWeeklyCtrl")

        return async.waterfall(
          [
            function (callback) {
              if (!$scope.options) {
                return callback("no store")
              }
              return ApiSrv.getStore({ id: $scope.options.id }).then(function (store) {
                const labelMap = {}
                store.labels.forEach(function (label) {
                  if ((label != null ? label.active : undefined) !== true) {
                    return
                  }
                  return (labelMap[label._id] = {
                    name: label.name,
                    color: label.color,
                    data: (() => {
                      const result1 = []
                      for (i = 0; i < 7; i++) {
                        result1.push([i, 0])
                      }
                      return result1
                    })(),
                    weekly: 0,
                    yesterday: 0,
                    active: label.active,
                  })
                })

                return callback(null, labelMap)
              })
            },
            (labelMap, callback) =>
              ApiSrv.getCountOfStore(_.merge({}, $scope.options, options)).then(function (
                response
              ) {
                if (!__guard__(response != null ? response.data : undefined, (x) => x.length)) {
                  return callback("nodata")
                }

                //vm.chartBarOption.xaxis.ticks = ([i, moment($scope.aWeekAgoString, 'YYYYMMDD0000').add(i, 'days').format('MM/DD ddd')] for i in [0...7])
                response.data.forEach(function (raw, idx) {
                  if (!labelMap[raw.labelId]) {
                    return
                  }

                  labelMap[raw.labelId].data[
                    moment
                      .utc(raw.statisticFor)
                      .startOf("day")
                      .diff(moment.utc(options.from, "YYYYMMDD0000"), "days")
                  ][1] = raw.count
                  if (
                    $scope.yesterdayString === moment.utc(raw.statisticFor).format("YYYYMMDD0000")
                  ) {
                    labelMap[raw.labelId].yesterday = raw.count
                  }
                  return (labelMap[raw.labelId].weekly += raw.count)
                })
                return callback(null, labelMap)
              }),
          ],
          function (err, labelMap) {
            vm.chartData = []
            vm.chartLastWeekPieData = []
            vm.chartYesterdayPieData = []

            if (err) {
              $scope.hideLoading("StoreLabelWeeklyCtrl")
              return
            }

            vm.chartBarOption.tooltip.content = function (label, x, y, item) {
              const datetime = moment.utc(options.from, "YYYYMMDD0000").add(x, "days")
              const dataIdx = item.dataIndex
              const result = [`<b>${Locale.dateTime(datetime, "fullDate")}</b><br/>`]
              _.forEach(labelMap, function (label) {
                result.push(`${label.name}: `)
                return result.push(`${$filter("number")(label.data[dataIdx][1])}<br />`)
              })

              return result.join("")
            }

            vm.chartBarOption.legend = {
              container: angular.element("#legend-weekly", $element),
              noColumns: 0,
            }

            let idx = 0
            for (var id in labelMap) {
              if (labelMap[id].data.length) {
                vm.chartData.push({
                  label: labelMap[id].name,
                  data: labelMap[id].data,
                  color: `rgb(${labelMap[id].color})`,
                  bars: {
                    order: idx++,
                  },
                })
                vm.chartLastWeekPieData.push({
                  label: labelMap[id].name,
                  data: labelMap[id].weekly,
                  color: `rgb(${labelMap[id].color})`,
                })
                vm.chartYesterdayPieData.push({
                  label: labelMap[id].name,
                  data: labelMap[id].yesterday,
                  color: `rgb(${labelMap[id].color})`,
                })
              }
            }

            return $scope.hideLoading("StoreLabelWeeklyCtrl")
          }
        )
      })

      let startFlag = false

      return $scope.$on("localizeResourcesUpdated", function () {
        if (startFlag) {
          $scope.$broadcast("SET_DATETIME")
        }
        return (startFlag = true)
      })
    }
  )
  .controller("StoreFaceSevenCtrl", function ($scope, ApiSrv, Face, Locale, HeaderSrv) {
    $scope.$on("SET_DATETIME", function () {
      if (!$scope.validTime || env.isLocalgrey) {
        return
      }
      const options = _.merge({}, $scope.options, {
        from: moment.utc($scope.sevenDaysAgoString, "YYYYMMDD0000"),
        to: moment.utc($scope.tomorrowString, "YYYYMMDD0000"),
        sampling: "1d",
        selectedSampling: "day",
        orgType: "store",
      })

      const dataLength = moment(options.to).diff(moment(options.from), "days")

      $scope.showLoading("StoreFaceSevenCtrl")

      return HeaderSrv.fetchCurrentCompany().then(function (company) {
        const ageTypeList = Face.makeAgeTypeList(company.ageGroup.value)

        return async.parallel(
          {
            gender(callback) {
              return Face.getGenderData(options, $scope.useFaceCountOnly).then(
                (response) => callback(null, response),
                (err) => callback(err)
              )
            },
            agegroup(callback) {
              return Face.getAgeGroupData(options, ageTypeList, $scope.useFaceCountOnly).then(
                (response) => callback(null, response),
                (err) => callback(err)
              )
            },
          },
          function (err, response) {
            if (err) {
              console.error(err)
              return
            }

            $scope.genderList = response.gender
            $scope.ageList = response.agegroup

            $scope.xaxisTicks = __range__(0, dataLength, false).map((i) => [
              i,
              Locale.dateTime(moment(options.from).add(i, "days"), "date"),
            ])

            $scope.genderChartOption = {
              label: {
                female: "",
                male: "",
              },
              color: {
                female: Face.genderList[0].color,
                male: Face.genderList[1].color,
              },
              order: {
                female: 0,
                male: 1,
              },
            }

            $scope.ageChartOption = {
              label: {},
              color: {},
              order: {},
            }
            for (let idx = 0; idx < ageTypeList.length; idx++) {
              var ageType = ageTypeList[idx]
              $scope.ageChartOption.label[ageType.name] = ageType.text
              $scope.ageChartOption.color[ageType.name] = ageType.color
              $scope.ageChartOption.order[ageType.name] = idx
            }

            return $scope.hideLoading("StoreFaceSevenCtrl")
          }
        )
      })
    })

    let startFlag = false
    return $scope.$on("localizeResourcesUpdated", function () {
      if (startFlag) {
        $scope.$broadcast("SET_DATETIME")
      }
      return (startFlag = true)
    })
  })
  .controller(
    "StoreGenderCtrl",
    function ($scope, $filter, $element, ApiSrv, ChartOptionSrv, Locale) {
      const vm = ($scope.vm = this)
      vm.chartOption = angular.copy(ChartOptionSrv.sevenDaysBarChart)
      const legendEle = angular.element("#legend-gender", $element)

      return $scope.$parent.$watch("genderList", function (genderList) {
        let femaleAvg, femaleLabel, maleAvg, maleLabel
        vm.chartData = []
        if (!genderList) {
          return
        }

        if (_.isEmpty(genderList)) {
          return legendEle.addClass("invisible")
        }
        if (legendEle.hasClass("invisible")) {
          legendEle.removeClass("invisible")
        }

        const genderSet = angular.copy($scope.$parent.genderChartOption)

        let femaleTotal = 0
        let maleTotal = 0

        _.forEach(genderList, function (gData, idx) {
          if ($scope.isNumber(gData.female) && $scope.isNumber(gData.male)) {
            femaleTotal += gData.female
            return (maleTotal += gData.male)
          }
        })

        if (femaleTotal + maleTotal > 0) {
          femaleAvg = Math.round((femaleTotal / (femaleTotal + maleTotal)) * 100)
          maleAvg = 100 - femaleAvg
          femaleLabel = ` (${femaleAvg}%)`
          maleLabel = ` (${maleAvg}%)`
        } else {
          femaleAvg = maleAvg = 0
          femaleLabel = maleLabel = ""
        }

        genderSet.label = {
          female: `${Locale.string("Female")}` + femaleLabel,
          male: `${Locale.string("Male")}` + maleLabel,
        }

        vm.chartOption.xaxis.ticks = $scope.$parent.xaxisTicks
        vm.chartOption.yaxis.tickFormatter = (val, axis) => `${val}%`
        vm.chartOption.series.bars.barWidth = 0.15
        vm.chartOption.legend = {
          container: legendEle,
          noColumns: 0,
        }
        vm.chartOption.tooltip.content = function (label, x, y, item) {
          let female, male
          const datetime = moment.utc($scope.sevenDaysAgoString, "YYYYMMDD0000").add(x, "days")
          const dataIdx = item.dataIndex
          const femaleValue = genderList[dataIdx].percent.female
          const maleValue = genderList[dataIdx].percent.male
          if ($scope.isNumber(femaleValue) && $scope.isNumber(maleValue)) {
            female = $filter("number")(femaleValue) + "%"
            male = $filter("number")(maleValue) + "%"
          } else {
            female = femaleValue
            male = maleValue
          }

          const result = [
            `<b>${Locale.dateTime(datetime, "fullDate")}</b><br/>`,
            `${Locale.string("Female")}: ${female || ""}<br />`,
            `${Locale.string("Male")}: ${male || ""}`,
          ]

          return result.join("")
        }

        vm.chartOption.grid.markings = [
          {
            color: genderSet.color.female,
            lineWidth: 1,
            yaxis: {
              from: femaleAvg,
              to: femaleAvg,
            },
          },
          {
            color: genderSet.color.male,
            lineWidth: 1,
            yaxis: {
              from: maleAvg,
              to: maleAvg,
            },
          },
        ]

        return (() => {
          const result = []
          for (var name in genderSet.label) {
            var label = genderSet.label[name]
            var chartData = []
            _.forEach(genderList, function (gender, idx) {
              const value = (() => {
                switch (gender.percent[name]) {
                  case null:
                    return 0
                  case "-":
                    return 0
                  default:
                    return gender.percent[name]
                }
              })()
              return chartData.push([idx, value])
            })
            result.push(
              vm.chartData.push({
                label,
                color: genderSet.color[name],
                bars: { order: genderSet.order[name] },
                data: chartData,
              })
            )
          }
          return result
        })()
      })
    }
  )
  .controller("StoreAgeCtrl", function ($scope, $filter, $element, ApiSrv, ChartOptionSrv, Locale) {
    const vm = ($scope.vm = this)
    vm.chartOption = angular.copy(ChartOptionSrv.sevenDaysBarChart)
    const legendEle = angular.element("#legend-age", $element)

    return $scope.$parent.$watch("ageList", function (ageList) {
      vm.chartData = []
      if (!ageList) {
        return
      }

      if (_.isEmpty(ageList)) {
        return legendEle.addClass("invisible")
      }

      if (legendEle.hasClass("invisible")) {
        legendEle.removeClass("invisible")
      }

      const ageSet = angular.copy($scope.$parent.ageChartOption)

      vm.chartOption.xaxis.ticks = $scope.$parent.xaxisTicks
      vm.chartOption.yaxis.tickFormatter = (val, axis) => `${val}%`
      vm.chartOption.series.bars.barWidth = 0.08
      //vm.chartOption.series.stack = true
      vm.chartOption.legend = {
        container: legendEle,
        noColumns: 0,
      }

      vm.chartOption.tooltip.content = function (label, x, y, item) {
        const datetime = moment.utc($scope.sevenDaysAgoString, "YYYYMMDD0000").add(x, "days")
        const dataIdx = item.dataIndex

        const result = [`<b>${Locale.dateTime(datetime, "fullDate")}</b><br/>`]
        for (var name in ageSet.label) {
          label = ageSet.label[name]
          var value = ageList[dataIdx].percent[name]
          var str = $scope.isNumber(value) ? ($filter("number")(value) || 0) + "%" : value

          result.push(`${label}: `)
          result.push(`${str || ""}<br />`)
        }

        return result.join("")
      }

      return (() => {
        const result = []
        for (var name in ageSet.label) {
          var label = ageSet.label[name]
          var chartData = []
          _.forEach(ageList, function (age, idx) {
            const value = (() => {
              switch (age.percent[name]) {
                case null:
                  return 0
                case "-":
                  return 0
                default:
                  return age.percent[name]
              }
            })()
            return chartData.push([idx, value])
          })
          result.push(
            vm.chartData.push({
              label,
              data: chartData,
              color: ageSet.color[name],
              bars: { order: ageSet.order[name] },
            })
          )
        }
        return result
      })()
    })
  })

  .controller(
    "StoreGenderVisitCtrl",
    function ($scope, $filter, $element, ApiSrv, ChartOptionSrv, Locale) {
      const vm = ($scope.vm = this)
      vm.chartOption = angular.copy(ChartOptionSrv.sevenDaysBarChart)
      const legendEle = angular.element("#legend-visit", $element)

      return $scope.$parent.$watch("genderList", function (genderList) {
        vm.chartData = []
        if (!genderList || $scope.useFaceCountOnly) {
          return
        }

        if (_.isEmpty(genderList)) {
          return legendEle.addClass("invisible")
        }

        if (legendEle.hasClass("invisible")) {
          legendEle.removeClass("invisible")
        }

        const genderVisitSet = angular.copy($scope.$parent.genderChartOption)
        genderVisitSet.label = {
          female: `${Locale.string("Female")}`,
          male: `${Locale.string("Male")}`,
        }

        vm.chartOption.xaxis.ticks = $scope.$parent.xaxisTicks
        vm.chartOption.series.bars.barWidth = 0.3
        vm.chartOption.series.stack = true
        vm.chartOption.legend = {
          container: legendEle,
          noColumns: 0,
        }

        vm.chartOption.tooltip.content = function (label, x, y, item) {
          let female, male, total
          const datetime = moment.utc($scope.sevenDaysAgoString, "YYYYMMDD0000").add(x, "days")
          const dataIdx = item.dataIndex
          const femaleValue = genderList[dataIdx].female
          const maleValue = genderList[dataIdx].male
          if ($scope.isNumber(femaleValue) && $scope.isNumber(maleValue)) {
            female = $filter("number")(femaleValue)
            male = $filter("number")(maleValue)
            total = $filter("number")(femaleValue + maleValue)
          } else {
            total = female = femaleValue
            male = maleValue
          }

          const result = [
            `<b>${Locale.dateTime(datetime, "fullDate")}</b><br/>`,
            `${Locale.string("Count")}: ${total || ""}<br />`,
            `${Locale.string("Female")}: ${female || ""}<br />`,
            `${Locale.string("Male")}: ${male || ""}<br />`,
          ]
          return result.join("")
        }

        return (() => {
          const result = []
          for (var name in genderVisitSet.label) {
            var label = genderVisitSet.label[name]
            var chartData = []
            _.forEach(genderList, function (gender, idx) {
              const value = (() => {
                switch (gender[name]) {
                  case null:
                    return 0
                  case "-":
                    return 0
                  default:
                    return gender[name]
                }
              })()
              return chartData.push([idx, value])
            })

            result.push(
              vm.chartData.push({
                label,
                color: genderVisitSet.color[name],
                data: chartData,
              })
            )
          }
          return result
        })()
      })
    }
  )
  .controller(
    "StoreAgeVisitCtrl",
    function ($scope, $filter, $element, ApiSrv, ChartOptionSrv, Locale) {
      const vm = ($scope.vm = this)
      vm.chartOption = angular.copy(ChartOptionSrv.sevenDaysBarChart)
      const legendEle = angular.element("#legend-agevisit", $element)

      return $scope.$parent.$watch("ageList", function (ageList) {
        vm.chartData = []
        if (!ageList || $scope.useFaceCountOnly) {
          return
        }

        if (_.isEmpty(ageList)) {
          return legendEle.addClass("invisible")
        }

        if (legendEle.hasClass("invisible")) {
          legendEle.removeClass("invisible")
        }

        const ageVisitSet = angular.copy($scope.$parent.ageChartOption)

        vm.chartOption.xaxis.ticks = $scope.$parent.xaxisTicks
        vm.chartOption.series.bars.barWidth = 0.3
        vm.chartOption.series.stack = true
        vm.chartOption.legend = {
          container: legendEle,
          noColumns: 0,
        }

        vm.chartOption.tooltip.content = function (label, x, y, item) {
          let total
          const datetime = moment.utc($scope.sevenDaysAgoString, "YYYYMMDD0000").add(x, "days")
          const dataIdx = item.dataIndex
          if ($scope.isNumber(ageList[dataIdx].total)) {
            total = $filter("number")(ageList[dataIdx].total)
          } else {
            ;({ total } = ageList[dataIdx])
          }

          const result = [
            `<b>${Locale.dateTime(datetime, "fullDate")}</b><br />`,
            `Count: ${total || ""}<br />`,
          ]
          for (var name in ageVisitSet.label) {
            label = ageVisitSet.label[name]
            var value = ageList[dataIdx][name]
            var str = $scope.isNumber(value) ? $filter("number")(value) : value

            result.push(`${label}: `)
            result.push(`${str || ""}<br />`)
          }
          return result.join("")
        }

        return (() => {
          const result = []
          for (var name in ageVisitSet.label) {
            var label = ageVisitSet.label[name]
            var chartData = []
            _.forEach(ageList, function (age, idx) {
              const value = (() => {
                switch (age[name]) {
                  case null:
                    return 0
                  case "-":
                    return 0
                  default:
                    return age[name]
                }
              })()
              return chartData.push([idx, value])
            })
            result.push(
              vm.chartData.push({
                label,
                data: chartData,
                color: ageVisitSet.color[name],
              })
            )
          }
          return result
        })()
      })
    }
  )

  .controller(
    "StoreEntranceMonthlyCtrl",
    function ($scope, $element, $filter, ApiSrv, ChartOptionSrv, Locale) {
      const vm = ($scope.vm = this)
      vm.chartOption = angular.copy(ChartOptionSrv.daysLineChart)
      vm.chartOption.legend = {
        container: angular.element("#legend-monthly", $element),
        noColumns: 0,
      }
      vm.chartOption.xaxis.minTickSize = Math.ceil($scope.maxMonth / 6)

      const monthLength = 2
      const splitJobCount = Math.ceil($scope.maxMonth / monthLength)

      $scope.$on("SET_DATETIME", function () {
        if (!$scope.validTime) {
          return
        }
        vm.chartOption.xaxis.tickFormatter = function (val) {
          const datetime = moment.utc($scope.maxMonthAgoString, "YYYYMMDD0000").add(val, "months")

          return Locale.dateTime(datetime, "month")
        }

        vm.chartOption.tooltip.content = function (label, x, y) {
          const datetime = moment.utc($scope.maxMonthAgoString, "YYYYMMDD0000").add(x, "months")
          return `<b>${label}</b><br/> \
${Locale.string("Date")}: ${Locale.dateTime(datetime, "month")}<br/> \
${Locale.string("Count")}: ${$filter("number")(y)}`
        }

        $scope.showLoading("StoreEntranceMonthlyCtrl")

        return async.times(
          splitJobCount,
          function (n, callback) {
            const startMonth = moment
              .utc($scope.maxMonthAgoString, "YYYYMMDD0000")
              .add(n * monthLength, "months")
              .startOf("month")
            let endMonth = moment(startMonth).add(monthLength, "months").startOf("month")
            if (
              moment(endMonth).diff(
                moment.utc($scope.maxMonthAgoString, "YYYYMMDD0000").startOf("months"),
                "month"
              ) > $scope.maxMonth
            ) {
              endMonth = moment.utc($scope.tomorrowString, "YYYYMMDD0000").startOf("month")
            }

            const options = {
              from: startMonth.format("YYYYMMDD0000"),
              to: endMonth.format("YYYYMMDD0000"),
              sampling: "1M",
              entrance: true,
            }

            return ApiSrv.getCountOfStore(_.merge({}, $scope.options, options)).then((response) =>
              callback(
                null,
                !__guard__(response != null ? response.data : undefined, (x) => x.length)
                  ? null
                  : response
              )
            )
          },
          function (error, responses) {
            vm.chartData = []
            let validResponseCount = 0
            const chartData = {
              lines: {
                fill: true,
              },
              label: Locale.string(`last ${$scope.maxMonth} months`),
              data: __range__(0, $scope.maxMonth, false).map((i) => [i, 0]),
            }

            for (var res of Array.from(responses)) {
              if (!res) {
                continue
              }
              validResponseCount += 1
              res.data.forEach(
                (raw) =>
                  (chartData.data[
                    moment
                      .utc(raw.statisticFor)
                      .diff(
                        moment.utc($scope.maxMonthAgoString, "YYYYMMDD0000").startOf("months"),
                        "months"
                      )
                  ][1] = raw.count)
              )
            }

            if (validResponseCount) {
              vm.chartData.push(chartData)
            }
            return $scope.hideLoading("StoreEntranceMonthlyCtrl")
          }
        )
      })

      let startFlag = false

      return $scope.$on("localizeResourcesUpdated", function () {
        if (startFlag) {
          $scope.$broadcast("SET_DATETIME")
        }
        return (startFlag = true)
      })
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
