/** @jsx jsx */

import { useState, useMemo, useEffect } from "react"
import { connect } from "react-redux"
import { createAsync } from "../../ducks/lib/redux-async"
import { jsx } from "@emotion/react"
import SelectField from "../react/SelectField"
import StoreSelectField from "./StoreSelectField"
import { useFormApi } from "informed"
import propTypes from "prop-types"

import {
  selectors as SensorSelector,
  actions as SensorActions,
} from "../../ducks/modules/sensor/model"
const SensorSelectAsync = ({ onChange, field, isPending, err, data, ...rest }) => {
  const formApi = useFormApi()
  const [storeId, setStoreId] = useState()
  const handleStoreChange = (option) => {
    setStoreId(option.value)
  }

  useEffect(() => {
    if (!storeId) {
      const value = formApi.getValue(field)
      if (value) {
        const sensor = data.find((d) => d._id == value)
        if (sensor) {
          if (!storeId) setStoreId(sensor._storeId)
        }
      }
    }
  })

  const options = useMemo(
    () =>
      data
        .filter((sensor) => sensor._storeId == storeId)
        .map((sensor) => ({
          label: sensor.name,
          value: sensor._id,
          data: sensor,
        })),
    [data, storeId]
  )

  return (
    <div className="row">
      <div className="col-sm-6">
        <StoreSelectField value={storeId} onChange={handleStoreChange} />
      </div>
      <div className="col-sm-6">
        <SelectField
          {...rest}
          onChange={onChange}
          loading={isPending}
          field={field}
          options={options}
        />
      </div>
    </div>
  )
}
SensorSelectAsync.propTypes = {
  onChange: propTypes.func,
  field: propTypes.string,
  isPending: propTypes.bool.isRequired,
  err: propTypes.object,
  data: propTypes.array.isRequired,
}

export const SensorSelect = connect(
  (state, ownProps) => {
    const Model = SensorSelector.model(state)
    const data = Model.all(state).toModelArray()
    return {
      ...ownProps,
      isPending: SensorSelector.index.isPending(state)(),
      isFulfilled: SensorSelector.index.isFulfilled(state)(),
      isRejected: SensorSelector.index.isRejected(state)(),
      data,
    }
  },
  (dispatch, ownProps) => {
    return {
      ...ownProps,
      actionFn: () => {
        dispatch(SensorActions.index.request())
      },
    }
  }
)(
  createAsync({
    Pending: SensorSelectAsync,
    Fulfilled: SensorSelectAsync,
  })
)

export default SensorSelect
