/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS201: Simplify complex destructure assignments
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

import { kStandardLabels, getLabelName, getStandardLabel } from "../../../components/counter-label"
import Promise from "bluebird"
import env from "../../../../config/environment"
import BizSchedule from "common-library/BizSchedule"
import moment from "moment-timezone"

angular
  .module("uCountitUiApp")
  .controller(
    "StoreDetailCtrl",
    function (
      $rootScope,
      $scope,
      $stateParams,
      $location,
      $q,
      ApiSrv,
      usSpinnerService,
      Locale,
      SamplingSrv
    ) {
      const SAMPLING = {
        HOUR: "hour",
        DAY: "day",
        MONTH: "month",
      }
      $scope.currentPage = "footfall"
      $scope.validTime = false
      $scope.title = {
        entrance: Locale.string("Entrance"),
        labels: Locale.string("Store Labels"),
      }

      $scope.pageOptions = {
        storeId: $stateParams.id,
        sampling: SAMPLING.DAY,
        entrance: {
          with: [0],
        },
        label: {
          with: null,
        },
        content: "footfall",
      }

      $scope.specialLabels = kStandardLabels

      const loadingQueue = []
      const showLoading = function (key) {
        if (key) {
          loadingQueue.push(key)
        }
        angular.element(".splash").show()
        usSpinnerService.spin("spinner")
        return angular.element(".spinner").css("position", "fixed")
      }

      const hideLoading = function (key) {
        if (key) {
          _.remove(loadingQueue, (raw) => raw === key)
          if (loadingQueue.length) {
            return
          }
        }

        angular.element(".splash").hide()
        usSpinnerService.stop("spinner")
        $scope.$apply()
      }

      const samplingKey = function (val) {
        switch (val) {
          case "hour":
            return "1h"
          case "day":
            return "1d"
          case "week":
            return "1w"
          case "month":
            return "1M"
          default:
            return "1d"
        }
      }

      const getDefaultData = function (start, end, sampling) {
        const data = []

        /*
    if (sampling is 'week')
      samp = 'isoWeek'
    else
      samp = sampling
    */
        const samp = sampling

        const samplingStart = moment(start).startOf(samp)
        const samplingEnd = moment(end).startOf(samp)
        //const curDate = moment().startOf(samp)
        // const isActiveBH = __guard__(
        //   $scope.currentStore != null ? $scope.currentStore.schedule : undefined,
        //   (x) => x.businessHours.active
        // )

        let dataLength = samplingEnd.diff(samplingStart, sampling)
        if (sampling === "week" || sampling === "month") {
          dataLength++
        }

        for (
          let i = 0, end1 = dataLength, asc = 0 <= end1;
          asc ? i < end1 : i > end1;
          asc ? i++ : i--
        ) {
          if (i === 0 && samplingStart < start) {
            data.push([start, null])
          } else {
            var newDate = moment(start).startOf(samp).add(i, sampling)
            if (newDate < end) {
              data.push([newDate, null])
            }
          }
        }

        return {
          data: data,
          totalCount: 0,
          startDate: start,
          endDate: end,
          sampling: sampling,
        }
      }

      const getStoreCountData = function (chartData, options) {
        const deffered = $q.defer()
        const localStartDate = moment.utc(options.from, "YYYYMMDDHHmm")
        const localEndDate = moment.utc(options.to, "YYYYMMDDHHmm")
        const splitJobLength = 30 // 1 month = 30day
        const splitJobCount = Math.ceil(localEndDate.diff(localStartDate, "day") / splitJobLength)

        async.times(
          splitJobCount,
          function (n, callback) {
            const start = moment(localStartDate).add(n * splitJobLength, "days")
            let end = moment(start).add(splitJobLength, "days")
            if (end.isAfter(localEndDate)) {
              end = localEndDate
            }
            options.from = start.format("YYYYMMDDHHmm")
            options.to = end.format("YYYYMMDDHHmm")

            return ApiSrv.getCountOfStore(options).then((response) =>
              callback(
                null,
                !__guard__(response != null ? response.data : undefined, (x) => x.length)
                  ? null
                  : response
              )
            )
          },
          function (error, responses) {
            if (error) {
              return deffered.reject(error)
            }

            let validResponseCount = 0
            for (var res of Array.from(responses)) {
              if (!res) {
                continue
              }
              validResponseCount += 1
              _.forEach(res.data, function (raw) {
                let dataIdx
                if (chartData.length >= 1) {
                  dataIdx = _.findIndex(chartData, function (n) {
                    if (!n.key) {
                      return 0
                    }
                    return n.key === raw.labelId
                  })
                  if (dataIdx < 0) {
                    deffered.resolve(chartData)
                    return
                  }
                }
                const selectedData = chartData[dataIdx] != null ? chartData[dataIdx] : chartData
                const { sampling } = selectedData
                const idx = moment
                  .utc(raw.statisticFor)
                  .startOf(sampling)
                  .diff(moment(localStartDate).startOf(sampling), sampling)
                if (selectedData.data[idx]) {
                  if (sampling === "hour") {
                    selectedData.data[idx][1] = raw.count
                    if (selectedData.beforeDay !== moment.utc(raw.statisticFor).date()) {
                      selectedData.beforeCount = 0
                    }

                    selectedData.data[idx][1] -= selectedData.beforeCount
                    selectedData.beforeCount = raw.count
                    selectedData.beforeDay = moment.utc(raw.statisticFor).date()

                    if (selectedData.data[idx][1] < 0) {
                      selectedData.data[idx][1] = 0
                    }
                    selectedData.totalCount += selectedData.data[idx][1]
                  } else {
                    selectedData.data[idx][1] += raw.count
                    selectedData.totalCount += raw.count
                  }
                }
              })
            }
            if (!validResponseCount) {
              chartData.data = null
            }
            return deffered.resolve(chartData)
          }
        )

        return deffered.promise
      }

      const getCameraCountData = function (chartData, options) {
        const deffered = $q.defer()
        const localStartDate = moment.utc(options.from, "YYYYMMDDHHmm")
        const localEndDate = moment.utc(options.to, "YYYYMMDDHHmm")
        const splitJobLength = 30 // 1 month = 30day
        const splitJobCount = Math.ceil(localEndDate.diff(localStartDate, "day") / splitJobLength)

        async.times(
          splitJobCount,
          function (n, callback) {
            const start = moment(localStartDate).add(n * splitJobLength, "days")
            let end = moment(start).add(splitJobLength, "days")
            if (end.isAfter(localEndDate)) {
              end = localEndDate
            }
            options.from = start.format("YYYYMMDDHHmm")
            options.to = end.format("YYYYMMDDHHmm")

            return ApiSrv.getCountOfCamera(options).then((response) =>
              callback(
                null,
                !__guard__(response != null ? response.data : undefined, (x) => x.length)
                  ? null
                  : response
              )
            )
          },
          function (error, responses) {
            if (error) {
              return deffered.reject(error)
            }

            let validResponseCount = 0
            for (var res of Array.from(responses)) {
              if (!res) {
                continue
              }
              validResponseCount += 1
              _.forEach(res.data, function (raw) {
                let dataIdx
                if (chartData.length >= 1) {
                  dataIdx = _.findIndex(chartData, function (n) {
                    if (!n.key) {
                      return 0
                    }
                    return n.key === raw.counterId
                  })
                  if (dataIdx < 0) {
                    deffered.resolve(chartData)
                    return
                  }
                }
                const selectedData = chartData[dataIdx] != null ? chartData[dataIdx] : chartData
                const { sampling } = selectedData
                const idx = moment
                  .utc(raw.statisticFor)
                  .startOf(sampling)
                  .diff(moment(localStartDate).startOf(sampling), sampling)

                if (selectedData.data[idx]) {
                  if (sampling === "hour") {
                    selectedData.data[idx][1] = raw.count
                    if (selectedData.beforeDay !== moment.utc(raw.statisticFor).date()) {
                      selectedData.beforeCount = 0
                    }

                    selectedData.data[idx][1] -= selectedData.beforeCount
                    selectedData.beforeCount = raw.count
                    selectedData.beforeDay = moment.utc(raw.statisticFor).date()

                    if (selectedData.data[idx][1] < 0) {
                      selectedData.data[idx][1] = 0
                    }
                    selectedData.totalCount += selectedData.data[idx][1]
                  } else {
                    selectedData.data[idx][1] += raw.count
                    selectedData.totalCount += raw.count
                  }
                }
              })
            }
            if (!validResponseCount) {
              chartData.data = null
            }
            return deffered.resolve(chartData)
          }
        )

        return deffered.promise
      }

      const isValidPeriod = function (start, end, sampling) {
        const limitDate = SamplingSrv[sampling].getLimitDate()
        const diffNum = moment(end).diff(start, limitDate.key)

        if (diffNum > limitDate.value) {
          return false
        } else {
          return true
        }
      }

      $scope.showLoading = showLoading
      $scope.hideLoading = hideLoading
      $scope.samplingKey = samplingKey
      $scope.getDefaultData = getDefaultData
      $scope.getStoreCountData = getStoreCountData
      $scope.getCameraCountData = getCameraCountData
      $scope.isValidPeriod = isValidPeriod

      $scope.reload = function (fromInit) {
        if (fromInit == null) {
          fromInit = false
        }
        if (fromInit) {
          $scope.validTime = true
        }
        return $scope.$broadcast("reload_chart")
      }

      $scope.select = function (idx) {
        $scope.selectedTab = idx
        if ($scope.validTime) {
          $scope.reload()
        }
      }

      const init = () =>
        Promise.all([
          ApiSrv.getStore({ id: $scope.pageOptions.storeId }),
          ApiSrv.getStoreBizSchedule({ id: $scope.pageOptions.storeId }),
        ])
          .then(function (...args) {
            const [store, schedule] = Array.from(args[0])
            $scope.currentStore = store
            $scope.storeName = store.name
            if (!$scope.currentStore) {
              return $location.path("/store")
            }

            if (
              ($scope.currentStore.specialLabels != null
                ? $scope.currentStore.specialLabels.length
                : undefined) > 0
            ) {
              $scope.specialLabels = _.merge(
                {},
                $scope.specialLabels,
                $scope.currentStore.specialLabels
              )
            }
            $scope.specialLabelNameList = _.map($scope.specialLabels, "name")

            $scope.bs = new BizSchedule(schedule, schedule.timezone)
            $scope.isActiveBH =
              ($scope.currentStore.schedule != null
                ? $scope.currentStore.schedule.businessHours.active
                : undefined) != null
                ? $scope.currentStore.schedule != null
                  ? $scope.currentStore.schedule.businessHours.active
                  : undefined
                : false
            $scope.bh = {
              open: 0,
              close: 24,
            }

            if ($scope.isActiveBH) {
              $scope.bh = schedule.bizHours.find((bh) => bh.active)
              $scope.midnight = $scope.bh.overnight
            }

            return ApiSrv.getCompany({ id: store._companyId }).then(function (res) {
              $scope.companyName = res.name
              return $rootScope.$broadcast("companyStoreNameUpdated", {
                companyName: res.name,
                storeName: store.name,
              })
            })
          })
          .then(() =>
            $scope.$broadcast(
              "init-range-search",
              $scope.currentStore != null ? $scope.currentStore.timezone : undefined
            )
          )

      return ($scope.isInitComplete = init())
    }
  )
  .controller(
    "StoreEntrancCtrl",
    function ($scope, $element, Auth, ChartOptionSrv, GridOptionSrv, Locale, SamplingSrv) {
      const legendEle = angular.element("#entranceLegend", $element)

      const chartOption = _.merge(
        {
          yaxis: { min: 0 },
        },
        ChartOptionSrv.lineChartOption
      )
      const miniChartOption = ChartOptionSrv.miniLineChartOption

      const gridOption = _.merge(
        {
          enableColumnResizing: true,
          onRegisterApi(gridApi) {
            return (this.gridApi = gridApi)
          },
          getGridApi() {
            return this.gridApi
          },
        },
        GridOptionSrv.gridExportOption
      )

      gridOption.exporterPdfCustomFormatter = function (docDefinition) {
        docDefinition.content[0].table.widths = GridOptionSrv.getColumnWidths(
          gridOption.columnDefs.length
        )
        return docDefinition
      }
      let startFlag = false

      const setChart = function (dataList) {
        const dataSet = []
        _.forEach(dataList, function (idx, n) {
          if (!$scope.countList[idx]) {
            return
          }

          const data = angular.copy($scope.countList[idx])
          data["lines"] = { fill: n === 0 }
          data.highlightColor = ChartOptionSrv.chartColorList[n]
          dataSet.push(data)
        })

        // if datalist include inout or occupancy chart
        if (_.findIndex($scope.pageOptions.entrance.with, (n) => n >= 2) >= 0) {
          chartOption["yaxis"]["min"] = null
        }

        _.merge(chartOption, {
          legend: {
            container: angular.element("#entranceLegend"),
            noColumns: 0,
          },
        })

        if (!dataList.length || !dataSet.length) {
          legendEle.addClass("invisible")
        } else {
          if (legendEle.hasClass("invisible")) {
            legendEle.removeClass("invisible")
          }
        }

        $scope.chartOption = chartOption
        return ($scope.chartData = dataSet)
      }

      const selectChart = function (idx) {
        if (_.findIndex($scope.pageOptions.entrance.with, (n) => n === idx) >= 0) {
          _.remove($scope.pageOptions.entrance.with, (n) => n === idx)
        } else {
          $scope.pageOptions.entrance.with.push(idx)
        }

        return setChart($scope.pageOptions.entrance.with)
      }

      let options = {}

      const load = function () {
        let filename
        if ($scope.selectedTab !== 0) {
          return
        }
        const { sampling } = $scope.pageOptions
        const startDate = moment($scope.pageOptions.dt.startDate)
        const endDate = moment($scope.pageOptions.dt.endDate)

        const period = startDate.format("YYYYMMDD") + "-" + endDate.format("YYYYMMDD")
        gridOption.filename = filename = $scope.companyName + "_" + $scope.storeName + "_" + period
        gridOption.exporterCsvFilename = filename + ".csv"
        gridOption.exporterPdfFilename = filename + ".pdf"

        const newOptions = {
          id: $scope.pageOptions.storeId,
          from: startDate.format("YYYYMMDDHHmm"),
          to: endDate.add(1, "days").startOf("day").format("YYYYMMDDHHmm"),
          sampling: $scope.samplingKey(sampling),
        }

        if (_.isEqual(options, newOptions)) {
          return
        }
        if (!$scope.isValidPeriod(startDate, endDate, sampling)) {
          return
        }

        options = newOptions
        const entranceChartData = _.merge(
          {
            label: Locale.string("Entrance"),
          },
          $scope.getDefaultData(startDate, endDate, sampling)
        )

        const exitChartData = _.merge(
          {
            label: Locale.string("Exit"),
          },
          $scope.getDefaultData(startDate, endDate, sampling)
        )

        const inoutChartData = _.merge(
          {
            label: Locale.string("In/Out"),
          },
          $scope.getDefaultData(startDate, endDate, sampling)
        )

        const occupancyChartData = _.merge(
          {
            label: Locale.string("Occupancy"),
          },
          $scope.getDefaultData(startDate, endDate, sampling)
        )

        // dataSet[0] = entrance
        // dataSet[1] = exit
        // dataSet[2] = inoutChartData
        // dataSet[3] = occupancy
        const dataSet = []
        const gridSet = []
        const showGridSet = []
        if (startFlag) {
          $scope.showLoading("entrance")
        }

        async.series(
          [
            (callback) => {
              $scope
                .getStoreCountData(entranceChartData, _.merge({}, options, { entrance: true }))
                .then(
                  function (result) {
                    if (result.data != null) {
                      dataSet.push(result)
                    }
                    return callback(null, entranceChartData.data)
                  },
                  (err) => callback(err)
                )
            },

            (callback) =>
              $scope
                .getStoreCountData(
                  exitChartData,
                  _.merge({}, options, { exit: true, entrance: false })
                )
                .then(
                  function (result) {
                    if (result.data != null) {
                      dataSet.push(result)
                    }
                    return callback(null, exitChartData.data)
                  },
                  (err) => callback(err)
                ),

            (callback) => {
              if (dataSet.length > 1) {
                _.forEach(inoutChartData.data, function (row, idx) {
                  row[1] = dataSet[0].data[idx][1] - dataSet[1].data[idx][1]
                  return (inoutChartData.totalCount += row[1])
                })

                dataSet.push(inoutChartData)
              } else {
                inoutChartData.data = null
              }

              return callback(null, inoutChartData.data)
            },
            (callback) => {
              async.waterfall(
                [
                  (callback) => {
                    Auth.flushCurrentAccount().then((me) => callback(me))
                  },
                  (me, callback) => {
                    if (me.gteSpace()) {
                      if (inoutChartData.data != null) {
                        _.forEach(occupancyChartData.data, function (row, idx) {
                          row[1] = dataSet[2].data[idx][1]
                          if (idx) {
                            row[1] += occupancyChartData.data[idx - 1][1]

                            // to clear for each day
                            /*
                if dataSet[2].data[idx][0].isAfter(occupancyChartData.data[idx-1][0], 'day')
                  occupancyChartData.totalCount += occupancyChartData.data[idx-1][1]
                else
                  row[1] += occupancyChartData.data[idx-1][1]
              occupancyChartData.totalCount += _.last(occupancyChartData.data)[1]
                */
                          }
                        })

                        occupancyChartData.totalCount = _.last(occupancyChartData.data)[1]
                        dataSet.push(occupancyChartData)
                      } else {
                        occupancyChartData.data = null
                      }
                    }
                    return callback(null, occupancyChartData.data)
                  },
                ],
                (err, data) => {
                  callback(data)
                }
              )
            },
          ],
          function (err, results) {
            $scope.isGridData = false

            if (err) {
              $scope.hideLoading("entrance")
              return
            }

            if (!dataSet || !dataSet.length) {
              $scope.gridOptions.data = []
              $scope.countList = []
              setChart($scope.pageOptions.label.with)
              startFlag = true
              $scope.hideLoading("entrance")
              return
            }

            chartOption.xaxis.minTickSize = [1, "day"]
            delete chartOption.xaxis.tickFormatter

            const dataLength = _.first(dataSet).data.length

            if (dataLength > 70) {
              chartOption.lines.lineWidth = 1
              chartOption.points.show = false
            } else {
              chartOption.lines.lineWidth = 3
              chartOption.points.show = true
              if (sampling === "hour") {
                chartOption.xaxis.minTickSize = [1, sampling]
                chartOption.xaxis.tickFormatter = (val) => `${Locale.dateTime(val, "hour")}`
              }
            }

            const columnSet = []
            const timeFieldName =
              sampling === "hour" ? Locale.string("time from") : Locale.string("time")
            columnSet.push({
              field: "time",
              minWidth: 300,
              cellClass: "text-center",
              displayName: timeFieldName,
            })

            _.forEach(dataSet, (data) =>
              columnSet.push({
                name: data.label.toLowerCase(),
                field: data.label.toLowerCase(),
                displayName: data.label,
                minWidth: 100,
                cellClass: "text-right",
                cellFilter: "number: 0",
              })
            )

            const samplingFormat = SamplingSrv[sampling].getFormat()
            const isoFormat = SamplingSrv[sampling].getIsoDayFormat()
            gridOption.columnDefs = columnSet
            for (
              var i = 0, end1 = dataLength - 1, asc = 0 <= end1;
              asc ? i <= end1 : i >= end1;
              asc ? i++ : i--
            ) {
              var showTimerange, timerange
              var end
              var currentDate = moment(results[0][i][0])
              switch (sampling) {
                case "hour":
                case "day":
                  showTimerange = Locale.dateTime(currentDate, samplingFormat)
                  timerange = Locale.dateTime(currentDate, isoFormat)
                  break
                case "week":
                case "month":
                  if (i === dataLength - 1) {
                    end = moment(endDate).subtract(1, "days")
                  } else {
                    end = moment(results[0][i + 1][0]).subtract(1, "days")
                  }

                  showTimerange = `${Locale.dateTime(
                    currentDate,
                    samplingFormat
                  )} ~ ${Locale.dateTime(end, samplingFormat)}`
                  timerange = `${Locale.dateTime(currentDate, isoFormat)} ~ ${Locale.dateTime(
                    end,
                    isoFormat
                  )}`
                  break
              }

              var gridItem = { time: timerange }
              var showGridItem = { time: showTimerange }

              _.forEach(columnSet, function (column, idx) {
                if (idx > 0) {
                  // column[0] is 'time' column.
                  const o = {}
                  o[column.field] = results[idx - 1][i][1]
                  gridItem = _.merge(gridItem, o)
                  showGridItem = _.merge(showGridItem, o)
                }
              })

              gridSet.push(gridItem)
              showGridSet.push(showGridItem)
            }

            $scope.gridOptions.data = showGridSet
            $scope.gridOptions.exportData = gridSet
            if (gridSet.length > 0) {
              $scope.isGridData = true
            }

            gridOption.exporterPdfHeader = {
              text: "Store Name : " + $scope.currentStore.name + " (Entrance Data)",
              style: "headerStyle",
            }
            $scope.countList = dataSet
            setChart($scope.pageOptions.entrance.with)
            startFlag = true
            $scope.hideLoading("entrance")
          },

          (err) => $scope.hideLoading("entrance")
        )
      }

      $scope.chartOption = chartOption
      $scope.miniChartOption = miniChartOption
      $scope.selectChart = selectChart
      $scope.gridOptions = gridOption

      $scope.$parent.isInitComplete.then(function () {
        load()
        return $scope.$on("reload_chart", () => load())
      })

      return $scope.$on("companyStoreNameUpdated", function (_event, args) {
        let filename
        $scope.companyName = args.companyName
        $scope.storeName = args.storeName

        const startDate = moment($scope.pageOptions.dt.startDate)
        const endDate = moment($scope.pageOptions.dt.endDate)

        const period = startDate.format("YYYYMMDD") + "-" + endDate.format("YYYYMMDD")
        gridOption.filename = filename = $scope.companyName + "_" + $scope.storeName + "_" + period
        gridOption.exporterCsvFilename = filename + ".csv"
        return (gridOption.exporterPdfFilename = filename + ".pdf")
      })
    }
  )
  .controller(
    "StoreLabelCtrl",
    function (
      $scope,
      $location,
      ChartOptionSrv,
      GridOptionSrv,
      ApiSrv,
      Locale,
      SamplingSrv,
      GridExportSrv
    ) {
      const legendEle = angular.element("#labelLegend")
      $scope.countList = []
      $scope.showDrawRate = false
      $scope.disableDarwRate = true
      const chartOption = _.merge(
        {
          yaxis: { min: 0 },
        },
        ChartOptionSrv.lineChartOption
      )
      const miniChartOption = ChartOptionSrv.miniLineChartOption

      const gridOption = _.merge(
        {
          onRegisterApi(gridApi) {
            return (this.gridApi = gridApi)
          },
          getGridApi() {
            return this.gridApi
          },
        },
        GridOptionSrv.gridExportOption
      )

      const setChart = function (dataSet) {
        const dataList = []

        $scope.countList.forEach((data, idx) => {
          if (dataSet.has(data.key)) {
            const cData = angular.copy(data)
            cData.lines = { fill: false }
            cData.highlightColor = cData.color ? cData.color : ChartOptionSrv.chartColorList[idx]

            dataList.push(cData)
          }
        })

        _.merge(chartOption, {
          legend: {
            container: legendEle,
            noColumns: 0,
          },
        })

        if (!dataList.length) {
          legendEle.addClass("invisible")
        } else {
          dataList[0].lines.fill = true
          if (legendEle.hasClass("invisible")) {
            legendEle.removeClass("invisible")
          }
        }

        $scope.chartOption = chartOption
        $scope.chartData = dataList
      }

      const selectChart = function (countData) {
        if ($scope.pageOptions.label.with.has(countData.key)) {
          $scope.pageOptions.label.with.delete(countData.key)
        } else {
          $scope.pageOptions.label.with.add(countData.key)
        }

        if ($scope.pageOptions.label.with.size === 0) {
          $location.search("labelComparison", "none")
        } else if ($scope.countList.every((data) => $scope.pageOptions.label.with.has(data.key))) {
          $location.search("labelComparison", "all")
        } else {
          $location.search("labelComparison", [...$scope.pageOptions.label.with].join(","))
        }

        return setChart($scope.pageOptions.label.with)
      }

      const isEmptyRow = function (dataSet, idx) {
        let result = true
        _.forEach(dataSet, function (data) {
          if (!_.isNull(data.data[idx][1])) {
            result = false
          }
        })

        return result
      }

      let options = {}

      const showWeather = (sampling) =>
        __guard__(
          $scope.currentStore != null ? $scope.currentStore.weather : undefined,
          (x) => x.locationId
        ) != null &&
        sampling !== "month" &&
        !env.isLocalgrey

      const getWeatherData = function (from, to, sampling) {
        if (!showWeather(sampling)) {
          return Promise.resolve([])
        }

        const option = {
          locationId: $scope.currentStore.weather.locationId,
          from,
          to,
          language: Locale.getLanguage().split("-")[0],
        }
        if (sampling === "day") {
          option.sampling = sampling
        }

        return ApiSrv.getWeatherObservationSafeData(option)
      }

      const numberWithCommas = function (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }

      $scope.load = function (reload = false) {
        let closeHour, filename
        if ($scope.selectedTab !== 1) {
          return
        }
        const { sampling } = $scope.pageOptions
        const startDate = moment($scope.pageOptions.dt.startDate)
        const endDate = moment($scope.pageOptions.dt.endDate)

        const period = startDate.format("YYYYMMDD") + "_" + endDate.format("YYYYMMDD")
        gridOption.filename = filename = $scope.companyName + "_" + $scope.storeName + "_" + period
        gridOption.exporterCsvFilename = filename + ".csv"
        gridOption.exporterPdfFilename = filename + ".pdf"
        gridOption.appScopeProvider = {
          getLableCount: function (col) {
            if (_.isNil(col)) return
            return col.toString().split(" ")[0]
          },
          getDrawRate: function (col) {
            if (_.isNil(col)) return
            return col.toString().split(" ")[1]
          },
        }

        const newOptions = {
          id: $scope.pageOptions.storeId,
          from: startDate.format("YYYYMMDDHHmm"),
          to: endDate.add(1, "days").startOf("day").format("YYYYMMDDHHmm"),
          sampling: $scope.samplingKey(sampling),
        }

        if ($scope.midnight && sampling === "hour") {
          closeHour = Math.ceil($scope.bh.close / 60)
          newOptions.to = endDate.add(closeHour, "hour").format("YYYYMMDDHHmm")
        }

        if (
          !reload &&
          (_.isEqual(options, newOptions) || !$scope.isValidPeriod(startDate, endDate, sampling))
        ) {
          return
        }

        options = newOptions

        const standardLabelsChartData = kStandardLabels
          .filter((label) => label.key != "nolabel")
          .map((label) =>
            _.merge(
              {
                _id: label._id,
                key: label.name,
                label: Locale.string(label.i18nKey),
                color: `rgb(${label.color})`,
                qs: label.key,
              },
              $scope.getDefaultData(startDate, endDate, sampling)
            )
          )

        const dataSet = []
        const gridSet = []
        const showGridSet = []
        const timeFieldName =
          sampling === "hour" ? Locale.string("time from") : Locale.string("time")
        const columnSet = [
          {
            name: "time",
            field: "time",
            minWidth: 150,
            cellClass: "text-center",
            displayName: timeFieldName,
          },
        ]

        $scope.showLoading("label")
        async.waterfall(
          [
            (callback) =>
              Promise.map(standardLabelsChartData, (chartData) =>
                $scope.getStoreCountData(chartData, _.merge({}, options, { [chartData.qs]: true }))
              )
                .then((results) => {
                  for (const result of results) {
                    if (result.data != null) {
                      dataSet.push(result)
                    }
                  }
                  callback()
                })
                .catch((err) => callback(err)),

            (callback) =>
              ApiSrv.getStore({ id: $scope.pageOptions.storeId }).then((store) =>
                callback(
                  null,
                  _.filter(store.labels, (label) => label.active === true)
                )
              ),

            function (labels, callback) {
              const kMinWidth = 100
              _.forEach(dataSet, function (data) {
                return columnSet.push({
                  name: data.label,
                  field: data.key,
                  displayName: data.label,
                  minWidth: kMinWidth,
                  cellClass: "text-right",
                  cellFilter: "number : 0",
                  exporterPdfAlign: "right",
                })
              })

              _.forEach(labels, function (label) {
                dataSet.push(
                  _.merge(
                    {
                      _id: label._id,
                      key: label._id,
                      label: label.name,
                      color: `rgb(${label.color})`,
                    },
                    $scope.getDefaultData(startDate, endDate, sampling)
                  )
                )

                // for column definition
                const colLength = columnSet.push({
                  name: label._id,
                  field: label._id,
                  displayName: label.name,
                  minWidth: kMinWidth,
                  cellClass: "text-right",
                  cellFilter: "number : 0",
                  exporterPdfAlign: "right",
                })

                if ($scope.showDrawRate) {
                  let labelCol = columnSet[colLength - 1]
                  delete labelCol.cellFilter
                  labelCol.cellTemplate =
                    '<div class="ui-grid-cell-contents"><span class="divide-two">{{grid.appScope.getLableCount(COL_FIELD)}}</span><span class="divide-two">{{grid.appScope.getDrawRate(COL_FIELD)}}</span></div>'

                  columnSet.push({
                    name: `${label._id}_draw_rate`,
                    field: `${label._id}_draw_rate`,
                    displayName: Locale.string("Entrance Draw Rate"),
                    minWidth: kMinWidth,
                    cellClass: "text-right",
                    exporterPdfAlign: "right",
                    visible: false,
                  })
                }
                return
              })

              const selectedLabels = $location.search().labelComparison

              if (selectedLabels && typeof selectedLabels === "string") {
                if (selectedLabels === "none") {
                  $scope.pageOptions.label.with = new Set()
                } else if (selectedLabels === "all") {
                  $scope.pageOptions.label.with = new Set(dataSet.map((data) => data.key))
                } else {
                  $scope.pageOptions.label.with = new Set(selectedLabels.split(","))
                }
              } else {
                $scope.pageOptions.label.with = new Set(dataSet.map((data) => data.key))
              }

              if (showWeather(sampling)) {
                columnSet.push({
                  name: "weather",
                  field: "weather",
                  minWidth: kMinWidth,
                  cellClass: "text-center",
                  displayName: Locale.string("Weather"),
                })
                columnSet.push({
                  name: "rain",
                  field: "rain",
                  minWidth: kMinWidth,
                  cellClass: "text-right",
                  displayName: Locale.string("Precipitation") + "(mm)",
                })
                columnSet.push({
                  name: "minTemp",
                  field: "minTemp",
                  minWidth: kMinWidth,
                  cellClass: "text-right",
                  displayName: Locale.string("Min Temperature") + "(℃)",
                })
                columnSet.push({
                  name: "maxTemp",
                  field: "maxTemp",
                  minWidth: kMinWidth,
                  cellClass: "text-right",
                  displayName: Locale.string("Max Temperature") + "(℃)",
                })
                columnSet.push({
                  name: "pm25",
                  field: "pm25",
                  minWidth: kMinWidth,
                  cellClass: "text-right",
                  displayName: Locale.string("PM2.5") + "(㎍/m³)",
                })
                columnSet.push({
                  name: "pm10",
                  field: "pm10",
                  minWidth: kMinWidth,
                  cellClass: "text-right",
                  displayName: Locale.string("PM10") + "(㎍/m³)",
                })
              }
              if ($scope.currentStore.customCode) {
                columnSet.push({
                  name: "customCode",
                  field: "customCode",
                  displayName: "CustomCode",
                })
              }

              gridOption.columnDefs = columnSet
              return callback(null, dataSet)
            },
          ],
          function (_err, dataSet) {
            $scope.isGridData = false
            const entranceData = _.find(dataSet, { key: "Entrance" })
            if (entranceData) {
              $scope.disableDarwRate = false
            } else {
              $scope.disableDarwRate = true
              $scope.showDrawRate = false
            }

            const _resetUIData = function () {
              $scope.gridOptions.data = []
              $scope.countList = []
              setChart($scope.pageOptions.label.with)
              return $scope.hideLoading("label")
            }

            if (!dataSet.length) {
              _resetUIData()
              return
            }

            Promise.all([
              $scope.getStoreCountData(dataSet, _.merge({}, options, { item: "label" })),
              getWeatherData(options.from, options.to, sampling).catch((_err) => ({})),
            ]).then(
              function (...args) {
                const [response, weather] = Array.from(args[0])
                if (response == null) {
                  _resetUIData()
                  return
                }

                _.forEach(response, function (responseData) {
                  if ($scope.midnight && sampling === "hour") {
                    closeHour = Math.ceil($scope.bh.close / 60)
                    ;(() => {
                      const result = []
                      for (
                        let start = responseData.data.length - 1, i = start, asc = start <= 0;
                        asc ? i <= 0 : i >= 0;
                        asc ? i++ : i--
                      ) {
                        var data = responseData.data[i]
                        if (startDate.isSame(data[0], "day") && data[0].hour() < closeHour) {
                          responseData.totalCount -= data[1]
                          result.push(responseData.data.splice(i, 1))
                        } else {
                          result.push(undefined)
                        }
                      }
                      return result
                    })()
                  }
                })

                const dataLength = _.first(dataSet).data.length
                if (dataLength > 70) {
                  chartOption.lines.lineWidth = 1
                  chartOption.points.show = false
                } else {
                  chartOption.lines.lineWidth = 3
                  chartOption.points.show = true
                }

                const samplingTime = sampling === "hour" && dataLength > 70 ? "day" : sampling

                chartOption.xaxis.minTickSize = [1, samplingTime]
                chartOption.xaxis.tickFormatter = function (val) {
                  const dbtime = moment.utc(val)
                  if (samplingTime === "day") {
                    dbtime.startOf("day")
                  }
                  let label = `${Locale.dateTime(dbtime, samplingTime)}`

                  if (weather.hasData) {
                    //showWeather(samplingTime)
                    const w = weather[samplingTime][dbtime.format()]
                    if (w != null) {
                      const min =
                        (w.temperature != null ? w.temperature.min : undefined) != null
                          ? `${w.temperature.min}°`
                          : "-"
                      const max =
                        (w.temperature != null ? w.temperature.max : undefined) != null
                          ? `${w.temperature.max}°`
                          : "-"
                      label += `\n${w.weatherSymbol}` + ` ${min}/${max}`
                    }
                  }
                  return label
                }

                if (sampling === "hour" && $scope.isActiveBH) {
                  chartOption.grid.markings = ChartOptionSrv.createCustomMarkingsFunc($scope.bs)
                } else {
                  chartOption.grid.markings = []
                }

                $scope.countList = response
                setChart($scope.pageOptions.label.with)

                const samplingFormat = SamplingSrv[sampling].getFormat()
                const isoFormat = SamplingSrv[sampling].getIsoDayFormat()

                // make grid data
                for (
                  var i = 0, end1 = dataLength - 1, asc = 0 <= end1;
                  asc ? i <= end1 : i >= end1;
                  asc ? i++ : i--
                ) {
                  var showTimerange, timerange
                  var end
                  if (isEmptyRow(dataSet, i)) {
                    continue
                  }

                  var currentDate = moment(_.first(dataSet).data[i][0])
                  switch (sampling) {
                    case "hour":
                      if ($scope.isActiveBH && !$scope.bs.isOpen(currentDate, true)) {
                        continue
                      }

                      showTimerange = Locale.dateTime(currentDate, samplingFormat)
                      timerange = Locale.dateTime(currentDate, isoFormat)
                      break
                    case "day":
                      showTimerange = Locale.dateTime(currentDate, samplingFormat)
                      timerange = Locale.dateTime(currentDate, isoFormat)
                      break
                    case "week":
                    case "month":
                      if (i === dataLength - 1) {
                        end = moment(endDate).subtract(1, "days")
                      } else {
                        end = moment(_.first(dataSet).data[i + 1][0]).subtract(1, "days")
                      }
                      showTimerange = `${Locale.dateTime(
                        currentDate,
                        samplingFormat
                      )} ~ ${Locale.dateTime(end, samplingFormat)}`
                      timerange = `${Locale.dateTime(currentDate, isoFormat)} ~ ${Locale.dateTime(
                        end,
                        isoFormat
                      )}`
                      break
                  }

                  var gridItem = { time: timerange }
                  var showGridItem = { time: showTimerange }

                  _.forEach(dataSet, function (data) {
                    gridItem[data.key] = data.data[i][1] != null ? data.data[i][1] : ""
                    showGridItem[data.key] = data.data[i][1]
                    if ($scope.showDrawRate && !getStandardLabel(data._id)) {
                      const entrance = entranceData ? entranceData.data[i][1] : undefined
                      const drawRate =
                        !entrance || _.isNil(data.data[i][1])
                          ? ""
                          : _.round((data.data[i][1] / entrance) * 100) + "%"

                      gridItem[`${data.key}_draw_rate`] = drawRate

                      if (drawRate) {
                        showGridItem[data.key] =
                          numberWithCommas(showGridItem[data.key]) + " " + drawRate
                      }
                    }

                    if (weather.hasData) {
                      //showWeather(sampling)
                      const w = weather[sampling][data.data[i][0].format()]
                      showGridItem["weather"] = gridItem["weather"] = w?.weatherText ?? ""
                      showGridItem["minTemp"] = gridItem["minTemp"] = w?.temperature.min ?? ""
                      showGridItem["maxTemp"] = gridItem["maxTemp"] = w?.temperature.max ?? ""
                      showGridItem["rain"] = gridItem["rain"] = w?.rain ?? ""
                      if (w?.pm) {
                        showGridItem["pm25"] = gridItem["pm25"] = w?.pm[25]?.value ?? ""
                        showGridItem["pm10"] = gridItem["pm10"] = w?.pm[10]?.value ?? ""
                      } else {
                        showGridItem["pm25"] = gridItem["pm25"] = ""
                        showGridItem["pm10"] = gridItem["pm10"] = ""
                      }
                    }
                    if ($scope.currentStore.customCode) {
                      gridItem["customCode"] = $scope.currentStore.customCode || ""
                    }
                  })

                  gridSet.push(gridItem)
                  showGridSet.push(showGridItem)
                }

                $scope.gridOptions.data = showGridSet
                $scope.gridOptions.exportData = gridSet
                if (gridSet.length > 0) {
                  $scope.isGridData = true
                }

                gridOption.exporterPdfHeader = {
                  text: "Store Name : " + $scope.currentStore.name + " (Labels Data)",
                  style: "headerStyle",
                }

                gridOption.exporterPdfCustomFormatter = function (docDefinition) {
                  docDefinition.content[0].table.widths = GridOptionSrv.getColumnWidths(
                    gridOption.columnDefs.length
                  )
                  return docDefinition
                }

                $scope.hideLoading("label")
              },

              (_err) => $scope.hideLoading("label")
            )
          }
        )
      }

      $scope.chartOption = chartOption
      $scope.miniChartOption = miniChartOption
      $scope.selectChart = selectChart
      $scope.gridOptions = gridOption

      $scope.$parent.isInitComplete.then(function () {
        $scope.load()
        return $scope.$on("reload_chart", () => $scope.load())
      })

      $scope.getMiniChartStyle = function (countData) {
        if ($scope.pageOptions.label.with.has(countData.key)) {
          if ($scope.specialLabelNameList.indexOf(countData.key) >= 0) {
            return "select-chart-on-standard"
          } else {
            return "select-chart-on"
          }
        } else {
          return ""
        }
      }

      $scope.getMiniChartLabel = function (countData) {
        if ($scope.specialLabelNameList.indexOf(countData.key) >= 0) {
          return "mini-chart-label-standard"
        } else {
          return "mini-chart-label"
        }
      }

      const getDisplayLabelName = function (label) {
        const sIndex = $scope.specialLabelNameList.indexOf(label)
        if (sIndex >= 0) {
          return Locale.string($scope.specialLabels[sIndex].i18nKey)
        } else {
          return label
        }
      }

      const sortByColumn = function (columns) {
        const standardLabelsStr = kStandardLabels.map((label) => Locale.string(label.i18nKey))

        columns = _.sortBy(columns, ["sensorName"])
        const header = _.filter(columns, { displayName: "-" })

        const standardLabelsData = standardLabelsStr.map((str) =>
          _.filter(columns, { displayName: str })
        )
        const customLabels = _.filter(
          columns,
          (column) => !standardLabelsStr.includes(column.displayName)
        )

        const _findLabelIndex = (id) => _.findIndex($scope.currentStore.labels, ["_id", id])
        customLabels.sort((a, b) => _findLabelIndex(a.labelId) - _findLabelIndex(b.labelId))

        return header.concat(standardLabelsData.flat(), customLabels)
      }

      $scope.exportAllCameras = function (type) {
        let closeHour
        const { sampling } = $scope.pageOptions
        const startDate = moment($scope.pageOptions.dt.startDate)
        const endDate = moment($scope.pageOptions.dt.endDate)

        options = {
          from: startDate.format("YYYYMMDDHHmm"),
          to: endDate.add(1, "days").startOf("day").format("YYYYMMDDHHmm"),
          sampling: $scope.samplingKey(sampling),
        }

        if ($scope.midnight && sampling === "hour") {
          closeHour = Math.ceil($scope.bh.close / 60)
          options.to = endDate.add(closeHour, "hour").format("YYYYMMDDHHmm")
        }

        const exportOption = _.clone(GridOptionSrv.gridExportOption)
        const period = `${startDate.format("YYYYMMDD")}_${endDate.format("YYYYMMDD")}`
        exportOption.filename = `${$scope.companyName}_${$scope.storeName}_allcameras_${period}`

        const dataSet = []
        const gridSet = []
        const columnSet = [
          { name: "time", field: "time", displayName: "DateTime" },
          { name: "sampling", field: "sampling", displayName: "SamplingTime" },
        ]
        let columnSetForLabel = [
          { name: "time", field: "time", displayName: "-" },
          { name: "sampling", field: "sampling", displayName: "-" },
        ]

        return ApiSrv.getCameraOfStore({ id: $scope.pageOptions.storeId }).then(function (cameras) {
          cameras = _.sortBy(cameras, ["name"])
          cameras = cameras.filter((camera) =>
            __guard__(camera.functions != null ? camera.functions.use : undefined, (x) => x.counter)
          )

          return async.eachLimit(
            cameras,
            4,
            (camera, next) =>
              ApiSrv.getFootfallsConfigOfCamera(camera._id, $scope.pageOptions.storeId, {
                from: startDate.format("YYYYMMDDHHmm"),
                to: endDate.format("YYYYMMDDHHmm"),
              }).then(function (footfalls) {
                let countDataSet = []
                let counters = []
                for (var footfall of Array.from(footfalls)) {
                  var result = Array.from(footfall.counters).map((counter) =>
                    _.merge(
                      {
                        key: counter._id,
                        name: camera.name,
                        label: getLabelName(counter),
                      },
                      $scope.getDefaultData(startDate, endDate, sampling)
                    )
                  )
                  countDataSet = countDataSet.concat(result)
                  counters = counters.concat(footfall.counters)
                }

                const newOptions = _.merge(options, { id: camera._id })

                return $scope
                  .getCameraCountData(countDataSet, _.merge({}, newOptions, { item: "counter" }))
                  .then(function (response) {
                    _.forEach(response, function (responseData) {
                      const counterIdx = _.findIndex(counters, (n) => n._id === responseData.key)

                      if (counterIdx < 0 || !counters[counterIdx].active) {
                        return
                      }

                      if ($scope.midnight && sampling === "hour") {
                        closeHour = Math.ceil($scope.bh.close / 60)
                        for (
                          let start = responseData.data.length - 1, i = start, asc = start <= 0;
                          asc ? i <= 0 : i >= 0;
                          asc ? i++ : i--
                        ) {
                          var data = responseData.data[i]
                          if (startDate.isSame(data[0], "day") && data[0].hour() < closeHour) {
                            responseData.totalCount -= data[1]
                            responseData.data.splice(i, 1)
                          }
                        }
                      }

                      dataSet.push(responseData)

                      columnSet.push({
                        name: counters[counterIdx]._id,
                        field: counters[counterIdx]._id,
                        displayName: camera.name,
                      })

                      columnSetForLabel.push({
                        name: counters[counterIdx]._id,
                        field: counters[counterIdx]._id,
                        displayName: getDisplayLabelName(responseData.label),
                        labelId: counters[counterIdx]._labelId,
                        sensorName: camera.name,
                      })
                    })

                    return next()
                  })
              }),
            function (_err) {
              const dataLength = _.first(dataSet).data.length
              const isoFormat = SamplingSrv[sampling].getIsoDayFormat()

              for (
                var i = 0, end1 = dataLength - 1, asc = 0 <= end1;
                asc ? i <= end1 : i >= end1;
                asc ? i++ : i--
              ) {
                var timerange
                var end
                var currentDate = moment(_.first(dataSet).data[i][0])
                switch (sampling) {
                  case "hour":
                    if ($scope.isActiveBH && !$scope.bs.isOpen(currentDate, true)) {
                      continue
                    }

                    timerange = Locale.dateTime(currentDate, isoFormat)
                    break
                  case "day":
                    timerange = Locale.dateTime(currentDate, isoFormat)
                    break
                  case "week":
                  case "month":
                    if (i === dataLength - 1) {
                      end = moment(endDate).subtract(1, "days")
                    } else {
                      end = moment(_.first(dataSet).data[i + 1][0]).subtract(1, "days")
                    }
                    timerange = `${Locale.dateTime(currentDate, isoFormat)} ~ ${Locale.dateTime(
                      end,
                      isoFormat
                    )}`
                    break
                }

                var gridItem = {
                  time: timerange,
                  sampling: SamplingSrv[sampling].getRequestOption(),
                }
                if ($scope.currentStore.customCode) {
                  gridItem.customCode = $scope.currentStore.customCode || ""
                }

                _.forEach(dataSet, (data) => (gridItem[data.key] = data.data[i][1]))

                gridSet.push(gridItem)
              }

              columnSetForLabel = sortByColumn(columnSetForLabel)
              if ($scope.currentStore.customCode) {
                columnSet.push({
                  name: "customCode",
                  field: "customCode",
                  displayName: "CustomCode",
                })
                columnSetForLabel.push({
                  name: "customCode",
                  field: "customCode",
                  displayName: "-",
                })
              }
              exportOption.columnDefs = columnSet
              exportOption.columnDefsForLabel = columnSetForLabel
              exportOption.exportData = gridSet
              return GridExportSrv.gridExport(exportOption, type)
            }
          )
        })
      }

      return ($scope.selectAllCountBy = function (selection) {
        if (selection) {
          $scope.pageOptions.label.with = new Set($scope.countList.map((data) => data.key))
          $location.search("labelComparison", "all")
        } else {
          $scope.pageOptions.label.with = new Set()
          $location.search("labelComparison", "none")
        }

        return setChart($scope.pageOptions.label.with)
      })
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
