/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

import Promise from "bluebird"
import moment from "moment-timezone"

angular
  .module("uCountitUiApp")

  .controller(
    "heatmaptagConfigCtrl",
    function (
      $scope,
      $element,
      $window,
      $compile,
      Heatmaptag,
      Locale,
      usSpinnerService,
      ngDialog,
      hotkeys,
      DrawZone,
      CameraAdminSrv,
      CameraConfigSrv,
      Auth,
      DateTimeSrv,
      ApiSrv
    ) {
      let createHeatmapPanel
      const MAX_TAG = 6

      const dbColorPattern = /\d+,\d+,\d+/
      $scope.rgbPattern = /rgb\(\d+,\d+,\d+\)/
      $scope.drawTags = []
      let savedDrawTags = []
      $scope.heatmaptag = null
      $scope.trackingzone = null
      $scope.snapshotUrl = null
      const addButtonStr = Locale.string("Add")
      const drawingButtonStr = Locale.string("Drawing")
      $scope.drawingTag = null
      $scope.tagButtonStr = addButtonStr
      const errorImgeUrl = "/assets/images/no-image.svg"
      let orgLinkedZT2TZ = false
      let trackingZoneTags = []
      let snapshotRatio = 9 / 16
      let selectedPeriodIdx = -1
      let orgPeriods = []
      let orgSavedDrawTags = []
      let removedTags = []
      $scope.isUpdating = false

      Auth.fetchCurrentAccount().then((auth) => ($scope.auth = auth))

      const formHeatmapTagConfig = angular.element(
        document.getElementsByClassName("zonetraffic-container main-view")
      )
      $scope.selectedItem = $scope.selectedItem != null ? $scope.selectedItem : {}

      angular.element($window).on("resize", function () {
        if ($scope.isOpen === true) {
          return loadImage()
        }
      })

      $scope.shortcuts = [
        {
          combo: "d",
          description: "Delete selected point of tag",
          callback() {
            return $scope.$broadcast("heatmaptag_delete_editpoint")
          },
        },
      ]

      $scope.strs = {
        linkedZT2TZ: Locale.string("Linked Tracking Area"),
        unLinkedZT2TZ: Locale.string("Unlinked Tracking Area"),
        add: Locale.string("Add"),
        edit: Locale.string("Edit"),
        save: Locale.string("Save"),
        cancel: Locale.string("Cancel"),
        clear: Locale.string("Clear"),
      }

      const showLoading = function () {
        angular.element("#heatmaptagSplash").show()
        return usSpinnerService.spin("heatmaptagSpinner")
      }

      const hideLoading = function () {
        angular.element("#heatmaptagSplash").hide()
        usSpinnerService.stop("heatmaptagSpinner")
        return $scope.$apply()
      }

      $scope.addTag = function () {
        if ($scope.editOptions) {
          $scope.editOptions.mode = "cancel"
        }

        if (($scope.drawTags != null ? $scope.drawTags.length : undefined) >= MAX_TAG) {
          return $window.alert(Locale.string("msg_heatmaptag_exceed"))
        }

        return ($scope.drawingTag = $scope.heatmaptag.getDefaultDrawTag($scope.drawTags.length))
      }

      $scope.cancelDrawing = function () {
        $scope.drawingTag = null
        return $scope.$broadcast("heatmaptag_init_drawing")
      }

      $scope.modifyTag = function (mode, index) {
        if (mode === "clear") {
          $scope.editOptions = null
          const newTag = $scope.heatmaptag.getDefaultDrawTag(index)
          return _.assign($scope.drawTags[index], newTag)
        } else if (mode === "add" && index === 0) {
          const hexagonTag = $scope.heatmaptag.transformDrawTags([
            $scope.heatmaptag.getHexagonTag(),
          ])[0]
          return ($scope.editOptions = {
            mode,
            index,
            tag: hexagonTag,
          })
        } else {
          return ($scope.editOptions = {
            mode,
            index,
          })
        }
      }

      $scope.deleteTag = (index) =>
        ngDialog
          .openConfirm({
            template: "components/popup/popup-confirm.html",
            data: {
              header: $scope.drawTags[index].name,
              title: Locale.string("msg_confirm_remove_tag"),
            },
            closeByEscape: true,
            closeByDocument: true,
            showClose: false,
            className: "ngdialog-theme-default popup-confirm",
            controller: "ucPopupConfirmCtrl",
          })
          .then((options) => _.pullAt($scope.drawTags, index))

      $scope.isEditingTag = (index) => $scope.editOptions && $scope.editOptions.index === index

      $scope.isEmptyPoints = (index) => _.isEmpty($scope.drawTags[index].points)

      $scope.isClearTag = (index) =>
        $scope.isEmptyPoints(index) && _.isEmpty($scope.drawTags[index].name)

      $scope.isNotEditTag = (index) => $scope.isEditingTag(index) || $scope.isClearTag(index)

      $scope.isShowBtn = function (type, index) {
        switch (type) {
          case "add":
            return $scope.isEmptyPoints(index) && !$scope.isEditingTag(index)
          case "edit":
            return !$scope.isEmptyPoints(index) && !$scope.isEditingTag(index)
          case "save":
            return $scope.isEditingTag(index)
          case "cancel":
            return $scope.isEditingTag(index)
          case "clear":
            return !$scope.isEmptyPoints(index) && !$scope.isEditingTag(index)
        }
      }

      $scope.setColor = function (tag) {
        tag.color = dbColorPattern.exec(tag.rgbcolor)
        if (tag.color) {
          return (tag.color = tag.color[0])
        }
      }

      $scope.toggleLinkedZT2TZ = () =>
        ($scope.selectedItem.linkedZT2TZ = !$scope.selectedItem.linkedZT2TZ)

      const isChangedHeatmapTags = function () {
        const pick = [
          "active",
          "color",
          "minRoiTime",
          "name",
          "points",
          "left",
          "top",
          "hideInImage",
        ]

        for (let idx = 0; idx < orgSavedDrawTags.length; idx++) {
          var object, other
          var tag = orgSavedDrawTags[idx]
          if (!tag) {
            continue
          }
          if (!$scope.hmtags[idx]) {
            return true
          }

          if (idx === selectedPeriodIdx) {
            object = $scope.drawTags.map((t) => _.pick(t, pick))
            other = tag.map((t) => _.pick(t, pick))

            if (!angular.equals(object, other)) {
              return true
            }
          } else {
            var hmtag = _makeDrawTags($scope.hmtags[idx].heatmaptags)
            object = hmtag.map((t) => _.pick(t, pick))
            other = tag.map((t) => _.pick(t, pick))

            if (!angular.equals(object, other)) {
              return true
            }
          }
        }

        return false
      }

      const isSettingChanged = function () {
        if (!_.isEmpty($scope.editOptions)) {
          return false
        }

        return (
          isChangedHeatmapTags() ||
          orgLinkedZT2TZ !== $scope.selectedItem.linkedZT2TZ ||
          !angular.equals($scope.periods, orgPeriods)
        )
      }

      $scope.canSubmitHeatmapTag = function () {
        if (!$scope.isOpen) {
          return
        }
        return $scope.formHeatmapTagConfig.$valid && isSettingChanged()
      }

      const getTrackingZone = () =>
        CameraConfigSrv.get({
          id: $scope.selectedItem._id,
          item: "trackAnalysis", // server/api/camera/trackAnalysis -> trackdevice
          _: Date.now(),
        })
          .$promise.then((res) =>
            (res != null ? res.trackingZone.zones : undefined) != null
              ? res != null
                ? res.trackingZone.zones
                : undefined
              : []
          )
          .catch(
            (
              err //INFO to fix no tags bug #deffered.reject err
            ) => []
          )

      // TODO: add periodic tracking zone
      const setTrackingZone = function () {
        if (!$scope.selectedItem.linkedZT2TZ) {
          return Promise.resolve()
        }

        if (trackingZoneTags.length === 0) {
          trackingZoneTags.push($scope.trackingzone.getDefaultDrawZone(0))
        }

        const heatmaptags = $scope.heatmaptag.transformTags($scope.drawTags)

        Object.assign(trackingZoneTags[0], {
          points: heatmaptags[0].points,
          color: heatmaptags[0].color,
          active: heatmaptags[0].active,
        })

        return CameraConfigSrv.set(
          {
            id: $scope.selectedItem._id,
            item: "trackAnalysis",
          },
          { trackingZone: { zones: trackingZoneTags } }
        )
          .$promise.then((res) => res)
          .catch((err) => err)
      }

      $scope.isTagLinked = (idx, name) => {
        if (selectedPeriodIdx === 0) return true
        return $scope.hmtags[selectedPeriodIdx - 1].heatmaptags[idx].name === name
      }

      $scope.submitForm = function () {
        $scope.isUpdating = true
        $scope.errorMessage = ""
        const heatmaptags = $scope.hmtags.map((hmtag, idx) => {
          return {
            ...hmtag,
            from: moment.utc(hmtag.from),
            to: moment.utc(hmtag.to),
            heatmaptags:
              idx === selectedPeriodIdx
                ? $scope.heatmaptag.transformTags($scope.drawTags)
                : hmtag.heatmaptags,
          }
        })

        return Promise.all([
          CameraAdminSrv.update({ id: $scope.selectedItem._id }, $scope.selectedItem),
          setTrackingZone(),
          $scope.heatmaptag.setTags(heatmaptags),
        ])
          .then(() => $scope.$emit("heatmaptag_config_complete"))
          .catch(
            (error) =>
              ($scope.errorMessage =
                error === "duplicated name"
                  ? Locale.string("msg_error_heatmaptag_name_duplicated")
                  : Locale.string("msg_error_update"))
          )
          .finally(() => ($scope.isUpdating = false))
      }

      $scope.isShowEffectiveArea = function () {
        if (snapshotRatio === 3 / 4 || snapshotRatio === 1) {
          return true
        } else {
          return false
        }
      }

      $scope.$watch("drawingTag", function (tag) {
        if (tag) {
          return ($scope.tagButtonStr = drawingButtonStr)
        } else {
          return ($scope.tagButtonStr = addButtonStr)
        }
      })

      $scope.selectedPeriod = function (idx, isManual) {
        if (idx < 0) {
          return
        }

        if (isManual && !angular.equals($scope.drawTags, orgSavedDrawTags[selectedPeriodIdx])) {
          const hmtag = $scope.hmtags[selectedPeriodIdx]
          if (hmtag) {
            hmtag.heatmaptags = $scope.heatmaptag.transformTags($scope.drawTags)
          }
        }

        selectedPeriodIdx = idx
        _setHeatmapTagSettings($scope.heatmapImgWidth, $scope.heatmapImgHeight)

        // if under editing, reset edit mode
        if ($scope.editOptions) {
          // eslint-disable-next-line no-unused-vars
          const { mode, index } = $scope.editOptions
          if (mode === "add" || mode === "edit") {
            return ($scope.editOptions = null)
          }
        }
      }

      $scope.changedPeriod = function (removed) {
        let i
        for (i = removed.length - 1; i >= 0; i--) {
          var idx = $scope.hmtags.findIndex((tag) => tag._id === removed[i]._id)
          var removedTag = $scope.hmtags.splice(idx, 1)[0]
          if (removedTag) {
            removedTags.push(removedTag)
          }
        }

        return (() => {
          let asc, end
          const result = []
          for (
            i = 0, end = $scope.periods.length, asc = 0 <= end;
            asc ? i < end : i > end;
            asc ? i++ : i--
          ) {
            if (!$scope.hmtags[i]) {
              var newTag = {
                _cameraId: $scope.selectedItem._id,
                from: $scope.periods[i].from,
                to: $scope.periods[i].to,
                heatmaptags: _.cloneDeep(
                  __guard__($scope.hmtags[i - 1], (x) => x.heatmaptags) || []
                ),
              }
              newTag.heatmaptags.forEach((tag) => delete tag._id)
              $scope.hmtags[i] = newTag
            }

            $scope.hmtags[i].from = $scope.periods[i].from
            result.push(($scope.hmtags[i].to = $scope.periods[i].to))
          }
          return result
        })()
      }

      $scope.$watch(
        () =>
          JSON.stringify({
            isOpen: $scope.isOpen,
            selectedItem: $scope.selectedItem != null ? $scope.selectedItem._id : undefined,
          }),
        () => load()
      )

      const _setBackground = function (img, width, height) {
        const canvas = $element[0].querySelector("#background")
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0, width, height)

        if ($scope.isShowEffectiveArea()) {
          const x = width / 2
          const y = height / 2
          const radius = _.min([x, y])

          // recommended effective area
          ctx.beginPath()
          const rRadius = radius * _getEffectiveAreaRatio("r")
          ctx.arc(x, y, rRadius, 0, 2 * Math.PI)
          ctx.strokeStyle = "red"
          ctx.stroke()
          // maximum effective area
          const mRadius = radius * _getEffectiveAreaRatio("m")
          ctx.beginPath()
          ctx.arc(x, y, mRadius, 0, 2 * Math.PI)
          ctx.strokeStyle = "blue"
          return ctx.stroke()
        }
      }

      var _makeDrawTags = function (tags) {
        const saved = $scope.heatmaptag.transformDrawTags(tags)
        const drawTags = []
        for (let i = 0, end = MAX_TAG, asc = 0 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
          if ((saved != null ? saved[i] : undefined) && !_.isEmpty(saved[i].points)) {
            drawTags.push(saved[i])
          } else {
            var tag = _.assign(
              saved[i] != null ? saved[i] : {},
              $scope.heatmaptag.getDefaultDrawTag(i)
            )
            drawTags.push(tag)
          }
        }
        return drawTags
      }

      var _setHeatmapTagSettings = function (width, height, url = null) {
        if (!$scope.hmtags || selectedPeriodIdx < 0 || !$scope.heatmapImgHeight) {
          return
        }

        $scope.heatmaptag = new Heatmaptag($scope.selectedItem._id, width, height)
        $scope.trackingzone = new DrawZone(width, height, "trackingzone")

        const hmtag = $scope.hmtags[selectedPeriodIdx]
        savedDrawTags = _makeDrawTags(hmtag.heatmaptags)
        if (!orgSavedDrawTags[selectedPeriodIdx]) {
          orgSavedDrawTags[selectedPeriodIdx] = savedDrawTags
        }
        $scope.drawTags = _.cloneDeep(savedDrawTags)
        if (url) {
          return ($scope.snapshotUrl = url)
        }
      }

      var _getEffectiveAreaRatio = function (type) {
        if (type == null) {
          type = "r"
        }
        let ratio = 0
        if (type === "r") {
          // recommended effective area
          ratio = (() => {
            if (snapshotRatio === 3 / 4) {
              return 0.44
            } else if (snapshotRatio === 1) {
              return 0.6
            }
          })()
        } else if (type === "m") {
          // maximum effective area
          ratio = (() => {
            if (snapshotRatio === 3 / 4) {
              return 0.65
            } else if (snapshotRatio === 1) {
              return 0.8
            }
          })()
        }
        return ratio
      }

      var loadImage = function () {
        const image = new Image()
        image.onload = function () {
          snapshotRatio = this.height / this.width
          $scope.heatmapImgWidth = formHeatmapTagConfig.width()
          $scope.heatmapImgHeight = $scope.heatmapImgWidth * snapshotRatio
          _setBackground(image, $scope.heatmapImgWidth, $scope.heatmapImgHeight)
          _setHeatmapTagSettings($scope.heatmapImgWidth, $scope.heatmapImgHeight)
          createHeatmapPanel()
          return hideLoading()
        }
        image.onerror = function () {
          $scope.heatmapImgWidth = formHeatmapTagConfig.width()
          $scope.heatmapImgHeight = $scope.heatmapImgWidth * snapshotRatio
          _setHeatmapTagSettings($scope.heatmapImgWidth, $scope.heatmapImgHeight, errorImgeUrl)
          createHeatmapPanel()
          return hideLoading()
        }
        return (image.src = $scope.selectedItem.snapshotUrl)
      }

      var load = function () {
        if (!$scope.isOpen) {
          return
        }

        $scope.shortcuts.forEach(hotkeys.bindTo($scope).add)

        $scope.errorMessage = ""
        snapshotRatio = 9 / 16
        $scope.drawingTag = null
        $scope.editOptions = null
        trackingZoneTags = []
        selectedPeriodIdx = -1
        $scope.drawTags = []
        savedDrawTags = []
        removedTags = []
        orgPeriods = []
        orgSavedDrawTags = []

        if (!$scope.selectedItem) {
          return
        }

        showLoading()
        if ($scope.selectedItem.linkedZT2TZ == null) {
          $scope.selectedItem.linkedZT2TZ = false
        }
        orgLinkedZT2TZ = _.cloneDeep($scope.selectedItem.linkedZT2TZ)

        $scope.heatmaptag = new Heatmaptag($scope.selectedItem._id, 0, 0)
        return Promise.all([
          $scope.heatmaptag.getTags({ all: true }),
          getTrackingZone(),
          ApiSrv.getCamera({ id: $scope.selectedItem._id }),
        ]).spread(function (hmtags, tzRes, camera) {
          $scope.hmtags = hmtags
          $scope.periods = $scope.hmtags.map((tag) => ({
            _id: tag._id,
            from: moment.utc(tag.from).format("YYYY-MM-DD"),
            to: moment.utc(tag.to).format("YYYY-MM-DD"),
          }))
          orgPeriods = _.cloneDeep($scope.periods)
          trackingZoneTags = tzRes
          $scope.camera = camera

          return loadImage()
        })
      }

      return (createHeatmapPanel = function () {
        if ($element.find(".dwellmap-container .heatmap-panel").length) {
          $element.find(".dwellmap-container .heatmap-panel").remove()
        }

        $scope.storetimezone = $scope.selectedItem.timezone
        const today = DateTimeSrv.getLocalTimeByTimezone($scope.storetimezone)
        $scope.param = {
          start: moment(today).subtract(3, "day").startOf("day"),
          end: moment(today).subtract(1, "day").endOf("day"),
          sampling: "day",
        }
        $scope.heatmapType = "pass"
        $scope.hideFrame = { scaleBar: true }
        $scope.heatmapWeight = $scope.selectedItem.store.heatmapWeight
        $scope.heatmapStats = $scope.selectedItem.store.stats
        $scope.validTime = true

        return $element
          .find(".dwellmap-container")
          .append(
            $compile(
              [
                "<heatmap-panel",
                '  data-data="camera"',
                '  data-live="false"',
                '  data-param="param"',
                '  data-timezone="storetimezone"',
                '  data-ready="validTime"',
                '  data-hide-frame="hideFrame"',
                '  data-show-data="heatmapType"',
                '  data-heatmap-weight="heatmapWeight"',
                '  data-heatmap-stats="heatmapStats">',
                "</heatmap-panel>",
              ].join("")
            )($scope)
          )
      })
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
