/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

angular
  .module("uCountitUiApp")
  .controller(
    "CompanyReportCtrl",
    function (
      $scope,
      $state,
      $stateParams,
      $filter,
      $q,
      Auth,
      ApiSrv,
      ChartOptionSrv,
      CompanyReportSrv,
      DateTimeSrv,
      Locale,
      SamplingSrv,
      SelectorSrv,
      StoreSrv,
      UtilSrv,
      usSpinnerService,
      currentCompany
    ) {
      $scope.currentCompany = currentCompany
      $scope.stores = []
      $scope.hourlyVisitChartData = []
      $scope.dayOfWeekChartData = []
      $scope.supportExport = false
      $scope.pageOptions = {
        sampling: "day",
        referenceDay: undefined,
        content: "report",
      }

      const chartOption = ChartOptionSrv.reportBarChartOption

      $scope.visitRankings = [
        {
          title: Locale.string("7 days comparison"),
          key: Locale.string("7 days comparison"),
          sampling: "day",
          period: "7D",
          label: {
            origin: Locale.string("last 7 days"),
            compare: Locale.string("label_1_week_before"),
          },
        },
        {
          title: Locale.string("the day vs 1 week before"),
          key: Locale.string("the day vs 1 week before"),
          sampling: "hour",
          period: "1D",
          label: {
            origin: Locale.string("label_the_day"),
            compare: Locale.string("label_1_week_before"),
          },
        },
      ]

      // Can be changed title, so copy deep!
      const theDayRanking = $scope.visitRankings[1]
      const the7DaysRanking = $scope.visitRankings[0]

      const loadingQueue = []
      const showLoading = function (key) {
        if (key) {
          loadingQueue.push(key)
        }
        angular.element(".splash").show()
        usSpinnerService.spin("spinner")
        return angular.element(".spinner").css("position", "fixed")
      }

      const hideLoading = function (key) {
        if (key) {
          _.remove(loadingQueue, (raw) => raw === key)
          if (loadingQueue.length) {
            return
          }
        }

        angular.element(".splash").hide()
        return usSpinnerService.stop("spinner")
      }

      const getReportData = function () {
        if (!$scope.stores.length) {
          return
        }

        const params = {
          storeList: $scope.stores,
          referenceDay: $scope.pageOptions.referenceDay,
        }

        async.eachSeries(
          $scope.visitRankings,
          function (rankingItem, callback) {
            showLoading("CompanyDailyReport_Ranking" + rankingItem.key)
            return CompanyReportSrv.getRankingsByRisingVisits(rankingItem.period, params)
              .then(function (ranking) {
                rankingItem.data = ranking
                hideLoading("CompanyDailyReport_Ranking" + rankingItem.key)
                return callback(null)
              })
              .catch(function (err) {
                hideLoading("CompanyDailyReport_Ranking" + rankingItem.key)
                return callback(null)
              })
          },
          function (err) {}
        )

        drawVisitChart("24H", params)
        return drawVisitChart("7D", params)
      }

      var drawVisitChart = function (byPeriod, options) {
        showLoading("CompanyDailyReport_Visit" + byPeriod)
        return getVisitData(options, byPeriod).then(function (response) {
          let chartOptions
          const referenceLabel =
            {
              "24H": `${Locale.dateTime($scope.pageOptions.referenceDay, "date")}`,
              "7D": Locale.string("last 7 days"),
            }[byPeriod] || Locale.string("reference day")

          const chartData = []
          if (_.findIndex(response, (res) => res.noData === false) >= 0) {
            chartData.push({
              label: Locale.string("1 week before"),
              data: response[1],
              color: "#c3c3c3",
              bars: { order: 0 },
            })
            chartData.push({
              label: referenceLabel,
              data: response[0],
              color: "#51a1c1",
              bars: { order: 1 },
            })
          }

          if (byPeriod === "24H") {
            $scope.hourlyVisitChartData = chartData
            chartOptions = $scope.hourlyChartOptions = angular.copy(chartOption)
          } else {
            $scope.dayOfWeekChartData = chartData
            chartOptions = $scope.chartOptions = angular.copy(chartOption)
          }
          if (chartData.length) {
            setChartOptions(chartOptions, chartData, byPeriod)
          }
          return hideLoading("CompanyDailyReport_Visit" + byPeriod)
        })
      }

      var getVisitData = function (options, timeType) {
        const deffered = $q.defer()
        async.series(
          [
            (callback) =>
              CompanyReportSrv.getCompanyVisitsCount(_.merge(options, { timeType })).then(
                (response) => callback(null, response)
              ),
            function (callback) {
              const compareOptions = {
                storeList: options.storeList,
                referenceDay: moment($scope.pageOptions.referenceDay).subtract(1, "week"),
                timeType,
              }
              return CompanyReportSrv.getCompanyVisitsCount(compareOptions).then((response) =>
                callback(null, response)
              )
            },
          ],
          (err, results) => deffered.resolve(results)
        )

        return deffered.promise
      }

      var setChartOptions = function (chartOption, chartData, timeType) {
        const dateFormat = "YYYYMMDD0000"
        const parsingTimeRange = UtilSrv.parseTimeRange(timeType, $scope.pageOptions.referenceDay)
        const { sampling } = parsingTimeRange
        const start = moment(parsingTimeRange.from).format(dateFormat)
        const end = moment(parsingTimeRange.to).add(1, "days").format(dateFormat)
        const dataLength = moment(end, dateFormat).diff(
          moment(start, dateFormat),
          parsingTimeRange.sampling
        )
        const tooltipDateFormat = SamplingSrv[sampling].getFormat()
        const xaxisTicks = __range__(0, dataLength, false).map((num) => [
          num,
          moment.utc(start, dateFormat).add(num, sampling),
        ])

        if (sampling === "hour") {
          chartOption.xaxis.ticks = (() =>
            _.map(
              _.filter(xaxisTicks, (tick) => tick[0] % 4 === 0),
              (ticks) => [ticks[0], Locale.dateTime(moment.utc(ticks[1]), "hour")]
            ))()
        } else {
          chartOption.xaxis.ticks = (() =>
            xaxisTicks.map((ticks) => [ticks[0], moment.utc(ticks[1]).format("ddd")]))()
        }

        return (chartOption.tooltip.content = function (label, x, y, item) {
          const oneWeekBeforeData = chartData[0].data
          const refData = chartData[1].data
          const dataIdx = item.dataIndex
          const date = xaxisTicks[dataIdx][1]
          const datetime = Locale.dateTime(moment.utc(date), tooltipDateFormat)

          return [
            `${Locale.string("1 week before")} : `,
            `<b>${$filter("number")(oneWeekBeforeData[dataIdx][1], 0)}</b><br/>`,
            `${datetime} : <b>${$filter("number")(refData[dataIdx][1], 0)}</b>`,
          ].join("")
        })
      }

      $scope.export = function (type) {}
      // TODO: Export Function implementation
      // switch type
      //   when 'csv'
      //
      //   when 'excel'
      //
      //   else return false

      $scope.reload = function (fromInit) {
        if (fromInit == null) {
          fromInit = false
        }
        const theDay = $scope.pageOptions.referenceDay
        const oneWeekBefore = moment(theDay).subtract(7, "day")

        theDayRanking.label = {
          origin: Locale.dateTime(moment(theDay), "date"),
          compare: Locale.dateTime(oneWeekBefore, "date"),
        }

        const last7DaysStr =
          "[" +
          Locale.dateTime(moment(theDay).subtract(6, "day"), "day") +
          " ~ " +
          Locale.dateTime(moment(theDay), "day") +
          "]"
        const oneWeekBeforeStr =
          "[" +
          Locale.dateTime(moment(oneWeekBefore).subtract(6, "day"), "day") +
          " ~ " +
          Locale.dateTime(oneWeekBefore, "day") +
          "]"
        the7DaysRanking.title =
          Locale.string("7 days comparison") + ` : ${oneWeekBeforeStr} vs ${last7DaysStr}`

        return getReportData()
      }

      const init = () =>
        SelectorSrv.getOrganizationInfo($stateParams.orgType, $stateParams.id)
          .then(function (orgInfo) {
            $scope.stores = orgInfo.stores
            return ($scope.timezone = orgInfo.timezone)
          })
          .then(() => $scope.$broadcast("init-date-search", $scope.timezone))
          .catch(function (err) {
            if (err) {
              console.error(err)
            }
            return $state.go("default")
          })

      return angular.element(() => init())
    }
  )

function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
