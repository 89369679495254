/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular.module("uCountitUiApp").config(function ($stateProvider) {
  $stateProvider.state("app.report", {
    url: "/report/:orgType/:id",
    templateUrl($stateParams) {
      return (
        {
          company: "app/report/company/companyreport.html",
          storegroup: "app/report/storegroup/storeGroupreport.html",
          store: "app/report/store/storereport.html",
        }[$stateParams.orgType.toLowerCase()] || "app/report/company/companyreport.html"
      )
    },

    controllerProvider($stateParams) {
      return (
        {
          company: "CompanyReportCtrl",
          storegroup: "StoreGroupReportCtrl",
          store: "StoreReportCtrl",
        }[$stateParams.orgType.toLowerCase()] || "CompanyReportCtrl"
      )
    },
    resolve: {
      currentCompany(HeaderSrv) {
        return HeaderSrv.fetchCurrentCompany()
      },
    },
  })

  return $stateProvider.state("app.premiumReport", {
    url: "/premium/:orgType/:id",
    templateUrl: "app/report/premium/premium-report.html",
    controller: "PremiumReportCtrl",
    resolve: {
      currentCompany(HeaderSrv) {
        return HeaderSrv.fetchCurrentCompany()
      },
    },
  })
})
