import DateRangePicker from "bootstrap-daterangepicker"
import moment from "moment"
import $ from "jquery"

//INFO: Javascript Inheritance
// https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Objects/Inheritance
let DateRangePicker2 = function (element, options, cb) {
  DateRangePicker.call(this, element, options, cb)
  this.selectRange = options.selectRange || "day"
}

DateRangePicker2.prototype = Object.create(DateRangePicker.prototype)

Object.defineProperty(DateRangePicker2.prototype, "constructor", {
  value: DateRangePicker2,
  enumerable: false,
  writable: true,
})

DateRangePicker2.prototype.setStartDate = function (date) {
  let newDate = date
  if (this.selectRange == "week") {
    newDate = moment(date).startOf("isoweek")
  }
  DateRangePicker.prototype.setStartDate.call(this, newDate)
}

DateRangePicker2.prototype.setEndDate = function (date) {
  let newDate = date
  if (this.selectRange == "week") {
    newDate = moment(date).endOf("isoweek")
  }
  DateRangePicker.prototype.setEndDate.call(this, newDate)
}

$.fn.daterangepicker2 = function (options, callback) {
  this.each(function () {
    var el = $(this)
    if (el.data("daterangepicker2")) el.data("daterangepicker2").remove()
    el.data("daterangepicker2", new DateRangePicker2(el, options, callback))
  })
  return this
}

export default DateRangePicker2
