import angular from "angular"
const moment = require("moment-timezone")
import _ from "lodash"

angular
  .module("uCountitUiApp")
  .controller(
    "AccountServiceTokenCtrl",
    ($scope, $stateParams, RestApi, me, $http, $state, $timeout, Locale) => {
      const { accountId } = $stateParams
      const baseUrl = `/api/1.0/account/${accountId}/servicetoken`
      let originalValue = null
      $scope.form = null

      RestApi.Account.get(accountId).then((account) => {
        $scope.myName = Locale.fullName(account)
        return get()
      })

      const get = () =>
        $http
          .get(baseUrl)
          .then((response) => {
            if (response.status == 200) {
              originalValue = _.cloneDeep(response.data)
              $scope.form = response.data
              $scope.canSubmit = (form) => !_.isEqual(form, originalValue)
            } else {
              $scope.form = null
            }
          })
          .catch((err) => {
            console.error(err)
          })

      $scope.onCreateClick = () =>
        $http
          .post(baseUrl)
          .then(() => get())
          .catch(console.error)

      $scope.onUpdateClick = ($event, formData) => {
        $event.preventDefault()
        return $http
          .patch(baseUrl, {
            whiteList: formData.whiteList,
            active: formData.active,
          })
          .then(() => get())
          .catch(console.error)
      }

      $scope.onRemoveClick = ($event, id) => {
        $event.preventDefault()
        return $http
          .delete(baseUrl)
          .then(() => {
            $scope.form = null
          })
          .catch(console.error)
      }

      $scope.displayIssuedAt = (issuedAt) => moment(issuedAt).fromNow()

      const copyClipTooltipMsgs = {
        hover: "Copy Service Token to clipboard",
        done: "Copied",
      }
      $scope.copyClipTooltip = copyClipTooltipMsgs.hover
      $scope.copyClipMouseout = () => {
        $timeout(() => {
          $scope.copyClipTooltip = copyClipTooltipMsgs.hover
        }, 500)
      }
      $scope.copyClip = ($event, item) => {
        const keyElement = document.getElementById(`input-${item._id}`)
        keyElement.setAttribute("type", "text")
        keyElement.select()
        document.execCommand("copy")
        $scope.copyClipTooltip = copyClipTooltipMsgs.done
      }
    }
  )
