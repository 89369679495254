import React from "react"
import CompanySelectField from "../../../../components/react/CompanySelectField"
import propTypes from "prop-types"

const CompanySourceParameter = ({ error }) => {
  return (
    <div className={`form-group ${error ? "has-error" : null}`}>
      <label className="control-label col-sm-3">Company</label>
      <div className="col-sm-9">
        <CompanySelectField field="source.parameter.companyId" />
        {error && error.companyId ? <span className="help-block">{error.companyId}</span> : null}
      </div>
    </div>
  )
}

CompanySourceParameter.propTypes = {
  error: propTypes.object,
}

export default CompanySourceParameter
