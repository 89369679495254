"use strict"

import { EnvStage } from "common-library/ucountit-env"
import { createApiUrls } from "./apiurls"
import { Optional, Required } from "./Environment"

const hostname = "retailtrend.net"
const config: EnvStage<Required, Optional> = {
  brand: "retailtrend",
  host: [hostname],
  api: Object.assign(createApiUrls("iot.retailtrend.net"), {
    dburl: "http://dbapi.retailtrend.net:19501/local-api/1.0",
    ioturl: "http://iot.retailtrend.net:19503/local-api/1.0/iot",
    queueurl: "http://q.retailtrend.net:19581/api/v1",
    trackurl: "http://trackio.retailtrend.net:19521/api/v1",
    faceurl: "http://secure.retailtrend.net:19509/api/v1",
    openapiurl: "http://openapi.retailtrend.net:19505",
    rebuildurl: "http://rebuild.retailtrend.net:19620/api/v1",
  }),

  web: {
    queueurl: "http://q.retailtrend.net",
    faceurl: "/facedashboard",
    tunnelUrl: "http://tunnel.retailtrend.net",
  },

  log: {
    analyticTrackingId: "G-2GP4KNFPP6",
  },

  s3: {
    static: {
      bucket: "retailtrend.static",
      destination: "",
      region: "us-west-1",
      cloudfront: "",
    },
    firmware: {
      bucket: "retailtrend.firmware",
      destination: "",
      region: "us-west-1",
    },
    sales: {
      bucket: "retailtrend.common",
      region: "us-west-1",
      destination: "sales",
    },
    face: {
      bucket: "retailtrend.faces",
      destination: "",
      region: "us-west-1",
    },
    customAgency: {
      bucket: "retailtrend.common",
      destination: "customAgency",
      region: "us-west-1",
    },
  },

  cloudFront: {
    bucket: "retailtrend.static",
    region: "us-west-1",
    prefix: "ui",
    basePath: "https://cdn.retailtrend.net",
  },

  email: {
    from: "notification@retailtrend.net",
  },

  sqs: {
    sales: {
      region: "us-west-1",
      requestParsingRawQueue:
        "https://sqs.us-west-1.amazonaws.com/163170497538/SalesRequestParsingRawQueue-retail",
      confirmWaitingdataQueue:
        "https://sqs.us-west-1.amazonaws.com/163170497538/SalesConfirmWaitingdataQueue-retail",
    },
  },
}

export default config
