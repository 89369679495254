/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS201: Simplify complex destructure assignments
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")
  .controller(
    "PremiumReportCtrl",
    function (
      $scope,
      $state,
      $stateParams,
      $location,
      $window,
      $document,
      $anchorScroll,
      Auth,
      ApiSrv,
      PremiumReportSrv,
      DateTimeSrv,
      Locale,
      NgTableParams,
      SelectorSrv,
      HeaderSrv
    ) {
      $scope.orgType = $stateParams.orgType
      $scope.orgId = $stateParams.id
      $scope.pageOptions = {
        sampling: "month",
        minView: "month",
        view: "month",
        referenceDay: undefined,
        content: "premium-report",
      }

      $scope.header = {
        date: Locale.string("Date"),
        period: Locale.string("Period"),
        type: Locale.string("File Type"),
        name: Locale.string("Name"),
        confirm: Locale.string("Confirm to Email"),
        build: Locale.string("Build"),
        contents: Locale.string("Contents"),
      }

      $scope.tooltip = {
        download: Locale.string("Download"),
        notemplate: Locale.string("No Template"),
      }

      const REGEXP_STORENAME = /@(.+)-/
      const REGEXP_COMPANYTYPE = /#(.+)-/
      const REGEXP_REPORTNAME = /(\d{8})-([^-]+)-([^#@]+)[^-]+-(.+)\./

      $scope.validTime = false
      $scope.tableParams = new NgTableParams({}, { data: [] })

      $scope.selectPeriod = function () {
        if ($scope.company) {
          return getReportList()
        }
      }

      const openBuildNewReportForm = function (report = null) {
        $scope.reportList = []
        for (var item of Array.from($scope.company.reports)) {
          if (item.active && item.type === "excel") {
            $scope.reportList.push(item.name)
          }
        }

        const now = new Date()
        $scope.newReport = {
          date: now,
          selectedReportName: $scope.reportList[0],
          selectedReport: null,
          calendarType: "week",
        }
        $scope.selectNewReport($scope.newReport.selectedReportName)
        $scope.isBuildNewReportDlg = true
        return $anchorScroll()
      }

      $scope.selectNewReport = function (item) {
        const rp = $scope.company.reports.find(
          (r) => r.active && r.type === "excel" && r.name === item
        )
        $scope.newReport.selectedReport = rp
        if (rp) {
          $scope.newReport.calendarType =
            rp.period === "weekly" ? "week" : rp.period === "daily" ? "date" : "month"
        }
        return rp
      }

      $scope.buildNewReport = () => openBuildNewReportForm()

      const calcReportTargetDate = function (date, period) {
        let dt
        const mDate = moment(date)
        if (period === "weekly") {
          dt = mDate.endOf("isoweek")
        } else if (period === "daily") {
          dt = mDate
        } else {
          dt = mDate.endOf("month")
        }

        const rDate = dt.toISOString().slice(0, 10).replaceAll("-", "/")
        return rDate
      }

      const calcReportDateRange = function (date, period) {
        let rDate
        const mDate = moment(date)
        if (period === "weekly") {
          const sd = mDate.clone().startOf("isoweek")
          const ed = mDate.clone().endOf("isoweek")
          rDate = sd.format("YYYY-MM-DD") + " ~ " + ed.format("YYYY-MM-DD")
        } else if (period === "daily") {
          rDate = mDate.format("YYYY-MM-DD")
        } else {
          rDate = mDate.clone().endOf("month").format("YYYY-MM")
        }

        return rDate
      }

      $scope.submitBuildNewReport = function (newReport) {
        const report = $scope.newReport.selectedReport
        if (!report) {
          return
        }

        const record = {}
        //INFO calc the last date of the period
        record.date = calcReportTargetDate(newReport.date, report.period)
        record.companyId = $scope.company._id
        record.reportId = report._id
        record.reportName = report.name
        record.period = report.period
        record.reportType = report.type
        record.reportUri = ""
        record.reportFiles = []
        record.builtFlag = false
        record.confirmFlag = false
        record.sentFlag = false
        record.orderNum = parseInt(new Date().getTime() / 1000)

        return PremiumReportSrv.save(record, function (res) {
          $scope.isBuildNewReportDlg = false
          return $scope.reload()
        })
      }

      const download = function (dataurl) {
        const a = document.createElement("a")
        a.href = dataurl
        //a.setAttribute("download", filename)
        return a.click()
      }

      const reportUpdateWebStatus = function (report) {
        if (report.sentFlag) {
          report._webStatus = "sent"
        } else {
          if (report.confirmFlag) {
            report._webStatus = "confirmed"
          } else {
            report._webStatus = "ready"
          }
        }

        return report
      }

      $scope.onClickConfirm = function (report) {
        reportUpdateWebStatus(report)
        return PremiumReportSrv.update({ id: report._id }, report)
      }

      $scope.onClickRebuild = function (report) {
        report.confirmFlag = false
        report.sentFlag = false
        report.builtFlag = false
        const unixMs = Math.floor(new Date().getTime())
        report.orderNum = unixMs * 1000
        reportUpdateWebStatus(report)
        return PremiumReportSrv.update({ id: report._id }, report)
      }

      const getReportContets = function (files, indexArr) {
        let left, left1, left2, sortContents
        if (_.isEmpty(files)) {
          return []
        }

        const contents = files.map(function (f, i) {
          let title, type
          const storeName = REGEXP_STORENAME.exec(f)
          if (_.isNull(storeName)) {
            type = "company"
            const companyType = REGEXP_COMPANYTYPE.exec(f)
            if (_.isNull(companyType)) {
              title = "Company"
            } else {
              title = companyType[1]
            }
          } else {
            type = "store"
            title = storeName[1]
          }

          return {
            name: f,
            type,
            title,
            fileIndex: indexArr[i],
          }
        })

        const storeList = _.sortBy(
          contents.filter((c) => c.type === "store"),
          ["title"]
        )
        const companyList = contents.filter((c) => c.type === "company")
        const companyReport =
          (left =
            (left1 =
              (left2 = _.find(companyList, ["title", "Company(V)"])) != null
                ? left2
                : _.find(companyList, ["title", "Company(F)"])) != null
              ? left1
              : _.find(companyList, ["title", "Company"])) != null
            ? left
            : companyList != null
            ? companyList[0]
            : undefined
        if (!_.isEmpty(companyReport)) {
          companyReport.title = $scope.company.name
          sortContents = [companyReport].concat(storeList)
        } else {
          sortContents = storeList
        }

        return sortContents
      }

      const isActiveEmail = function (config) {
        const companyEmailsStatus =
          (config.companyFlag ? config.companyFlag : false) && !_.isEmpty(config.emails)
        const storeEmailsStatus =
          (config.storeFlag ? config.storeFlag : false) && !_.isEmpty(config.storeEmails)

        return companyEmailsStatus || storeEmailsStatus
      }

      var getReportList = function () {
        const period = $scope.reportPeriod
        const from = $scope.pageOptions.referenceDay
        from.set("date", 1)
        const to = from.clone()
        to.add(1, "month")

        //"201705"
        return ApiSrv.getReportList({
          companyId: $scope.company._companyId,
          from,
          to,
          period,
        }).then(function (list) {
          //sample = [
          //  { date: '2017-05-09', name: '일간 리포트', pdfUrl: '72cX8luW2za8fzUu.pdf' }
          //  { date: '2017-05-10', name: '일간 리포트', pdfUrl: '7-trends-for-big-data-final_ko-kr.pdf' }
          //]
          const reports = list.map(function (report) {
            report.id = report._id
            report.type = report.reportType
            report.name = report.reportName
            report.dateStr = calcReportDateRange(report.date, report.period)
            reportUpdateWebStatus(report)

            report.builtAtStr = report.builtFlag ? moment(report.builtAt).format() : "⏳"
            report.confirmErrMsg = Locale.string("Inactive Email")
            const config = _.find($scope.company.reports, ["_id", report._reportId])
            if (config) {
              report.invalidReport = false
              report.activeEmail = isActiveEmail(config)
            } else {
              report.invalidReport = true
              report.confirmErrMsg = Locale.string("No Template")
            }

            report.contents = getReportContets(report.reportFiles, report.reportIndex)

            if (report.reportType === "excel" && !_.isEmpty(report.contents)) {
              report.selectedContent = report.contents[0]
              const nameRes = REGEXP_REPORTNAME.exec(report.selectedContent.name)
              report.name = `${nameRes[2]}-${nameRes[3]}-${nameRes[4]}`
            }

            return report
          })

          const pageRowArr = [10, 50, 100]
          return ($scope.tableParams = new NgTableParams(
            { count: pageRowArr[0], sorting: { date: "desc" } },
            { counts: pageRowArr, data: reports }
          ))
        })
      }

      $scope.openReport = function (report, index) {
        ;`\
popup = $window.open("/premiumReport.html", "_blank", "menubar=1, width=1200")
pdfUrl = "./#{report.pdfUrl}"
popup.onload = ->
  popup.document.title = "Premium Report - #{report.date} - #{report.name}"

  embedEle = document.createElement 'embed'
  embedEle.src = pdfUrl
  embedEle.width = '100%'
  embedEle.height = "2100px"

  popup.document.getElementById("pdfViewer").appendChild embedEle\
`
        if (report.reportType === "excel") {
          //popup = $window.open("/api/1.0/reports/#{report.id}/#{index}", "_blank", "menubar=1, width=1200, height=920")
          download(`/api/1.0/reports/${report.id}/${index}`)
        } else {
          $window.open(
            `/report/premium/reports/${report.id}/view?companyName=${$scope.company.name}`,
            "_blank",
            "menubar=1, width=1200, height=920"
          )
        }
        return true
      }

      $scope.reload = function (fromInit) {
        if (fromInit == null) {
          fromInit = false
        }
        return getReportList()
      }

      $scope.getContentIcon = function (type) {
        if (type === "company") {
          return "mdi-domain"
        } else {
          return "mdi-store"
        }
      }

      $scope.isComfirmEmail = (report) => report.activeEmail && !report.invalidReport

      const getLastTimeZoneStore = function (allStores) {
        let stores = allStores.filter((store) => store.orgType === "store")
        stores = stores.map(function (store) {
          const offset = moment.tz(store.timezone != null ? store.timezone : "UTC").utcOffset()
          store.offset = offset
          return store
        })

        return _.minBy(stores, "offset")
      }

      const makeReportCreationTimeStr = function (store) {
        const reportExecuteTime = moment.tz(store.timezone).set({ hour: 2, minute: 0, second: 0 })
        reportExecuteTime.add(1, "hour")

        const offset = moment().utcOffset()
        const localReportExecuteTime = moment(reportExecuteTime).utcOffset(offset)

        return `Report Creation Time: ${localReportExecuteTime.format("HH:mm")}`
      }

      const init = function () {
        $scope.isBuildNewReportDlg = false

        Promise.all([HeaderSrv.fetchCurrentCompany(), HeaderSrv.fetchAllStore()])
          .then(function (...args) {
            const [company, allStores] = Array.from(args[0])
            $scope.company = company

            const lastTimezoneStore = getLastTimeZoneStore(allStores)
            return ($scope.strReportCreationTime = makeReportCreationTimeStr(lastTimezoneStore))
          })
          .then(() => $scope.$broadcast("init-date-search", $scope.timezone))
        return ($scope.reportPeriod = "all")
      }

      return angular.element(() => init())
    }
  )
