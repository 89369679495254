/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

import Promise from "bluebird"
import env from "../../../config/environment"

angular
  .module("uCountitUiApp")

  .controller(
    "SitemapCtrl",
    function ($scope, $filter, NgTableParams, Auth, HeaderSrv, RestApi, me, BrandSrv, UtilSrv) {
      $scope.me = me
      $scope.stores = []
      $scope.storeGroups = []
      $scope.isStores = true
      $scope.brandNav = BrandSrv.getBrand().navMenu
      $scope.displayGroupName = UtilSrv.getDisplayStoreGroupName
      $scope.isLocalgrey = env.isLocalgrey

      $scope.isNoFunctionality = (features) => !_.includes(features, true)

      const loadItems = (items) =>
        new NgTableParams({ count: 5 }, { counts: [1, 5, 10, 50], data: items })

      const sortEmptyToEnd = function (items, key) {
        const list = _.filter(items, (o) => o[key].length)
        const emptyList = _.filter(items, (o) => !o[key].length)

        return list.concat(emptyList)
      }

      $scope.reload = function (cache) {
        if (cache == null) {
          cache = true
        }
        return HeaderSrv.fetchCurrentCompany(cache).then(function (company) {
          $scope.company = company
          return Promise.all([
            RestApi.Store.index(cache, { params: { companyId: company._id } }),
            RestApi.StoreGroup.index(cache, { params: { companyId: company._id } }),
            RestApi.Sensor.index(cache, { params: { companyId: company._id } }),
          ]).spread(function (stores, storeGroups, sensors) {
            if (!(stores != null ? stores.length : undefined)) {
              $scope.isStores = false
            }
            //stores
            async.map(
              stores,
              (store, callback) =>
                store.getFunctionality().then(function (contents) {
                  const sensorList = _.filter(sensors, { _storeId: store._id })
                  return callback(null, {
                    name: store.name,
                    _id: store._id,
                    store,
                    contents,
                    sensors: _.sortBy(sensorList, ["name"]),
                  })
                }),

              function (err, result) {
                $scope.stores = sortEmptyToEnd($filter("orderBy")(result, ["name"]), "sensors")
                return ($scope.tableParams = loadItems($scope.stores))
              }
            )

            //store groups
            return async.map(
              storeGroups,
              (group, callback) =>
                group.getFunctionality().then(function (contents) {
                  const storeList = []
                  group.storeList.forEach(function (storeID) {
                    const store = _.find(stores, ["_id", storeID])
                    if (store) {
                      return storeList.push({ name: store.name })
                    }
                  })

                  return callback(null, {
                    name: group.name,
                    _id: group._id,
                    contents,
                    stores: _.sortBy(storeList, ["name"]),
                  })
                }),

              function (err, result) {
                $scope.storeGroups = sortEmptyToEnd($filter("orderBy")(result, ["name"]), "stores")
                return ($scope.storeGroupTableParams = loadItems($scope.storeGroups))
              }
            )
          })
        })
      }

      const init = () => $scope.reload(false)

      return init()
    }
  )
