"use strict"

import Promise from "bluebird"
import { clone } from "../class-utils"

function Store(storeData, Api) {
  this.Api = Api
  this.data = storeData
  this.orgType = Store.ORG_TYPE
  delete storeData["data"]
  this.data = storeData
  for (const k in storeData) {
    this[k] = storeData[k]
  }
  this.functionalityPromise = null

  return this
}

Store.prototype.clone = function () {
  return clone(this)
}

Store.prototype.thumbnailUrl = function () {
  return `/api/1.0/store/${this.data._id}/thumbnail`
}
Store.prototype.getOwnFunctionality = function (functionality = {}) {
  const featureKey = new Set()
  for (const feature of [...Object.keys(functionality), ...Object.keys(this.features)]) {
    featureKey.add(feature)
  }

  const result = {}
  featureKey.forEach((feature) => {
    result[feature] = functionality[feature] || this.features[feature]
  })

  return result
}
Store.prototype.getFunctionality = async function (defaultFunctionality = {}) {
  if (this.functionalityPromise == null) {
    this.functionalityPromise = Promise.all([
      this.Api.Company.get(this._companyId),
      this.Api.Sensor.index(true, { params: { storeId: this.data._id } }),
    ]).spread((company, sensors) => {
      let functionality = company.getOwnFunctionality()
      functionality = sensors.reduce(
        (functionality, sensor) => sensor.getOwnFunctionality(functionality),
        functionality
      )
      return functionality
    })
  } else {
    this.functionalityPromise
  }

  return this.getOwnFunctionality(
    Object.assign(await this.functionalityPromise, defaultFunctionality)
  )
}

Store.ORG_TYPE = "store"

export default Store
