/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory("RateValue", function () {
    const get = function (maxValue, rates, opt) {
      if (opt == null) {
        opt = "raw"
      }
      const vsum = rates.reduce((p, c) => Number(p) + Number(c))
      if (!vsum) {
        return rates
      }

      if (opt === "100%") {
        if (vsum !== 100) {
          throw new Error("sum of array must 100")
        }
        rates = rates.map((v) => v / 100)
      } else if (opt === "raw") {
        rates = rates.map((v) => v / vsum)
      } //'float'
      //noop

      let frates = rates.map(function (v, idx) {
        let i
        const d = maxValue * v
        if (0 < d && d < 1) {
          i = 1
        } else {
          i = Math.round(d)
        }
        return { idx, value: i, r: d - i }
      })

      const sum = frates.reduce((p, c) => (p.value != null ? p.value : p) + c.value)

      let dx = sum - maxValue
      while (dx !== 0) {
        frates = frates.sort(function (a, b) {
          if (a.value === 0) {
            a.err = Infinity
          } else {
            a.err = Math.abs(a.r / a.value)
          }
          if (b.value === 0) {
            b.err = Infinity
          } else {
            b.err = Math.abs(b.r / b.value)
          }
          return a.err - b.err
        })

        var delta = dx > 0 ? -1 : +1
        frates[0].value += delta
        frates[0].r += delta
        dx += delta
      }

      frates.sort((a, b) => a.idx - b.idx)
      return frates.map((d) => d.value)
    }

    return {
      get,
    }
  })
