/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

import Promise from "bluebird"
import { kStandardLabels, createDefaultFootfall } from "../counter-label"

angular
  .module("uCountitUiApp")

  .controller(
    "countingareaConfigCtrl",
    function (
      $scope,
      $element,
      $window,
      $compile,
      Locale,
      usSpinnerService,
      CameraConfigSrv,
      DrawZone,
      DateTimeSrv,
      ApiSrv,
      Auth
    ) {
      let createHeatmapPanel
      const MAX_AREA = 6

      const dbColorPattern = /\d+,\d+,\d+/
      $scope.rgbPattern = /rgb\(\d+,\d+,\d+\)/
      //INFO $scope.trackAnalysis.countingArea.areas # countingAreas of DB

      $scope.drawAreas = [] // countingAreas of form
      $scope.orgDrawAreas = [] // old zones to compare
      $scope.countingAreas = [] // countingAreas of controller
      $scope.orgCountingAreas = []
      $scope.orgFootfall = {}

      $scope.startSrv = null
      $scope.endSrv = null
      $scope.cameraId = null
      $scope.selectedItem = $scope.selectedItem != null ? $scope.selectedItem : {}

      $scope.strs = {
        add: Locale.string("Add"),
        edit: Locale.string("Edit"),
        linetag: Locale.string("Line Tag"),
        save: Locale.string("Save"),
        cancel: Locale.string("Cancel"),
        clear: Locale.string("Clear"),
      }

      $scope.modeList = [
        {
          name: Locale.string("Standard"),
          value: "standard",
        },
        {
          name: Locale.string("Simple"),
          value: "simple",
        },
      ]

      const findModeName = (value) => {
        const mode = $scope.modeList.find((a) => a.value == value)
        return mode?.name
      }

      $scope.changeAreaMode = function (area, mode) {
        area.mode = mode.value
        area.modeName = mode.name

        if (area.mode == "simple") {
          // clear lineTag
          $scope.drawAreas[2 * area.index].line = []
          $scope.drawAreas[2 * area.index + 1].line = []
        }
      }

      $scope.periods = []
      $scope.footfalls = []
      $scope.footfall = {}
      $scope.selectedLabels = []
      $scope.selectedStoreLabels = []
      $scope.standardLabels = kStandardLabels
      $scope.removedFootfalls = []

      Auth.fetchCurrentAccount().then((auth) => ($scope.auth = auth))

      $scope.changeStoreLabel = function (label, idx, tag) {
        $scope.selectedLabels[idx] = label
        return ($scope.footfall.counters[tag.index]._labelId = label._id)
      }

      // 본 함수는 period-config-btn-directive.js의 scope.selectedPeriod 에서 호출한다.
      // idx: period array index, isManual: 처음 초기화할때는 false로 호출, UI에서 선택하면 true를 호출
      $scope.selectedPeriod = function (idx, isManual) {
        if (idx < 0) {
          return
        }

        if (isManual) {
          if ($scope.footfall != null) {
            $scope.footfall.counters.forEach(
              (counter, i) => (counter.label = $scope.selectedLabels[i])
            )
          }
        }

        $scope.selectedPeriodIdx = idx
        $scope.footfall = $scope.footfalls[idx]
        $scope.footfall.counters.forEach((counter, i) => ($scope.selectedLabels[i] = counter.label))

        //sync footfall -> drawArea
        createCountingAreasFromFootfall($scope.footfall)
        $scope.orgDrawAreas = _.cloneDeep($scope.drawAreas)
        $scope.orgCountingAreas = angular.toJson($scope.countingAreas)
        $scope.orgFootfall = _.cloneDeep($scope.footfall)

        // if under editing, reset edit mode
        if ($scope.editOptions) {
          // eslint-disable-next-line no-unused-vars
          const { mode, index } = $scope.editOptions
          if (mode === "add" || mode === "edit") {
            return ($scope.editOptions = null)
          }
        }
      }

      // eslint-disable-next-line no-unused-vars
      const printPeriods = function (msg, periods) {
        if (msg.length) {
          console.info(msg)
        }
        return periods.forEach((p, i) => console.info("period", i, p._id, p.name))
      }

      $scope.changedPeriod = function (removed) {
        //printPeriods("A", $scope.footfalls)
        let i
        for (i = removed.length - 1; i >= 0; i--) {
          var idx = $scope.footfalls.findIndex((footfall) => footfall._id === removed[i]._id)
          var removedFootfall = $scope.footfalls.splice(idx, 1)[0]
          if (removedFootfall) {
            $scope.removedFootfalls.push(removedFootfall)
          }
        }

        return (() => {
          let asc, end
          const result = []
          for (
            i = 0, end = $scope.periods.length, asc = 0 <= end;
            asc ? i < end : i > end;
            asc ? i++ : i--
          ) {
            if (!$scope.footfalls[i]) {
              var newFootfall = {
                _cameraId: $scope.cameraId,
                from: $scope.periods[i].from,
                to: $scope.periods[i].to,
                counters: _.cloneDeep(
                  __guard__($scope.footfalls[i - 1], (x) => x.counters) ||
                    createDefaultFootfall("tFF").counters
                ),
                isNew: true,
              }
              $scope.footfalls[i] = newFootfall
            }

            $scope.footfalls[i].from = $scope.periods[i].from
            result.push(($scope.footfalls[i].to = $scope.periods[i].to))
          }
          return result
        })()
      }

      $scope.getDisplayLabelName = function (label) {
        const sLabel = _.find(kStandardLabels, ["name", label])
        if (sLabel) {
          return Locale.string(sLabel.i18nKey)
        } else {
          return label
        }
      }

      const loadFootfallCfg = (cameraId, storeId) =>
        Promise.all([
          ApiSrv.getFootfallsConfigOfCamera(cameraId, storeId, { all: true }, false),
          ApiSrv.getStore({ id: storeId, isCache: false }),
        ])
          .spread(function (footfalls, store) {
            $scope.selectedStoreLabels = store.labels
            $scope.footfalls = footfalls.map(function (ff) {
              ff.from = moment.utc(ff.from).format("YYYY-MM-DD")
              ff.to = moment.utc(ff.to).format("YYYY-MM-DD")
              return ff
            })
            $scope.periods = $scope.footfalls.map((ff) => _.pick(ff, ["_id", "from", "to"]))
            $scope.orgPeriods = _.cloneDeep($scope.periods)
            return ($scope.orgFootfalls = _.cloneDeep($scope.footfalls))
          })
          .catch((err) => console.error("loadFootfallCfg error", err))

      angular.element($window).on("resize", () => load())

      const showLoading = function () {
        angular.element("#mySplash").show()
        return usSpinnerService.spin("mySpinner")
      }

      const hideLoading = function () {
        angular.element("#mySplash").hide()
        return usSpinnerService.stop("mySpinner")
      }

      const getIndex = (zname, index) => 2 * index + (zname === "start" ? 0 : 1)

      const getDefaultArea = function (srv, index) {
        const zone = srv.getDefaultDrawZone(index)
        //TODO add line
        const line = []
        return { zone, line }
      }

      $scope.modifyZone = function (mode, zname, index) {
        let zoneSrv
        const idx = getIndex(zname, index)
        if (zname === "start") {
          zoneSrv = $scope.startSrv
        } else {
          zoneSrv = $scope.endSrv
        }

        $scope.editOptions = {
          mode,
          index: idx,
        }

        //TODO: move to coutingareaEditor.driective areaCmd function
        if (mode === "clear") {
          $scope.editOptions = null
          return _.assign($scope.drawAreas[idx], getDefaultArea(zoneSrv, index))
        } else if (mode === "add") {
          return checkNenableCountingArea(index)
        }
      }

      var checkNenableCountingArea = function (index) {
        // always enable area on adding new zone
        $scope.drawAreas[2 * index].zone.active = true
        $scope.drawAreas[2 * index + 1].zone.active = true
        return ($scope.countingAreas[index].active = true)
      }

      $scope.changeActive = function (tag, index) {
        $scope.countingAreas[index].active = tag.active
        $scope.drawAreas[2 * index].zone.active = tag.active
        return ($scope.drawAreas[2 * index + 1].zone.active = tag.active)
      }

      $scope.changeNetVisitsActive = function (tag, index) {
        $scope.countingAreas[index].netVisits.active = tag.netVisits.active
      }

      $scope.changeNetVisitsTuneRatio = function (tag, index) {
        $scope.countingAreas[index].netVisits.tuneRatio = tag.netVisits.tuneRatio
      }

      $scope.isEditingTag = function (zname, index) {
        const idx = getIndex(zname, index)
        return $scope.editOptions && $scope.editOptions.index === idx
      }

      $scope.isEmptyPoints = function (zname, index) {
        const idx = getIndex(zname, index)
        return _.isEmpty(
          __guard__(
            $scope.drawAreas[idx] != null ? $scope.drawAreas[idx].zone : undefined,
            (x) => x.points
          )
        )
      }

      $scope.isValidArea = (index) =>
        !$scope.isEditingTag("start", index) &&
        !$scope.isEditingTag("end", index) &&
        ((!$scope.isEmptyPoints("start", index) && !$scope.isEmptyPoints("end", index)) ||
          ($scope.isEmptyPoints("start", index) && $scope.isEmptyPoints("end", index)))

      $scope.isAllValidArea = function () {
        for (let i = 0; i < $scope.countingAreas.length; i++) {
          if (
            !$scope.isValidArea(i) ||
            ($scope.countingAreas[i].active &&
              ($scope.isEmptyPoints("start", i) || $scope.isEmptyPoints("end", i)))
          ) {
            return false
          }
        }
        return true
      }

      $scope.FilledArea = (index) =>
        !$scope.isEmptyPoints("start", index) && !$scope.isEmptyPoints("end", index)

      $scope.isShowBtn = function (type, zname, idx) {
        switch (type) {
          case "add":
            return $scope.isEmptyPoints(zname, idx) && !$scope.isEditingTag(zname, idx)
          case "linetag":
            if ($scope.countingAreas[idx]?.mode == "simple") return false
            return !$scope.isEmptyPoints(zname, idx) && !$scope.isEditingTag(zname, idx)
          case "edit":
            return !$scope.isEmptyPoints(zname, idx) && !$scope.isEditingTag(zname, idx)
          case "save":
            return $scope.isEditingTag(zname, idx)
          case "cancel":
            return $scope.isEditingTag(zname, idx)
          case "clear":
            return !$scope.isEmptyPoints(zname, idx) && !$scope.isEditingTag(zname, idx)
        }
      }

      $scope.syncAreaName = function (area) {
        // update the name of the drawAreas
        if ($scope.drawAreas[2 * area.index]) {
          $scope.drawAreas[2 * area.index].zone.name = "B:" + area.name
        }
        if ($scope.drawAreas[2 * area.index + 1]) {
          $scope.drawAreas[2 * area.index + 1].zone.name = "E:" + area.name
        }
      }

      const syncAllAreaName = () => $scope.countingAreas.map($scope.syncAreaName)

      $scope.onNameChanged = (area) => $scope.syncAreaName(area)

      $scope.setColor = function (tag) {
        tag.color = dbColorPattern.exec(tag.rgbcolor)
        if (tag.color) {
          tag.color = tag.color[0]
        }

        if ($scope.drawAreas[2 * tag.index] != null) {
          $scope.drawAreas[2 * tag.index].zone.color = tag.color
        }
        if ($scope.drawAreas[2 * tag.index + 1] != null) {
          return ($scope.drawAreas[2 * tag.index + 1].zone.color = tag.color)
        }
      }

      const convert2DrawZone = (points, name, color) => ({
        active: true,
        color,
        name,
        points: points != null ? points : [],
        rgbcolor: `rgb(${color})`,
      })

      const convert2ObjSize = (sz1M) => Math.round(sz1M * 1000000)
      const convert2ObjSize1M = (sz) => Math.round(sz / 1000000)

      const convertCountingAreas2Footfall = function (ff) {
        const footfall = {
          _id: ff._id,
          _cameraId: ff._cameraId,
          from: ff.from,
          to: ff.to,
          counters: ff.counters,
        }
        for (let i = 0; i < $scope.countingAreas.length; i++) {
          var area = $scope.countingAreas[i]
          footfall.counters[i].active = area.active
          footfall.counters[i].name = area.name
          footfall.counters[i].sourceType = "CA"
          footfall.counters[i].mode = area.mode
          footfall.counters[i].netVisits = area.netVisits
          if ($scope.FilledArea(i)) {
            area.objSize = convert2ObjSize(area.objSize1M)
            var sDrawArea = $scope.drawAreas[2 * i]
            area.begin.zone = $scope.startSrv.transformZone(sDrawArea.zone).points
            area.begin.line = sDrawArea.line
            var bDrawArea = $scope.drawAreas[2 * i + 1]
            area.end.zone = $scope.endSrv.transformZone(bDrawArea.zone).points
            area.end.line = bDrawArea.line
            footfall.counters[i].area = area
          } else {
            footfall.counters[i].area = {}
          }
        }

        return footfall
      }

      $scope.canSubmit = function () {
        if (!$scope.isOpen) {
          return
        }
        if (!$scope.isAllValidArea()) {
          return
        }

        const drawChanged =
          !angular.equals($scope.drawAreas, $scope.orgDrawAreas) && _.isEmpty($scope.editOptions)
        //to remove $$hashKey in $scope.countingAreas
        const ca = angular.toJson($scope.countingAreas)
        const countChanged = !_.isEqual($scope.orgCountingAreas, ca)
        const periodChanged = !_.isEqual($scope.orgPeriods, $scope.periods)
        const labelChanged = !_.isEqual($scope.orgFootfall, $scope.footfall)
        return (
          $scope.formConfig.$valid && (drawChanged || countChanged || periodChanged || labelChanged)
        )
      }

      $scope.submitForm = function () {
        $scope.errorMessage = ""

        //sync current drawarea -> footfall
        $scope.footfall = convertCountingAreas2Footfall($scope.footfall)

        return ApiSrv.FootfallConfigOfCamera()
          .set({ id: $scope.cameraId }, $scope.footfalls)
          .$promise.then(function () {
            $scope.orgDrawAreas = _.cloneDeep($scope.drawAreas)
            $scope.orgCountingAreas = _.cloneDeep($scope.countingAreas)
            $scope.orgFootfall = _.cloneDeep($scope.footfall)
            return $scope.$emit("countingarea_config_complete")
          })
          .catch((error) => {
            console.error(error)
            $scope.errorMessage =
              error === "duplicated name"
                ? Locale.string("msg_error_trackingzone_name_duplicated")
                : Locale.string("msg_error_update")
          })
      }

      var createCountingAreasFromFootfall = function (footfall) {
        const _getBlankCountingArea = (idx) => ({
          index: idx,
          active: false,
          color: "",
          newId: false,
          name: `C${idx + 1}`,
          minTime: 0,
          maxTime: 0,
          objSize: 0,
          maxPeople: 2,
          mode: "simple",
          begin: { zone: [], line: [] },
          end: { zone: [], line: [] },
        })

        const drawAreas = []
        const areas = []

        for (let i = 0, end = MAX_AREA, asc = 0 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
          var endzone, startzone
          areas[i] = _getBlankCountingArea(i)
          var { area } = footfall.counters[i]
          if (
            __guard__(
              __guard__(area != null ? area.begin : undefined, (x1) => x1.zone),
              (x) => x.length
            )
          ) {
            var sdz = convert2DrawZone(
              __guard__(area != null ? area.begin : undefined, (x2) => x2.zone),
              "S:" + area.name,
              area.color
            )
            startzone = $scope.startSrv.transformDrawZone(sdz, i)
            _.merge(areas[i], _.pick(footfall.counters[i], ["active", "name"]))
            _.merge(
              areas[i],
              { mode: "standard" }, // INFO no data means "standard"
              _.pick(footfall.counters[i].area, [
                "minTime",
                "maxTime",
                "objSize",
                "maxPeople",
                "mode",
              ])
            )
          } else {
            startzone = $scope.startSrv.getDefaultDrawZone(i)
          }
          areas[i].modeName = findModeName(areas[i].mode)
          areas[i].objSize1M = convert2ObjSize1M(areas[i].objSize)
          areas[i].color = startzone.color
          areas[i].rgbcolor = `rgb(${areas[i].color})`
          areas[i].begin.drawTagIndex = 2 * i
          areas[i].netVisits = footfall.counters[i].netVisits ?? {
            active: false,
            tuneRatio: 0,
          }
          if (
            __guard__(
              __guard__(area != null ? area.end : undefined, (x4) => x4.zone),
              (x3) => x3.length
            )
          ) {
            var edz = convert2DrawZone(
              __guard__(area != null ? area.end : undefined, (x5) => x5.zone),
              "E:" + area.name,
              area.color
            )
            endzone = $scope.endSrv.transformDrawZone(edz, i)
          } else {
            endzone = $scope.endSrv.getDefaultDrawZone(i)
            areas[i].end.drawTagIndex = 2 * i + 1
          }

          drawAreas[2 * i] = {
            zone: startzone,
            line: __guard__(area != null ? area.begin : undefined, (x6) => x6.line) || [],
          }
          drawAreas[2 * i + 1] = {
            zone: endzone,
            line: __guard__(area != null ? area.end : undefined, (x7) => x7.line) || [],
          }
        }

        $scope.drawAreas = drawAreas
        $scope.countingAreas = areas
        return syncAllAreaName()
      }

      $scope.$watch(
        () =>
          JSON.stringify({
            isOpen: $scope.isOpen,
            selectedItem: $scope.selectedItem != null ? $scope.selectedItem._id : undefined,
          }),
        () => load()
      )

      const setObjectDetectionPoint = function (vcaConfig) {
        const dp = vcaConfig.detectionPoint ?? "unknown"
        $scope.objectDetectionPoint = Locale.string(dp.charAt(0).toUpperCase() + dp.slice(1))
      }

      var load = function () {
        if (!$scope.isOpen) {
          return
        }
        if (!$scope.selectedItem) {
          return
        }

        $scope.cameraId = $scope.selectedItem._id

        showLoading()
        $scope.errorMessage = ""
        $scope.drawAreas = []
        $scope.editOptions = null
        $scope.removedFootfalls = []

        const formElement = angular.element(
          document.getElementsByClassName("countingarea-container main-view")
        )
        const _setSettings = function (ratio, url) {
          $scope.imgWidth = formElement.width()
          $scope.imgHeight = $scope.imgWidth * ratio
          $scope.startSrv = new DrawZone($scope.imgWidth, $scope.imgHeight, "countingarea.begin")
          $scope.endSrv = new DrawZone($scope.imgWidth, $scope.imgHeight, "countingarea.end")

          Promise.all([
            ApiSrv.getCamera({ id: $scope.cameraId }),
            CameraConfigSrv.get({ id: $scope.cameraId, item: "vcaconfig" }).$promise,
          ]).spread(function (camera, vcaConfig) {
            $scope.camera = camera
            $scope.isEdgeFace = camera.functions.use.edgeFace ?? false
            $scope.storeId = camera != null ? camera._storeId : undefined
            setObjectDetectionPoint(vcaConfig)

            loadFootfallCfg($scope.cameraId, camera?._storeId).then(function () {
              $scope.selectedPeriod($scope.periods.length - 1)

              //INFO $scope.snapshotUrl should be changed at last
              $scope.snapshotUrl = url
              createHeatmapPanel()
              hideLoading()
            })
          })
        }

        const image = new Image()
        let snapshotRatio = 9 / 16
        image.onload = function () {
          snapshotRatio = this.height / this.width
          _setSettings(snapshotRatio, $scope.selectedItem.snapshotUrl)
        }
        image.onerror = function () {
          const kErrorImgeUrl = "/assets/images/no-image.svg"
          _setSettings(snapshotRatio, kErrorImgeUrl)
        }
        image.src = $scope.selectedItem.snapshotUrl
      }

      createHeatmapPanel = function () {
        if ($element.find(".dwellmap-container .heatmap-panel").length) {
          $element.find(".dwellmap-container .heatmap-panel").remove()
        }

        $scope.storetimezone = $scope.selectedItem.timezone
        const today = DateTimeSrv.getLocalTimeByTimezone($scope.storetimezone)
        $scope.param = {
          start: moment(today).subtract(3, "day").startOf("day"),
          end: moment(today).subtract(1, "day").endOf("day"),
          sampling: "day",
        }
        $scope.heatmapType = "pass"
        $scope.hideFrame = { scaleBar: true }
        $scope.heatmapWeight = $scope.selectedItem.store.heatmapWeight
        $scope.heatmapStats = $scope.selectedItem.store.stats
        $scope.validTime = true

        return $element
          .find(".dwellmap-container")
          .append(
            $compile(
              [
                "<heatmap-panel",
                '  data-data="camera"',
                '  data-live="false"',
                '  data-param="param"',
                '  data-timezone="storetimezone"',
                '  data-ready="validTime"',
                '  data-hide-frame="hideFrame"',
                '  data-show-data="heatmapType"',
                '  data-heatmap-weight="heatmapWeight"',
                '  data-heatmap-stats="heatmapStats">',
                "</heatmap-panel>",
              ].join("")
            )($scope)
          )
      }
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
