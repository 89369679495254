import config from "./config/environment"
import _ from "lodash"

/**
 * @param dataProvider Account 관련 데이터를 가져오는 함수들을
 * 제공. statusCode가 404일 경우 null을 반환한다.
 * @param {Array|Object} dataProvider.Agency.get(id)로 선언
 * @param {Array|Object} dataProvider.Company.get(id)로 선언
 * @param {Array|Object} dataProvider.Store.get(id)로 선언
 * @param {Array|Object} dataProvider.StoreGroup.get(id)로 선언
 * @param {Array|Object} dataProvider.Account.get(id)로 선언
 * @param {Array|Object} dataProvider.AccountGroup.get(id)로 선언
 * @param {Array|Object} dataProvider.Sensor.get(id)로 선언
 */

export const createAccount = (createDataProvider) => {
  function Account(data) {
    this.data = data
    this.Api = createDataProvider(data)
    for (const k in data) {
      this[k] = data[k]
    }

    return this
  }

  Account.USER_ROLES = config.userRoles.reduce((acc, r, idx) => {
    acc[r] = idx
    return acc
  }, {})

  Account.prototype.clone = function () {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this)
  }

  Account.prototype.hasOrgById = function (id) {
    return this.data.role.orgs.indexOf(id) >= 0
  }

  Account.prototype.hasSubRole = function (role) {
    return Account.USER_ROLES[this.data.role.group] >= Account.USER_ROLES[role]
  }

  Account.prototype.hasExcSubRole = function (role) {
    return Account.USER_ROLES[this.data.role.group] > Account.USER_ROLES[role]
  }

  Account.prototype.isAgency = function () {
    return this.data.role.group == Account.AGENCY
  }

  Account.prototype.isCompany = function () {
    return this.data.role.group == Account.COMPANY
  }

  Account.prototype.isStore = function () {
    return this.data.role.group == Account.STORE
  }

  Account.prototype.isDeveloper = function () {
    return this.data.role.group == Account.DEV_ADMIN
  }
  /**
   * @deprecated use isDeveloper()
   */
  Account.prototype.isDevAdmin = function () {
    return this.isDeveloper()
  }

  Account.prototype.isSysAdmin = function () {
    return this.data.role.group == Account.SPACE
  }

  /**
   * @deprecated use isSysAdmin()
   */
  Account.prototype.isSpace = function () {
    return this.isSysAdmin()
  }

  /**
   * @deprecated use isSysAdmin()
   */
  Account.prototype.isAdmin = function () {
    return this.isSysAdmin()
  }

  Account.prototype.isUser = function () {
    return this.isCompany() || this.isStore()
  }

  Account.prototype.gteCompany = function () {
    return this.hasSubRole(Account.COMPANY)
  }

  Account.prototype.gteAgency = function () {
    return this.hasSubRole(Account.AGENCY)
  }

  Account.prototype.gteSysAdmin = function () {
    return this.hasSubRole(Account.SPACE)
  }

  /**
   * @deprecated use gteSysAdmin()
   */
  Account.prototype.gteSpace = function () {
    return this.gteSysAdmin()
  }

  /**
   * @deprecated use gteSysAdmin()
   */
  Account.prototype.gteAdmin = function () {
    return this.gteSysAdmin()
  }

  Account.prototype.gteDeveloper = function () {
    return this.hasSubRole(Account.DEV_ADMIN)
  }

  /**
   * @deprecated use gteDeveloper()
   */
  Account.prototype.gteDevAdmin = function () {
    return this.gteDeveloper()
  }

  Account.prototype.hasRAgencyId = function (agencyId) {
    if (this.gteSpace()) return Promise.resolve(true)
    return this.Api.Agency.get(agencyId)
      .then((a) => _.isObject(a) && a._id == agencyId)
      .catch(handle404)
  }
  Account.prototype.hasWAgencyId = function (agencyId) {
    if (this.gteSpace()) return Promise.resolve(true)
    if (this.hasSubRole(Account.AGENCY)) return this.hasRAgencyId(agencyId)
    return Promise.resolve(false)
  }

  Account.prototype.hasDAgencyId = function (agencyId) {
    if (this.gteSpace()) return Promise.resolve(true)
    return Promise.resolve(false)
  }

  Account.prototype.hasRCompanyId = function (companyId) {
    if (this.gteSpace()) return Promise.resolve(true)
    return this.Api.Company.get(companyId)
      .then((c) => _.isObject(c) && c._id == companyId)
      .catch(handle404)
  }
  Account.prototype.hasWCompanyId = function (companyId) {
    if (this.gteSpace()) return Promise.resolve(true)
    if (this.hasSubRole(Account.COMPANY)) return this.hasRCompanyId(companyId)
    return Promise.resolve(false)
  }
  Account.prototype.hasDCompanyId = function (companyId) {
    if (this.gteSpace()) return Promise.resolve(true)
    if (this.hasSubRole(Account.AGENCY)) return this.hasRCompanyId(companyId)
    return Promise.resolve(false)
  }

  Account.prototype.hasRStoreId = function (storeId) {
    if (this.gteSpace()) return Promise.resolve(true)
    return this.Api.Store.get(storeId)
      .then((s) => _.isObject(s) && s._id == storeId)
      .catch(handle404)
  }
  Account.prototype.hasWStoreId = function (storeId) {
    if (this.gteSpace()) return Promise.resolve(true)
    if (this.hasSubRole(Account.COMPANY)) return this.hasRStoreId(storeId)
    return Promise.resolve(false)
  }
  Account.prototype.hasDStoreId = function (storeId) {
    return this.hasDStoreId(storeId)
  }

  Account.prototype.hasRStoreGroupId = function (storeGroupId) {
    if (this.gteSpace()) return Promise.resolve(true)
    return this.Api.StoreGroup.get(storeGroupId)
      .then((s) => {
        return _.isObject(s) && s._id == storeGroupId
      })
      .catch(handle404)
  }
  Account.prototype.hasWStoreGroupId = function (storeGroupId) {
    if (this.gteSpace()) return Promise.resolve(true)
    if (this.hasSubRole(Account.COMPANY)) {
      return this.hasRStoreGroupId(storeGroupId)
    }
    return Promise.resolve(false)
  }
  Account.prototype.hasDStoreGroupId = function (storeGroupId) {
    return this.hasWStoreGroupId(storeGroupId)
  }
  Account.prototype.hasRAccountGroupId = function (accountGroupId) {
    if (this.gteSpace()) return Promise.resolve(true)
    return this.Api.AccountGroup.get(accountGroupId)
      .then((s) => {
        return _.isObject(s) && s._id == accountGroupId
      })
      .catch(handle404)
  }
  Account.prototype.hasWAccountGroupId = function (accountGroupId) {
    if (this.gteSpace()) return Promise.resolve(true)
    if (this.hasSubRole(Account.COMPANY)) return this.hasRAccountGroupId(accountGroupId)
    return Promise.resolve(false)
  }
  Account.prototype.hasDAccountGroupId = function (accountGroupId) {
    return this.hasWAccountGroupId(accountGroupId)
  }

  Account.prototype.hasRSensorId = function (sensorId) {
    if (this.gteSpace()) return Promise.resolve(true)
    return this.Api.Sensor.get(sensorId)
      .then((s) => _.isObject(s) && s._id == sensorId)
      .catch(handle404)
  }

  Account.prototype.hasWSensorId = function (sensorId) {
    if (this.gteSpace()) return Promise.resolve(true)
    if (this.hasSubRole(Account.COMPANY)) {
      return this.hasRSensorId(sensorId)
    }
    return Promise.resolve(false)
  }

  Account.prototype.hasDSensorId = function (sensorId) {
    return this.hasWSensor(sensorId)
  }

  Account.prototype.hasRAccount = function (account) {
    if (this.gteSpace()) {
      return Promise.resolve(true)
    } else if (account.gteSpace()) {
      return Promise.resolve(false)
    }
    // 2. 본인이 소유한 Organization에 속하는 모든 계정
    else if (account.isAgency()) {
      return Promise.all(account.role.orgs.map((o) => this.hasRAgencyId(o))).then((tests) =>
        tests.some((t) => t == true)
      )
    } else if (account.isStore() || account.isCompany()) {
      return Promise.all(account.role.orgs.map((o) => this.hasRCompanyId(o))).then((tests) =>
        tests.some((t) => t == true)
      )
    } else {
      return Promise.reject(new Error("Bug: hasRAccount"))
    }
  }

  Account.prototype.hasWAccount = function (account) {
    // 2. 본인 수정 가능
    if (account._id == this._id) return Promise.resolve(true)

    // 3. store계정은 다른 계정을 수정할 수 없다.
    if (this.isStore()) return false

    // 4. 본인 보다 높은 Role은 수정이 불가능하다.
    if (!this.hasSubRole(account.role.group)) return Promise.resolve(false)
    // 1. Read Permission으로부터 상속받는다.
    return this.hasRAccount(account)
  }

  Account.prototype.hasDAccount = function (account) {
    if (account._id != this._id && account.role.group == this.role.group)
      return Promise.resolve(false)
    return this.hasWAccount(account)
  }

  Object.defineProperty(Account, "DEV_ADMIN", {
    value: "dev_admin",
    writable: false,
    enumerable: true,
    configurable: false,
  })
  Object.defineProperty(Account, "SPACE", {
    value: "space",
    writable: false,
    enumerable: true,
    configurable: false,
  })
  Object.defineProperty(Account, "AGENCY", {
    value: "agency",
    writable: false,
    enumerable: true,
    configurable: false,
  })
  Object.defineProperty(Account, "COMPANY", {
    value: "company",
    writable: false,
    enumerable: true,
    configurable: false,
  })
  Object.defineProperty(Account, "STORE", {
    value: "store",
    writable: false,
    enumerable: true,
    configurable: false,
  })

  return Account
}

function handle404(err) {
  if (err?.status == 404) {
    return null
  } else {
    throw err
  }
}

export default createAccount
