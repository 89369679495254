/** @jsx jsx */
import { useRef, useState, useEffect, useMemo, useCallback } from "react"
import { DateTime } from "luxon"
import { css, jsx } from "@emotion/react"
import DatePicker from "./DatePicker"
import PropTypes from "prop-types"
import { OrganizationSelector } from "../../../components/react/OrganizationSelector"
import { List } from "./List"

const START_DATE_KEY = "search:startDate"
const END_DATE_KEY = "search:endDate"

const getDefaultDate = (isoDate, key) => {
  let defaultStartDate
  if (isoDate) {
    let date = DateTime.fromISO(isoDate)
    return date
  } else {
    const localdate = sessionStorage.getItem(key) || localStorage.getItem(key)
    if (localdate) {
      const date = DateTime.fromISO(localdate)
      if (date.isValid) {
        return date
      }
    }
  }

  if (!defaultStartDate || defaultStartDate.isValid) {
    return DateTime.now()
  }
}

export const ActivityLogPage = ({ $state, $stateParams }) => {
  const MAX_RANGE = 7
  const defaultEndDate = useMemo(
    () => getDefaultDate($stateParams.endDate, END_DATE_KEY).endOf("day"),
    [$stateParams.endDate]
  )
  const defaultStartDate = useMemo(() => {
    let startDate = getDefaultDate($stateParams.startDate, START_DATE_KEY)
    if (defaultEndDate.diff(startDate, "day").days > MAX_RANGE) {
      startDate = defaultEndDate.minus({ day: MAX_RANGE }).startOf("day")
    }
    return startDate
  }, [$stateParams.startDate])
  const defaultStoreId = useMemo(() => $stateParams.storeId, [$stateParams.storeId])

  useEffect(() => {
    $state.go($state.current, {
      ...$stateParams,
      storeId: defaultStoreId,
      startDate: defaultStartDate.toISODate(),
      endDate: defaultEndDate.toISODate(),
    })
  }, [])

  const [date, setDate] = useState([defaultStartDate, defaultEndDate])
  const [company, setCompany] = useState()
  const [store, setStore] = useState()
  const [drawOnBh, setDrawOnBh] = useState(true)
  const parent = useRef(null)

  const handleChangeDate = useCallback((date) => {
    setDate(date)
    localStorage.setItem(START_DATE_KEY, date[0].toISODate())
    localStorage.setItem(END_DATE_KEY, date[1].toISODate())
    sessionStorage.setItem(START_DATE_KEY, date[0].toISODate())
    sessionStorage.setItem(END_DATE_KEY, date[1].toISODate())
    const state = {
      ...$stateParams,
      startDate: date[0].toISODate(),
      endDate: date[1].toISODate(),
    }

    $state.go($state.current, state)
  }, [])

  const handleChangeStore = useCallback((company, store) => {
    setCompany(company)
    setStore(store)

    $state.go($state.current, {
      ...$stateParams,
      storeId: store._id,
    })
  }, [])

  const handleDrawOnBh = useCallback((event) => {
    setDrawOnBh(!drawOnBh)
  })

  const renderList = () => {
    if (store && company && date) {
      return (
        <List
          drawOnBh={drawOnBh}
          parent={parent}
          company={company}
          store={store}
          from={date[0]}
          to={date[1]}
        />
      )
    } else {
      return null
    }
  }

  const orgSelectorContainer = css`
    margin: auto auto auto auto;
    text-align: left;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #ccc;
    padding-bottom: 10px;
  `

  return (
    <div className="content">
      <div className="container-default">
        <DatePicker
          noSampling={true}
          maxDate={DateTime.now().endOf("day")}
          maxRange={MAX_RANGE}
          date={date}
          sampling="day"
          onChangeDate={handleChangeDate}
        />
      </div>
      <section className="panel panel-default">
        <div className="header">
          <h4 className="panel-title">
            <span className="glyphicon glyphicon-th" /> Sensor Activity
          </h4>
        </div>
        <div
          ref={parent}
          css={css`
            padding-left: 15px !important;
            padding-right: 15px !important;
          `}
        >
          <div css={orgSelectorContainer}>
            <OrganizationSelector
              css={css`
                display: inline-block;
              `}
              onChangeStore={handleChangeStore}
              defaultStoreId={defaultStoreId}
              selectStore={true}
            />
            <div
              className="pull-right"
              css={css`
                padding-top: 8px;
              `}
            >
              <label className="ui-checkbox ">
                <input type="checkbox" checked={drawOnBh} onChange={handleDrawOnBh} />
                <span>Draw on BusinessHour</span>
              </label>
            </div>
          </div>
          {renderList()}
        </div>
      </section>
    </div>
  )
}

ActivityLogPage.propTypes = {
  $state: PropTypes.any.isRequired,
  $stateParams: PropTypes.object.isRequired,
}

export default ActivityLogPage
