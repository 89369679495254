/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("ngMouseWheelUp", ($parse) => ({
    link(scope, element, attrs) {
      return element.bind("DOMMouseScroll mousewheel onmousewheel", function (event) {
        // cross-browser wheel delta
        event = window.event || event // old IE support
        const delta = Math.max(-1, Math.min(1, event.wheelDelta || -event.detail))
        const mouseWheelUpFunc = $parse(attrs.ngMouseWheelUp)

        if (delta > 0) {
          scope.$apply(() => mouseWheelUpFunc(scope, { $event: event }))
        }

        // for IE
        event.returnValue = false
        // for Chrome and Firefox
        if (event.preventDefault) {
          return event.preventDefault()
        }
      })
    },
  }))
  .directive("ngMouseWheelDown", ($parse) => ({
    link(scope, element, attrs) {
      return element.bind("DOMMouseScroll mousewheel onmousewheel", function (event) {
        // cross-browser wheel delta
        event = window.event || event // old IE support
        const delta = Math.max(-1, Math.min(1, event.wheelDelta || -event.detail))
        const mouseWheelDownFunc = $parse(attrs.ngMouseWheelDown)

        if (delta < 0) {
          scope.$apply(() => mouseWheelDownFunc(scope, { $event: event }))
        }

        // for IE
        event.returnValue = false
        // for Chrome and Firefox
        if (event.preventDefault) {
          return event.preventDefault()
        }
      })
    },
  }))
