"use strict"

import angular from "angular"

angular.module("uCountitUiApp").directive("icalendarList", (ApiSrv) => {
  return {
    replace: true,
    restrict: "E",
    scope: {
      type: "@", // holiday, workday
      orgType: "@", // store, company
      orgId: "=",
      icalendars: "=",
      icalendarsOfCompany: "=",
      icalendarsOfGlobal: "=",
      deletedIcalendars: "=",
    },
    templateUrl: "components/icalendar/icalendar-list.html",
    link: (scope, element, attrs) => {
      scope.addIcalendarOfOrg = (icalendar) => {
        scope.icalendars.push({
          _orgId: scope.orgId,
          name: icalendar.name,
          url: icalendar.url,
          autoSync: true,
          type: scope.type,
          crc: icalendar.crc,
          data: icalendar.data,
        })
      }

      scope.addNewIcalendar = () => {
        scope.icalendars.push({
          _orgId: scope.orgId,
          name: "",
          url: "",
          autoSync: true,
          type: scope.type,
        })
      }

      scope.deleteIcalendar = (idx) => {
        const deleteItem = scope.icalendars.splice(idx, 1)[0]
        if (!deleteItem || !deleteItem._id) return

        scope.deletedIcalendars.push(deleteItem)
      }
    },
  }
})
