/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular.module("uCountitUiApp").config(function ($stateProvider) {
  $stateProvider.state("app.footfall", {
    url: "/footfall/:orgType/:id",
    templateUrl($stateParams) {
      switch ($stateParams.orgType) {
        case "store":
          return "app/content/footfall/storedetail.html"
        case "sensor":
          return "app/camera/detail/cameradetail.html"
        default:
          return "app/store/comparison/storecomparison.html"
      }
    },

    controllerProvider($stateParams) {
      switch ($stateParams.orgType) {
        case "store":
          return "StoreDetailCtrl"
        case "sensor":
          return "CameraDetailCtrl"
        default:
          return "StoreComparisonCtrl"
      }
    },
  })

  $stateProvider.state("app.genderAge", {
    url: "/gender-age/:orgType/:id",
    templateUrl: "app/content/gender-age/gender-age.html",
    controller: "GenderAgeSearchCtrl",
  })

  $stateProvider.state("app.queue", {
    url: "/queue/:orgType/:id",
    templateUrl($stateParams) {
      const templateUrl =
        $stateParams.orgType === "store"
          ? "app/content/queue/storequeue.html"
          : "app/content/store-only.html"

      return templateUrl
    },
    controllerProvider($stateParams) {
      const ctrlName = $stateParams.orgType === "store" ? "StoreQueueCtrl" : null
      return ctrlName
    },
  })

  $stateProvider.state("app.sales", {
    url: "/sales/:orgType/:id",
    templateUrl($stateParams) {
      const templateUrl = ["store", "storegroup", "company"].includes($stateParams.orgType)
        ? "app/content/sales/storesales.html"
        : "app/content/store-only.html"

      return templateUrl
    },
    controllerProvider($stateParams) {
      const ctrlName = ["store", "storegroup", "company"].includes($stateParams.orgType)
        ? "StoreSalesCtrl"
        : null
      return ctrlName
    },
  })

  return $stateProvider.state(
    "app.heatmap",
    {
      url: "/heatmap/:orgType/:id",
      templateUrl($stateParams) {
        if (["store", "sensor"].includes($stateParams.orgType)) {
          return "app/content/heatmap/storeheatmap.html"
        } else {
          return "app/content/store-only.html"
        }
      },
      controllerProvider($stateParams) {
        if (["store", "sensor"].includes($stateParams.orgType)) {
          return "StoreHeatmapCtrl"
        } else {
          return null
        }
      },
    },

    $stateProvider.state("app.tracking-tree", {
      url: "/tracking-tree/:orgType/:id",
      templateUrl($stateParams) {
        if (["sensor"].includes($stateParams.orgType)) {
          return "app/content/tracking-tree/trackingtree.html"
        } else {
          return "app/content/sensor-only.html"
        }
      },
      controller: "TrackingTreeCtrl",
    }),

    $stateProvider.state("app.path-tree", {
      url: "/path-tree/:orgType/:id",
      templateUrl($stateParams) {
        if (["store"].includes($stateParams.orgType)) {
          return "app/content/path-tree/path-tree.html"
        } else {
          return "app/content/store-only.html"
        }
      },
      controller: "PathTreeCtrl",
    }),

    $stateProvider.state("app.zonetraffic", {
      url: "/zonetraffic/:orgType/:id",
      templateUrl($stateParams) {
        if (["store", "sensor"].includes($stateParams.orgType)) {
          return "app/content/zonetraffic/zonetraffic.html"
        } else {
          return "app/content/store-only.html"
        }
      },
      controllerProvider($stateParams) {
        if (["store", "sensor"].includes($stateParams.orgType)) {
          return "ZoneTrafficCtrl"
        } else {
          return null
        }
      },
    }),

    $stateProvider.state("app.occupancy", {
      url: "/occupancy/:orgType/:id",
      templateUrl($stateParams) {
        if (["store", "sensor"].includes($stateParams.orgType)) {
          return "app/content/occupancy/occupancy.html"
        } else {
          return "app/content/store-only.html"
        }
      },
      controllerProvider($stateParams) {
        if (["store", "sensor"].includes($stateParams.orgType)) {
          return "OccupancyCtrl"
        } else {
          return null
        }
      },
    }),

    $stateProvider.state("app.directionmap", {
      url: "/directionmap/:orgType/:id",
      templateUrl: "app/content/directionmap/directionmap.html",
      controller: "DirectionmapCtrl",
    })
  )
})
