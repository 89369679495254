/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("bottomInfo", () => ({
    restrict: "E",

    template: [
      '<div class="bottom-info">',
      "<div>",
      "<div>{{deployVersion}}</div>",
      "<div>",
      '<button class="link-text" type="button" data-ng-show="termsPath" data-ng-click="showTerms()" data-i18n="Terms"></button>',
      `<span data-ng-show="termsPath && privacyPath">  |  <span> \
<button class="link-text" type="button" data-ng-show="privacyPath" data-ng-click="showPrivacy()" data-i18n="Privacy Policy"></button>`,
      "</div>",
      "<div>{{copyrightText}}</div>",
      "</div>",
      "</div>",
    ].join(""),

    scope: {
      deployVersion: "=",
      copyrightText: "=",
      termsPath: "=",
      privacyPath: "=",
    },

    controller($scope, Terms) {
      $scope.showTerms = function () {
        if (!$scope.termsPath) {
          return
        }

        const options = {
          type: "terms",
          path: $scope.termsPath,
        }
        return Terms.show(options)
      }
      return ($scope.showPrivacy = function () {
        if (!$scope.privacyPath) {
          return
        }

        const options = {
          type: "privacy",
          path: $scope.privacyPath,
        }
        return Terms.show(options)
      })
    },
  }))
