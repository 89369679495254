/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("tableView", ($compile, $timeout) => ({
    templateUrl: "components/tableview/tableview.html",
    restrict: "A",

    scope: {
      data: "=",
    },

    link(scope, element, attrs) {
      let sampling = "day"
      const start = function () {
        sampling = scope.data[0]

        const lastDate = scope.data[0].endDate
        const timeSeries = _.map(scope.data[0].data, function (row) {
          switch (sampling) {
            case "hour":
              return row[0].format("lll")
            case "day":
              return row[0].format("ll")
            case "week":
            case "month":
              var end = moment(row[0]).endOf(sampling)
              if (end > lastDate) {
                end = moment(lastDate).subtract(1, "days")
              }
              return `${row[0].format("LL")} - ${end.format("LL")}`
          }
        })

        return (scope.timeSeries = timeSeries)
      }

      scope.$watch("data", function (val) {
        if (!val) {
          return
        }
        return start()
      })

      return (scope.tableExport = (type) => scope.$broadcast("export-" + type))
    },
  }))
  .directive("onFinished", ($timeout) => ({
    restrict: "A",

    link(scope, element, attr) {
      if (scope.$last === true) {
        return scope.$evalAsync(attr.onFinished)
      }
    },
  }))
