"use strict"

import Promise from "bluebird"
import angular from "angular"
import { createFormClass } from "./form"

const S3FileApiEditCtrl = ($scope, $http, $stateParams, RestApi) => {
  const Form = createFormClass($http)

  Promise.all([
    RestApi.Company.index(),
    RestApi.Store.index(),
    $http.get(`/api/1.0/s3fileapi/${$stateParams.id}`).then((res) => res.data),
  ]).spread((companies, stores, apikey) => {
    const form = new Form(companies, stores, apikey)
    const submitFunc = form.submit.bind(form)
    form.submit = (event) => submitFunc(event).then(() => $scope.$parent.reload(false))
    $scope.form = form
  })
}

angular.module("uCountitUiApp").controller("S3FileApiEditCtrl", S3FileApiEditCtrl)
