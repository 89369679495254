/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .controller("NotificationCtrl", function ($scope, ApiSrv, Locale) {
    class NotificationForm {
      constructor(address) {
        this.address = address
        this.active = false
        this.name = ""
        this.value = ""
        this.load = function () {
          return ApiSrv.ServerGlobalConfig(this.address).then((res) => {
            this.active = res.active
            this.name = res.name
            return (this.value = res.value)
          })
        }

        this.load()
      }

      update(callback) {
        return ApiSrv.ServerGlobalConfig(this.address, this).then(
          (res) =>
            callback({
              isError: false,
              msg: "msg_success_update",
            }),
          (err) =>
            callback({
              isError: true,
              msg: "msg_error_update",
              error: err,
            })
        )
      }
    }

    const init = () => ($scope.signinForm = new NotificationForm("notification/signin"))

    $scope.updateSignin = function () {
      $scope.isResultError = null
      $scope.executionResultMsg = ""

      return $scope.signinForm.update(function (result) {
        $scope.isResultError = result.isError
        $scope.executionResultMsg = Locale.string(result.msg)

        if (result.error) {
          return console.error(result.error.data)
        }
      })
    }

    return init()
  })
