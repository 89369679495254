"use strict"
import { EnvDefault, getConfigV2 } from "common-library/ucountit-env"
import { Optional } from "./Environment"
import path from "path"
import retailtrend from "./retailtrend"
import development from "./development"
import localgrey from "./localgrey"
import aging from "./aging"
import local from "./local"
// @ts-ignore
import my from "./my"

const defaultEnv: EnvDefault<Optional> = {
  env: process.env.NODE_ENV || "development",
  root: path.join(__dirname, "../../.."),
  port: 19500,
  bsPort: 3000,

  // List of user roles
  userRoles: ["store", "company", "agency", "space", "dev_admin"],

  redis: {
    host: "localhost",
    port: "6379",
  },

  uploadPath: "/tmp/ucountit.uploadtmp",

  emblem: {
    maxWidth: 128,
    maxHeight: 128,
  },

  logo: {
    maxWidth: 300,
    maxHeight: 60,
  },

  fixedSnapshot: {
    maxWidth: 1280,
    maxHeight: 960,
  },

  heatmap: {
    uri: "/uapi-cgi/reporthm.cgi?reportfmt=csv&table=0",
    basePath: "/tmp/heatmap/",
    timeout: 30,
    cacheTime: 3600,
  },

  thumbnail: {
    uri: "/nvc-cgi/operator/snapshot.fcgi",
    basePath: "/tmp/thumbnail/",
    defaultImagePath: "dist/public/assets/images/thumbnail",
    cacheTime: 3600,
    size: {
      tiny: { width: 35, height: 35 },
      small: { width: 60, height: 35 },
      medium: { width: 120, height: 68 },
      large: { width: 160, height: 90 },
      xlarge: { width: 320, height: 180 },
    },
  },
  isLocalgrey: false,
}

const stageEnvs = {
  local,
  development,
  aging,
  retailtrend,
  localgrey,
}

const config = getConfigV2(stageEnvs, defaultEnv, my)

export default config
