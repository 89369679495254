/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

import { kStandardLabels } from "../../../components/counter-label"
import env from "../../../../config/environment"

angular
  .module("uCountitUiApp")

  .factory("StoreAdminSrv", ($rootScope, $resource) =>
    $resource(
      $rootScope.globalConfig.apipath + "/store/:id",
      { id: "@id" },
      {
        update: {
          method: "PUT",
        },
      }
    )
  )
  .factory("StoreAccessSrv", ($rootScope, $resource) =>
    $resource(
      `${$rootScope.globalConfig.apipath}/store/:storeId/access/:item/:id`,
      {
        storeId: "@storeId",
        item: "@item",
        id: "@id",
      },
      {
        get: {
          method: "GET",
        },

        add: {
          method: "POST",
          isArray: true,
        },

        setPermission: {
          method: "PUT",
        },

        remove: {
          method: "DELETE",
        },
      }
    )
  )
  .factory("SeedCounter", function (Locale) {
    const getStandardLabelsSeedCounterList = function () {
      return kStandardLabels.map((label) => ({
        name: Locale.string(label.i18nKey),
        value: label.seedCounter,
        group: "",
      }))
    }

    const getPathTreeSeedCounterList = function (usePathTree) {
      if (!usePathTree || env.isLocalgrey) {
        return []
      }
      return [
        { name: "PathTree - Guest", value: "PathTree Guest", group: "  " },
        { name: "PathTree - All", value: "PathTree All", group: "  " },
      ]
    }

    const getList = function (labels, usePathTree = false) {
      const labelList = []

      _.forEach(labels, function (label) {
        if (label._id) {
          return labelList.push({
            name: label.name,
            value: label._id,
          })
        }
      })

      return [{ name: Locale.string("None"), value: "None", group: " " }]
        .concat(getPathTreeSeedCounterList(usePathTree))
        .concat(getStandardLabelsSeedCounterList())
        .concat(labelList)
    }

    return {
      getList,
    }
  })
