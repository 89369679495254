/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")
  .constant("DefaultGroupName", "All Stores")
  .factory("UtilSrv", function (SamplingSrv, Locale, DefaultGroupName) {
    const rgbToHex = function (rgb) {
      const rgbArray = rgb.split(",")
      return (
        "#" +
        ((1 << 24) + (Number(rgbArray[0]) << 16) + (Number(rgbArray[1]) << 8) + Number(rgbArray[2]))
          .toString(16)
          .slice(1)
      )
    }

    const hexToRGB = function (hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
      hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      if (result) {
        return (
          parseInt(result[1], 16) + "," + parseInt(result[2], 16) + "," + parseInt(result[3], 16)
        )
      } else {
        return null
      }
    }

    const smoothColor = function (srgb) {
      if (!srgb) {
        return
      }
      const darkerColor = (rgb) => rgb.map((x) => Math.floor(x * 0.8))

      const enwhiteColor = (rgb) => rgb.map((x) => x + Math.floor(((255 - x) * 1) / 2))

      const stringtorgb = (str) => str.split(",").map((x) => x)

      const rgb = stringtorgb(srgb)
      const nrgb = enwhiteColor(darkerColor(rgb))
      return nrgb.join(",")
    }

    const getComparisonResult = function (oldValue, newValue, limit) {
      if (limit == null) {
        limit = 999
      }
      if (!oldValue && !newValue) {
        return { comparisonRatio: "-", showable: false }
      }
      const ratio = ((newValue - oldValue) / oldValue) * 100
      const showable = !(ratio === Infinity || isNaN(ratio || Math.abs(ratio) > limit))
      return {
        showable,
        comparisonRatio: showable
          ? `${ratio > 0 ? "+" : ""}${ratio.toFixed(Math.abs(ratio) < 10 ? 1 : undefined)}`
          : "NOT COMPARABLE",
      }
    }

    const parseTimeRange = function (timeType, theDay) {
      let to
      const regExp = /(\d+)(\D)/
      const parser = regExp.exec(timeType)

      if (parser == null) {
        console.error("not available")
        return
      }

      const num = parser[1] - 1
      const unit = parser[2]
      const sampling = SamplingSrv.getSamplingKey(unit)

      if (sampling === "hour") {
        to = moment(theDay).endOf("day")
      } else {
        to = moment(theDay).endOf(sampling)
      }

      const from = moment(to).subtract(num, sampling).startOf(sampling)

      return {
        from,
        to,
        sampling,
        diffNum: num,
      }
    }

    const generateData = function (
      start,
      end,
      list,
      sampling,
      dateFieldName,
      countFieldName,
      timeFormat,
      sortByKey
    ) {
      let from, to
      if (!list) {
        return []
      }
      const dateFormat = sampling === "hour" ? "YYYYMMDDHHmm" : "YYYYMMDD0000"
      if (sortByKey) {
        list = _.sortBy(list, sortByKey)
      }

      if (timeFormat) {
        from = moment.utc(start, timeFormat)
        to = moment.utc(end, timeFormat)
      } else {
        from = moment.utc(start)
        to = moment.utc(end)
      }

      const dataLength = to.diff(from, sampling)

      const dataSet = __range__(0, dataLength, false).map(function (i) {
        const curDate = moment.utc(from).add(i, sampling)
        const formatCurDate = moment(curDate).format(dateFormat)
        const findIdx = _.findIndex(list, function (o) {
          const dateTime = moment.utc(o[dateFieldName]).format(dateFormat)
          return dateTime === formatCurDate
        })

        const data = {}
        data.date = curDate
        data.count = findIdx > -1 ? list[findIdx][countFieldName] : null
        data.noData = findIdx < 0 ? true : false

        return data
      })

      return dataSet
    }

    const isDefaultStoreGroup = (name) => name === DefaultGroupName

    const getDefaultStoreGroupName = function (translate) {
      if (translate == null) {
        translate = true
      }
      if (translate) {
        return Locale.string(DefaultGroupName)
      } else {
        return DefaultGroupName
      }
    }

    const getDisplayStoreGroupName = function (name) {
      if (isDefaultStoreGroup(name)) {
        return getDefaultStoreGroupName()
      } else {
        return name
      }
    }

    return {
      rgbToHex,
      hexToRGB,
      smoothColor,
      getComparisonResult,
      parseTimeRange,
      generateData,
      getDisplayStoreGroupName,
      isDefaultStoreGroup,
      getDefaultStoreGroupName,
    }
  })

function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
