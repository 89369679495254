/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")

  .filter(
    "paramGroupFilter",
    () =>
      function (params, group) {
        if (!params) {
          return
        }
        const result = []
        _.forEach(params, function (param) {
          if (param.key.split(".")[0] === group) {
            return result.push(param)
          }
        })

        return result
      }
  )
