/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")
  .directive(
    "ucDatePicker",
    function ($compile, $document, $parse, dateTimeConfig, datePickerUtils, Locale) {
      const body = $document.find("body")

      const getPickerTemplate = function (
        attrs,
        id,
        modelName,
        view,
        minView,
        maxView,
        minDate,
        maxDate,
        timezone
      ) {
        attrs = angular.extend(attrs, {
          ngModel: modelName,
          view: view || "date",
          minView: minView || false,
          maxView: maxView || false,
          minDate: minDate && moment.isMoment(minDate) ? minDate.format() : false,
          maxDate: maxDate && moment.isMoment(maxDate) ? maxDate.format() : false,
          timezone: "UTC",
        })
        return dateTimeConfig.template(attrs, id)
      }

      const randomPickerId = () => `picker${Math.random().toString().substr(2)}`

      return {
        restrict: "E",
        templateUrl: "components/picker/uc-date-picker.html",
        require: "ngModel",
        scope: {
          ngModel: "=",
          options: "=",
          change: "&changeDate",
          gotoDate: "=",
          isGotoDate: "=?",
        },
        replace: true,
        link(scope, element, attrs, ngModel) {
          const pickerId = randomPickerId()
          let picker = null
          let container = null
          const views = $parse(attrs.views)(scope) || dateTimeConfig.views.concat()
          let view = (scope.options != null ? scope.options.view : undefined) || views[0]
          let template = null
          let dateChange = null
          const position = attrs.position || dateTimeConfig.position
          let displayFormat = "ll"
          let sampling = "day"
          scope.isSearchPage = attrs.isSearchPage ? attrs.isSearchPage : false
          scope.tooltip = {
            first: Locale.string("Start Date of Data"),
            last: Locale.string("End Date of Data"),
          }
          scope.isGotoDate = scope.isGotoDate != null ? scope.isGotoDate : false

          if (angular.isDefined(attrs.dateChange)) {
            dateChange = datePickerUtils.findFunction(scope, attrs.dateChange)
          }

          const getDateStr = (date) => Locale.dateTime(date, displayFormat)

          const getTemplate = function () {
            const { options } = scope
            const pickerTemplate = getPickerTemplate(
              attrs,
              pickerId,
              "selectedDate",
              options.view,
              options.minView,
              options.maxView,
              scope.start,
              options.maxDate,
              options.timezone
            )
            return (template = [
              '<div class="date-picker-popup single">',
              '<div class="picker">',
              pickerTemplate,
              "</div>",
              '<div class="side">',
              '<button class="btn btn-primary btn-sm" data-ng-click="apply()">',
              '<span data-i18n="Apply"></span>',
              "</button>",
              '<button class="btn btn-default btn-sm" data-ng-click="cancel()">',
              '<span data-i18n="Cancel"></span>',
              "</button>",
              "</div>",
              "</div>",
            ].join(""))
          }

          const clear = function () {
            if (picker) {
              picker.remove()
              picker = null
            }

            if (container) {
              container.remove()
              return (container = null)
            }
          }

          const showPicker = function () {
            let height, pos
            if (picker) {
              return
            }
            picker = $compile(template)(scope)

            if (!shownOnce) {
              scope.$on("setDate", function (event, date, view) {
                if (dateChange) {
                  return dateChange(date)
                }
              })

              scope.$on("hidePicker", clear)

              scope.$on("$destroy", clear)

              var shownOnce = true
            }

            if (position === "absolute") {
              pos = element[0].getBoundingClientRect()
              height = pos.height || element[0].offsetHeight
              picker.css({
                top: `${pos.top + height}px`,
                left: `${pos.left}px`,
                display: "block",
                position,
              })
              body.append(picker)
            } else {
              pos = element[0].getBoundingClientRect()
              height = pos.height || element[0].offsetHeight
              container = angular.element("<div date-picker-wrapper></div>")
              element[0].parentElement.insertBefore(container[0], element[0])

              container.css({
                // if you show date picker
                // top: "#{element[0].offsetHeight-20}px"
                // display: 'block'
              })

              container.append(picker)
            }

            return picker.bind("mousedown", (evt) => evt.preventDefault())
          }

          scope.openDatePicker = function () {
            var isOpen = !isOpen
            if (isOpen) {
              showPicker()
            } else {
              clear()
            }
          }

          scope.moveHandler = function (direction) {
            const dt = angular.copy(scope.selectedDate)
            if (direction === "prev") {
              let beforeDate = moment(dt).subtract(1, sampling).endOf(sampling)
              if (scope.options.minDate && beforeDate < scope.options.minDate) {
                beforeDate = scope.options.minDate
              }
              scope.selectedDate = beforeDate
            } else {
              let afterDate = moment(dt).add(1, sampling).endOf(sampling)
              if (scope.options.maxDate && afterDate > scope.options.maxDate) {
                afterDate = scope.options.maxDate
              }
              scope.selectedDate = afterDate
            }
            scope.ngModel = scope.selectedDate

            if (scope.change) {
              return scope.change({ date: scope.selectedDate })
            }
          }

          scope.apply = function () {
            scope.ngModel = scope.selectedDate

            if (scope.change) {
              scope.change({ date: scope.selectedDate })
            }
            return clear()
          }

          scope.cancel = function () {
            scope.selectedDate = scope.ngModel
            return clear()
          }

          getTemplate()

          scope.$watch("ngModel", function (newValue) {
            if (newValue) {
              scope.selectedDate = newValue
              return getTemplate()
            }
          })

          scope.$watch("selectedDate", (newValue) => (scope.dateStr = getDateStr(newValue)))

          scope.$watch("options.sampling", function (newSampling) {
            if (newSampling) {
              sampling = newSampling
              if (sampling === "month") {
                view = "month"
                displayFormat = "month"
              } else {
                view = "date"
                displayFormat = "fullDate"
              }
              scope.options.view = view
              scope.options.minView = view
              scope.dateStr = getDateStr(scope.selectedDate)
              return getTemplate()
            }
          })

          return
        },
      }
    }
  )
