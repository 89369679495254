/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory("ChartOptionSrv", function (Locale, $filter) {
    const color = {
      primary: "#5B90BF",
      success: "#A3BE8C",
      info: "#7FABD2",
      infoAlt: "#B48EAD",
      warning: "#EBCB8B",
      danger: "#BF616A",
      gray: "#DCDCDC",
    }

    const lineChart = {
      series: {
        lines: {
          show: true,
          fill: false,
          fillColor: {
            colors: [{ opacity: 0.1 }, { opacity: 0.8 }],
          },
        },
        points: {
          show: true,
          lineWidth: 2,
          fill: true,
          fillColor: "#ffffff",
          symbol: "circle",
          radius: 4,
        },
        shadowSize: 1,
      },
      grid: {
        hoverable: true,
        clickable: true,
        tickColor: "#f9f9f9",
        borderWidth: 1,
        borderColor: "#eeeeee",
      },
      colors: [color.success, color.info, color.warning, color.danger],
      tooltip: {
        show: true,
        defaultTheme: false,
      },
      yaxes: [{ position: "left", autoscaleMargin: 0.1 }],
      xaxis: {
        show: true,
        minTickSize: 1,
        tickDecimals: 0,
      },
    }

    const barChart = {
      series: {
        stack: false,
        bars: {
          show: true,
          fill: 1,
          barWidth: 0.1,
          lineWidth: 1,
          align: "center",
          horizontal: false,
        },
      },
      grid: {
        hoverable: true,
        borderWidth: 1,
        borderColor: "#eeeeee",
      },
      tooltip: {
        show: true,
        defaultTheme: false,
      },
      colors: [color.success, color.info, color.warning, color.danger],
      yaxes: [{ autoscaleMargin: 0.1 }],
    }

    const weeklyLineChart = _.merge({}, lineChart, {
      xaxis: {
        ticks: [
          [1, "Mon"],
          [2, "Tue"],
          [3, "Wed"],
          [4, "Thu"],
          [5, "Fri"],
          [6, "Sat"],
          [7, "Sun"],
        ],
      },
      yaxis: {
        min: 0,
        tickDecimals: 0,
      },
    })

    const dailyLineChart = _.merge({}, lineChart, {
      xaxis: {
        tickFormatter(val) {
          return `${Locale.dateTime(moment({ hour: val % 24 }), "hour")}`
        },
      },
      yaxis: {
        min: 0,
        tickDecimals: 0,
      },
    })

    const daysLineChart = _.merge({}, lineChart, {
      yaxis: {
        min: 0,
        tickDecimals: 0,
      },
    })

    const sevenDaysLineChart = _.merge({}, lineChart, {
      yaxis: {
        min: 0,
        tickDecimals: 0,
      },
    })

    const sevenDaysBarChart = _.merge({}, barChart, {
      xaxis: {
        mode: "time",
        timeformat: "%b %e",
        minTickSize: [1, "day"],
        tickLength: 0,
      },
      yaxis: {
        min: 0,
        tickDecimals: 0,
      },
    })

    const contentsBarChart = _.merge({}, barChart, {
      xaxis: {
        show: true,
        mixTeckSize: 1,
        tickDecimals: 0,
      },
      yaxis: {
        min: 0,
        tickDecimals: 0,
      },
    })

    const pipChart = {
      series: {
        pie: {
          show: true,
        },
      },
      legend: {
        show: true,
      },
      grid: {
        hoverable: true,
        clickable: true,
      },
      colors: [color.success, color.info, color.warning, color.danger],
      tooltip: true,
      tooltipOpts: {
        content: "%p.0%, %s",
        defaultTheme: false,
      },
    }

    const weeklyPipChart = _.merge({}, pipChart, {
      series: {
        pie: {
          innerRadius: 0.4,
        },
      },
    })

    const chartColorList = ["#4997D0", "#DEB887", "#A3BE8C", "#967117", "#E3DAC9"]
    const lineChartOption = {
      xaxis: {
        mode: "time",
        timeformat: "%b %e",
        minTickSize: [1, "day"],
        tickLength: 0,
      },
      yaxis: {
        minTickSize: 10,
        tickFormatter(val) {
          return Number(val)
        },
      },
      lines: {
        show: true,
        lineWidth: 3,
        fillColor: {
          colors: [{ opacity: 0.2 }, { opacity: 0.8 }],
        },
      },
      shadowSize: 1,
      //    'legend':
      //      'container': angular.element '#legendContainer', $element
      //      'noColumns': 0
      grid: {
        hoverable: true,
        autoHighlight: false,
        borderWidth: 1,
        borderColor: "#DDD",
      },
      points: {
        show: true,
        radius: 2,
      },
      colors: chartColorList,
      highlightColor: "#AAA",
    }

    const miniLineChartOption = {
      xaxis: {
        show: false,
      },
      yaxis: {
        show: false,
        autoscaleMargin: 0,
      },
      lines: {
        show: true,
        lineWidth: 1,
        fill: true,
        fillColor: {
          colors: [{ opacity: 0.2 }, { opacity: 0.8 }],
        },
      },
      shadowSize: 0,
      legend: {
        show: false,
      },
      grid: {
        borderWidth: 0,
      },
      colors: chartColorList,
    }
    const barChartOption = {
      series: {
        stack: false,
        bars: {
          show: true,
          fill: 1,
          lineWidth: 1,
          horizontal: false,
        },
      },
      grid: {
        hoverable: true,
        borderWidth: { top: 0, right: 0, bottom: 2, left: 0 },
        borderColor: "#c3c3c3",
      },
      tooltip: {
        show: true,
        defaultTheme: false,
      },
      xaxis: {
        mode: "time",
        tickLength: 0,
      },
      legend: {
        show: false,
      },
      yaxis: {
        min: 0,
        tickDecimals: 0,
        autoscaleMargin: 0.1,
      },
    }

    const contentChartOption = {
      grid: {
        hoverable: true,
        borderWidth: { top: 0, right: 0, bottom: 2, left: 0 },
        borderColor: "#c3c3c3",
      },
      tooltip: {
        show: true,
        defaultTheme: false,
      },
      xaxis: {
        mode: "time",
        tickLength: 0,
      },
      legend: {
        show: false,
      },
      yaxis: {
        min: 0,
        tickDecimals: 0,
        autoscaleMargin: 0,
        tickFormatter(val) {
          return $filter("number")(val, 0)
        },
      },
    }

    const contentLineChartOption = {
      series: {
        lines: {
          show: true,
        },
        points: {
          radius: 3,
          fill: true,
          show: true,
        },
      },
      tooltip: {
        show: true,
        defaultTheme: false,
      },
      grid: {
        hoverable: true,
        autoHighlight: false,
        borderWidth: { top: 0, right: 0, bottom: 2, left: 0 },
        borderColor: "#c3c3c3",
      },
      legend: {
        show: false,
      },
      shadowSize: 0,
      xaxis: {
        mode: "time",
        tickLength: 0,
      },
      yaxis: {
        min: 0,
        minTickSize: 1,
        tickFormatter(val) {
          return $filter("number")(val, 0)
        },
      },
    }

    const reportBarChartOption = _.merge({}, barChartOption, {
      series: {
        bars: {
          lineWidth: 0.5,
          barWidth: 0.2,
        },
      },
      legend: {
        show: true,
        noColumns: 3,
      },
      xaxis: {
        ticks: [],
      },
      yaxis: {
        autoscaleMargin: 0,
        tickFormatter(val) {
          return $filter("number")(val, 0)
        },
      },
    })

    const setBarChartMargin = function (dataLength) {
      switch (dataLength) {
        case 1:
          return 3
        case 2:
          return 0.5
        case 3:
          return 0.05
        default:
          return null
      }
    }

    const createCustomMarkingsFunc = (bs) =>
      function (axis) {
        if (bs == null) {
          return []
        }

        const markings = []
        const datetime = moment.utc(axis.xaxis.min)
        const maxTime = moment.utc(axis.xaxis.max)

        while (datetime.isSameOrBefore(maxTime)) {
          if (!bs.isOpen(datetime, true)) {
            markings.push({
              xaxis: {
                from: datetime.valueOf(),
                to: datetime.clone().add(1, "hour").valueOf(),
              },
              color: "#ededed",
            })
          }

          datetime.add(1, "hour")
        }
        return markings
      }

    return {
      weeklyLineChart,
      dailyLineChart,
      sevenDaysLineChart,
      sevenDaysBarChart,
      contentsBarChart,
      weeklyPipChart,
      daysLineChart,

      lineChartOption,
      miniLineChartOption,
      chartColorList,
      barChartOption,
      reportBarChartOption,
      contentChartOption,
      contentLineChartOption,

      setBarChartMargin,
      createCustomMarkingsFunc,
    }
  })
