/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("tableExport", () => ({
    restrict: "A",

    link(scope, element, attrs) {
      scope.$on("export-pdf", () =>
        element.tableExport({
          type: "pdf",
          escape: "false",
          pdfFontSize: 8,
        })
      )

      scope.$on("export-excel", () =>
        element.tableExport({
          type: "excel",
          escape: "false",
        })
      )

      scope.$on("export-doc", () =>
        element.tableExport({
          type: "doc",
          escape: "false",
        })
      )

      return scope.$on("export-csv", () =>
        element.tableExport({
          type: "csv",
          escape: "false",
        })
      )
    },
  }))
