/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import Promise from "bluebird"
import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("liveView", ($parse) => ({
    restrict: "EA",
    template: '<img ng-src="{{snapshotUrl}}" class="screen">',

    scope: {
      src: "=",
      play: "=",
      playEvent: "=",
    },

    controller($scope, $rootScope, $timeout, Auth, ApiSrv, HeaderSrv) {
      return Promise.all([Auth.fetchCurrentAccount(), HeaderSrv.fetchCurrentCompany()]).spread(
        function (me, company) {
          const errorImage = "/assets/images/no-image.svg"
          const blockedImage = "/assets/images/blocked-image.svg"
          const maxErrCount = 10
          $scope.alive = true
          const maxLiveTime = 600 // seconds

          const Player = function (snapshotUrl) {
            if (company.isBlockedSnapshot(me)) {
              this.snapshotUrl = blockedImage
            } else {
              this.snapshotUrl = snapshotUrl
            }

            this.playing = false
            this.errCount = 0
            return (this.startTime = null)
          }

          Player.prototype = {
            constructor: Player.prototype,
            _setSnapshotUrl(buff, delay, defaultUrl) {
              const player = this
              $scope.snapshotUrl = defaultUrl || buff.src

              $scope.$apply()
              return $timeout(
                function () {
                  if (player.playing === false || $scope.alive === false || $scope.play === false) {
                    return
                  }
                  if (player.errCount > maxErrCount) {
                    console.error("Liveview is stopped due to connect fail with camera")
                    player.stop()
                    return false
                  }

                  //          buff.src = player.snapshotUrl + "&_=" + (new Date()).getTime()
                  return (buff.src = `${player.snapshotUrl}` + `?_=${new Date().getTime()}`)
                },

                delay
              )
            },

            _isOverLiveTime() {
              return moment().diff(this.startTime, "seconds") >= maxLiveTime
            },

            play() {
              const player = this
              $(new Image())
                .on("load", function () {
                  player.errCount = 0

                  if (player._isOverLiveTime()) {
                    player.stop()
                    return
                  }

                  return player._setSnapshotUrl(this, 2000 + $scope.livePeriod * 1000)
                })
                .on("error", function () {
                  console.error("Image loading error... ")

                  player.errCount++
                  return player._setSnapshotUrl(this, 10000, errorImage)
                })
                .prop("src", `${player.snapshotUrl}` + `?_=${new Date().getTime()}`)

              player.playing = true
              return (player.startTime = moment())
            },

            stop() {
              this.playing = false
              if ($scope.playEvent != null) {
                return $scope.playEvent(false)
              }
            },
          }

          $scope.$watch("src", function (val) {
            if (!val) {
              return
            }
            //      player = new Player("#{$scope.src}?access_token=#{Auth.getToken}")

            const arr = $scope.src.split("/")
            const cameraId = arr[arr.length - 2]

            return ApiSrv.getCamera({ id: cameraId, isCache: false }).then(function (response) {
              $scope.livePeriod = response.livePeriod != null ? response.livePeriod : 0

              $scope.player = new Player($scope.src)
              return $scope.player.play()
            })
          })

          $scope.$watch("play", function (val) {
            if (val == null || !$scope.player) {
              return
            }

            if (val) {
              return $scope.player.play()
            } else {
              return $scope.player.stop()
            }
          })

          return $scope.$on("$destroy", () => ($scope.alive = false))
        }
      )
    },
  }))
