/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

import Promise from "bluebird"

angular.module("uCountitUiApp").factory("contentSrv", function (ApiSrv) {
  const isSupportGotoDate = function (content, orgType) {
    let ret = true
    switch (content) {
      case "footfall":
      case "directionmap":
      case "gender":
      case "age-group":
      case "gender age":
        if (["sensor", "store", "storegroup", "company"].indexOf(orgType) < 0) {
          ret = false
        }
        break
      case "zonetraffic":
      case "pass":
      case "dwell":
      case "dual heatmap":
      case "occupancy":
        if (["sensor", "store"].indexOf(orgType) < 0) {
          ret = false
        }
        break
      case "overview":
        if (["store", "storegroup", "company"].indexOf(orgType) < 0) {
          ret = false
        }
        break
      case "report":
        if (["store", "storegroup", "company"].indexOf(orgType) < 0) {
          ret = false
        }
        break
      default:
        ret = false
    }

    return ret
  }

  const _makeQueryList = function (options) {
    const list = []
    switch (options.content) {
      case "dual heatmap":
        list.push(_.merge({}, options, { content: "heatmap" }))
        list.push(_.merge({}, options, { content: "dwellheatmap" }))
        break
      case "gender age":
        list.push(_.merge({}, options, { content: "gender" }))
        list.push(_.merge({}, options, { content: "age-group" }))
        break
      case "overview":
        list.push(_.merge({}, options, { content: "footfall" }))
        list.push(_.merge({}, options, { content: "gender" }))
        list.push(_.merge({}, options, { content: "age-group" }))

        if (options.orgType === "store") {
          list.push(_.merge({}, options, { content: "heatmap" }))
          list.push(_.merge({}, options, { content: "dwellheatmap" }))
        }
        break
      case "report":
        if (options.orgType === "company" || options.orgType === "storegroup") {
          list.push(_.merge({}, options, { content: "footfall" }))
        } else if (options.orgType === "store") {
          list.push(_.merge({}, options, { content: "footfall" }))
          list.push(_.merge({}, options, { content: "gender" }))
          list.push(_.merge({}, options, { content: "age-group" }))
        }
        break
      case "pass":
        list.push(_.merge({}, options, { content: "heatmap" }))
        break
      case "dwell":
        list.push(_.merge({}, options, { content: "dwellheatmap" }))
        break
      default:
        list.push(options)
    }

    return list
  }

  const _getFirstLastLocalTime = function (type, opts) {
    let getDateTime
    if (type === "first") {
      getDateTime = ApiSrv.getFirstLocalTime
    } else {
      getDateTime = ApiSrv.getLastLocalTime
    }

    return getDateTime(opts)
      .then((res) => moment(res.localtime))
      .catch((err) => null)
  }

  const getFirstDate = function (options) {
    const promises = []
    const queryList = _makeQueryList(options)
    queryList.forEach((opt) => promises.push(_getFirstLastLocalTime("first", opt)))

    return Promise.all(promises).then(function (results) {
      const filtered = results.filter((el) => moment.isMoment(el))
      if (filtered.length > 0) {
        return moment.min(filtered)
      } else {
        return Promise.reject(new Error(`no first date (${options.content})`))
      }
    })
  }

  const getLastDate = function (options) {
    const promises = []
    const queryList = _makeQueryList(options)
    queryList.forEach((opt) => promises.push(_getFirstLastLocalTime("last", opt)))

    return Promise.all(promises).then(function (results) {
      const filtered = results.filter((el) => moment.isMoment(el))
      if (filtered.length > 0) {
        return moment.max(filtered)
      } else {
        return Promise.reject(new Error(`no last date (${options.content})`))
      }
    })
  }

  return {
    isSupportGotoDate,
    getFirstDate,
    getLastDate,
  }
})
