import angular, { IScope } from "angular"
import DbApi from "common-library/types/DbApi"
import _ from "lodash"
import Bluebird from "bluebird"

import Snapshot = DbApi.Snapshot

interface DirectiveScope {
  data: Snapshot.Data & { fixed?: { file: File } }
  noPersonalData?: boolean
  usePrivacyLaw?: DbApi.Company.Data
  bh?: DbApi.BusinessHours
  onChange?: (data: Snapshot.Data) => void
  onApplyAll: () => Promise<void>
  targetOrg: "company" | "sensor"

  isCreate?: boolean
}

type FormValue = {
  kind: Snapshot.Kind
  schedule?: {
    weekdays: { [key: number]: boolean }
    days: string
    timeKind: Snapshot.TimeKind
    absolute?: string | Date // This created as Date If user input empty field
    open?: number
  }
  fixed?: {
    file?: File
    url: string
  }
}

interface ControllerScope extends DirectiveScope, IScope {
  formValue: FormValue
  bhOfStore: string
  isActiveBH: boolean
  applyState?: Promise<void>
}

const makeBHString = function (businessHours?: DbApi.BusinessHours) {
  if (!businessHours || !businessHours.active) {
    return "00:00:00 ~ 24:00:00"
  }

  return `${businessHours.dayOfWeek[0].open} ~ ${businessHours.dayOfWeek[0].close}`
}

const DEFAULT_FIXED_SNAPSHOT_URL = "/assets/images/fixed-snapshot.png"

angular.module("uCountitUiApp").directive("snapshotForm", () => ({
  restrict: "E",
  templateUrl: "components/snapshot/Form.html",
  scope: {
    bh: "=",
    data: "=",

    onChange: "=",
    onApplyAll: "=",
    targetOrg: "=", /// 'sensor' or 'company'
    disable: "=",
    noPersonalData: "=",
    isCreate: "=?",
  },
  controller: ($scope: ControllerScope) => {
    $scope.SETUP_TIMES = [600, 1200, 1800, 2400, 3000, 3600]
    $scope.MAX_FILE_SIZE = "1MB"

    $scope.$watch("form", () => {
      if (!$scope.form["snapshot.schedule.days"].$validators.format) {
        $scope.form["snapshot.schedule.days"].$validators.format = (v: string) => {
          if ($scope.formValue.kind == "schedule" && v) {
            const pattern = /^(((\d+), ?)+)?(\d+)$/
            const match = pattern.exec(v)
            if (match) {
              const splited = match[0].split(",")
              const days = splited.map((i) => parseInt(i)).filter((i) => !isNaN(i))
              if (days.find((elem, idx) => days.indexOf(elem) != idx)) {
                return false
              }
              const invalidFound = splited.find((s) => {
                const v = parseInt(s)
                return v < 1 || v > 31
              })
              return invalidFound ? false : true
            } else {
              return false
            }
          } else {
            return true
          }
        }
      }
      if (!$scope.form["snapshot.schedule.absolute"].$validators.format) {
        $scope.form["snapshot.schedule.absolute"].$validators.format = (v: string) => {
          if (
            $scope.formValue.kind == "schedule" &&
            $scope.formValue.schedule?.timeKind == "absolute" &&
            v
          ) {
            const pattern = /\d{2}:\d{2}:\d{2}/
            return pattern.exec(v) ? true : false
          } else {
            return true
          }
        }
      }
    })

    $scope.openFileDlg = function () {
      document.getElementById("file-upload")?.click()
      return true
    }

    $scope.selectFixedSnapshot = function () {
      const reader = new FileReader()
      const file = (document.getElementById("file-upload") as HTMLInputElement)?.files?.[0]
      if (file) {
        reader.onload = (e: ProgressEvent<FileReader>) => {
          if (e.target?.result) {
            $scope.formValue.fixed = {
              file,
              url: e.target?.result?.toString(),
            }
          }
          $scope.handleChange()
          $scope.$apply()
        }
        reader.readAsDataURL(file)
      }
    }

    $scope.clearFixedSnapshot = function () {
      $scope.formValue.fixed = { url: DEFAULT_FIXED_SNAPSHOT_URL }
      $scope.handleChange()
    }

    $scope.formValue = {
      kind: "hourly",
    }

    $scope.isCompanyForm = () => $scope.targetOrg == "company"
    $scope.isSensorForm = () => $scope.targetOrg == "sensor"

    $scope.$watch("targetOrg", () => {
      $scope.targetOrg = $scope.targetOrg ?? "sensor"
    })

    const data2form = () => {
      const formValue: FormValue = {
        kind: $scope.data.kind,
      }

      if ($scope.data.fixed) {
        formValue.fixed = $scope.data.fixed
      }

      if ($scope.data.schedule) {
        const schedule = $scope.data.schedule
        const weekdays = schedule.weekdays.reduce(
          (acc: boolean[], weekday: number) => {
            acc[weekday] = true
            return acc
          },
          [false, false, false, false, false, false, false]
        )
        formValue.schedule = {
          ...schedule,
          days: schedule.days.join(","),
          weekdays,
        }
      }

      return formValue
    }

    $scope.$watchGroup(["data", "bh"], (newVal, oldVal) => {
      if ($scope.isSensorForm()) {
        if ($scope.bh) {
          $scope.isActiveBH = $scope.bh.active
        } else {
          $scope.isActiveBH = false
        }
      } else {
        $scope.isActiveBH = true
      }

      $scope.bhOfStore = makeBHString($scope.bh)
      if ($scope.data) {
        const origForm = _.cloneDeep($scope.formValue)
        const newForm = data2form()

        if (!_.isEqual(origForm, newForm) || !_.isEqual(newVal[1], oldVal[1])) {
          $scope.formValue = newForm
          $scope.form.$setDirty(true)
        }
      } else {
        // Fill default values to data
        $scope.handleChange()
      }
      $scope.applyState = undefined
    })

    // form2data
    $scope.handleChange = () => {
      const form = $scope.formValue

      if (form.kind == "schedule" && !form.schedule) {
        form.schedule = {
          weekdays: [false, false, false, false, false, false, false],
          days: "",
          timeKind: $scope.isActiveBH ? "open" : "absolute",
          absolute: "09:00:00",
          open: 600,
        }
      } else if (form.kind == "fixed" && !form.fixed) {
        form.fixed = { url: DEFAULT_FIXED_SNAPSHOT_URL }
      }

      if (!$scope.form.$valid) {
        return
      }

      const data: Snapshot.Data = {
        kind: form.kind,
      }

      if (form.kind == "schedule" && form.schedule != null) {
        const schedule = form.schedule
        let absolute
        if (schedule.absolute instanceof Date) {
          const dt = schedule.absolute
          absolute = [dt.getHours(), dt.getMinutes(), dt.getSeconds()]
            .map((n) => n.toString().padStart(2, "0"))
            .join(":")
        } else {
          absolute = schedule.absolute
        }
        const weekdays: number[] = []
        for (const i in Object.keys(schedule.weekdays)) {
          if (schedule.weekdays[i]) {
            weekdays.push(parseInt(i))
          }
        }

        data.schedule = {
          weekdays,
          days: schedule.days
            .split(",")
            .map((s) => parseInt(s))
            .filter((s) => !isNaN(s)),
          timeKind: schedule.timeKind,
          absolute,
          //absolute,
          open: schedule.open,
        }
      }
      if (form.kind == "fixed" && form.fixed != null) {
        data.fixed = form.fixed
      }

      $scope.absoluteRequired =
        $scope.formValue.kind == "schedule" && $scope.formValue.schedule?.timeKind == "absolute"

      if ($scope.onChange) {
        $scope.onChange(data)
      }
    }

    $scope.applyState
    $scope.handleApplyAll = ($event: Event) => {
      $event.preventDefault()
      if ($scope.onApplyAll) {
        $scope.applyState = Bluebird.resolve().then(() => $scope.onApplyAll())
      }
    }
  },
}))
