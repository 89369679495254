/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

import dataUrlToBlob from "blueimp-canvas-to-blob"
import Promise from "bluebird"
import { getFootfallFeature } from "../util/footfall"

angular
  .module("uCountitUiApp")
  .controller(
    "SummaryCamerasCtrl",
    (
      $rootScope,
      $scope,
      $state,
      $timeout,
      ApiSrv,
      RestApi,
      Auth,
      Locale,
      Upload,
      CameraAdminSrv,
      IotAdminSrv,
      FaceDeviceSrv,
      usSpinnerService,
      HeaderSrv,
      FaUsageSrv
    ) =>
      Promise.all([
        HeaderSrv.fetchCurrentCompany(),
        HeaderSrv.fetchCurrentStore(),
        Auth.fetchCurrentAccount(),
      ]).spread(function (company, store, me) {
        let hideLoading
        if (!$scope.data || !$scope.data._id) {
          return
        }
        let isInitialized = false
        const PENDING = "PENDING"
        const FULFILL = "FULFILL"
        const DEFAULT_UPDATE_DATA_PERIOD = 10 //seconds
        const COUNDOWN_TIME = 1000 //miliseconds
        let countTimer = null

        $scope.camera = $scope.data
        $scope.status = { online: true, lastAccessTime: "", uptime: "" }
        $scope.contents = []
        $scope.autoRefresh = true
        $scope.autoRefreshStatus = null
        $scope.countDown = 0
        $scope.me = me

        const kFixedSnapshot = Locale.string("Fixed Snapshot")
        const kUpdatePeriod = Locale.string("Update Period")
        const kMaintenanceMode = Locale.string("Maintenance mode")
        const canvas = document.createElement("canvas")
        const EXCEPT_CONTENTS_LIST = [
          "heatmaps",
          "trafficmap",
          "metacounter",
          "trackcounter",
          "facecounter",
          "log",
          "blackboximage",
        ]

        $scope.liveViewInfo =
          $scope.camera.snapshot.kind == "fixed"
            ? kFixedSnapshot
            : $scope.camera.livePeriod
            ? kUpdatePeriod.replace("%d", $scope.camera.livePeriod)
            : ""

        $scope.isPlay = true

        $scope.go = (state) => $state.go(state, { id: $scope.camera._id })

        const _getFullname = function (n, contents) {
          let fullname = ""
          let tag = ""
          switch (n) {
            case "counter":
              fullname = "Footfall"
              tag = getFootfallFeature(contents)
              break
            case "heatmap":
              fullname = "Traffic Heatmap"
              if (contents.trafficmap) {
                tag = "tm"
              }
              break
            case "dwellmap":
              fullname = "Dwell Heatmap"
              break
            case "face":
            case "rtFace":
              fullname = "Gender/Age"
              if (n === "rtFace") {
                if ($scope.camera.face != null ? $scope.camera.face.rtService : undefined) {
                  tag = "R/T"
                }
              }
              break
            case "queue":
              fullname = "Queue"
              break
            case "zonetraffic":
              fullname = "Zone Traffic"
              break
            case "directionmap":
              fullname = "Flow Map"
              break
            case "occupancy":
              fullname = "Occupancy"
              break
            default:
              return null
          }
          return { fullname, tag }
        }

        const _toGenderChar = function (g) {
          if (g == null) {
            return "?"
          }
          if ((g != null ? g.toLowerCase() : undefined) === "male") {
            return "♂"
          } else {
            return "♀"
          }
        }

        const _getFaceData = function (d) {
          let age, gender, url
          try {
            const a = JSON.parse(d.analysis)
            if (d.imageUrl) {
              url = d.imageUrl
            } else {
              if (a.url) {
                //old version
                ;({ url } = a)
              } else {
                ;({ url } = JSON.parse(d.face))
              }
            }
            const attr = a.face[0].attributes != null ? a.face[0].attributes : a.face[0].attribute
            age = attr.age.value || "?"
            gender = _toGenderChar(attr.gender.value)
          } catch (e) {
            console.error(e)
          }

          let out = `${gender} ${age}`
          if (me.isDeveloper()) {
            if (!company.isBlockedSnapshot(me) && url != null) {
              out += ` <img class=small-thumbnail src="${url}" >`
            }
          }
          return out
        }

        const _set2DefaultCount = function () {
          if ($scope.camera.functions.use.face || $scope.camera.functions.use.rtFace) {
            return ($scope.countDown = 3)
          } else {
            return ($scope.countDown = DEFAULT_UPDATE_DATA_PERIOD)
          }
        }

        var _countLoop = function (start) {
          if (!$scope.autoRefresh) {
            return
          }

          if (start === "start") {
            _set2DefaultCount()
          }
          return (countTimer = $timeout(function () {
            $scope.countDown -= 1
            if ($scope.countDown <= 0) {
              _set2DefaultCount()
              _getLastContents()
              return
            }

            return _countLoop()
          }, COUNDOWN_TIME))
        }

        const _updateSensorStatus = function () {
          const { offset, fota } = $scope.camera
          const { status, description } = $scope.camera.iotdevice
          $scope.status = {
            online: $scope.camera.iotdevice.online,
            lastAccessTime: _IsoTime(status.heartbeat.at, offset).format("lll"),
            bootat: _IsoTime(status.uptime.bootat, offset).format("lll"),
            fw_versions: "",
            fw_versions_ok: fota
              ? fota.deviceVersion && fota.deviceVersion === fota.pkgVersion
              : true,
            fota_heartbeat: moment(fota != null ? fota.heartbeat : undefined).format("lll"),
            fota_heartbeat_ok:
              moment().diff(moment(fota != null ? fota.heartbeat : undefined), "seconds") < 2 * 600,
            sd: (status.sd != null ? status.sd.mount : undefined) || "-",
            sdok:
              (status.sd != null ? status.sd.readwrite : undefined) === "ok" &&
              status.sd.mount !== "not installed",
          }

          const fws = [description != null ? description.properties.firmware.version : undefined]
          if (fota) {
            if ($scope.status.fw_versions_ok) {
              fws.push(fota != null ? fota.deviceVersion : undefined)
            } else {
              fws.push(
                `${fota != null ? fota.deviceVersion : undefined}!=${
                  fota != null ? fota.pkgVersion : undefined
                }`
              )
            }
          }
          $scope.status.fw_versions = fws.join(" / ")

          if (status.sd.readwrite && status.sd.readwrite.indexOf("Read-only") >= 0) {
            return ($scope.status.sd += "[Read Only]")
          }
        }

        const _checkContentsStatus = function () {
          $scope.contents_allok = true
          const now = moment()
          return $scope.contents.forEach(function (content) {
            if (content.lastTime) {
              const dx = now.diff(content.lastTime, "seconds")
              //INFO last should be updated before sampling * 1.5
              content.ok = dx <= 1.5 * content.sampling
            } else {
              content.ok = false
            }
            if (!content.ok) {
              content.style = "mdi-timer-sand mdi-spin"
              const sampling = content.sampling || (content.name === "heatmap" ? 3600 : 600)
              const nextDataTime = moment(Math.ceil(now.unix() / sampling) * sampling * 1000)
              const waitingTime = nextDataTime.add(3, "minute").diff(now, "minute")
              if (["face", "rtFace"].includes(content.name)) {
                content.msg = "Waiting face detected..."
              } else {
                content.msg = `Waiting ${waitingTime}min...`
              }
              return ($scope.contents_allok = false)
            } else {
              content.style = "mdi-check-circle-outline"
              return (content.msg = null)
            }
          })
        }

        const _dbTimetoIsoTime = (dbtime, offset) =>
          moment.utc(dbtime).subtract(offset, "minutes").utcOffset(offset)
        var _IsoTime = (isotime, offset) => moment(isotime).utcOffset(offset)

        var _getLastContents = function (start) {
          if ($scope.autoRefreshStatus === PENDING) {
            return
          }

          $scope.autoRefreshStatus = PENDING
          return async.each(
            $scope.contents,
            (content, next) =>
              ApiSrv.getLastData({
                id: $scope.camera._id,
                content: content.name,
                isCache: false,
              }).then(
                function (res) {
                  switch (content.name) {
                    case "track":
                      content.lastTime = moment.parseZone(res.lasttracktime)
                      content.data = "-"
                      res.sampling = 600
                      break
                    case "counter":
                      content.lastTime = _dbTimetoIsoTime(res.createdAt, res.timeOffset)
                      content.data = res.data
                        .map(function (c, idx) {
                          const ct = _.find(
                            $scope.camera.footfall != null
                              ? $scope.camera.footfall.counters
                              : undefined,
                            { _id: c._counterId }
                          )
                          if (ct != null) {
                            return `${ct.name} : ${c.count}/${c.rawCount}`
                          } else {
                            return `${c.source} : ${c.count}/${c.rawCount}`
                          }
                        })
                        .join("\n")
                      break
                    case "heatmap":
                      content.lastTime = _dbTimetoIsoTime(res.from, res.timeOffset)
                      content.data = "-"
                      break
                    case "face":
                    case "rtFace":
                      if (res != null) {
                        res.sampling = 3600
                        content.lastTime = moment.tz(res.datetime, $scope.camera.timezone)
                        content.data = _getFaceData(res)
                      }
                      break
                    case "queue":
                      if (res.data != null) {
                        content.lastTime = _IsoTime(res.datetime, $scope.camera.offset)
                        content.data = `TotalWait:${
                          res.data != null ? res.data.totalWait : undefined
                        }, rowId:${res.data != null ? res.data.rowId : undefined}`
                      }
                      break
                    case "zonetraffic":
                    case "directionmap":
                    case "dwellmap":
                      content.lastTime = moment.parseZone(res.localtime)
                      content.data = "-"
                      break
                    case "occupancy":
                      content.lastTime = moment.parseZone(res.localtime)
                      content.data =
                        res.counts != null
                          ? res.counts.map((c) => `${c.name}:${c.count} `)
                          : undefined
                      break
                    case "metacounter":
                    case "trackcounter":
                    case "facecounter":
                      return
                  }

                  content.sampling = res.sampling
                  if (!["face", "rtFace", "counter", "track"].includes(content.name)) {
                    content.lastTime.add(res.sampling, "seconds")
                  }
                  content.last = content.lastTime.format("lll")

                  return next()
                },
                function (res) {
                  //error
                  if ($scope.camera.offline) {
                    $scope.autoRefresh = false
                  }
                  return next()
                }
              ),

            function (_err, _results) {
              if (me.gteAgency() && $scope.isFaceCamera() && $scope.contents.length > 0) {
                const faceContent = $scope.contents.find((c) => c.name == "face")
                if (faceContent?.lastTime) {
                  const lastTime = faceContent.lastTime.clone().startOf("day")
                  const query = {
                    id: $scope.camera._id,
                    from: lastTime.clone().add(-2, "day").format("YYYY-MM-DD"),
                    to: lastTime.clone().add(1, "day").format("YYYY-MM-DD"),
                  }

                  FaUsageSrv.query(query).$promise.then(
                    (faUsages) =>
                      ($scope.faUsages = faUsages.map((faUsage) =>
                        Object.assign({}, faUsage, {
                          datetime: moment.utc(faUsage.datetime).format("ll"),
                        })
                      ))
                  )
                }
              }

              $timeout(function () {
                //INFO to call $apply
                _updateSensorStatus()
                return _checkContentsStatus()
              })

              $scope.autoRefreshStatus = FULFILL
              _countLoop(start)
              return (isInitialized = true)
            }
          )
        }

        const Start = function () {
          isInitialized = true
          const facePromise =
            $scope.camera.functions.use.face || $scope.camera.functions.use.rtFace
              ? FaceDeviceSrv.get({ id: $scope.camera._id }).$promise
              : Promise.resolve()
          return Promise.all([
            facePromise,
            IotAdminSrv.Device().get({ uid: $scope.camera.accessKey, _: Date.now() }).$promise,
            IotAdminSrv.getFOTAInfo($scope.camera.accessKey).catch(function (err) {}),
            ApiSrv.getStore({ id: $scope.camera._storeId }),
            ApiSrv.getCompany({ id: $scope.camera._companyId }),
          ]).spread(function (face, iot, fota, store, company) {
            $scope.camera.face = face
            $scope.camera.iotdevice = iot.data
            $scope.camera.fota = fota
            $scope.camera.store = store
            $scope.camera.company = company
            return ApiSrv.getAgency({ id: company._agencyId }).then(function (agency) {
              const usePDP = agency.policy.noPersonalData
              $scope.isMaintenanceMode =
                usePDP && store.maintenanceMode.company && store.maintenanceMode.agency
              if ($scope.isMaintenanceMode) {
                $scope.liveViewInfo = `[${kMaintenanceMode}] `
                $scope.liveViewInfo += $scope.camera.livePeriod
                  ? kUpdatePeriod.replace("%d", $scope.camera.livePeriod)
                  : ""
              }
              if (
                ($scope.camera.fixedSnapshot != null
                  ? $scope.camera.fixedSnapshot.active
                  : undefined) &&
                !$scope.isMaintenanceMode
              ) {
                $scope.isPlay = false
              }

              $scope.contents = _.map($scope.camera.functions.use, function (value, name) {
                const fullname = _getFullname(name, $scope.camera.functions.use)
                if (fullname) {
                  return { name, value, fullname: fullname.fullname, tag: fullname.tag }
                } else {
                  return null
                }
              }).filter(
                (d) => d && d.value && EXCEPT_CONTENTS_LIST.indexOf(d.name.toLowerCase()) < 0
              )

              const _useTrack = () =>
                Object.values(
                  _.pick($scope.camera.functions.use, [
                    //"trackcounter",
                    "trafficmap",
                    "dwellmap",
                    "directionmap",
                    "zonetraffic",
                  ])
                ).filter((d) => d).length
              if (_useTrack()) {
                $scope.contents.unshift({ name: "track", fullname: "Track", tag: "" })
              }

              if (me.gteAgency()) {
                return _getLastContents("start")
              }
            })
          })
        }

        Start()

        $scope.$on("localizeResourcesUpdated", function () {
          if (isInitialized) {
            return Start()
          }
        })

        $scope.toggleAutoRefresh = function () {
          $scope.autoRefresh = !$scope.autoRefresh
          if ($scope.autoRefresh) {
            return _getLastContents()
          } else {
            return $timeout.cancel(countTimer)
          }
        }

        $scope.$on("$destroy", function () {
          $scope.autoRefresh = false
          return ($scope.autoRefreshStatus = PENDING)
        })

        $scope.playLiveView = (status) => ($scope.isPlay = status)

        $scope.isLiveView = function () {
          if ($scope.camera.offline) {
            return false
          }
          if ($scope.isMaintenanceMode) {
            return true
          }
          if ($scope.camera.snapshot.kind == "fixed") {
            return false
          }
          return true
        }

        $scope.isFaceCamera = () =>
          $scope.camera.functions.use.face || $scope.camera.functions.use.rtFace

        const getBase64ImageFromLiveSnapshot = () =>
          new Promise(function (resolve, reject) {
            const src = $(".screen").attr("src")
            if (!src) {
              return resolve(null)
            }

            const img = new Image()
            img.src = src
            return (img.onload = function () {
              if (this.naturalWidth < 500) {
                return reject(new Error("No image"))
              }

              canvas.width = this.naturalWidth
              canvas.height = this.naturalHeight
              canvas.getContext("2d").drawImage(this, 0, 0)
              const base64Image = canvas.toDataURL("image/jpeg", 0.75)
              return resolve(base64Image)
            })
          })

        $scope.uploadFixedSnapshot = function () {
          $scope.uploadSuccessMsg = ""
          showLoading()
          $scope.isPlay = false

          return getBase64ImageFromLiveSnapshot()
            .then(function (base64Image) {
              if (!base64Image) {
                return
              }

              const blob = dataUrlToBlob(base64Image)
              blob.name = "fixedSnapshot.jpeg"

              const uploadUrl = `${$rootScope.globalConfig.apipath}/camera/${$scope.camera._id}/fixedSnapshot`
              return uploadSnapshot(uploadUrl, blob)
            })
            .then(function (res) {
              if (!res) {
                return
              }

              $scope.camera.snapshot.fixed = {
                ...$scope.camera.snapshot.fixed,
                url: res.data.fixed.url,
              }

              return CameraAdminSrv.update({ id: $scope.camera._id }, $scope.camera.data).$promise
            })
            .then(function (res) {
              if (!res) {
                return
              }

              $timeout(() => ($scope.uploadSuccessMsg = ""), 3000)
              $scope.uploadSuccessMsg = "Fixed snapshot is uploaded."
              // Clear cache
              return RestApi["Sensor"].get($scope.camera._id, false)
            })
            .catch(function (err) {
              console.error(err.message)
              $timeout(() => ($scope.uploadErrorMsg = ""), 3000)
              return ($scope.uploadErrorMsg = "Error: Failed to upload fixed snapshot.")
            })
            .finally(function () {
              hideLoading()
              return ($scope.isPlay = true)
            })
        }

        var uploadSnapshot = (uploadUrl, blob) =>
          Upload.upload({
            url: uploadUrl,
            data: {
              file: blob,
            },
          })

        var showLoading = function () {
          angular.element(".splash").show()
          return usSpinnerService.spin("spinner")
        }

        return (hideLoading = function () {
          angular.element(".splash").hide()
          return usSpinnerService.stop("spinner")
        })
      })
  )
