import React from "react"
import { useField } from "informed"
import { Validator } from "jsonschema"

const validate = (value) => {
  const validator = new Validator()
  return value.reduce((ret, val) => {
    const validated = validator.validate(value, {
      type: "array",
      items: {
        type: "string",
        format: "email",
      },
    })
    if (validated.valid) return ret
    return validated.errors.map((e) => e.message).join(", ")
  }, undefined)
}

const MultipleEmailField = (props) => {
  const { fieldState, fieldApi, render, ref, userProps } = useField({ ...props, validate })

  const { value } = fieldState
  const { setValue, setTouched } = fieldApi
  const { onChange, onBlur, ...rest } = userProps

  const inputValue = value ? value.join(", ") : ""

  return render(
    <input
      {...rest}
      ref={ref}
      defaultValue={inputValue}
      //value={inputValue}
      onChange={(e) => {
        //inputValue = e.target.value
        if (onChange) onChange(e)
      }}
      onBlur={(e) => {
        const value = e.target.value.split(",").map((v) => v.trim())
        setValue(value)
        setTouched(true)
        if (onBlur) onBlur(e)
      }}
      type="text"
      required
      className="form-control"
    />
  )
}

export default MultipleEmailField
