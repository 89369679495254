import Iot from "common-library/types/IoT"

/// "verified": 등록 가능하며 인증된 라이센스
/// "unverified": 등록 가능하지만 인증되지 않은 라이센스
/// "noLicense": 등록 가능한 라이센스가 존재하지 않음
/// 이외의 상태는 라이센스 조회 실패, 삭제, 인스톨 중임을 나타낸다.
/// "unknown": 라이센스 상태를 알 수 없음
export type LicenseStatusValue =
  | "verified"
  | "noLicense"
  | "unverified"
  | "unknown"
  | "syncing"
  | "installing"
  | "removing"

export function getDeviceLicenseStatus(device?: Iot.Device.Data): LicenseStatusValue {
  if (!device) {
    return "unknown"
  }
  const hasLicense = device?.licenses?.find((l) => ["unverified", "verified"].includes(l.validity))
    ? true
    : false

  const isUcLicense = device?.licenses?.find((l) => "verified" == l.validity) ? true : false
  if (hasLicense && isUcLicense) {
    return "verified"
  } else if (hasLicense) {
    return "unverified"
  } else {
    return "noLicense"
  }
}

export function isVerifiedLicenseStatus(status: LicenseStatusValue) {
  return status == "verified"
}

export function isRegistrableLicenseStatus(status: LicenseStatusValue) {
  if (status === "unverified" || status === "verified") {
    return true
  } else {
    return false
  }
}
