/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS104: Avoid inline assignments
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

import Promise from "bluebird"
import env from "../../../../config/environment"
const escapeStringRegexp = require("escape-string-regexp")

angular
  .module("uCountitUiApp")
  .controller(
    "CompanyCtrl",
    function (
      $scope,
      $filter,
      $window,
      $anchorScroll,
      Auth,
      ApiSrv,
      CompanyAdminSrv,
      FaceDeviceSrv,
      NotificationSrv,
      Locale,
      NgTableParams,
      ngDialog,
      StoreSalesSrv,
      StoreAdminSrv,
      $q,
      CameraConfigSrv,
      HeaderSrv,
      RestApi,
      me
    ) {
      $scope.isLocalgrey = env.isLocalgrey
      $scope.me = me
      if (me.isStore()) {
        return
      }
      $scope.languageList = Locale.getLangList()

      $scope.editable = me.gteAgency()
      $scope.multiEmailPattern =
        /^(([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/
      $scope.numberPattern = /^^([1-9][0-9]{0,3})$|^10000$/
      $scope.integerPattern = /^(0|-?[1-9][0-9]*)$/
      $scope.itemName = Locale.string("Company")
      $scope.agencyPlaceholder = Locale.string("choose an agency")
      $scope.inputDlgTitle = "Create"
      $scope.analysisTrackWorkerStatus = {}
      let original = null
      const strActive = Locale.string("Active")
      const strInactive = Locale.string("Inactive")

      $scope.$watch("showSelectedOrganization", (_value) => $scope.reload(true))

      const kExportDataDefaultVersion = 2
      $scope.exportModuleVersions = [
        { name: Locale.string("All Version"), value: 0 },
        { name: "V1", value: 1 },
        { name: "V2", value: 2 },
      ]
      $scope.exportDataFormats = [
        { name: "CSV", value: "csv" },
        { name: "Excel", value: "xls" },
      ]
      $scope.exportDataEncodings = [
        { name: "UTF-8", value: "utf8" },
        { name: "UTF-16LE", value: "utf16le" },
      ]
      $scope.exportDataEOLs = [
        { name: "Windows CRLF", value: "\r\n" },
        { name: "Linux LF", value: "\n" },
      ]
      $scope.exportDataDelimiters = [
        { name: "Comma ,", value: "," },
        { name: "TAB \\t", value: "\t" },
        { name: "Semicolon ;", value: ";" },
      ]
      $scope.exportDataBOMs = [
        { name: "With BOM", value: true },
        { name: "No BOM", value: false },
      ]

      $scope.setupTimes = [600, 1200, 1800, 2400, 3000, 3600]

      let originalZTTimeSegment = null
      $scope.LAST_TIME = 9999
      $scope.MIN_TIME = 0
      $scope.MAX_TIME = $scope.LAST_TIME - 1

      $scope.KZTTimeSegmentName = {
        STANDARD: "standard",
        SHORTTIME: "shorttime",
        LONGTIME: "longtime",
        CUSTOM: "custom",
      }

      $scope.ztTimeSegmentValues = {
        [$scope.KZTTimeSegmentName.STANDARD]: [30, 60, 180, 300, 600, 1200, 9999],
        [$scope.KZTTimeSegmentName.SHORTTIME]: [30, 60, 120, 180, 300, 600, 9999],
        [$scope.KZTTimeSegmentName.LONGTIME]: [60, 180, 300, 600, 1200, 1800, 9999],
        [$scope.KZTTimeSegmentName.CUSTOM]: [30, 60, 180, 300, 600, 1200, 9999],
      }

      $scope.ztTimeSegmentList = [
        { name: Locale.string("Standard"), value: $scope.KZTTimeSegmentName.STANDARD },
        { name: Locale.string("Short Time"), value: $scope.KZTTimeSegmentName.SHORTTIME },
        { name: Locale.string("Long Time"), value: $scope.KZTTimeSegmentName.LONGTIME },
        { name: Locale.string("Custom"), value: $scope.KZTTimeSegmentName.CUSTOM },
      ]

      $scope.ztTimeSegmentSelector = { selected: $scope.KZTTimeSegmentName.STANDARD }

      const setSalesDriver = function (active, name) {
        if (!active) {
          $scope.form.sales = { active: false }
        }

        if (active) {
          let left
          $scope.form.sales.driver =
            (left = _.find($scope.salesdrivers, { name: name })) != null
              ? left
              : $scope.defaultDriver
        } else {
          $scope.form.sales.driver = $scope.salesNoneDriver
        }
        return ($scope.form.sales.driverName =
          $scope.form.sales.driver != null ? $scope.form.sales.driver.name : undefined)
      }

      $scope.checkSalesActive = () =>
        setSalesDriver($scope.form.sales.active, $scope.form.sales.driverName)

      $scope.selectSalesDriver = (selectedDriver) => setSalesDriver(true, selectedDriver.name)

      $scope.revert = function () {
        $scope.form = _.merge(
          {},
          {
            name: "",
            active: true,
            policy: {
              disallowAdminSnapshot: false,
              privacyLaw: {
                superAdmin: false,
                companyAdmin: false,
              },
              snapshot: {
                kind: "hourly",
              },
              hideOverviewMenu: false,
              hideStandardReportMenu: true,
              hideCamerasMenu: true,
              hideAdminMenu: true,
              hideCamerasMenuStore: true,
            },
            premiumReport: {
              active: false,
            },
            exportData: {
              ui: {
                encodingOption: {},
                version: $scope.exportModuleVersions[kExportDataDefaultVersion],
              },
              cameraFootfall: true,
              useFaceCount: false,
              PHFloorplan: false,
              PHTraffic: false,
              PHDwell: true,
            },
            reports: [],
            heatmapWeight: {
              pass: 1,
              dwell: 1,
            },
            ageGroup: {},
            zoneTraffic: {
              timeSegments: $scope.ztTimeSegmentValues.standard,
            },
            dwellmap: {
              timeSegments: [10, 30, 60, 9999],
            },
            schedule: {
              holidays: [],
              workdays: [],
            },
          }
        )

        $scope.selectedAgency =
          ($scope.agencies != null ? $scope.agencies.length : undefined) === 1
            ? $scope.agencies[0]
            : _.find($scope.agencies, {
                _id: Auth.clientInfo("admin.companies.lastSelectedAgencyId"),
              })
        $scope.form_create.$setPristine()
        return ($scope.errorMessage = "")
      }

      $scope.onFaceChange = (face) => {
        if ($scope.form) {
          $scope.form.face = face
        }
      }
      $scope.onSnapshotChange = (snapshot) => {
        if ($scope.form) {
          $scope.form.policy.snapshot = snapshot
        }
      }

      $scope.canSubmit = function () {
        if (!$scope.isOpenInputDlg) {
          return false
        }
        $scope.form._spaceId =
          $scope.selectedAgency != null ? $scope.selectedAgency._spaceId : undefined
        $scope.form._agencyId =
          $scope.selectedAgency != null ? $scope.selectedAgency._id : undefined
        return $scope.form_create.$valid && !angular.equals($scope.form, original)
      }

      const exportDataUI2DB = (form, db) =>
        (db.versions = form.ui.version.value ? [form.ui.version.value] : [])
      // INFO: hide encoding options
      // db.format = form.ui.format.value
      // db.encodingOption = {} if not db.encodingOption
      // db.encodingOption.encoding = form.ui.encodingOption.encoding.value
      // db.encodingOption.eol = form.ui.encodingOption.eol.value
      // db.encodingOption.delimiter = form.ui.encodingOption.delimiter.value
      // db.encodingOption.bom = form.ui.encodingOption.bom.value

      const exportDataDB2UI = function (db, form) {
        const _findValue = function (v, list) {
          if (v != null) {
            return _.find(list, { value: v })
          } else {
            return list[0]
          }
        }

        return (form.ui.version = _findValue(
          db.versions != null ? db.versions[0] : undefined,
          $scope.exportModuleVersions
        ))
      }
      // INFO: hide encoding options
      // form.ui.format = _findValue db.format, $scope.exportDataFormats
      // form.ui.encodingOption.encoding = _findValue db.encodingOption?.encoding, $scope.exportDataEncodings
      // form.ui.encodingOption.eol = _findValue db.encodingOption?.eol, $scope.exportDataEOLs
      // form.ui.encodingOption.delimiter = _findValue db.encodingOption?.delimiter, $scope.exportDataDelimiters
      // form.ui.encodingOption.bom = _findValue db.encodingOption?.bom, $scope.exportDataBOMs

      $scope.submitForm = function () {
        exportDataUI2DB($scope.form.exportData, $scope.form.exportData)

        const submit = $scope.onCreating() ? $scope.createItem : $scope.updateItem
        return submit($scope.form).then(() =>
          $scope.reload(false, () => HeaderSrv.emitter.reload())
        )
      }

      $scope.createItem = (item) =>
        new Promise(function (resolve, _reject) {
          item.ageGroup = $scope.ageGroup
          return CompanyAdminSrv.save(
            item,
            function (_sobj, _res) {
              $scope.isOpenInputDlg = false
              Auth.clientInfo(
                "admin.companies.lastSelectedAgencyId",
                $scope.selectedAgency != null ? $scope.selectedAgency._id : undefined
              )
              return resolve()
            },
            function (error) {
              if (error.status === 409) {
                $scope.errorMessage = Locale.string("msg_error_name_duplicated")
              } else {
                $scope.errorMessage = Locale.string("msg_error_create")
              }
              return resolve()
            }
          )
        })

      $scope.updateItem = (item) =>
        updateHolidays(item)
          .then(() => RestApi.Company.update(item._id, item))
          .then(function () {
            $scope.isOpenInputDlg = false
            return updateLifeCycle(item)
          })
          .catch(function (error) {
            if (error.status === 409) {
              return ($scope.errorMessage = Locale.string("msg_error_name_duplicated"))
            } else {
              return ($scope.errorMessage = Locale.string("msg_error_create"))
            }
          })

      $scope.isCompleteList = (list) => list && (list != null ? list.length : undefined) > 1

      //# List Table ##
      $scope.openInputDlg = function (companyData) {
        const item = companyData != null ? companyData.company : undefined
        if (
          !$scope.agencies ||
          ($scope.agencies != null ? $scope.agencies.length : undefined) === 0
        ) {
          $window.alert(Locale.string("agency list is empty"))
        }

        $scope.isOpenInputDlg = true
        $scope.isUpdatingFace = false
        $scope.isOpenEmailReportDlg = false
        $scope.isOpenNotificationDlg = false
        $scope.isOpenPremiumReportDlg = false
        $scope.heatmapWeightState = {}
        $scope.tsState = {}
        $scope.fixedSnapshotState = ""
        $scope.companyEmailPremiumReportStatus = false
        $scope.deletedIcalendars = []
        $scope.revert()

        if (item != null) {
          $scope.inputDlgTitle = "Update"
          _.merge($scope.form, item.data)

          $scope.form.premiumReport = {
            active: __guard__(_.first(item.data.reports), (x) => x.active),
          }

          if (item.data.dwellmap.timeSegments.length > 0) {
            $scope.form.dwellmap.timeSegments = _.clone(item.data.dwellmap.timeSegments)
          }
          if (item.data.zoneTraffic.timeSegments.length > 0) {
            $scope.form.zoneTraffic.timeSegments = _.clone(item.data.zoneTraffic.timeSegments)
          }
          initZTTimeSegment($scope.form.zoneTraffic.timeSegments)

          setSalesDriver(
            item.sales != null ? item.sales.active : undefined,
            item.sales != null ? item.sales.driverName : undefined
          )
          $scope.selectedAgency = _.find($scope.agencies, { _id: item._agencyId })
          delete $scope.form.createdAt
          $scope.form_create.$setDirty()
          $scope.errorMessage = ""

          $scope.form.face = item.face
          exportDataDB2UI(item.exportData, $scope.form.exportData)

          NotificationSrv.Company.get({ id: item._id, _: Date.now() }, function (nt) {
            if (nt) {
              $scope.companyEmailPremiumReportStatus =
                __guard__(nt != null ? nt.report : undefined, (x1) => x1.active) &&
                __guard__(
                  __guard__(nt != null ? nt.report : undefined, (x3) => x3.content),
                  (x2) => x2.premiumReport
                )
            }
          })

          ApiSrv.getIcalendars({ orgId: $scope.form._id }).then(function (calendars) {
            $scope.form.schedule.holidays = calendars.filter((c) => c.type === "holiday")
            $scope.form.schedule.workdays = calendars.filter((c) => c.type === "workday")
            return (original = angular.copy($scope.form))
          })
        } else {
          $scope.form.face = {
            useThrottling: false,
            retentionPeriod: 7,
            limitCount: 1000,
            engine: {
              kind: "dual",
              dual: {
                gender: "face++",
                age: "face++",
              },
            },
          }
          $scope.inputDlgTitle = "Create"
        }

        $scope.ageGroup = _.cloneDeep($scope.form.ageGroup)
        original = angular.copy($scope.form)
        $anchorScroll()

        return ApiSrv.getTrackStatus({ ops: "analysisTrackWorker" }).then(function (status) {
          __guard__(
            __guard__(
              __guard__(status != null ? status.running : undefined, (x3) => x3.data),
              (x2) => x2.cameras
            ),
            (x1) =>
              x1.forEach(function (c) {
                if (
                  __guard__(c != null ? c.analysisTrack : undefined, (x4) => x4.AnalysisS3Track)
                ) {
                  delete c.analysisTrack.AnalysisS3Track
                }
              })
          )
          return ($scope.analysisTrackWorkerStatus = status)
        })
      }

      $scope.submitNotification = function (nt) {
        if (nt.abnormal.strEmails != null) {
          nt.abnormal.emails = nt.abnormal.strEmails.split(";").filter((m) => m.length)
        }

        return NotificationSrv.Company.update(
          { id: nt._parentId },
          nt,
          (_res) => ($scope.isOpenNotificationDlg = false)
        )
      }

      $scope.submitEmailReport = function (nt) {
        if (nt.report.strEmails != null) {
          nt.report.emails = nt.report.strEmails.split(";").filter((m) => m.length)
        }

        nt.report.language = $scope.report_lang != null ? $scope.report_lang.lang : undefined

        return NotificationSrv.Company.update(
          { id: nt._parentId },
          nt,
          (_res) => ($scope.isOpenEmailReportDlg = false)
        )
      }

      const _defaultNotificationEmails = function (item) {
        //TODO: add SuperAdmin, Agency emails
        const nt = {
          _parentId: item._id,
          abnormal: {
            active: false,
            emails: [],
            strEmails: "",
            delayed: {
              retentionTime: 3600,
            },
          },
          dataMon: {
            active: false,
          },
          report: {
            active: true,
            emails: [],
            strEmails: "",
            language: "en",
            content: {
              overviewPage: false,
              premiumReport: false,
            },
            sendDelay: {
              premiumReport: 0,
            },
            useConfirmation: {
              premiumReport: true,
            },
          },
        }

        $scope.report_lang = _.find($scope.languageList, { lang: nt.report.language })

        return nt
      }

      $scope.openNotificationDlg = function (companyData) {
        const item = companyData.company
        $scope.isOpenInputDlg = false
        $scope.isOpenPremiumReportDlg = false
        $scope.isOpenEmailReportDlg = false
        $scope.notification = _defaultNotificationEmails(item)

        $scope._notification = { companyName: item.name }
        NotificationSrv.Company.get(
          { id: item._id, _: Date.now() },
          function (res) {
            const nt = res != null ? res.toJSON() : undefined
            if (!(nt != null ? nt.abnormal : undefined)) {
              return
            }

            $scope.notification = nt
            $scope.notification.report.active = true //UCNT-4050, always set to True
            $scope.notification.abnormal.strEmails = $scope.notification.abnormal.emails.join(";")

            $scope.ntForm.$setPristine()
          },
          (_err) => $scope.ntForm.$setPristine()
        )

        $scope.isOpenNotificationDlg = true
        return $anchorScroll()
      }

      $scope.openOverviewReportDlg = function (companyData) {
        const item = companyData.company
        $scope.isOpenInputDlg = false
        $scope.isOpenPremiumReportDlg = false
        $scope.isOpenNotificationDlg = false
        $scope.notification = _defaultNotificationEmails(item)
        // 소속 타임존 알아내기
        // overview : 1:50분 이후
        // premium 2:50분 이후
        const storeList = $filter("filter")($scope.stores, (store) => store._companyId === item._id)
        $scope.notificationLastTimezoneStore = _.minBy(storeList, "timeOffset")

        $scope._notification = {
          companyName: item.name,
          expectTime: {
            overview: "",
          },
        }
        $scope._notification.expectTime.overview = moment
          .tz(
            "01:00",
            "HH:mm",
            $scope.notificationLastTimezoneStore != null
              ? $scope.notificationLastTimezoneStore.timezone
              : undefined
          )
          .format("HH:mm Z")
        NotificationSrv.Company.get(
          { id: item._id, _: Date.now() },
          function (res) {
            const nt = res != null ? res.toJSON() : undefined

            $scope.notification = nt
            $scope.notification.report.active = true //UCNT-4050, always set to True
            $scope.notification.report.strEmails = $scope.notification.report.emails.join(";")
            $scope.report_lang = _.find($scope.languageList, {
              lang: $scope.notification.report.language,
            })

            return $scope.emailReportForm.$setPristine()
          },
          (_err) => $scope.emailReportForm.$setPristine()
        )

        $scope.isOpenEmailReportDlg = true
        return $anchorScroll()
      }

      $scope.removeItem = function (companyData) {
        const item = companyData.company
        return ApiSrv.getAllStore({ companyId: item._id, isCache: false })
          .then(function (stores) {
            if (stores != null ? stores.length : undefined) {
              return alertDlg({
                title: Locale.string("msg_alert_head_remove_company"),
                message: Locale.string("msg_alert_body_remove_company"),
              })
            } else {
              return conformDlg({ title: Locale.string("msg_confirm_remove_company") }).then(() => {
                return CompanyAdminSrv.delete(
                  { id: item._id },
                  (_sobj, _res) => $scope.reload(false, () => HeaderSrv.emitter.reload()),
                  function (err) {
                    if (err.statusText != null) {
                      $window.alert(`${err.statusText}`)
                    }
                  }
                )
              })
            }
          })
          .catch(function (err) {
            if ((err != null ? err.statusText : undefined) != null) {
              $window.alert(`${err.statusText}`)
            }
          })
      }

      $scope.reload = function (isCache, callback) {
        if (isCache == null) {
          isCache = false
        }
        if (callback == null) {
          callback = function () {}
        }
        if (!env.isLocalgrey) {
          StoreSalesSrv.getAllDrivers(function (err, driverList) {
            if (err) {
              return console.error(err)
            }
            $scope.salesNoneDriver = _.find(driverList, { name: "NONE" })
            _.remove(driverList, $scope.salesNoneDriver)
            $scope.salesdrivers = driverList
            return ($scope.defaultDriver = _.find(driverList, { name: "standard" }))
          })
        }

        return async.parallel(
          [
            (cback) =>
              ApiSrv.getAllAgency({ isCache: isCache }).then(function (res) {
                $scope.agencies = _.map(res, (element) => ({
                  _id: element._id,
                  _spaceId: element._spaceId,
                  name: element.name,
                  indexName: `${element.name} [${element.index}]`,
                  active: element.active,

                  policy: {
                    noPersonalData:
                      (element.policy != null ? element.policy.noPersonalData : undefined) != null
                        ? element.policy != null
                          ? element.policy.noPersonalData
                          : undefined
                        : false,
                  },
                }))
                return cback()
              }),
            (cback) =>
              ApiSrv.getAllStore({ isCache: isCache }).then(function (res) {
                $scope.stores = _.map(res, function (element) {
                  const offset = moment().tz(element.timezone).format("Z")
                  return {
                    _id: element._id,
                    _companyId: element._companyId,
                    name: element.name,
                    timezone: element.timezone,
                    offset: offset,
                    timeOffset: moment().utcOffset(offset).utcOffset(),
                  }
                })
                return cback()
              }),
            (cback) =>
              ApiSrv.getAllCamera({ isCache: isCache }).then(function (res) {
                $scope.cameras = _.map(res, function (element) {
                  const offset = moment().tz(element.timezone).format("Z")
                  return {
                    _companyId: element._companyId,
                    _storeId: element._storeId,
                    name: element.name,
                    timezone: element.timezone,
                    offset: offset,
                    timeOffset: moment().utcOffset(offset).utcOffset(),
                  }
                })
                return cback()
              }),
          ],
          function (_err, _results) {
            const loadCompanyList = function (companyList) {
              if (!companyList) {
                return
              }
              if (!_.isArray(companyList)) {
                companyList = [companyList]
              }
              const dataList = companyList.map(function (company) {
                const stores = $scope.stores.filter((d) => d._companyId === company._id)
                const cameras = $scope.cameras.filter((d) => d._companyId === company._id)
                return {
                  company,
                  indexName: `${company.name} [${company.index}]`,
                  agency: _.find($scope.agencies, { _id: company._agencyId }),
                  stores,
                  nbrStores: stores.length,
                  cameras,
                  nbrSensors: cameras.length,
                }
              })

              loadItems(dataList)
            }

            const getCompaniesP = !$scope.showSelectedOrganization
              ? ApiSrv.getAllCompany({ isCache: isCache })
              : HeaderSrv.fetchCurrentCompany(isCache)

            return getCompaniesP.then(function (res) {
              callback()
              return loadCompanyList(res)
            })
          }
        )
      }

      var loadItems = function (items) {
        $scope.Items = items
        const _getData = function ($defer, params) {
          //const filter = $scope.searchKeywords
          const sorting = $scope.row
          const count = params.count()
          //const page = params.page()
          $scope.pagecount = count

          $scope.filterItems = $filter("filter")($scope.Items, function (val) {
            const str = new RegExp(`.*${$scope.searchKeywords}.*`, "i")

            return (
              __guard__(
                __guard__(val != null ? val.agency : undefined, (x1) => x1.name),
                (x) => x.match(str)
              ) != null ||
              __guard__(val != null ? val.company.name : undefined, (x2) => x2.match(str)) != null
            )
          })

          params.total($scope.filterItems.length)
          const orderItems = $filter("orderBy")($scope.filterItems, sorting)

          const start = (params.page() - 1) * params.count()
          const end = start + params.count()
          $scope.currentPageItems = orderItems.slice(start, end)
          return $defer.resolve($scope.currentPageItems)
        }

        return ($scope.tableParams = new NgTableParams(
          { count: $scope.pagecount != null ? $scope.pagecount : 25 },
          { counts: [10, 25, 50, 100], getData: _getData }
        ))
      }

      // filter
      $scope.searchKeywords = ""
      $scope.row = "name"

      $scope.enterKeyword = function (keyword) {
        $scope.searchKeywords = escapeStringRegexp(keyword)
        return loadItems($scope.Items)
      }

      $scope.order = function (rowName) {
        if ($scope.currentPageItems.length < 1) {
          return
        }
        $scope.row = rowName
        loadItems($scope.Items)
      }

      $scope.isLoggedIn = true

      $scope.getPrivacyLawStatus = function () {
        if (
          __guard__(
            __guard__($scope.form != null ? $scope.form.policy : undefined, (x1) => x1.privacyLaw),
            (x) => x.companyAdmin
          ) ||
          __guard__(
            __guard__($scope.form != null ? $scope.form.policy : undefined, (x3) => x3.privacyLaw),
            (x2) => x2.superAdmin
          )
        ) {
          $scope.usePrivacyLaw = true
          $scope.form.policy.fixedSnapshot.active = true
          return strActive
        } else {
          $scope.usePrivacyLaw = false
          return strInactive
        }
      }

      const updateFaceToAll = (form, callback) =>
        async.waterfall(
          [
            (step) =>
              ApiSrv.getAllStore({ companyId: form._id, isCache: false }).then(
                function (stores) {
                  if (!stores) {
                    return step("no stores")
                  }
                  return step(null, stores)
                },
                function (err) {
                  console.error(err)
                  return step("fail to get store list")
                }
              ),
            (stores, step) =>
              ApiSrv.getAllCamera({ companyId: form._id, isCache: false }).then(
                function (cameras) {
                  try {
                    cameras.forEach(function (cam, index) {
                      const store = _.find(stores, { _id: cam._storeId })
                      return (cameras[index].timezone =
                        store.timezone != null ? store.timezone : "UTC")
                    })
                  } catch (ex) {
                    return step(ex)
                  }

                  return step(null, cameras)
                },
                function (err) {
                  console.error(err)
                  return step("fail to get camera list")
                }
              ),
          ],
          function (err, cameras) {
            if (err) {
              return callback(err)
            }

            const cameraLimit = 3
            const face = {
              quota: form.face.quota,
              engine: form.face.engine,
            }

            return async.eachLimit(
              cameras,
              cameraLimit,
              function (camera, next) {
                if (!camera.functions.use.face) {
                  return next()
                }
                return FaceDeviceSrv.set(
                  { id: camera._id },
                  { face },
                  (_res) => next(),
                  (_error) => next(`fail to update ${camera.accessKey}`)
                )
              },
              (err) => callback(err)
            )
          }
        )

      var updateLifeCycle = function (company, callback) {
        if (callback == null) {
          callback = function () {}
        }
        return CompanyAdminSrv.updateLifeCycle(
          { id: company._id },
          company,
          (_sobj, _res) => callback(null),
          (err) => callback(err)
        )
      }

      $scope.applyToAllSensors = function () {
        $scope.isUpdatingFace = true

        return updateFaceToAll($scope.form, function (err) {
          if (err) {
            $scope.errorMessage = err
          }
          $scope.isUpdatingFace = false

          return RestApi.Company.update($scope.form._id, { face: $scope.form.face }).then(() => {
            $scope.reload(false, () => (original.face = _.cloneDeep($scope.form.face)))
          })
        })
      }

      $scope.changeCompany = function (agency) {
        if (agency != null ? agency.policy.noPersonalData : undefined) {
          $scope.form.face.retentionPeriod = 0
          $scope.form.policy.hideCamerasMenu = true
          $scope.form.policy.fixedSnapshot.active = true
        }
      }

      const updateHeatmapWeight = (type, company, callback) =>
        async.waterfall(
          [
            (next) =>
              ApiSrv.getAllStore({ companyId: company._id, isCache: false }).then(
                (stores) => next(null, stores),
                (err) => next(err)
              ),
            function (stores, next) {
              if (!(stores != null ? stores.length : undefined)) {
                return next("no store")
              }

              return async.each(
                stores,
                function (store, step) {
                  if (!(store != null ? store.heatmapWeight : undefined)) {
                    store.heatmapWeight = { pass: 1, dwell: 1 }
                  }
                  store.heatmapWeight[type] = company.heatmapWeight[type]
                  return StoreAdminSrv.update(
                    { id: store._id },
                    store.data,
                    function (_res) {
                      ApiSrv.getStore({ id: store._id, isCache: false })
                      return step(null)
                    },
                    (err) => step(err)
                  )
                },
                (err) => next(err)
              )
            },
          ],
          function (err, _res) {
            if (err === "no store") {
              err = null
            }
            return callback(err)
          }
        )

      $scope.applyToAllHeatmapWeight = function (type) {
        if ($scope.isDisableHeatmapWeightBtn(type)) {
          return
        }
        $scope.setHeatmapWeightState(type, "update")

        updateHeatmapWeight(type, $scope.form, function (err) {
          if (err) {
            $scope.errorMessage = err
            return $scope.setHeatmapWeightState(type)
          } else {
            $scope.setHeatmapWeightState(type, "complete")

            return RestApi.Company.update($scope.form._id, {
              heatmapWeight: $scope.form.heatmapWeight,
            }).then(() => $scope.reload(false))
          }
        })
      }

      $scope.isDisableHeatmapWeightBtn = function (type) {
        if (
          $scope.onCreating() ||
          ($scope.tsState != null ? $scope.tsState[type] : undefined) === "update"
        ) {
          return true
        }
        if (type === "pass") {
          return !$scope.form_create.heatmapWeightPass.$valid
        } else {
          return !$scope.form_create.heatmapWeightDwell.$valid
        }
      }

      $scope.setHeatmapWeightState = function (type, state) {
        if (state == null) {
          state = "clear"
        }
        if (!$scope.heatmapWeightState) {
          $scope.heatmapWeightState = {}
        }
        return ($scope.heatmapWeightState[type] = state)
      }

      $scope.onCreating = () => $scope.inputDlgTitle === "Create"

      const arrString2Integer = function (str) {
        if (typeof str !== "string") {
          str = str.toString()
        }
        const ts = str.split(",").map(Number)
        const valid =
          Number.MAX_SAFE_INTEGER !==
          ts.reduce(function (p, c) {
            if (c > p) {
              return c
            } else {
              return Number.MAX_SAFE_INTEGER
            }
          }, 0)
        return [valid, ts]
      }

      $scope.setTSState = function (type, state) {
        if (state == null) {
          state = "clear"
        }
        if (type === "zoneTraffic") {
          if ($scope.ztTimeSegmentSelector.selected === $scope.KZTTimeSegmentName.CUSTOM) {
            validateZTTimeSegment()
          }
        } else {
          const [valid, ts] = Array.from(arrString2Integer($scope.form[type].timeSegments))
          $scope.form_create.tsDwellmap.$setValidity("value", valid)
          if (valid) {
            $scope.form[type].timeSegments = ts
          }
        }

        $scope.tsState[type] = state
        if (type === "zoneTraffic" && state === "complete") {
          originalZTTimeSegment = _.clone($scope.form.zoneTraffic.timeSegments)
        }
      }

      $scope.isDisabledTSBtn = function (type) {
        if (
          $scope.onCreating() ||
          ($scope.tsState != null ? $scope.tsState[type] : undefined) === "update" ||
          ($scope.analysisTrackWorkerStatus != null
            ? $scope.analysisTrackWorkerStatus.start
            : undefined)
        ) {
          return true
        }
        if (type === "zoneTraffic") {
          return !$scope.isChangedZTTimeSegment() || !$scope.isValidateZTTimeSegment()
        } else {
          return !$scope.form_create.tsDwellmap.$valid
        }
      }

      $scope.applyToAllTS = function (type) {
        const company = $scope.form
        const dlgOption = {
          title: Locale.string("msg_confirm_apply_to_all_sensors"),
          option: [
            {
              message: Locale.string("Update All Past Data"),
              checked: false,
            },
          ],
        }

        return conformDlg(dlgOption).then(function () {
          $scope.setTSState(type, "update")
          return ApiSrv.getAllCamera({ companyId: company._id, isCache: false })
            .then(function (cameras) {
              const targets = cameras.filter((c) => c.functions.use[type.toLowerCase()])
              return $q.all(
                targets.map(function (camera) {
                  const data = { dwellConfig: {} }
                  if (type === "zoneTraffic") {
                    data.dwellConfig.tsRoi = company.zoneTraffic.timeSegments
                  } else {
                    data.dwellConfig.tsDwell = company.dwellmap.timeSegments
                  }
                  return CameraConfigSrv.set({ id: camera._id, item: "trackAnalysis" }, data)
                })
              )
            })
            .then(function () {
              // TODO: get past data range
              const opt = {
                contents: type,
                companyId: company._id,
                async: true,
                analysis: true,
                subsampling: true,
                nParallelCamera: 2,
                nParallelS3Track: 8,
                //from: "2019-03-21"
                //to: "2019-03-22"
              }
              if (dlgOption.option[0].checked) {
                return ApiSrv.requestTrackAnalsis(opt)
              } else {
                return Promise.resolve()
              }
            })
            .then(function () {
              $scope.setTSState(type, "complete")

              return RestApi.Company.update(company._id, {
                [type]: company[type],
              })
            })
            .then(() => $scope.reload(false, () => (original[type] = _.cloneDeep(company[type]))))
            .catch(function (err) {
              console.warn("applyToAllTS error", err)
              $scope.errorMessage = JSON.stringify(err.data, null, 2)
              return $scope.setTSState(type)
            })
        })
      }

      $scope.applyAgeGroup = () => {
        return CompanyAdminSrv.update(
          { id: $scope.form._id },
          { ageGroup: $scope.ageGroup }
        ).$promise.then(() => $scope.reload(false))
      }

      const alertDlg = (data) =>
        ngDialog.openConfirm({
          template: "components/popup/popup-alert.html",
          data,
          closeByEscape: true,
          closeByDocument: true,
          showClose: false,
          className: "ngdialog-theme-default popup-alert",
          controller: "ucPopupAlertCtrl",
        })

      const conformDlg = (data) =>
        ngDialog.openConfirm({
          template: "components/popup/popup-confirm.html",
          data,
          closeByEscape: true,
          closeByDocument: true,
          showClose: false,
          className: "ngdialog-theme-default popup-confirm",
          controller: "ucPopupConfirmCtrl",
        })

      $scope.applyToAllSnapshot = function () {
        const company = $scope.form
        CompanyAdminSrv.updateFixedSnapshot({ id: company._id }, company.policy.snapshot)
          .$promise.then(function () {
            return RestApi.Company.update(company._id, {
              policy: company.policy,
            })
          })
          .then(() => {
            $scope.reload(false, () => (original.policy = _.cloneDeep(company.policy)))
          })
          .catch(function (err) {
            return console.warn("applyToAllFixedSnapshot error: ", err)
          })
      }

      //$scope.reload() is called by showSelectedOrganization
      //$scope.reload()

      $scope.hasError = function (index) {
        const error =
          ($scope.form_create[`zt-${index}`] != null
            ? $scope.form_create[`zt-${index}`].$error
            : undefined) != null
            ? $scope.form_create[`zt-${index}`] != null
              ? $scope.form_create[`zt-${index}`].$error
              : undefined
            : {}
        return Object.keys(error).length > 0
      }

      $scope.getErrorMessage = function (index) {
        const error =
          ($scope.form_create[`zt-${index}`] != null
            ? $scope.form_create[`zt-${index}`].$error
            : undefined) != null
            ? $scope.form_create[`zt-${index}`] != null
              ? $scope.form_create[`zt-${index}`].$error
              : undefined
            : {}
        switch (false) {
          case !error.required:
            return Locale.string("Enter time segment")
          case !error.min:
          case !error.max:
            return Locale.string("Enter valid range") + `(${$scope.MIN_IME} ~ ${$scope.MAX_TIME})`
          case !error.value:
            return Locale.string("Enter valid time segment")
          default:
            return ""
        }
      }

      $scope.isValidateZTTimeSegment = function () {
        const value = $scope.form.zoneTraffic.timeSegments
        for (
          let i = 0, end = value.length, asc = 0 <= end;
          asc ? i < end : i > end;
          asc ? i++ : i--
        ) {
          if ($scope.hasError(i)) {
            return false
          }
        }

        return true
      }

      const isAscending = function (arr) {
        let left, left1
        return __range__(0, arr.length, false).map(
          (i) =>
            ((left = arr[i - 1]) != null ? left : 0) < arr[i] &&
            arr[i] < ((left1 = arr[i + 1]) != null ? left1 : Infinity)
        )
      }

      var validateZTTimeSegment = function () {
        const timeSegment = $scope.form.zoneTraffic.timeSegments
        const asc = isAscending(timeSegment)
        return (() => {
          const result = []
          for (
            let i = 0, end = timeSegment.length, asc1 = 0 <= end;
            asc1 ? i < end : i > end;
            asc1 ? i++ : i--
          ) {
            if ($scope.form_create[`zt-${i}`] != null) {
              $scope.form_create[`zt-${i}`].$setDirty()
            }
            result.push(
              $scope.form_create[`zt-${i}`] != null
                ? $scope.form_create[`zt-${i}`].$setValidity(
                    "value",
                    asc[i] != null ? asc[i] : true
                  )
                : undefined
            )
          }
          return result
        })()
      }

      $scope.changeZTTimeSegment = function () {
        const name = $scope.ztTimeSegmentSelector.selected
        _.set($scope.form, "zoneTraffic.timeSegments", $scope.ztTimeSegmentValues[name])
        return $scope.setTSState("zoneTraffic")
      }

      $scope.editZTTimeSegmentRow = function (value, index) {
        $scope.form.zoneTraffic.timeSegments[index] = value
        $scope.ztTimeSegmentValues.custom[index] = value
        return $scope.setTSState("zoneTraffic")
      }

      $scope.addZTTimeSegmentRow = function () {
        const lastIdx = $scope.form.zoneTraffic.timeSegments.length - 1
        $scope.form.zoneTraffic.timeSegments.splice(lastIdx, 0, null)
        $scope.ztTimeSegmentValues.custom = _.clone($scope.form.zoneTraffic.timeSegments)
        return $scope.setTSState("zoneTraffic")
      }

      $scope.deleteZTTimeSegmentRow = function (index) {
        $scope.form.zoneTraffic.timeSegments.splice(index, 1)
        $scope.ztTimeSegmentValues.custom = _.clone($scope.form.zoneTraffic.timeSegments)
        return $scope.setTSState("zoneTraffic")
      }

      $scope.isCustomZTTimeSegment = () =>
        $scope.ztTimeSegmentSelector.selected === $scope.KZTTimeSegmentName.CUSTOM

      $scope.isChangedZTTimeSegment = () =>
        !_.isEqual(originalZTTimeSegment, $scope.form.zoneTraffic.timeSegments)

      var initZTTimeSegment = function (ztTimeSegment) {
        originalZTTimeSegment = _.cloneDeep(ztTimeSegment)
        $scope.ztTimeSegmentValues.custom = _.cloneDeep($scope.ztTimeSegmentValues.standard)

        if (
          _.isEqual(ztTimeSegment, $scope.ztTimeSegmentValues[$scope.KZTTimeSegmentName.STANDARD])
        ) {
          return ($scope.ztTimeSegmentSelector.selected = $scope.KZTTimeSegmentName.STANDARD)
        } else if (
          _.isEqual(ztTimeSegment, $scope.ztTimeSegmentValues[$scope.KZTTimeSegmentName.SHORTTIME])
        ) {
          return ($scope.ztTimeSegmentSelector.selected = $scope.KZTTimeSegmentName.SHORTTIME)
        } else if (
          _.isEqual(ztTimeSegment, $scope.ztTimeSegmentValues[$scope.KZTTimeSegmentName.LONGTIME])
        ) {
          return ($scope.ztTimeSegmentSelector.selected = $scope.KZTTimeSegmentName.LONGTIME)
        } else {
          $scope.ztTimeSegmentSelector.selected = $scope.KZTTimeSegmentName.CUSTOM
          return ($scope.ztTimeSegmentValues.custom = _.cloneDeep(ztTimeSegment))
        }
      }

      $scope.displayTimeFormat = function (val) {
        let n, unit
        val = Number(val)
        if (val >= 60) {
          n = val / 60
          unit = Locale.string("symbol_min")
        } else {
          n = val
          unit = Locale.string("symbol_sec")
        }

        return `${parseFloat(n.toFixed(1))}${unit}`
      }

      $scope.selectedCompanyData = null
      $scope.openPremiumReportDlg = function (companyData) {
        const item = companyData != null ? companyData.company : undefined
        $scope.isOpenPremiumReportDlg = true
        $scope.isOpenInputDlg = false
        $scope.isOpenEmailReportDlg = false
        $scope.isOpenNotificationDlg = false
        $scope.reportStoreList = $filter("filter")(
          $scope.stores,
          (store) => store._companyId === item._id
        )
        $scope.selectedCompanyData = _.clone(item)
        return $anchorScroll()
      }

      $scope.handlePRUpdate = function (openPRDlg = false) {
        $scope.isOpenPremiumReportDlg = openPRDlg
        return $scope.reload(false, () => HeaderSrv.emitter.reload())
      }

      const isChangedBizHours = (original, item) =>
        !angular.equals(original.schedule, item.schedule)
      const updateHolidays = (item) =>
        new Promise(function (resolve, reject) {
          if (!isChangedBizHours(original, item)) {
            item.schedule.holidays = item.schedule.holidays.map((d) => d._id)
            item.schedule.workdays = item.schedule.workdays.map((d) => d._id)
            return resolve()
          }

          return Promise.all([
            Promise.mapSeries($scope.deletedIcalendars, (icalendar) =>
              ApiSrv.deleteIcalendar({ id: icalendar._id })
            ),

            Promise.mapSeries(item.schedule.holidays, (holiday, idx) =>
              ApiSrv.updateIcalendar(holiday).then((res) => (item.schedule.holidays[idx] = res._id))
            ),
            Promise.mapSeries(item.schedule.workdays, (workday, idx) =>
              ApiSrv.updateIcalendar(workday).then((res) => (item.schedule.workdays[idx] = res._id))
            ),
          ])
            .then(() => resolve())
            .catch((err) => reject(err))
        })
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
