/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("heatmaptagViewer", () => ({
    restrict: "E",

    template: [
      '<div class="tag-container" style="position:relative;">',
      '<canvas id="heatmaptagCanvas"></canvas>',
      "</div>",
    ].join(""),

    controller: "heatmaptagViewerCtrl",
    controllerAs: "vm",

    scope: {
      tagList: "=",
      width: "=",
      height: "=",
      canNotModify: "=",
      snapshotUrl: "=",
      drawingTag: "=?",
      mode: "=",
      editOptions: "=",
      showTagname: "=?",
      showInactiveTag: "=?",
    },
  }))
