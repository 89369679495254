"use strict"

import angular from "angular"

angular.module("uCountitUiApp").config(($stateProvider) => {
  $stateProvider
    .state("app.admin.restfulapi", {
      url: "/restfulapi",
      templateUrl: "app/admin/restfulapi/list.html",
      controller: "RestfulApiListCtrl",
    })
    .state("app.admin.restfulapi.new", {
      url: "/new",
      templateUrl: "app/admin/restfulapi/form.html",
      controller: "RestfulApiNewCtrl",
    })

    .state("app.admin.restfulapi.edit", {
      url: "/:id/edit",
      templateUrl: "app/admin/restfulapi/form.html",
      controller: "RestfulApiEditCtrl",
    })
})
