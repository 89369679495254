/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS104: Avoid inline assignments
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

const heatmapCommon = require("../../../components/heatmappanel/heatmapCommon")
const mng = require("common-library/product-core/heatmapData")

angular
  .module("uCountitUiApp")
  .controller(
    "StoreHeatmapCtrl",
    function (
      $scope,
      $state,
      $stateParams,
      $location,
      $filter,
      $q,
      $timeout,
      $window,
      $element,
      ApiSrv,
      Auth,
      SelectorSrv,
      $cacheFactory,
      hotkeys,
      ngDialog
    ) {
      let left
      let key
      const cache =
        (left = $cacheFactory.get("heatmapCache")) != null ? left : $cacheFactory("heatmapCache")
      const cacheVMKey = "ViewMode"
      const cacheSDKey = "ShowData"

      $scope.shortcuts = [
        {
          combo: "1",
          description: "Show 1 view mode",
          callback() {
            return $scope.changeViewMode($scope.VIEWMODE.ONE)
          },
        },
        {
          combo: "4",
          description: "Show 4 view mode",
          callback() {
            return $scope.changeViewMode($scope.VIEWMODE.FOUR)
          },
        },
        {
          combo: "9",
          description: "Show 9 view mode",
          callback() {
            return $scope.changeViewMode($scope.VIEWMODE.NINE)
          },
        },
        {
          combo: "t",
          description: "View traffic heatmap only",
          callback() {
            return $scope.changeDualViewMode($scope.SHOWDATA.PASS)
          },
        },
        {
          combo: "i",
          description: "View interest heatmap only",
          callback() {
            return $scope.changeDualViewMode($scope.SHOWDATA.DWELL)
          },
        },
        {
          combo: "d",
          description: "View dual heatmap",
          callback() {
            return $scope.changeDualViewMode($scope.SHOWDATA.DUAL)
          },
        },
        {
          combo: ["right", "pageup"],
          description: "Page up the sensor index",
          callback() {
            return $scope.movePage(1)
          },
        },
        {
          combo: ["left", "pagedown"],
          description: "Page down the sensor index",
          callback() {
            return $scope.movePage(-1)
          },
        },

        // heatmapPanel directives
        {
          combo: "0",
          description: "Reset the scale bar position",
          callback() {
            return $scope.$emit("heatmap_scale_update", 250)
          },
        },
        {
          combo: "=",
          description: "Increase the scale bar position",
          callback() {
            return $scope.$emit("heatmap_scale_dx_update", 25)
          },
        },
        {
          combo: "-",
          description: "Decrease the scale bar position",
          callback() {
            return $scope.$emit("heatmap_scale_dx_update", -25)
          },
        },
        {
          combo: "]",
          description: "Move the time bar forward",
          callback() {
            return $scope.$emit("heatmap_timebar_dx_update", 1)
          },
        },
        {
          combo: "[",
          description: "Move the time bar backward",
          callback() {
            return $scope.$emit("heatmap_timebar_dx_update", -1)
          },
        },
      ]

      $scope.PAGE_MAX_SIZE_MIN = 3
      $scope.PAGE_MAX_SIZE_MAX = 5
      $scope.VIEWMODE = {
        ONE: "1",
        FOUR: "4",
        NINE: "9",
      }
      $scope.SAMPLING = {
        HOUR: "hour",
        DAY: "day",
        MONTH: "month",
      }
      $scope.SHOWDATA = {
        PASS: "pass",
        DWELL: "dwell",
        DUAL: "dual",
      }

      $scope.samplingList = (() => {
        const result = []
        for (key in $scope.SAMPLING) {
          var sampling = $scope.SAMPLING[key]
          result.push({ key: sampling, sampling, title: `By ${sampling}` })
        }
        return result
      })()
      $scope.samplingList.push({ key: "accumulated", sampling: "day", title: "Day Average" })

      $scope.pg = {}
      $scope.pageMaxSize = $scope.PAGE_MAX_SIZE_MIN
      // bug - https://stackoverflow.com/questions/34775157/angular-ui-bootstrap-pagination-ng-model-not-updating
      $scope.pg.curPage = 1
      $scope.totalCameraCount = 0
      $scope.currentPageCameras = []
      $scope.displayCount = $scope.VIEWMODE.ONE
      $scope.allCameras = []
      $scope.diffDays = 2
      $scope.imageRatioType = [
        { name: "original" },
        { name: "16:9", ratio: 0.5625 },
        { name: "4:3", ratio: 0.75 },
      ]
      $scope.validTime = false
      $scope.isCamera = false

      $scope.pageOptions = {
        storeId: $stateParams.id,
        sampling: $scope.SAMPLING.DAY,
        displayOption: {
          imageRatio: $scope.imageRatioType[0], //getDefaultImageRatio()
          viewMode: $scope.displayCount,
          showData: $scope.SHOWDATA.DUAL,
        },
        content: "dual heatmap",
      }
      $scope.snapshotRatio = undefined

      $scope.movePage = function (dx) {
        if (!$scope.totalCameraCount) {
          return
        }
        const maxPage = Math.ceil($scope.totalCameraCount / $scope.displayCount)
        let idx = $scope.pg.curPage + dx
        if (idx < 1) {
          idx = 1
        }
        if (idx > maxPage) {
          idx = maxPage
        }

        if ($scope.pg.curPage !== idx) {
          $scope.pg.curPage = idx
          return $scope.reloadHeatmapCameras()
        }
      }

      const getMaxSize = function () {
        if ($scope.totalCameraCount <= $scope.PAGE_MAX_SIZE_MIN) {
          return $scope.PAGE_MAX_SIZE_MIN
        }

        if (
          $scope.pg.curPage - 1 < $scope.PAGE_MAX_SIZE_MIN ||
          $scope.totalCameraCount - $scope.pg.curPage < $scope.PAGE_MAX_SIZE_MIN
        ) {
          return $scope.PAGE_MAX_SIZE_MAX
        } else {
          return $scope.PAGE_MAX_SIZE_MIN
        }
      }

      $scope.reloadHeatmapCameras = function () {
        //return  if not $scope.allCameras.length or not $scope.store or not $scope.validTime

        const selectedCameras = _.filter($scope.allCameras, (item) => item.selected)
        $scope.totalCameraCount =
          (selectedCameras != null ? selectedCameras.length : undefined) != null
            ? selectedCameras != null
              ? selectedCameras.length
              : undefined
            : 0
        $scope.pageMaxSize = getMaxSize()
        $scope.currentPageCameras = []
        const startIdx = $scope.displayCount * ($scope.pg.curPage - 1)
        const endIdx = $scope.displayCount * $scope.pg.curPage - 1
        const pageCameras = selectedCameras.slice(startIdx, +endIdx + 1 || undefined)

        const _getCamera = (camera) =>
          ApiSrv.getCamera({ id: camera._id }).then(function (cam) {
            cam.storeName = $scope.store.name
            cam.exportFilename = `${$scope.store.name}_${cam.name}_heatmap.csv`
            return cam
          })

        return $q
          .all(pageCameras.map((cam) => _getCamera(cam)))
          .then(function (cams) {
            const setWidthPromise = new Promise(function (resolve, reject) {
              if (isFitHeightMode() && (cams != null ? cams.length : undefined) > 0) {
                const image = new Image()
                image.onload = function () {
                  $scope.snapshotRatio = this.height / this.width
                  setWidth()
                  return resolve()
                }
                return (image.src = cams[0].heatmapUrl())
              } else {
                clearWidth()
                return resolve()
              }
            })

            return setWidthPromise.then(() => ($scope.currentPageCameras = cams))
          })
          .catch((err) => console.error(err))
      }

      // eslint-disable-next-line no-unused-vars
      const getDefaultImageRatio = () =>
        // TODO : How to get default image ratio
        $scope.imageRatioType[0]

      $scope.changeViewMode = function (value) {
        if (value != null) {
          $scope.pageOptions.displayOption.viewMode = value
        } else {
          value = $scope.pageOptions.displayOption.viewMode
        }
        const { showData } = $scope.pageOptions.displayOption
        $scope.pg.curPage = 1
        $scope.displayCount = value
        $scope.displayClass = getDisplayClass(value, showData)
        $scope.extendViewMode = getExtendViewMode(value, showData)
        $scope.reloadHeatmapCameras()

        if ($stateParams.orgType === "store") {
          return cache.put(cacheVMKey, value)
        }
      }

      $scope.$watch("pageOptions.displayOption.viewMode", (value) => $scope.changeViewMode())

      var getExtendViewMode = (mode, type) =>
        mode === $scope.VIEWMODE.ONE && type === $scope.SHOWDATA.DUAL

      var getDisplayClass = function (mode, type) {
        if (mode === $scope.VIEWMODE.ONE || type === $scope.SHOWDATA.DUAL) {
          return "col-sm-12"
        } else if (mode === $scope.VIEWMODE.FOUR) {
          return "col-sm-6"
        } else if (mode === $scope.VIEWMODE.NINE) {
          return "col-sm-4"
        }
      }

      $scope.$watch("pageOptions.displayOption.showData", (value) =>
        $scope.changeDualViewMode(value)
      )

      $scope.changeDualViewMode = function (value) {
        if (value !== $scope.pageOptions.displayOption.showData) {
          $scope.pageOptions.displayOption.showData = value
        }

        $scope.pageOptions.content = value === $scope.SHOWDATA.DUAL ? "dual heatmap" : value
        const showData = value
        const { viewMode } = $scope.pageOptions.displayOption
        if (showData === $scope.SHOWDATA.DUAL) {
          $scope.dataList = ["pass", "dwell"]
        } else {
          $scope.dataList = [`${showData}`]
        }

        $scope.displayClass = getDisplayClass(viewMode, showData)
        $scope.extendViewMode = getExtendViewMode(viewMode, showData)

        $scope.reloadHeatmapCameras()
        return cache.put(cacheSDKey, value)
      }

      $scope.selectAllCameraBy = function (selection, reload) {
        if (reload == null) {
          reload = true
        }
        $scope.allCameras.forEach((item, key) => (item.selected = selection))

        if (reload) {
          return $scope.reloadHeatmapCameras()
        }
      }

      $scope.toggleCamera = function (camera) {
        if (!camera.selected) {
          const idx = _.findIndex($scope.currentPageCameras, { _id: camera._id })
          if (idx > -1) {
            $scope.currentPageCameras.splice(idx, 1)
          }
        }

        return $scope.reloadHeatmapCameras()
      }

      $scope.$on("heatmap_timebar_update", (event, min) =>
        $scope.$broadcast("move_timebar_global", min)
      )

      $scope.$on("heatmap_timebar_dx_update", (event, dx) =>
        $scope.$broadcast("move_timebar_dx_global", dx)
      )

      $scope.$on("heatmap_scale_update", (event, val) =>
        $scope.$broadcast("move_scalebar_global", val)
      )

      $scope.$on("heatmap_scale_dx_update", (event, dx) =>
        $scope.$broadcast("move_scalebar_dx_global", dx)
      )

      $scope.$on("heatmap_stats", (event, stats) =>
        $scope.$broadcast("heatmap_stats_global", calcTotalStats(stats))
      )

      $scope.$on("heatmap_type_update", (event, val) =>
        $scope.$broadcast("heatmap_type_global", val)
      )

      const totalStatsList = {}

      var calcTotalStats = function (stats) {
        let id
        if (!(id = heatmapCommon.findStatId(stats))) {
          return
        }

        if (!totalStatsList[id]) {
          totalStatsList[id] = {}
        }
        for (var mapname in stats) {
          totalStatsList[id][mapname] = stats[mapname]
        }
        const tstats = []
        $scope.currentPageCameras.map(function (cam) {
          if (totalStatsList[cam.accessKey] != null) {
            return tstats.push(totalStatsList[cam.accessKey])
          }
        })

        const gstats = mng.calcTotalStats(stats, tstats)
        heatmapCommon.setGlobalStats(gstats)

        return gstats
      }

      $scope.reload = function (fromInit) {
        if (fromInit == null) {
          fromInit = false
        }
        if (fromInit) {
          $scope.validTime = true
        }

        return ($scope.param = {
          start: $scope.pageOptions.dt.startDate,
          end: $scope.pageOptions.dt.endDate,
          sampling: $scope.pageOptions.sampling,
        })
      }

      const getDefaultShowData = function (features) {
        return features.heatmap && !features.dwellmap
          ? $scope.SHOWDATA.PASS
          : !features.heatmap && features.dwellmap
          ? $scope.SHOWDATA.DWELL
          : $scope.SHOWDATA.DUAL
      }

      //######
      //# main
      const init = function () {
        heatmapCommon.changeGridSize("init")
        $scope.isCamera = $stateParams.orgType === "sensor"

        if ($stateParams.orgType === "store") {
          const viewMode = cache.get(cacheVMKey)
          if (viewMode) {
            $scope.changeViewMode(viewMode)
          }
        }

        SelectorSrv.getOrganizationInfo($stateParams.orgType, $stateParams.id)
          .then(function (orgInfo) {
            $scope.store = orgInfo.store
            $scope.storetimezone = orgInfo.timezone
            $scope.heatmapWeight = orgInfo.store.heatmapWeight
            $scope.heatmapStats = orgInfo.store.stats

            const filterCameras =
              orgInfo.sensors != null
                ? orgInfo.sensors.filter(
                    (camera) =>
                      __guard__(
                        camera.functions != null ? camera.functions.use : undefined,
                        (x) => x.heatmap
                      ) ||
                      __guard__(
                        camera.functions != null ? camera.functions.use : undefined,
                        (x1) => x1.dwellmap
                      )
                  )
                : undefined
            $scope.allCameras = $filter("orderBy")(filterCameras, "name")

            const showData = cache.get(cacheSDKey)
            $scope.pageOptions.displayOption.showData = showData
              ? showData
              : getDefaultShowData(orgInfo.features)

            if ($stateParams.orgType === "sensor") {
              $scope.changeViewMode($scope.VIEWMODE.ONE)
            }

            return $scope.selectAllCameraBy(true)
          })
          .then(
            () => $scope.$broadcast("init-range-search", $scope.storetimezone),

            function (err) {
              console.error(err)
              return $state.go("default")
            }
          )

        return $scope.shortcuts.forEach(hotkeys.bindTo($scope).add)
      }

      init()

      $scope.changeRatio = function () {
        if ($scope.snapshotRatio && isFitHeightMode()) {
          setWidth()
        }

        return true
      }

      var isFitHeightMode = function () {
        const option = $scope.pageOptions.displayOption
        return option.viewMode === $scope.VIEWMODE.ONE && option.showData !== $scope.SHOWDATA.DUAL
      }

      var setWidth = function () {
        let ratio
        const option = $scope.pageOptions.displayOption
        if (option.imageRatio.name === $scope.imageRatioType[0].name) {
          ratio = $scope.snapshotRatio
        } else {
          ;({ ratio } = option.imageRatio)
        }
        const height = window.innerHeight * 0.8
        let width = height / ratio
        const formWidth = $element.find(".heatmap-section").width()
        if (width > formWidth) {
          width = formWidth
        }

        return $element.find(".heatmap-section > .row").css("width", width)
      }

      var clearWidth = () => $element.find(".heatmap-section > .row").css("width", "")

      return angular.element($window).on("resize", function () {
        if (!($scope.snapshotRatio && isFitHeightMode())) {
          return
        }

        return setWidth()
      })
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
