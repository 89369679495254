/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

let loadingQueue = []

angular
  .module("uCountitUiApp")

  .controller(
    "ucContentsCleanerCtrl",
    function ($scope, $window, ApiSrv, Locale, DateTimeSrv, DataOperationSrv) {
      const vm = this
      vm.existData = false

      $scope.errorMessage = ""

      vm.pageOptions = {
        _cameraId: $scope.selectedItem != null ? $scope.selectedItem._id : undefined,
        _storeId: $scope.selectedItem != null ? $scope.selectedItem._storeId : undefined,
        deleteAll: true,
        contents: {
          counter: true,
          face: true,
          heatmap: true,
          dwellmap: true,
          roiheatmap: true,
          directionmap: true,
          occupancy: true,
          snapshot: true,
        },
        offset: 0,
      }
      vm.pickerOptions = {
        sampling: "day",
        rangeMaxDate: moment(),
      }

      const setDatetimeConfiguration = function () {
        if (!vm.pageOptions) {
          return
        }
        if (!vm.pickerOptions) {
          return
        }

        const configuration = DateTimeSrv.getDateTimeConfiguration()

        vm.pageOptions.dt = configuration.dt
        vm.pickerOptions.rangeMaxDate = configuration.maxDate
        return (vm.pickerOptions.ranges = configuration.ranges)
      }

      const checkAllDisable = (obj) =>
        Object.keys(obj)
          .map((k) => obj[k])
          .reduce((p, c) => p || c)

      vm.checkContents = function (type) {
        if (!checkAllDisable(vm.pageOptions.contents)) {
          return (vm.pageOptions.contents[type] = true)
        } else {
          return $scope.$broadcast("SET_CONTENTS")
        }
      }

      vm.canSubmit = function () {
        if (!$scope.isOpen) {
          return
        }
        if (
          (vm.pageOptions != null ? vm.pageOptions._cameraId : undefined) == null ||
          (vm.pageOptions != null ? vm.pageOptions._storeId : undefined) == null
        ) {
          return
        }

        return vm.existData
      }

      vm.submitForm = function () {
        if (!$window.confirm(Locale.string("msg_confirm_remove"))) {
          return
        }

        return async.map(
          Object.keys(vm.pageOptions.contents),
          function (name, callback) {
            if (vm.pageOptions.contents[name]) {
              let query = {
                contents: name,
                operation: "delete",
                to: moment().add(1, "day").startOf("day").format("YYYY-MM-DD"),
                srcId: vm.pageOptions._cameraId,
              }

              if (!vm.pageOptions.deleteAll) {
                query = _.merge({}, query, {
                  from: vm.pageOptions.dt.startDate.format("YYYY-MM-DD"),
                  to: moment(vm.pageOptions.dt.endDate).add(1, "minute").format("YYYY-MM-DD"),
                })
              }

              return DataOperationSrv.update(query)
                .$promise.then((res) => callback(null))
                .catch((err) => callback(null))
            } else {
              return callback(null)
            }
          },

          function (err, result) {
            if (err) {
              $scope.errorMessage = err
              return
            }

            return $scope.$broadcast("SET_CONTENTS")
          }
        )
      }

      vm.watchDate = function () {
        if (!$scope.isOpen) {
          return
        }

        return $scope.$broadcast("SET_CONTENTS")
      }

      $scope.$watch("vm.pageOptions.deleteAll", vm.watchDate)

      $scope.$watch("isOpen", vm.watchDate)

      $scope.$watch("selectedItem", function () {
        vm.pageOptions._cameraId = $scope.selectedItem != null ? $scope.selectedItem._id : undefined
        vm.pageOptions._storeId =
          $scope.selectedItem != null ? $scope.selectedItem._storeId : undefined

        if (vm.pageOptions._storeId) {
          return ApiSrv.getStore({ id: vm.pageOptions._storeId }).then(
            function (store) {
              vm.pageOptions.offset = moment
                .tz(store.timezone != null ? store.timezone : "UTC")
                .utcOffset()
              return $scope.$broadcast("SET_CONTENTS")
            },
            (err) => $scope.$broadcast("SET_CONTENTS")
          )
        }
      })

      setDatetimeConfiguration()
    }
  )
  .controller(
    "ucTotalContentsCtrl",
    function ($scope, ChartOptionSrv, ApiSrv, Locale, usSpinnerService) {
      const vm = ($scope.vm = this)
      vm.pageOptions = $scope.$parent.vm.pageOptions
      vm.chartData = []
      vm.chartBarOption = angular.copy(ChartOptionSrv.contentsBarChart)
      vm.chartNoDataStr = Locale.string("no data")
      vm.chartBarOption.legend = false
      vm.chartBarOption.tooltip.content = (label, x, y, item) => `${label}: ${y}`

      const DATEFORMAT = "YYYYMMDDHHmm"
      const tickName = {
        counter: Locale.string("Counter"),
        face: Locale.string("Gender/Age"),
        heatmap: Locale.string("Traffic Heatmap"),
        dwellmap: Locale.string("Dwell Heatmap"),
        roiheatmap: Locale.string("Zone Traffic"),
        directionmap: Locale.string("Flow Map"),
        occupancy: Locale.string("Occupancy"),
        snapshot: Locale.string("Snapshot"),
      }

      const showLoading = function (key) {
        if (key) {
          loadingQueue.push(key)
        }
        angular.element(".splash").show()
        usSpinnerService.spin("spinner")
        return angular.element(".spinner").css("position", "fixed")
      }

      const hideLoading = function (key) {
        if (key) {
          _.remove(loadingQueue, (raw) => raw === key)
          if (loadingQueue.length) {
            return
          }
        }

        angular.element(".splash").hide()
        return usSpinnerService.stop("spinner")
      }

      $scope.$on("SET_CONTENTS", function () {
        let name
        if (!vm.pageOptions._cameraId) {
          return
        }
        showLoading()
        $scope.errorMessage = ""

        const contents = []
        for (name of Array.from(Object.keys(vm.pageOptions.contents))) {
          if (vm.pageOptions.contents[name]) {
            contents.push(name)
          }
        }

        return async.map(
          contents,
          function (name, callback) {
            if (!vm.pageOptions.contents[name]) {
              return callback(null)
            }

            let query = {
              id: vm.pageOptions._cameraId,
              item: name,
              isCache: false,
            }
            if (name === "occupancy") {
              query.sampling = "10m"
            }

            if (!vm.pageOptions.deleteAll) {
              query = _.merge({}, query, {
                from: vm.pageOptions.dt.startDate.format(DATEFORMAT),
                to: moment(vm.pageOptions.dt.endDate).add(1, "minute").format(DATEFORMAT),
              })

              if (["dwellmap", "snapshot"].includes(name)) {
                query.from = moment(query.from, DATEFORMAT)
                  .utcOffset(vm.pageOptions.offset, true)
                  .toISOString()
                query.to = moment(query.to, DATEFORMAT)
                  .utcOffset(vm.pageOptions.offset, true)
                  .toISOString()
              }
            }

            return ApiSrv.getTotalOfCamera(query).then(
              (res) => callback(null, _.merge({}, { name }, res)),
              (err) => callback(null, _.merge({}, { name }, { count: 0 }))
            )
          },

          function (err, result) {
            let i, item
            if (err) {
              $scope.errorMessage = err
              hideLoading()
              return
            }

            vm.chartBarOption.xaxis.ticks = (() => {
              const result1 = []
              for (i = 0; i < result.length; i++) {
                item = result[i]
                result1.push([i, tickName[item.name]])
              }
              return result1
            })()
            vm.chartBarOption.xaxis.autoscaleMargin = result.length > 1 ? 0.1 : 3

            $scope.$parent.vm.existData = false
            for (item of Array.from(result)) {
              if (item.count > 0) {
                $scope.$parent.vm.existData = true
              }
            }

            vm.chartData = []

            for (i = 0; i < result.length; i++) {
              item = result[i]
              vm.chartData.push({
                label: tickName[item.name],
                data: [[i, item.count]],
              })
            }

            return hideLoading()
          }
        )
      })
    }
  )
