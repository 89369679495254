import angular from "angular"

angular
  .module("uCountitUiApp")
  .controller(
    "AppCtrl",
    (
      $scope,
      $location,
      $http,
      $state,
      $rootScope,
      ApiSrv,
      Auth,
      CustomUISrv,
      Locale,
      BrandSrv,
      Terms,
      RestApi,
      me,
      brand,
      $stateParams
    ) => {
      $scope.me = me
      $scope.CustomUISrv = CustomUISrv
      $scope.Locale = Locale
      $scope.brand = BrandSrv.getBrand()
      $rootScope.globalConfig.haveBrandByAccount = true

      if ($scope.brand.terms && $scope.brand.terms.forceAgree)
        Terms.checkAgreeTerm({ type: "terms", path: $scope.brand.terms.path }, (err) => {
          if (err) window.location.reload()
        })
    }
  )
