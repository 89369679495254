/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS104: Avoid inline assignments
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular.module("uCountitUiApp").directive("rangeSearchContainer", () => ({
  restrict: "E",
  templateUrl: "components/range-search-container/range-search-container.html",

  scope: {
    searchOptions: "=",
    loadCallback: "=",
    shortTermList: "=?",
    samplingList: "=?",
    onLoad: "=?",
  },

  controller(
    $scope,
    $stateParams,
    SamplingSrv,
    UtilSrv,
    DateTimeSrv,
    rangePickerCacheSrv,
    ApiSrv,
    Locale,
    contentSrv
  ) {
    let key, sampling
    const SAMPLING = {
      HOUR: "hour",
      DAY: "day",
      MONTH: "month",
    }
    // WEEK: 'week'

    const DEFAULT_SAMPLINGLIST = (() => {
      const result = []
      for (key in SAMPLING) {
        sampling = SAMPLING[key]
        result.push({ key: sampling, sampling, title: `By ${sampling}` })
      }
      return result
    })()
    $scope.shortTermList = $scope.shortTermList != null ? $scope.shortTermList : ["24H", "7D", "6M"]
    $scope.samplingList = $scope.samplingList != null ? $scope.samplingList : DEFAULT_SAMPLINGLIST
    $scope.samplingList.forEach((s) => (s.shortTitle = s.key[0].toUpperCase()))
    $scope.pickerOptions = {}
    $scope.timezone = null
    $scope.isGotoDate = false
    $scope.tooltips = {
      T24: Locale.string("Today by Hour"),
    }

    const setDateRangeByShortcut = function (timeType) {
      let parsingTimeRange
      const dt = _.cloneDeep($scope.searchOptions.dt)
      if ($scope.pickerOptions.rangeMaxDate.isBefore(dt.endDate)) {
        dt.endDate = $scope.pickerOptions.rangeMaxDate
      }

      if (timeType === "T24") {
        parsingTimeRange = UtilSrv.parseTimeRange(
          "24H",
          DateTimeSrv.getLocalTimeByTimezone($scope.timezone).utc(true)
        )
      } else {
        parsingTimeRange = UtilSrv.parseTimeRange(timeType, dt.endDate)
      }
      ;({ sampling } = parsingTimeRange)
      const changedSampling = sampling === $scope.pickerOptions.sampling
      dt.startDate = parsingTimeRange.from.utc(true)
      dt.endDate = parsingTimeRange.to.utc(true)
      $scope.pickerOptions.sampling = sampling
      $scope.searchOptions.sampling = sampling
      $scope.searchOptions.dt = dt

      return changedSampling
    }

    const getShortDateTooltip = function (shortDate) {
      const regExp = /(\d+)(\D)/
      const parser = regExp.exec(shortDate)
      key = SamplingSrv.getSamplingKey(parser[2]) + "s"

      return `${Locale.string("Date Range")}: ${parser[1]} ${Locale.string(key)}`
    }

    const setDatetimeConfiguration = function (timezone, options) {
      const today = DateTimeSrv.getLocalTimeByTimezone(timezone).utc(true)
      const configuration = DateTimeSrv.getDateTimeConfiguration(
        today,
        options != null ? options.dtConfig : undefined
      )

      $scope.pickerOptions.rangeMaxDate = configuration.maxDate
      $scope.pickerOptions.ranges = configuration.ranges

      $scope.shortTermList.forEach((s) => ($scope.tooltips[s] = getShortDateTooltip(s)))

      const res = rangePickerCacheSrv.getCachedTimeByTimezone("UTC", "search")
      if (res) {
        let left
        sampling =
          (left = __guard__(
            _.find($scope.samplingList, { key: res.sampling }),
            (x) => x.sampling
          )) != null
            ? left
            : SAMPLING.DAY
        if ($scope.pickerOptions.rangeMaxDate.isBefore(res.dt.endDate)) {
          res.dt.endDate = $scope.pickerOptions.rangeMaxDate
        }
        if (res.dt.endDate.isBefore(res.dt.startDate)) {
          res.dt.startDate = moment(res.dt.endDate).startOf("day")
        }

        $scope.searchOptions.dt = {
          startDate: moment(res.dt.startDate).startOf(sampling),
          endDate: moment(res.dt.endDate).endOf(sampling),
        }
        $scope.searchOptions.sampling = sampling
        return ($scope.pickerOptions.sampling = sampling)
      } else {
        $scope.searchOptions.dt = configuration.dt
        return ($scope.pickerOptions.sampling = options.sampling)
      }
    }

    $scope.searchHandler = function (buttonType, options = null) {
      if (!$scope.searchOptions.dt) {
        return
      }
      let manualReload = true

      switch (buttonType) {
        case "shortcut":
          manualReload = setDateRangeByShortcut(options)
          break
        case "sampling":
          manualReload = options === $scope.pickerOptions.sampling
          $scope.pickerOptions.sampling = options
          break
      }

      rangePickerCacheSrv.putCacheTime(
        $scope.searchOptions.dt,
        $scope.pickerOptions.sampling,
        "search"
      )
      if ($scope.loadCallback && manualReload) {
        return $scope.loadCallback()
      }
    }

    $scope.gotoDate = function (type) {
      const { dt } = $scope.searchOptions
      sampling = $scope.searchOptions.sampling === "hour" ? "day" : $scope.searchOptions.sampling
      const diffDays = moment(dt.endDate).diff(dt.startDate, sampling)
      const options = {
        orgType: $stateParams.orgType,
        orgId: $stateParams.id,
        content: $scope.searchOptions.content,
      }

      if (type === "first") {
        return contentSrv
          .getFirstDate(options)
          .then(function (date) {
            const theDateTime = moment(date).utc(true).startOf(sampling)
            const newdt = {
              startDate: theDateTime,
              endDate: moment(theDateTime).add(diffDays, sampling).endOf(sampling),
            }
            $scope.searchOptions.dt = newdt
            rangePickerCacheSrv.putCacheTime(
              $scope.searchOptions.dt,
              $scope.pickerOptions.sampling,
              "search"
            )
            if ($scope.loadCallback) {
              return $scope.loadCallback()
            }
          })
          .catch((err) => console.error((err != null ? err.message : undefined) || err))
      } else {
        return contentSrv
          .getLastDate(options)
          .then(function (date) {
            const theDateTime = moment(date).utc(true).endOf(sampling)
            const newdt = {
              startDate: moment(theDateTime).subtract(diffDays, sampling).startOf(sampling),
              endDate: theDateTime,
            }
            $scope.searchOptions.dt = newdt
            rangePickerCacheSrv.putCacheTime(
              $scope.searchOptions.dt,
              $scope.pickerOptions.sampling,
              "search"
            )
            if ($scope.loadCallback) {
              return $scope.loadCallback()
            }
          })
          .catch((err) => console.error((err != null ? err.message : undefined) || err))
      }
    }

    const initRangeSearch = function () {
      $scope.pickerOptions = {}
      setDatetimeConfiguration($scope.timezone, $scope.searchOptions)
      if ($scope.loadCallback) {
        return $scope.loadCallback(true)
      }
    }

    $scope.$watch("searchOptions.content", function (newVal, oldVal) {
      if (oldVal && newVal !== oldVal) {
        return ($scope.isGotoDate = contentSrv.isSupportGotoDate(newVal, $stateParams.orgType))
      }
    })

    $scope.$on("init-range-search", function (e, timezone) {
      $scope.timezone = timezone
      $scope.isGotoDate = contentSrv.isSupportGotoDate(
        $scope.searchOptions.content,
        $stateParams.orgType
      )
      return initRangeSearch()
    })

    if ($scope.onLoad) {
      return $scope.onLoad()
    }
  },
}))

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
