/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .controller("StoreViewCtrl", function ($scope, Auth, StoreSrv, HeaderSrv, me) {
    $scope.Auth = Auth
    HeaderSrv.fetchCurrentCompany().then(function (company) {
      $scope.isHideAdminMenu = company.isHideAdminMenu.bind(company, me)
      return StoreSrv.getCompareYesterday({
        company: company,
      })
        .then(function (response) {
          $scope.isStores = response.length > 0
          return (vm.stores = response)
        })
        .catch((err) => console.error(err))
    })

    var vm = ($scope.vm = this)
    $scope.tile = []
    return ($scope.selectTileItem = function (idx) {
      if ($scope.tile.indexOf(true) === idx) {
        return ($scope.tile[idx] = false)
      }
      $scope.tile = __range__(0, vm.stores.length, true).map((i) => false)
      return ($scope.tile[idx] = true)
    })
  })

function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
