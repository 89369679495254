/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import _ from "lodash"
import angular from "angular"

angular.module("uCountitUiApp").factory("StoreNotificationFormCtrl", function (NotificationSrv) {
  return class NotificationFormCtrl {
    constructor(store) {
      this.store = store
      this.defaultFormData = {
        queue: {
          alert: {
            active: false,
            qCnt: 10,
            averWait: 60,
            emails: [],
          },
        },
      }
      this.formData
      this.resetFormData
      this.isOpened = false
    }

    // ; separated list of email
    emailsToArray(stringEmails) {
      if (stringEmails.length <= 0) {
        return []
      } else {
        return _.map(stringEmails.split(";"), (item) => item.trim())
      }
    }

    emailsToString(emails) {
      return emails.join(";")
    }

    submit(event) {
      event.preventDefault()
      const transformedData = _.cloneDeep(this.formData)
      transformedData.queue.alert.emails = this.emailsToArray(this.formData.queue.alert.emails)

      const self = this
      return NotificationSrv.Store.update({ id: this.store._id }, transformedData)
        .$promise.then(() => self.closeDialog())
        .catch((err) => console.error(err))
    }

    openDialog() {
      const self = this
      return NotificationSrv.Store.show({ id: self.store._id, _: Date.now() })
        .$promise.then((resource) => resource.toJSON())
        .catch((err) => self.defaultFormData)
        .then(function (formData) {
          self.isOpened = true

          formData = _.merge({}, self.defaultFormData, formData)
          formData.queue.alert.emails = self.emailsToString(formData.queue.alert.emails)

          self.resetFormData = _.cloneDeep(formData)
          return (self.formData = formData)
        })
    }

    closeDialog() {
      this.isOpened = false
      return (this.formData = _.cloneDeep(this.defaultFormData))
    }

    isDirty() {
      return !_.isEqual(this.resetFormData, this.formData)
    }
  }
})
