import angular from "angular"
import Store from "../../../lib/org/store"

angular
  .module("uCountitUiApp")
  .controller("PathTreeCtrl", ($scope, usSpinnerService, HeaderSrv) => {
    // This page just pass Organization information to iframe
    ;(async () => {
      const store = await HeaderSrv.fetchCurrentStore()
      if (store.orgType == Store.ORG_TYPE) {
        usSpinnerService.spin("spinner")
        $scope.onLoad = () => {
          usSpinnerService.stop("spinner")
        }
        $scope.pathtreeSrc = `/v2/embed/contents/path-tree/stores/${store._id}`
      }
    })()
  })
