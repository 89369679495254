/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import { fabric } from "fabric"

angular
  .module("uCountitUiApp")

  .controller("heatmaptagViewerCtrl", function ($scope, $element, $compile, Locale, ngDialog) {
    const customCursor = "url(/assets/images/icon/pencil.png) 0 24, auto"
    const canvasElement = $element[0].querySelector("#heatmaptagCanvas")
    const tagCanvas = new fabric.Canvas(canvasElement, {
      selection: false,
      preserveObjectStacking: true,
    })
    canvasElement.fabric = tagCanvas
    if ($scope.canNotModify) {
      canvasElement.parentNode.style.pointerEvents = "none"
    }
    $scope.pointArray = []
    $scope.lineArray = []
    $scope.activeLine = null
    $scope.reload = true
    $scope.editTag = null
    $scope.editPolygon = null
    $scope.MODELIST = {
      SHOW: "show",
      HIDE: "hide",
      DOT: "dot",
    }
    let prevSelectedState = ""
    $scope.showTagname = $scope.showTagname != null ? $scope.showTagname : true
    $scope.showInactiveTag = $scope.showInactiveTag != null ? $scope.showInactiveTag : true

    const initDrawing = function () {
      _.forEach($scope.pointArray, (point) => tagCanvas.remove(point))
      $scope.pointArray = []

      _.forEach($scope.lineArray, (line) => tagCanvas.remove(line))
      $scope.lineArray = []

      tagCanvas.remove($scope.activeLine)
      return ($scope.activeLine = null)
    }

    const removeEditPoints = function () {
      _.forEach($scope.pointArray, (point) => tagCanvas.remove(point))
      $scope.pointArray = []
      return ($scope.editPoints = [])
    }

    const initEdit = function () {
      removeEditPoints()

      tagCanvas.remove($scope.editPolygon)
      $scope.editPolygon = null

      return (prevSelectedState = "")
    }

    const randomID = function () {
      const min = 1
      const max = 999999
      return `circle${Math.floor(Math.random() * (max - min + 1)) + min}`
    }

    const getFontSize = function (width) {
      switch (false) {
        case !(width > 768):
          return 20
        case !(width > 320):
          return 16
        default:
          return 12
      }
    }

    const setFontCenterPosition = function (polygon, text) {
      const center = polygon.getCenterPoint()
      text.left = center.x - text.width / 2
      return (text.top = center.y - text.height / 2)
    }

    const drawTag = function (tag) {
      if (!$scope.showInactiveTag && !tag.active) {
        return
      }
      if ($scope.mode != null && $scope.mode === $scope.MODELIST.HIDE) {
        return
      }
      if (_.isEmpty(tag.points)) {
        return
      }
      if ($scope.canNotModify && tag.hideInImage) {
        return
      }

      const drawDottedLine =
        (tag.active ? $scope.mode != null && $scope.mode === $scope.MODELIST.DOT : true) ||
        tag.hideInImage

      const polygon = new fabric.Polygon(tag.points, {
        left: tag.left,
        top: tag.top,
        fill: "transparent",
        stroke: tag.rgbcolor,
        strokeWidth: 2,
        strokeDashArray: drawDottedLine ? [5, 5] : [],
      })

      const name = $scope.showTagname && (tag != null ? tag.name : undefined) ? tag.name : ""

      const text = new fabric.Text(name, {
        fontSize: getFontSize($scope.width),
        fontFamily: "roboto",
        fontWeight: 600,
        stroke: tag.rgbcolor,
        strokeWidth: 1,
        fill: "white",
      })
      setFontCenterPosition(polygon, text)

      const group = new fabric.Group([polygon, text], {
        hasControls: false,
        selectable: !$scope.canNotModify,
        opacity: tag.hideInImage ? 0.5 : 1,
        id: tag.id,
      })
      return tagCanvas.add(group)
    }

    const addPoint = function (options) {
      const circle = new fabric.Circle({
        radius: 5,
        fill: "#ffffff",
        stroke: "#333333",
        strokeWidth: 0.5,
        left: options.e.layerX / tagCanvas.getZoom(),
        top: options.e.layerY / tagCanvas.getZoom(),
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: "center",
        originY: "center",
        id: randomID(),
      })

      if ($scope.pointArray.length === 0) {
        circle.set({
          fill: "red",
          hoverCursor: "pointer",
          selectable: true,
        })
      }

      const points = [
        options.e.layerX / tagCanvas.getZoom(),
        options.e.layerY / tagCanvas.getZoom(),
        options.e.layerX / tagCanvas.getZoom(),
        options.e.layerY / tagCanvas.getZoom(),
      ]

      const line = new fabric.Line(points, {
        strokeWidth: 2,
        fill: "#999999",
        stroke: "#999999",
        class: "line",
        originX: "center",
        originY: "center",
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
      })

      $scope.activeLine = line

      $scope.pointArray.push(circle)
      $scope.lineArray.push(line)

      tagCanvas.add(line)
      return tagCanvas.add(circle)
    }

    const generatePolygon = function (points) {
      const polygonPoints = []
      _.forEach(points, (point) =>
        polygonPoints.push({
          x: point.left,
          y: point.top,
        })
      )

      const newTag = _.cloneDeep($scope.drawingTag)
      newTag.left = _.minBy(polygonPoints, "x").x
      newTag.top = _.minBy(polygonPoints, "y").y
      newTag.points = polygonPoints.map((n) => ({
        x: n.x - newTag.left,
        y: n.y - newTag.top,
      }))

      $scope.reload = false
      $scope.tagList.push(newTag)

      drawTag(newTag)
      initDrawing()
      return ($scope.drawingTag = null)
    }

    const load = function (tags) {
      if (!$scope.reload) {
        return ($scope.reload = true)
      }

      tagCanvas.clear()
      initDrawing()
      initEdit()
      $scope.drawingTag = null
      $scope.editTag = null

      return _.forEach(tags, (tag) => drawTag(tag))
    }

    const setBackgroundSnapshot = function (url, width, height) {
      const canvas = $element.find("#heatmaptagCanvas")
      return canvas.css({
        width: width,
        height: height,
        background: `url(${url})`,
        "background-size": width + "px " + height + "px",
      })
    }

    const setModificationFlag = function (flag) {
      tagCanvas.discardActiveObject().renderAll()
      return tagCanvas.forEachObject(function (obj) {
        obj.selectable = flag
        return (obj.evented = flag)
      })
    }

    const getObjectById = (id) => _.find(tagCanvas.getObjects(), ["id", id])

    const getEditArray = function (state) {
      if (state == null) {
        state = "real"
      }
      if (state === "virtual") {
        return $scope.editPoints
      } else {
        return _.filter($scope.editPoints, (o) => Number.isInteger(o.index))
      }
    }

    const makeEditPoints = function (points) {
      const editPoints = []
      points.forEach(function (point, index) {
        let next = index + 1
        if (next === points.length) {
          next = 0
        }

        const midPoint = point.midPointFrom(points[next])
        point.index = index
        editPoints.push(point)
        return editPoints.push(midPoint)
      })

      return editPoints
    }

    const drawEditablePolygon = function (points = null, polygonType) {
      if (polygonType == null) {
        polygonType = "real"
      }
      let syncFlag = true

      if (points === null) {
        points = $scope.editTag.points.map(
          (p) => new fabric.Point(p.x + $scope.editTag.left, p.y + $scope.editTag.top)
        )
        syncFlag = false
      }
      $scope.editPoints = makeEditPoints(points)

      $scope.editPolygon = new fabric.Polygon(getEditArray(polygonType), {
        strokeWidth: 2,
        fill: "transparent",
        stroke: $scope.tagList[$scope.editOptions.index].rgbcolor, //'#999999'
        objectCaching: false,
        hasControls: false,
        id: $scope.editTag.id,
      })
      tagCanvas.add($scope.editPolygon)

      $scope.pointArray = []
      $scope.editPoints.forEach(function (point, index) {
        const circle = new fabric.Circle({
          radius: 5,
          fill: "#ffffff",
          stroke: "#333333",
          left: point.x,
          top: point.y,
          originX: "center",
          originY: "center",
          hasBorders: false,
          hasControls: false,
          hoverCursor: "pointer",
          opacity: point.index === undefined ? 0.3 : 1,
          state: point.index === undefined ? "virtual" : "real",
          name: point.index,
          extendName: index,
        })
        tagCanvas.add(circle)
        return $scope.pointArray.push(circle)
      })

      if (syncFlag) {
        return syncEditTag()
      }
    }

    const removeDeleteBtn = function () {
      if ($element.find(".deleteBtn").length) {
        return $element.find(".deleteBtn").remove()
      }
    }

    const makeDeleteBtn = function (x, y, w) {
      let btnLeft = x
      const btnTop = y - 13
      const widthadjust = w / 2
      btnLeft = widthadjust + btnLeft

      return $element
        .find(".tag-container")
        .append(
          $compile(
            [
              '<p class="deleteBtn" title="' +
                Locale.string("delete") +
                '" style="color:black;position:absolute;top:' +
                btnTop +
                "px;left:" +
                btnLeft +
                'px;cursor:pointer;font-size:12px;" data-ng-click="deleteSelectedPoint()">❌</p>',
            ].join("")
          )($scope)
        )
    }

    const isValidTagPointCount = function (points) {
      if (points.length < 3) {
        ngDialog.open({
          template: "components/popup/popup-alert.html",
          data: {
            title: Locale.string("msg_alert_heatmaptag_points"),
          },
          closeByEscape: true,
          closeByDocument: true,
          showClose: false,
          className: "ngdialog-theme-default popup-alert",
          controller: "ucPopupAlertCtrl",
        })
        return false
      } else {
        return true
      }
    }

    $scope.deleteSelectedPoint = function () {
      const selected = tagCanvas.getActiveObject()
      if (!selected || selected.state === "virtual") {
        return
      }

      const points = _.cloneDeep(getEditArray())
      points.splice(selected.name, 1)
      if (isValidTagPointCount(points)) {
        initEdit()
        return drawEditablePolygon(points)
      }
    }

    $scope.$on("heatmaptag_init_drawing", () => initDrawing())

    $scope.$on("heatmaptag_delete_editpoint", () => $scope.deleteSelectedPoint())

    $scope.$watch(
      "drawingTag",
      function (tag) {
        if (tag) {
          tagCanvas.defaultCursor = customCursor
          tagCanvas.hoverCursor = customCursor
          return setModificationFlag(false)
        } else {
          tagCanvas.defaultCursor = "default"
          tagCanvas.hoverCursor = "move"
          return setModificationFlag(true)
        }
      },
      true
    )

    $scope.$watch("tagList", (list) => load(list), true)

    $scope.$watch(
      () =>
        JSON.stringify({
          width: $scope.width,
          height: $scope.height,
          snapshotUrl: $scope.snapshotUrl,
        }),
      function () {
        tagCanvas.setWidth($scope.width)
        tagCanvas.setHeight($scope.height)

        if ($scope.snapshotUrl) {
          return setBackgroundSnapshot($scope.snapshotUrl, $scope.width, $scope.height)
        }
      }
    )

    $scope.$watch("canNotModify", function (flag) {
      canvasElement.parentNode.style.pointerEvents = flag ? "none" : "auto"
      return tagCanvas.forEachObject((obj) => (obj.selectable = !flag))
    })

    const resetEditMode = function () {
      if ($scope.drawingTag) {
        initDrawing()
        $scope.drawingTag = null
      }

      if ($scope.editTag) {
        const org = _.find($scope.tagList, ["id", $scope.editTag.id])
        drawTag(org)
        initEdit()
        $scope.editTag = null
      }

      return setModificationFlag(false)
    }

    const isEditableMode = () =>
      ($scope.editOptions != null ? $scope.editOptions.mode : undefined) === "edit" ||
      ($scope.editOptions != null ? $scope.editOptions.mode : undefined) === "add"

    var syncEditTag = function () {
      $scope.editTag.left = $scope.editPolygon.left
      $scope.editTag.top = $scope.editPolygon.top
      return ($scope.editTag.points = getEditArray("real").map((point) => ({
        x: point.x - $scope.editTag.left,
        y: point.y - $scope.editTag.top,
      })))
    }

    $scope.$watch(
      "editOptions",
      function (opt) {
        let tagInfo
        if (!opt) {
          return
        }

        switch (opt != null ? opt.mode : undefined) {
          case "add":
            resetEditMode()

            var _getAddOptions = function (index) {
              let name
              const offsetArray = [
                [1, 1],
                [5, 1],
                [1, 5],
                [5, 5],
              ]
              const offset = offsetArray[index % offsetArray.length]
              const w = $scope.width / 4
              const h = $scope.height / 4
              if (
                __guard__(
                  $scope.tagList[index] != null ? $scope.tagList[index].name : undefined,
                  (x) => x.length
                )
              ) {
                ;({ name } = $scope.tagList[index])
              } else {
                name = `tag${index + 1}`
              }

              return {
                active: true,
                name,
                left: (w * offset[0]) / 2,
                top: (h * offset[1]) / 2,
                points: [
                  { x: 0, y: 0 },
                  { x: w, y: 0 },
                  { x: w, y: h },
                  { x: 0, y: h },
                ],
              }
            }

            if (opt != null ? opt.tag : undefined) {
              tagInfo = _.pick(opt.tag, ["active", "name", "left", "top", "points"])
            } else {
              tagInfo = _getAddOptions(opt.index)
            }

            $scope.editTag = _.assign({}, $scope.tagList[opt.index], tagInfo)
            return drawEditablePolygon()
          case "edit":
            resetEditMode()
            $scope.editTag = _.cloneDeep($scope.tagList[opt.index])
            tagCanvas.remove(getObjectById($scope.editTag.id))
            return drawEditablePolygon()
          case "save":
            var { index } = $scope.editOptions
            if (!_.isEqual($scope.editTag, $scope.tagList[index])) {
              $scope.reload = false
              $scope.tagList[index] = $scope.editTag
            }

            initEdit()
            drawTag($scope.editTag)
            $scope.editOptions = null
            $scope.editTag = null
            return setModificationFlag(true)
          case "cancel":
            initEdit()
            drawTag($scope.tagList[$scope.editOptions.index])
            $scope.editOptions = null
            $scope.editTag = null
            return setModificationFlag(true)
        }
      },
      true
    )

    tagCanvas.on("object:modified", function (e) {
      const activeTarget = e.target
      if (isEditableMode()) {
        if (activeTarget.type === "circle") {
          const origin = $scope.editPoints[activeTarget.extendName]
          if (origin.x === activeTarget.left && origin.y === activeTarget.top) {
            return
          }

          let count = 0
          const newPoints = []
          $scope.editPolygon.points.forEach(function (point, index) {
            if (
              (point != null ? point.index : undefined) !== undefined ||
              point.eq(activeTarget.getCenterPoint())
            ) {
              point.index = count++
              return newPoints.push(point)
            }
          })

          initEdit()
          drawEditablePolygon(newPoints, activeTarget.state)

          const selectObj =
            activeTarget.state === "virtual"
              ? $scope.pointArray[activeTarget.extendName + 1]
              : $scope.pointArray[activeTarget.extendName]
          return tagCanvas.setActiveObject(selectObj)
        } else if (activeTarget.type === "polygon") {
          $scope.editTag.left = activeTarget.left
          $scope.editTag.top = activeTarget.top

          initEdit()
          drawEditablePolygon()
          return tagCanvas.setActiveObject($scope.editPolygon)
        }
      } else {
        const index = _.findIndex($scope.tagList, (n) => n.id === activeTarget.id)
        if (index < 0) {
          return
        }

        const tagInfo = _.cloneDeep($scope.tagList[index])
        tagInfo.left = activeTarget.left
        tagInfo.top = activeTarget.top

        return ($scope.tagList[index] = tagInfo)
      }
    })

    tagCanvas.on("object:moving", function (options) {
      let x, y
      const { target } = options

      // canvas boundary limit
      if (
        target.currentHeight > target.canvas.height ||
        target.currentWidth > target.canvas.width
      ) {
        return
      }

      target.setCoords()
      const bound = target.getBoundingRect(true)
      const { width } = target.canvas
      const { height } = target.canvas

      if (target.type === "circle") {
        const curCenter = target.getCenterPoint()
        x = Math.min(Math.max(0, curCenter.x), width - 2)
        y = Math.min(Math.max(0, curCenter.y), height - 2)
        const pos = new fabric.Point(x, y)
        target.setPositionByOrigin(pos, "center", "center")
      } else {
        target.left = Math.min(Math.max(0, bound.left), width - bound.width)
        target.top = Math.min(Math.max(0, bound.top), height - bound.height)
      }

      // to do when in editable mode
      if (!isEditableMode()) {
        return
      }

      if (target.type === "circle") {
        let index
        removeDeleteBtn()
        if (target.state === "real") {
          index = target.name
          tagCanvas.remove(_.nth($scope.pointArray, target.extendName - 1))
          tagCanvas.remove(_.nth($scope.pointArray, target.extendName + 1))
        } else {
          index = target.extendName
        }

        return ($scope.editPolygon.points[index] = new fabric.Point(
          target.getCenterPoint().x,
          target.getCenterPoint().y
        ))
      } else {
        return removeEditPoints()
      }
    })

    const bringToFrontCanvas = function (obj) {
      tagCanvas.bringToFront(obj)
      if (isEditableMode() && obj.type === "polygon") {
        return $scope.pointArray.forEach((point) => tagCanvas.bringToFront(point))
      }
    }

    tagCanvas.on("mouse:down", function (options) {
      const { target } = options
      if (target) {
        bringToFrontCanvas(target)
        if (isEditableMode() && target.type === "circle") {
          if ((target != null ? target.state : undefined) !== prevSelectedState) {
            $scope.editPolygon.points = _.cloneDeep(getEditArray(target.state))
            tagCanvas.renderAll()
            return (prevSelectedState = target.state)
          }
        }
      } else {
        if ($scope.drawingTag && _.isEmpty($scope.pointArray)) {
          return setModificationFlag(false)
        }
      }
    })

    tagCanvas.on("mouse:move", function (options) {
      if ($scope.activeLine && $scope.activeLine.class === "line") {
        const pointer = tagCanvas.getPointer(options.e)
        $scope.activeLine.set({ x2: pointer.x, y2: pointer.y })
        return tagCanvas.renderAll()
      }
    })

    tagCanvas.on("mouse:up", function (options) {
      const target = options != null ? options.target : undefined
      if ($scope.drawingTag) {
        if (target) {
          if (
            target.id === ($scope.pointArray[0] != null ? $scope.pointArray[0].id : undefined) &&
            isValidTagPointCount($scope.pointArray)
          ) {
            generatePolygon($scope.pointArray)
            return setModificationFlag(true)
          }
        } else {
          return addPoint(options)
        }
      }
    })

    tagCanvas.on("mouse:over", function (options) {
      const target = options != null ? options.target : undefined
      if (target) {
        if (isEditableMode() && target.type === "circle") {
          target.set("fill", "#5C5B5B")
          return tagCanvas.renderAll()
        }
      }
    })

    tagCanvas.on("mouse:out", function (options) {
      const target = options != null ? options.target : undefined
      if (target) {
        if (isEditableMode() && target.type === "circle") {
          target.set("fill", "#ffffff")
          return tagCanvas.renderAll()
        }
      }
    })

    tagCanvas.on("selection:created", function (options) {
      if (!isEditableMode()) {
        return
      }

      removeDeleteBtn()
      const { target } = options
      if (target.type === "circle" && (target != null ? target.state : undefined) === "real") {
        return makeDeleteBtn(target.oCoords.mt.x, target.oCoords.mt.y, target.width)
      }
    })

    tagCanvas.on("selection:cleared", (options) => removeDeleteBtn())

    return tagCanvas.on("selection:updated", function (options) {
      if (!isEditableMode()) {
        return
      }

      removeDeleteBtn()
      const { target } = options
      if (target.type === "circle" && (target != null ? target.state : undefined) === "real") {
        return makeDeleteBtn(target.oCoords.mt.x, target.oCoords.mt.y, target.width)
      }
    })
  })

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
