/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

import config from "../../../config/environment"

angular
  .module("uCountitUiApp")

  .factory("BrandSrv", function ($rootScope, $http, ApiSrv) {
    let brand = null
    let cacheFaviconHtml = null
    const defaultBrand = {
      brandName: "uCount.it",
      email: "support@ucountit.com",
      copyrightText: "",
      logo: {
        url: "/assets/images/ucountit-logo.png",
      },
      emblem: {
        url: "/assets/images/ucountit-emblem.png",
      },
      favicon: {
        url: "/assets/favicons/favicons.html",
      },
      signin: {
        showBrandName: true,
        hexColor: "#0078ab",
        url: "/assets/images/background/bg-graph.png",
      },
      colorTheme: {
        index: 1,
      },
      signinNotification: {
        active: false,
        text: "",
      },
      language: "en",
      region: "en-gb",
      terms: {
        path: "",
        forceAgree: false,
      },
      privacy: {
        path: "",
      },
      navMenu: {
        footfall: true,
        zonetraffic: true,
        occupancy: true,
        heatmap: true,
        directionmap: true,
        genderAge: true,
        sales: false,
        queue: false,
        flowmap: true,
        dashboardQueue: false,
        dashboardFace: false,
        "tracking-tree": true,
        "path-tree": true,
        "app.datadownload": true,
        "app.premiumReport": true,
        "admin.event": true,
        "admin.s3fileapi": true,
        "system.operation": true,
        "system.data-operation": true,
        v2: true,
      },
      isCustom: false,
    }

    const retailTrendBrand = _.merge({}, defaultBrand, {
      brandName: "RetailTrend",
      email: "support@retailtrend.com",
      logo: {
        url: "/assets/retailtrend/retailtrend-log180x35.png",
      },
      emblem: {
        url: "/assets/retailtrend/retailtrend-emblem150.png",
      },
      favicon: {
        url: "/assets/retailtrend/favicons/favicons.html",
      },
      signin: {
        showBrandName: false,
        hexColor: "#39267d",
        url: "/assets/images/background/bg-graph-grey.png",
      },
      colorTheme: {
        index: 25,
      },
      language: "ko",
      region: "ko-kr",
    })

    const developmentBrand = _.merge({}, defaultBrand, {
      brandName: "Development",
      email: "support@development.com",
      logo: {
        url: "/assets/development/development-logo180x35.png",
      },
      emblem: {
        url: "/assets/development/development-emblem150.png",
      },
      favicon: {
        url: "/assets/development/favicons/favicons.html",
      },
      signin: {
        showBrandName: true,
        hexColor: "#404040",
        url: "/assets/images/background/bg-graph-grey.png",
      },
      colorTheme: {
        index: 28,
      },
      language: "ko",
      region: "ko-kr",
    })

    const agingBrand = _.merge({}, defaultBrand, {
      brandName: "Aging",
      email: "support@aging.com",
      logo: {
        url: "/assets/aging/aging-logo180x35.png",
      },
      emblem: {
        url: "/assets/aging/aging-emblem150.png",
      },
      favicon: {
        url: "/assets/aging/favicons/favicons.html",
      },
      signin: {
        showBrandName: true,
        hexColor: "#213A40",
        url: "/assets/images/background/bg-graph-grey.png",
      },
      colorTheme: {
        index: 29,
      },
      language: "ko",
      region: "ko-kr",
    })

    const localgreyBrand = _.merge({}, retailTrendBrand, {
      navMenu: _.merge({}, retailTrendBrand.navMenu, {
        occupancy: false,
        genderAge: false,
        sales: false,
        queue: false,
        dashboardQueue: false,
        dashboardFace: false,
        "tracking-tree": false,
        "app.datadownload": false,
        "app.premiumReport": false,
        "admin.event": false,
        "admin.s3fileapi": false,
        "system.operation": false,
        "system.data-operation": false,
        v2: false,
      }),
    })

    const getBrand = () => brand

    const getDefaultBrand = function () {
      if (config.brand === "retailtrend") {
        return retailTrendBrand
      } else if (config.brand === "development") {
        return developmentBrand
      } else if (config.brand === "aging") {
        return agingBrand
      } else if (config.brand === "localgrey") {
        return localgreyBrand
      } else {
        return defaultBrand
      }
    }

    const setBrand = function (req) {
      brand = _.cloneDeep(getDefaultBrand())
      const oldBrandName = brand.brandName
      if (!req && config.brand !== "localgrey") {
        brand.navMenu.sales = true
        brand.navMenu.queue = true
        brand.navMenu.dashboardQueue = true
        brand.navMenu.dashboardFace = true
      } else if (req != null ? req.customSettings : undefined) {
        const customSettings = req != null ? req.customSettings : undefined
        const customNav = req != null ? req.customNav : undefined
        if (customSettings.use) {
          _.merge(brand, customSettings)
          brand.isCustom = true
        }
        if ((customNav != null ? customNav.active : undefined) === true) {
          _.merge(brand.navMenu, customNav.menu)
        }
      }

      if (brand.favicon.url) {
        if (oldBrandName === brand.brandName && cacheFaviconHtml) {
          angular.element("head").append(cacheFaviconHtml.data)
        } else {
          $http({
            method: "GET",
            url: brand.favicon.url,
            transformRequest(data, headersGetter) {
              const headers = headersGetter()
              delete headers["Authorization"]
              return data
            },
            headers: {
              Authorization: undefined,
            },
          }).then(function (res) {
            cacheFaviconHtml = res
            const head = angular.element("head")
            $rootScope.title =
              brand.brandName.length === 0 ? getDefaultBrand().brandName : brand.brandName
            return head.append(res.data)
          })
        }
      }

      return getBrand()
    }

    const setCustomSettingsbyUrl = (callback) =>
      ApiSrv.getCustomSettingsbyUrl().then((res) =>
        callback(null, setBrand({ customSettings: res }))
      )

    brand = _.cloneDeep(getDefaultBrand())

    return {
      getBrand,
      getDefaultBrand,
      setBrand,
      setCustomSettingsbyUrl,
    }
  })
