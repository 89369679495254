import Promise from "bluebird"
import url from "url"
import { BrowserTokenModel } from "common-library/token-model"

export const routerConfig = (
  $stateProvider,
  $urlRouterProvider,
  $locationProvider,
  $httpProvider,
  usSpinnerConfigProvider
) => {
  $urlRouterProvider.otherwise("/") // TODO: 404로 보내야지 '/'로 무조건 보내면 안된다.
  $locationProvider.html5Mode(true)
  usSpinnerConfigProvider.setTheme("blue", { color: "#5992c5", radius: 10 })
}

export const createRootState = ($stateProvider) => {
  $stateProvider
    .state("app", {
      // 로그인 세션의 전역 콘트롤러
      controller: "AppCtrl",
      templateUrl: "app/app.html",
      url: "?language&service_token&access_token",
      resolve: {
        me: (Auth) => Auth.fetchCurrentAccount(),
        brand: (me, RestApi, BrandSrv) => {
          let ret = Promise.resolve()
          if (!me.gteSpace()) {
            ret = RestApi.Agency.index().then((agencies) => {
              BrandSrv.setBrand(agencies[0])
            })
          } else {
            BrandSrv.setBrand()
          }
          return ret
        },
      },
    })
    .state("default", {
      // 존재하지 않는 페이지에 대한 콘트톨러
      url: "/?language&service_token&access_token",
      tempalteUrl: "<div ui-view />",
      controller: ($state, $stateParams, HeaderSrv) => {
        return $state.go("app.intro", $stateParams)
      },
    })
}

const tokenModel = new BrowserTokenModel()
// 로그인, 비로그인 세션 모두 처리함
export const routerRun = (Auth, Locale, $transitions, $state) => {
  return $transitions.onBefore({}, (trans) => {
    const accessToken = trans.params().accessToken
    if (accessToken) tokenModel.setToken(trans.params().accessToken)
    return redirectLogin(Auth, $state, trans)
      .then(() => setupLocale(Auth, Locale, trans.params()))
      .catch((err) => {
        console.warn(err.toString(), err)
      })
  })
}

const noAuthUrls = ["/login", "/account/recovery"]
const redirectLogin = (Auth, $state, trans) => {
  return Auth.fetchCurrentAccount().catch((err) => {
    const parsedUrl = url.parse(trans.to().url)
    if (noAuthUrls.indexOf(parsedUrl.pathname) < 0 || noAuthUrls.indexOf(location.pathname) < 0) {
      window.location.replace("/login")
    }
    return Promise.reject(err)
  })
}

const setupLocale = (Auth, Locale, params) => {
  if (params.language) {
    Locale.setLanguage(params.language)
    return Locale.setup({ region: Locale.getLanguage() })
  } else {
    return Auth.fetchCurrentAccount().then((acc) => {
      Locale.setLanguage(acc.language)
      return Locale.setup(acc.locale)
    })
  }
}
