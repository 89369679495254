"use strict"

import angular from "angular"
import "./EventPage"

angular.module("uCountitUiApp").config(($stateProvider) => {
  $stateProvider
    .state("app.admin.event", {
      url: "/event",
      template: "<section><event-page /></section>",
    })
    .state("app.admin.event.new", {
      url: "/new",
      template: "<section><event-page /></section>",
    })
    .state("app.admin.event.edit", {
      url: "/:id/edit",
      template: "<section><event-page /></section>",
    })
})
