/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")
  .directive(
    "ucRangePicker",
    function (
      $compile,
      $document,
      $filter,
      $parse,
      dateTimeConfig,
      datePickerUtils,
      Locale,
      SamplingSrv
    ) {
      const body = $document.find("body")

      const getPickerTemplate = function (
        attrs,
        id,
        modelName,
        view,
        minView,
        maxView,
        minDate,
        maxDate
      ) {
        attrs = angular.extend(attrs, {
          ngModel: modelName,
          view: view || "date",
          minView: minView || false,
          maxView: maxView || false,
          minDate: minDate && moment.isMoment(minDate) ? minDate.format() : false,
          maxDate: maxDate && moment.isMoment(maxDate) ? maxDate.format() : false,
          timezone: "UTC",
        })
        return dateTimeConfig.template(attrs, id)
      }

      const randomPickerId = () => `picker${Math.random().toString().substr(2)}`

      return {
        restrict: "E",
        templateUrl: "components/picker/uc-range-picker.html",
        require: "ngModel",
        scope: {
          ngModel: "=",
          options: "=",
          change: "&changeDate",
          gotoDate: "=",
          isGotoDate: "=",
        },
        replace: true,
        link(scope, element, attrs, ngModel) {
          const startPickerId = randomPickerId()
          const endPickerId = randomPickerId()
          let picker = null
          let container = null
          const position = attrs.position || dateTimeConfig.position
          let sampling = attrs.sampling || "day"
          let view = "date"
          let shownOnce = false
          let template = null
          let isOpen = false
          let maxDate = null
          let minDate = null
          let rangeMinDate = false
          let rangeMaxDate = false
          let displayFormat = "ll"
          const useLimitDate = attrs.useLimitDate ? attrs.useLimitDate : false
          let limitDate = null
          scope.isSearchPage = attrs.isSearchPage ? attrs.isSearchPage : false
          scope.tooltip = {
            first: Locale.string("Start Date of Data"),
            last: Locale.string("End Date of Data"),
          }

          const setStartPickerMin = (date) =>
            scope.$broadcast("pickerUpdate", startPickerId, { minDate: date })

          const setStartPickerMax = (date) =>
            scope.$broadcast("pickerUpdate", startPickerId, { maxDate: date })

          const setEndPickerMin = (date) =>
            scope.$broadcast("pickerUpdate", endPickerId, { minDate: date })

          const setEndPickerMax = (date) =>
            scope.$broadcast("pickerUpdate", endPickerId, { maxDate: date })

          const getMinDate = function (date) {
            if (!limitDate) {
              return false
            }
            let curMinDate = moment(date)
              .subtract(limitDate.value, limitDate.key)
              .add(1, "day")
              .startOf("day")
            if (rangeMinDate) {
              curMinDate = curMinDate < rangeMinDate ? rangeMinDate : curMinDate
            }

            return curMinDate
          }

          const getMaxDate = function (date) {
            if (!limitDate) {
              return false
            }
            let curMaxDate = moment(date)
              .add(limitDate.value, limitDate.key)
              .subtract(1, "day")
              .endOf("day")
            if (rangeMaxDate) {
              curMaxDate = curMaxDate > rangeMaxDate ? rangeMaxDate : curMaxDate
            }

            return curMaxDate
          }

          const getTemplate = function () {
            if (!scope.ngModel) {
              return
            }

            attrs.onSetDate = "selectedDate"
            const { options } = scope
            const startPickerTemplate = getPickerTemplate(
              attrs,
              startPickerId,
              "start",
              options.view,
              options.minView,
              options.maxView,
              options.minDate,
              scope.end
            )
            const endPickerTemplate = getPickerTemplate(
              attrs,
              endPickerId,
              "end",
              options.view,
              options.minView,
              options.maxView,
              scope.start,
              options.maxDate
            )

            return (template = [
              '<div class="date-picker-popup range">',
              "<div>",
              '<div class="picker start">',
              '<span class="title" data-i18n="From Date"></span>',
              '<span class="date-str">{{getDateStr(start)}}</span>',
              startPickerTemplate,
              "</div>",
              '<div class="picker">',
              '<span class="title" data-i18n="To Date"></span>',
              '<span class="date-str">{{getDateStr(end)}}</span>',
              endPickerTemplate,
              "</div>",
              "</div>",
              '<div class="side">',
              '<button class="btn btn-primary btn-sm" data-ng-click="apply()">',
              '<span data-i18n="Apply"></span>',
              "</button>",
              '<button class="btn btn-default btn-sm" data-ng-click="cancel()">',
              '<span data-i18n="Cancel"></span>',
              "</button>",
              '<div class="custom-btn-section">',
              "</div>",
              useLimitDate ? getLimitInfoTemplate() : "",
              "</div>",
              "</div>",
            ].join(""))
          }

          var getLimitInfoTemplate = function () {
            if (!limitDate) {
              return
            }

            const periodStr = moment
              .duration(limitDate.value, limitDate.key)
              .locale(Locale.isSetup().datetime)
              .humanize()

            return [
              '<div class="alert alert-warning help" role="alert">',
              '<div class="help-header">',
              '<span data-i18n="Max Period"></span>',
              "</div>",
              '<div class="help-content">',
              periodStr,
              "</div>",
              "</div>",
            ].join("")
          }

          const clear = function () {
            isOpen = false
            if (picker) {
              picker.remove()
              picker = null
            }

            if (container) {
              container.remove()
              return (container = null)
            }
          }

          const showPicker = function () {
            let height, pos
            if (picker) {
              return
            }
            picker = $compile(template)(scope)

            if (!shownOnce) {
              picker = $compile(template)(scope)
              scope.$on("hidePicker", clear)

              scope.$on("$destroy", clear)

              shownOnce = true
            }

            if (position === "absolute") {
              pos = element[0].getBoundingClientRect()
              height = pos.height || element[0].offsetHeight
              picker.css({
                top: `${pos.top + height}px`,
                left: `${pos.left}px`,
                display: "block",
                position,
              })
              body.append(picker)
            } else {
              pos = element[0].getBoundingClientRect()
              height = pos.height || element[0].offsetHeight
              container = angular.element("<div date-picker-wrapper></div>")
              element[0].parentElement.insertBefore(container[0], element[0])

              container.css({
                // if you show range picker
                // top: "#{element[0].offsetHeight+20}px"
                "z-index": 999,
              })

              container.append(picker)
            }

            return picker.bind("mousedown", (evt) => evt.preventDefault())
          }

          const setMinMaxStartPicker = function (end) {
            if (useLimitDate) {
              minDate = getMinDate(end)
              setStartPickerMin(minDate)
            }

            return setStartPickerMax(end)
          }

          const setMinMaxEndPicker = function (start) {
            if (useLimitDate) {
              maxDate = getMaxDate(start)
              setEndPickerMax(maxDate)
            }

            return setEndPickerMin(start)
          }

          scope.selectedDate = function (modelName, date) {
            if (modelName === "start") {
              return setMinMaxEndPicker(date)
            } else {
              if (sampling === "month") {
                date = moment(date).endOf("month")
              } else {
                date = moment(date).endOf("day")
              }
              scope.end = date
              return setMinMaxStartPicker(date)
            }
          }

          scope.openRangePicker = function () {
            isOpen = !isOpen
            if (isOpen) {
              showPicker()
            } else {
              clear()
            }
          }

          const _getSamplingFormat = function (sampling) {
            switch (sampling) {
              case "hour":
              case "day":
                return "ll"
              case "month":
                return "MMM YYYY"
              case "year":
                return "YYYY"
              default:
                return "ll"
            }
          }

          scope.moveHandler = function (direction) {
            let endDate, startDate
            const start = angular.copy(scope.start)
            const end = angular.copy(scope.end)
            const diffDays = moment(end).diff(start, sampling)
            const samplingFormat = _getSamplingFormat(sampling)

            if (direction === "prev") {
              if (
                (rangeMinDate != null ? rangeMinDate.format(samplingFormat) : undefined) ===
                (start != null ? start.format(samplingFormat) : undefined)
              ) {
                return
              }

              endDate = moment(start).subtract(1, sampling).endOf(sampling)
              if (rangeMinDate && endDate.isBefore(rangeMinDate)) {
                return
              }
              setMinMaxStartPicker(endDate)
              startDate = moment(endDate).subtract(diffDays, sampling).startOf(sampling)
              if (startDate < minDate) {
                startDate = minDate
              }
              setMinMaxEndPicker(startDate)
            } else {
              if (
                (rangeMaxDate != null ? rangeMaxDate.format(samplingFormat) : undefined) ===
                (end != null ? end.format(samplingFormat) : undefined)
              ) {
                return
              }

              startDate = moment(end).add(1, sampling).startOf(sampling)
              if (rangeMaxDate && startDate.isAfter(rangeMaxDate)) {
                return
              }
              setMinMaxEndPicker(startDate)
              endDate = moment(startDate).add(diffDays, sampling).endOf(sampling)
              if (endDate > maxDate) {
                endDate = maxDate
              }
              setMinMaxStartPicker(endDate)
            }

            scope.start = startDate != null ? startDate : start
            scope.end = endDate != null ? endDate : end
            scope.ngModel.startDate = startDate
            scope.ngModel.endDate = endDate
            scope.options.minDate = minDate
            scope.options.maxDate = maxDate

            getTemplate()

            if (scope.change) {
              return scope.change({ date: scope.ngModel })
            }
          }

          scope.getDateStr = function (date) {
            if (moment.isMoment(date)) {
              return Locale.dateTime(date, displayFormat)
            } else {
              return ""
            }
          }

          scope.apply = function () {
            scope.ngModel.startDate = scope.start
            scope.ngModel.endDate = scope.end
            scope.options.minDate = minDate
            scope.options.maxDate = maxDate

            getTemplate()
            clear()

            if (scope.change) {
              return scope.change({ date: scope.ngModel })
            }
          }

          scope.cancel = function () {
            scope.start = scope.ngModel.startDate
            scope.end = scope.ngModel.endDate
            return clear()
          }

          getTemplate()

          scope.$watch("ngModel", function (newValue) {
            if (
              !moment.isMoment(newValue != null ? newValue.startDate : undefined) ||
              !moment.isMoment(newValue != null ? newValue.endDate : undefined)
            ) {
              return
            }

            scope.start = newValue.startDate
            scope.end = newValue.endDate
            scope.startDateStr = scope.getDateStr(newValue.startDate)
            scope.endDateStr = scope.getDateStr(newValue.endDate)

            setMinMaxStartPicker(newValue.endDate)
            setMinMaxEndPicker(newValue.startDate)
            return getTemplate()
          })

          scope.$watchGroup(["ngModel.startDate", "ngModel.endDate"], function (newDate) {
            if (!moment.isMoment(newDate[0]) || !moment.isMoment(newDate[1])) {
              return
            }

            scope.startDateStr = scope.getDateStr(newDate[0])
            return (scope.endDateStr = scope.getDateStr(newDate[1]))
          })

          const isInitilalizing = (opt) => opt.every(_.isUndefined)

          scope.$watchGroup(
            ["options.sampling", "options.rangeMinDate", "options.rangeMaxDate"],
            function (options, oldOptions) {
              if (isInitilalizing(options)) {
                return
              }

              const isSame = options.reduce(function (ret, cur, idx) {
                const same = moment.isMoment(cur)
                  ? moment(cur).isSame(oldOptions[idx])
                  : cur === oldOptions[idx]

                return ret && same
              }, true)
              sampling = options[0]
              rangeMinDate = options[1]
              rangeMaxDate = options[2]

              if (rangeMinDate) {
                minDate = rangeMinDate
              }
              if (rangeMaxDate) {
                maxDate = rangeMaxDate
              }

              if (sampling) {
                if (sampling === "month") {
                  view = "month"
                  displayFormat = "month"
                } else {
                  view = "date"
                  displayFormat = "fullDate"
                }

                scope.options.view = view
                scope.options.minView = view

                if (useLimitDate) {
                  let newMaxDate
                  limitDate = SamplingSrv[sampling].getLimitDate()

                  const from = angular.copy(scope.start)
                  const to = angular.copy(scope.end)

                  const diffNum = moment(to)
                    .add(1, "days")
                    .startOf("day")
                    .diff(from, limitDate.key, true)
                  if (diffNum > limitDate.value) {
                    scope.start = moment(to)
                      .subtract(limitDate.value, limitDate.key)
                      .add(1, "day")
                      .startOf("day")
                    if (scope.ngModel != null) {
                      scope.ngModel.startDate = scope.start
                    }
                  }

                  if (rangeMaxDate) {
                    if (sampling === "month") {
                      const diffDays = to.diff(from, "days")
                      const endDateOfMonth = moment(to).endOf("month")
                      if (endDateOfMonth >= moment(rangeMaxDate).endOf("month")) {
                        newMaxDate = moment(to).endOf("month")
                        scope.end = moment(to).endOf("month")
                      } else {
                        scope.end = endDateOfMonth
                      }

                      scope.start = moment(scope.end).subtract(diffDays, "days").startOf("month")
                    } else {
                      if (moment(to).isAfter(rangeMaxDate)) {
                        scope.end = rangeMaxDate
                      }
                    }

                    if (scope.ngModel != null) {
                      scope.ngModel.startDate = scope.start
                    }
                    if (scope.ngModel != null) {
                      scope.ngModel.endDate = scope.end
                    }
                  }

                  if (scope.change && !isInitilalizing(oldOptions) && !isSame) {
                    scope.change({ date: scope.ngModel })
                  }

                  minDate = getMinDate(scope.end)
                  maxDate = newMaxDate ? newMaxDate : getMaxDate(scope.start)

                  if (scope.ngModel != null) {
                    scope.ngModel.startDate = scope.start
                  }
                  if (scope.ngModel != null) {
                    scope.ngModel.endDate = scope.end
                  }

                  translate()
                }
              }

              scope.options.minDate = minDate
              scope.options.maxDate = maxDate

              scope.startDateStr = scope.getDateStr(scope.start)
              scope.endDateStr = scope.getDateStr(scope.end)
              return getTemplate()
            }
          )

          var translate = () =>
            (scope.help = Locale.string(
              `msg_help_datepicker_${limitDate != null ? limitDate.value : undefined}${
                limitDate != null ? limitDate.key : undefined
              }`
            ))
          // Locale.string "msg_help_datepicker_2year
          // Locale.string "msg_help_datepicker_6month
          // Locale.string "msg_help_datepicker_7day

          scope.$on("localizeResourcesUpdated-language", () => translate())

          return
        },
      }
    }
  )
