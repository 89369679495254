"use strict"

import Promise from "bluebird"
import angular from "angular"
import { createFormClass } from "./form"

const RestfulApiEditCtrl = ($scope, $http, $stateParams, Locale, RestApi) => {
  const Form = createFormClass($http, Locale)

  Promise.all([
    RestApi.Agency.index(),
    RestApi.Company.index(),
    RestApi.Store.index(),
    $http.get(`/api/1.0/restfulapi/${$stateParams.id}`).then((res) => res.data),
  ]).spread((agencies, companies, stores, apikey) => {
    const form = new Form(agencies, companies, stores, apikey)
    const submitFunc = form.submit.bind(form)
    form.submit = (event) => submitFunc(event).then(() => $scope.$parent.reload(false))
    $scope.form = form
  })
}

angular.module("uCountitUiApp").controller("RestfulApiEditCtrl", RestfulApiEditCtrl)
