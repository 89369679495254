/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

angular
  .module("uCountitUiApp")
  .controller(
    "StoreGroupReportCtrl",
    function (
      $scope,
      $state,
      $stateParams,
      $element,
      ApiSrv,
      DateTimeSrv,
      StoreGroupSrv,
      SelectorSrv,
      usSpinnerService,
      UtilSrv
    ) {
      $scope.currentPage = "report"
      $scope.options = { id: $stateParams.id }
      $scope.validTime = false
      $scope.maxMonth = 6
      $scope.pageOptions = {
        sampling: "day",
        referenceDay: undefined,
        content: "report",
      }
      $scope.displayName = UtilSrv.getDisplayStoreGroupName

      const loadingQueue = []
      let firstLoading = false
      $scope.showLoading = function (key) {
        if (!firstLoading) {
          return
        }
        if (key) {
          loadingQueue.push(key)
        }
        angular.element(".splash").show()
        usSpinnerService.spin("spinner")
        return angular.element(".spinner").css("position", "fixed")
      }

      $scope.hideLoading = function (key) {
        if (key) {
          _.remove(loadingQueue, (raw) => raw === key)
          if (loadingQueue.length) {
            return
          }
        }

        firstLoading = true

        angular.element(".splash").hide()
        return usSpinnerService.stop("spinner")
      }

      $scope.reload = function (fromInit) {
        if (fromInit == null) {
          fromInit = false
        }
        if (fromInit) {
          $scope.validTime = true
        }
        const datetime = moment($scope.pageOptions.referenceDay)
          .endOf("day")
          .format("YYYY-MM-DDTHH:00:00")
        const cacheTime = moment().add(1, "hours").startOf("hour").format()
        $scope.todayString = moment(datetime).format("YYYYMMDD0000")
        $scope.yesterdayString = moment(datetime).subtract(1, "days").format("YYYYMMDD0000")
        $scope.desterdayString = moment(datetime).subtract(2, "days").format("YYYYMMDD0000")
        $scope.aWeekAgoString = moment(datetime)
          .subtract(1, "week")
          .add(1, "days")
          .format("YYYYMMDD0000")
        $scope.tomorrowString = moment(datetime).add(1, "days").format("YYYYMMDD0000")
        $scope.sevenDaysAgoString = moment(datetime)
          .subtract(7, "days")
          .add(1, "days")
          .format("YYYYMMDD0000")
        $scope.thirdyDaysAgoString = moment(datetime)
          .subtract(30, "days")
          .add(1, "days")
          .format("YYYYMMDD0000")
        $scope.sixMonthsAgoString = moment(datetime)
          .subtract(26, "weeks")
          .add(1, "days")
          .format("YYYYMMDD0000")
        $scope.maxMonthAgoString = moment(datetime)
          .subtract($scope.maxMonth, "months")
          .format("YYYYMMDD0000")

        $scope.thisWeekString = moment(datetime).startOf("isoWeek").format("YYYYMMDD0000")
        $scope.lastWeekString = moment(datetime)
          .startOf("isoWeek")
          .subtract(1, "weeks")
          .format("YYYYMMDD0000")
        $scope.blastWeeksAgoString = moment(datetime)
          .startOf("isoWeek")
          .subtract(2, "weeks")
          .format("YYYYMMDD0000")

        $scope.options = {
          id: $stateParams.id,
          datetime: datetime,
          cacheTime: cacheTime,
          group: "group",
        }

        return $scope.$broadcast("SET_DATETIME")
      }

      const init = () =>
        SelectorSrv.getOrganizationInfo($stateParams.orgType, $stateParams.id)
          .then(function (orgInfo) {
            $scope.currentStore = orgInfo.store
            $scope.stores = orgInfo.stores
            return ($scope.timezone = orgInfo.timezone)
          })
          .then(
            () => $scope.$broadcast("init-date-search", $scope.timezone),
            function (err) {
              if (err) {
                console.error(err)
              }
              return $state.go("default")
            }
          )

      return angular.element(() => init())
    }
  )
  .controller(
    "StoreGroupEntranceThirdyCtrl",
    function ($scope, $element, $filter, ApiSrv, ChartOptionSrv, Locale) {
      const vm = ($scope.vm = this)
      vm.chartOption = angular.copy(ChartOptionSrv.daysLineChart)

      $scope.$on("SET_DATETIME", function () {
        if (!$scope.validTime) {
          return
        }
        const options = {
          from: $scope.thirdyDaysAgoString,
          to: $scope.tomorrowString,
          sampling: "1d",
          entrance: true,
        }

        $scope.showLoading("StoreEntranceThirdyCtrl")

        return ApiSrv.getCountOfStore(_.merge({}, $scope.options, options)).then(function (
          response
        ) {
          vm.chartData = []

          if (!__guard__(response != null ? response.data : undefined, (x) => x.length)) {
            $scope.hideLoading("StoreEntranceThirdyCtrl")
            return
          }

          const chartData = {
            lines: {
              fill: true,
            },
            label: Locale.string("Last 30 days"),
            data: __range__(0, 30, false).map((i) => [i, 0]),
          }

          vm.chartOption.xaxis.ticks = (() =>
            _.map(
              _.filter(__range__(0, 30, false), (n) => n % 3 === 0),
              (day) => [
                day,
                Locale.dateTime(moment.utc(options.from, "YYYYMMDD0000").add(day, "days"), "date"),
              ]
            ))()

          vm.chartOption.tooltip.content = function (label, x, y) {
            const datetime = moment.utc(options.from, "YYYYMMDD0000").add(x, "days")
            return `<b>${label}</b><br/> \
${Locale.string("Date")}: ${Locale.dateTime(datetime, "fullDate")}<br/> \
${Locale.string("Count")}: ${$filter("number")(y)}`
          }

          vm.chartOption.legend = {
            container: angular.element("#legendContainer-thirdy", $element),
            noColumns: 0,
          }

          response.data.forEach(
            (raw) =>
              (chartData.data[
                moment
                  .utc(raw.statisticFor)
                  .startOf("day")
                  .diff(moment.utc(options.from, "YYYYMMDD0000"), "days")
              ][1] = raw.count)
          )

          vm.chartData.push(chartData)
          return $scope.hideLoading("StoreEntranceThirdyCtrl")
        })
      })

      let startFlag = false

      return $scope.$on("localizeResourcesUpdated", function () {
        if (startFlag) {
          $scope.$broadcast("SET_DATETIME")
        }
        return (startFlag = true)
      })
    }
  )
  .controller(
    "StoreGroupEntranceSevenCtrl",
    function ($scope, $element, $filter, ApiSrv, ChartOptionSrv, Locale) {
      const vm = ($scope.vm = this)
      vm.chartOption = angular.copy(ChartOptionSrv.daysLineChart)

      $scope.$on("SET_DATETIME", function () {
        if (!$scope.validTime) {
          return
        }
        const options = {
          from: $scope.sevenDaysAgoString,
          to: $scope.tomorrowString,
          sampling: "1d",
          entrance: true,
        }

        $scope.showLoading("StoreEntranceSevenCtrl")

        return ApiSrv.getCountOfStore(_.merge({}, $scope.options, options)).then(function (
          response
        ) {
          let i
          vm.chartData = []

          if (!__guard__(response != null ? response.data : undefined, (x) => x.length)) {
            $scope.hideLoading("StoreEntranceSevenCtrl")
            return
          }

          const chartData = {
            lines: {
              fill: true,
            },
            label: Locale.string("Last 7 days"),
            data: (() => {
              const result = []
              for (i = 0; i < 7; i++) {
                result.push([i, 0])
              }
              return result
            })(),
          }

          response.data.forEach(
            (raw) =>
              (chartData.data[
                moment
                  .utc(raw.statisticFor)
                  .startOf("day")
                  .diff(moment.utc(options.from, "YYYYMMDD0000"), "days")
              ][1] = raw.count)
          )
          vm.chartData.push(chartData)

          vm.chartOption.xaxis.ticks = (() => {
            const result1 = []

            for (i = 0; i < 7; i++) {
              result1.push([
                i,
                Locale.dateTime(moment.utc(options.from, "YYYYMMDD0000").add(i, "days"), "date"),
              ])
            }
            return result1
          })()

          vm.chartOption.tooltip.content = function (label, x, y) {
            const datetime = moment.utc(options.from, "YYYYMMDD0000").add(x, "days")
            return `<b>${label}</b><br/> \
${Locale.string("Date")}: ${Locale.dateTime(datetime, "fullDate")}<br/> \
${Locale.string("Count")}: ${$filter("number")(y)}`
          }

          vm.chartOption.legend = {
            container: angular.element("#legendContainer-seven", $element),
            noColumns: 0,
          }

          return $scope.hideLoading("StoreEntranceSevenCtrl")
        })
      })

      let startFlag = false

      return $scope.$on("localizeResourcesUpdated", function () {
        if (startFlag) {
          $scope.$broadcast("SET_DATETIME")
        }
        return (startFlag = true)
      })
    }
  )
  .controller(
    "StoreGroupEntranceDailyCtrl",
    function ($scope, $element, $filter, ApiSrv, ChartOptionSrv, Locale) {
      const vm = ($scope.vm = this)
      vm.chartOption = ChartOptionSrv.dailyLineChart
      let lastHour = 24

      const getDefaultData = function () {
        let i
        return [
          {
            label: Locale.string("1 week before"),
            lines: {
              fill: true,
            },
            beforeValue: 0,
            data: (() => {
              const result = []
              for (i = 0; i < 24; i++) {
                result.push([i, 0])
              }
              return result
            })(),
          },
          {
            label: Locale.string("1 day before"),
            lines: {
              fill: true,
            },
            beforeValue: 0,
            data: (() => {
              const result1 = []
              for (i = 0; i < 24; i++) {
                result1.push([i, 0])
              }
              return result1
            })(),
          },
          {
            label: moment($scope.todayString, "YYYYMMDD0000").format("ll"),
            beforeValue: 0,
            data: (() => {
              const result2 = []
              for (i = 0; i < 24; i++) {
                result2.push([i, 0])
              }
              return result2
            })(),
          },
        ]
      }

      $scope.$on("SET_DATETIME", function () {
        if (!$scope.validTime) {
          return
        }
        const options = {
          from: $scope.aWeekAgoString,
          to: $scope.tomorrowString,
          sampling: "1h",
          entrance: true,
        }

        $scope.showLoading("StoreEntranceDailyCtrl")

        return ApiSrv.getCountOfStore(_.merge({}, $scope.options, options)).then(function (
          response
        ) {
          vm.chartData = []

          if (!__guard__(response != null ? response.data : undefined, (x) => x.length)) {
            $scope.hideLoading("StoreEntranceDailyCtrl")
            return
          }

          const chartData = getDefaultData()
          _.forEach(response.data, function (raw) {
            let selectedChartData
            const hour = moment.utc(raw.statisticFor).hours()
            switch (moment.utc(raw.statisticFor).format("YYYYMMDD0000")) {
              case $scope.todayString:
                selectedChartData = chartData[2]
                break
              case $scope.yesterdayString:
                selectedChartData = chartData[1]
                break
              case $scope.aWeekAgoString:
                selectedChartData = chartData[0]
                break
              default:
                return
            }

            const count = raw.count - selectedChartData.beforeValue
            selectedChartData.data[hour] = [hour, count]
            return (selectedChartData.beforeValue = raw.count)
          })

          lastHour = moment.utc(__guard__(_.last(response.data), (x1) => x1.statisticFor)).hour()
          chartData[2].data = _.filter(chartData[2].data, (raw) => raw[0] <= lastHour)

          vm.chartData = chartData
          vm.chartOption.tooltip.content = function (label, x, y) {
            const startDatetime = moment.utc(options.from, "YYYYMMDD0000").add(x, "hours")
            return `<b>${label}</b><br/> \
${Locale.string("time from")}: ${Locale.dateTime(startDatetime, "hour")}<br/> \
${Locale.string("Count")}: ${$filter("number")(y)}`
          }
          vm.chartOption.legend = {
            container: angular.element("#legendContainer-daily", $element),
            noColumns: 0,
          }

          return $scope.hideLoading("StoreEntranceDailyCtrl")
        })
      })

      let startFlag = false

      return $scope.$on("localizeResourcesUpdated", function () {
        if (startFlag) {
          $scope.$broadcast("SET_DATETIME")
        }
        return (startFlag = true)
      })
    }
  )
  .controller(
    "StoreGroupInflowDailyCtrl",
    function ($scope, $element, ApiSrv, ChartOptionSrv, Locale) {
      $scope.vm = this
      $scope.chartOption = ChartOptionSrv.dailyLineChart
      delete $scope.chartOption.yaxis.min

      const getDefaultData = function () {
        let i
        return [
          {
            label: Locale.string("1 week before"),
            lines: {
              fill: true,
            },
            beforeValue: 0,
            data: (() => {
              const result = []
              for (i = 0; i < 24; i++) {
                result.push([i, 0])
              }
              return result
            })(),
          },
          {
            label: Locale.string("1 day before"),
            lines: {
              fill: true,
            },
            beforeValue: 0,
            data: (() => {
              const result1 = []
              for (i = 0; i < 24; i++) {
                result1.push([i, 0])
              }
              return result1
            })(),
          },
          {
            label: moment($scope.todayString, "YYYYMMDD0000").format("ll"),
            beforeValue: 0,
            data: (() => {
              const result2 = []
              for (i = 0; i < 24; i++) {
                result2.push([i, 0])
              }
              return result2
            })(),
          },
        ]
      }

      $scope.$on("SET_DATETIME", function () {
        if (!$scope.validTime) {
          return
        }
        const options = {
          from: $scope.aWeekAgoString,
          to: $scope.tomorrowString,
          sampling: "1h",
          entrance: true,
        }

        let lastHour = 24
        let exitLastHour = 24

        $scope.showLoading("StoreInflowDailyCtrl")

        return async.waterfall(
          [
            (callback) =>
              ApiSrv.getCountOfStore(_.merge({}, $scope.options, options)).then(function (
                response
              ) {
                if (!__guard__(response != null ? response.data : undefined, (x) => x.length)) {
                  callback("nodata")
                }

                const entranceData = getDefaultData()
                _.forEach(response.data, function (raw) {
                  let selectedChartData
                  const hour = moment.utc(raw.statisticFor).hours()
                  switch (moment.utc(raw.statisticFor).format("YYYYMMDD0000")) {
                    case $scope.todayString:
                      selectedChartData = entranceData[2]
                      break
                    case $scope.yesterdayString:
                      selectedChartData = entranceData[1]
                      break
                    case $scope.aWeekAgoString:
                      selectedChartData = entranceData[0]
                      break
                    default:
                      return
                  }

                  const count = raw.count - selectedChartData.beforeValue
                  selectedChartData.data[hour] = [hour, count]
                  return (selectedChartData.beforeValue = raw.count)
                })

                lastHour = moment
                  .utc(__guard__(_.last(response.data), (x1) => x1.statisticFor))
                  .hour()
                return callback(null, entranceData)
              }),

            function (entranceData, callback) {
              options.entrance = false
              options.exit = true

              return ApiSrv.getCountOfStore(_.merge({}, $scope.options, options)).then(function (
                response
              ) {
                if (!__guard__(response != null ? response.data : undefined, (x) => x.length)) {
                  return callback("nodata")
                }

                const exitData = getDefaultData()
                _.forEach(response.data, function (raw) {
                  let selectedChartData
                  const hour = moment.utc(raw.statisticFor).hours()
                  switch (moment.utc(raw.statisticFor).format("YYYYMMDD0000")) {
                    case $scope.todayString:
                      selectedChartData = exitData[2]
                      break
                    case $scope.yesterdayString:
                      selectedChartData = exitData[1]
                      break
                    case $scope.aWeekAgoString:
                      selectedChartData = exitData[0]
                      break
                    default:
                      return
                  }

                  const count = raw.count - selectedChartData.beforeValue
                  selectedChartData.data[hour] = [hour, count]
                  return (selectedChartData.beforeValue = raw.count)
                })

                const inflowData = getDefaultData()

                _.forEach(exitData, (obj, index) =>
                  _.forEach(obj.data, function (extRaw, idx) {
                    const entRaw = entranceData[index].data[idx]
                    if (entRaw[0] !== extRaw[0] || entRaw[0] !== idx) {
                      return callback(new Error("Mismatch entrance data and exit data"))
                    }

                    return (inflowData[index].data[idx][1] = entRaw[1] - extRaw[1])
                  })
                )

                exitLastHour = moment
                  .utc(__guard__(_.last(response.data), (x1) => x1.statisticFor))
                  .hour()
                if (lastHour < exitLastHour) {
                  lastHour = exitLastHour
                }

                return callback(null, inflowData)
              })
            },
          ],
          function (err, result) {
            if (err) {
              $scope.chartData = []
              $scope.hideLoading("StoreInflowDailyCtrl")
              return err
            }

            result[2].data = _.filter(result[2].data, (raw) => raw[0] <= lastHour)

            $scope.chartOption.legend = {
              container: angular.element("#legendContainer", $element),
              noColumns: 0,
            }

            $scope.chartData = result
            return $scope.hideLoading("StoreInflowDailyCtrl")
          }
        )
      })

      let startFlag = false

      return $scope.$on("localizeResourcesUpdated", function () {
        if (startFlag) {
          $scope.$broadcast("SET_DATETIME")
        }
        return (startFlag = true)
      })
    }
  )
  .controller(
    "StoreGroupEntranceMonthlyCtrl",
    function ($scope, $element, $filter, ApiSrv, ChartOptionSrv, Locale) {
      const vm = ($scope.vm = this)
      vm.chartOption = angular.copy(ChartOptionSrv.daysLineChart)
      vm.chartOption.legend = {
        container: angular.element("#legendContainer-monthly", $element),
        noColumns: 0,
      }
      vm.chartOption.xaxis.minTickSize = Math.ceil($scope.maxMonth / 6)

      const monthLength = 2
      const splitJobCount = Math.ceil($scope.maxMonth / monthLength)

      $scope.$on("SET_DATETIME", function () {
        if (!$scope.validTime) {
          return
        }
        vm.chartOption.xaxis.tickFormatter = function (val) {
          const datetime = moment.utc($scope.maxMonthAgoString, "YYYYMMDD0000").add(val, "months")

          return Locale.dateTime(datetime, "month")
        }

        vm.chartOption.tooltip.content = function (label, x, y) {
          const datetime = moment.utc($scope.maxMonthAgoString, "YYYYMMDD0000").add(x, "months")
          return `<b>${label}</b><br/> \
${Locale.string("Date")}: ${Locale.dateTime(datetime, "month")}<br/> \
${Locale.string("Count")}: ${$filter("number")(y)}`
        }

        $scope.showLoading("StoreGroupEntranceMonthlyCtrl")

        return async.times(
          splitJobCount,
          function (n, callback) {
            const startMonth = moment
              .utc($scope.maxMonthAgoString, "YYYYMMDD0000")
              .add(n * monthLength, "months")
              .startOf("month")
            let endMonth = moment(startMonth).add(monthLength, "months").startOf("month")
            if (
              moment(endMonth).diff(
                moment.utc($scope.maxMonthAgoString, "YYYYMMDD0000").startOf("months"),
                "month"
              ) > $scope.maxMonth
            ) {
              endMonth = moment.utc($scope.tomorrowString, "YYYYMMDD0000").startOf("month")
            }

            const options = {
              from: startMonth.format("YYYYMMDD0000"),
              to: endMonth.format("YYYYMMDD0000"),
              sampling: "1M",
              entrance: true,
            }

            return ApiSrv.getCountOfStore(_.merge({}, $scope.options, options)).then((response) =>
              callback(
                null,
                !__guard__(response != null ? response.data : undefined, (x) => x.length)
                  ? null
                  : response
              )
            )
          },
          function (error, responses) {
            vm.chartData = []
            let validResponseCount = 0
            const chartData = {
              lines: {
                fill: true,
              },
              label: Locale.string(`last ${$scope.maxMonth} months`),
              data: __range__(0, $scope.maxMonth, false).map((i) => [i, 0]),
            }

            for (var res of Array.from(responses)) {
              if (!res) {
                continue
              }
              validResponseCount += 1
              res.data.forEach(
                (raw) =>
                  (chartData.data[
                    moment
                      .utc(raw.statisticFor)
                      .diff(
                        moment.utc($scope.maxMonthAgoString, "YYYYMMDD0000").startOf("months"),
                        "months"
                      )
                  ][1] = raw.count)
              )
            }

            if (validResponseCount) {
              vm.chartData.push(chartData)
            }
            return $scope.hideLoading("StoreGroupEntranceMonthlyCtrl")
          }
        )
      })

      let startFlag = false

      return $scope.$on("localizeResourcesUpdated", function () {
        if (startFlag) {
          $scope.$broadcast("SET_DATETIME")
        }
        return (startFlag = true)
      })
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
