"usr strict"

export const getSnapshotUrl = (item, ServiceTokenSrv, config) => {
  const servicetoken = ServiceTokenSrv.getToken()
  const query = servicetoken ? `?service_token=${servicetoken}` : ""
  let snapshotUrl
  if (item.blockSnapshot) {
    snapshotUrl = "/assets/images/blocked-image.svg"
  } else {
    //INFO check whether item object is camera or iotdevice
    const cameraId = item.uid ? undefined : item._id
    if (cameraId) {
      snapshotUrl = `${config.apipath}/camera/${cameraId}/heatmap/snapshot`
    } else {
      // floating sensor
      snapshotUrl = `${config.apipath}/iot/device/${item.accessKey}/snapshot` + query
    }
  }
  return snapshotUrl
}
