import angular, { IScope } from "angular"
import DbApi from "common-library/types/DbApi"
import _ from "lodash"

import StaffExclusion = DbApi.CameraNS.StaffExclusion

interface DirectiveScope {
  data: StaffExclusion
  onChange: (data: StaffExclusion) => void
}

type FormValue = {
  acquisition: {
    kind: StaffExclusion["acquisition"]["kind"]
    custom?: {
      from: Date
      to: Date
    }
  }
}

interface ControllerScope extends DirectiveScope, IScope {
  formValue: FormValue
}

const DEFAULT_FORM_VALUE: FormValue = {
  acquisition: {
    kind: "daily",
  },
}

angular.module("uCountitUiApp").directive("staffExclusionForm", () => ({
  restrict: "E",
  templateUrl: "components/staff-exclusion/Form.html",
  scope: {
    data: "=",
    onChange: "=",
  },
  controller: ($scope: ControllerScope, Auth: any) => {
    Auth.fetchCurrentAccount().then((me: unknown) => {
      $scope.me = me
      $scope.$apply()
    })
    $scope.formValue = DEFAULT_FORM_VALUE
    $scope.customTimeRequired = () => $scope.formValue.acquisition.kind === "custom"

    function data2form(data: StaffExclusion): FormValue {
      const form: FormValue = {
        ...data,
        acquisition: {
          ...data.acquisition,
          custom: undefined,
        },
      }
      if (data.acquisition.custom) {
        const custom = data.acquisition.custom
        const from = custom.from.split(":")
        const to = custom.to.split(":")
        const fromDt = new Date(0)
        fromDt.setHours(parseInt(from[0]))
        fromDt.setMinutes(parseInt(from[1]))
        const toDt = new Date(0)
        toDt.setHours(parseInt(to[0]))
        toDt.setMinutes(parseInt(to[1]))
        form.acquisition.custom = {
          from: fromDt,
          to: toDt,
        }
      }

      return form
    }

    function form2data(form: FormValue): StaffExclusion {
      const data: StaffExclusion = {
        ...form,
        acquisition: {
          ...form.acquisition,
          custom: undefined,
        },
      }
      if (form.acquisition.custom) {
        const custom = form.acquisition.custom
        if (custom.from && custom.to) {
          // 초이하는 버린다. angular의 <input type="time" />의 step이 작동하지 않으므로 수동으로 없애줌
          for (const time of [custom.from, custom.to]) {
            time.setSeconds(0, 0)
          }
          data.acquisition.custom = {
            from: `${custom.from.getHours().toString().padStart(2, "0")}:${custom.from
              .getMinutes()
              .toString()
              .padStart(2, "0")}`,
            to: `${custom.to.getHours().toString().padStart(2, "0")}:${custom.to
              .getMinutes()
              .toString()
              .padStart(2, "0")}`,
          }
        }
      }

      return data
    }

    $scope.$watch("data", (newVal?: StaffExclusion, oldVal?: StaffExclusion) => {
      if (newVal && _.isEqual(oldVal, newVal)) {
        return
      }
      if (newVal) {
        $scope.formValue = data2form(newVal)
      } else {
        $scope.formValue = _.cloneDeep(DEFAULT_FORM_VALUE)
        $scope.handleChange()
      }
    })

    $scope.handleChange = () => {
      if ($scope.onChange) {
        $scope.onChange(form2data($scope.formValue))
      }
    }
    if ($scope.data) {
      $scope.formValue = data2form($scope.data)
    }
  },
}))
