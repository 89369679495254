export const hotkeysTemplate = (hotkeysProvider) =>
  (hotkeysProvider.template =
    "" +
    '<div class="cfp-hotkeys-container fade" ng-class="{in: helpVisible}" style="display: none;"><div class="cfp-hotkeys">' +
    '<h4 class="cfp-hotkeys-title" ng-if="!header">{{ title }}</h4>' +
    '<div ng-bind-html="header" ng-if="header"></div>' +
    "<table><tbody><tr><td>" +
    "<table><tbody>" +
    "<tr ng-repeat=\"hotkey in hotkeys | filter:{ description: '!$$undefined$$' }\">" +
    '<td class="cfp-hotkeys-keys" ng-if="$even">' +
    '<span ng-repeat="key in hotkey.combo track by $index" class="cfp-hotkeys-key">{{ key }}</span>' +
    "</td>" +
    '<td class="cfp-hotkeys-text" ng-if="$even">{{ hotkey.description }}</td>' +
    "</tr>" +
    "</tbody></table>" +
    "</td><td>" +
    "<table><tbody>" +
    "<tr ng-repeat=\"hotkey in hotkeys | filter:{ description: '!$$undefined$$' }\">" +
    '<td class="cfp-hotkeys-keys" ng-if="$odd">' +
    '<span ng-repeat="key in hotkey.combo track by $index" class="cfp-hotkeys-key">{{ key }}</span>' +
    "</td>" +
    '<td class="cfp-hotkeys-text" ng-if="$odd">{{ hotkey.description }}</td>' +
    "</tr>" +
    "</tbody></table>" +
    "</td></tr></tbody></table>" +
    '<div ng-bind-html="footer" ng-if="footer"></div>' +
    '<div class="cfp-hotkeys-close" ng-click="toggleCheatSheet()">&#215;</div>' +
    "</div></div>")
