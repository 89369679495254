/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import { BeatLoader } from "react-spinners"
import propTypes from "prop-types"

const override = css`
  && {
    width: 62px;
    display: block;
    margin: auto auto auto auto;
  }
`

export const UcLoader = ({ color = "#016b90", loading = true }) => (
  <div css={override}>
    <BeatLoader loading={loading} color={color} />
  </div>
)

UcLoader.propTypes = {
  color: propTypes.string,
  loading: propTypes.bool,
}

export default UcLoader
