"usr strict"

const d3 = require("d3")

export const standardLabelColors = [
  "192,192,192",
  "0,153,255",
  "250,102,65",
  "48,194,24",
  "107,97,220",
] // No label, OT, Entrance, Exit

export const zoneTrafficColors = [
  "235,61,45",
  "39,174,96",
  "14,128,185",
  "255,236,57",
  "144,59,190",
  "255,164,24",
]

export const labelColors = [
  "0,94,126",
  "97,189,208",
  "155,93,66",
  "216,146,115",
  "63,84,164",
  "114,137,222",
  "113,97,21",
  "180,155,37",
  "32,101,15",
  "109,165,78",
]

export const colors = [
  "235,172,35",
  "184,0,88",
  "0,140,249",
  "0,110,0",
  "0,187,173",
  "209,99,230",
  "178,69,2",
  "255,146,135",
  "89,84,214",
  "0,198,248",
  "135,133,0",
  "0,167,108",
]

export const maskingColors = [
  "252,149,0",
  "211,149,33",
  "252,198,0",
  "211,178,33",
  "252,178,0",
  "211,198,33",
]

export const getColors = (index, type) => {
  let list
  switch (type) {
    case "standardlabel":
      list = standardLabelColors
      break
    case "label":
      list = labelColors
      break
    case "zonetraffic":
    case "trackingzone":
      list = zoneTrafficColors
      break
    case "staffzone":
      list = zoneTrafficColors.slice(1)
      break
    case "maskingzone":
      list = maskingColors
      break
    case "chart":
      list = predefinedChartColors
      break
    default:
      list = colors
  }

  return index < list.length ? list[index] : getRandomRGBColor()
}

export const getRandomRGBColor = () => {
  const r = Math.floor(Math.random() * 256)
  const g = Math.floor(Math.random() * 256)
  const b = Math.floor(Math.random() * 256)
  return `${r},${g},${b}`
}

const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min

const rgbToHsl = (r, g, b) => {
  ;(r /= 255), (g /= 255), (b /= 255)
  var max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  var h,
    s,
    l = (max + min) / 2

  if (max == min) {
    h = s = 0 // achromatic
  } else {
    var d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }

  return [h * 360, s * 100, l * 100]
}

const hslToRgb = (h, s, l) => {
  s /= 100
  l /= 100

  const k = (n) => (n + h / 30) % 12
  const a = s * Math.min(l, 1 - l)
  const f = (n) => l - a * Math.max(Math.min(k(n) - 3, 9 - k(n), 1), -1)

  const r = Math.round(255 * f(0))
  const g = Math.round(255 * f(8))
  const b = Math.round(255 * f(4))

  return [r, g, b]
}

export const generateSimilarColor = (baseColor, adj = 10) => {
  const baseRGB = baseColor.split(",").map((arr) => parseInt(arr))

  const hsl = rgbToHsl(baseRGB[0], baseRGB[1], baseRGB[2])

  const newH = Math.min(360, hsl[0] + getRandomInt(adj * -2, adj * 2))
  const newS = Math.min(100, hsl[1] + getRandomInt(adj * (-1 / 2), adj * (1 / 2)))
  const newL = Math.min(100, hsl[2] + getRandomInt(adj * -1, adj))

  const randomRGB = hslToRgb(newH, newS, newL)

  return `${randomRGB[0]},${randomRGB[1]},${randomRGB[2]}`
}

const hexToRgb = (hex) => {
  hex = hex.replace("#", "")
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("")
  }

  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return `${r},${g},${b}`
}

export const getPredefinedColors = (numColors) => {
  const colorScale = d3.scaleOrdinal(d3.schemeCategory10)
  const colors = []

  for (let i = 0; i < numColors; i++) {
    const hexColor = colorScale(i)
    colors.push(hexToRgb(hexColor))
  }

  return colors
}
export const predefinedChartColors = getPredefinedColors(20)
