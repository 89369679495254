/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory("CompanyAdminSrv", function ($rootScope, $resource) {
    return $resource(
      $rootScope.globalConfig.apipath + "/company/:id",
      { id: this.id },
      {
        updateLifeCycle: {
          url: $rootScope.globalConfig.apipath + "/company/:id/lifecycle",
          method: "POST",
        },

        updateFixedSnapshot: {
          url: $rootScope.globalConfig.apipath + "/company/:id/fixedsnapshot",
          method: "PUT",
        },
      }
    )
  })
