/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("rankingContainer", () => ({
    restrict: "E",
    templateUrl: "components/rankingcontainer/rankingcontainer.html",
    replace: true,

    scope: {
      title: "=",
      data: "=",
      sampling: "=",
      label: "=",
    },

    controller($scope, $element, $attrs, SamplingSrv, Locale) {
      $scope.rankingList = []
      $scope.sampling = $scope.sampling != null ? $scope.sampling : "day"
      $scope.MAXPERCENT = 999
      $scope.miniChartOption = {
        xaxis: {
          mode: "time",
          tickLength: 0,
        },
        yaxis: {
          min: 0,
          tickDecimals: 0,
          tickLength: 0,
          autoscaleMargin: 0.1,
        },
        lines: {
          show: true,
          lineWidth: 2,
        },
        shadowSize: 0,
        legend: {
          show: true,
          noColumns: 3,
          margin: [0, 0],
        },
        grid: {
          borderWidth: { top: 0, right: 0, bottom: 1, left: 1 },
          borderColor: "#ccc",
        },
        colors: ["#c3c3c3", "#15b0d6"],
      }
      $scope.chartData = []

      const tickFormatterFunc = function (val, axis) {
        const date = moment.utc(val)
        return Locale.dateTime(date, SamplingSrv[$scope.sampling].getTickFormat(val))
      }

      const makeLineChart = function (res) {
        const chartData = [
          {
            //compare data
            data: [],
            color: $scope.miniChartOption.colors[0],
          },
          {
            //origin data
            data: [],
            color: $scope.miniChartOption.colors[1],
          },
        ]

        for (var key in res.chartData) {
          var val = res.chartData[key]
          var date = val[0]
          var compareValue = 0
          if (res.compareChartData[key] != null) {
            compareValue = res.compareChartData[key][1]
          }
          chartData[0].data.push([date, compareValue])
          chartData[1].data.push(val)
        }

        return chartData
      }

      return $scope.$watch("data", function (data) {
        if (data != null) {
          if (
            (data != null ? data.list : undefined) != null &&
            __guard__(data != null ? data.list : undefined, (x) => x.length) > 6
          ) {
            data.list.splice(3, data.list.length - 6)
          }
          $scope.rankingList = data.list
          $scope.chartData = makeLineChart($scope.rankingList[0])
          $scope.chartData[0].label = $scope.label.compare
          $scope.chartData[1].label = $scope.label.origin

          const tickSize = SamplingSrv[$scope.sampling].getTickSize(data.from, data.to)
          const minTickSize = SamplingSrv[$scope.sampling].getMinTickSize()
          $scope.miniChartOption.xaxis.tickFormatter = tickFormatterFunc
          $scope.miniChartOption.xaxis.tickSize = [tickSize, $scope.sampling]
          return ($scope.miniChartOption.xaxis.minTickSize = [minTickSize, $scope.sampling])
        }
      })
    },
  }))

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
