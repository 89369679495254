/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .controller(
    "SpaceCtrl",
    function ($scope, $filter, $window, Auth, ApiSrv, SpaceSrv, Locale, NgTableParams, me) {
      $scope.me = me
      if (!me.gteSpace()) {
        return
      }

      $scope.itemName = Locale.string("Space")
      $scope.inputDlgTitle = "Create"
      let original = null

      $scope.revert = function () {
        $scope.form = {
          name: "",
          active: true,
        }

        $scope.form_create.$setPristine()
        return ($scope.errorMessage = "")
      }

      $scope.canSubmit = function () {
        if (!$scope.isOpenInputDlg) {
          return
        }
        return $scope.form_create.$valid && !angular.equals($scope.form, original)
      }

      $scope.submitForm = function () {
        if ($scope.inputDlgTitle === "Create") {
          return $scope.createItem($scope.form)
        } else {
          return $scope.updateItem($scope.form)
        }
      }

      $scope.createItem = (item) =>
        SpaceSrv.save(
          item,
          function (sobj, res) {
            $scope.isOpenInputDlg = false
            return $scope.reload(false)
          },
          function (error) {
            if (error.status === 409) {
              return ($scope.errorMessage = Locale.string("msg_error_name_duplicated"))
            } else {
              return ($scope.errorMessage = Locale.string("msg_error_create"))
            }
          }
        )

      $scope.updateItem = (item) =>
        SpaceSrv.update(
          { id: item._id },
          item,
          function (sobj, res) {
            $scope.isOpenInputDlg = false
            return $scope.reload(false)
          },
          function (error) {
            if (error.status === 409) {
              return ($scope.errorMessage = Locale.string("msg_error_name_duplicated"))
            } else {
              return ($scope.errorMessage = Locale.string("msg_error_create"))
            }
          }
        )

      //# List Table ##
      $scope.openInputDlg = function (item) {
        $scope.isOpenInputDlg = true
        if (item != null) {
          $scope.inputDlgTitle = "Update"
          $scope.form = angular.copy(item)
          delete $scope.form.createdAt
          $scope.form_create.$setDirty()
          $scope.errorMessage = ""
        } else {
          $scope.inputDlgTitle = "Create"
          $scope.revert()
        }

        return (original = angular.copy($scope.form))
      }

      $scope.removeItem = function (item) {
        if (!$window.confirm(Locale.string("msg_confirm_remove"))) {
          return
        }

        return SpaceSrv.delete(
          { id: item._id },
          (sobj, res) => $scope.reload(false),
          function (error) {
            if (error.data != null) {
              return ($scope.errorMessage = error.data)
            }
          }
        )
      }

      $scope.reload = (isCache) =>
        ApiSrv.getAllSpace({ isCache: isCache }).then((res) => loadItems(res))

      var loadItems = function (items) {
        $scope.Items = items
        const _getData = function ($defer, params) {
          const sorting = $scope.row
          const count = params.count()
          $scope.pagecount = count

          $scope.filterItems = $filter("filter")($scope.Items, function (val) {
            const str = new RegExp(`.*${$scope.searchKeywords}.*`, "i")

            return __guard__(val != null ? val.name : undefined, (x) => x.match(str)) != null
          })

          params.total($scope.filterItems.length)
          const orderItems = $filter("orderBy")($scope.filterItems, sorting)

          const start = (params.page() - 1) * params.count()
          const end = start + params.count()
          $scope.currentPageItems = orderItems.slice(start, end)
          return $defer.resolve($scope.currentPageItems)
        }

        return ($scope.tableParams = new NgTableParams(
          { count: $scope.pagecount != null ? $scope.pagecount : 10 },
          { counts: [10, 25, 50, 100], getData: _getData }
        ))
      }

      // filter
      $scope.searchKeywords = ""
      $scope.row = "name"

      $scope.enterKeyword = function (keyword) {
        $scope.searchKeywords = keyword
        return loadItems($scope.Items)
      }

      $scope.order = function (rowName) {
        if ($scope.currentPageItems.length < 1) {
          return
        }
        $scope.row = rowName
        return loadItems($scope.Items)
      }

      // start reload account
      return $scope.reload(false)
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
