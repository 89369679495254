// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../../../.yarn/__virtual__/html-loader-virtual-ce9d30f266/0/cache/html-loader-npm-4.1.0-8e88fdeeb9-d544cecb05.zip/node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("/assets/development/favicons/apple-touch-icon.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/assets/development/favicons/favicon-32x32.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/assets/development/favicons/favicon-16x16.png", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/assets/development/favicons/site.webmanifest", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/assets/development/favicons/safari-pinned-tab.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var code = "<link rel=\"apple-touch-icon\" sizes=\"180x180\" href=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\">\n<link rel=\"icon\" type=\"image/png\" sizes=\"32x32\" href=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\">\n<link rel=\"icon\" type=\"image/png\" sizes=\"16x16\" href=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\">\n<link rel=\"manifest\" href=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\">\n<link rel=\"mask-icon\" href=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\" color=\"#5bbad5\">\n<meta name=\"msapplication-TileColor\" content=\"#da532c\">\n<meta name=\"theme-color\" content=\"#ffffff\">\n";
// Exports
export default code;