/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import axios from "axios"

import Promise from "bluebird"
import env from "../../../../config/environment"

angular
  .module("uCountitUiApp")

  .factory("CameraAdminSrv", function ($rootScope, $resource) {
    return $resource(
      $rootScope.globalConfig.apipath + "/camera/:id",
      { id: this.id },
      { update: { method: "PUT" } }
    )
  })
  .factory("FaceDeviceSrv", ($rootScope, $resource) =>
    $resource(
      `${$rootScope.globalConfig.apipath}/camera/:id/face/device`,
      { id: "@id" },
      { set: { method: "PUT" } }
    )
  )
  .factory("FaUsageSrv", ($rootScope, $resource) =>
    $resource(
      `${$rootScope.globalConfig.apipath}/camera/:id/fausage`,
      { id: "@id" },
      { set: { method: "PUT" } }
    )
  )
  .factory("CameraConfigSrv", ($rootScope, $resource) =>
    $resource(
      `${$rootScope.globalConfig.apipath}/camera/:id/:item`,
      {
        id: "@id",
        item: "@item",
      },
      { set: { method: "PUT" }, setAsArray: { method: "PUT", isArray: true } }
    )
  )
  .factory(
    "CameraDeleteSrv",
    function (FaceDeviceSrv, CameraConfigSrv, CameraAdminSrv, DataOperationSrv) {
      return {
        deleteData(camera) {
          const contents = _.chain(camera.functions.use)
            .pick([
              "counter",
              "face",
              "rtFace",
              "heatmap",
              "dwellmap",
              "zonetraffic",
              "directionmap",
              "occupancy",
            ])
            .pickBy((feature) => feature)
            .set("snapshot", true)
            .map((_value, key) => {
              let contents = key
              if (key === "zonetraffic") {
                contents = "roiheatmap"
              }
              if (key === "rtFace") {
                contents = "face"
              }

              return contents
            })
            .value()

          return Promise.map(
            contents,
            (content) => {
              switch (content) {
                case "face":
                  return axios.delete(`/api/face/${camera._id}`)
                default:
                  return DataOperationSrv.update({
                    contents: content,
                    operation: "delete",
                    to: moment().add(1, "day").startOf("day").format("YYYY-MM-DD"),
                    srcId: camera._id,
                  }).$promise.catch((err) => console.error("[Error]: ", err))
              }
            },
            { concurrency: 2 }
          )
        },

        deleteCamera(cameraId) {
          const param = { id: cameraId }

          const promises = []
          if (!env.isLocalgrey) {
            promises.push(
              FaceDeviceSrv.delete(param).$promise.catch((err) => {
                // Sensor는 Facedevice가 존재하지 않을 수도 있다.
                if (err.status != 404) {
                  throw err
                }
              })
            )
          }

          return Promise.all(
            promises.concat([
              CameraConfigSrv.delete({ id: cameraId, item: "panorama" }).$promise,
              CameraConfigSrv.delete({ id: cameraId, item: "heatmaptags" }).$promise,
              CameraConfigSrv.delete({ id: cameraId, item: "trackAnalysis" }).$promise,
              CameraConfigSrv.delete({ id: cameraId, item: "directions" }).$promise,
              CameraConfigSrv.delete({ id: cameraId, item: "footfall" }).$promise,
            ])
          )
            .then(() => CameraAdminSrv.delete(param).$promise)
            .catch((err) => console.error("CameraDeleteSrv::deleteCamera error", err))
        },
      }
    }
  )
