/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  // swtich for mini style NAV, realted to 'collapseNav' directive
  .directive("toggleNavCollapsedMin", ($rootScope) => ({
    restrict: "A",

    link(scope, ele, attrs) {
      const app = $("#app")

      return ele.on("click", function (e) {
        if (app.hasClass("nav-collapsed-min")) {
          app.removeClass("nav-collapsed-min")
          $("#nav .bottom-info").show()
        } else {
          app.addClass("nav-collapsed-min")
          $("#nav .bottom-info").hide()
          $rootScope.$broadcast("nav:reset")
        }

        return e.preventDefault()
      })
    },
  }))
  .directive("collapseNav", () => ({
    restrict: "A",

    link(scope, ele, attrs) {
      const $window = $(window)
      const $lists = ele.find("ul").parent("li") // only target li that has sub ul
      $lists.append(
        '<i class="ti-angle-down icon-has-ul-h"></i><i class="ti-angle-double-right icon-has-ul"></i>'
      )
      const $a = $lists.children("a")
      const $listsRest = ele.children("li").not($lists)
      const $aRest = $listsRest.children("a")
      const $aLists = ele.find("ul").find("a")

      const $app = $("#app")
      const $navContainer = $("#nav-container")
      const $nav = $("#nav")

      $a.on("click", function (event) {
        // disable click event when Nav is mini style || DESKTOP horizontal nav
        if (
          $app.hasClass("nav-collapsed-min") ||
          ($navContainer.hasClass("nav-horizontal") && $window.width() >= 768)
        ) {
          return false
        }

        const $this = $(this)
        const $parent = $this.parent("li")
        $lists.not($parent).removeClass("open").find("ul").slideUp()
        $parent
          .toggleClass("open")
          .find("ul")
          .stop()
          .slideToggle(function () {
            let navHeight = 0
            $nav.children("li").each(function (index) {
              if ($(this).height() < 0) {
                return
              }
              return (navHeight += $(this).height())
            })

            const $copyright = $nav.find(".copyright")
            navHeight += $copyright.height() + 10

            if (navHeight > $nav.height()) {
              return $nav.find(".copyright").css("bottom", $nav.height() - navHeight)
            } else {
              return $nav.find(".copyright").css("bottom", "5px")
            }
          })

        return event.preventDefault()
      })

      $aRest.on("click", function (event) {
        $lists.removeClass("open").find("ul").slideUp()
        $app.toggleClass("on-canvas")
        return $nav.find(".copyright").css("bottom", "5px")
      })

      $aLists.on("click", function (event) {
        $app.toggleClass("on-canvas")
        return $nav.find(".copyright").css("bottom", "5px")
      })

      // reset NAV, sub Ul should slideUp
      scope.$on("nav:reset", (event) => $lists.removeClass("open").find("ul").slideUp())

      // removeClass('nav-collapsed-min') when size < $screen-sm
      // reset Nav when go from mobile to horizontal Nav
      let prevWidth = $window.width()
      const updateClass = function () {
        const currentWidth = $window.width()
        if (currentWidth < 768) {
          $app.removeClass("nav-collapsed-min")
        }
        if (prevWidth < 768 && currentWidth >= 768 && $navContainer.hasClass("nav-horizontal")) {
          // reset NAV, sub Ul should slideUp
          $lists.removeClass("open").find("ul").slideUp()
        }

        return (prevWidth = currentWidth)
      }

      return $window.resize(function () {
        let t
        clearTimeout(t)
        return (t = setTimeout(updateClass, 300))
      })
    },
  }))
  .directive("highlightActive", () => ({
    restrict: "A",

    controller: [
      "$scope",
      "$element",
      "$attrs",
      "$location",
      function ($scope, $element, $attrs, $location) {
        const links = $element.find("a")
        const path = () => $location.path()

        const highlightActive = (links, path) =>
          angular.forEach(links, function (link) {
            const $link = angular.element(link)
            const $li = $link.parent("li")
            const href = $link.attr("href")

            if ($li.hasClass("active")) {
              $li.removeClass("active")
            }
            if (path.indexOf(href) === 0) {
              return $li.addClass("active")
            }
          })

        highlightActive(links, $location.path())

        return $scope.$watch(path, function (newVal, oldVal) {
          if (newVal === oldVal) {
            return
          }
          return highlightActive(links, $location.path())
        })
      },
    ],
  }))

  // toggle on-canvas for small screen, with CSS
  .directive("toggleOffCanvas", () => ({
    restrict: "A",

    link(scope, ele, attrs) {
      return ele.on("click", () => $("#app").toggleClass("on-canvas"))
    },
  }))
  .directive("slimScroll", () => ({
    restrict: "A",

    link(scope, ele, attrs) {
      return $(ele).slimScroll({
        height: attrs.scrollHeight || "100%",
      })
    },
  }))
