/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory("CustomUISrv", function () {
    const customTheme = [
      { index: 1, colors: { header: "bg-white", headerLogo: "bg-blue-dark", nav: "bg-blue" } },
      { index: 2, colors: { header: "bg-blue-dark", headerLogo: "bg-blue-dark", nav: "bg-blue" } },
      { index: 3, colors: { header: "bg-blue-dark", headerLogo: "bg-blue-dark", nav: "bg-white" } },
      {
        index: 4,
        colors: { header: "bg-white", headerLogo: "bg-custom1-light", nav: "bg-custom1" },
      },
      {
        index: 5,
        colors: { header: "bg-custom1-light", headerLogo: "bg-custom1-light", nav: "bg-custom1" },
      },
      { index: 6, colors: { header: "bg-custom1", headerLogo: "bg-custom1", nav: "bg-white" } },
      { index: 7, colors: { header: "bg-white", headerLogo: "bg-dark", nav: "bg-dark" } },
      { index: 8, colors: { header: "bg-white", headerLogo: "bg-white", nav: "bg-dark" } },
      { index: 9, colors: { header: "bg-dark", headerLogo: "bg-dark", nav: "bg-white" } },
      { index: 10, colors: { header: "bg-white", headerLogo: "bg-primary", nav: "bg-dark" } },
      { index: 11, colors: { header: "bg-primary", headerLogo: "bg-primary", nav: "bg-dark" } },
      { index: 12, colors: { header: "bg-primary", headerLogo: "bg-primary", nav: "bg-white" } },
      { index: 13, colors: { header: "bg-white", headerLogo: "bg-success", nav: "bg-dark" } },
      { index: 14, colors: { header: "bg-success", headerLogo: "bg-success", nav: "bg-dark" } },
      { index: 15, colors: { header: "bg-success", headerLogo: "bg-success", nav: "bg-white" } },
      { index: 16, colors: { header: "bg-white", headerLogo: "bg-info-alt", nav: "bg-dark" } },
      { index: 17, colors: { header: "bg-info-alt", headerLogo: "bg-info-alt", nav: "bg-dark" } },
      { index: 18, colors: { header: "bg-info-alt", headerLogo: "bg-info-alt", nav: "bg-white" } },
      { index: 19, colors: { header: "bg-white", headerLogo: "bg-warning", nav: "bg-dark" } },
      { index: 20, colors: { header: "bg-warning", headerLogo: "bg-warning", nav: "bg-dark" } },
      { index: 21, colors: { header: "bg-warning", headerLogo: "bg-warning", nav: "bg-white" } },
      { index: 22, colors: { header: "bg-white", headerLogo: "bg-danger", nav: "bg-dark" } },
      { index: 23, colors: { header: "bg-danger", headerLogo: "bg-danger", nav: "bg-dark" } },
      { index: 24, colors: { header: "bg-danger", headerLogo: "bg-danger", nav: "bg-white" } },
      { index: 25, colors: { header: "bg-white", headerLogo: "bg-purple-dark", nav: "bg-purple" } },
      {
        index: 26,
        colors: { header: "bg-purple-dark", headerLogo: "bg-purple-dark", nav: "bg-purple" },
      },
      {
        index: 27,
        colors: { header: "bg-purple-dark", headerLogo: "bg-purple-dark", nav: "bg-white" },
      },
      { index: 28, colors: { header: "bg-dev-light", headerLogo: "bg-dev-dark", nav: "bg-dev" } },
      { index: 29, colors: { header: "bg-white", headerLogo: "bg-aging-dark", nav: "bg-aging" } },
    ]

    const pageTransitionOpts = [
      {
        name: "Fade up",
        class: "animate-fade-up",
      },
      {
        name: "Scale up",
        class: "ainmate-scale-up",
      },
      {
        name: "Slide in from right",
        class: "ainmate-slide-in-right",
      },
      {
        name: "Flip Y",
        class: "animate-flip-y",
      },
    ]

    const config = {
      layout: "wide", // 'boxed', 'wide'
      menu: "vertical", // 'horizontal', 'vertical'
      fixedHeader: true, // true, false
      fixedSidebar: true, // true, false
      pageTransition: pageTransitionOpts[0], // unlimited, check out "_animation.scss"
    }

    const getThemeList = () => customTheme

    const getBodyClass = () => ({
      "layout-boxed": config.layout === "boxed",
    })

    const getHeaderClass = function (index) {
      if (!index) {
        return
      }

      const theme = _.find(customTheme, { index: index })
      const configClass = {
        "header-fixed": config.fixedHeader,
        [theme.colors.header]: true,
      }

      return configClass
    }

    const getHeaderLogoClass = function (index) {
      if (!index) {
        return
      }

      const theme = _.find(customTheme, { index: index })
      return { [theme.colors.headerLogo]: true }
    }

    const getNavClass = function (index) {
      if (!index) {
        return
      }

      const theme = _.find(customTheme, { index: index })
      const configClass = {
        "nav-fixed": config.fixedSidebar,
        "nav-horizontal": config.menu === "horizontal",
        "nav-vertical": config.menu === "vertical",
        [theme.colors.nav]: true,
      }

      return configClass
    }

    const getContentClass = () => config.pageTransition.class

    return {
      getThemeList,
      getBodyClass,
      getHeaderClass,
      getHeaderLogoClass,
      getNavClass,
      getContentClass,
    }
  })
