/** @jsx jsx */

import { useState, useMemo, useEffect } from "react"
import { connect } from "react-redux"
import { createAsync } from "../../ducks/lib/redux-async"
import { jsx } from "@emotion/react"
import SelectField from "../react/SelectField"
import CompanySelect from "./CompanySelect"
import { useFormApi } from "informed"
import propTypes from "prop-types"

import {
  selectors as StoreSelector,
  actions as StoreActions,
} from "../../ducks/modules/store/model"
const StoreSelectAsync = ({ onChange, field, isPending, err, data, ...rest }) => {
  const formApi = useFormApi()
  const [companyId, setCompanyId] = useState()
  const handleCompanyChange = (option) => {
    setCompanyId(option.value)
  }

  useEffect(() => {
    if (!companyId) {
      const value = formApi.getValue(field)
      if (value) {
        const store = data.find((d) => d._id == value)
        if (store) {
          if (!companyId) setCompanyId(store._companyId)
        }
      }
    }
  })

  const options = useMemo(
    () =>
      data
        .filter((store) => store._companyId == companyId)
        .map((store) => ({
          label: store.name,
          value: store._id,
          data: store,
        })),
    [data, companyId]
  )

  return (
    <div className="row">
      <div className="col-sm-6">
        <CompanySelect value={companyId} onChange={handleCompanyChange} />
      </div>
      <div className="col-sm-6">
        <SelectField
          {...rest}
          onChange={onChange}
          loading={isPending}
          field={field}
          options={options}
        />
      </div>
    </div>
  )
}
StoreSelectAsync.propTypes = {
  onChange: propTypes.func,
  field: propTypes.string,
  isPending: propTypes.bool.isRequired,
  err: propTypes.object,
  data: propTypes.array.isRequired,
}

export const StoreSelect = connect(
  (state, ownProps) => {
    const Model = StoreSelector.model(state)
    const data = Model.all(state).toModelArray()
    return {
      ...ownProps,
      isPending: StoreSelector.index.isPending(state)(),
      isFulfilled: StoreSelector.index.isFulfilled(state)(),
      isRejected: StoreSelector.index.isRejected(state)(),
      data,
    }
  },
  (dispatch, ownProps) => {
    return {
      ...ownProps,
      actionFn: () => {
        dispatch(StoreActions.index.request())
      },
    }
  }
)(
  createAsync({
    Pending: StoreSelectAsync,
    Fulfilled: StoreSelectAsync,
  })
)

export default StoreSelect
