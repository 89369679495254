"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory("DateTimeFormat", function () {
    let DateTimeFormatSrv = {}

    class DateTimeFormat {
      constructor(time, hour, fullDate, date, month, day, simpleDay, dayOfWeek) {
        // default : en
        this.time = time
        this.hour = hour
        this.fullDate = fullDate
        this.date = date
        this.month = month
        this.day = day
        this.simpleDay = simpleDay
        this.dayOfWeek = dayOfWeek
        this.hour = "h A" // 2PM
        this.time = "LT" // 2:12PM
        this.dayOfWeek = "ddd" // Mon
        this.day = "MMM D" // Jul 3
        this.simpleDay = "M/D" // 7/3
        this.month = "MMM, YYYY" // Jul, 2017
        this.year = "YYYY"
        this.fullDate = `${this.dayOfWeek}, ll` // Mon, Jul 3, 2017
        this.date = `${this.dayOfWeek}, ${this.day}` // Mon, Jul 3
        this.fullDateTime = `${this.fullDate} ${this.hour}` // Mon, Jul 3, 2017 2PM
        this.fullDHM = `${this.fullDate} ${this.time}` // Mon, Jul 3, 2017 2:12PM
      }
    }

    class Korean extends DateTimeFormat {
      constructor() {
        super()
        this.hour = "A h시"
        this.dayOfWeek = "dddd"
        this.day = "MMMM D일"
        this.month = "YYYY년 MMMM"
        this.simpleDay = "M/D"
        this.fullDate = `LL ${this.dayOfWeek}`
        this.date = `${this.day} ddd`
        this.fullDateTime = `${this.fullDate} ${this.hour}`
      }
    }

    class Japanese extends DateTimeFormat {
      constructor() {
        super()
        this.hour = "Ah時"
        this.dayOfWeek = "dddd"
        this.day = "M月D日"
        this.simpleDay = "M/D"
        this.month = "YYYY年M月"
        this.fullDate = `LL ${this.dayOfWeek}`
        this.date = `${this.day} ddd`
        this.fullDateTime = `${this.fullDate} ${this.hour}`
      }
    }

    class Chinese extends DateTimeFormat {
      constructor() {
        super()
        this.hour = "Ah点"
        this.dayOfWeek = "dddd"
        this.day = "MMMD日"
        this.simpleDate = "M/D"
        this.month = "YYYY年MMM"
        this.fullDate = `LL${this.dayOfWeek}`
        this.date = `${this.day}ddd`
        this.fullDateTime = `${this.fullDate} ${this.hour}`
      }
    }

    class ChineseTaiwan extends DateTimeFormat {
      constructor() {
        super()
        this.hour = "Ah點"
        this.day = "MMMD日"
        this.simpleDate = "M/D"
        this.month = "YYYY年MMM"
        this.fullDate = `LL${this.dayOfWeek}`
        this.date = `${this.day}${this.dayOfWeek}`
        this.fullDateTime = `${this.fullDate} ${this.hour}`
      }
    }

    class Russian extends DateTimeFormat {
      constructor() {
        super()
        this.hour = "H:mm"
        this.day = "D MMM."
        this.simpleDay = "D/M"
        this.month = "MMM. YYYY г."
        this.fullDate = `${this.dayOfWeek}, ll`
        this.date = `${this.dayOfWeek}, ${this.day}`
        this.fullDateTime = `${this.fullDate}, ${this.hour}`
      }
    }

    class German extends DateTimeFormat {
      constructor() {
        super()
        this.hour = "H:mm"
        this.day = "D. MMM"
        this.simpleDay = "D.M"
        this.month = "MMM YYYY"
        this.fullDate = `${this.dayOfWeek}, ll`
        this.date = `${this.dayOfWeek}, ${this.day}`
        this.fullDateTime = `${this.fullDate} ${this.hour}`
      }
    }

    class Spanish extends DateTimeFormat {
      constructor() {
        super()
        this.hour = "H:mm"
        this.day = "D [de] MMM"
        this.simpleDay = "D/M"
        this.month = "MMM [de] YYYY"
        this.fullDate = `${this.dayOfWeek}, ll`
        this.date = `${this.dayOfWeek}, ${this.day}`
        this.fullDateTime = `${this.fullDate} ${this.hour}`
      }
    }

    class French extends DateTimeFormat {
      constructor() {
        super()
        this.hour = "H:mm"
        this.day = "D MMM"
        this.simpleDay = "D/M"
        this.month = "MMM YYYY"
        this.fullDate = `${this.dayOfWeek} ll`
        this.date = `${this.dayOfWeek} ${this.day}`
        this.fullDateTime = `${this.fullDate} ${this.hour}`
      }
    }

    class Italian extends DateTimeFormat {
      constructor() {
        super()
        this.hour = "H:mm"
        this.day = "D MMM"
        this.simpleDay = "D/M"
        this.month = "MMM YYYY"
        this.fullDate = `${this.dayOfWeek}, ll`
        this.date = `${this.dayOfWeek}, ${this.day}`
        this.fullDateTime = `${this.fullDate} ${this.hour}`
      }
    }

    class UnitedKingdom extends DateTimeFormat {
      constructor() {
        super()
        this.hour = "H:mm"
        this.day = "D MMM"
        this.simpleDay = "D/M"
        this.month = "MMM YYYY"
        this.fullDate = `${this.dayOfWeek}, ll`
        this.date = `${this.dayOfWeek}, ${this.day}`
        this.fullDateTime = `${this.fullDate} ${this.hour}`
      }
    }

    class Portuguese extends DateTimeFormat {
      constructor() {
        super()
        this.hour = "H:mm"
        this.day = "D [de] MMM"
        this.simpleDay = "D/M"
        this.month = "MMM [de] YYYY"
        this.fullDate = `${this.dayOfWeek}, ll`
        this.date = `${this.dayOfWeek}, ${this.day}`
        this.fullDateTime = `${this.fullDate} ${this.hour}`
      }
    }

    class Thailand extends DateTimeFormat {
      constructor() {
        super()
        this.hour = "H:mm"
        this.day = "D MMM"
        this.simpleDay = "D/M"
        this.month = "MMM YYYY"
        this.fullDate = `${this.dayOfWeek} ll`
        this.date = `${this.dayOfWeek} ${this.day}`
        this.fullDateTime = `${this.fullDate} ${this.hour}`
      }
    }
    class Sweden extends DateTimeFormat {
      constructor() {
        super()
        this.hour = "H:mm"
        this.day = "D MMM"
        this.simpleDay = "D/M"
        this.month = "MMM YYYY"
        this.fullDate = `${this.dayOfWeek} ll`
        this.date = `${this.dayOfWeek} ${this.day}`
        this.fullDateTime = `${this.fullDate} ${this.hour}`
      }
    }

    DateTimeFormatSrv = {
      "ko-kr": new Korean(),
      "ja-jp": new Japanese(),
      "en-us": new DateTimeFormat(),
      "ru-ru": new Russian(),
      "de-de": new German(),
      "es-us": new Spanish(),
      "fr-fr": new French(),
      "it-it": new Italian(),
      "en-gb": new UnitedKingdom(),
      "zh-cn": new Chinese(),
      "zh-tw": new ChineseTaiwan(),
      "pt-pt": new Portuguese(),
      "th-th": new Thailand(),
      "sv-se": new Sweden(),
    }

    return DateTimeFormatSrv
  })
