"use strict"

import angular from "angular"
import moment from "moment"

angular.module("uCountitUiApp").directive("periodConfigBtn", ($uibModal) => {
  return {
    replace: true,
    restrict: "E",
    scope: {
      onChanged: "&",
      onSelected: "&",
      periods: "=",
    },
    templateUrl: "components/period-config/period-config-btn.html",
    link: (scope, element, attrs) => {
      const dateFormat = "YYYY-MM-DD"

      scope.periodSelector = { selected: {} }

      const initializeSelector = () => {
        const initialValue = scope.activePeriods[scope.activePeriods.length - 1]
        if (initialValue) {
          scope.periodSelector.selected = initialValue
          scope.selectedPeriod(initialValue, false)
        } else {
          scope.periodSelector.selected = {}
        }
      }

      scope.$watch("periods", (newValue) => {
        if (!newValue) {
          return
        }

        scope.allPeriods = newValue.map((period) => {
          return {
            _id: period._id,
            from: moment.utc(period.from).format(dateFormat),
            to: moment.utc(period.to).add(-1, "day").format(dateFormat),
            deleted: false,
          }
        })

        scope.activePeriods = newValue.map((period, idx) => {
          return {
            _id: period._id,
            from: moment.utc(period.from).format(dateFormat),
            to: moment.utc(period.to).add(-1, "day").format(dateFormat),
            idx,
          }
        })

        initializeSelector()
      })

      scope.isShowPeriodBtn = () => {
        if (!scope.activePeriods || scope.activePeriods.length == 0) return false

        return !(
          scope.activePeriods.length === 1 &&
          scope.activePeriods[0].from === "2000-01-01" &&
          moment.utc(scope.activePeriods[0].to).isSameOrAfter("8999-12-31")
        )
      }

      scope.getDateString = (period) => {
        if (!period.from || !period.to) {
          return ""
        }
        return `${period.idx + 1}: ${period.from} - ${period.to}`
      }

      scope.showPeriodConfig = async () => {
        const modal = $uibModal.open({
          animation: false,
          backdrop: "static",
          controller: "periodConfigCtrl",
          keyboard: false,
          scope,
          templateUrl: "components/period-config/period-config.html",
          resolve: {
            periods: () => scope.allPeriods || [],
          },
        })

        try {
          const { result } = await modal.result

          const deleted = result.filter((period) => period.deleted)
          const persists = result.filter((period) => !period.deleted)
          const removed = []

          for (let i = deleted.length - 1; i >= 0; i--) {
            const idx = scope.periods.findIndex((period) => period._id == deleted[i]._id)
            if (idx >= 0) {
              removed.push(scope.periods.splice(idx, 1)[0])
            }
          }

          for (let i = scope.periods.length - 1; i >= persists.length; i--) {
            removed.push(scope.periods.splice(i, 1)[0])
          }

          scope.activePeriods = result
            .map((period, idx) => ({
              _id: period._id,
              from: period.from,
              to: period.to,
              deleted: period.deleted,
              idx,
            }))
            .filter((period) => !period.deleted)

          scope.allPeriods = result.map((period) => ({
            _id: period._id,
            from: period.from,
            to: period.to,
            deleted: period.deleted,
          }))

          for (let i = 0; i < persists.length; i++) {
            const to = moment.utc(persists[i].to).add(1, "day")

            if (!scope.periods[i]) {
              scope.periods.push({
                from: persists[i].from,
                to: to.format(dateFormat),
              })
            } else {
              scope.periods[i].from = persists[i].from
              scope.periods[i].to = to.format(dateFormat)
            }
          }

          scope.onChanged({ removed })
          initializeSelector()
        } catch (cancel) {}
      }

      scope.selectedPeriod = (period, isManual = true) => {
        const index = scope.activePeriods.indexOf(period)
        scope.onSelected({ index, isManual })
      }
    },
  }
})
