/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular.module("uCountitUiApp").directive("nodataCanvas", () => ({
  restrict: "E",

  template: [
    '<div class="canvas-container">',
    '<canvas id="nodataCanvas"></canvas>',
    "</div>",
  ].join(""),

  scope: {
    width: "=",
    height: "=",
  },

  controller($scope, $element, Locale) {
    const canvas = $element[0].querySelector("#nodataCanvas")
    const ctx = canvas.getContext("2d")
    const MIN_WIDTH = 496

    const getTextSize = function (width) {
      switch (false) {
        case !(width > 768):
          return 52
        case !(width > 320):
          return 32
        default:
          return 18
      }
    }

    const drawNodata = function () {
      const textSize = getTextSize($scope.width)
      const x = $scope.width * 0.5
      const y = $scope.height * 0.5
      ctx.fillStyle = "white"
      ctx.strokeStyle = "#737373"
      ctx.globalAlpha = 0.7
      ctx.textAlign = "center"
      ctx.font = `bold ${textSize}px roboto`
      ctx.fillText(Locale.string("No Data"), x, y)
      ctx.lineWidth = $scope.width > MIN_WIDTH ? 2 : 1
      return ctx.strokeText(Locale.string("No Data"), x, y)
    }

    return $scope.$watchGroup(["width", "height"], function (newValues, oldValues) {
      if (newValues[0] !== oldValues[0] || newValues[1] !== oldValues[1]) {
        canvas.width = newValues[0]
        canvas.height = newValues[1]
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        return drawNodata()
      }
    })
  },
}))
