/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("roleIcon", () => ({
    restrict: "EA",

    scope: {
      roleIcon: "@",
      roleIconSize: "@",
    },

    link(scope, element, attrs) {
      const addRoleIcon = function (role, size) {
        if (size == null) {
          size = 16
        }
        if (scope.className) {
          element.removeClass(scope.className)
        }

        if (role === "space") {
          role = "admin"
        }
        scope.className = `uci uci-${size} uci-${role}-role`
        return element.addClass(scope.className)
      }

      addRoleIcon(scope.roleIcon, scope.roleIconSize)

      return scope.$watch(
        () => [scope.roleIcon, scope.roleIconSize],
        (value) => addRoleIcon(scope.roleIcon, scope.roleIconSize),
        true
      )
    },
  }))
