"use strict"

import { EnvStage } from "common-library/ucountit-env"
import { Optional, Required } from "./Environment"
import { createApiUrls } from "./apiurls"

const hostname = "localhost"
const config: EnvStage<Required, Optional> = {
  brand: "ucountit",
  api: Object.assign(createApiUrls(hostname), {}),
  host: ["localhost", "127.0.0.1"],

  log: {
    analyticTrackingId: "G-XNC0EH31YX",
  },

  email: {
    from: "notification@ucountit.com",
  },

  cloudFront: {
    bucket: "ucount-it.static",
    region: "us-west-1",
    prefix: "ui-local",
    basePath: "https://cdn.retailanalysis.cloud",
  },

  web: {
    queueurl: "http://localhost:19581",
    faceurl: "/facedashboard",
    tunnelUrl: "http://tunnel.retailanalysis.cloud",
  },

  sqs: {
    sales: {
      region: "us-west-1",
      requestParsingRawQueue:
        "https://sqs.us-west-1.amazonaws.com/163170497538/SalesRequestParsingRawQueue-dev",
      confirmWaitingdataQueue:
        "https://sqs.us-west-1.amazonaws.com/163170497538/SalesConfirmWaitingdataQueue-dev",
    },
  },

  s3: {
    static: {
      bucket: "ucount-it.stage",
      destination: hostname + "/static",
      region: "us-west-1",
      cloudfront: "",
    },
    firmware: {
      bucket: "ucount-it.stage",
      destination: hostname + "/firmware",
      region: "us-west-1",
    },
    sales: {
      bucket: "ucount-it.stage",
      region: "us-west-1",
      destination: hostname + "/sales",
    },
    face: {
      bucket: "ucount-it.stage",
      destination: hostname + "/faces",
      region: "us-west-1",
    },
    customAgency: {
      bucket: "ucount-it.stage",
      destination: hostname + "/customAgency",
      region: "us-west-1",
    },
  },
}

export default config
