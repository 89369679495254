"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .controller("ucPopupConfirmCtrl", function ($scope) {
    const vm = ($scope.vm = this)

    vm.ok = function () {
      $scope.confirm($scope.ngDialogData.option)
    }

    vm.cancel = function () {
      $scope.closeThisDialog()
    }
  })
