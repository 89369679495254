"use strict"

import React from "react"
import angular from "angular"
import { react2angular } from "react2angular"
import { Component } from "react"
import { createApp } from "../../../components/react"
import ListEvent from "./ListEvent"
import Async from "react-async"
import propTypes from "prop-types"
import NewEvent from "./NewEvent"
import EditEvent from "./EditEvent"
import UcLoader from "../../../components/loaders/UcLoader"
import axios from "axios"
import moment from "moment-timezone"

class EventPage extends Component {
  static propTypes = {
    $stateParams: propTypes.object,
    $state: propTypes.object.isRequired,
    stateName: propTypes.string.isRequired,
  }

  fetchEvents = () => {
    return axios
      .get(`/api/1.0/event?_=${moment().unix()}`)
      .then((res) => res.data)
      .catch((err) => new Error(err))
  }

  handleResolve = () => {
    this.props.$state.go("app.admin.event")
  }

  watchFn = (props, prevProps) => {
    return prevProps.stateName != props.stateName && props.stateName == "app.admin.event"
  }

  render() {
    return (
      <div className="content ">
        <div className="container-default">
          <div className="page admin">
            {this.props.stateName == "app.admin.event.new" ? (
              <>
                <NewEvent onResolve={this.handleResolve} />
                <br />
              </>
            ) : null}
            <Async
              promiseFn={this.fetchEvents}
              watchFn={this.watchFn}
              stateName={this.props.stateName}
            >
              {({ data, reload, isPending, isFulfilled, isRejected, err }) => {
                if (isPending) {
                  return <UcLoader />
                } else if (isFulfilled) {
                  let editEvent = null
                  if (this.props.stateName == "app.admin.event.edit") {
                    const event = data.find((e) => e._id == this.props.$stateParams.id)
                    if (event) {
                      editEvent = <EditEvent initialValues={event} onResolve={this.handleResolve} />
                    } else {
                      editEvent = <p>Not found event {this.props.$stateParams.id}</p>
                    }
                  }

                  return (
                    <>
                      {editEvent}
                      <br />
                      <ListEvent data={data} reload={reload} />
                    </>
                  )
                } else if (isRejected) {
                  return <p>{err.message}</p>
                } else {
                  return null
                }
              }}
            </Async>
          </div>
        </div>
      </div>
    )
  }
}

angular
  .module("uCountitUiApp")
  .component(
    "eventPage",
    react2angular(createApp(EventPage), [], ["$state", "$stateParams", "$transitions", "$http"])
  )

export default EventPage
