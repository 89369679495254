/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS104: Avoid inline assignments
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import async from "async"
import angular from "angular"
import Promise from "bluebird"
import moment from "moment-timezone"
import { getSnapshotUrl } from "../../../components/snapshot"
import env from "../../../../config/environment"
import { parseFeaturesTwoLine, featuresTooltip } from "../../../components/parseFeatures"
import {
  getDeviceLicenseStatus,
  isRegistrableLicenseStatus,
  isVerifiedLicenseStatus,
} from "../../../lib/LicenseStatus"

angular
  .module("uCountitUiApp")

  .controller(
    "IoTCtrl",
    function (
      $rootScope,
      $cookieStore,
      $scope,
      $filter,
      $resource,
      $window,
      $anchorScroll,
      $timeout,
      Auth,
      IotAdminSrv,
      ApiSrv,
      Locale,
      ngDialog,
      NgTableParams,
      usSpinnerService,
      HeaderSrv,
      me,
      ServiceTokenSrv
    ) {
      let left
      $scope.booleanTypes = [
        { id: "", title: Locale.string("all") },
        { id: true, title: Locale.string("true") },
        { id: false, title: Locale.string("false") },
      ] // for ngTable Boolean Filter
      if (!me.gteSpace()) {
        return
      }
      $scope.isLocalgrey = env.isLocalgrey
      $scope.me = me
      $scope.angular = angular

      $scope.itemName = Locale.string("Sensor")
      $scope.inputDlgTitle = "Create"
      $scope.original = null
      $scope.isOpenDetailDlg = false
      $scope.detailDlg = { item: {} }
      $scope.isOpenLogDlg = false
      $scope.logDlg = { item: {} }
      $scope.isOpenUpgradeDlg = false
      $scope.isOpenTunnelDlg = false
      $scope.isOpenUpgradeFOTADlg = false
      $scope.upgradeDlg = { item: {} }
      $scope.isOpenConsoleDlg = false
      $scope.consoleDlg = { item: {} }

      $scope.idOpenRawConfigDlg = false
      $scope.selectedItem = {}

      $scope.Items = []
      $scope.filterItems = []
      $scope.firmwareList = null
      $scope.FOTAList = null
      $scope.updateFOTAnow = true
      $scope.isRegistrableLicenseStatus = isRegistrableLicenseStatus
      $scope.isVerifiedLicenseStatus = isVerifiedLicenseStatus

      $scope.header = {
        snapshot: Locale.string("Snapshot"),
        camera: Locale.string("Sensor"),
        accessKey: Locale.string("Access Key"),
        online: Locale.string("Online"),
        online_tunnel: Locale.string("Online / Tunneling"),
        lastAccess: Locale.string("Last Access"),
        uptime: Locale.string("Uptime"),
        license: Locale.string("License"),
        ops: Locale.string("ops"),
        fwversion: Locale.string("FW"),
        fotaversion: Locale.string("FOTA"),
        features: Locale.string("Features"),
        sdstatus: Locale.string("SD"),
        tunneling: Locale.string("Tunnel"),
        serviceStatus: Locale.string("Service"),
        fotaTooltip: "Installed FOTA Version / Target FOTA Version",
        featuresTooltip: featuresTooltip,
        lastAccessTooltip:
          "IoT Client Last Access Time / FOTA Client Last Access Time (FOTA-IoT >= 1 hour and Now-FOTA < 1 hour)",
        tunnelingTooltip: Locale.string(""),
        serviceStatusTooltip: Locale.string("A:Activated, D:Deactivated, S:Serviced, E:Expired"),
      }

      $scope.selectionFilters = [
        { id: "registered", name: Locale.string("Registered") },
        { id: "serviced", name: Locale.string("Serviced") },
        { id: "expired", name: Locale.string("Expired") },
        { id: "floating", name: Locale.string("Floating") },
        { id: "blocked", name: Locale.string("Blocked") },
        { id: "deactivated", name: Locale.string("Deactivated") },
        { id: "deactivated_serviced", name: Locale.string("Deactivated & Serviced") },
        { id: "unregistered", name: Locale.string("Unregistered") },
      ]

      $scope.selectedFilter =
        (left = Auth.clientInfo("admin.registerFilter")) != null ? left : $scope.selectionFilters[0]

      $scope.selectFilter = function (selected) {
        $scope.selectedFilter = selected
        Auth.clientInfo("admin.registerFilter", selected)
        return $scope.tableParams != null ? $scope.tableParams.reload() : undefined
      }

      $scope.myOrganization = !!Auth.clientInfo("admin.showSelectedOrganization")

      $scope.selectMyOrganization = function (s) {
        Auth.clientInfo("admin.showSelectedOrganization", $scope.myOrganization)
        return $scope.tableParams != null ? $scope.tableParams.reload() : undefined
      }

      $scope.checkAllLicense = () => {
        for (const item of $scope.tableParams?.data || []) {
          if (item.online) {
            IotAdminSrv.checkLicense(item, 0, 1)
              .then((licenses) => {
                item.licenses = licenses
                item.licenseStatus = getDeviceLicenseStatus(item)
              })
              .catch((err) => {
                console.warn(err)
              })
          }
        }
      }
      const cameraSchema = () => ({
        _id: "",
        name: "",
        accessKey: "",
        online: false,
        fwversion: "",
        userfs: "",
      })

      $scope.submitForm = function () {
        if ($scope.inputDlgTitle === "Create") {
          return $scope.createItem($scope.form)
        } else {
          return $scope.updateItem($scope.form)
        }
      }

      $scope.updateGroupArray = function (item) {
        if (item.group == null) {
          item.group = []
        }
        const gid = item.group.indexOf("ucountit")
        if (!item.registered) {
          if (gid >= 0) {
            return item.group.splice(gid, 1)
          }
        } else {
          if (gid < 0) {
            return item.group.push("ucountit")
          }
        }
      }

      $scope.createItem = function (item) {
        $scope.updateGroupArray(item)
        return IotAdminSrv.Device().save(
          item,
          function (res) {
            $scope.isOpenInputDlg = false
            return $scope.reload(false)
          },
          function (error) {
            if (error.data != null) {
              return ($scope.errorMessage = error.data)
            }
          }
        )
      }

      $scope.removeItem = function (item) {
        if (!$window.confirm("Are you sure?")) {
          return
        }
        return IotAdminSrv.Device().delete({ uid: item.accessKey }, function (error) {
          if (error != null) {
            $scope.errorMessage = error
          }
          _.remove($scope.Items, (it) => item.accessKey === it.accessKey)
          return loadItems($scope.Items)
        })
      }

      $scope.updateItem = function (item) {
        let update
        $scope.updateGroupArray(item)
        if (item.registered) {
          ;({ update } = IotAdminSrv.Group())
        } else {
          update = IotAdminSrv.Group().remove
        }
        return update(
          { uid: item.accessKey },
          item,
          function (res) {
            $scope.isOpenInputDlg = false
            return $scope.reload(false)
          },
          function (error) {
            if (error.data.err != null) {
              return ($scope.errorMessage = error.data.err)
            }
          }
        )
      }

      $scope.openInputDlg = function (item) {
        $scope.isOpenInputDlg = true
        if (item != null) {
          $scope.inputDlgTitle = "Update"
          $scope.form = angular.copy(item)
        } else {
          $scope.inputDlgTitle = "Create"
          $scope.form = cameraSchema()
        }
        $scope.form.isDeregistrable = checkDeregistrable($scope.form)
        $scope.errorMessage = ""
        $scope.original = angular.copy($scope.form)
        return $anchorScroll()
      }

      $scope.showDetails = function (item) {
        $scope.isOpenDetailDlg = true
        $scope.detailDlg.item = item
        return IotAdminSrv.Device().get(
          { uid: item.accessKey, _: Date.now() },
          function (res) {
            if (
              __guard__(
                __guard__(
                  __guard__(res != null ? res.data : undefined, (x2) => x2.rawconfig),
                  (x1) => x1.vca
                ),
                (x) => x.data
              ) != null
            ) {
              delete res.data.rawconfig.vca.data
            }
            if (
              __guard__(
                __guard__(
                  __guard__(res != null ? res.data : undefined, (x5) => x5.rawconfig),
                  (x4) => x4.system
                ),
                (x3) => x3.data
              ) != null
            ) {
              delete res.data.rawconfig.system.data
            }
            $scope.detailDlg.data = JSON.stringify(res.data, null, 2)
            return $anchorScroll()
          },
          function (error) {
            if (error.data != null) {
              return ($scope.errorMessage = error.data)
            }
          }
        )
      }

      $scope.showLogFile = function (item) {
        $scope.isOpenLogDlg = true
        $scope.logDlg.item = item
        $scope.logDlg.data = "loading..."
        return IotAdminSrv.Group("log").get(
          { uid: item.accessKey, _: Date.now() },
          (res) => ($scope.logDlg.data = res.data.replace(/\n/g, "<br>")),
          function (error) {
            if (error.data != null) {
              return ($scope.errorMessage = error.data)
            }
          }
        )
      }

      $scope.checkFaceFeatures = (item) =>
        (item.camera != null ? item.camera.functions.use.face : undefined) ||
        (item.camera != null ? item.camera.functions.use.rtFace : undefined)

      $scope.showFaceQuotaLog = (item) =>
        ngDialog.open({
          template: "components/facelog/facelog.html",
          data: item.camera._id,
          closeByEscape: true,
          className: "ngdialog-theme-default facelog",
          controller: "FaceLogCtrl",
        })

      $scope.runBlackList = function (item, block) {
        const job = block
          ? IotAdminSrv.addBlacklist(item.accessKey)
          : IotAdminSrv.removeBlacklist(item.accessKey)
        return job.then(() =>
          IotAdminSrv.getAllBlacklist({ isCache: false }).then(function (blacklist) {
            $scope.blacklist = blacklist
            attachBlocked($scope.devices, blacklist)
            return loadItems($scope.devices)
          })
        )
      }

      $scope.canUpdateFW = (item) =>
        (item != null ? item.newfwversion : undefined) &&
        __guard__(item != null ? item.newfwurl : undefined, (x) => x.length) &&
        (item != null ? item.online : undefined)

      $scope.canUploadPlugin = (item) =>
        (item != null ? item.newpluginversion : undefined) &&
        __guard__(item != null ? item.newpluginurl : undefined, (x) => x.length) &&
        (item != null ? item.online : undefined)

      $scope.updateFW = function (item) {
        if (!$window.confirm("Are you sure?")) {
          return
        }
        $scope.message = {
          isSuccess: true,
          value: "send command & downloading file...",
        }

        const query = {
          uid: item.accessKey,
          version: item.newfwversion,
          url: item.newfwurl,
          _: Date.now(),
        }
        return IotAdminSrv.Group("firmware").update(
          query,
          query,
          (body) =>
            ($scope.message = {
              isSuccess: true,
              value: body.message,
            }),
          function (res) {
            if (res.data != null) {
              return ($scope.message = {
                isSuccess: false,
                value: res.data.message,
              })
            }
          }
        )
      }

      $scope.uploadPlugin = function (item) {
        if (!$window.confirm("Are you sure?")) {
          return
        }
        return IotAdminSrv.Group("plugin").update(
          { uid: item.accessKey, url: item.newpluginurl },
          {},
          (body) =>
            ($scope.pluginResult = {
              isSuccess: true,
              value: body.data,
            }),
          function (res) {
            if (res.data != null) {
              return ($scope.pluginResult = {
                isSuccess: false,
                value: res.data,
              })
            }
          }
        )
      }

      $scope.showTunnel = function (item) {
        $scope.isOpenTunnelDlg = true
        $scope.message = {}
        $scope.form = item
        return $anchorScroll()
      }

      $scope.showUpgradeFW = function (item) {
        if (!$scope.firmwareList) {
          loadFWList()
        }
        $scope.isOpenUpgradeDlg = true
        $scope.message = {}
        if (item.description.properties.device.dtype === "IPM") {
          IotAdminSrv.Group("plugin").get({ uid: item.accessKey }, function (body) {
            if (body && body.data) {
              return ($scope.form.plugin = body.data)
            }
          })
        }
        $scope.pluginResult = null
        $scope.form = item
        return $timeout(() => $anchorScroll())
      }

      $scope.showUpgradeFOTA = function (item) {
        let dtype = item.description.properties.device.dtype.toLowerCase()
        if (dtype === "ipt") {
          dtype = "ipn"
        }
        const fetchFota = IotAdminSrv.getFOTAPacakges(dtype)
          .then(function (res) {
            $scope.FOTADefaultVersion = res.defaultVersion
            return ($scope.FOTAList = res.list)
          })
          .catch((ex) => console.warn("no fota packages"))

        return fetchFota.then(function () {
          $scope.isOpenUpgradeFOTADlg = true
          $scope.message = {}
          delete item.fota
          $scope.form = item
          $scope.form.nofota = false
          return IotAdminSrv.getFOTAInfo(item.accessKey)
            .then(function (fota) {
              $scope.form.fota = fota
              const selectedFOTA = _.find($scope.FOTAList, { version: fota.pkgVersion })
              $scope.form.selectedFOTA = selectedFOTA != null ? selectedFOTA : null
              return $anchorScroll()
            })
            .catch(function (err) {
              if (err.status === 404) {
                $scope.form.nofota = true
                return ($scope.message = {
                  value: Locale.string("Base FOTA package is not installed"),
                })
              } else {
                return ($scope.message = { value: err.statusText })
              }
            })
        })
      }

      $scope.canUpdateFOTA = (item) =>
        __guard__(item != null ? item.fota : undefined, (x) => x.newVersion) &&
        item.fota.newVersion !== item.fota.pkgVersion

      $scope.changeFOTAselection = (sel) => ($scope.form.fota.newVersion = sel.version)

      $scope.updateFOTA = (item) =>
        IotAdminSrv.updateFOTAInfo(item.accessKey, { pkgVersion: item.fota.newVersion })
          .then((res) => (item.fota.pkgVersion = item.fota.newVersion))
          .then(function () {
            if ($scope.updateFOTAnow) {
              return IotAdminSrv.installBaseFOTA(
                item.accessKey,
                item.description.properties.device.dtype,
                $scope.fotahost
              )
            }
          })
          .catch((err) => ($scope.message = { value: err }))

      $scope.tunnelOnOff = function (item) {
        const promise = !item.tunnel
          ? ApiSrv.tunnel.post(item.camera._id, item.tunneldurationMins)
          : //ApiSrv.tunnel.del(item._cameraId)
            ApiSrv.tunnel.update(item.camera._id, item.tunneldurationMins, "shutdown")
        return promise.then(function () {
          $scope.reload()
          return ($scope.isOpenTunnelDlg = false)
        })
      }

      $scope.tunnelUpdate = (item) =>
        ApiSrv.tunnel.update(item.camera._id, item.tunneldurationMins, null)

      $scope.installBaseFOTA = function (item) {
        usSpinnerService.spin("spinning")
        return IotAdminSrv.installBaseFOTA(
          item.accessKey,
          item.description.properties.device.dtype,
          $scope.fotahost
        )
          .then(function (res) {
            usSpinnerService.stop("spinning")
            return ($scope.message = { value: res.data })
          })
          .catch(function (err) {
            usSpinnerService.stop("spinning")
            return ($scope.message = { value: err })
          })
      }

      $scope.execConsole = function (item) {
        $scope.consoleDlg.prevcommand = $scope.consoleDlg.command
        const query = {
          uid: item.accessKey,
          command: $scope.consoleDlg.command,
          _: Date.now(),
        }
        return IotAdminSrv.Group("console").update(
          query,
          {},
          function (res) {
            $scope.consoleDlg.command = ""
            $scope.consoleDlg.data = res.data
            return $scope.focusOnConsole()
          },
          function (error) {
            if (error.data != null) {
              return ($scope.consoleDlg.data = JSON.stringify(error.data))
            }
          }
        )
      }

      $scope.focusOnConsole = () => $timeout(() => document.getElementById("inputConsole").focus())

      $scope.consoleCommand = function (cmd) {
        let exec
        const _getCommand = function (cmd) {
          switch (cmd) {
            case "read param cfg":
              return 'wget "http://localhost:25525/uapi-cgi/param.fcgi" -O /dev/stdout'
            case "iot service log":
              return "cat /var/log/app/iot_serviced.log"
            case "messages log":
              return "cat /var/log/messages"
            case "brand file":
              return "cat /usr/www/brand.xml"
            case "fota folder":
              return "ls -lat /mnt/rwfs/app"
            case "ps":
              return "ps"
            case "redo":
              return $scope.consoleDlg.prevcommand
            default:
              return null
          }
        }

        if (!(exec = _getCommand(cmd.toLowerCase()))) {
          return
        }

        $scope.consoleDlg.command = exec
        return $scope.execConsole($scope.consoleDlg.item)
      }

      $scope.showConsole = function (item) {
        $scope.isOpenConsoleDlg = true
        $scope.consoleDlg.item = item
        $scope.consoleDlg.data = ""
        return $scope.focusOnConsole()
      }

      $scope.readRawConfig = (item) =>
        IotAdminSrv.Group("db/rawconfig").get(
          { uid: item.accessKey, _: Date.now() },
          function (res) {
            item.rawconfig = res.data
            if (__guard__(item.rawconfig != null ? item.rawconfig.vca : undefined, (x) => x.at)) {
              item.lastvca = new Date(item.rawconfig.vca.at)
            }
            if (
              __guard__(item.rawconfig != null ? item.rawconfig.system : undefined, (x1) => x1.at)
            ) {
              item.lastsystem = new Date(item.rawconfig.system.at)
            }
            if (item.backupSystemConfig == null) {
              item.backupSystemConfig = true
            }
            if (item.backupVcaConfig == null) {
              return (item.backupVcaConfig = false)
            }
          }
        )

      $scope.showRawConfig = function (item) {
        $scope.idOpenRawConfigDlg = true
        $scope.errorMessage = null
        $scope.selectedItem = item
        return $scope.readRawConfig($scope.selectedItem)
      }

      const _convertYesNo = function (tf) {
        if (tf) {
          return "yes"
        } else {
          return "no"
        }
      }

      $scope.backupRawConfig = function (item) {
        if ($scope.canRestore()) {
          if (!$window.confirm("Are you sure? It will overwrite")) {
            return
          }
        }
        $scope.errorMessage = null
        const uid = item.accessKey
        const query = {
          uid,
          vca: _convertYesNo(item.backupVcaConfig),
          system: _convertYesNo(item.backupSystemConfig),
          _: Date.now(),
        }
        return IotAdminSrv.Group("rawconfig").get(
          query,
          function (res) {
            if (res.error != null) {
              return ($scope.errorMessage = res.error)
            }
            if (res.data == null) {
              return ($scope.errorMessage = "no rawconfig data")
            }
            const rawconfig = {}
            if (item.backupVcaConfig && res.data.vca != null) {
              rawconfig.vca = {
                at: new Date(),
                data: res.data.vca, //"vca test data"
              }
            }
            if (item.backupSystemConfig && res.data.system != null) {
              rawconfig.system = {
                at: new Date(),
                data: res.data.system, //"system test data"
              }
            }
            $scope.errorMessage = "succeed to download"
            return IotAdminSrv.Group("db/rawconfig").save(
              { uid },
              rawconfig,
              function (res) {
                $scope.errorMessage = "succeed to download & backup"
                return $scope.readRawConfig($scope.selectedItem)
              },
              function (error) {
                if (error.data != null) {
                  return ($scope.errorMessage = error.data)
                }
              }
            )
          },
          function (error) {
            if (error.data != null) {
              return ($scope.errorMessage = error.data)
            }
          }
        )
      }

      $scope.restoreRawConfig = function (item) {
        if (!$window.confirm("Are you sure?")) {
          return
        }
        $scope.errorMessage = null
        const uid = item.accessKey
        const query = {
          uid,
          vca: _convertYesNo(item.restoreVcaConfig),
          system: _convertYesNo(item.restoreSystemConfig),
          _: Date.now(),
        }
        return IotAdminSrv.Group("rawconfig").save(
          query,
          item.rawconfig,
          function (res) {
            if (res.error != null) {
              return ($scope.errorMessage = res.error)
            }
            let msg = "succeed to restore."
            if (item.restoreSystemConfig) {
              msg += "The client will reboot"
            }
            return ($scope.errorMessage = msg)
          },
          function (error) {
            if (error.data != null) {
              return ($scope.errorMessage = error.data)
            }
          }
        )
      }

      $scope.canBackup = () =>
        $scope.selectedItem.backupSystemConfig || $scope.selectedItem.backupVcaConfig

      $scope.canRestore = function () {
        const item = $scope.selectedItem
        return (
          (item.restoreVcaConfig &&
            __guard__(item.rawconfig != null ? item.rawconfig.vca : undefined, (x) => x.data)) ||
          (item.restoreSystemConfig &&
            __guard__(item.rawconfig != null ? item.rawconfig.system : undefined, (x1) => x1.data))
        )
      }

      // eslint-disable-next-line no-unused-vars
      const iotClientVersion = function (item) {
        const version = __guard__(
          __guard__(
            item.description != null ? item.description.properties : undefined,
            (x1) => x1.iotclient
          ),
          (x) => x.version
        )
        if (!version) {
          return 0
        }
        const [M, m, r] = Array.from(version.match(/\d+/g))
        return Number(M) * 10000 + Number(m) * 100 + Number(r)
      }

      $scope.selectOperation = function (item, ops, body) {
        if (body == null) {
          body = {}
        }
        if (!$window.confirm("Are you sure?")) {
          return
        }
        $scope.errorMessage = null
        return IotAdminSrv.Group("operation").update(
          { uid: item.accessKey, command: ops },
          body,
          function (res) {
            if (res.error != null) {
              return ($scope.errorMessage = res.error)
            }
            return ($scope.errorMessage = "success command: " + ops)
          },
          function (error) {
            if (error.data != null) {
              return ($scope.errorMessage = error.data)
            }
          }
        )
      }

      const parseParamList = function (str) {
        const result = []
        str.split("\r\n").forEach(function (pair) {
          let list
          const [node, explain] = Array.from(pair.split("="))
          if (!explain) {
            return
          }
          let [attr, type, min, max] = Array.from(explain.split("|"))

          attr = {
            //support : attr[0] is 's'
            add: attr[1] === "a",
            remove: attr[2] === "r",
            update: attr[3] === "u",
            list: attr[4] === "l",
          }

          let isRange = false
          let isArray = false
          let maxchar = 0
          switch (type) {
            case "area":
            case "direction":
            case "idlist":
            case "MD":
            case "msec":
            case "sec":
            case "kbitps":
            case "kbyteps":
            case "fps":
            case "port":
            case "int":
            case "percent":
              isRange = true
              break
            case "sz":
            case "url":
              maxchar = true
              break
            case "select":
            case "mselect":
              isArray = true
              break
            case "yesno":
              isArray = true
              if (!min) {
                min = "yes,no"
              }
              break
            case "count":
              list = null
              break
          }

          if (isArray && min) {
            list = min.split(",")
          }
          if (isRange) {
            if (min && !max) {
              ;[min, max] = Array.from(min.split(":"))
            }
          }

          const rs = {
            attr,
            type,
            list,
            isArray,
            isRange,
          }

          if (maxchar) {
            rs.maxchar = parseInt(list)
          }
          if (isRange) {
            rs.min = parseInt(min)
          }
          if (isRange) {
            rs.max = parseInt(max)
          }
          if (attr.list) {
            return result.push({ key: node, data: rs })
          }
        })

        return result
      }

      const parseGroupList = function (params) {
        const result = []
        const preKey = null
        _.forEach(params, function (param) {
          const key = param.key.split(".")[0]
          if (key !== preKey) {
            return result.push(key)
          }
        })

        return _.sortBy(_.uniq(result))
      }

      $scope.showParamsLength = 100
      $scope.moreParams = () => ($scope.limitParams += $scope.showParamsLength)

      $scope.loadParamConfig = function (item) {
        $scope.limitParams = $scope.showParamsLength
        $scope.selectedGroup = "VCA"

        return IotAdminSrv.Group("rawconfig/param/explain").get(
          { uid: item.accessKey, _: Date.now() },
          function (res) {
            item.paramConfig = []
            item.groupList = []
            if (res.error != null) {
              return ($scope.errorMessage = res.error)
            }
            if (res.data == null) {
              return ($scope.errorMessage = "no data")
            }
            const params = parseParamList(res.data)
            const groups = parseGroupList(params)
            item.groupList = groups
            item.paramConfig = params

            return IotAdminSrv.Group("rawconfig/param").get(
              { uid: item.accessKey, _: new Date().getTime() },
              function (res) {
                if (res.error != null) {
                  return ($scope.errorMessage = res.error)
                }
                if (res.data == null) {
                  return ($scope.errorMessage = "no data")
                }
                return res.data.split("\r\n").forEach(function (pair) {
                  const [key, value] = Array.from(pair.split("="))
                  const obj = _.find(params, { key })
                  if (obj) {
                    return (obj.data.value = value)
                  }
                })
              }
            )
          }
        )
      }

      $scope.updateParamConfig = function (item, key, data) {
        const params = [{ name: key, value: data.value }]
        return IotAdminSrv.Group("rawconfig/param").update(
          { uid: item.accessKey },
          params,
          function (res) {
            if (res.error != null) {
              return ($scope.errorMessage = res.error)
            }
          },
          function (error) {
            if (error.data != null) {
              return ($scope.errorMessage = error.data)
            }
          }
        )
      }

      $scope.showParamConfig = function (item) {
        $scope.isOpenParamDlg = true
        $scope.errorMessage = null
        $scope.selectedItem = item
        return $scope.loadParamConfig(item)
      }

      $scope.runParamConfig = (item) =>
        ngDialog.open({
          template: "components/runparamscript/index.html",
          data: item,
          closeByEscape: false,
          closeByDocument: false,
          className: "ngdialog-theme-default param-text-script",
          controller: "ParamScriptCtrl",
        })

      $scope.changeFWselection = function (sel) {
        $scope.form.newfwversion = sel.version
        return ($scope.form.newfwurl = new window.URL(
          sel.url,
          `${location.protocol}//${location.host}`
        ).toString())
      }

      $scope.changePluginselection = function (sel) {
        $scope.form.newpluginversion = sel.version
        return ($scope.form.newpluginurl = new window.URL(
          sel.url,
          `${location.protocol}//${location.host}`
        ).toString())
      }

      const ipn_ver_pattern = /V(\d+(\.\d+)+)/
      const ipm_ver_pattern = /^(A[.0-9]+_[0-9]+)/
      var loadFWList = function () {
        const firmwareUrl = `${$rootScope.globalConfig.apipath}/iot/firmware`
        return $resource(firmwareUrl).query(
          { _: Date.now() },
          (res) =>
            ($scope.firmwareList =
              res != null
                ? res.map(function (file) {
                    const _parseVersion = function (name) {
                      //INFO only to get the main firmware version
                      //ex) IPN-V1.12.12.6-limit.enc ufs_1.12.12.6_190910.enc
                      const ipn_ver_match = name.match(ipn_ver_pattern)
                      if (ipn_ver_match) {
                        return ipn_ver_match[1]
                      }

                      const ipm_ver_match = name.match(ipm_ver_pattern)
                      if (ipm_ver_match) {
                        return ipm_ver_match[1]
                      }

                      return name
                    }

                    const version = _parseVersion(file.name)
                    return { version, url: file.url, name: file.name }
                  })
                : undefined)
        )
      }

      $scope.openMemo = (camera) =>
        ngDialog.open({
          template: "components/cameranote/cameranote.html",
          data: camera,
          closeByEscape: false,
          closeByDocument: false,
          className: "ngdialog-theme-default cameranote",
          controller: "CameraNoteCtrl",
        })

      $scope.removeLicense = async function (item) {
        if (!$window.confirm(Locale.string("msg_confirm_remove_license"))) {
          return
        }
        item.licenseStatus = "removing"
        try {
          await IotAdminSrv.removeLicense(item, { actor: me.email })
        } catch (err) {
          return $window.alert(err.message)
        }
        try {
          item.licenseStatus = "syncing"
          const licenses = await IotAdminSrv.checkLicense(item, null, null)
          item.licenses = licenses
        } catch (err) {
          $window.alert(`Fail to read license: ${err.message}`)
          item.licenses = []
        }
        item.licenseStatus = getDeviceLicenseStatus(item)
      }

      $scope.removableLicense = function (item) {
        return (
          isRegistrableLicenseStatus(item.licenseStatus) &&
          item.online &&
          item.registered &&
          item.issuableLicense
        )
      }

      $scope.installLicense = async function (item) {
        if (!$window.confirm("Are you sure?")) {
          return
        }
        item.licenseStatus = "installing"
        try {
          await IotAdminSrv.installLicense(item, { actor: me.email })
          try {
            item.licenseStatus = "syncing"
            const licenses = await IotAdminSrv.checkLicense(item, null, null)
            item.licenses = licenses
            item.licenseStatus = getDeviceLicenseStatus(item)
            return
          } catch (err) {
            const message = err?.data?.message || err.message
            item.licenseStatus = "unknown"
            $window.alert(message)
          }
        } catch (err) {
          const message = err?.data?.message || err.message
          $window.alert("fail to install a license. " + message)
          item.licenseStatus = "unknown"
          item.lienseStatus = getDeviceLicenseStatus(item)
        }
      }

      $scope.installableLicense = function (item) {
        return (
          item.licenseStatus == "noLicense" &&
          item.online &&
          item.registered &&
          item.issuableLicense
        )
      }

      //#########################################################################
      // table
      const checkIfCameraisUsed = (cam) =>
        __guard__(cam != null ? cam.storeid : undefined, (x) => x.length) > 0

      var checkDeregistrable = (cam) => !checkIfCameraisUsed(cam)

      const _getLocalTime = (at, offset) => moment(at).utcOffset(offset).format("YYYY-MM-DD HH:mmZ")

      const setDurationString = function (item) {
        if (
          __guard__(item.status != null ? item.status.uptime : undefined, (x) => x.duration) == null
        ) {
          return
        }

        const _pad = (num) => _.padStart(num, 2, "0")

        const _GetDurationString = function (msDuration) {
          const _d = moment.duration(msDuration)
          const days = Math.floor(_d.asDays())
          let str = days ? `${days}D ` : ""
          return (str += `${_pad(_d.hours())}:${_pad(_d.minutes())}`)
        }

        item.uptime = item.status.uptime.duration
        return (item.uptimestr = _GetDurationString(item.status.uptime.duration))
      }

      const setCameraInfo = function (item, callback) {
        const _toGiga = function (m) {
          if (!m) {
            return "?"
          }
          const u = m.slice(-1)
          const n = Math.round(m.slice(0, +-2 + 1 || undefined))
          const base = u === "M" ? 1000 : u === "K" ? 1000000 : 1
          let g = (n / base).toFixed(1)
          g = !Number(g) ? "0.1" : g
          return g + "G"
        }

        const _setSDStatus = function (item) {
          const { sd } = item.status
          item.sdStatusUsage = ""
          item.sdStatus = ""
          item.sdUsage = ""
          item.sdError = false

          if (!(sd != null ? sd.mount : undefined)) {
            return
          }
          if (sd.mount === "not installed") {
            item.sdStatus = "NO SD"
            item.sdError = true
            item.sdStatusUsage = item.sdStatus + item.sdUsage
            return
          } else if (sd.mount === "not available") {
            item.sdStatus = "-"
            item.sdStatusUage = ""
            return
          }

          // eslint-disable-next-line no-unused-vars
          let [x, u, d, t] = Array.from(sd.mount.split(" "))
          const rw = sd.readwrite === "ok" ? "RW" : "RO"
          item.sdError = rw !== "RW"
          u = _toGiga(u)
          const usage = [u, d, t].join("")
          item.sdStatus = rw
          item.sdUsage = usage
          return (item.sdStatusUsage = item.sdStatus + item.sdUsage)
        }

        const _makeOrganizationName = function (item) {
          if (item.camera) {
            item.companyid = item.camera._companyId
            item.storeid = item.camera._storeId
            item.storename = item.camera?.store?.name

            const company = _.find($scope.companies, (d) => d._id === item.companyid)
            item.agencyid = company?._agencyId
            item.companyname = company?.name

            const agency = _.find($scope.agencies, (d) => d._id === item.agencyid)
            item.agencyid = agency?._id
            item.agencyname = agency?.name
          }

          return (item.orgname = `${item.agencyname} > ${item.companyname} > ${item.storename} > ${item.name}`)
        }

        const _checkLastAccessAndHeartBeat = function (iot, fota) {
          const now = moment()
          iot = moment(iot)
          fota = moment(fota)
          return Math.abs(iot.diff(fota, "hours")) >= 1 && Math.abs(now.diff(fota, "hours")) < 1
        }

        if (item.accessKey == null) {
          item.accessKey = item.uid
          item.fwversion = __guard__(
            __guard__(
              item.description != null ? item.description.properties : undefined,
              (x1) => x1.firmware
            ),
            (x) => x.version
          )
          item.vcaPlugin =
            __guard__(
              item.description != null ? item.description.properties : undefined,
              (x2) => x2.firmware["VCAedge"]
            ) ||
            __guard__(
              __guard__(item != null ? item.description : undefined, (x4) => x4.properties),
              (x3) => x3.firmware["VCAedge-AI"]
            )
          item.displayFwVersion = item.fwversion
          if (item.vcaPlugin) {
            item.displayFwVersion += `\n${item.vcaPlugin}`
          }
          item.userfs = __guard__(
            __guard__(
              item.description != null ? item.description.properties : undefined,
              (x6) => x6.firmware
            ),
            (x5) => x5.userfs
          )
          item.offset = __guard__(
            item.description != null ? item.description.properties : undefined,
            (x7) => x7.timezone.offset
          )
          if (item.offset) {
            item.lastaccesstime = _getLocalTime(
              __guard__(item.status != null ? item.status.heartbeat : undefined, (x8) => x8.at),
              item.offset
            )
            if (item.fotaheartbeat) {
              item.heartbeatMismatch = _checkLastAccessAndHeartBeat(
                __guard__(item.status != null ? item.status.heartbeat : undefined, (x9) => x9.at),
                item.fotaheartbeat
              )
              item.lastheartbeat = _getLocalTime(item.fotaheartbeat, item.offset)
            }
          }
          _setSDStatus(item)
          setDurationString(item)
        }

        item.registered = item.camera ? true : false

        if (!item.cid) {
          const camera = _.find($scope.cameras, { accessKey: item.accessKey })
          if (camera != null) {
            const store = _.find($scope.stores, { _id: camera._storeId })
            const company = _.find($scope.companies, {
              _id: store != null ? store._companyId : undefined,
            })
            item.blockSnapshot = company != null ? company.blockSnapshot : undefined
            item.companyId = company != null ? company._id : undefined
            item.name = camera.name
            item.cid = camera._id
            item.storeid = camera._storeId
            //INFO: UCNT-2548 disable backup feature temporarily
            item.supportRawConfig = false //item.online and iotClientVersion(item) >= 10002
          }
        }

        item.licenseStatus = getDeviceLicenseStatus(item)

        if (!item.snapshotUrl) {
          item.snapshotUrl = getSnapshotUrl(item, ServiceTokenSrv, $rootScope.globalConfig)
        }
        if (!item.storename) {
          _makeOrganizationName(item)
        }

        return callback()
      }

      const attachOragnization = function (devices) {
        $scope.cameras.forEach(function (c) {
          const store = _.find($scope.stores, { _id: c._storeId })
          if (store) {
            c.store = store
            const company = _.find($scope.companies, { _id: store._companyId })
            if (company) {
              return (c.company = company)
            }
          }
        })

        return devices.forEach(function (d) {
          const camera = _.find($scope.cameras, { accessKey: d.uid })
          if (camera) {
            return (d.camera = camera)
          }
        })
      }

      const attachFotaInfo = function (devices, fotas) {
        fotas.forEach(function (f) {
          const item = _.find(devices, { uid: f.accessKey })
          if (item) {
            item.fotaversion = f.pkgVersion
            item.fotainstalled = f.deviceVersion
            return (item.fotaheartbeat = f.heartbeat)
          }
        })
        const versionReplacePattern = /[A-Za-z]+/g
        return devices.forEach(function (item) {
          if (item.description.fota && item.description.fota.embeded) {
            const newVersion = `E V${item.description.fota.version.replaceAll(
              versionReplacePattern,
              ""
            )}`
            item.fotainstalled = newVersion
            return (item.fotaversion = newVersion)
          }
        })
      }

      const getOnlineTunnelStatus = (dev) =>
        (typeof dev.online === "undefined" ? "?" : dev.online) + "_" + dev.tunnelStatus

      const getTunnelPortDesc = function (dev) {
        if (dev.uid && dev.uid.indexOf("NVF") >= 0) {
          return "port:WWW port+1:RTSP port+2:ssh"
        } else {
          return "port:WWW port+1:RTSP port+2:telnet"
        }
      }

      const attachTunnelInfo = function (devices, tunnels) {
        tunnels.forEach(function (f) {
          const item = devices.find((device) => device.camera?._id == f._cameraId)
          if (item) {
            return (item.tunnel = f)
          }
        })

        return devices.forEach(function (dev) {
          if (dev.tunnel) {
            dev.tunnelStatus = (() => {
              switch (dev.tunnel.status) {
                case "shutdown":
                  return Locale.string("shutdown")
                default:
                  return Locale.string("on")
              }
            })()
            dev.tunneldurationMins = dev.tunnel.durationMins
            const gap = moment().diff(moment(dev.tunnel.startAt))
            dev.tunnelUptime = Math.floor(moment.duration(gap).asMinutes())
            dev.tunnelUrl = `${env.web.tunnelUrl}:${dev.tunnel.port}`
            dev.tunnelBtnName = Locale.string("off")
          } else {
            dev.tunnelStatus = Locale.string("off")
            dev.tunneldurationMins = 120
            dev.tunnelBtnName = Locale.string("on")
          }
          dev.tunnelPortDesc = getTunnelPortDesc(dev)
          return (dev.online_tunnel = getOnlineTunnelStatus(dev))
        })
      }

      var attachBlocked = (devices, blacklist) =>
        devices.forEach(function (d) {
          const item = _.find(blacklist, { accessKey: d.uid })
          return (d.blocked = !!item)
        })

      const attachFeatures = function (devices, cameras) {
        devices.forEach(function (d) {
          if (d.camera) {
            const [fone, ftwo] = parseFeaturesTwoLine(d.camera.functions.use)
            d.features = fone.concat(ftwo)
            d.features_one = fone.join("·")
            d.features_two = ftwo.join("·")
          }
        })
      }

      const attachServiceStatus = function (devices) {
        const now = new Date()
        return devices.forEach(function (item) {
          const { camera } = item
          if (!camera) {
            return
          }

          item.serviceBeginDate = camera.serviceBeginDate ? new Date(camera.serviceBeginDate) : ""
          item.serviceEndDate = camera.serviceEndDate ? new Date(camera.serviceEndDate) : ""
          const S = !(item.serviceEndDate && now > item.serviceEndDate)
          const A =
            isRegistrableLicenseStatus(item.licenseStatus) &&
            camera.active &&
            camera.store.active &&
            camera.company.active
          item.serviceStatusActive = A
          item.serviceStatusService = S
          const ss = (A ? "A" : "D") + (S ? "S" : "E")
          item.serviceStatusOrder = ["DS", "AE", "AS", "DE"].indexOf(ss)
          item.serviceStatusWarning = ss === "DS" || ss === "AE"
          return (item.serviceStatus = ss)
        })
      }

      const getData = function (params) {
        let filter = params.filter()
        const sorting = params.sorting()

        const _removeNonamefilter = function (orgfilter) {
          const newfilter = angular.copy(orgfilter)
          for (var f in newfilter) {
            var v = newfilter[f]
            if (v === "" || v === null) {
              delete newfilter[f]
            }
          }
          return newfilter
        }

        let filterItems = $scope.Items
        if ($scope.myOrgInfo && $scope.myOrganization) {
          filterItems = $filter("filter")($scope.Items, function (device) {
            switch ($scope.myOrgInfo.orgType) {
              case "company":
                return $scope.myOrgInfo._id === device.companyid
              case "storegroup":
                return $scope.myOrgInfo.storeList.indexOf(device.storeid) !== -1
              case "store":
                return $scope.myOrgInfo._id === device.storeid
              default:
                return device
            }
          })
        }

        if ($scope.selectedFilter.id === "expired") {
          filterItems = $filter("filter")(
            filterItems,
            (device) => device.cid != null && !device.serviceStatusService
          )
        } else if ($scope.selectedFilter.id === "serviced") {
          filterItems = $filter("filter")(
            filterItems,
            (device) => device.cid != null && device.serviceStatusService
          )
        } else if ($scope.selectedFilter.id === "deactivated") {
          filterItems = $filter("filter")(
            filterItems,
            (device) => device.cid != null && !device.serviceStatusActive
          )
        } else if ($scope.selectedFilter.id === "deactivated_serviced") {
          filterItems = $filter("filter")(
            filterItems,
            (device) =>
              device.cid != null && !device.serviceStatusActive && device.serviceStatusService
          )
        } else if ($scope.selectedFilter.id === "floating") {
          filterItems = $filter("filter")(filterItems, (device) => {
            return device.cid == null && !device.blocked
          })
        } else if ($scope.selectedFilter.id === "blocked") {
          filterItems = $filter("filter")(filterItems, (device) => !device.cid && device.blocked)
        } else if ($scope.selectedFilter.id === "registered") {
          filterItems = $filter("filter")(filterItems, (device) => device.registered)
        } else if ($scope.selectedFilter.id === "unregistered") {
          filterItems = $filter("filter")(filterItems, (device) => !device.registered)
        }

        filter = _removeNonamefilter(filter)
        filterItems = filter ? $filter("filter")(filterItems, filter) : filterItems
        params.total(filterItems.length)
        const sortingkey = Object.keys(sorting)
        const sortingvalue = sorting[sortingkey]
        const reverse = sortingvalue === "desc"
        $scope.filterItems = $filter("orderBy")(filterItems, sortingkey, reverse)
        const start = (params.page() - 1) * params.count()
        const end = start + params.count()
        const pageItems = $scope.filterItems.slice(start, end)
        return pageItems
      }

      const fetchMyOrgInfo = () =>
        HeaderSrv.fetchCurrentStore().then((orgInfo) => ($scope.myOrgInfo = orgInfo))

      var loadItems = function (items) {
        if (items != null) {
          $scope.Items = items
        }

        async.each(
          $scope.Items,
          function (item, callback) {
            delete item.rawconfig
            return setCameraInfo(item, callback)
          },
          function (_err) {
            if ($scope.tableParams != null) {
              return $scope.tableParams.reload()
            }
          }
        )

        // add blank string to fix IE blank filter bug(UCNT-1099)
        if ($scope.tableParams == null) {
          const params = {
            count: 25,
            filter: {
              //registered: $scope.selectedFilter.id !== "unregistered",
              online: "",
            },
            sorting: { orgname: "asc" },
          }
          const settings = { counts: [10, 25, 50, 100], getData }
          return ($scope.tableParams = new NgTableParams(params, settings))
        }
      }

      $scope.reload = function (isCache) {
        if (isCache == null) {
          isCache = true
        }
        const fetchSensors = Promise.all([
          ApiSrv.getAllAgency({ isCache }),
          ApiSrv.getAllCompany({ isCache }).then((res) =>
            _.map(res, function (element) {
              element = element.clone()
              element.blockSnapshot = element.checkBlockedSnapshot(me)
              return element
            })
          ),
          ApiSrv.getAllStore({ isCache }),
          ApiSrv.getAllCamera({ isCache }),
          IotAdminSrv.getAllDevice({ isCache }),
          IotAdminSrv.getAllFOTAInfo({ heartbeat: true }),
          IotAdminSrv.getAllBlacklist({ isCache }),
          ApiSrv.getServiceEnvironment(),
          $scope.isLocalgrey ? Promise.resolve([]) : ApiSrv.tunnel.getAll(),
          fetchMyOrgInfo(),
        ])
          .spread(function (
            agencies,
            companies,
            stores,
            cameras,
            devices,
            fotas,
            blacklist,
            srvEnv,
            jobTunnel,
            orgInfo
          ) {
            $scope.agencies = agencies
            $scope.companies = companies
            $scope.stores = stores
            $scope.cameras = cameras
            $scope.devices = devices
            $scope.jobTunnel = jobTunnel
            $scope.fotahost = new URL(srvEnv.services.api.ioturl).hostname
            if (!$scope.fotahost || $scope.fotahost === "localhost") {
              $scope.fotahost = "iot-dev.retailanalysis.cloud"
            }
            $scope.blacklist = blacklist
            attachOragnization(devices)
            attachFeatures(devices, cameras)
            attachFotaInfo(devices, fotas)
            attachTunnelInfo(devices, jobTunnel)
            attachBlocked(devices, blacklist)

            const items = loadItems(devices)

            attachServiceStatus(devices, cameras)

            return items
          })
          .catch((ex) => console.error("reload error", ex))
        return Promise.all([fetchSensors])
      }

      $scope.reload()

      return ($scope.isValidTunnel = (tunnel) =>
        tunnel && (tunnel != null ? tunnel.status : undefined) !== "shutdown")
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
