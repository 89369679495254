"use strict"
const _ = require("lodash")
const PassingLine = require("../PassingLine")

/*
const testRemoveValue = () => {
  let t, rmValue
  const kLength = 8
  const t1 = new PassingLine([1, 3, 2], kLength)
  t1.print()
  console.info("t1.isArranged()", t1.isArranged())

  t1.arrange()
  t1.print()
  console.info("t1.isArranged()", t1.isArranged())

  const t2 = new PassingLine([0, 1, 2, 6, 7], kLength)
  t2.print()
  t2.arrange()
  t2.print()
  console.info("t2.isArranged()", t2.isArranged())

  const t4 = new PassingLine([0], kLength)
  t4.print()
  t4.arrange()
  t4.print()
  console.info("t4.isArranged()", t4.isArranged())

  const t3 = new PassingLine([0, 1, 3, 6, 7], kLength)
  t3.print()
  t3.arrange()
  t3.print()
  console.info("t3.isArranged()", t3.isArranged())
  t3.removeValue(3)
  t3.print()
  console.info("t3.isArranged()", t3.isArranged())

  t = new PassingLine([0, 1, 2, 6, 7], kLength)
  rmValue = 2
  console.info(``)
  console.info(`input ${t} removeValue ${rmValue}`)
  t.removeValue(rmValue)
  console.info(`removed ${rmValue} => ${t}`)
  console.info("t.isArranged()", t.isArranged())
}
*/

const testRemovePoint = () => {
  const _rmTest = (length, values, rmPoint, result) => {
    let t = new PassingLine(values, length)
    console.info(``)
    console.info(`input ${t} removePoint ${rmPoint}`)
    if (!t.isArranged()[0]) {
      console.info("~~~~~~~~~ isArranged()", t.isArranged())
    }
    t.removePoint(rmPoint)
    console.info(`removed ${rmPoint} => ${t}`)
    if (!t.isArranged()[0]) {
      console.info("~~~~~~~~~ isArranged()", t.isArranged())
    }
    if (!_.isEqual(result, t.getValues())) {
      throw new Error(`~~~~~~~~~ expected value ${result} != ${t.getValues()}`)
    }
  }

  const kLength = 6
  _rmTest(kLength, [1, 2, 3], 0, [0, 1, 2])
  _rmTest(kLength, [1, 2, 3], 1, [0, 1, 2])
  _rmTest(kLength, [1, 2, 3], 2, [1, 2])
  _rmTest(kLength, [1, 2, 3], 3, [1, 2])
  _rmTest(kLength, [1, 2, 3], 4, [1, 2, 3])
  _rmTest(kLength, [1, 2, 3], 5, [1, 2, 3])

  _rmTest(kLength, [0, 1, 2], 5, [0, 1, 2])
  _rmTest(kLength, [0, 1, 2], 0, [4, 0, 1])
  _rmTest(kLength, [0, 1, 2], 1, [0, 1])
  _rmTest(kLength, [0, 1, 2], 2, [0, 1])
  _rmTest(kLength, [0, 1, 2], 3, [0, 1, 2])

  _rmTest(kLength, [5, 0, 1], 0, [4, 0])
  _rmTest(kLength, [5, 0, 1], 1, [4, 0])
  _rmTest(kLength, [5, 0, 1], 2, [4, 0, 1])
  _rmTest(kLength, [5, 0, 1], 3, [4, 0, 1])
  _rmTest(kLength, [5, 0, 1], 5, [4, 0, 1])

  _rmTest(kLength, [4, 5, 0], 3, [3, 4, 0])
  _rmTest(kLength, [4, 5, 0], 4, [3, 4, 0])
  _rmTest(kLength, [4, 5, 0], 5, [4, 0])
  _rmTest(kLength, [4, 5, 0], 0, [3, 4])
  _rmTest(kLength, [4, 5, 0], 1, [3, 4, 0])

  _rmTest(kLength, [3, 4, 5], 2, [2, 3, 4])
  _rmTest(kLength, [3, 4, 5], 3, [2, 3, 4])
  _rmTest(kLength, [3, 4, 5], 4, [3, 4])
  _rmTest(kLength, [3, 4, 5], 0, [2, 3, 4])

  _rmTest(kLength, [0], 0, [4])
  _rmTest(kLength, [1], 1, [0])
  _rmTest(kLength, [5], 4, [4])
  _rmTest(kLength, [5], 0, [4])

  _rmTest(kLength, [], 0, [])

  _rmTest(kLength, [0, 1, 2, 3, 4, 5], 0, [0, 1, 2, 3, 4])
}

const testAddPoint = () => {
  const _addTest = (length, values, pt, result) => {
    let t = new PassingLine(values, length)
    console.info(``)
    console.info(`input ${t} addPoint ${pt}`)
    if (!t.isArranged()[0]) {
      console.info("~~~~~~~~~ isArranged()", t.isArranged())
    }
    t.addPoint(pt)
    console.info(`added ${pt} => ${t}`)
    if (!t.isArranged()[0]) {
      console.info("~~~~~~~~~ isArranged()", t.isArranged())
    }
    if (!_.isEqual(result, t.getValues())) {
      throw new Error(`~~~~~~~~~ expected value ${result} != ${t.getValues()}`)
    }
  }

  const kLength = 6
  _addTest(kLength, [1, 2, 3], 1, [1, 2, 3, 4])
  _addTest(kLength, [1, 2, 3], 2, [1, 2, 3, 4])
  _addTest(kLength, [1, 2, 3], 3, [1, 2, 3, 4])
  _addTest(kLength, [1, 2, 3], 4, [1, 2, 3])
  _addTest(kLength, [1, 2, 3], 5, [1, 2, 3])

  _addTest(kLength, [0, 1, 2], 5, [0, 1, 2])
  _addTest(kLength, [0, 1, 2], 0, [0, 1, 2, 3])
  _addTest(kLength, [0, 1, 2], 1, [0, 1, 2, 3])
  _addTest(kLength, [0, 1, 2], 2, [0, 1, 2, 3])
  _addTest(kLength, [0, 1, 2], 3, [0, 1, 2])

  _addTest(kLength, [5, 0, 1], 0, [6, 0, 1, 2])
  _addTest(kLength, [5, 0, 1], 1, [6, 0, 1, 2])
  _addTest(kLength, [5, 0, 1], 2, [6, 0, 1])
  _addTest(kLength, [5, 0, 1], 3, [6, 0, 1])
  _addTest(kLength, [5, 0, 1], 5, [5, 6, 0, 1])

  _addTest(kLength, [4, 5, 0], 3, [5, 6, 0])
  _addTest(kLength, [4, 5, 0], 4, [4, 5, 6, 0])
  _addTest(kLength, [4, 5, 0], 5, [4, 5, 6, 0])
  _addTest(kLength, [4, 5, 0], 0, [5, 6, 0, 1])
  _addTest(kLength, [4, 5, 0], 1, [5, 6, 0])

  _addTest(kLength, [3, 4, 5], 2, [4, 5, 6])
  _addTest(kLength, [3, 4, 5], 3, [3, 4, 5, 6])
  _addTest(kLength, [3, 4, 5], 4, [3, 4, 5, 6])
  _addTest(kLength, [3, 4, 5], 0, [4, 5, 6])

  _addTest(kLength, [0], 0, [0, 1])
  _addTest(kLength, [1], 1, [1, 2])
  _addTest(kLength, [5], 0, [6])

  _addTest(kLength, [], 0, [])

  _addTest(kLength, [0, 1, 2, 3, 4, 5], 0, [0, 1, 2, 3, 4, 5, 6])
}

if (require.main === module) {
  //testRemoveValue()
  testRemovePoint()
  testAddPoint()
}
