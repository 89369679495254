/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("directionConfig", () => ({
    templateUrl: "components/direction-config/direction-config.html",
    controller: "directionConfigCtrl",

    scope: {
      isOpen: "=",
      selectedItem: "=",
    },
  }))
