/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

// import { BrowserTokenModel } from "common-library/token-model"

angular
  .module("uCountitUiApp")
  .controller(
    "LoginCtrl",
    function (
      $rootScope,
      $scope,
      $location,
      $window,
      $cookieStore,
      Auth,
      BrandSrv,
      $uibModal,
      VersionSrv,
      ApiSrv,
      Locale,
      $stateParams
    ) {
      $scope.account = {}
      $scope.submitted = false
      $scope.loginError = {
        title: "Login failed",
        contents: null,
        closeAlert() {
          return (this.contents = null)
        },
      }

      const loginSuccess = () =>
        Auth.flushCurrentAccount().then(function (account) {
          if ($stateParams.returnUrl) {
            return window.location.replace($stateParams.returnUrl)
          } else {
            return $location.path("/")
          }
        })

      const hasEmail = function () {
        const eleEmail = angular.element("input[type=email]")
        $scope.account.email = $scope.account.email || eleEmail.val()
        return eleEmail.val() != null
      }

      const hasPassword = function () {
        const elePass = angular.element("input[type=password]")
        $scope.account.password = $scope.account.password || elePass.val()
        return elePass.val() != null
      }

      $scope.login = function (form) {
        $scope.submitted = true

        if (form.$valid || (hasEmail() && hasPassword())) {
          // Logged in, redirect to home
          return Auth.login({
            email: $scope.account.email,
            password: $scope.account.password,
          })
            .then(() => loginSuccess())
            .catch(function (err) {
              $scope.submitted = false
              $scope.loginError.contents = "Invalid EMAIL or PASSWORD."
              if (err) {
                console.info(err)
                if (err === 403) {
                  return ($scope.loginError.contents = "This account is not actived")
                } else {
                  return ($scope.loginError.contents = "Internal server error occurred.")
                }
              }
            })
        }
      }

      $scope.loginOauth = (provider) => ($window.location.href = "/auth/" + provider)

      $scope.forgotPassword = () =>
        $uibModal.open({
          animation: false,
          templateUrl: "app/account/recovery/forgot.html",
          controller: "ForgotCtrl",
          size: "sm",
          backdrop: "static",
          windowClass: "modal-window",
        })

      const setNotification = function (active, text) {
        const notiContainer = document.getElementById("notification-container")
        if (notiContainer && active) {
          return (notiContainer.innerHTML = text)
        }
      }

      BrandSrv.setCustomSettingsbyUrl(function (err, brand) {
        $scope.brand = brand
        if (_.isEqual($scope.brand, BrandSrv.getDefaultBrand())) {
          return ApiSrv.ServerGlobalConfig("notification/signin").then(
            (res) => setNotification(res.active, res.value),
            (err) => console.error(err.data)
          )
        } else {
          return setNotification(
            $scope.brand.signinNotification.active,
            $scope.brand.signinNotification.text
          )
        }
      })

      VersionSrv.getVersions().then(function (versions) {
        if (versions) {
          return ($scope.deployVersion = versions["uCountIt"].version)
        }
      })

      // Redirect to main page if already logged
      return loginSuccess().catch(function () {})
    }
  )
