"use static"

import Promise from "bluebird"
import angular from "angular"
import _ from "lodash"
import moment from "moment"
import { Buffer } from "buffer"

const RestfulApiListCtrl = (
  $scope,
  $http,
  $uibModal,
  ApiSrv,
  Auth,
  RestApi,
  Locale,
  NgTableParams
) => {
  const fetchRestfulApiKeys = () => {
    return Promise.all([
      ApiSrv.getAllAgency(),
      RestApi.Company.index(),
      RestApi.Store.index(),
      $http.get(`/api/1.0/restfulapi`).then((res) => res.data),
    ])
      .spread((agencies, companies, stores, data) => {
        return data
          .map((d) => {
            const key = JSON.parse(Buffer.from(d.key.split(".")[1], "base64").toString("utf8"))
            const iatHumanize = moment(key.iat * 1000).from(moment.now())

            let company, agency, displayName
            if (d.orgType == "company") {
              company = companies.find((c) => c._id == d.organization)
              if (!company) return null
              agency = agencies.find((a) => a._id == company._agencyId)
              displayName = `${agency ? agency.name : "No Agency"} > ${company.name}`
            } else if (d.orgType == "agency") {
              agency = agencies.find((a) => a._id == d.organization)
              if (!agency) return null
              displayName = agency.name
            } else return Promise.reject(new Error("Invalid restfulapi key"))

            return {
              ...d,
              ...key,
              displayName,
              iatHumanize,
              company,
              agency,
            }
          })
          .filter((d) => d)
      })
      .catch((err) => {
        console.error(err.toString())
        return Promise.reject(err)
      })
  }

  const getData = (params) => {
    return fetchRestfulApiKeys().then((data) => {
      const sorting = params.sorting()
      const keys = Object.keys(sorting)

      let sortBy, orderBy
      if (keys && keys.length > 0) {
        sortBy = keys[0]
        orderBy = sorting[sortBy]
      }
      if (sortBy && orderBy) data = _.orderBy(data, sortBy, orderBy)

      return data
    })
  }
  $scope.tableParam = new NgTableParams({ count: 25 }, { counts: [10, 25, 50, 100], getData })
  $scope.reload = (cache = true) => {
    $scope.tableParam.reload()
  }
  $scope.delete = (row) => {
    const answer = confirm(`Delete key for ${row.displayName}(${row.memo}).\n Are you sure?`)
    if (answer)
      return $http.delete(`/api/1.0/restfulapi/${row._id}`).then(() => $scope.reload(false))
    else return Promise.resolve()
  }
  $scope.copyToClipboard = (row) => {
    const keyElement = document.getElementById(`input-${row.key}`)
    keyElement.setAttribute("type", "text")
    keyElement.select()
    document.execCommand("copy")
    keyElement.setAttribute("type", "hidden")
    $scope.alerts.push({
      type: "success",
      msg: "Copied",
    })
    const length = $scope.alerts.length - 1
    setTimeout(() => $scope.closeAlert(length), 3000)
  }

  $scope.alerts = []
  $scope.closeAlert = (index) => {
    if ($scope.alerts.length > index) $scope.alerts.splice(index, 1)
  }
}

angular.module("uCountitUiApp").controller("RestfulApiListCtrl", RestfulApiListCtrl)
