/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import Promise from "bluebird"
import _ from "lodash"
import angular from "angular"
import async from "async"
import moment from "moment-timezone"

import { getSnapshotUrl } from "../../../components/snapshot"
import {
  kStandardLabels,
  createDefaultFootfall,
  getStandardLabel,
} from "../../../components/counter-label"
import { getFootfallFeature } from "../../../components/util/footfall"
import env from "../../../../config/environment"
import { IPNDependencies, IPMDependencies, VCAServerDependencies } from "./FeatureDependency"
import {
  getDeviceLicenseStatus,
  isRegistrableLicenseStatus,
  isVerifiedLicenseStatus,
} from "../../../lib/LicenseStatus"
import { parseFeaturesTwoLine, featuresTooltip } from "../../../components/parseFeatures"

angular
  .module("uCountitUiApp")

  .controller(
    "CameraCtrl",
    function (
      $rootScope,
      $scope,
      $filter,
      $window,
      $element,
      $anchorScroll,
      $timeout,
      $http,
      Auth,
      CameraAdminSrv,
      FaceDeviceSrv,
      SeedCounter,
      CameraConfigSrv,
      ApiSrv,
      NotificationSrv,
      IotAdminSrv,
      Locale,
      ngDialog,
      NgTableParams,
      Upload,
      HeaderSrv,
      me,
      BrandSrv,
      ServiceTokenSrv,
      Heatmaptag,
      CameraDeleteSrv,
      usSpinnerService
    ) {
      $scope.isLocalgrey = env.isLocalgrey
      $scope.numberPattern = /^([1-9][0-9]{0,4})$|^100000$/
      $scope.floatPattern = /^[0-9]+(\.[0-9])?$/
      $scope.integerPattern = /^(0|-?[1-9][0-9]*)$/
      $scope.lineDirectionList = [
        { key: "right", name: Locale.string("Right") },
        { key: "left", name: Locale.string("Left") },
      ]
      $scope.isRegistrableLicenseStatus = isRegistrableLicenseStatus
      $scope.isVerifiedLicenseStatus = isVerifiedLicenseStatus
      $scope.strs = {
        config: Locale.string("Configuration"),
        virtualSensor: Locale.string("Virtual Sensor"),
        countingLine: Locale.string("Counting Lines"),
        heatmapTag: Locale.string("Zone Traffic"),
        trackingZone: Locale.string("Tracking Area"),
        countingArea: Locale.string("Counting Area"),
        directionMap: Locale.string("Flow Map"),
        cameraMemo: Locale.string("Camera Memo"),
        installLicense: Locale.string("Install License"),
        uninstallLicense: Locale.string("Uninstall License"),
        deleteData: Locale.string("Delete DB Data"),
        deleteSensor: Locale.string("Delete Sensor"),
        addStore: Locale.string("Add to Store"),
        servicePeriodTooltip: Locale.string("tooltip_service_period"),
        serviceStatusTooltip: Locale.string("tooltip_service_status"),
        featuresTooltip: featuresTooltip,
        timezoneTooltip: Locale.string("tooltip_timezone"),
        notRegistrableSensor: Locale.string("This sensor can not register. checkout status"),
        floatingStatusOk: Locale.string("msg_floating_status_ok"),
        floatingStatusUnverifiedLicense: Locale.string("msg_floating_status_unverified_license"),
        floatingStatusNoLicense: Locale.string("msg_floating_status_no_license"),
      }

      const kDEFALUT_TRACKANALYSIS = {
        cameraInfo: {
          calibrated: false,
          altitude: 2.7,
          // pan: 0
          // tilt: 90
          minFootRatio: 20,
          maxFootRatio: 60,
          objPosRatio: 50,
        },
        dwellConfig: {
          staffRemoval: 600,
          maxHovering: 0.3,
          minDwellTime: 2,
          maxDwellTime: 180,
          minRoiTime: 3,
        },
      }

      $scope.networkConnectionTypes = ["WiFi", "LTE", "PoE", "-"]

      if (me.isStore()) {
        return
      }

      $scope.itemName = Locale.string("Sensor")
      $scope.storePlaceholder = Locale.string("choose a store")
      $scope.counterSyncTooltip = Locale.string("counter_sync_tooltip")
      $scope.linkedSensorTooltip = Locale.string("msg_vff_linked_sensor")
      $scope.FootfallFeaturesTooltip = {
        mFF: Locale.string("tooltip_mff"),
        tFF: Locale.string("tooltip_tff"),
        fFF: Locale.string("tooltip_fff"),
      }
      $scope.inputDlgTitle = "Create"
      $scope.selectedLabels = []
      $scope.selectedStore = {}
      $scope.rgbPattern = /rgb\(\d+,\d+,\d+\)/
      let original = null

      $scope.isOpenUnlinkedCameraListDlg = true
      $scope.floatingCameras = []
      $scope.blacklist = []

      $scope.standardLabels = kStandardLabels
      $scope.hmtagSrv = null

      $scope.brandNav = BrandSrv.getBrand().navMenu
      $scope.brandFunctionalityFZHD = () =>
        $scope.brandNav.footfall ||
        $scope.brandNav.zonetraffic ||
        $scope.brandNav.heatmap ||
        $scope.brandNav.directionmap

      $scope.removedFootfalls = []
      $scope.footfalls = []
      $scope.periods = []
      $scope.onFaceChange = (face) => {
        if ($scope.form?.functions.use.face) {
          $scope.faceForm = {
            ...$scope.faceForm,
            ...face,
          }
        }
      }
      $scope.onVcaConfigChange = (vcaConfig) => {
        $scope.form.vcaConfig = vcaConfig
      }
      $scope.onGroupFaceChange = (gp) => {
        if ($scope.form?.functions.use.groupFace) {
          $scope.form.staffExclusion = {
            ...$scope.form?.staffExclusion,
            ...gp,
          }
        }
      }
      $scope.snapshotFormCfg = {}
      $scope.onSnapshotChange = (snapshot) => {
        if ($scope.form) {
          $scope.form.snapshot = {
            ...$scope.form.snapshot,
            ...snapshot,
          }
        }
      }

      $scope.openDlg = function (dlg, v) {
        const items = {
          input: "isOpenInputDlg",
          vs: "isOpenVSDlg",
          contentscleaner: "isOpenContentsCleanerDlg",
          heatmaptag: "isOpenHeatmapTagConfigDlg",
          trackingzone: "isOpenTrackingZoneConfigDlg",
          countingarea: "isOpenCountingAreaConfigDlg",
          direction: "isOpenDirectionConfigDlg",
          panorama: "isOpenPanoramaConfigDlg",
        }
        for (var key in items) {
          var value = items[key]
          $scope[value] = key === dlg ? v : false
        }

        if (v) {
          $timeout(() => $anchorScroll())
        }
      }

      $scope.openDlg("all", false)

      $scope.showIconMenu = function (item, menu) {
        const fns = item.functions.use
        const showFlag =
          menu === "trackingzone"
            ? fns.zonetrace || fns.zonetraffic || fns.heatmaps
            : menu === "panoramazone"
            ? fns.flowmap
            : menu === "countingarea"
            ? fns.counter && fns.trackcounter
            : fns[menu] != null
            ? fns[menu]
            : false

        return !$scope.nowLoading && showFlag
      }

      const _getCameraUsn = (accessKey) => _.last(accessKey.split("-"))

      const defaultCameraSchema = function (item) {
        let funcs
        if (item) {
          funcs = item.deviceFunctionalities
        } else {
          funcs = {}
        }

        return {
          _storeId: "",
          name: "",
          ip: "",
          port: 80,
          macAddr: "",
          model: "",
          accessKey: "",
          id: "",
          passwd: "",
          thumbnail: {
            useSnapshot: true,
            path: "camera01.gif",
          },
          functions: {
            use: {
              counter: funcs.counter,
              trackcounter: funcs?.counter && funcs?.meta,
              metacounter: false,
              facecounter: false,
              heatmaps: false, // merge traffic heatmap and dwell heatmap(UCNT-3507)
              heatmap: false,
              trafficmap: (funcs != null ? funcs.meta : undefined) && true, // enable trafficmap as default
              dwellmap: false,
              directionmap: false,
              face: false,
              rtFace: false,
              queue: false,
            },
          },
          active: true,
          snapshot: null,
          snapshotBIA: true,
          livePeriod: 0,
          editAccessKey: false,
          trackAnalysis: null,
          face: null,
          footfall: null,
          deviceFunctionalities: {},
          abnormal: {
            active: true,
          },
          dataMon: {
            active: true,
          },
          availableGroupCount: false,
        }
      }

      $scope.$watch("showSelectedOrganization", (_value) => $scope.reload())

      const isChangedCounters = function () {
        const pick = ["active", "_labelId", "name", "bGroupCount", "groupCount", "faceWeight"]

        for (let idx = 0; idx < $scope.orgFootfalls.length; idx++) {
          var footfall = $scope.orgFootfalls[idx]
          if (!$scope.footfalls[idx]) {
            return true
          }

          var object = footfall.counters.map((c) => _.pick(c, pick))
          var other = $scope.footfalls[idx].counters.map((c) => _.pick(c, pick))
          if (!angular.equals(object, other)) {
            return true
          }
        }

        return false
      }

      const isChangedFootfallConfig = () =>
        !$scope.form.functions.use.trackcounter &&
        (isChangedCounters() || !angular.equals($scope.periods, $scope.orgPeriods))

      $scope.canSubmit = function (formName) {
        if (
          !$scope.isOpenInputDlg ||
          !$scope.form ||
          ($scope.selectedStore != null ? $scope.selectedStore._id : undefined) == null
        ) {
          return false
        }
        if (!hasFeatures()) {
          return false
        }

        const dst = Object.assign({}, $scope.form)
        if (original != null) {
          delete original.editAccessKey
        }
        delete dst.editAccessKey
        if (original != null) {
          delete original.footfall
        }
        delete dst.footfall

        return (
          $scope[formName].$valid &&
          !$scope.nowLoading &&
          (!angular.equals(dst, original) || isChangedFootfallConfig() || $scope.faceForm?.$dirty)
        )
      }

      $scope.setLoadingFlag = (flag) => ($scope.nowLoading = flag)

      $scope.submitForm = function () {
        $scope.setLoadingFlag(true)
        const dbColorPattern = /\d+,\d+,\d+/
        if ($scope.form.footfall.counters != null) {
          $scope.form.footfall.counters.forEach(function (counter) {
            counter.color = dbColorPattern.exec(counter.rgbcolor)
            if (counter.color) {
              counter.color = counter.color[0]
            }
          })
        }
        if ($scope.inputDlgTitle === "Create") {
          return createItem($scope.form)
        } else {
          return updateItem($scope.form)
        }
      }

      var createItem = async function (item) {
        try {
          const sobj = await CameraAdminSrv.save(item).$promise
          Auth.clientInfo(
            "admin.stores.lastSelectedStoreId",
            $scope.selectedStore != null ? $scope.selectedStore._id : undefined
          )
          item._id = sobj._id
          return updateItem(item, function (err) {
            console.warn(err)
          })
        } catch (error) {
          if (error.status === 409) {
            $scope.errorMessage = Locale.string("msg_error_accesskey_duplicated")
          } else {
            $scope.errorMessage = Locale.string("msg_error_create")
          }
          return $scope.setLoadingFlag(false)
        }
      }

      const _uploadImage = function (uploadUrl, imagePath, mode, callback) {
        if (imagePath && imagePath.type.indexOf("image/") > -1) {
          return Upload.upload({
            url: uploadUrl,
            data: {
              file: imagePath,
              mode,
            },
          }).then(
            (resp) => callback(null, resp.data),
            (resp) => callback({ message: `Fail to upload image(httpcode: ${resp.status})` })
          )
        } else {
          return callback()
        }
      }

      const _isTrackAnalysisChanged = (original, item) =>
        (item != null ? item.trackAnalysis : undefined) &&
        !(
          angular.equals(item.trackAnalysis.cameraInfo, original.trackAnalysis.cameraInfo) &&
          angular.equals(item.trackAnalysis.dwellConfig, original.trackAnalysis.dwellConfig)
        )

      const isFootfallCfgChanged = function (original, item) {
        if (
          (original?.functions?.use?.counter || item?.functions?.use?.counter) &&
          item.floatingSensor
        ) {
          return true
        }
        if (original?.functions?.use?.counter != item?.functions?.use?.counter) {
          return true
        }

        return isChangedFootfallConfig()
      }

      const isEmptyHeatmapTags = function () {
        if (_.isNull($scope.form.heatmapTags)) {
          return true
        }
        return _.every($scope.form.heatmapTags, (n) =>
          _.every(n.heatmaptags, (t) => _.isEmpty(t.points))
        )
      }

      var updateFootfallSourceType = function (item, type) {
        const list = [
          ["metacounter", "VCA"],
          ["trackcounter", "CA"],
          ["facecounter", "FC"],
        ]
        if (type != null && list.find((d) => d[0] === type)) {
          // INFO: remove orginal counter feature to write footfall config by force
          delete original?.functions?.use?.counter
        }

        for (let i = 0; i < list.length; i++) {
          const [feature, sourceType] = list[i]
          // 기존처럼 모든 footfalls를 변경하는게 맞는건지, 현재 periodic의 footfall만을 변경하는 맞는건지 애마하다.
          // 일단 모든 periodic의 footfall을 변경한다. 현재만 수정하려면 feature도 periodic을 지원해야한다.
          if (item.functions?.use?.[feature]) {
            $scope.footfalls.forEach((footfall) => {
              footfall.counters.forEach((counter, j) => {
                counter.sourceType = sourceType
                // facecounter는 첫번째 카운터만 사용한다.
                if (feature == "facecounter" && j) counter.active = false
              })
            })
          }
        }
      }

      var updateItem = function (item, callback) {
        if (callback == null) {
          callback = function () {}
        }
        return async.parallel(
          [
            function (next) {
              if (!item._id) {
                return next({ message: "no cameraId" })
              }
              if ($scope.form.snapshot?.fixed?.file) {
                const fixedSnapshotUploadUrl = `${$rootScope.globalConfig.apipath}/camera/${item._id}/fixedSnapshot`
                return _uploadImage(
                  fixedSnapshotUploadUrl,
                  $scope.form.snapshot.fixed.file,
                  null,
                  function (err, data) {
                    if (err) {
                      return next(err)
                    }
                    item.snapshot.fixed.url = data.fixed.url
                    return next(null, data)
                  }
                )
              } else {
                next()
              }
            },
            function (next) {
              if (!isFootfallCfgChanged(original, item)) {
                return next()
              }
              updateFootfallSourceType(item)

              const footfalls = $scope.footfalls.map((ff) => ({
                ...ff,
                _cameraId: item._id,
                from: moment.utc(ff.from),
                to: moment.utc(ff.to),
              }))

              footfalls.forEach((ff) => updateGroupCount(ff))

              return ApiSrv.FootfallConfigOfCamera()
                .set({ id: item._id }, footfalls)
                .$promise.then(() => next())
                .catch((err) => next(err))
            },
            function (next) {
              if ($scope.faceForm?.$dirty) {
                return FaceDeviceSrv.set(
                  { id: item._id },
                  { face: $scope.faceForm },
                  (res) => next(null, res),
                  (error) => next(error)
                )
              } else {
                next()
              }
            },
            function (next) {
              if (!item.floatingSensor && !_isTrackAnalysisChanged(original, item)) {
                return next()
              }
              if (item.trackAnalysis.lasttracktime == null) {
                item.trackAnalysis.lasttracktime = moment()
                  .utcOffset(item.offset != null ? item.offset : 0)
                  .startOf("day")
                  .format()
              }
              const clonedTrackAnalysis = _.cloneDeep(item.trackAnalysis)
              if (item.floatingSensor) {
                clonedTrackAnalysis.dwellConfig.tsDwell =
                  ($scope.selectedCompany.dwellmap != null
                    ? $scope.selectedCompany.dwellmap.timeSegments
                    : undefined) != null
                    ? $scope.selectedCompany.dwellmap != null
                      ? $scope.selectedCompany.dwellmap.timeSegments
                      : undefined
                    : [10, 30, 60, 9999]
                clonedTrackAnalysis.dwellConfig.tsRoi =
                  ($scope.selectedCompany.zoneTraffic != null
                    ? $scope.selectedCompany.zoneTraffic.timeSegments
                    : undefined) != null
                    ? $scope.selectedCompany.zoneTraffic != null
                      ? $scope.selectedCompany.zoneTraffic.timeSegments
                      : undefined
                    : [30, 60, 120, 180, 300, 9999]
              }
              if (!clonedTrackAnalysis.usn && item.accessKey) {
                clonedTrackAnalysis.usn = _getCameraUsn(item.accessKey)
              }
              return CameraConfigSrv.set(
                { id: item._id, item: "trackAnalysis" },
                clonedTrackAnalysis,
                (_res) => next(),
                (error) => next(error)
              )
            },
            function (next) {
              if (item.functions.use.zonetraffic === original.functions.use.zonetraffic) {
                return next()
              }
              if (!item.functions.use.zonetraffic) {
                return next()
              }
              if (!isEmptyHeatmapTags()) {
                return next()
              }

              if (!$scope.hmtagSrv) {
                $scope.hmtagSrv = new Heatmaptag(item._id)
              }

              const tags = [$scope.hmtagSrv.getHexagonTag()]
              tags[0].active = false // UCNT-3658, Default Off On Adding ZoneTraffic Feature
              const hmtag = [
                {
                  _cameraId: item._id,
                  heatmaptags: tags,
                },
              ]
              return $scope.hmtagSrv.setTags(hmtag).then(
                (_res) => next(),
                (error) => next(error)
              )
            },
            function (next) {
              CameraConfigSrv.set(
                { id: item._id, item: "vcaconfig" },
                item.vcaConfig,
                (_res) => next(),
                (error) => next(error)
              )
            },
          ],
          function (err, _results) {
            if (err) {
              console.warn("updateItem End error", err)
            }
            if (item.serviceEndDate) {
              item.serviceEndDate = moment
                .tz(item.serviceEndDate, item.store != null ? item.store.timezone : undefined)
                .endOf("day")
                .toDate()
            }
            if (item.accessKey && item.accessKey !== item.serialNum) {
              item.serialNum = item.accessKey
            }
            return CameraAdminSrv.update(
              { id: item._id },
              item,
              function (_sobj, _res) {
                $scope.openDlg("input", false)
                callback()
                $timeout(() => $scope.reload(false, () => HeaderSrv.emitter.reload()), 2000)
                if (me.gteAgency() && (!item.accessKey || item.editAccessKey)) {
                  //INFO: the iot client receviced reload command takes some time to be online.
                  $timeout(() => $scope.loadFloatingCameras(false), 10000)
                }
              },

              function (error) {
                callback(error)
                if (error.status === 409) {
                  $scope.errorMessage = Locale.string("msg_error_accesskey_duplicated")
                } else {
                  $scope.errorMessage = Locale.string("msg_error_update")
                }
                return $scope.setLoadingFlag(false)
              }
            )
          }
        )
      }

      const initializeSpecialCounter = (store) =>
        $scope.standardLabels.forEach(
          (label, idx) =>
            (label.color =
              __guard__(store != null ? store.specialLabels[idx] : undefined, (x) => x.color) !=
              null
                ? __guard__(store != null ? store.specialLabels[idx] : undefined, (x) => x.color)
                : kStandardLabels[idx].color)
        )

      const getDefaultStore = function () {
        if (($scope.stores != null ? $scope.stores.length : undefined) === 1) {
          return $scope.stores[0]
        } else {
          const store = _.find($scope.stores, {
            _id: Auth.clientInfo("admin.stores.lastSelectedStoreId"),
          })
          return store != null ? store : $scope.stores[0]
        }
      }

      const loadForm = function (item) {
        let store
        if (item != null ? item.uid : undefined) {
          // create sensor from floating sensor
          $scope.inputDlgTitle = "Create"
          $scope.form = defaultCameraSchema(item)
          $scope.form.floatingSensor = true
          _.merge(
            $scope.form,
            _.pick(item, [
              "online",
              "accessKey",
              "ip",
              "fwversion",
              "deviceFunctionalities",
              "dtype",
            ])
          )
        } else {
          // load sensor
          $scope.inputDlgTitle = "Update"
          if (item.livePeriod == null) {
            item.livePeriod = defaultCameraSchema().livePeriod
          }
          item.availableGroupCount =
            item.functions.use.counter &&
            (item.functions.use.metacounter || item.functions.use.trackcounter)
          delete item.createdAt
          $scope.form = _.cloneDeep(item)
          $scope.form.floatingSensor = false
          updateTrackEnabled(item.functions.use)
        }

        $scope.formDlg.$setDirty()
        $scope.errorMessage = ""
        $scope.warningMessage = ""
        original = null

        if (item._storeId) {
          store = _.find($scope.stores, { _id: item._storeId })
        } else {
          store = getDefaultStore()
        }

        const device = getDeviceByAccessKey(item.accessKey)
        let promiseChain = Promise.resolve()
        if (item._id) {
          promiseChain = $http.get(`/api/camera/${item._id}/vcaconfig`).then((res) => {
            if (res.data) {
              $scope.form.vcaConfig = res.data
            }
          })
        } else {
          promiseChain = $http
            .get(
              `/api/camera/vcaconfig/defaults?dtype=${device.description.properties.device.dtype}`
            )
            .then((res) => {
              if (res.data) {
                $scope.form.vcaConfig = res.data
              }
            })
        }

        updateVcaConfigForm($scope.form)

        return promiseChain.then(() => {
          return $scope.changeStore(store).then(() => {
            original = angular.copy($scope.form)
          })
        })
      }

      $scope.changeStore = function (store) {
        $scope.selectedStore = store
        $scope.form.store = store
        $scope.form._storeId = store._id
        $scope.form.storeactive = $scope.selectedStore.active
        $scope.selectedCompany = _.find($scope.companies, { _id: $scope.selectedStore._companyId })
        $scope.selectedAgency = _.find($scope.agencies, { _id: $scope.selectedCompany._agencyId })
        $scope.form._companyId = store._companyId
        $scope.form.companyactive = $scope.selectedCompany.active
        initializeSpecialCounter(store)
        if ($scope.inputDlgTitle == "Create" && $scope.selectedCompany) {
          $scope.form.snapshot = $scope.selectedCompany?.snapshot
        }

        $scope.updateSeedCounterList()
        // 기존에 설정된 seedCounterofGA가 새로 선택한 Store에 존재하지 않을 경우에는 None으로 설정한다.
        if (
          $scope.seedCounterList.find((label) => label.value == $scope.form.seedCounterOfGA) == null
        ) {
          $scope.form.seedCounterOfGA = "None"
        }
        return loadAllFeatureConfigs($scope.form).then(() =>
          Promise.all([
            NotificationSrv.Company.get({
              id: $scope.selectedCompany._id,
              _: Date.now(),
            }).$promise.then(function (nt) {
              if (nt) {
                return ($scope.form.notification = nt)
              } else {
                console.error("BUG: Not found notification for this company")
                return ($scope.form.notification = {
                  abnormal: {
                    active: false,
                    emails: [],
                  },
                  dataMon: {
                    active: false,
                  },
                })
              }
            }),

            getCompanyPolicy($scope.selectedStore._companyId),
            getBusinessHours($scope.selectedStore._id),
          ]).then(() => {
            updateLicenseFunctionalities($scope.form)
            updateVcaConfigForm($scope.form)
          })
        )
      }

      $scope.changeAccessKey = _.debounce((accessKey) => {
        $scope.form.accessKey = accessKey
        setCameraInfo($scope.form)
        // TODO: Update VcaConfig.
        updateLicenseFunctionalities($scope.form)
        updateVcaConfigForm($scope.form)
        $scope.$apply()
      }, 200)

      function updateVcaConfigForm(item) {
        const device = getDeviceByAccessKey(item.accessKey)
        if (device) {
          $scope.vcaConfigFormProps = {
            dependsTrackingEngines: getDependsTrackingEngines(item.functions?.use),
            deviceVca: device.description.properties.vca,
            deviceLicenses: device.licenses,
            noLicenseValidation: device.description.properties.device.dtype != "VCASERVER",
          }
        } else {
          $scope.vcaConfigFormProps = {}
        }
      }

      function updateLicenseFunctionalities(item) {
        if (!item.accessKey && !item.isVirtual) {
          // Deregister된 경우에 Feature를 활성화, 끄기 위함. 모든 Functions를 활성화 한다.
          item.licenseFunctionalities = {
            meta: true,
            face: true,
            edgeFace: true,
            counter: true,
            queue: true,
            occupancy: true,
          }
          return
        }
        const device = getDeviceByAccessKey(item.accessKey)
        if (!device) {
          item.licenseFunctionalities = {}
        } else if ($scope.selectedAgency.permission?.noVcaLicenseInstallation || env.isLocalgrey) {
          item.licenseFunctionalities = getLicenseFunctionalities(device)
        } else {
          // VCA License가 자동 인스톨 될 경우에는 장비의 모듼 functionality를 사용 가능하다.
          item.licenseFunctionalities = device.description?.functions || {}
        }
        //checkoutFeatureByLicense(item)
      }

      // TODO: face, counter에 대한 처리 한 후에 활성화할 것
      // function checkoutFeatureByLicense(item) {
      //   if (!item.accessKey && !item.isVirtual) {
      //     return
      //   }
      //   const device = getDeviceByAccessKey(item.accessKey)
      //   if (!device) {
      //     item.functions.use = {}
      //     return
      //   }
      //   const features = item.functions.use
      //   for (const feature in features) {
      //     if (!$scope.licenseHasFeature(feature)) {
      //       features[feature] = false
      //     }
      //   }
      // }

      function getLicenseFunctionalities(device) {
        const functionalitySet = new Set()
        for (const license of device.licenses) {
          for (const functionality of license.functionality) {
            functionalitySet.add(functionality)
          }
        }
        const licenseFunctionalities = {}
        for (const functionality of functionalitySet.values()) {
          licenseFunctionalities[functionality] = true
        }

        return licenseFunctionalities
      }

      $scope.isCompleteList = (list) => list && (list != null ? list.length : undefined) > 1

      $scope.openInputDlg = function (item) {
        $scope.setLoadingFlag(true)
        $scope.isSensorLinked = true
        $scope.faceForm = null
        $scope.openDlg("input", true)
        return loadForm(item)
          .then(async () => {
            $scope.isSensorLinked = await checkSensorLinked(item)
          })
          .then(function () {
            $scope.setLoadingFlag(false)
          })
          .catch(function (err) {
            $scope.setLoadingFlag(false)
            return console.warn("openInputDlg err", err)
          })
      }

      $scope.openSensorDlg = function (item) {
        if ((item != null ? item.model : undefined) === "panorama") {
          return $scope.openPanoramaDlg(item)
        } else {
          return $scope.openInputDlg(item)
        }
      }

      $scope.openVSDlg = function (item) {
        checkStoreLengh()
        $scope.openDlg("vs", true)
        return loadForm(item)
      }

      $scope.canSubmitVS = function (formName) {
        if (!$scope.isOpenVSDlg) {
          return false
        }
        if (($scope.selectedStore != null ? $scope.selectedStore._id : undefined) == null) {
          return false
        }
        $scope.form._storeId = $scope.selectedStore != null ? $scope.selectedStore._id : undefined
        return (
          $scope[formName].$valid &&
          ($scope.selectedStore != null ? $scope.selectedStore._id : undefined) &&
          !$scope.nowLoading &&
          !$scope.errorMessage
        )
      }

      $scope.submitVSForm = function () {
        const opt = {
          id: $scope.form._id,
          item: "virtual",
          storeId: $scope.form._storeId,
        }
        if ($scope.formDlgVS.cameraName.$dirty) {
          opt.cameraName = $scope.form.name
        }

        return CameraConfigSrv.set(
          opt,
          {},
          function (res) {
            $scope.errorMessage = `Success! new accessKey: ${res.accessKey}`
            return $scope.reload(false)
          },
          (err) => ($scope.errorMessage = err)
        )
      }

      var checkStoreLengh = function () {
        if (!($scope.stores != null ? $scope.stores.length : undefined)) {
          $window.alert(Locale.string("store list is empty"))
        }
      }

      $scope.openPanoramaDlg = async function (item) {
        if (item) $scope.panoramaSrc = `/v2/embed/admin/sensors/${item._id}/panorama/edit`
        else {
          let selectedStore = _.find($scope.stores, { _id: HeaderSrv.getStoreId() })
          if (!selectedStore) {
            selectedStore = _.find($scope.stores, { _companyId: HeaderSrv.getCompanyId() })
          }
          $scope.panoramaSrc =
            "/v2/embed/admin/sensors/panorama/new" +
            (selectedStore?._id ? `?headerStoreId=${selectedStore._id}` : "")
        }
        $scope.openDlg("panorama", true)
      }

      $scope.closePanoramaDlg = function () {
        $scope.panoramaSrc = ""
        $scope.openDlg("panorama", false)
      }

      $scope.panoramaMessageHandler = (event) => {
        if (event.data === "PANORAMA_SAVED") {
          $scope.reload(false, () => HeaderSrv.emitter.reload())
          $scope.closePanoramaDlg()
        }
      }

      const reloadSnapshot = function (item) {
        const canvasWidth = 800
        const canvasHeight = 450
        const snapshot = $element.find("#snapshotCanvas")
        let query = `?&_=${new Date().getTime()}`
        const servicetoken = ServiceTokenSrv.getToken()
        if (servicetoken) {
          query = `${query}&service_token=${servicetoken}`
        }
        const snapshotUrl = item.snapshotUrl + query
        snapshot.css({
          background: `url(${snapshotUrl})`,
          "background-size": canvasWidth + "px " + canvasHeight + "px",
          width: canvasWidth,
          height: canvasHeight,
        })
        return true
      }

      $scope.removeItem = function (item) {
        const deleteContent = {
          name: "deleteContent",
          message: Locale.string("msg_confirm_remove_content"),
          checked: true,
        }

        return ngDialog
          .openConfirm({
            template: "components/popup/popup-confirm.html",
            data: {
              header: item.cameraname,
              title: Locale.string("msg_confirm_remove_sensor"),
              option: [deleteContent],
            },
            closeByEscape: true,
            closeByDocument: true,
            showClose: false,
            className: "ngdialog-theme-default popup-confirm",
            controller: "ucPopupConfirmCtrl",
          })
          .then(function (_options) {
            showLoading("delete_camera")
            if (deleteContent.checked) {
              return CameraDeleteSrv.deleteData(item)
            } else {
              return Promise.resolve()
            }
          })
          .then(() => CameraDeleteSrv.deleteCamera(item._id))
          .then(function () {
            $scope.reload(false, () => HeaderSrv.emitter.reload())
            return hideLoading("delete_camera")
          })
      }

      $scope.filteredStores = (keyword) =>
        $filter("filter")($scope.stores, function (item) {
          const str = new RegExp(`.*${keyword}.*`, "i")
          return (
            __guard__(item != null ? item.companyName : undefined, (x) => x.match(str)) != null ||
            __guard__(item != null ? item.name : undefined, (x1) => x1.match(str)) != null
          )
        })

      $scope.reload = function (isCache, callback) {
        if (isCache == null) {
          isCache = true
        }
        if (callback == null) {
          callback = function () {}
        }
        const _reloadStatus = function (status) {
          const v = status === "start"
          $scope.reLoading = v
          return $scope.setLoadingFlag(v)
        }

        if ($scope.reLoading) {
          return
        }
        _reloadStatus("start")

        async.parallel(
          [
            (cback) => {
              ApiSrv.getAllAgency({ isCache: isCache }).then(function (res) {
                $scope.agencies = res
                return cback()
              })
            },

            (cback) =>
              ApiSrv.getAllCompany({ isCache: isCache }).then(function (res) {
                $scope.companies = _.map(res, (element) => ({
                  _id: element._id,
                  name: element.name,
                  active: element.active,
                  blockSnapshot: element.checkBlockedSnapshot(me),
                  face: element.face,
                  snapshot: element.policy?.snapshot,
                  zoneTraffic: element.zoneTraffic,
                  dwellmap: element.dwellmap,
                }))
                return cback()
              }),

            (cback) =>
              ApiSrv.getAllStore({ isCache: isCache }).then(function (res) {
                $scope.stores = _.map(res, (element) => ({
                  _id: element._id,
                  name: element.name,
                  labels: element.labels,
                  specialLabels: element.specialLabels,
                  active: element.active,
                  timezone: element.timezone,
                  _companyId: element._companyId,
                  companyName: __guard__(
                    _.find($scope.companies, { _id: element._companyId }),
                    (x) => x.name
                  ),
                  heatmapWeight: element.heatmapWeight,
                  stats: element.stats,
                }))
                $scope.stores = $filter("orderBy")($scope.stores, [
                  "companyName",
                  "-active",
                  "name",
                ])
                return cback()
              }),

            (cback) =>
              ApiSrv.getAllCamera({ isCache: isCache }).then(function (res) {
                $scope.cameras = res
                  .filter((c) => !(c.isVirtual && c.model === "footfall"))
                  .map((c) => _.cloneDeep(c.data))
                return cback()
              }),

            (cback) =>
              loadIoTDevices(isCache, function (err, devices) {
                setDevices(devices)
                return cback(err)
              }),
          ],
          function (err) {
            if (err) {
              console.warn("$scope.reload err", err)
              _reloadStatus("end")
              callback(err)
              return
            }

            HeaderSrv.fetchCurrentStore()
              .then(function (orgInfo) {
                let cameraList
                $scope.cameras.forEach(function (camera) {
                  camera.store = _.find($scope.stores, { _id: camera._storeId })
                  camera.company = _.find($scope.companies, {
                    _id: camera.store != null ? camera.store._companyId : undefined,
                  })
                  camera.cameraname = `${
                    camera.company != null ? camera.company.name : undefined
                  } > ${camera.store != null ? camera.store.name : undefined} > ${camera.name}`
                  return setCameraInfo(camera)
                })

                if ($scope.showSelectedOrganization) {
                  cameraList = $filter("filter")($scope.cameras, function (camera) {
                    switch (orgInfo.orgType) {
                      case "company":
                        return (
                          orgInfo._id === (camera.company != null ? camera.company._id : undefined)
                        )
                      case "storegroup":
                        return (
                          orgInfo.storeList.indexOf(
                            camera.store != null ? camera.store._id : undefined
                          ) !== -1
                        )
                      case "store":
                        return orgInfo._id === (camera.store != null ? camera.store._id : undefined)
                      default:
                        return false
                    }
                  })
                } else {
                  cameraList = $scope.cameras
                }

                loadItems(cameraList)
                _reloadStatus("end")
                if (me.gteAgency()) {
                  $scope.loadFloatingCameras()
                }
                return callback()
              })
              .catch(function (err) {
                console.warn("reload exception err", err)
                _reloadStatus("end")
                return callback(err)
              })
          }
        )
      }

      $scope.removeLicense = (item) =>
        $timeout(() => IotAdminSrv.removeLicense(item, { actor: me.email }))

      $scope.installLicense = (item) =>
        $timeout(() => IotAdminSrv.installLicense(item, { actor: me.email }))

      var setCameraLicense = function (camera, device) {
        camera.ucountlicense = getDeviceLicenseStatus(device)
      }

      var loadItems = function (items) {
        $scope.Items = items

        const _getData = function ($defer, params) {
          //const filter = $scope.searchKeywords
          const sorting = $scope.row
          const count = params.count()
          //const _page = params.page()
          $scope.pagecount = count

          $scope.filterItems = $filter("filter")($scope.Items, function (val) {
            const str = new RegExp(`.*${$scope.searchKeywords}.*`, "i")

            return (
              __guard__(
                __guard__(val != null ? val.company : undefined, (x1) => x1.name),
                (x) => x.match(str)
              ) != null ||
              __guard__(
                __guard__(val != null ? val.store : undefined, (x3) => x3.name),
                (x2) => x2.match(str)
              ) != null ||
              __guard__(val != null ? val.name : undefined, (x4) => x4.match(str)) != null ||
              __guard__(val != null ? val.accessKey : undefined, (x5) => x5.match(str)) != null
            )
          })

          if (!$scope.filterItems) {
            return
          }
          params.total($scope.filterItems.length)
          const orderItems = $filter("orderBy")($scope.filterItems, sorting)

          const start = (params.page() - 1) * params.count()
          const end = start + params.count()
          $scope.currentPageItems = orderItems.slice(start, end)
          $defer.resolve($scope.currentPageItems)
        }

        return ($scope.tableParams = new NgTableParams(
          { count: $scope.pagecount != null ? $scope.pagecount : 25 },
          { counts: [10, 25, 50, 100], getData: _getData }
        ))
      }

      // filter
      $scope.searchKeywords = ""
      $scope.row = "cameraname"

      $scope.enterKeyword = function (keyword) {
        $scope.searchKeywords = keyword
        return loadItems($scope.Items)
      }

      $scope.order = function (rowName) {
        if ($scope.currentPageItems.length < 1) {
          return
        }
        $scope.row = rowName
        loadItems($scope.Items)
      }

      const loadFloatingItems = function (items) {
        $scope.floatingItems = items

        const _getData = function ($defer, _params) {
          $scope.currentFloatingItems = $filter("filter")($scope.floatingItems, function (val) {
            const str = new RegExp(`.*${$scope.searchFloatingKeywords}.*`, "i")

            return val.frwversion?.match(str) || val.uid?.match(str) || val.ip?.match(str)
          })

          return $defer.resolve($scope.currentFloatingItems)
        }

        return ($scope.floatingTableParams = new NgTableParams(
          {},
          { counts: [], getData: _getData }
        ))
      }

      $scope.searchFloatingKeywords = ""

      $scope.enterFloatingKeyword = function (keyword) {
        $scope.searchFloatingKeywords = keyword
        return loadFloatingItems($scope.floatingItems)
      }

      const setCountingline2Form = function (cls, form) {
        form.footfall.counters.forEach($scope.clearCounter)

        return cls.forEach(function (cl, _idx) {
          if ((cl != null ? cl.source : undefined) == null) {
            return
          }
          const id = parseInt(cl.source.match(/\d+/)[0])

          const counter = form.footfall.counters[id]
          _.merge(counter, cl)
        })
      }

      $scope.loadIoTCounterInfo = (form) =>
        IotAdminSrv.Group("counter").get(
          { uid: form.accessKey, _: Date.now() },
          function (res) {
            setCountingline2Form(res.data, $scope.form)
            return reloadSnapshot(form)
          },
          function (error) {
            if (error.data != null) {
              $scope.errorMessage = error.data
            }
          }
        )

      const addIoTCounterInfo = function (item) {
        const idxes = []

        item.iotCounters.forEach(function (cl, _idx) {
          if ((cl != null ? cl.source : undefined) == null) {
            return
          }
          const id = parseInt(cl.source.match(/\d+/)[0])
          idxes.push(id)
          const fcounter = item.footfall.counters[id]
          if (fcounter) {
            fcounter.source = cl.source
            fcounter.cameraName = cl.name
          }
        })

        return item.footfall.counters.forEach(function (fcounter, idx) {
          if (idxes.indexOf(idx) >= 0) {
            return
          }
          fcounter.displayName = ""
        })
      }

      const isAciveGroupCount = (counter) =>
        !!(
          (counter.groupCount != null ? counter.groupCount.maxTime : undefined) &&
          (counter.groupCount != null ? counter.groupCount.linkTime : undefined)
        )

      var updateGroupCount = (ff) =>
        ff.counters.forEach(function (ct) {
          if (!ct.bGroupCount) {
            if (ct.groupCount) {
              ct.groupCount = { maxTime: 0, linkTime: 0 }
            }
          } else {
            if (
              !(
                (ct.groupCount != null ? ct.groupCount.maxTime : undefined) &&
                (ct.groupCount != null ? ct.groupCount.linkTime : undefined)
              )
            ) {
              ct.groupCount = { maxTime: 30, linkTime: 3 }
            }
          }
        })
      // if bGroupCount and counter.groupCount is active, keep values

      const loadFootfallCfg = function (form) {
        let footfallPromise, iotCountersPromise
        form.footfalltype = getFootfallFeature(form.functions)

        if (!form._id) {
          footfallPromise = Promise.resolve([
            {
              from: "2000-01-01",
              to: "9000-01-01",
              counters: createDefaultFootfall(form.footfalltype).counters,
            },
          ])
        } else {
          footfallPromise = ApiSrv.getFootfallsConfigOfCamera(
            form._id,
            form.store._id,
            { all: true },
            false
          )
        }

        if (form.isVirtual || !form.online || !form.accessKey) {
          iotCountersPromise = Promise.resolve([])
        } else {
          iotCountersPromise = IotAdminSrv.Group("counter")
            .get({ uid: form.accessKey, _: Date.now() })
            .$promise.then((res) => res.data)
            .catch(() => [])
        }

        return Promise.all([footfallPromise, iotCountersPromise])
          .spread(function (footfalls, iotCounters) {
            $scope.footfalls = footfalls.map(function (ff) {
              ff.from = moment.utc(ff.from).format("YYYY-MM-DD")
              ff.to = moment.utc(ff.to).format("YYYY-MM-DD")
              ff.counters.forEach((ct) => (ct.bGroupCount = isAciveGroupCount(ct)))
              return ff
            })

            $scope.periods = $scope.footfalls.map((ff) => _.pick(ff, ["id", "from", "to"]))

            if (!original) {
              $scope.orgPeriods = _.cloneDeep($scope.periods)
              $scope.orgFootfalls = _.cloneDeep($scope.footfalls)
            }

            return (form.iotCounters = iotCounters)
          })
          .catch(function (err) {
            if (form.functions.use.counter) {
              console.error("loadFootfallCfg error", err)
            }
          })
      }

      $scope.selectedPeriod = function (idx, isManual) {
        if (idx < 0) {
          return
        }

        if (isManual) {
          if ($scope.form.footfall != null) {
            $scope.form.footfall.counters.forEach(
              (counter, i) => (counter.label = $scope.selectedLabels[i])
            )
          }
        }

        $scope.selectedPeriodIdx = idx
        $scope.form.footfall = $scope.footfalls[idx]
        $scope.form.footfall.counters.forEach(
          (counter, i) => ($scope.selectedLabels[i] = counter.label)
        )

        addIoTCounterInfo($scope.form)
      }

      $scope.changedPeriod = function (removed) {
        let i
        for (i = removed.length - 1; i >= 0; i--) {
          var idx = $scope.footfalls.findIndex((footfall) => footfall._id === removed[i]._id)
          var removedFootfall = $scope.footfalls.splice(idx, 1)[0]
          if (removedFootfall) {
            $scope.removedFootfalls.push(removedFootfall)
          }
        }

        return (() => {
          let asc, end
          const result = []
          for (
            i = 0, end = $scope.periods.length, asc = 0 <= end;
            asc ? i < end : i > end;
            asc ? i++ : i--
          ) {
            if (!$scope.footfalls[i]) {
              var newFootfall = {
                from: $scope.periods[i].from,
                to: $scope.periods[i].to,
                counters: _.cloneDeep(
                  __guard__($scope.footfalls[i - 1], (x) => x.counters) ||
                    createDefaultFootfall($scope.form.footfalltype).counters
                ),
              }
              newFootfall.counters.forEach((ff) => delete ff._id)
              $scope.footfalls[i] = newFootfall
            }

            $scope.footfalls[i].from = $scope.periods[i].from
            result.push(($scope.footfalls[i].to = $scope.periods[i].to))
          }
          return result
        })()
      }

      const getDefaultFaceCfg = function (isEdge) {
        const defaultCfg = _.cloneDeep($scope.selectedCompany.face)
        if (isEdge) {
          defaultCfg.engine.kind = "edge"
          defaultCfg.engine.edge = {
            age: "edge",
            gender: "edge",
          }
          defaultCfg.quota = 10000
        }

        return defaultCfg
      }

      const loadFaceCfg = function (form) {
        if (!form._id || $scope.isLocalgrey) {
          return Promise.resolve()
        }

        return FaceDeviceSrv.get({ id: form._id })
          .$promise.then(function (res) {
            if ((res != null ? res.engine : undefined) != null) {
              res.retentionPeriod = $scope.selectedCompany.face.retentionPeriod
              return res
            }
          })
          .catch(function (err) {
            if (form.functions.use.face || form.functions.use.rtFace) {
              console.error("loadFaceCfg error", err)
              return {}
            }
          })
      }

      const loadTrackCfg = function (form) {
        const ta = _.merge({}, kDEFALUT_TRACKANALYSIS)
        if (!(form != null ? form._id : undefined)) {
          return Promise.resolve(ta)
        }

        return CameraConfigSrv.get({ id: form._id, item: "trackAnalysis", _: Date.now() })
          .$promise.then(function (res) {
            _.merge(ta.cameraInfo, res != null ? res.cameraInfo : undefined)
            _.merge(ta.dwellConfig, res != null ? res.dwellConfig : undefined)
            return ta
          })
          .catch(function (err) {
            if (form.functions.use.track) {
              console.error("loadTrackCfg error", err)
            }
            return ta
          })
      }

      const loadZoneCfg = function (form) {
        if (!form._id) {
          return Promise.resolve()
        }

        $scope.hmtagSrv = new Heatmaptag(form._id)
        return $scope.hmtagSrv.getTags({ all: true }).catch(function (err) {
          if (form.functions.use.zonetraffic) {
            console.error("loadZoneCfg error", err)
          }
        })
      }

      var loadAllFeatureConfigs = function (form) {
        //INFO always reload footfall info by changed store label
        let facePromise, footfallPromise, trackPromise, zonePromise
        footfallPromise = loadFootfallCfg(form)

        if (!$scope.faceForm) {
          facePromise = loadFaceCfg(form).then((res) => {
            $scope.faceForm = res
          })
        } else {
          facePromise = Promise.resolve()
        }

        if (!form.heatmapTags) {
          zonePromise = loadZoneCfg(form).then((res) => (form.heatmapTags = res))
        } else {
          zonePromise = Promise.resolve()
        }

        if (!form.trackAnalysis) {
          trackPromise = loadTrackCfg(form).then((res) => (form.trackAnalysis = res))
        } else {
          trackPromise = Promise.resolve()
        }

        return Promise.all([footfallPromise, facePromise, zonePromise, trackPromise])
      }

      // const isValidColor = (color) =>
      //   (color != null ? color.match(/\d+,\d+,\d+/) : undefined) != null

      $scope.clearCounter = function (counter) {
        counter.active = false
        counter.name = ""
        counter.displayName = ""
        counter.color = ""
        delete counter.labelpos
        delete counter.labelposString
        delete counter.linepoints
        return delete counter.linepointsString
      }

      $scope.setCounterValue = function (form, counter) {
        $scope.errorMessage = ""
        const countervalues = [{ id: counter.id, value: counter.count }]
        return IotAdminSrv.Group("counter/value").update(
          { uid: form.accessKey },
          countervalues,
          function (res) {
            if (res != null) {
              $scope.errorMessage = JSON.stringify(res)
            }
          },
          function (error) {
            if (error.data != null) {
              $scope.errorMessage = JSON.stringify(error.data)
            }
          }
        )
      }

      const getDateTime = function (date) {
        if (date) {
          return new Date(date)
        } else {
          return ""
        }
      }

      $scope.showDate = function (date, tz) {
        if (date) {
          return $filter("date")(date, "yyyy-MM-dd", tz)
        } else {
          return "-"
        }
      }

      $scope.showDatetime = function (date, tz) {
        if (date) {
          return $filter("date")(date, "yyyy-MM-dd HH:mm", tz)
        } else {
          return "-"
        }
      }

      const setServiceStatus = function (item) {
        const now = new Date()
        item.lastActivationDate = getDateTime(item.lastActivationDate)
        item.installationDate = getDateTime(item.installationDate)
        item.serviceBeginDate = getDateTime(item.serviceBeginDate)
        item.serviceEndDate = getDateTime(item.serviceEndDate)

        const A =
          isRegistrableLicenseStatus(item.ucountlicense) &&
          item.active &&
          item.store.active &&
          item.company.active
        const S = !(item.serviceEndDate && now > item.serviceEndDate)

        item.serviceStatusActive = A
        item.serviceStatusService = S
        const ss = (A ? "A" : "D") + "/" + (S ? "S" : "E")
        item.serviceStatusOrder = ["D/S", "A/E", "A/S", "D/E"].indexOf(ss)
        item.serviceStatusWarning = ss === "D/S" || ss === "A/E"
        return (item.serviceStatus = ss)
      }

      const setCameraFeatures = function (item) {
        const [fone, ftwo] = parseFeaturesTwoLine(item.functionalities)
        item.features_one = fone.join("·")
        item.features_two = ftwo.join("·")
        return
      }

      var setCameraInfo = function (item) {
        const device = _.find($scope.devices, { uid: item.accessKey })

        if (!device) {
          item.online = false
        }

        if (item.model === "panorama") {
          item.online = true
          item.deviceFunctionalities = {
            virtual: true,
          }
        }

        if (item.isVirtual) {
          item.ucountlicense = "verified"
        } else {
          setCameraLicense(item, device)
        }

        if (device && device.status && device.description) {
          item.online = device.online
          item.offset = device.description.properties.timezone.offset
          if (device.status.heartbeat != null ? device.status.heartbeat.at : undefined) {
            item.lastaccesstime = device.status.heartbeat.at
          } else {
            console.warn("No heartbeat at: ", device.uid)
          }
          if (device.status.uptime.bootat) {
            item.bootat = device.status.uptime.bootat
          }
          item.fwversion = device.description.properties.firmware.version
          item.deviceFunctionalities = device.description.functions
          item.dtype = device.description.properties.device.dtype
        }

        item.cameraname = `${item.company != null ? item.company.name : undefined} > ${
          item.store != null ? item.store.name : undefined
        } > ${item.name}`
        item.cameratimeoffset = item.offset
        if ((item.store != null ? item.store.timezone : undefined) != null) {
          item.storetimezone = item.store.timezone
          item.storetimeoffset = moment().tz(item.store.timezone).format("Z").replace(":", "")
        }
        if (item.cameratimeoffset != null) {
          item.differOffset = item.storetimeoffset !== item.cameratimeoffset
        }

        item.functionalities = item.functions != null ? item.functions.use : undefined
        if ((item.company != null ? item.company.name : undefined) === "TRASH") {
          item.abnormal.status = false
        }
        setCameraFeatures(item)
        setServiceStatus(item)

        item.snapshotUrl = getSnapshotUrl(item, ServiceTokenSrv, $rootScope.globalConfig)
      }

      var loadIoTDevices = (isCache, callback) =>
        IotAdminSrv.getAllDevice({ isCache: isCache }).then(
          (devices) => callback(null, devices),
          (err) => callback(err, [])
        )

      $scope.loadFloatingCameras = function (isCache) {
        const setFloatingDevices = function (devices) {
          $scope.floatingCameras = []
          const floatinglist = []
          for (const device of devices) {
            const found = _.find($scope.cameras, { accessKey: device.uid })
            if (found) {
              continue
            }
            device.accessKey = device.uid
            device.ip = device.description?.properties?.network?.tcp?.ip4
            device.licenseStatus = getDeviceLicenseStatus(device)
            device.agencies = device.description?.fota?.agencies ?? []
            delete device.rawconfig
            delete device._id

            //TODO: checking agency code UCNT-1297
            if (device.online && !_.find($scope.blacklist, { accessKey: device.accessKey })) {
              floatinglist.push(device)
            }
          }

          floatinglist.forEach(function (item) {
            item.fwversion =
              item.description != null ? item.description.properties.firmware.version : undefined
            item.deviceFunctionalities =
              item.description != null ? item.description.functions : undefined
            item.dtype =
              item.description != null ? item.description.properties.device.dtype : undefined
            item.snapshotUrl = getSnapshotUrl(item, ServiceTokenSrv, $rootScope.globalConfig)
            item.displayStatuses = createFloatingStatuses(item)
            item.isRegistrable = isRegistrableDevice(item)
          })
          $scope.floatingCameras = floatinglist
          return loadFloatingItems(floatinglist)
        }

        return Promise.all([
          IotAdminSrv.getAllDevice({ isCache }).catch((_err) => []),
          IotAdminSrv.getAllFloatingDevice({ isCache }).catch((_err) => []),
          IotAdminSrv.getAllBlacklist({ isCache }),
        ]).spread(function (devices, floatingDevices, blacklist) {
          setDevices(devices)
          $scope.blacklist = blacklist
          return setFloatingDevices(floatingDevices)
        })
      }

      const deviceMap = {}
      function setDevices(devices) {
        $scope.devices = devices
        for (const device of devices) {
          deviceMap[device.uid] = device
        }
      }

      function getDeviceByAccessKey(accessKey) {
        return deviceMap[accessKey]
      }

      const createFloatingStatuses = (item) => {
        let statuses = []

        if (item.statusMessages) {
          for (const statusMessage of item.statusMessages) {
            const status = {
              isWarn: statusMessage.level == "warn",
              isErr: statusMessage.level == "err",
              messages: [],
            }
            statusMessage.level
            for (const message of statusMessage.messages) {
              if (message.kind == "translationKey") {
                status.messages.push(Locale.string(message.data))
              } else {
                status.messages.push(message.data)
              }
            }
            statuses.push(status)
          }
        }

        if (statuses.length == 0) {
          statuses.push({
            messages: ["OK"],
          })
        }

        return statuses
      }

      const isRegistrableDevice = (item) => {
        if (item.statusMessages) {
          return item.statusMessages.find((statusMessage) => statusMessage.level == "err")
            ? false
            : true
        }

        return true
      }

      $scope.registerCamera = function (camera) {
        let update
        if (camera.registered) {
          ;({ update } = IotAdminSrv.Group())
        } else {
          update = IotAdminSrv.Group().remove
        }
        return update({ uid: camera.accessKey }, camera)
      }

      $scope.openCameraMemo = function (camera) {
        window.scrollTo({ top: 0, behavior: "smooth" })
        return ngDialog.open({
          template: "components/cameranote/cameranote.html",
          data: camera,
          closeByEscape: true,
          closeByDocument: true,
          className: "ngdialog-theme-default cameranote",
          controller: "CameraNoteCtrl",
        })
      }

      $scope.changeStoreLabel = function (label, idx, counter) {
        $scope.selectedLabels[idx] = label
        return (counter._labelId = label._id)
      }

      var hasFeatures = function () {
        const fns = $scope.form.functions.use
        const features = [
          "counter",
          "zonetraffic",
          "directionmap",
          "flowmap",
          "face",
          "rtFace",
          "queue",
          "occupancy",
          "heatmaps",
        ]
        for (var f of Array.from(features)) {
          if (fns[f]) {
            return true
          }
        }
        return false
      }

      const createCountersIfMissing = function () {
        let isMissing = false

        $scope.footfalls.forEach(function (footfall) {
          if (footfall.counters.length === 0) {
            footfall.counters = createDefaultFootfall($scope.form.footfalltype).counters
            isMissing = true
          }
        })

        if (isMissing) {
          $scope.selectedPeriod($scope.selectedPeriodIdx, false)
        }
      }

      $scope.deviceHasFeature = (feature) => {
        if ($scope.form?.deviceFunctionalities) {
          return hasFeatureFc($scope.form.deviceFunctionalities, feature)
        } else {
          return false
        }
      }

      $scope.licenseHasFeature = (feature) => {
        if ($scope.form?.licenseFunctionalities) {
          // 라이센스가 기능을 지원하더라도 장비가 지원하지 않으면 안된다.(eg. occupancy는 uCountIt 1ch가 지원하지만 UCS2500CT3D에서만 동작한다.)
          return (
            hasFeatureFc($scope.form.deviceFunctionalities, feature) &&
            hasFeatureFc($scope.form.licenseFunctionalities, feature)
          )
        } else {
          return false
        }
      }

      function featureDepsByDtype(dtype) {
        let featureDeps
        if (dtype == "IPM") {
          featureDeps = IPMDependencies
        } else if (dtype == "IPN") {
          featureDeps = IPNDependencies
        } else {
          featureDeps = VCAServerDependencies
        }

        return featureDeps
      }

      /** HasFeatureFunctionality
       * Feature를 활성화 시키는데 필요한 functionality가 있는지 체크
       * Recursive하게 검색하지는 않는다.(현재로써는 이 방식이 올바르다. Face, EdgeFace를 고려 해보길)
       */
      function hasFeatureFc(functionalities, feature) {
        if (!$scope.form || !functionalities) {
          return true
        }
        const featureDeps = featureDepsByDtype($scope.form.dtype)

        if (featureDeps[feature] != null ? featureDeps[feature].dependsFunctionality : undefined) {
          for (var func of Array.from(featureDeps[feature].dependsFunctionality)) {
            if (functionalities[func]) {
              return true
            }
          }
        }

        return false
      }

      var updateTrackEnabled = (fns) =>
        ($scope.form.trackEnabled =
          fns.heatmaps ||
          fns.zonetraffic ||
          fns.directionmap ||
          fns.metacounter ||
          fns.facecounter ||
          fns.trackcounter)

      /**
       * Feature를 선택하고 선택된 Feature의 목록을 반환한다.
       * $scope.form을 mutate하지 않는다.
       */
      const selectFeature = function (type) {
        let depsSet
        const featureDeps = featureDepsByDtype($scope.form.dtype)
        const fns = { ...$scope.form.functions.use }

        const findFeaturesDependBy = (feature) => {
          return Object.keys(featureDeps).filter((k) =>
            (featureDeps[k].depends ?? []).includes(feature)
          )
        }

        if (featureDeps[type]) {
          const makeCheckIn = fns[type]
          depsSet = new Set()
          depsSet.add(type)
          if (makeCheckIn) {
            let dep, item
            let stk = []
            for (dep of Array.from(featureDeps[type].depends || [])) {
              stk.push(dep)
            }
            stk.push(type)
            while (stk.length > 0) {
              item = stk.pop()
              fns[item] = true
              for (dep of Array.from(featureDeps[item].depends || [])) {
                if (!depsSet.has(dep)) {
                  depsSet.add(dep)
                  stk.push(dep)
                }
              }
            }

            // CheckOut conflicts of deps
            stk = []
            depsSet.forEach((d) =>
              Array.from(featureDeps[d].conflicts || []).map((i) => stk.push(i))
            )
            for (var i of Array.from(featureDeps[type].conflicts || [])) {
              stk.push(i)
            }
            const confSet = new Set()
            while (stk.length > 0) {
              item = stk.pop()
              if (depsSet.has(item)) {
                continue
              }
              fns[item] = false
              // item을 depends하는 feature를 false
              for (const key of findFeaturesDependBy(item)) {
                if (!confSet.has(key) && !depsSet.has(key)) {
                  confSet.add(key)
                  stk.push(key)
                }
              }
            }
          } else if (!makeCheckIn) {
            let depsSet = new Set()
            let stk = [...findFeaturesDependBy(type), type]
            depsSet = new Set()
            for (const f of stk) {
              depsSet.add(f)
            }
            while (stk.length > 0) {
              const target = stk.pop()
              if (!fns[target]) {
                continue
              }
              fns[target] = false

              for (const child of findFeaturesDependBy(target)) {
                if (fns[child]) {
                  stk.push(child)
                  depsSet.add(child)
                }
              }
              for (const child of featureDeps[target].depends ?? []) {
                if (!depsSet.has(child) && fns[child] && !$scope.licenseHasFeature(child)) {
                  stk.push(child)
                  depsSet.add(child)
                }
              }
            }
          }
        }

        return fns
      }

      $scope.selectFeature = function (type) {
        const original = { ...$scope.form.functions.use, [type]: !$scope.form.functions.use[type] }
        const fns = selectFeature(type)
        $scope.form.footfalltype = getFootfallFeature(fns)
        if (fns.counter) {
          createCountersIfMissing()
        }

        updateFootfallSourceType($scope.form, type)

        if (fns["face"] && !original["face"]) {
          if (_.isNil($scope.faceForm)) {
            $scope.faceForm = getDefaultFaceCfg(fns.edgeFace)
          }
        }
        if ($scope.faceForm != null) {
          $scope.faceForm.rtService = fns.rtFace
        }

        if (fns["groupFace"] && !original["groupFace"]) {
          // Set default value
          $scope.form.staffExclusion = null
          $scope.form.optimizeExposure = true
        } else {
          delete $scope.form.staffExclusion
        }

        if (!fns["groupFace"] && original["groupFace"]) {
          $scope.form.optimizeExposure = false
        }

        updateTrackEnabled(fns)

        $scope.vcaConfigFormProps = {
          ...$scope.vcaConfigFormProps,
          dependsTrackingEngines: getDependsTrackingEngines(fns),
        }

        $scope.form.functions.use = fns
      }

      function getDependsTrackingEngines(fns = {}) {
        const featureDeps = featureDepsByDtype($scope.form.dtype)
        let result = []

        for (const fn of Object.keys(fns)) {
          if (fns[fn] && featureDeps[fn]?.dependsTrackingEngines) {
            result = result.concat(featureDeps[fn].dependsTrackingEngines)
          }
        }

        return result
      }

      $scope.isIPMSensor = () => {
        return $scope.form?.dtype === "IPM" ? true : false
      }

      $scope.supportFaceFeature = () => {
        return $scope.deviceHasFeature("face") || $scope.deviceHasFeature("edgeFace")
      }

      $scope.supportFootfallSubFeatures = () => {
        return (
          $scope.deviceHasFeature("metacounter") ||
          $scope.deviceHasFeature("edgeFace") ||
          $scope.deviceHasFeature("trackcounter")
        )
      }

      const LICENSE_STATUS = {
        NO_DIVICE: "no_device",
        SUPPORT: "support",
        NOT_SUPPORT: "not_support",
      }

      const getLicenseStatus = (feature) => {
        if (!$scope.deviceHasFeature(feature)) {
          return LICENSE_STATUS.NO_DIVICE
        } else {
          return $scope.licenseHasFeature(feature)
            ? LICENSE_STATUS.SUPPORT
            : LICENSE_STATUS.NOT_SUPPORT
        }
      }

      const checkNotSupportLicencse = (status) => {
        return status === LICENSE_STATUS.NOT_SUPPORT
      }

      const FEATURES_GROUP1 = [
        { key: "metacounter", i18nKey: "mFF" },
        { key: "edgeFace", i18nKey: "fFF" },
        { key: "trackcounter", i18nKey: "tFF" },
        { key: "zonetraffic", i18nKey: "Zone Traffic" },
        { key: "heatmaps", i18nKey: "Heatmap" },
        { key: "directionmap", i18nKey: "Flow Map" },
      ]

      $scope.showLicenseInfoMsg = (type) => {
        if (!$scope.form?.licenseFunctionalities) return

        let isShowMsg = false

        if (type == "group1") {
          isShowMsg = _.reduce(
            FEATURES_GROUP1,
            (res, feature) => {
              const status = getLicenseStatus(feature.key)
              return res || checkNotSupportLicencse(status)
            },
            false
          )
        } else if (type == "face") {
          const faceLicenseStatus = $scope.isIPMSensor()
            ? getLicenseStatus("edgeFace")
            : getLicenseStatus("face")
          const rtFaceLicenseStatus = getLicenseStatus("rtFace")
          const groupFaceLicenseStatus = getLicenseStatus("groupFace")

          isShowMsg =
            checkNotSupportLicencse(faceLicenseStatus) ||
            checkNotSupportLicencse(rtFaceLicenseStatus) ||
            checkNotSupportLicencse(groupFaceLicenseStatus)
        } else {
          isShowMsg = checkNotSupportLicencse(getLicenseStatus(type))
        }

        return isShowMsg
      }

      $scope.getNotSupportLicenseList = (type) => {
        if (!$scope.form?.licenseFunctionalities) return

        let list = ""
        if (type == "group1") {
          let addComma = false
          _.forEach(FEATURES_GROUP1, (feature) => {
            const status = getLicenseStatus(feature.key)
            if (checkNotSupportLicencse(status)) {
              if (addComma) {
                list += ", "
                addComma = false
              }
              list += Locale.string(feature.i18nKey)
              addComma = true
            }
          })
        } else {
          list = Locale.string(type)
        }

        return list
      }

      $scope.getCompanyNameOfStore = (store) =>
        store.companyName != null ? store.companyName : "-"

      $scope.openContentsCleanerDlg = function (item) {
        $scope.selectedItem = item
        return $scope.openDlg("contentscleaner", true)
      }

      $scope.openHeatmapTagConfigDlg = function (item) {
        $scope.selectedItem = item
        return $scope.openDlg("heatmaptag", true)
      }

      $scope.$on("heatmaptag_config_complete", function () {
        $scope.openDlg("heatmaptag", false)
        return $scope.reload(false)
      })

      $scope.openTrackingZoneConfigDlg = function (item) {
        $scope.selectedItem = item
        return $scope.openDlg("trackingzone", true)
      }

      $scope.$on("trackingzone_config_complete", function () {
        $scope.openDlg("trackingzone", false)
        return $scope.reload(false)
      })

      $scope.openCountingAreaConfigDlg = function (item) {
        $scope.selectedItem = item
        return $scope.openDlg("countingarea", true)
      }

      $scope.$on("countingarea_config_complete", function () {
        $scope.openDlg("countingarea", false)
        return $scope.reload(false)
      })

      $scope.openDirectionConfigDlg = function (item) {
        $scope.selectedItem = item
        return $scope.openDlg("direction", true)
      }

      $scope.$on("direction_config_complete", function () {
        $scope.openDlg("direction", false)
        return $scope.reload()
      })

      $scope.isSpecialCounter = (counter) => getStandardLabel(counter)

      var getCompanyPolicy = function (companyId, isCreate) {
        if (!companyId) {
          return Promise.resolve()
        }

        return ApiSrv.getCompany({ id: companyId, isCache: true }).then(function (res) {
          if (!res) {
            return Promise.resolve()
          }

          if (isCreate) {
            $scope.form.snapshot = res.policy.snpashot
          }

          $scope.snapshotFormCfg.usePrivacyLaw =
            (res.policy.privacyLaw != null ? res.policy.privacyLaw.superAdmin : undefined) ||
            (res.policy.privacyLaw != null ? res.policy.privacyLaw.companyAdmin : undefined)

          return ApiSrv.getAgency({ id: res._agencyId, isCache: true }).then(function (agency) {
            if (!agency) {
              return
            }

            if (agency.policy != null ? agency.policy.noPersonalData : undefined) {
              $scope.snapshotFormCfg.noPersonalData = true
              $scope.form.functions.misc = {
                blackboxImage: {
                  active: false,
                },
              }
            }
            $scope.selectedAgency = agency
          })
        })
      }

      var getBusinessHours = function (storeId) {
        if (!storeId) {
          return Promise.resolve()
        }

        return ApiSrv.getStore({ id: storeId }).then(function (res) {
          if (!res) {
            return Promise.resolve()
          }
          $scope.businessHours = res.schedule?.businessHours
        })
      }

      $scope.getCounterName = function (counter) {
        if (!counter || !counter.name) {
          return ""
        }
        return `[${counter.source}] ${counter.name}`
      }

      $scope.syncCounterConfig = (form) =>
        form.footfall.counters.forEach(function (counter, i) {
          const iotCounter = form.iotCounters[i]
          if (iotCounter) {
            _.merge(
              counter,
              _.pick(iotCounter, [
                "active",
                "source",
                "name",
                "linedirection",
                "lineobjectwidth",
                "linepoints",
              ])
            )
            counter.displayName = `[${counter.source}] ${counter.name}`
          }
        })

      $scope.isSyncAllCounters = function (counters) {
        if (!counters) {
          return false
        }

        const res = _.filter(counters, { sync: false })
        return res.length === 0
      }

      $scope.isSyncCounter = function (form, index) {
        const counter = form.footfall.counters[index]
        if (!form.online) {
          counter.sync = true
        } else {
          const iotCounter = form.iotCounters[index]
          if (counter && iotCounter) {
            counter.sync =
              counter.active &&
              counter.source === iotCounter.source &&
              counter.name === iotCounter.name
          } else {
            counter.sync = counter.active ? false : true
          }
        }

        return counter.sync
      }

      $scope.isValidNumber = (error) => _.isEqual(error, {})

      $scope.selectOperation = function (item, ops, body) {
        if (body == null) {
          body = {}
        }
        if (!$window.confirm("Are you sure?")) {
          return
        }
        $scope.errorMessage = null
        IotAdminSrv.Group("operation").update(
          { uid: item.accessKey, command: ops },
          body,
          function (res) {
            if (res.error != null) {
              return ($scope.errorMessage = res.error)
            }
            // return ($scope.errorMessage = "success command: " + cmd)
            return ($scope.errorMessage = "success command: " + ops)
          },
          function (error) {
            if (error.data != null) {
              $scope.errorMessage = error.data
            }
          }
        )
      }

      $scope.getDisplayLabelName = function (label) {
        const sLabel = _.find(kStandardLabels, ["name", label])
        if (sLabel) {
          return Locale.string(sLabel.i18nKey)
        } else {
          return label
        }
      }

      var showLoading = function (_key) {
        angular.element(".splash").show()
        usSpinnerService.spin("spinner")
        return angular.element(".spinner").css("position", "fixed")
      }

      var hideLoading = function (_key) {
        angular.element(".splash").hide()
        return usSpinnerService.stop("spinner")
      }

      $scope.seedCounterList = []
      $scope.updateSeedCounterList = () => {
        $scope.seedCounterList = SeedCounter.getList($scope.form.store?.labels)
      }

      $scope.forcedSnapshot = async (item) => {
        try {
          const res = await $http.post(`/api/1.0/camera/${item._id}/forced-snapshot`)
          if (res.status != 200) {
            alert(res.data.message)
          }
        } catch (err) {
          alert(err.data?.message || err.message)
        }
      }

      $scope.searchSensorForm = {
        accessKey: "",
      }

      $scope.$watch(
        "searchSensorForm",
        () => {
          $scope.searchSensorResult = null
        },
        true
      )

      $scope.searchSensor = async () => {
        const { accessKey } = $scope.searchSensorForm
        if (!accessKey) return

        try {
          const params = accessKey.includes("-") ? { accessKey } : { endusn: accessKey }
          const res = await $http.get(`/api/1.0/camera/search`, { params })

          if (res.status === 200) {
            $scope.searchSensorResult = res.data
          }
        } catch {
          $scope.searchSensorResult = null
        }
      }

      // UCNT-5046 수정중인 센서가 VFF에 연결된 센서인지 확인
      const checkSensorLinked = async (sensorInfo) => {
        if (!sensorInfo) return false
        const sensors = await ApiSrv.getCameraOfStore({ id: sensorInfo._storeId, isCache: true })
        const vffSensor = sensors.find((sensor) => sensor.model === "footfall")
        if (vffSensor) {
          const footfalls = await ApiSrv.getFootfallsConfigOfCamera(
            vffSensor._id,
            vffSensor._storeId,
            { all: true },
            false
          )
          for (const footfall of footfalls || []) {
            for (const counter of footfall.counters || []) {
              if (counter?.rule?.orgId === sensorInfo._id) return true
            }
          }
        }
        return false
      }

      //#############################################################################
      // Main Code
      $scope.reload()
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
