/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS104: Avoid inline assignments
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"
import async from "async"

angular
  .module("uCountitUiApp")
  .controller(
    "AddSalesCtrl",
    function (
      $rootScope,
      $scope,
      $timeout,
      $interval,
      Upload,
      NgTableParams,
      StoreSalesSrv,
      Locale
    ) {
      const config = $rootScope.globalConfig
      $scope.salesDataTableParams = null
      $scope.errMsg = null
      $scope.progBarOption = {
        progValue: 1,
        progMax: 100,
      }

      $scope.header = {
        date: Locale.string("Date"),
        footfall: Locale.string("Footfall"),
        tr: Locale.string("TR"),
        trFull: Locale.string("Transaction"),
        sales: Locale.string("Sales"),
        unit: Locale.string("Unit"),
      }

      const STATE = {
        UPLOAD: {
          comment: {
            process: "Uploading file...",
            fail: "Fail to upload file",
          },
        },
        WAITPARSING: {
          comment: {
            process: "Wait parsing work",
            fail: "Fail to parse data. Please try again",
          },
        },
        PARSING: {
          comment: {
            process: "Parsing uploaded data...",
            fail: "Fail to parse data. Please try again",
          },
        },
        WAITCONFIRM: {
          comment: {
            fail: "Fail to get Waiting data. Please try again",
          },
        },
        MERGING: {
          comment: {
            process: "Merging waiting data to sales data",
            fail: "Fail to merge waiting data",
          },
        },
        ERROR: {
          comment: {
            timeout: "Timeout reached.",
            unknown: "Unknown error",
          },
        },
      }

      const setProgBarValue = function (value, max) {
        $scope.progBarOption.max = max
        return ($scope.progBarOption.value = value)
      }

      const showProgBar = function (value, max) {
        $scope.showProgBar = true
        return setProgBarValue(value, max)
      }

      const hideProgBar = function () {
        $scope.showProgBar = false
        return setProgBarValue(1, 100)
      }

      const setButtonState = function (confirm, close) {
        $scope.btnConfirm = confirm
        return ($scope.btnClose = close)
      }

      const showErrorMessage = function (state) {
        let left
        const message =
          state === STATE.ERROR.comment.timeout
            ? STATE.ERROR.comment.timeout
            : (left =
                __guard__(state != null ? state.comment : undefined, (x) => x.fail) != null
                  ? __guard__(state != null ? state.comment : undefined, (x) => x.fail)
                  : state != null
                  ? state.comment
                  : undefined) != null
            ? left
            : STATE.ERROR.comment.unknown

        hideProgBar()
        setButtonState(false, true)
        $scope.salesDataTableParams = null
        return ($scope.errMsg = message)
      }

      const setCurrentStatus = function (state) {
        if ($scope.currentState === state) {
          return
        }
        $scope.currentState = state
        switch (state) {
          case STATE.UPLOAD:
          case STATE.WAITPARSING:
          case STATE.PARSING:
          case STATE.MERGING:
            showProgBar(1, 100)
            return setButtonState(false, false)
          case STATE.WAITCONFIRM:
            hideProgBar()
            return setButtonState(true, true)
          default:
            return showErrorMessage(state)
        }
      }

      const uploadFile = function (callback) {
        setCurrentStatus(STATE.UPLOAD)
        return Upload.upload({
          url: `${config.apipath}/sales/data/${$scope.storeId}`,
          data: {
            file: $scope.rawFile,
            timezone: $scope.store.timezone,
            driverName: $scope.store.sales != null ? $scope.store.sales.driverName : undefined,
          },
        }).then(
          function (res) {
            // success
            $scope.parsingWorkId = res.data.id
            return callback(null)
          },
          (
            res // fail
          ) => callback(res)
        )
      }

      const checkCurrentState = function (state, callback) {
        const _endTimer = function (error) {
          $interval.cancel($scope.checkTimer)
          $scope.checkTimer = null
          return callback(error)
        }

        const kCHECKINGTIME = 1000 // ms
        const kMAXWAITINGCOUNT = 180 // 1s * 180 = 3min
        let waitingCount = kMAXWAITINGCOUNT
        return ($scope.checkTimer = $interval(
          StoreSalesSrv.getCurrentStatus,
          kCHECKINGTIME,
          0,
          true,
          { workId: $scope.parsingWorkId },
          function (err, currentState) {
            if (!$scope.checkTimer) {
              return
            }
            if (err || currentState.error > 0) {
              console.error(err != null ? err : currentState.comment)
              return _endTimer(state != null ? state : currentState)
            }
            if (currentState.workSize === 0) {
              return setCurrentStatus(STATE.WAITPARSING)
            }
            if (waitingCount-- === 0) {
              return _endTimer(STATE.ERROR.comment.timeout)
            }

            setCurrentStatus(state)
            const progValue =
              state === STATE.PARSING ? currentState.waiting : currentState.confirmed
            setProgBarValue(progValue, currentState.workSize)
            if (progValue === currentState.workSize) {
              return _endTimer(null)
            }
          }
        ))
      }

      const getWaitingData = function (callback) {
        setCurrentStatus(STATE.WAITCONFIRM)
        return StoreSalesSrv.getWaiting(
          { workId: $scope.parsingWorkId },
          function (err, waitingdata) {
            if (err) {
              return callback(err)
            }
            $scope.salesDataTableParams = new NgTableParams(
              {
                count: 14,
                sorting: { datetime: "asc" },
              },
              { counts: [7, 14, 50, 100], data: waitingdata }
            )
            return callback(null)
          }
        )
      }

      $scope.confirmWaitingData = () =>
        async.waterfall(
          [
            (callback) =>
              StoreSalesSrv.confirmWaiting(
                {
                  workId: $scope.parsingWorkId,
                  dropItems: [],
                },
                callback
              ),
            function (confirmId, callback) {
              setCurrentStatus(STATE.MERGING)
              return checkCurrentState(STATE.MERGING, callback)
            },
          ],
          function (errState) {
            if (errState) {
              return showErrorMessage(errState)
            }
            return $scope.confirm($scope.parsingWorkId)
          }
        )

      const init = () =>
        async.waterfall(
          [
            (callback) => uploadFile(callback),
            (callback) => checkCurrentState(STATE.PARSING, callback),
            (callback) => getWaitingData(callback),
          ],
          function (errState) {
            if (errState) {
              return showErrorMessage(errState)
            }
          }
        )

      return init()
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
