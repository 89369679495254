"use strict"

import angular from "angular"

/**
 * @class UIv2Embed
 *
 * @description
 * UIv2를 UIv1에 iframe으로 embed 합니다.
 *
 * @param {string} src Embed 하고자 하는 UIv2 페이지의 URL
 * @param {string} [id] (OPTIONAL) iframe tag의 id
 * @param {string} [class] (OPTIONAL) iframe tag의 class
 * @param {function} [messageHandler] (OPTIONAL) Child로부터 수신한 message event를
 * 처리하는 함수. Child에서 데이터를 전달받아야 하는 경우 사용한다. Child에서
 * `window.parent.postMessage(data)`로 parent에 message event를 전송할 수 있다.
 * @param {function} [onLoad] (OPTIONAL) iframe의 load 이벤트시 실행되는 함수
 *
 * @example
 * // Controller
 * $scope.messageHandler = (event) => {
 *   console.info("Message Received:", event.data)
 * }
 * $scope.onLoad = () => {
 *   console.info("loaded")
 * }
 *
 * // Template
 * <ui-v2-embed
 *   src="/v2/embed/admin/sensor/panorama/new"
 *   id="panorama-config"
 *   message-handler="messageHandler"
 *   on-load="onLoad()"
 * ></ui-v2-embed>
 *
 * // CSS
 * #panorama-config {
 *   width: 100%;
 *   height: 1020px;
 *   border: none;
 * }
 */

angular
  .module("uCountitUiApp")

  .directive("uiV2Embed", () => ({
    templateUrl: "components/ui-v2-embed/ui-v2-embed.html",
    controller: "uiV2EmbedCtrl",

    scope: {
      src: "@",
      id: "@",
      class: "@",
      messageHandler: "=",
      onLoad: "&",
    },
  }))
