/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular.module("uCountitUiApp").config(function ($stateProvider) {
  $stateProvider.state("login", {
    url: "/login?returnUrl",
    templateUrl: "app/account/login/login.html",
    controller: "LoginCtrl",
  })

  return $stateProvider
    .state("recovery", {
      url: "/account/recovery",
      templateUrl: "app/account/recovery/recovery.html",
      controller: "RecoveryCtrl",
    })
    .state("app.profile", {
      url: "/profile",
      templateUrl: "app/account/profile/profile.html",
      controller: "ProfileCtrl",
      authenticate: true,
    })
})
