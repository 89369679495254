/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

import Promise from "bluebird"

angular
  .module("uCountitUiApp")

  .controller(
    "trackingzoneConfigCtrl",
    function (
      $scope,
      $element,
      $window,
      $compile,
      Locale,
      usSpinnerService,
      ngDialog,
      hotkeys,
      Heatmaptag,
      CameraAdminSrv,
      CameraConfigSrv,
      DrawZone,
      DateTimeSrv,
      ApiSrv
    ) {
      let createHeatmapPanel
      const dbColorPattern = /\d+,\d+,\d+/
      $scope.rgbPattern = /rgb\(\d+,\d+,\d+\)/
      $scope.drawTags = []
      let savedDrawTags = []
      let orgTrackingZone = []
      let orgStaffZone = []
      let orgMaskingZone = []
      let orgGateZone = []
      let orgTrackingConfig = {}
      $scope.tzSrv = null
      $scope.hmtagSrv = null
      $scope.szSrv = null
      $scope.mzSrv = null
      const addButtonStr = Locale.string("Add")
      const drawingButtonStr = Locale.string("Drawing")
      $scope.drawingTag = null
      $scope.tagButtonStr = addButtonStr
      const errorImgeUrl = "/assets/images/no-image.svg"
      let orgLinkedZT2TZ = false
      const kDEFALUT_TRACKANALYSIS = {
        trackingZone: {
          maxDistance: 25,
          linkTime: 300,
          zones: [],
        },
        staffZone: {
          zones: [],
        },
        maskingZone: {
          zones: [],
        },
        countingArea: {
          areas: [],
        },
        gateZone: {
          zones: [],
        },
      }
      $scope.directionTypes = [
        { name: Locale.string("In & Out"), value: "io" },
        { name: Locale.string("In Only"), value: "i" },
        { name: Locale.string("Out Only"), value: "o" },
      ]

      const formConfig = angular.element(document.getElementsByClassName("trackingzone-container"))
      $scope.selectedItem = $scope.selectedItem != null ? $scope.selectedItem : {}

      angular.element($window).on("resize", () => load())

      $scope.shortcuts = [
        {
          combo: "d",
          description: "Delete selected point of tag",
          callback() {
            return $scope.$broadcast("trackingzone_delete_editpoint")
          },
        },
      ]

      $scope.strs = {
        linkedZT2TZ: Locale.string("Linked Traffic Zone"),
        unLinkedZT2TZ: Locale.string("Unlinked Traffic Zone"),
        add: Locale.string("Add"),
        edit: Locale.string("Edit"),
        save: Locale.string("Save"),
        cancel: Locale.string("Cancel"),
        clear: Locale.string("Clear"),
      }

      $scope.tzCount = 1
      $scope.szCount = 2
      $scope.mzBaseIndex = $scope.tzCount + $scope.szCount
      $scope.mzCount = 4
      $scope.gzBaseIndex = $scope.mzBaseIndex + $scope.mzCount
      $scope.gzCount = 4

      const showLoading = function () {
        angular.element("#mySplash").show()
        return usSpinnerService.spin("mySpinner")
      }

      const hideLoading = function () {
        angular.element("#mySplash").hide()
        return usSpinnerService.stop("mySpinner")
      }

      $scope.isZoneTrace = () =>
        __guard__(
          __guard__(
            $scope.selectedItem != null ? $scope.selectedItem.functions : undefined,
            (x1) => x1.use
          ),
          (x) => x.zonetrace
        )

      $scope.isTrackingTree = () =>
        __guard__(
          __guard__(
            $scope.selectedItem != null ? $scope.selectedItem.functions : undefined,
            (x1) => x1.use
          ),
          (x) => x.trackingtree
        )

      $scope.modifyZone = function (ztype, mode, index) {
        let dz
        if (ztype === "trackingzone") {
          dz = $scope.tzSrv
        } else if (ztype === "staffzone") {
          dz = $scope.szSrv
        } else if (ztype === "maskingzone") {
          dz = $scope.mzSrv
        } else {
          throw new Error("invalid zone type " + ztype)
        }

        $scope.editOptions = {
          mode,
          index,
        }
        if (mode === "clear") {
          $scope.editOptions = null
          return _.assign($scope.drawTags[index], dz.getDefaultDrawZone(index))
        } else if (mode === "add") {
          if (ztype === "trackingzone" && index === 0) {
            return ($scope.editOptions.tag = dz.transformDrawZones([dz.getHexagonZone()])[0])
          }
        }
      }

      $scope.isEditingTag = (index) => $scope.editOptions && $scope.editOptions.index === index

      $scope.isEmptyPoints = (index) => _.isEmpty($scope.drawTags[index].points)

      $scope.isClearTag = (index) =>
        $scope.isEmptyPoints(index) && _.isEmpty($scope.drawTags[index].name)

      $scope.isNotEditTag = (index) => $scope.isEditingTag(index) || $scope.isClearTag(index)

      $scope.isShowBtn = function (type, index) {
        switch (type) {
          case "add":
            return $scope.isEmptyPoints(index) && !$scope.isEditingTag(index)
          case "edit":
            return !$scope.isEmptyPoints(index) && !$scope.isEditingTag(index)
          case "save":
            return $scope.isEditingTag(index)
          case "cancel":
            return $scope.isEditingTag(index)
          case "clear":
            return !$scope.isEmptyPoints(index) && !$scope.isEditingTag(index)
        }
      }

      $scope.setColor = function (tag) {
        tag.color = dbColorPattern.exec(tag.rgbcolor)
        if (tag.color) {
          return (tag.color = tag.color[0])
        }
      }

      $scope.changeGateDirection = function (tag) {
        tag.direction = tag.dirType.value
      }

      $scope.toggleLinkedZT2TZ = () =>
        ($scope.selectedItem.linkedZT2TZ = !$scope.selectedItem.linkedZT2TZ)

      const isSettingChanged = () =>
        (!angular.equals($scope.drawTags, savedDrawTags) && _.isEmpty($scope.editOptions)) ||
        orgLinkedZT2TZ !== $scope.selectedItem.linkedZT2TZ ||
        !_.isEqual(orgTrackingConfig, $scope.trackingConfig)

      $scope.canSubmit = function () {
        if (!$scope.isOpen) {
          return
        }
        return $scope.formConfig.$valid && isSettingChanged()
      }

      const updateLinkedZoneTraffic = async function (tags) {
        if (!$scope.selectedItem.linkedZT2TZ) {
          return
        }

        const heatmapTags = await $scope.hmtagSrv.getTags({ all: true })
        const latestHeatmapTag = _.last(heatmapTags)

        if (_.isEmpty(latestHeatmapTag.heatmaptags)) {
          latestHeatmapTag.heatmaptags.push($scope.hmtagSrv.getDefaultDrawTag(0))
        }

        Object.assign(latestHeatmapTag.heatmaptags[0], {
          points: tags[0].points,
          color: tags[0].color,
          active: tags[0].active,
        })
        if (_.isEmpty(latestHeatmapTag.heatmaptags[0].name)) {
          latestHeatmapTag.heatmaptags[0].name = "tag1"
        }

        return await $scope.hmtagSrv.setTags(heatmapTags)
      }

      $scope.submitForm = function () {
        $scope.errorMessage = ""

        $scope.trackAnalysis.trackingZone = {
          maxDistance: Math.round((65535.0 / 100) * $scope.trackingConfig.maxDistance),
          linkTime: $scope.trackingConfig.linkTime,
          zones: $scope.tzSrv.transformZones($scope.drawTags.slice(0, $scope.tzCount)),
        }
        $scope.trackAnalysis.staffZone = {
          zones: $scope.szSrv.transformZones(
            $scope.drawTags.slice($scope.tzCount, $scope.mzBaseIndex)
          ),
        }
        $scope.trackAnalysis.maskingZone = {
          zones: $scope.mzSrv.transformZones(
            $scope.drawTags.slice($scope.mzBaseIndex, $scope.gzBaseIndex)
          ),
        }
        $scope.trackAnalysis.gateZone = {
          zones: $scope.gzSrv.transformZones($scope.drawTags.slice($scope.gzBaseIndex)),
        }

        return Promise.all([
          CameraAdminSrv.update({ id: $scope.selectedItem._id }, $scope.selectedItem).$promise,
          CameraConfigSrv.set(
            { id: $scope.selectedItem._id, item: "trackAnalysis" },
            $scope.trackAnalysis
          ).$promise,
          updateLinkedZoneTraffic($scope.trackAnalysis.trackingZone.zones),
        ]).spread(
          function (adRes, confRes, ztRes) {
            savedDrawTags = _.cloneDeep($scope.drawTags)
            orgTrackingConfig = _.cloneDeep($scope.trackingConfig)

            return $scope.$emit("trackingzone_config_complete")
          },
          (error) =>
            ($scope.errorMessage =
              error === "duplicated name"
                ? Locale.string("msg_error_trackingzone_name_duplicated")
                : Locale.string("msg_error_update"))
        )
      }

      $scope.$watch("drawingTag", function (tag) {
        if (tag) {
          return ($scope.tagButtonStr = drawingButtonStr)
        } else {
          return ($scope.tagButtonStr = addButtonStr)
        }
      })

      $scope.$watch(
        () =>
          JSON.stringify({
            isOpen: $scope.isOpen,
            selectedItem: $scope.selectedItem != null ? $scope.selectedItem._id : undefined,
          }),
        () => load()
      )

      var load = function () {
        if (!$scope.isOpen) {
          return
        }
        if (!$scope.selectedItem) {
          return
        }
        if (
          $scope.selectedItem.snapshotUrl === $scope.snapshotUrl &&
          formConfig.width() === $scope.imgWidth
        ) {
          return
        }

        showLoading()
        $scope.errorMessage = ""
        let snapshotRatio = 9 / 16
        $scope.drawingTag = null
        $scope.editOptions = null
        $scope.trackAnalysis = _.cloneDeep(kDEFALUT_TRACKANALYSIS)
        if ($scope.selectedItem.linkedZT2TZ == null) {
          $scope.selectedItem.linkedZT2TZ = false
        }
        orgLinkedZT2TZ = _.cloneDeep($scope.selectedItem.linkedZT2TZ)

        const _convert2DrawZones = function (maxCount, dz, zones) {
          const dzones = dz.transformDrawZones(zones)
          return __range__(0, maxCount, false).map(function (i) {
            if (
              (dzones != null ? dzones[i] : undefined) &&
              !_.isEmpty(dzones[i] != null ? dzones[i].points : undefined)
            ) {
              return dzones[i]
            } else {
              return dz.getDefaultDrawZone(i)
            }
          })
        }

        const _getSafeCameraConfig = (id, item) =>
          CameraConfigSrv.get({ id, item, _: Date.now() })
            .$promise.then((d) => d)
            .catch(function (err) {
              console.warn("CameraConfigSrvSafe error", err)
              return {}
            })

        const _convert2GateForm = (gatezone) => {
          gatezone.forEach((z) => {
            let dirType = 0 // default "io"
            if (z.direction == "i") {
              dirType = 1
            } else if (gatezone.direction == "o") {
              dirType = 2
            }
            z.dirType = $scope.directionTypes[dirType]
          })
          return gatezone
        }

        const _setSettings = function (ratio, url) {
          $scope.imgWidth = formConfig.width()
          $scope.imgHeight = $scope.imgWidth * ratio

          $scope.hmtagSrv = new Heatmaptag(
            $scope.selectedItem._id,
            $scope.imgWidth,
            $scope.imgHeight
          )
          $scope.tzSrv = new DrawZone($scope.imgWidth, $scope.imgHeight, "trackingzone")
          $scope.szSrv = new DrawZone($scope.imgWidth, $scope.imgHeight, "staffzone")
          $scope.mzSrv = new DrawZone($scope.imgWidth, $scope.imgHeight, "maskingzone")
          $scope.gzSrv = new DrawZone($scope.imgWidth, $scope.imgHeight, "gatezone")

          return Promise.all([
            _getSafeCameraConfig($scope.selectedItem._id, "trackAnalysis"),
            ApiSrv.getCamera({ id: $scope.selectedItem._id }),
          ])
            .spread(function (ta, camera) {
              _.merge($scope.trackAnalysis, ta)
              $scope.trackAnalysis.trackingZone.maxDistance =
                __guard__(ta != null ? ta.trackingZone : undefined, (x) => x.maxDistance) != null
                  ? Math.round((100.0 / 65535) * $scope.trackAnalysis.trackingZone.maxDistance)
                  : kDEFALUT_TRACKANALYSIS.trackingZone.maxDistance
              orgTrackingConfig = {
                maxDistance: $scope.trackAnalysis.trackingZone.maxDistance,
                linkTime: $scope.trackAnalysis.trackingZone.linkTime,
              }
              orgTrackingZone = _convert2DrawZones(
                $scope.tzCount,
                $scope.tzSrv,
                $scope.trackAnalysis.trackingZone?.zones
              )
              orgStaffZone = _convert2DrawZones(
                $scope.szCount,
                $scope.szSrv,
                $scope.trackAnalysis.staffZone?.zones
              )
              orgMaskingZone = _convert2DrawZones(
                $scope.mzCount,
                $scope.mzSrv,
                $scope.trackAnalysis.maskingZone?.zones
              )
              orgGateZone = _convert2DrawZones(
                $scope.gzCount,
                $scope.gzSrv,
                $scope.trackAnalysis.gateZone?.zones
              )
              orgGateZone = _convert2GateForm(orgGateZone)

              savedDrawTags = _.concat(orgTrackingZone, orgStaffZone, orgMaskingZone, orgGateZone)
              $scope.drawTags = _.cloneDeep(savedDrawTags)
              $scope.trackingConfig = _.cloneDeep(orgTrackingConfig)
              $scope.snapshotUrl = url
              $scope.camera = camera
              createHeatmapPanel()
              return hideLoading()
            })
            .catch((err) => console.warn("err", err))
        }

        const image = new Image()
        image.onload = function () {
          snapshotRatio = this.height / this.width
          return _setSettings(snapshotRatio, $scope.selectedItem.snapshotUrl)
        }
        image.onerror = () => _setSettings(snapshotRatio, errorImgeUrl)
        image.src = $scope.selectedItem.snapshotUrl
        return $scope.shortcuts.forEach(hotkeys.bindTo($scope).add)
      }

      return (createHeatmapPanel = function () {
        if ($element.find(".dwellmap-container .heatmap-panel").length) {
          $element.find(".dwellmap-container .heatmap-panel").remove()
        }

        $scope.storetimezone = $scope.selectedItem.timezone
        const today = DateTimeSrv.getLocalTimeByTimezone($scope.storetimezone)
        $scope.param = {
          start: moment(today).subtract(3, "day").startOf("day"),
          end: moment(today).subtract(1, "day").endOf("day"),
          sampling: "day",
        }
        $scope.heatmapType = "pass"
        $scope.hideFrame = { scaleBar: true }
        $scope.heatmapWeight = $scope.selectedItem.store.heatmapWeight
        $scope.heatmapStats = $scope.selectedItem.store.stats
        $scope.validTime = true

        return $element
          .find(".dwellmap-container")
          .append(
            $compile(
              [
                "<heatmap-panel",
                '  data-data="camera"',
                '  data-live="false"',
                '  data-param="param"',
                '  data-timezone="storetimezone"',
                '  data-ready="validTime"',
                '  data-hide-frame="hideFrame"',
                '  data-show-data="heatmapType"',
                '  data-heatmap-weight="heatmapWeight"',
                '  data-heatmap-stats="heatmapStats">',
                "</heatmap-panel>",
              ].join("")
            )($scope)
          )
      })
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
