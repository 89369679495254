/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular.module("uCountitUiApp").factory("QueueSrv", function ($rootScope, $q, $resource) {
  const config = $rootScope.globalConfig

  const getGateResource = function () {
    return $resource(`${config.apipath}/queue/stores/:storeId/gates/:gateId`, {
      storeId: this.storeId,
      gateId: this.gateId,
    })
  }

  const _getQueueLines = () =>
    $resource(
      `${config.apipath}/queue/stores/:storeId/gates/:gateId/queuelines`,
      {},
      { get: { isArray: true } }
    )

  const _getQueueStat = () =>
    $resource(
      `${config.apipath}/queue/stores/:storeId/gates/:gateId/queuelines/:queueLineId/stats`,
      {},
      { get: { isArray: true } }
    )

  const _isValidParams = function (object, requiredProps) {
    if (object == null) {
      return false
    }

    for (var props of Array.from(requiredProps)) {
      if (!object.hasOwnProperty(props)) {
        return false
      }
    }

    return true
  }

  const getQueueLinesOfGate = function (params) {
    const deferred = $q.defer()

    const requiredProperties = ["storeId", "gateId"]
    const isValidParams = _isValidParams(params, requiredProperties)
    if (isValidParams) {
      params._ = Date.now()
      _getQueueLines().get(
        params,
        (qLine) => deferred.resolve(qLine),
        (err) => deferred.reject(err)
      )
    } else {
      deferred.reject("invalid parameters")
    }

    return deferred.promise
  }

  const getQueueData = function (params) {
    const deferred = $q.defer()

    const requiredProperties = ["storeId", "gateId"]
    const isValidParams = _isValidParams(params, requiredProperties)
    if (isValidParams) {
      _getQueueStat().get(
        params,
        (res) => deferred.resolve(res),
        (err) => deferred.reject(err)
      )
    } else {
      deferred.reject("invalid parameters")
    }

    return deferred.promise
  }

  return {
    Gate: getGateResource,
    getQueueLinesOfGate,
    getQueueData,
  }
})
