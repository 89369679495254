/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .controller(
    "ForgotCtrl",
    ($scope, $location, $uibModalInstance, BrandSrv, RecoverySrv) =>
      ($scope.submit = function () {
        $scope.resultSuccess = false
        $scope.resultError = false

        const brand = BrandSrv.getBrand()
        return RecoverySrv.save({
          email: $scope.email.address,
          brandName: brand.brandName,
          hostUrl: `${$location.protocol()}://${$location.host()}:${$location.port()}`,
        }).$promise.then(
          (res) => ($scope.resultSuccess = true),

          (res) => ($scope.resultError = true)
        )
      })
  )
