/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS201: Simplify complex destructure assignments
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import Promise from "bluebird"
import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")
  .directive("storeScheduleForm", () => ({
    restrict: "E",
    templateUrl: "app/admin/store/store-schedule-form.html",
    replace: true,

    scope: {
      store: "=",
      isOpened: "=",
    },

    controller: "storeScheduleFormCtrl",
  }))
  .controller("storeScheduleFormCtrl", function ($scope, ApiSrv, Locale, StoreAdminSrv) {
    let updateHolidays
    $scope.hours = _.range(25).map(function (num) {
      if (num < 10) {
        return "0" + num.toString()
      } else {
        return num.toString()
      }
    })
    $scope.minutes = ["00", "10", "20", "30", "40", "50"]
    $scope.weekdayList = [
      { name: Locale.string("monday"), value: "mon" },
      { name: Locale.string("tuesday"), value: "tue" },
      { name: Locale.string("wednesday"), value: "wed" },
      { name: Locale.string("thursday"), value: "thu" },
      { name: Locale.string("friday"), value: "fri" },
      { name: Locale.string("saturday"), value: "sat" },
      { name: Locale.string("sunday"), value: "sun" },
    ]

    const initialize = function () {
      $scope.bizHours = []
      $scope.icalendarsOfGlobal = { holidays: [] }
      $scope.icalendarsOfCompany = { holidays: [], workdays: [] }
      $scope.deletedIcalendars = []

      $scope.originalBizHours = null
      $scope.originalIcalendars = null

      return ($scope.isHolidays = [])
    }

    $scope.$watch("store", function () {
      if ($scope.isOpened) {
        initialize()

        $scope.bizHours = convertStrTimeToBizHours($scope.store.schedule.businessHours)
        $scope.isHolidays = $scope.bizHours.map((bh, idx) => $scope.isHoliday(idx))

        $scope.originalBizHours = {
          active: _.cloneDeep($scope.store.schedule.businessHours.active),
          bizHours: _.cloneDeep($scope.bizHours),
        }

        return getIcalendars().then(
          () =>
            ($scope.originalIcalendars = {
              holidays: _.cloneDeep($scope.store.schedule.holidays),
              workdays: _.cloneDeep($scope.store.schedule.workdays),
            })
        )
      }
    })

    $scope.closeDialog = function () {
      $scope.isOpened = false
      return ($scope.store = null)
    }

    $scope.canSubmit = function () {
      if (!$scope.store || !$scope.formDlg.$valid) {
        return false
      }
      return isChangedIcalendars() || isChangedBizHours()
    }

    $scope.submitForm = function () {
      $scope.store.schedule.businessHours.dayOfWeek = convertBizHoursToStrTime()

      return updateHolidays($scope.store)
        .then(() => StoreAdminSrv.update({ id: $scope.store._id }, $scope.store.data).$promise)
        .then(() => $scope.closeDialog())
        .catch((err) => console.error(err))
    }

    $scope.copyAll = function () {
      let i
      let asc, end
      for (
        i = 1, end = $scope.bizHours.length, asc = 1 <= end;
        asc ? i < end : i > end;
        asc ? i++ : i--
      ) {
        $scope.bizHours[i] = _.cloneDeep($scope.bizHours[0])
      }

      return (() => {
        let asc1, end1
        const result = []
        for (
          i = 1, end1 = $scope.bizHours.length, asc1 = 1 <= end1;
          asc1 ? i < end1 : i > end1;
          asc1 ? i++ : i--
        ) {
          result.push(($scope.isHolidays[i] = $scope.isHolidays[0]))
        }
        return result
      })()
    }

    $scope.validateBizHours = function (idx) {
      const bh = $scope.bizHours[idx]
      if (!bh) {
        return
      }

      const openHour = parseInt(bh.open.hour)
      const closeHour = parseInt(bh.close.hour)

      if (openHour === 24) {
        bh.open.minute = $scope.minutes[0]
      }
      if (closeHour === 24) {
        return (bh.close.minute = $scope.minutes[0])
      }
    }

    $scope.toggleHoliday = function (idx) {
      if ($scope.isHolidays[idx]) {
        const bh = $scope.bizHours[idx]
        bh.open.hour = "00"
        bh.open.minute = "00"
        bh.close.hour = "00"
        return (bh.close.minute = "00")
      }
    }

    $scope.isHoliday = function (idx) {
      const bh = $scope.bizHours[idx]
      return (
        bh.open.hour === "00" &&
        bh.open.minute === "00" &&
        bh.close.hour === "00" &&
        bh.close.minute === "00"
      )
    }

    var getIcalendars = () =>
      Promise.all([
        ApiSrv.getIcalendars({ orgId: "c00000000000000000000001" }),
        ApiSrv.getIcalendars({ orgId: $scope.store._id }),
        ApiSrv.getIcalendars({ orgId: $scope.store._companyId }),
      ]).then(function (...args) {
        const [calsOfGlobal, calsOfStore, calsOfCompany] = Array.from(args[0])
        $scope.icalendarsOfGlobal.holidays = calsOfGlobal.filter((c) => c.type === "holiday")

        $scope.store.schedule.holidays = calsOfStore.filter((c) => c.type === "holiday")
        $scope.store.schedule.workdays = calsOfStore.filter((c) => c.type === "workday")

        $scope.icalendarsOfCompany.holidays = calsOfCompany.filter((c) => c.type === "holiday")
        return ($scope.icalendarsOfCompany.workdays = calsOfCompany.filter(
          (c) => c.type === "workday"
        ))
      })

    const _parseTime = function (tm) {
      const _tm = tm.split(":")
      return {
        hour: _tm[0],
        minute: _tm[1],
      }
    }

    const parseHourAndMinute = (bh) => ({
      // '10:00' => { hour: '10', minute: '00' }
      open: _parseTime(bh.open),

      close: _parseTime(bh.close),
    })

    var convertStrTimeToBizHours = function (sbh) {
      if (sbh.dayOfWeek.length === 0) {
        return Array(7)
          .fill()
          .map(() => Object.assign({}, parseHourAndMinute({ open: "00:00", close: "24:00" })))
      } else if (sbh.dayOfWeek.length === 1) {
        return Array(7)
          .fill()
          .map(() => Object.assign({}, parseHourAndMinute(sbh.dayOfWeek[0])))
      } else {
        return sbh.dayOfWeek.map(parseHourAndMinute)
      }
    }

    var convertBizHoursToStrTime = () =>
      $scope.bizHours.map((bh) => ({
        open: `${bh.open.hour}:${bh.open.minute}`,
        close: `${bh.close.hour}:${bh.close.minute}`,
      }))

    var isChangedIcalendars = () =>
      !angular.equals($scope.originalIcalendars, {
        holidays: $scope.store.schedule.holidays,
        workdays: $scope.store.schedule.workdays,
      })

    var isChangedBizHours = () =>
      !angular.equals($scope.originalBizHours, {
        active: $scope.store.schedule.businessHours.active,
        bizHours: $scope.bizHours,
      })

    return (updateHolidays = function (store) {
      if (!isChangedIcalendars()) {
        return Promise.resolve()
      }

      return Promise.all([
        Promise.mapSeries($scope.deletedIcalendars, (icalendar) =>
          ApiSrv.deleteIcalendar({ id: icalendar._id })
        ),

        Promise.mapSeries(store.schedule.holidays, (holiday, idx) =>
          ApiSrv.updateIcalendar(holiday).then((res) => (store.schedule.holidays[idx] = res._id))
        ),
        Promise.mapSeries(store.schedule.workdays, (workday, idx) =>
          ApiSrv.updateIcalendar(workday).then((res) => (store.schedule.workdays[idx] = res._id))
        ),
      ])
    })
  })

// $scope.subsamplingCountdata = (isFootfall, isQueue, isTrafficHeatmap) ->
//   $scope.messageOfSubsampling = message: ''
//   return  unless $window.confirm Locale.string 'msg_confirm_convert'

//   startDate = moment($scope.pageOptions.dt.startDate)
//   endDate = moment($scope.pageOptions.dt.endDate)
//   open = $scope.open.timeFunc()
//   close = $scope.close.timeFunc()

//   params =
//     id: $scope.form._id
//     from: startDate.format 'YYYYMMDDHHmm'
//     to: endDate.add(1, 'days').startOf('day').format 'YYYYMMDDHHmm'

//   async.series
//     updateBH: (callback) ->
//       item =
//         _companyId: $scope.form._companyId
//         schedule:
//           businessHours:
//             active: true
//             dayOfWeek: [{open, close}]

//       StoreAdminSrv.update {id: params.id}, item, (sobj, res) ->
//         callback null
//       , (err) ->
//         callback err

//     hour: (callback) ->
//       if !isFootfall
//         callback null
//         return

//       ApiSrv.subsamplingByStore(_.merge params, {sampling: 'hour'})
//       .then (res) ->
//         callback null
//       , (err) ->
//         callback err

//     day: (callback) ->
//       if !isFootfall
//         callback null
//         return

//       ApiSrv.subsamplingByStore(_.merge params, {sampling: 'day'})
//       .then (res) ->
//         callback null
//       , (err) ->
//         callback err

//     queue: (callback) ->
//       if !isQueue
//         callback null
//         return

//       ApiSrv.queueSubsamplingByStore($scope.form._id, startDate.format(), endDate.format())
//       .then (res) ->
//         callback null
//       , (err) ->
//         callback err

//     trafficHeatmap: (callback) ->
//       if !isTrafficHeatmap
//         callback null
//         return

//       ApiSrv.trafficHeatmapSubsamplingByStore(params)
//       .then (res) ->
//         callback null
//       , (err) ->
//         callback err

//   , (err, results) ->
//     $scope.showConvertMessage = false
//     if err
//       $scope.messageOfSubsampling =
//         message: 'Error: failed to convert.'
//         isSuccess: false
//     else
//       $scope.reload false
//       $scope.messageOfSubsampling =
//         message: 'Ok: success to convert.'
//         isSuccess: true
