/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("exportButton", () => ({
    restrict: "E",

    template: [
      '<div class="export btn-group" uib-dropdown>',
      '<button id="{{id}}" class="btn btn-default btn-xs hidden-xs" uib-dropdown-toggle>',
      '<span data-i18n="{{name}}"></span>',
      '<span class="caret"></span>',
      "</button>",
      '<ul class="pull-right" role="menu" aria-labelledby="{{id}}" uib-dropdown-menu>',
      '<li role="menuitem">',
      '<a href="#" data-ng-click="exportFn({type:\'csv\'})">',
      '<i class="fa fa-file-text"></i>',
      " CSV",
      "</a>",
      "</li>",
      '<li role="menuitem">',
      '<a href="#" data-ng-click="exportFn({type:\'excel\'})">',
      '<i class="fa fa-file-excel-o"></i>',
      " EXCEL",
      "</a>",
      "</li>",
      "</ul>",
      "</div>",
    ].join(""),

    scope: {
      id: "@",
      name: "@",
      exportFn: "&export",
    },

    replace: true,

    compile(element, attrs) {
      if (!attrs.name) {
        return (attrs.name = "Export")
      }
    },
  }))
  .directive("shortcutButton", () => ({
    restrict: "E",

    template: [
      '<a href="" class="btn-icon btn-icon-round bg-gray-light btn-to-the-top" data-ng-click="gotoTarget()">',
      '<i class="mdi mdi-chevron-{{iconDirection}}"></i>',
      "</a>",
    ].join(""),

    scope: {
      target: "@",
      iconDirection: "@",
    },

    replace: true,

    controller($scope, $anchorScroll, $location) {
      $anchorScroll.yOffset = 65

      if (angular.isUndefined($scope.iconDirection)) {
        $scope.iconDirection = "up"
      }

      return ($scope.gotoTarget = function () {
        $location.hash($scope.target)
        return $anchorScroll()
      })
    },
  }))
