/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import Promise from "bluebird"
import angular from "angular"

import async from "async"

angular
  .module("uCountitUiApp")
  .controller(
    "AccountCtrl",
    function (
      $scope,
      $filter,
      $window,
      ApiSrv,
      AccountSrv,
      Locale,
      Auth,
      NgTableParams,
      AccountGroupSrv,
      BrandSrv,
      ngDialog,
      HeaderSrv,
      Account,
      me
    ) {
      if (!me.gteCompany()) {
        return
      }
      $scope.Account = Account
      $scope.me = me
      $scope.itemName = Locale.string("Account")
      $scope.companyPlaceholder = Locale.string("choose a company")
      $scope.agencyPlaceholder = Locale.string("choose an agency")
      $scope.inputDlgTitle = "Create"
      $scope.ORG_NOT_AVAILABLE = `<${Locale.string("org_not_available")}>`
      $scope.selectedAgency = []
      let original = null

      const kPDPMessage = [
        "Please check if the email address and the name are completely anonymous. Our service DOES NOT ACCEPT any actual private email addresses or names by the law in your country.",
        "For example,",
        "     * Email: ucountit@customerdomain.com, store_a@customerdomain.com, ...",
        "     * Name: uCount.it Admin, Store A Manager, ...",
        "Once you click Ok, you confirm that you have not enter any actual private email address or name for this account. Otherwise click Cancel and change them properly.",
      ].join("\n")

      $scope.$watch("showSelectedOrganization", (value) => $scope.reload(true))

      $scope.showAdditionalOperation = () =>
        $scope.selectedRole === "store" &&
        $scope.inputDlgTitle === "Create" &&
        $scope.selectedCompany

      $scope.revert = function () {
        $scope.form = {
          provider: "local",
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          active: true,
          favorite: {
            cameras: [],
            stores: [],
          },
          role: {
            group: "store",
          },
          lastLoginedAt: null,
          language: BrandSrv.getDefaultBrand().language,
          locale: {
            region: BrandSrv.getDefaultBrand().region,
          },
          addDefaultAccountGroup: false,
        }

        $scope.selectedRole = $scope.lastSelectedRole != null ? $scope.lastSelectedRole : "store"
        const defaultCompany =
          ($scope.companies != null ? $scope.companies.length : undefined) === 1
            ? $scope.companies[0]
            : _.find($scope.companies, {
                _id: Auth.clientInfo("admin.accounts.lastSelectedCompanyId"),
              })
        $scope.selectCompany(defaultCompany)
        $scope.selectedAgency = (() => {
          if (($scope.agencies != null ? $scope.agencies.length : undefined) === 1) {
            return [$scope.agencies[0]]
          } else {
            const ids = Auth.clientInfo("admin.accounts.lastSelectedAgencyId")
            return $scope.agencies.filter(function (a) {
              if (ids) {
                return ids.indexOf(a._id) >= 0
              } else {
                return false
              }
            })
          }
        })()

        $scope.form_create.$setPristine()
        return ($scope.errorMessage = "")
      }

      const AddDefaultAccountGroup = function (id, account, callback) {
        if (callback == null) {
          callback = function () {}
        }
        if (!id || account.role.group !== "store") {
          return callback("invalid account role")
        }

        if (!$scope.defaultAccountGroup) {
          return callback("no account group")
        }

        return AccountGroupSrv.addMember(
          {
            id: $scope.defaultAccountGroup._id,
            accountId: id,
          },
          (res) => callback(),
          (err) => callback(err)
        )
      }

      const findDefaultAccountGroup = function (company) {
        const kDefaultAccountGroupName = "Users"
        return ($scope.defaultAccountGroup = _.find(
          $scope.allAccountGroup,
          (ag) =>
            ag.companyId === (company != null ? company._id : undefined) &&
            ag.name === kDefaultAccountGroupName
        ))
      }

      // eslint-disable-next-line no-unused-vars
      const setAvailableAccountGroup = (company) =>
        ($scope.accountGroup = $filter("filter")(
          $scope.allAccountGroup,
          (ac) => ac.companyId === company._id
        ))

      $scope.selectCompany = function (company) {
        $scope.selectedCompany = company
        //setAvailableAccountGroup company
        return findDefaultAccountGroup(company)
      }

      const setFormLanguageLocale = function (defaultLocale) {
        Auth.fetchCurrentAccount().then(function (auth) {
          if (defaultLocale.language === "") {
            $scope.form.language = auth.language
          }
          if (defaultLocale.locale.region === "") {
            $scope.form.locale.region = auth.locale.region
          }
          if (defaultLocale.language !== "") {
            $scope.form.language = defaultLocale.language
          }
          if (defaultLocale.locale.region !== "") {
            return ($scope.form.locale.region = defaultLocale.locale.region)
          }
        })
      }

      $scope.canSubmit = function () {
        if (!$scope.isOpenInputDlg) {
          return
        }
        $scope.form.role.group = $scope.selectedRole
        const selectedOrgs = (() => {
          switch ($scope.form.role.group) {
            case "store":
            case "company":
              return $scope.selectedCompany
            case "agency":
              return $scope.selectedAgency
            case "space":
              return $scope.spaces[0]
            default:
              return []
          }
        })()

        if ($scope.canShowAgencyList() && $scope.selectedAgency?.length > 0) {
          setFormLanguageLocale($scope.selectedAgency[0].default)
        } else if ($scope.canShowCompanyList() && $scope.selectedCompany) {
          ApiSrv.getAgency({ id: $scope.selectedCompany._agencyId }).then((agency) => {
            setFormLanguageLocale(agency.default)
          })
        }

        $scope.form.role.orgs =
          selectedOrgs && _.isArray(selectedOrgs)
            ? selectedOrgs.map((a) => a._id)
            : selectedOrgs
            ? [selectedOrgs._id]
            : []

        if (!$scope.form.password) {
          delete $scope.form.password
        }
        if (!$scope.form.confirm_password) {
          delete $scope.form.confirm_password
        }

        return $scope.form_create.$valid && !angular.equals($scope.form, original)
      }

      $scope.submitForm = function () {
        if ($scope.inputDlgTitle === "Create") {
          return $scope.createItem($scope.form)
        } else {
          return $scope.updateItem($scope.form)
        }
      }

      $scope.createItem = function (item) {
        const _save = () =>
          AccountSrv.save(
            item,
            function (sobj, res) {
              $scope.isOpenInputDlg = false
              $scope.lastSelectedRole = $scope.selectedRole
              Auth.clientInfo(
                "admin.accounts.lastSelectedCompanyId",
                $scope.selectedCompany != null ? $scope.selectedCompany._id : undefined
              )
              Auth.clientInfo(
                "admin.accounts.lastSelectedAgencyId",
                $scope.selectedAgency.map((a) => a._id)
              )

              if ($scope.form.addDefaultAccountGroup) {
                return AddDefaultAccountGroup(sobj._id, item, function (err) {
                  $scope.reload(false)
                  return $scope.$broadcast("reload_accountgroup")
                })
              } else {
                return $scope.reload(false)
              }
            },

            function (error) {
              if (error.status === 409) {
                return ($scope.errorMessage = Locale.string("msg_error_mail_duplicated"))
              } else {
                return ($scope.errorMessage = Locale.string("msg_error_create"))
              }
            }
          )

        let usePDP = false
        switch (item.role.group) {
          case "company":
          case "store":
            var agency = _.find($scope.agencies, { _id: $scope.selectedCompany._agencyId })
            usePDP = agency.policy != null ? agency.policy.noPersonalData : undefined
            break
          case "agency":
            var agencies = _.intersectionBy($scope.agencies, $scope.selectedAgency, "_id")
            usePDP = _.some(agencies, { policy: { noPersonalData: true } })
            break
        }

        if (usePDP) {
          return ngDialog
            .openConfirm({
              template: "components/popup/popup-confirm.html",
              data: {
                message: kPDPMessage,
              },
              closeByEscape: true,
              closeByDocument: true,
              showClose: false,
              className: "ngdialog-theme-default popup-confirm wide",
              controller: "ucPopupConfirmCtrl",
            })
            .then(() => _save())
        } else {
          return _save()
        }
      }

      $scope.updateItem = function (item) {
        if (!item.password) {
          delete item.password
        }
        return AccountSrv.update(
          { id: item._id },
          item,
          function (sobj, res) {
            $scope.isOpenInputDlg = false
            return $scope.reload(false)
          },

          (error) => ($scope.errorMessage = Locale.string("msg_error_update"))
        )
      }

      $scope.roleLabelHtml = function (item) {
        if (item.role === "space") {
          return "class='label label-danger'"
        } else {
          return "class='label label-info'"
        }
      }

      $scope.$watch("selectedRole", function (role) {
        switch (role) {
          case "company":
          case "store":
            if (
              !$scope.companies ||
              ($scope.companies != null ? $scope.companies.length : undefined) === 0
            ) {
              return $window.alert(Locale.string("company list is empty"))
            }
            break
          case "agency":
            if (
              !$scope.agencies ||
              ($scope.agencies != null ? $scope.agencies.length : undefined) === 0
            ) {
              return $window.alert(Locale.string("agency list is empty"))
            }
            break
        }
      })

      $scope.canShowCompanyList = () =>
        $scope.selectedRole === "store" || $scope.selectedRole === "company"

      $scope.canShowAgencyList = () => $scope.selectedRole === "agency"

      $scope.isCompleteList = (list) => list && (list != null ? list.length : undefined) > 1

      $scope.openInputDlg = function (item) {
        $scope.isOpenInputDlg = true
        $scope.canChangeRole = true
        if (item != null) {
          if (
            me.role.group !== Account.DEV_ADMIN &&
            [Account.DEV_ADMIN, Account.SPACE].indexOf(item.role.group) >= 0
          ) {
            $scope.canChangeRole = false
          }
          if (me._id === item._id) {
            $scope.canChangeRole = false
          }

          $scope.inputDlgTitle = "Update"
          $scope.form = _.cloneDeep(item.data)
          if ($scope.form.role.group === "company" || $scope.form.role.group === "store") {
            $scope.selectCompany(_.find($scope.companies, { _id: item.role.orgs[0] }))
          } else if ($scope.form.role.group === "agency") {
            $scope.selectedAgency = $scope.agencies.filter(
              (a) => item.role.orgs.indexOf(a._id) >= 0
            )
          }
          $scope.selectedRole = item.role.group
          delete $scope.form.createdAt
          $scope.form_create.$setDirty()
          $scope.errorMessage = ""
        } else {
          $scope.inputDlgTitle = "Create"
          $scope.revert()
        }

        return (original = _.cloneDeep($scope.form))
      }

      $scope.removeItem = function (item) {
        if (!$window.confirm(Locale.string("msg_confirm_remove"))) {
          return
        }
        //INFO: after removing account, it is automatically removed from all account group
        return AccountSrv.delete(
          { id: item._id },
          function (sobj, res) {
            $scope.reload(false)
            return $scope.$broadcast("reload_accountgroup")
          },

          function (error) {
            if (error.data != null) {
              return ($scope.errorMessage = error.data)
            }
          }
        )
      }

      $scope.reload = function (isCache) {
        if (isCache == null) {
          isCache = true
        }
        return async.series(
          [
            (callback) =>
              ApiSrv.getAllSpace().then(function (res) {
                $scope.spaces = _.map(res, (element) => ({
                  _id: element._id,
                  name: element.name,
                }))
                return callback(null)
              }),
            (callback) =>
              ApiSrv.getAllCompany().then(function (res) {
                $scope.companies = _.map(res, (element) => ({
                  _id: element._id,
                  name: element.name,
                  active: element.active,
                  _agencyId: element._agencyId,
                }))
                return callback(null)
              }),
            (callback) =>
              ApiSrv.getAllAgency().then(function (res) {
                $scope.agencies = _.map(res, (element) => ({
                  _id: element._id,
                  name: element.name,
                  active: element.active,
                  policy: element.policy,
                  default: element.default,
                }))
                return callback(null)
              }),
            (callback) =>
              ApiSrv.getAllAccountGroup().then(function (res) {
                $scope.allAccountGroup = _.map(res, (element) => ({
                  _id: element._id,
                  name: element.name,
                  companyId: element._companyId,
                  accountList: element.accountList,
                }))
                return callback(null)
              }),
          ],
          function (err, results) {
            $scope.hasWAccountTable = {}
            $scope.hasDAccountTable = {}
            return ApiSrv.getAllAccount({ isCache: isCache }).then(function (res) {
              const accountList = []
              return HeaderSrv.fetchCurrentCompany()
                .then(function (company) {
                  for (var account of Array.from(res)) {
                    var addListCondition = $scope.showSelectedOrganization === false
                    switch (account.role.group) {
                      case "company":
                      case "store":
                        account.affiliations = $scope.companies.filter(
                          (c) => account.role.orgs.indexOf(c._id) >= 0
                        )
                        if (!_.isEmpty(account.affiliations)) {
                          account.affiliationsRole = "company"
                        }
                        addListCondition = addListCondition || company._id === account.role.orgs[0]
                        break
                      case "agency":
                        account.affiliations = $scope.agencies.filter(
                          (a) => account.role.orgs.indexOf(a._id) >= 0
                        )
                        if (!_.isEmpty(account.affiliations)) {
                          account.affiliationsRole = "agency"
                        }
                        break
                      case "space":
                        account.affiliations = $scope.spaces.filter(
                          (s) => account.role.orgs.indexOf(s._id) >= 0
                        )
                        if (!_.isEmpty(account.affiliations)) {
                          account.affiliationsRole = "space"
                        }
                        break
                      case "dev_admin":
                        account.affiliations = [{ name: Locale.string("developer") }]
                        account.affiliationsRole = "dev_admin"
                        break
                      default:
                        account.affiliations = []
                        account.affiliationsRole = ""
                    }
                    account.affiliations = account.affiliations.map((a) => a.name).join(", ")
                    account.name = Locale.fullName(account)
                    if (addListCondition) {
                      accountList.push(account)
                    }
                  }
                  return Promise.each(res, (account) =>
                    me
                      .hasWAccount(account)
                      .then(function (bool) {
                        $scope.hasWAccountTable[account._id] = bool
                        return me.hasDAccount(account)
                      })
                      .then((bool) => ($scope.hasDAccountTable[account._id] = bool))
                      .then(() => loadItems(accountList))
                  )
                })
                .catch((err) => console.error(err))
            })
          }
        )
      }

      var loadItems = function (items) {
        $scope.Items = items
        const _getData = function ($defer, params) {
          const sorting = $scope.row
          const count = params.count()
          $scope.pagecount = count

          const roleItems = $filter("filter")($scope.Items, { role: { group: $scope.currentRole } })
          $scope.filterItems = $filter("filter")(roleItems, function (val) {
            const str = new RegExp(`.*${$scope.searchKeywords}.*`, "i")
            return (
              (val.affiliations && val.affiliations.match(str)) ||
              (val.firstName && val.firstName.match(str)) ||
              (val.lastName && val.lastName.match(str)) ||
              (val.email && val.email.match(str))
            )
          })

          params.total($scope.filterItems.length)
          const orderItems = $filter("orderBy")($scope.filterItems, sorting)

          const start = (params.page() - 1) * params.count()
          const end = start + params.count()
          $scope.currentPageItems = orderItems.slice(start, end)
          return $defer.resolve($scope.currentPageItems)
        }

        return ($scope.tableParams = new NgTableParams(
          { count: $scope.pagecount != null ? $scope.pagecount : 25 },
          { counts: [10, 25, 50, 100], getData: _getData }
        ))
      }

      // filter
      $scope.searchKeywords = ""
      $scope.row = "company.name"
      $scope.currentRole = ""

      $scope.enterKeyword = function (keyword) {
        $scope.searchKeywords = keyword
        return loadItems($scope.Items)
      }

      $scope.selectRole = function (role) {
        $scope.currentRole = role
        return loadItems($scope.Items)
      }

      $scope.order = function (rowName) {
        if ($scope.currentPageItems.length < 1) {
          return
        }
        $scope.row = rowName
        return loadItems($scope.Items)
      }

      $scope.getRoleName = function (type) {
        switch (type) {
          case Account.DEV_ADMIN:
            return Locale.string("developer")
          case Account.SPACE:
            return Locale.string("system")
          case Account.AGENCY:
          case Account.COMPANY:
          case Account.STORE:
            return Locale.string(type)
          default:
            return Locale.string("store")
        }
      }

      $scope.roleGroup = ""

      $scope.roleMarkConfigList = [
        [Account.DEV_ADMIN, "ti"],
        [Account.SPACE, "ti"],
        [Account.AGENCY, "ti"],
        [Account.COMPANY, "ti"],
        [Account.STORE, "ti"],
      ]

      // start reload account
      return $scope.reload()
    }
  )
