/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

angular
  .module("uCountitUiApp")

  .factory(
    "IotAdminSrv",
    function (
      $rootScope,
      $resource,
      $q,
      $timeout,
      $window,
      CameraAdminSrv,
      ApiSrv,
      $cacheFactory,
      Locale
    ) {
      const cache = $cacheFactory("iotApiCache")
      const iotdeviceurl = $rootScope.globalConfig.apipath + "/iot/device"
      const iotgroupurl = $rootScope.globalConfig.apipath + "/iot/ucountit"

      const _AllDevice = () => $resource(iotdeviceurl + "/allinfo")

      const Device = function (node) {
        const path = iotdeviceurl + "/:uid" + (node != null ? `/${node}` : "")
        return $resource(path, {}, { update: { method: "PUT" } })
      }

      const Group = function (node) {
        const path = iotgroupurl + "/:uid" + (node != null ? `/${node}` : "")
        return $resource(path, {}, { update: { method: "PUT" } })
      }

      const getAllDevice = function (options) {
        const deffered = $q.defer()
        const params = _.clone(options || {})
        delete params["isCache"]
        const cacheId = "getAllDevice-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        params._ = Date.now()
        _AllDevice().get(
          params,
          function (res) {
            cache.put(cacheId, res.data)
            return deffered.resolve(res.data)
          },
          (err) => deffered.reject(err)
        )
        return deffered.promise
      }

      const getAllFloatingDevice = function (options) {
        const deffered = $q.defer()
        $resource(`${iotdeviceurl}/allfloating`, {}, { get: { method: "GET", isArray: true } }).get(
          options,
          (res) => deffered.resolve(res)
        )
        return deffered.promise
      }

      const checkLicense = async function (item, timeout, maxretry) {
        if (timeout == null) {
          timeout = 5000
        }
        if (maxretry == null) {
          maxretry = 5
        }
        var _Try = function (trycnt) {
          if (trycnt > maxretry) {
            throw new Error("getLicenseRetry fails by maxretry")
          }

          return $timeout(
            () => {
              return Group(`license/refresh`)
                .get({ uid: item.uid, _: Date.now() })
                .$promise.then(() =>
                  Device()
                    .get({ uid: item.uid, _: Date.now() })
                    .$promise.then((res) => {
                      return res.data.licenses
                    })
                )
            },
            (_err) => _Try(trycnt + 1),
            timeout
          )
        }

        return _Try(0)
      }

      const getAllCameraDataLog = function (camera, force, within) {
        if (force == null) {
          force = false
        }
        if (within == null) {
          within = 48
        }
        const deferred = $q.defer()

        if (!force && camera.data != null) {
          deferred.resolve(camera.data)
          return deferred.promise
        }

        const startDate = moment()
          .utcOffset(camera.offset)
          .subtract(within, "hour")
          .format("YYYYMMDDHH00")

        const _getCounterData = function (callback) {
          const opt = {
            id: camera._id,
            from: startDate,
            sampling: "1h",
            entrance: "all",
            isCache: true,
          }

          if (
            !camera.registered ||
            !camera._id ||
            !(camera.functionalities != null ? camera.functionalities.counter : undefined)
          ) {
            return callback(null, [])
          }

          return ApiSrv.getCountOfCamera(opt).then(
            (res) => callback(null, res.data),
            (err) => callback(null, [])
          )
        }

        const _getHeatmapData = function (callback) {
          const opt = {
            id: camera._id,
            from: startDate,
            sampling: 3600,
            isCache: true,
          }

          if (
            !camera.registered ||
            !camera._id ||
            !(camera.functionalities != null ? camera.functionalities.heatmap : undefined)
          ) {
            return callback(null, [])
          }

          return ApiSrv.getHeatmapHistogramOfCamera(opt).then(
            (res) => callback(null, res),
            (err) => callback(null, [])
          )
        }

        async.parallel(
          {
            counter: _getCounterData,
            heatmap: _getHeatmapData,
          },
          (err, result) => deferred.resolve((camera.data = result))
        )

        return deferred.promise
      }

      const getFOTAPacakges = function (dtype) {
        const url = `${$rootScope.globalConfig.apipath}/fota/packages/${dtype}`
        return $resource(url)
          .get({ _: Date.now() })
          .$promise.then((res) => ({
            defaultVersion: res != null ? res.defaultVersion : undefined,

            list: __guard__(res != null ? res.list : undefined, (x) =>
              x.map((l) => ({
                version: l.name,
                desc:
                  (l.meta != null ? l.meta.desc : undefined) != null
                    ? l.meta != null
                      ? l.meta.desc
                      : undefined
                    : "",
              }))
            ),
          }))
      }

      const getAllFOTAInfo = function (qry) {
        if (qry == null) {
          qry = {}
        }
        const url = `${$rootScope.globalConfig.apipath}/fota/sensors`
        return $resource(url, qry, { get: { method: "GET", isArray: true } })
          .get({ _: Date.now() })
          .$promise.then((res) => res)
          .catch(function (ex) {
            console.warn("no fota sensor list")
            return []
          })
      }

      const rFOTA = $resource(
        `${$rootScope.globalConfig.apipath}/fota/sensors/:accessKey`,
        { accessKey: "@accessKey" },
        { update: { method: "PUT" } }
      )

      const getFOTAInfo = function (accessKey, heartbeat) {
        if (heartbeat == null) {
          heartbeat = false
        }
        return rFOTA.get({ accessKey, heartbeat, _: Date.now() }).$promise.then(function (res) {
          delete res._id
          return res
        })
      }

      const updateFOTAInfo = (accessKey, query) => rFOTA.update({ accessKey }, query).$promise

      const installBaseFOTA = function (accessKey, deviceType, fotahost) {
        if (fotahost == null) {
          fotahost = "iot-dev.retailanalysis.cloud"
        }
        let cmd = `curl -skL https://${fotahost}/fota/public/start_fota.sh | bash - > /dev/null &`
        if (deviceType.toLowerCase() === "ipm") {
          cmd =
            "cd /mnt/plugin/earlgrey && curl -skL https://iot-dev.retailanalysis.cloud/fota/public/start_fota.sh | ./bash - > /dev/null &"
        }
        const query = {
          uid: accessKey,
          command: cmd,
          _: Date.now(),
        }
        return Group("console").update(query, {}).$promise
      }

      const rBlackList = $resource(
        `${$rootScope.globalConfig.apipath}/blacklist/:accessKey`,
        { accessKey: "@accessKey" },
        { update: { method: "PUT" } }
      )

      const getAllBlacklist = function (options) {
        const cacheId = "getAllBlacklist"
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          return Promise.resolve(cacheData)
        }

        return rBlackList
          .query({})
          .$promise.catch((err) => [])
          .then(function (data) {
            cache.put(cacheId, data)
            return data
          })
      }

      const addBlacklist = (accessKey) => rBlackList.save({ accessKey }, {}).$promise

      const removeBlacklist = (accessKey) => rBlackList.delete({ accessKey }, {}).$promise

      /**
       * callback(err: Error)
       *
       */
      const removeLicense = async function (item, options) {
        const lc = item.licenses.find((lc) => lc)
        const id = lc ? lc.id : null

        if (id == null) {
          throw new new Error("No VCA License")()
        }

        return Group("license")
          .remove({ uid: item.uid, licenseid: id, actor: options.actor })
          .$promise.then((_res) => {
            return checkLicense(item, null, null)
          })
      }

      const installLicense = async function (item, options) {
        return Group("license")
          .save({ uid: item.uid, actor: options?.actor }, {})
          .$promise.then((res) => {
            if (res.error ?? res.message) {
              throw new Error(res.error ?? res.message)
            }
          })
      }

      return {
        Device,
        Group,
        getAllDevice,
        getAllFloatingDevice,
        checkLicense,
        getAllCameraDataLog,
        getFOTAPacakges,
        getAllFOTAInfo,
        getFOTAInfo,
        updateFOTAInfo,
        installBaseFOTA,
        getAllBlacklist,
        addBlacklist,
        removeBlacklist,
        removeLicense,
        installLicense,
      }
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
