/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

const prefixify = function (hostname: string, prefix?: string) {
  if (hostname === "localhost") {
    return hostname
  } else if (prefix) {
    return `${prefix}-${hostname}`
  } else {
    return hostname
  }
}

export const createApiUrls = (hostname: string) => ({
  dburl: "http://" + prefixify(hostname) + ":19501/local-api/1.0",
  ioturl: "http://" + prefixify(hostname) + ":19503/local-api/1.0/iot",
  queueurl: "http://" + prefixify(hostname, "q") + ":19581/api/v1",
  trackurl: "http://" + prefixify(hostname, "trackio") + ":19521/api/v1",
  faceurl: "http://" + prefixify(hostname, "io") + ":19507/api/v1",
  syncurl: "http://" + prefixify(hostname) + ":19508/api/v1",
  openapiurl: "http://" + prefixify(hostname) + ":19505",
  exportdataurl: "http://" + prefixify(hostname) + ":19510/api/v1",
  fotaurl: "http://" + prefixify(hostname) + ":19531/api/v1",
  arbiterurl: "http://" + prefixify(hostname) + ":19511/api/v1",
  retracheurl: `http://${prefixify(hostname)}:19541/api/v1`,
  mseaurl: `http://${prefixify(hostname)}:19551/api/v1`,
  metarealtimeurl: `http://${prefixify(hostname)}:19571/api/v1`,
  rebuildurl: `http://${prefixify(hostname)}:19620/api/v1`,
})

export type ApiUrls = ReturnType<typeof createApiUrls>
