/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS104: Avoid inline assignments
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import Chart from "chart.js"
import alasql from "alasql"

angular
  .module("uCountitUiApp")
  .controller(
    "GenderAgeSearchCtrl",
    function (
      $scope,
      $state,
      $stateParams,
      ApiSrv,
      ChartOptionSrv,
      Locale,
      NgTableParams,
      SamplingSrv,
      SelectorSrv,
      uiGridExporterService,
      Face,
      HeaderSrv,
      SeedCounter
    ) {
      let sampling
      let currentCompany = {}
      let currentStore = {}
      let currentSensor = {}
      let seedCounterList = []
      $scope.seedCounterName = ""
      const SAMPLING = {
        HOUR: "hour",
        DAY: "day",
      }
      // MONTH: 'month'

      $scope.PAGE_MAX_SIZE = 3
      $scope.CHARTTYPE = {
        COUNT: "count",
        RATE: "rate",
      }
      $scope.ageTypeList = []
      $scope.columns = [{ title: Locale.string("Date"), sortable: "isoDate", subtitle: [] }]
      $scope.totalColumnIdx = 0

      $scope.selectedChartType = $scope.CHARTTYPE.RATE

      // initData
      $scope.gridData = []
      $scope.genderAgeChart = null
      $scope.isNoData = true
      $scope.total = {}
      $scope.avg = {}

      $scope.currentPage = 1
      $scope.diffDays = 2
      $scope.chartOption = _.merge({}, ChartOptionSrv.barChartOption, {
        series: {
          stack: true,
          bars: {
            align: "center",
          },
        },
      })
      $scope.showStayTime = false

      $scope.samplingList = (() => {
        const result = []
        for (var key in SAMPLING) {
          sampling = SAMPLING[key]
          result.push({ key: sampling, sampling, title: `By ${sampling}` })
        }
        return result
      })()
      $scope.shortTermList = ["24H", "7D"] // , '6M', '12M'

      $scope.validTime = false

      $scope.pageOptions = {
        sampling: SAMPLING.DAY,
        content: "gender age",
      }

      const kMinAge = 9
      const kMaxAge = 80

      $scope.useFaceCountOnly = undefined
      $scope.timezone = undefined

      const isStartOfWeek = (date) =>
        Locale.dateTime(moment(date), "dayOfWeek", "en-us").toLowerCase() ===
          Locale.firstDayOfWeek() && $scope.pageOptions.sampling === "day"

      const init = () =>
        HeaderSrv.fetchCurrentCompany()
          .then(function (company) {
            currentCompany = company
            $scope.ageTypeList = Face.makeAgeTypeList(company.ageGroup.value, true)
            $scope.columns = $scope.columns.concat(
              $scope.ageTypeList.map((row) => ({
                title: row.text,
                subtitle: Face.genderList,
              }))
            )
            $scope.columns.push({
              title: Locale.string("header_total"),
              subtitle: Face.genderList.concat({ name: "sum", text: "Sum", short: "Sum" }),
            })
            $scope.totalColumnIdx = $scope.columns.length - 1

            return
          })
          .then(() => SelectorSrv.getOrganizationInfo($stateParams.orgType, $stateParams.id))
          .then(function (orgInfo) {
            currentStore = orgInfo.store
            currentSensor = orgInfo.sensor
            if ($stateParams.orgType === ["store", "sensor"].includes($stateParams.orgType)) {
              seedCounterList = SeedCounter.getList(currentStore.labels)

              const currentOrg = currentSensor || currentStore
              $scope.seedCounterName =
                _.find(seedCounterList, ["value", currentOrg.seedCounterOfGA])?.name ?? ""
            }
            $scope.useFaceCountOnly = orgInfo.useFaceCountOnly
            $scope.timezone = orgInfo.timezone
            $scope.showStayTime =
              $stateParams.orgType == "store" && currentStore?.features?.pathtree
          })
          .then(() => $scope.$broadcast("init-range-search", $scope.timezone))
          .catch(function (err) {
            console.error(err)
            return $state.go("default")
          })

      const getExportPeriod = function () {
        const { dt } = $scope.pageOptions
        return `${dt.startDate.format("YYYYMMDD")}_${dt.endDate.format("YYYYMMDD")}`
      }

      $scope.export = function (type) {
        const { sampling } = $scope.pageOptions
        let filename
        switch ($stateParams.orgType) {
          case "company":
            filename = `[age ${$scope.selectedChartType}]_[${
              currentCompany.name
            }]_[${getExportPeriod()}]_[${sampling}]`
            break
          case "store":
            filename = `[age ${$scope.selectedChartType}]_[${currentCompany.name}]_[${
              currentStore.name
            }]_[${getExportPeriod()}]_[${sampling}]`
            break
          case "sensor":
            filename = `[age ${$scope.selectedChartType}]_[${currentCompany.name}]_[${
              currentStore.name
            }]_[${currentSensor.name}]_[${getExportPeriod()}]_[${sampling}]`

            break
        }

        let headerItems = ["Date"]
        $scope.ageTypeList.forEach(function (ageType) {
          headerItems.push(`${ageType.text}(F)`)
          if ($scope.showStayTime) headerItems.push(`${ageType.text}(F:stay)`)
          headerItems.push(`${ageType.text}(M)`)
          if ($scope.showStayTime) headerItems.push(`${ageType.text}(M:stay)`)
        })
        if ($scope.showStayTime) {
          headerItems = headerItems.concat([
            "Total(F)",
            "Total(F:stay)",
            "Total(M)",
            "Total(M:stay)",
            "Total(Sum)",
            "Total(Sum:stay)",
          ])
        } else {
          headerItems = headerItems.concat(["Total(F)", "Total(M)", "Total(Sum)"])
        }

        let items = []
        _.forEach($scope.gridData, function (row) {
          let item = [row.isoDate]
          _.forEach($scope.ageTypeList, function (ageType, idx) {
            item.push(row[ageType.name].female)
            if ($scope.showStayTime) item.push($scope.getRowStayTime(row, idx + 1, "female"))
            item.push(row[ageType.name].male)
            if ($scope.showStayTime) item.push($scope.getRowStayTime(row, idx + 1, "male"))
          })
          item.push(row.total.female)
          if ($scope.showStayTime)
            item.push($scope.getRowStayTime(row, $scope.ageTypeList.length + 1, "female"))
          item.push(row.total.male)
          if ($scope.showStayTime)
            item.push($scope.getRowStayTime(row, $scope.ageTypeList.length + 1, "male"))
          item.push(row.total.sum)
          if ($scope.showStayTime)
            item.push($scope.getRowStayTime(row, $scope.ageTypeList.length + 1, "sum"))
          items.push(item)
        })

        switch (type) {
          case "csv":
            var csvData = ""
            csvData += headerItems.join(",") + "\n"
            csvData += items.map((item) => item.join(",")).join("\n")
            return uiGridExporterService.downloadFile(filename + ".csv", csvData, true)
          case "excel":
            var headerInfo = {}
            headerItems.forEach((hi) => (headerInfo[hi.replace(/[():]/g, "")] = hi))

            var bodyInfo = items.map((item) => {
              const rowData = {}
              item.forEach((d, idx) => {
                rowData[headerItems[idx].replace(/[():]/g, "")] = d
              })
              return rowData
            })
            var excelData = [headerInfo].concat(bodyInfo)
            return alasql(`SELECT * INTO XLSX("'${filename}'",{headers: false}) FROM ?`, [
              excelData,
            ])
          default:
            return false
        }
      }

      $scope.exportAll = function (type) {
        const { sampling } = $scope.pageOptions
        let filename
        switch ($stateParams.orgType) {
          case "company":
            filename = `[ages raw}]_[${currentCompany.name}]_[${getExportPeriod()}]_[${sampling}]`
            break

          case "store":
            filename = `[ages raw}]_[${currentCompany.name}]_[${
              currentStore.name
            }]_[${getExportPeriod()}]_[${sampling}]`
            break

          case "sensor":
            filename = `[ages raw}]_[${currentCompany.name}]_[${currentStore.name}]_[${
              currentSensor.name
            }]_[${getExportPeriod()}]_[${sampling}]`
            break
        }

        const options = requestOptions()

        return Face.getAgeData(options, $scope.useFaceCountOnly).then(function (ageData) {
          let asc, end, j
          let i
          switch (type) {
            case "csv":
              var csvData =
                ["Date", "Gender"].concat(__range__(kMinAge, kMaxAge, true)).join(",") + "\n"
              _.forEach(ageData, function (row) {
                const date = SamplingSrv[sampling].getIsoDayDate(moment.utc(row.statisticFor))
                csvData += [date, "F"].concat(row.agesFemale.slice(kMinAge)).join(",") + "\n"
                return (csvData += [date, "M"].concat(row.agesMale.slice(kMinAge)).join(",") + "\n")
              })
              filename += ".csv"
              return uiGridExporterService.downloadFile(filename, csvData, true)
            case "excel":
              filename += ".xlsx"
              var headerInfo = {
                date: "Date",
                gender: "Gender",
              }
              for (
                j = kMinAge, i = j, end = kMaxAge, asc = kMinAge <= end;
                asc ? j <= end : j >= end;
                asc ? j++ : j--, i = j
              ) {
                headerInfo[`age${i}`] = i
              }

              var excelData = [headerInfo]
              _.forEach(ageData, function (row) {
                let asc1, end1
                let asc2, end2
                const date = SamplingSrv[sampling].getIsoDayDate(moment.utc(row.statisticFor))
                const female = { date, gender: "F" }
                for (
                  i = kMinAge, end1 = kMaxAge, asc1 = kMinAge <= end1;
                  asc1 ? i <= end1 : i >= end1;
                  asc1 ? i++ : i--
                ) {
                  female[`age${i}`] = row.agesFemale[i]
                }
                excelData.push(female)

                const male = { date, gender: "M" }
                for (
                  i = kMinAge, end2 = kMaxAge, asc2 = kMinAge <= end2;
                  asc2 ? i <= end2 : i >= end2;
                  asc2 ? i++ : i--
                ) {
                  male[`age${i}`] = row.agesMale[i]
                }
                return excelData.push(male)
              })

              return alasql('SELECT * INTO XLSX("' + filename + '",{headers: false}) FROM ?', [
                excelData,
              ])
            default:
              return false
          }
        })
      }

      const tickFormatterFunc = function (val, axis) {
        ;({ sampling } = $scope.pageOptions)
        const date = moment.utc(val)
        const { dt } = $scope.pageOptions
        if (dt.startDate.isAfter(date) || dt.endDate.isBefore(date)) {
          return ""
        } else {
          return Locale.dateTime(date, SamplingSrv[sampling].getTickFormat(val))
        }
      }

      const initData = function () {
        $scope.gridData = []
        $scope.tableParams = new NgTableParams({}, { data: [] })
        for (var ageType of Array.from($scope.ageTypeList)) {
          ageType.total = { female: 0, male: 0 }
          ageType.avgTotal = { female: 0, male: 0 }
          ageType.avg = { female: 0, male: 0 }
        }
        if ($scope.genderAgeChart) {
          $scope.genderAgeChart.destroy()
          $scope.genderAgeChart = null
        }
        $scope.isNoData = true
        $scope.total = { female: 0, male: 0, sum: 0 }
        $scope.avg = { female: 0, male: 0, sum: 0 }

        return
      }

      var requestOptions = function () {
        const dt = angular.copy($scope.pageOptions.dt)
        ;({ sampling } = $scope.pageOptions)

        const diffDays = dt.endDate.diff(dt.startDate, "days")
        const datetime = moment(dt.startDate).endOf("day").format("YYYY-MM-DDTHH:00:00")
        const cacheTime = moment().add(1, "hours").startOf("hour").format()
        const end = moment(dt.endDate).add(1, "days")
        const start = moment(dt.endDate).subtract(diffDays, "days").startOf("day")

        return {
          id: $stateParams.id,
          datetime,
          cacheTime,
          from: start,
          to: end,
          selectedSampling: sampling,
          orgType: $stateParams.orgType,
        }
      }

      const isEmptyRow = function (dataSet) {
        let result = true

        _.forEach(dataSet, function (data) {
          if (!_.isNull(data.female) || !_.isNull(data.male)) {
            //if dataSet is all zero, the row is considered empty
            if (data.female != 0 || data.male != 0) {
              result = false
            }
          }
        })

        return result
      }

      const initChartDataSet = (ageType, gender) => ({
        type: "bar",
        stack: gender,
        backgroundColor: ageType.color[gender],
        data: [],
        name: `${ageType.name}-${gender}`,
        label: ageType.text,
        barPercentage: 0.8,
        categoryPercentage: 0.5,
      })

      const convertChartValue = function (data) {
        switch (data) {
          case null:
            return 0
          case "-":
            return 0
          default:
            return data
        }
      }

      const makeGroupdStackBarChart = function (chartData) {
        let unit, yMax
        ;({ sampling } = $scope.pageOptions)
        const { dt } = $scope.pageOptions
        if ($scope.selectedChartType === $scope.CHARTTYPE.RATE) {
          unit = "%"
          yMax = 100
        } else {
          unit = ""
          ;({ yMax: undefined })
        }
        const tooltipDateFormat = SamplingSrv[sampling].getFormat()
        const tickSize = SamplingSrv[sampling].getTickSize(dt.startDate, dt.endDate)

        const _getAgeGroupTooltipValue = function (data, unit) {
          if (data == null) {
            return ""
          }

          if (data !== "-") {
            data += unit
          }
          return data
        }

        const _tooltipTitleCallback = function (tooltipItem, data) {
          const datetime = Locale.dateTime(moment.utc(tooltipItem[0].xLabel), tooltipDateFormat)
          const stackName = Locale.string(data.datasets[tooltipItem[0].datasetIndex].stack)
          return `${datetime}(${stackName})`
        }

        const _tooltipLabelCallback = function (tooltipItem, data) {
          const title = data.datasets[tooltipItem.datasetIndex].label
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

          return `${title} : ${_getAgeGroupTooltipValue(value, unit)}`
        }

        const _isVisibleTick = function (label, index) {
          if (index % tickSize === 0) {
            return true
          } else {
            return false
          }
        }

        const _customTooltips = function (tooltip) {
          let tooltipEl = document.getElementById("chartjs-tooltip")
          const chartEl = document.getElementById("genderAgeChart")

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement("div")
            tooltipEl.id = "chartjs-tooltip"
            tooltipEl.innerHTML = "<table></table>"
            chartEl.parentNode.appendChild(tooltipEl)
          }

          // Hide if no tooltip
          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0
            return
          }

          // Set caret Position
          tooltipEl.classList.remove("above", "below", "no-transform")
          if (tooltip.yAlign) {
            tooltipEl.classList.add(tooltip.yAlign)
          } else {
            tooltipEl.classList.add("no-transform")
          }

          const _getBody = (bodyItem) => bodyItem.lines

          // Set Text
          if (tooltip.body) {
            const titleLines = tooltip.title || []
            const bodyLines = tooltip.body.map(_getBody)
            let innerHtml = "<thead>"
            titleLines.forEach((title) => (innerHtml += "<tr><th>" + title + "</th></tr>"))
            innerHtml += "</thead><tbody>"
            bodyLines.forEach(function (body, i) {
              const colors = tooltip.labelColors[i]
              let style = "background:" + colors.backgroundColor
              style += "; border-color:" + colors.borderColor
              style += "; border-width: 2px"
              const span = '<span class="chartjs-tooltip-key" style="' + style + '"></span>'
              return (innerHtml += "<tr><td>" + span + body + "</td></tr>")
            })
            innerHtml += "</tbody>"

            const tableRoot = tooltipEl.querySelector("table")
            tableRoot.innerHTML = innerHtml
          }

          const positionY = chartEl.offsetTop
          const positionX = chartEl.offsetLeft
          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1
          tooltipEl.style.left = positionX + tooltip.caretX + "px"
          tooltipEl.style.top = positionY + tooltip.caretY + "px"
          tooltipEl.style.fontFamily = tooltip._bodyFontFamily
          tooltipEl.style.fontSize = tooltip.bodyFontSize + "px"
          tooltipEl.style.fontStyle = tooltip._bodyFontStyle
          return (tooltipEl.style.padding = tooltip.yPadding + "px " + tooltip.xPadding + "px")
        }

        const ctx = document.getElementById("genderAgeChart")
        if ($scope.genderAgeChart) {
          $scope.genderAgeChart.destroy()
        }

        $scope.genderAgeChart = new Chart(ctx, {
          type: "bar",
          data: chartData,
          options: {
            maintainAspectRatio: false,
            legend: { display: false },
            scales: {
              xAxes: [
                {
                  stacked: true,
                  ticks: {
                    callback(label, index, labels) {
                      if (_isVisibleTick(label, index)) {
                        return tickFormatterFunc(label)
                      }
                    },
                  },
                  gridLines: { display: false },
                },
              ],
              yAxes: [
                {
                  stacked: true,
                  ticks: {
                    maxTicksLimit: 6,
                    max: yMax,
                    callback(label, index, labels) {
                      return `${label}${unit}`
                    },
                  },
                },
              ],
            },
            tooltips: {
              enabled: false,
              mode: "x",
              custom: _customTooltips,
              yAlign: "above",
              callbacks: {
                title: _tooltipTitleCallback,
                label: _tooltipLabelCallback,
              },
            },
          },
        })
      }

      const _getAverage = function (total, period) {
        if (_.isNil(total)) {
          return 0
        }

        return parseFloat((total / period).toFixed(1))
      }

      const _add = function (v1, v2, digits = 0) {
        return +(v1 + v2).toFixed(digits)
      }

      $scope.getRowStayTime = function (row, colIdx, subkey) {
        // row.iosDate : "2024-08-29(Thu)"
        // colIdx: 0:isoDate, 1~n-1: ageGroupIndex, n: total
        // subkey: female, male
        let stayTime = $scope.stayTableData?.[row.isoDate]?.[colIdx]?.[subkey]
        // console.log("getRowStayTime", row.isoDate, colIdx, subkey, stayTime)
        if (isNaN(stayTime)) {
          return "-"
        }
        return Locale.duration(stayTime) // "01m 12s"
      }

      const createStayDateTable = function (stayData) {
        let tableData = {}
        if (!stayData?.data?.length) {
          return tableData
        }
        stayData?.data.forEach((d) => {
          const date = moment(d.at, "YYYYMMDDHHmm")
          const isoDate = SamplingSrv[$scope.pageOptions.sampling].getIsoDayDate(date)
          const ageGroupData = d.data.ageGroup.map((g, idx) => {
            return {
              female: g.female.stayTime,
              male: g.male.stayTime,
              sum: g.stayTime,
            }
          })
          const totalData = {
            female: d.data.female.stayTime,
            male: d.data.male.stayTime,
            sum: d.data.stayTime,
          }
          tableData[isoDate] = [isoDate, ...ageGroupData, totalData]
        })

        let avgData = [
          "average",
          ...$scope.ageTypeList.map((d, idx) => {
            const g = stayData?.average.ageGroup[idx]
            return {
              female: g.female.stayTime,
              male: g.male.stayTime,
              sum: g.stayTime,
            }
          }),
          {
            female: stayData?.average.female.stayTime,
            male: stayData?.average.male.stayTime,
            sum: stayData?.average.stayTime,
          },
        ]
        tableData["average"] = avgData
        return tableData
      }

      const getAllData = function (mode) {
        const { sampling } = $scope.pageOptions
        const options = requestOptions()
        const faceCountOnly = mode == "visits" ? false : $scope.useFaceCountOnly
        const ageGroupPromise = Face.getAgeGroupData(
          options,
          $scope.ageTypeList,
          faceCountOnly,
          true
        )

        let stayPromise = Promise.resolve()
        // INFO stay data는 store && day일 경우만 사용한다.
        if ($scope.showStayTime && sampling === SAMPLING.DAY) {
          const requestDateFormat = "YYYYMMDD0000"
          const opt = {
            orgType: $stateParams.orgType,
            id: $stateParams.id,
            sampling: "1d",
            from: moment(options.from).format(requestDateFormat),
            to: moment(options.to).format(requestDateFormat),
          }
          stayPromise = ApiSrv.getPathTreeStayData(opt)
        }
        return Promise.all([ageGroupPromise, stayPromise])
      }

      const getVisitsData = function () {
        const { sampling } = $scope.pageOptions

        getAllData("visits").then(function ([ageData, stayData]) {
          let ageType
          initData()
          if (!(ageData != null ? ageData.length : undefined) || $scope.useFaceCountOnly) {
            return
          }

          $scope.stayTableData = createStayDateTable(stayData)

          $scope.isNoData = false
          const chartData = { labels: [], datasets: [] }
          const gridData = []

          for (ageType of Array.from($scope.ageTypeList)) {
            chartData.datasets.push(initChartDataSet(ageType, "female"))
            chartData.datasets.push(initChartDataSet(ageType, "male"))
          }

          for (
            let idx = 0, end = ageData.length, asc = 0 <= end;
            asc ? idx < end : idx > end;
            asc ? idx++ : idx--
          ) {
            var aData = ageData[idx]
            chartData.labels.push(aData.date)

            var counterObj = {}
            let totalAData = { female: 0, male: 0, sum: 0 }
            for (ageType of Array.from($scope.ageTypeList)) {
              counterObj[ageType.name] = aData[ageType.name]
              // female
              var femaleIdx = _.findIndex(chartData.datasets, ["name", `${ageType.name}-female`])
              var fValue = convertChartValue(counterObj[ageType.name].female)
              chartData.datasets[femaleIdx].data.push(fValue)
              ageType.total.female += fValue
              totalAData.female = _add(totalAData.female, fValue)
              // male
              var maleIdx = _.findIndex(chartData.datasets, ["name", `${ageType.name}-male`])
              var mValue = convertChartValue(counterObj[ageType.name].male)
              chartData.datasets[maleIdx].data.push(mValue)
              ageType.total.male += mValue
              totalAData.male = _add(totalAData.male, mValue)

              totalAData.sum = _add(totalAData.sum, fValue + mValue)
            }

            if (!isEmptyRow(counterObj)) {
              $scope.total.female = _add($scope.total.female, totalAData.female)
              $scope.total.male = _add($scope.total.male, totalAData.male)
              $scope.total.sum = _add($scope.total.sum, totalAData.sum)

              var gridItem = {
                isoDate: SamplingSrv[sampling].getIsoDayDate(aData.date),
                dateTime: SamplingSrv[sampling].getFormatDate(aData.date),
                isStartOfWeek: isStartOfWeek(aData.date),
              }
              gridItem = _.merge(gridItem, counterObj, { total: totalAData })
              gridData.push(gridItem)
            }
          }

          for (ageType of Array.from($scope.ageTypeList)) {
            ageType.avg.female = _getAverage(ageType.total.female, gridData.length)
            ageType.avg.male = _getAverage(ageType.total.male, gridData.length)
          }

          $scope.avg.female = _getAverage($scope.total.female, gridData.length)
          $scope.avg.male = _getAverage($scope.total.male, gridData.length)
          $scope.avg.sum = _getAverage($scope.total.sum, gridData.length)

          makeGroupdStackBarChart(chartData)
          $scope.gridData = gridData

          const pageRowsArr = SamplingSrv[sampling].getPageRowType()
          $scope.tableParams = new NgTableParams(
            { count: pageRowsArr[0] },
            { counts: pageRowsArr, data: gridData }
          )
        })
      }

      const getPercentData = function () {
        const { sampling } = $scope.pageOptions

        getAllData("percent").then(function ([ageData, stayData]) {
          initData()
          if (!ageData?.length) {
            return
          }
          $scope.stayTableData = createStayDateTable(stayData)

          $scope.isNoData = false
          const chartData = { labels: [], datasets: [] }
          const gridData = []

          for (let ageType of Array.from($scope.ageTypeList)) {
            chartData.datasets.push(initChartDataSet(ageType, "female"))
            chartData.datasets.push(initChartDataSet(ageType, "male"))
          }

          for (
            let idx = 0, end = ageData.length, asc = 0 <= end;
            asc ? idx < end : idx > end;
            asc ? idx++ : idx--
          ) {
            var aData = ageData[idx]
            chartData.labels.push(aData.date)
            var percentObj = {}
            let totalAData = { female: 0, male: 0, sum: 0 }
            for (let ageType of Array.from($scope.ageTypeList)) {
              percentObj[ageType.name] = aData.percent[ageType.name]
              // female
              var femaleIdx = _.findIndex(chartData.datasets, ["name", `${ageType.name}-female`])
              var fValue = convertChartValue(percentObj[ageType.name].female)
              chartData.datasets[femaleIdx].data.push(fValue)
              ageType.avgTotal.female += fValue
              totalAData.female = _add(totalAData.female, fValue, 1)
              // male
              var maleIdx = _.findIndex(chartData.datasets, ["name", `${ageType.name}-male`])
              var mValue = convertChartValue(percentObj[ageType.name].male)
              chartData.datasets[maleIdx].data.push(mValue)
              ageType.avgTotal.male += mValue
              totalAData.male = _add(totalAData.male, mValue, 1)

              totalAData.sum = _add(totalAData.sum, fValue + mValue, 1)
            }

            // Create NgTable Data
            if (!isEmptyRow(percentObj)) {
              //adjust percent value(ex: 99.9, 100.1)
              if (totalAData.female == 0 || totalAData.male == 0) {
                if (totalAData.female != 0)
                  totalAData.female = Math.round(totalAData.female / 10) * 10
                if (totalAData.male != 0) totalAData.male = Math.round(totalAData.male / 10) * 10
              }
              totalAData.sum = Math.round(totalAData.sum / 10) * 10

              $scope.total.female = _add($scope.total.female, totalAData.female, 1)
              $scope.total.male = _add($scope.total.male, totalAData.male, 1)
              $scope.total.sum = _add($scope.total.sum, totalAData.sum, 1)

              var gridItem = {
                isoDate: SamplingSrv[sampling].getIsoDayDate(aData.date),
                dateTime: SamplingSrv[sampling].getFormatDate(aData.date),
                isStartOfWeek: isStartOfWeek(aData.date),
              }
              gridItem = _.merge(gridItem, percentObj, { total: totalAData })
              gridData.push(gridItem)
            }
          }

          for (let ageType of Array.from($scope.ageTypeList)) {
            ageType.avg.female = _getAverage(ageType.avgTotal.female, gridData.length)
            ageType.avg.male = _getAverage(ageType.avgTotal.male, gridData.length)
          }

          $scope.avg.female = _getAverage($scope.total.female, gridData.length)
          $scope.avg.male = _getAverage($scope.total.male, gridData.length)
          $scope.avg.sum = _getAverage($scope.total.sum, gridData.length)

          makeGroupdStackBarChart(chartData)
          $scope.gridData = gridData

          const pageRowsArr = SamplingSrv[sampling].getPageRowType()
          $scope.tableParams = new NgTableParams(
            { count: pageRowsArr[0] },
            { counts: pageRowsArr, data: gridData }
          )
        })
      }

      const getAgeData = function () {
        if (!$scope.pageOptions.dt || !$scope.validTime) {
          return
        }

        if ($scope.selectedChartType === $scope.CHARTTYPE.COUNT) {
          $scope.avgUnitStr = Locale.string("visits")
          return getVisitsData()
        } else {
          $scope.avgUnitStr = Locale.string("rate")
          return getPercentData()
        }
      }

      $scope.reload = function (fromInit) {
        if (fromInit == null) {
          fromInit = false
        }
        if (fromInit) {
          $scope.validTime = true
        }
        return getAgeData()
      }

      $scope.getRowValue = (row, colIdx, subkey) => {
        if (colIdx == $scope.totalColumnIdx) {
          return row.total[`${subkey}`]
        } else {
          return row[`group-${colIdx - 1}`][`${subkey}`]
        }
      }

      $scope.getColumnWidth = function () {
        const count = $scope.ageTypeList.length * 2 + 3
        const width = 80 / count
        if (!Number.isFinite(width)) {
          return
        }

        return { width: `${width}%` }
      }

      return init()
    }
  )

function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
