import angular from "angular"
import { apipath } from "../initialize/config"
import createCrud from "../../lib/create-crud"
import Directory from "../../lib/directory"
import Company from "../../lib/org/company"
import StoreGroup from "../../lib/org/store-group"
import Store from "../../lib/org/store"
import Sensor from "../../lib/org/sensor"
import { default as _createAccount } from "../../../create-account"

const createAccount = ($http, createDataProvider) => {
  const AccountClass = _createAccount(createDataProvider)

  AccountClass.prototype.canAccessAllStores = function () {
    return this.Api.AccountGroup.index().then((accountGroups) => {
      let userGroup
      if (accountGroups) {
        userGroup = accountGroups.find((group) => {
          group.name == "Users"
        })
      }
      return !this.isStore() || userGroup
    })
  }

  return AccountClass
}

const createApi = (http) => {
  const Api = {}
  const Account = createAccount(http, () => Api)

  const indexQuery = (data, query) => {
    if (Object.keys(query).length <= 0) return data
    const ret = []
    for (const d of data) {
      let push = true
      for (const qk in query) {
        if (!(d[qk] == query[qk] || d[`_${qk}`] == query[qk])) {
          push = false
          break
        }
      }
      if (push) ret.push(d)
    }
    return ret
  }

  Api.invalidate = () => {
    Api["Account"] = new Directory({
      ...createCrud(http, `${apipath}/account`),
      indexQuery,
      map: (a) => new Account(a),
    })
    Api["AccountGroup"] = new Directory({
      ...createCrud(http, `${apipath}/account/group`),
      indexQuery,
    })
    Api["Agency"] = new Directory({
      ...createCrud(http, `${apipath}/agency`),
      indexQuery,
    })
    Api["Company"] = new Directory({
      ...createCrud(http, `${apipath}/company`),
      indexQuery,
      map: (c) => new Company(c, Api),
    })
    Api["Store"] = new Directory({
      ...createCrud(http, `${apipath}/store`),
      indexQuery,
      map: (s) => new Store(s, Api),
    })
    Api["StoreGroup"] = new Directory({
      ...createCrud(http, `${apipath}/store/group`),
      indexQuery,
      map: (sg) => new StoreGroup(sg, Api),
    })
    Api["Sensor"] = new Directory({
      ...createCrud(http, `${apipath}/camera`),
      indexQuery,
      map: (s) => new Sensor(s, Api),
    })
    Api["_AccountClass"] = Account
  }
  Api.invalidate()

  return Api
}

angular.module("uCountitUiApp").factory("RestApi", ["$http", createApi])
