/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS104: Avoid inline assignments
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

const ResizeObserver = require("resize-observer-polyfill").default

angular
  .module("uCountitUiApp")
  .directive("flotChart", ($compile, $timeout) => ({
    restrict: "A",

    scope: {
      data: "=",
      options: "=",
      animation: "=",
      object: "=",
      nodataString: "=",
    },

    link(scope, element, attrs) {
      let plot = null
      const initChart = function () {
        if (scope.animation === true) {
          $.plotAnimator(element, [], {})
        } else {
          $.plot(element, [], {})
        }

        plot = null
        return (scope.object = undefined)
      }

      const makeChart = function (data) {
        if (plot) {
          initChart()
        }

        if (!(data != null ? data.length : undefined)) {
          const noDataTemplate = [
            "<div class='no-data-container'>",
            "<div class='align-wrapper'>",
            "<div class='center-wrapper'>",
            `<span data-i18n='${
              scope.nodataString != null ? scope.nodataString : "no data"
            }'><span>`,
            "</div>",
            "</div>",
            "</div>",
          ].join("")

          const newElement = $compile(noDataTemplate)(scope)
          element.html(newElement)
          return
        }

        if (scope.animation === true) {
          plot = $.plotAnimator(element, data, scope.options)
        } else {
          plot = $.plot(element, data, scope.options)
        }
        if (scope.object !== undefined) {
          return (scope.object = plot)
        }
      }

      scope.$watch("data", function (newData) {
        if (!newData) {
          return
        }
        if (!element.width() || !element.height()) {
          return
        }

        return makeChart(newData)
      })

      const ro = new ResizeObserver((entries) =>
        (() => {
          const result = []
          for (var entry of Array.from(entries)) {
            if (entry && plot === null) {
              result.push(makeChart(scope.data))
            } else {
              result.push(undefined)
            }
          }
          return result
        })()
      )
      return ro.observe(element[0])
    },
  }))
  .directive("lineChart", ($compile, $timeout, $filter, SamplingSrv) => ({
    restrict: "A",

    scope: {
      data: "=",
      options: "=",
      animation: "=",
      useTooltip: "=",
      sampling: "=",
      object: "=",
      nodataString: "=",
    },

    link(scope, element, attrs) {
      let plot = null
      let latestIdx = null
      let pageX = 0

      /*
    getNextHalfTime = (momentObj, sampling) ->
      switch sampling
        when 'hour' then momentObj.add(30, 'minutes').startOf sampling
        when 'day' then momentObj.add(12, 'hours').startOf sampling
        when 'week' then momentObj.add(3, 'days').startOf sampling
        when 'month' then momentObj.add(15, 'days').startOf sampling
    */

      const getHalfSecond = function (sampling) {
        switch (sampling) {
          case "10m":
            return 30
          case "hour":
            return 30 * 60
          case "day":
            return 12 * 60 * 60
          case "week":
            return 3.5 * 24 * 60 * 60
          case "month":
            return 15 * 24 * 60 * 60
          default:
            return 0
        }
      }

      const updateLegend = function (pos) {
        let idx = null
        const sampling =
          scope.sampling || __guard__(_.first(scope.data), (x) => x.sampling) || "day"
        const axes = plot.getAxes()
        let tooltipContents = ""

        if (
          pos.x < axes.xaxis.min ||
          pos.x > axes.xaxis.max ||
          pos.y < axes.yaxis.min ||
          pos.y > axes.yaxis.max
        ) {
          clearChart()
          return
        }

        const dataset = plot.getData()
        const halfTime = getHalfSecond(sampling) * 1000

        _.forEach(dataset, function (series, seq) {
          let left
          if (_.isUndefined(idx) || _.isNull(idx)) {
            let end
            idx = _.findIndex(series.data, (raw) => raw[0].format("x") >= pos.x - halfTime)
            if (idx === 0 && series.data[1]) {
              if (pos.x >= (series.data[0][0] + series.data[1][0]) / 2) {
                idx = 1
              }
            }

            const currentDate = moment(series.data[idx][0])
            switch (sampling) {
              case "10m":
                tooltipContents = `<b>${SamplingSrv[sampling].getFormatDate(currentDate)}</b><br/>`
                break
              case "hour":
                tooltipContents = `<b>${SamplingSrv[sampling].getFormatDate(currentDate)}</b><br/>`
                break
              case "day":
                tooltipContents = `<b>${SamplingSrv[sampling].getFormatDate(currentDate)}</b><br/>`
                break
              case "week":
              case "month":
                if (idx === series.data.length - 1) {
                  end = moment(series.endDate).subtract(1, "days")
                } else {
                  end = moment(series.data[idx + 1][0]).subtract(1, "days")
                }
                tooltipContents = `<b>${SamplingSrv[sampling].getFormatDate(
                  currentDate
                )} - ${SamplingSrv[sampling].getFormatDate(end)}</b><br/>`
                break
            }
          }

          if (_.isUndefined(idx) || _.isNull(idx) || _.isEqual(idx, latestIdx)) {
            return
          }

          plot.unhighlight()
          for (
            let i = 0, end1 = dataset.length - 1, asc = 0 <= end1;
            asc ? i <= end1 : i >= end1;
            asc ? i++ : i--
          ) {
            plot.highlight(i, idx)
          }

          tooltipContents += ` <svg width=7 height=7><rect width=5 height=5 fill=${
            series.color
          }></svg> \
${series.label}: <b>${(left = $filter("number")(series.data[idx][1])) != null ? left : ""}</b><br/>`

          pageX = parseInt(series.xaxis.p2c(series.data[idx][0].format("x")))
          return
        })

        if (_.isEqual(idx, latestIdx)) {
          return
        }

        latestIdx = idx
        const tooltipPageX = pageX + parseInt(plot.offset().left)
        const tooltipPageY = parseInt(pos.pageY)
        return showTooltip(tooltipPageX, tooltipPageY, tooltipContents)
      }

      var clearChart = function () {
        if (scope.useTooltip && plot) {
          plot.unhighlight()
          hideToolip()
        }
        return (latestIdx = null)
      }

      var hideToolip = () => angular.element(".tooltip").remove()

      var showTooltip = function (x, y, contents) {
        hideToolip()
        const tooltip = angular.element(`<div class='tooltip'>${contents}</div>`)
        tooltip.appendTo("body")

        let positionX = x - tooltip.width() - 30
        if (positionX < plot.offset().left) {
          positionX = x + 20
        }

        let positionY = y + 15
        if (positionY > plot.offset().top + plot.height() - 30) {
          positionY = y - tooltip.height() - 15
        }

        return tooltip.css({
          left: positionX,
          top: positionY,
        })
      }

      element.bind("mouseleave", clearChart)

      element.bind("plothover", function (event, pos, item) {
        if (scope.useTooltip !== true) {
          return
        }
        return updateLegend(pos)
      })

      plot = null
      const initChart = function () {
        if (scope.animation === true) {
          $.plotAnimator(element, [], {})
        } else {
          $.plot(element, [], {})
        }

        plot = null
        return (scope.object = undefined)
      }

      const makeChart = function (data) {
        if (plot) {
          initChart()
        }

        if (!(data != null ? data.length : undefined)) {
          const noDataTemplate = [
            "<div class='no-data-container'>",
            "<div class='align-wrapper'>",
            "<div class='center-wrapper'>",
            `<span data-i18n='${
              scope.nodataString != null ? scope.nodataString : "no data"
            }'><span>`,
            "</div>",
            "</div>",
            "</div>",
          ].join("")

          const newElement = $compile(noDataTemplate)(scope)
          element.html(newElement)
          return
        } else {
          if (scope.useTooltip !== true) {
            __guard__(
              scope.options != null ? scope.options.points : undefined,
              (x) => (x.show = false)
            )
          }

          if (scope.animation === true) {
            plot = $.plotAnimator(element, data, scope.options)
          } else {
            plot = $.plot(element, data, scope.options)
          }

          if (!_.isUndefined(scope.object)) {
            return (scope.object = plot)
          }
        }
      }

      scope.$watch("data", function (newData) {
        if (!newData) {
          return
        }
        if (!element.width() || !element.height()) {
          return
        }

        return makeChart(newData)
      })

      const ro = new ResizeObserver((entries) =>
        (() => {
          const result = []
          for (var entry of Array.from(entries)) {
            if (entry && plot === null) {
              result.push(makeChart(scope.data))
            } else {
              result.push(undefined)
            }
          }
          return result
        })()
      )
      return ro.observe(element[0])
    },
  }))

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
