/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")

  .controller("DataLogDlgCtrl", function ($scope, $element, ChartOptionSrv, Locale, SamplingSrv) {
    $scope.chartOption = angular.copy(ChartOptionSrv.barChartOption)
    $scope.chartOption.series.bars.barWidth = 60 * 60 * 200
    $scope.chartOption.xaxis.tickLength = 10
    $scope.chartOption.xaxis.tickSize = [6, "hour"]
    $scope.chartOption.xaxis.tickFormatter = function (val, axis) {
      const date = moment.utc(val)
      return Locale.dateTime(date, SamplingSrv["hour"].getTickFormat(val))
    }

    $scope.chartOption.tooltip.content = function (label, x, y, item) {
      let datetime = moment.utc(x)
      datetime = Locale.dateTime(datetime, "fullDateTime")

      return `\
<b>${datetime}</b><br/>
${parseInt(y)}<br/>\
`
    }

    $scope.chartOption.legend = {
      container: angular.element("#legendContainer-counter", $element),
      noColumns: 0,
    }

    $scope.chartOptionHM = _.clone($scope.chartOption)
    $scope.chartOptionHM.legend = {
      container: angular.element("#legendContainer-heatmap", $element),
      noColumns: 0,
    }

    const _getDefaultData = function (start, data, node, timenode, interval) {
      let idx
      idx = 0

      return {
        label: "",
        lines: {
          fill: false,
        },
        data: (() => {
          const result = []
          for (idx = 0; idx < data.length; idx++) {
            var d = data[idx]
            result.push([moment(d[timenode]), d[node]])
          }
          return result
        })(),
      }
    }

    let defaultData = _getDefaultData(
      moment().subtract(2, "days"),
      $scope.ngDialogData != null ? $scope.ngDialogData.counter : undefined,
      "count",
      "statisticFor",
      "hours"
    )

    if (defaultData.data.length === 0) {
      $scope.counterData = []
    } else {
      $scope.counterData = [
        _.merge(defaultData, {
          label: Locale.string("Last 2 days"),
          lines: {
            fill: true,
          },
        }),
      ]
    }

    defaultData = _getDefaultData(
      moment().subtract(2, "days"),
      $scope.ngDialogData != null ? $scope.ngDialogData.heatmap : undefined,
      "totalsum",
      "to",
      "hours"
    )

    if (defaultData.data.length === 0) {
      return ($scope.heatmapData = [])
    } else {
      return ($scope.heatmapData = [
        _.merge(defaultData, {
          label: Locale.string("Last 2 days"),
          lines: {
            fill: true,
          },
        }),
      ])
    }
  })
