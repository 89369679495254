import React, { Component } from "react"
import { Provider } from "react-redux"
import { store } from "../../ducks"
import propTypes from "prop-types"
import _ from "lodash"

export const createApp = (Children) => {
  class App extends Component {
    constructor(props) {
      super(props)

      this.state = {
        stateName: props.$state.current.name,
      }
    }

    successHook = (trans) => {
      this.setState({
        stateName: _.clone(this.props.$state.current.name),
      })
    }

    componentDidMount() {
      const { $transitions } = this.props
      this.unhook = $transitions.onSuccess({}, this.successHook)
    }

    componentWillUnmount() {
      if (this.unhook) this.unhook()
    }

    render() {
      return (
        <Provider store={store}>
          <Children stateName={this.state.stateName} {...this.props} />
        </Provider>
      )
    }
  }
  App.propTypes = {
    $transitions: propTypes.object.isRequired,
    $state: propTypes.object.isRequired,
    $stateParams: propTypes.object.isRequired,
  }

  return App
}

export default createApp
