import angular from "angular"
import { defaultWeight, weightFunctions } from "../counter-label"

angular.module("uCountitUiApp").controller("weightInputCtrl", ($scope) => {
  if (!$scope.weight) $scope.weight = defaultWeight

  $scope.function = {
    list: weightFunctions,
    selected:
      weightFunctions.find((f) => f.function === $scope.weight.function) || weightFunctions[0],
  }

  $scope.strParams = $scope.function.selected.paramsToString($scope.weight.params)

  $scope.$watch("function.selected", (newFunc, oldFunc) => {
    $scope.weight.function = newFunc.function
    if (newFunc.function !== oldFunc.function) $scope.strParams = newFunc.defaultStrParams
  })

  $scope.$watch("strParams", (strParams) => {
    $scope.weight.params = $scope.function.selected.stringToParams(strParams)
  })
})
