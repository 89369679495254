import jwt from "jsonwebtoken"
const querystring = require("querystring")

export const getToken = () => {
  if (window.location.search) {
    const { service_token } = querystring.parse(window.location.search.substring(1))
    return service_token
  }
}

export const getDecoded = () => {
  if (getToken()) return jwt.decode(getToken())
  return null
}
