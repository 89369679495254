/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")
  .controller(
    "RecoveryCtrl",
    function ($stateParams, $scope, $location, RecoverySrv, Auth, Locale) {
      const checkHashcodeValidation = function (callback) {
        const { key } = $location.search()
        if (!key) {
          return callback(new Error("key parameter is required."))
        }

        return RecoverySrv.get({ key, _: Date.now() }).$promise.then(
          (res) => callback(null),

          (res) => callback(new Error("Could not find the key"))
        )
      }

      $scope.canNotSubmit = function () {
        $scope.pwdLengthError = !(
          __guard__(
            $scope.password != null ? $scope.password.newPassword : undefined,
            (x) => x.length
          ) >= 4
        )
        $scope.pwdUnmatchError =
          ($scope.password != null ? $scope.password.confirmPassword : undefined) !==
          ($scope.password != null ? $scope.password.newPassword : undefined)
        return $scope.pwdLengthError || $scope.pwdUnmatchError
      }

      $scope.submitForm = () =>
        RecoverySrv.update({
          key: $location.search().key,
          password: $scope.password.newPassword,
        }).$promise.then(
          (res) => ($scope.resultSuccess = true),

          function (err) {
            $scope.resultSuccess = false
            return ($scope.resultError = true)
          }
        )

      $scope.goLogin = function () {
        $location.search("key", null)
        return $location.path("/login")
      }

      $scope.reset = function () {
        $scope.resultSuccess = false
        $scope.resultError = false
        if ($scope.password != null) {
          $scope.password.newPassword = ""
        }
        return $scope.password != null ? ($scope.password.confirmPassword = "") : undefined
      }

      const init = function () {
        Auth.logout()
        Locale.setup() // set default locale : en-gb(UnitedKingdom)
        Locale.setLanguage() // set default language : en-us(English - UnitedStates)

        return checkHashcodeValidation(function (err) {
          if (err) {
            return $location.path("/login")
          }
        })
      }

      return init()
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
