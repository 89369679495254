/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

// import terms from "../terms"

angular
  .module("uCountitUiApp")

  .controller(
    "TermsCtrl",
    function ($rootScope, $scope, $http, $location, $uibModalInstance, Auth, ApiSrv, Locale) {
      const getTitle = function (type) {
        switch (type) {
          case "terms":
            return Locale.string("Terms of Use")
          case "privacy":
            return Locale.string("Privacy Policy")
        }
      }

      const init = function () {
        $scope.title = getTitle($scope.options.type)

        return ApiSrv.getCustomPolicy({ path: $scope.options.path }).then(
          (data) => ($scope.terms = data)
        )
      }

      $scope.agree = () =>
        $http.post("/api/1.0/me/agree").success(function () {
          Auth.flushCurrentAccount()
          return $uibModalInstance.close()
        })

      $scope.decline = function () {
        Auth.logout()
        ApiSrv.clearCache()
        $location.path("/login")
        return $uibModalInstance.dismiss()
      }

      return init()
    }
  )
