/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory("PremiumReportSrv", ($rootScope, $resource) =>
    $resource(
      `${$rootScope.globalConfig.apipath}/reports/:id`,
      { id: "@id" },
      {
        update: {
          method: "PUT",
        },
      }
    )
  )
