/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import _ from "lodash"
import angular from "angular"
import escapeRegexp from "escape-string-regexp"
import Promise from "bluebird"
import moment from "moment"

import {
  kStandardLabels,
  getStandardLabel,
  counterSchema,
  defaultWeight,
  weightFunctions,
} from "../../../components/counter-label"

angular
  .module("uCountitUiApp")
  .directive("storeVirtualFootfallForm", () => ({
    restrict: "E",
    templateUrl: "app/admin/store/store-virtual-footfall.html",
    replace: true,

    scope: {
      store: "=",
      isOpened: "=",
      mme: "=",
    },

    controller: "storeVirtualFootfallFormCtrl",
  }))
  .controller(
    "storeVirtualFootfallFormCtrl",
    function ($scope, Locale, ApiSrv, CameraConfigSrv, $filter, Heatmaptag) {
      $scope.standardLabels = kStandardLabels
      let kDefaultContents = ["ZT", "FF", "sfgFF", "cnfsFF"]
      // .concat(
      //   //INFO me를 요청하면 "Error: $injector:unpr Unknown Provider"가 발생한다.
      //   $scope.mme.isDeveloper() ? ["cfsFF"] : []
      // )
      const kCompanyLevelContents = ["ZT"]
      $scope.supportedContents = kDefaultContents
      $scope.companyVirtualFF = false

      const initializeForm = function () {
        $scope.camera = null
        $scope.counters = []
        $scope.allCameras = []
        $scope.sources = []

        $scope.orgzSelector = []
        $scope.contentsSelector = []
        $scope.sourceSelector = []

        $scope.selectedPeriodIdx = -1
        $scope.removedFootfalls = []

        $scope.periods = []
        $scope.orgPeriods = []

        $scope.footfalls = []
        $scope.orgFootfalls = []

        $scope.sensorFootfalls = []
        $scope.footfallCounters = []
        $scope.footfallTableLegends = []
      }

      $scope.getOrgzList = function (idx, keyword) {
        if ($scope.contentsSelector[idx].selected === "sfgFF") {
          return [$scope.store]
        }
        return $scope.filteredAllCameras($scope.contentsSelector[idx].selected, keyword)
      }

      $scope.reload = function () {
        $scope.companyVirtualFF = $scope.store != null ? $scope.store.companyVirtualFF : undefined

        initializeForm()

        const loadCameras = $scope.companyVirtualFF
          ? ApiSrv.getCameraOfCompany({
              companyId: $scope.store._companyId,
              isCache: true,
              attachStore: true,
            }).then((cameras) =>
              cameras.map(function (c) {
                c.name = c.store.name + " > " + c.name
                return c
              })
            )
          : ApiSrv.getCameraOfStore({ id: $scope.store._id, isCache: true })
        return loadCameras
          .then(function (cameras) {
            $scope.allCameras = cameras
            $scope.allCameras = $filter("orderBy")($scope.allCameras, ["name"])
            $scope.filteredAllCameras = (contents, keyword) =>
              $filter("filter")($scope.allCameras, function (camera) {
                if (camera.isVirtual) {
                  return false
                }
                if (["FF", "cnfsFF"].includes(contents) && !camera.functions.use.counter) {
                  return false
                }
                if (["cfsFF", "cnfsFF"].includes(contents) && !camera.functions.use.groupFace) {
                  return false
                }
                const str = new RegExp(`.*${escapeRegexp(keyword)}.*`, "i")
                return (
                  __guard__(camera != null ? camera.name : undefined, (x) => x.match(str)) != null
                )
              })

            initializeCounters(cameras)
            return initializeFootfallCounters()
          })
          .catch((err) => console.error(err))
      }

      $scope.$watch("store", function () {
        if ($scope.isOpened) {
          return $scope.reload()
        }
      })

      var initializeFootfallCounters = function () {
        const footfallCameras = $scope.allCameras.filter(
          (camera) =>
            camera.functions.use.counter && camera.active && camera._storeId === $scope.store._id
        )
        let sensorFootfalls = []
        return Promise.map(footfallCameras, (camera) => {
          if (camera.model !== "footfall") {
            return ApiSrv.getFootfallsConfigOfCamera(
              camera._id,
              $scope.store._id,
              { all: true },
              false
            ).then(function (footfalls) {
              footfalls.forEach(function (footfall) {
                footfall.counters = footfall.counters.filter(function (counter) {
                  return counter.active
                })
                return footfall.counters.forEach(function (counter) {
                  counter.cameraName = camera.name
                })
              })

              sensorFootfalls.push(footfalls)
            })
          }
        }).then(function () {
          $scope.sensorFootfalls = sensorFootfalls
        })
      }

      $scope.$watch(
        "[sensorFootfalls,sources,counters,periods,selectedPeriodIdx]",
        (param) => setFootfallCounters(...param),
        true
      )

      const setFootfallCounters = (
        sensorFootfalls,
        sources,
        counters,
        periods,
        selectedPeriodIdx
      ) => {
        if (!sensorFootfalls) return
        if (!counters) return
        if (!(selectedPeriodIdx >= 0)) return

        const period = periods[selectedPeriodIdx]
        if (!period) {
          return
        }

        const periodFrom = moment.utc(period.from)
        const periodTo = moment.utc(period.to)

        const footfallCounters = []
        const footfallTableLegends = []

        const standardLabels = $scope.standardLabels.map((label, idx) => ({
          ...label,
          weights: $scope.store.specialLabels[idx]?.weights || label.weights,
        }))

        const labels = standardLabels.concat(
          $scope.store.labels.filter(function (label) {
            return label.active
          })
        )

        const nolabelIndex = labels.findIndex((label) => label.key === "nolabel")
        if (nolabelIndex >= 0) {
          const nolabel = labels.splice(nolabelIndex, 1)[0]
          labels.push(nolabel)
        }

        // Virtual Counters
        let virtualCounters
        virtualCounters = counters
          .map((counter, idx) => ({ counter, idx }))
          .filter(({ counter }) => counter.active && counter.rule?.sourceId)
          .map(({ counter, idx }) => {
            const sign = counter.op != null ? counter.op : "+"
            const weightFunction = weightFunctions.find(
              (wf) => wf.function === counter.rule.weights.function
            )

            const weights = weightFunction
              ? !weightFunction.isDefaultParams(counter.rule.weights.params)
                ? {
                    strParams: weightFunction.paramsToString(counter.rule.weights.params) || "",
                    i18nKey: weightFunction.i18nKey || "",
                  }
                : {
                    strParams: "",
                    i18nKey: "",
                  }
              : {
                  strParams: "",
                  i18nKey: "",
                }

            if (sign === "+") footfallTableLegends.push("virtualPlus")
            else if (sign === "-") footfallTableLegends.push("virtualMinus")

            const newCounter = {
              name: sign + counter.name,
              label: counter.label,
              color: sign === "+" ? "vff-color-plus" : "vff-color-minus",
              weights,
              periods: [],
            }

            sources[idx]?.forEach((source) => {
              if (
                source.active &&
                source._id === counter.rule.sourceId &&
                source.item === (counter.rule.item || "")
              ) {
                const from = moment.utc(source.from)
                const to = moment.utc(source.to)
                newCounter.periods.push({ from, to })
              }
            })

            return newCounter
          })
          .filter((counter) => counter.periods.length)
          .sort((a, b) => (a.name > b.name ? 1 : -1))

        // Sensor Counters
        let sensorCounters = []

        sensorFootfalls.forEach((footfalls) => {
          const filteredFootfalls = footfalls.filter(
            (footfall) =>
              periodTo > moment.utc(footfall.from) && periodFrom < moment.utc(footfall.to)
          )

          const counters = []

          filteredFootfalls.forEach((footfall) => {
            footfall.counters.forEach((counter) => {
              const counterPeriod = {
                from: moment.utc(footfall.from),
                to: moment.utc(footfall.to),
              }

              const duplicatedCounter = counters.find(
                (c) => c.name == counter.name && c.label._id == counter.label._id
              )

              if (duplicatedCounter) {
                duplicatedCounter.periods.push(counterPeriod)
              } else {
                const counterCopy = Object.assign({}, counter)
                counterCopy.periods = [counterPeriod]
                counters.push(counterCopy)
              }
            })
          })

          sensorCounters = sensorCounters.concat(counters)
        })

        sensorCounters = sensorCounters
          .map((counter) => {
            const newCounter = {
              name: `[${counter.cameraName}:FF]:${counter.name}`,
              label: counter.label,
              periods: counter.periods,
            }
            return newCounter
          })
          .sort((a, b) => (a.name > b.name ? 1 : -1))

        if (sensorCounters.length) footfallTableLegends.push("sensor")

        // All Counters
        let allCounters = sensorCounters.concat(virtualCounters)
        labels.forEach((label) => {
          const counters = allCounters.filter((counter) => counter.label._id === label._id)
          if (counters.length > 0) {
            const weightFunction = weightFunctions.find(
              (wf) => wf.function === label.weights.function
            )

            const weights = weightFunction
              ? !weightFunction.isDefaultParams(label.weights.params)
                ? {
                    strParams: weightFunction.paramsToString(label.weights.params) || "",
                    i18nKey: weightFunction.i18nKey || "",
                  }
                : {
                    strParams: "",
                    i18nKey: "",
                  }
              : {
                  strParams: "",
                  i18nKey: "",
                }

            return footfallCounters.push({
              label: { ...label, weights },
              counters: counters.map((counter, idx) => {
                const paddingStyle = {
                  "no-padding-top": idx !== 0,
                  "no-padding-bottom": idx !== counters.length - 1,
                }
                const newCounter = { ...counter, periods: "", paddingStyle }

                const periods = []
                counter.periods.forEach((period) => {
                  if (!periods.length) periods.push(period)
                  else {
                    const lastPeriod = periods[periods.length - 1]
                    if (lastPeriod.to.isSame(period.from, "day")) lastPeriod.to = period.to
                    else periods.push(period)
                  }
                })

                if (
                  periods.length &&
                  (periods > 1 || periods[0].from > periodFrom || periods[0].to < periodTo)
                ) {
                  newCounter.periods = periods
                    .map((period) => {
                      const from = periodFrom > period.from ? periodFrom : period.from
                      const to = periodTo < period.to ? periodTo : period.to
                      return makeGroupName(from, to)
                    })
                    .join(", ")
                }

                return newCounter
              }),
            })
          }
        })

        $scope.footfallTableLegends = footfallTableLegends
        $scope.footfallCounters = footfallCounters
      }

      var initializeCounters = function (cameras) {
        $scope.camera = cameras.find(
          (camera) =>
            camera.isVirtual && camera.model === "footfall" && camera._storeId === $scope.store._id
        )
        const promise = $scope.camera
          ? ApiSrv.getFootfallsConfigOfCamera(
              $scope.camera._id,
              $scope.store._id,
              { all: true },
              false
            )
          : Promise.resolve([
              {
                from: "2000-01-01",
                to: "9000-01-01",
                counters: [],
              },
            ])

        return promise.then(function (footfalls) {
          $scope.footfalls = footfalls.map(function (ff) {
            ff.from = moment.utc(ff.from).format("YYYY-MM-DD")
            ff.to = moment.utc(ff.to).format("YYYY-MM-DD")
            ff.counters.forEach(function (counter) {
              if (!counter.op) {
                counter.op = "+"
              }
              if (!counter.rule.weights) {
                counter.rule.weights = defaultWeight
              }
            })
            return ff
          })

          $scope.periods = $scope.footfalls.map((ff) => ({
            _id: ff._id,
            from: ff.from,
            to: ff.to,
          }))

          $scope.orgPeriods = _.cloneDeep($scope.periods)
          $scope.orgFootfalls = _.cloneDeep($scope.footfalls)
          return $scope.$apply()
        })
      }

      const createVirtualCamera = function () {
        const body = {
          active: true,
          _storeId: $scope.store._id,
          isVirtual: true,
          model: "footfall",
          functions: { use: { counter: true } },
          name: "Virtual sensor",
        }

        return CameraConfigSrv.save({}, body).$promise
      }

      const makeGroupName = function (from, to) {
        const start = moment.utc(from).format("YYYY-MM-DD")
        const end = moment.utc(to).add(-1, "day").format("YYYY-MM-DD")
        return `${start} - ${end}`
      }

      const initializeSourceList = function (idx) {
        $scope.sources[idx] = []
        $scope.sourceSelector[idx].selected = null
      }

      // UCNT-4949 Virtual Footfall에서 counterId가 같은 Period Configuration은 결과적으로 같은 결과를 보여준다.
      // 이를 보여주기 위해서 Period Name에 [🔗]를 보여준다.
      const addPeriodicLinkedFlag = (sourceList) => {
        const uniqueSources = []
        const duplicatedSources = []
        sourceList.forEach(function (d) {
          if (!d.active) return
          if (uniqueSources.some((s) => s._id === d._id && s.item === d.item)) {
            if (!duplicatedSources.some((s) => s._id === d._id && s.item === d.item)) {
              duplicatedSources.push(d)
            }
          } else {
            uniqueSources.push(d)
          }
        })
        sourceList.forEach(function (d) {
          if (!d.active) return
          if (duplicatedSources.some((s) => s._id === d._id && s.item === d.item)) {
            d.period = "[🔗] " + d.period
          }
        })
      }

      $scope.getActiveSources = (idx) => $scope.sources[idx]?.filter((src) => src.active) || []

      const createNewSource = function (id, name, itemName) {
        return {
          active: true,
          from: "2000-01-01",
          to: "9000-01-01",
          _id: id,
          name: name,
          item: itemName,
        }
      }

      const createSFGSource = function (id, idx) {
        return [
          ["default", ""],
          ["all visits", "cnt"],
          ["staff", "staff"],
          ["visitor", "visitor"],
          ["none staff", "none staff"],
        ].map((d) => createNewSource(id, d[0], d[1]))
      }

      const createCFSSource = function (id, idx) {
        return [
          ["all visits", "cnt"],
          ["staff", "staff"],
          ["visitor", "visitor"],
          ["good vistits", "good"],
          ["faulty vistits", "fault"],
          ["none staff", "nonestaff"],
        ].map((d) => createNewSource(id, d[0], d[1]))
      }

      const createCNFSSource = function (idx, resolve, reject) {
        const orgz = $scope.orgzSelector[idx].selected
        if (!orgz) {
          $scope.sources[idx] = []
          $scope.sourceSelector[idx].selected = null
          return resolve()
        }

        const cameraId = orgz._id
        const period = $scope.periods[$scope.selectedPeriodIdx]
        const param = { from: period.from, to: period.to }
        ApiSrv.getFootfallsConfigOfCamera(cameraId, $scope.store._id, param, true)
          .then((periodCfgs) => {
            const sourceList = []
            periodCfgs.forEach((period) => {
              period.counters.forEach((counter) => {
                if (counter.active === false) return
                if (!counter.netVisits?.active) return
                ;[
                  ["visitor", "staff"],
                  ["staff", "visitor"],
                  ["except faulty vistits", "fault"],
                  ["except none staff", "nonestaff"],
                  ["except good vistits", "good"],
                ].forEach((d) => {
                  const source = {
                    active: true,
                    from: period.from,
                    to: period.to,
                    period: makeGroupName(period.from, period.to),
                    _id: counter._id,
                    name: `${counter.name}.${d[0]}`,
                    item: d[1],
                  }
                  sourceList.push(source)
                })
              })
            })

            addPeriodicLinkedFlag(sourceList)
            $scope.sources[idx] = sourceList
            $scope.sourceSelector[idx].selected = null
            return resolve()
          })
          .catch((err) => reject(err))
      }

      const updateSourceList = (idx) =>
        new Promise(function (resolve, reject) {
          let getConfigPromise, itemName
          const orgz = $scope.orgzSelector[idx].selected
          const period = $scope.periods[$scope.selectedPeriodIdx]
          const contents = $scope.contentsSelector[idx].selected
          if (!orgz || !contents) {
            return resolve()
          }

          const param = { from: period.from, to: period.to }

          if (contents === "sfgFF") {
            $scope.sources[idx] = createSFGSource($scope.store._id, idx)
            $scope.sourceSelector[idx].selected = null
            //INFO sfgFF는 source counter config 를 선택할 필요가 없으므로 바로 resolve
            return resolve()
          } else if (contents === "cfsFF") {
            $scope.sources[idx] = orgz ? createCFSSource(orgz._id, idx) : []
            $scope.sourceSelector[idx].selected = null
            //INFO cfsFF는 source counter config 를 선택할 필요가 없으므로 바로 resolve
            return resolve()
          } else if (contents === "cnfsFF") {
            return createCNFSSource(idx, resolve, reject)
          } else if (contents === "FF") {
            if (!orgz.functions.use.counter) {
              initializeSourceList(idx)
              return resolve()
            } else {
              getConfigPromise = ApiSrv.getFootfallsConfigOfCamera(
                orgz._id,
                $scope.store._id,
                param,
                true
              )
              itemName = "counters"
            }
          } else if (contents === "ZT") {
            if (!orgz.functions.use.zonetraffic) {
              initializeSourceList(idx)
              return resolve()
            } else {
              const hmtagSrv = new Heatmaptag(orgz._id)
              getConfigPromise = hmtagSrv.getTags(param, true)
              itemName = "heatmaptags"
            }
          } else {
            return reject(`Not supported contents[${contents}] type`)
          }

          return getConfigPromise
            .then(function (periodCfgs) {
              const sourceList = []
              periodCfgs.forEach(function (periodCfg) {
                periodCfg[itemName].forEach(
                  (item, idx) => (item.idx = itemName === "counters" ? item.source : idx + 1)
                )
                const items = periodCfg[itemName]
                return items.forEach(function (item) {
                  item.period = makeGroupName(periodCfg.from, periodCfg.to)
                  item.from = periodCfg.from
                  item.to = periodCfg.to
                  if (item.item == null) {
                    item.item = ""
                  }
                  sourceList.push(item)

                  if (contents === "FF" && item.groupCount) {
                    const groupItem = _.cloneDeep(item)
                    delete groupItem.$$hashKey
                    groupItem.name = `${item.name}.GroupCount`
                    groupItem.item = "group"
                    sourceList.push(groupItem)
                  }

                  if (contents == "FF" && item.netVisits?.active) {
                    const netItem = _.cloneDeep(item)
                    delete netItem.$$hashKey
                    netItem.name = `${item.name}.NetVisits`
                    netItem.item = "netVisits"
                    sourceList.push(netItem)
                  }
                })
              })

              addPeriodicLinkedFlag(sourceList)
              $scope.sources[idx] = sourceList
              $scope.sourceSelector[idx].selected = null
              return resolve()
            })
            .catch((err) => reject(err))
        })

      // update footfall counter's rule by linked contents
      const updateCounter = function (idx) {
        const orgz = $scope.orgzSelector[idx].selected
        const source = $scope.sourceSelector[idx].selected

        const rule = $scope.counters[idx].rule ? $scope.counters[idx].rule : {}
        rule.orgId = orgz?._id || ""
        rule.contents = $scope.contentsSelector[idx].selected || ""
        rule.sourceId = source?._id || ""
        rule.item = source?.item || ""
        $scope.sources[idx].rule = rule

        $scope.counters[idx].name = `[${orgz?.name || ""}:${rule.contents}]:${source?.name || ""}`
      }

      const addCounter = function (counter) {
        counter.sourceType = "Linked"
        delete counter.linedirection
        delete counter.lineobjectwidth
        delete counter.linepoints

        const idx = $scope.counters.length
        $scope.orgzSelector[idx] = { selected: null }
        $scope.contentsSelector[idx] = { selected: null }
        $scope.sourceSelector[idx] = { selected: null }
        return $scope.counters.push(counter)
      }

      const isValidRule = function () {
        if (!$scope.counters) {
          return false
        }

        for (
          let i = 0, end = $scope.counters.length, asc = 0 <= end;
          asc ? i < end : i > end;
          asc ? i++ : i--
        ) {
          var counter = $scope.counters[i]
          if (!counter.rule) {
            return false
          }

          var { orgId, contents, sourceId } = counter.rule
          if (!orgId || !contents || !sourceId) {
            return false
          }
        }

        return true
      }

      $scope.closeDialog = function () {
        $scope.isOpened = false
        return ($scope.store = null)
      }

      $scope.getDisplayLabelName = function (label) {
        const sLabel = _.find(kStandardLabels, ["name", label])
        if (sLabel) {
          return Locale.string(sLabel.i18nKey)
        } else {
          return label
        }
      }

      $scope.isSpecialCounter = (counter) => getStandardLabel(counter)

      $scope.changeLabel = function (label, counter) {
        counter.label = label
        return (counter._labelId = label._id)
      }

      $scope.changeOrganization = function (idx) {
        const camera = $scope.orgzSelector[idx].selected
        if ($scope.companyVirtualFF && camera._storeId !== $scope.store._id) {
          $scope.supportedContents = kCompanyLevelContents
        } else {
          $scope.supportedContents = kDefaultContents
        }
        return updateSourceList(idx).then(() => updateCounter(idx))
      }

      $scope.changeContents = (idx) => {
        if ($scope.contentsSelector[idx].selected === "sfgFF") {
          $scope.orgzSelector[idx].selected = $scope.store
        } else {
          $scope.orgzSelector[idx].selected = null
          $scope.sourceSelector[idx].selected = null
        }

        return updateSourceList(idx).then(() => updateCounter(idx))
      }

      $scope.changeSource = (idx) => updateCounter(idx)

      $scope.addNewCounter = function () {
        const counter = counterSchema($scope.counters.length, kStandardLabels[1])
        counter.active = true
        counter.name = ""
        counter.op = "+"
        counter.rule = {
          weights: defaultWeight,
        }

        return addCounter(counter)
      }

      $scope.removeCounter = function (idx) {
        $scope.contentsSelector.splice(idx, 1)
        $scope.orgzSelector.splice(idx, 1)
        $scope.sourceSelector.splice(idx, 1)
        $scope.sources.splice(idx, 1)
        $scope.counters.splice(idx, 1)
      }

      // 아래 함수가 최초에 load() 역활을 대신하고 있다.
      $scope.selectedPeriod = function (idx, isManual) {
        if (idx < 0) {
          return
        }

        if (isManual) {
          const footfall = $scope.footfalls[$scope.selectedPeriodIdx]
          if (footfall) {
            footfall.counters = $scope.counters
          }
        }

        $scope.selectedPeriodIdx = idx

        $scope.counters = []
        return Promise.map($scope.footfalls[idx].counters, function (counter, idx) {
          if (!counter.rule) {
            return
          }

          addCounter(counter)

          const { orgId, contents } = counter.rule
          $scope.contentsSelector[idx].selected = contents
          if (contents === "sfgFF") {
            $scope.orgzSelector[idx].selected = $scope.store
          } else {
            $scope.orgzSelector[idx].selected = $scope.allCameras.find((c) => c._id === orgId)
          }
          return updateSourceList(idx)
        }).then(function () {
          $scope.counters.forEach(function (counter, idx) {
            if (!counter.rule) {
              return
            }
            if (counter.rule.contents === "sfgFF" || counter.rule.contents === "cfsFF") {
              let source = $scope.sources[idx].find(
                (source) =>
                  source._id === counter.rule.sourceId && source.item === (counter.rule.item || "")
              )
              if (!source) $scope.sourceSelector[idx].selected = { nonexistent: true }
              else $scope.sourceSelector[idx].selected = source
            } else {
              // FF, ZT, cnfsFF
              // Find active source first
              let source = $scope.sources[idx].find(
                (source) =>
                  source.active &&
                  source._id === counter.rule.sourceId &&
                  source.item === (counter.rule.item || "")
              )
              if (!source)
                // If active source doesn't exist, find inactive source
                source = $scope.sources[idx].find(
                  (source) =>
                    source._id === counter.rule.sourceId &&
                    source.item === (counter.rule.item || "")
                )

              if (!source) $scope.sourceSelector[idx].selected = { nonexistent: true }
              else $scope.sourceSelector[idx].selected = source
            }
            updateCounter(idx)
          })
          return $scope.$apply()
        })
      }

      $scope.changedPeriod = function (removed) {
        let i
        for (i = removed.length - 1; i >= 0; i--) {
          var idx = $scope.footfalls.findIndex((footfall) => footfall._id === removed[i]._id)
          var removedFootfall = $scope.footfalls.splice(idx, 1)[0]
          if (removedFootfall) {
            $scope.removedFootfalls.push(removedFootfall)
          }
        }

        return (() => {
          let asc, end
          const result = []
          for (
            i = 0, end = $scope.periods.length, asc = 0 <= end;
            asc ? i < end : i > end;
            asc ? i++ : i--
          ) {
            if (!$scope.footfalls[i]) {
              var newFootfall = {
                _cameraId: $scope.camera != null ? $scope.camera._id : undefined,
                from: $scope.periods[i].from,
                to: $scope.periods[i].to,
                counters: _.cloneDeep(__guard__($scope.footfalls[i - 1], (x) => x.counters) || []),
              }
              newFootfall.counters.forEach((ff) => delete ff._id)
              $scope.footfalls[i] = newFootfall
            }

            $scope.footfalls[i].from = $scope.periods[i].from
            result.push(($scope.footfalls[i].to = $scope.periods[i].to))
          }
          return result
        })()
      }

      const isChangedCounters = function () {
        const pick = ["active", "_labelId", "name", "rule", "source", "op"]

        for (let idx = 0; idx < $scope.orgFootfalls.length; idx++) {
          var object, other
          var footfall = $scope.orgFootfalls[idx]
          if (!$scope.footfalls[idx]) {
            return true
          }

          if (idx === $scope.selectedPeriodIdx) {
            object = $scope.counters.map((c) => _.pick(c, pick))
            other = footfall.counters.map((c) => _.pick(c, pick))
            if (!angular.equals(object, other)) {
              return true
            }
          } else {
            object = footfall.counters.map((c) => _.pick(c, pick))
            other = $scope.footfalls[idx].counters.map((c) => _.pick(c, pick))
            if (!angular.equals(object, other)) {
              return true
            }
          }
        }

        return false
      }

      $scope.makeItemName = function (item) {
        if (!item) {
          return ""
        } else {
          return item.name
        }
      }

      $scope.toggleOP = (counter) => (counter.op = counter.op === "-" ? "+" : "-")

      $scope.isSubmitting = false

      $scope.canSubmit = () =>
        !$scope.isSubmitting &&
        $scope.formDlg.$valid &&
        isValidRule() &&
        (!angular.equals($scope.periods, $scope.orgPeriods) || isChangedCounters())

      $scope.submitForm = function () {
        $scope.isSubmitting = true
        const isNeedUpdateCameras = !$scope.camera
        const promise = $scope.camera ? Promise.resolve() : createVirtualCamera()
        return promise
          .then(function (camera) {
            if (camera) {
              $scope.camera = camera
              $scope.footfalls.forEach((footfall) => (footfall._cameraId = camera._id))
            }

            const footfalls = $scope.footfalls.map((ff) => ({
              ...ff,
              from: moment.utc(ff.from),
              to: moment.utc(ff.to),
            }))
            footfalls[$scope.selectedPeriodIdx].counters = $scope.counters

            return ApiSrv.FootfallConfigOfCamera().set(
              { id: $scope.camera._id },
              footfalls
            ).$promise
          })
          .then(function (res) {
            if (isNeedUpdateCameras) {
              ApiSrv.getCameraOfStore({ id: $scope.store._id, isCache: false })
            }
            $scope.isSubmitting = false
            return $scope.closeDialog()
          })
          .catch((err) => {
            $scope.isSubmitting = false
            console.error(err)
          })
      }
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
