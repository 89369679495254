/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

import { getColors } from "../util/colors"

angular
  .module("uCountitUiApp")

  .factory("Heatmaptag", function ($q, $cacheFactory, CameraConfigSrv) {
    let Heatmaptag
    const cache = $cacheFactory("heatmapTagsCache")
    const kDEFAULT_MINROITIME = 10

    return (Heatmaptag = (function () {
      let _regularPolygonPoints = undefined
      let _moveCenterObject = undefined
      let _transformCoordinateToPixel = undefined
      let _transformPixelToCoordinate = undefined
      let _transformDrawTag = undefined
      let _transformTag = undefined
      let _randomID = undefined
      Heatmaptag = class Heatmaptag {
        static initClass() {
          _regularPolygonPoints = function (sideCount, radius) {
            const sweep = (Math.PI * 2) / sideCount
            const cx = radius
            const cy = radius
            const points = []
            let i = 0
            while (i < sideCount) {
              var x = cx + radius * Math.cos(i * sweep)
              var y = cy + radius * Math.sin(i * sweep)
              points.push({
                x,
                y,
              })
              i++
            }
            return points
          }

          _moveCenterObject = function (obj) {
            const left = _.minBy(obj, "x").x
            const top = _.minBy(obj, "y").y
            const right = _.maxBy(obj, "x").x
            const bottom = _.maxBy(obj, "y").y
            const center = {
              x: 65535 / 2 - (right - left) / 2,
              y: 65535 / 2 - (bottom - top) / 2,
            }

            const points = obj.map((n) => ({
              x: n.x - left + center.x,
              y: n.y - top + center.y,
            }))
            return points
          }

          _transformCoordinateToPixel = (x, resolution) => (x * resolution) / 65535

          _transformPixelToCoordinate = (x, resolution) => Math.round((x * 65535) / resolution)

          _transformDrawTag = function (data, width, height) {
            let left, points, top
            const transformPoints = data.points.map((n) => ({
              x: _transformCoordinateToPixel(n.x, width),
              y: _transformCoordinateToPixel(n.y, height),
            }))
            if (transformPoints.length) {
              left = _.minBy(transformPoints, "x").x
              top = _.minBy(transformPoints, "y").y
              points = transformPoints.map((n) => ({
                x: n.x - left,
                y: n.y - top,
              }))
            }

            return {
              active: data.active,
              color: data.color,
              name: data.name,
              minRoiTime: data.minRoiTime != null ? data.minRoiTime : kDEFAULT_MINROITIME,
              left: left != null ? left : 0,
              top: top != null ? top : 0,
              points: points != null ? points : [],
              rgbcolor: `rgb(${data.color})`,
              id: _randomID(),
              _id: data._id,
              hideInImage: _.isNil(data.hideInImage) ? false : data.hideInImage,
            }
          }

          _transformTag = function (data, width, height) {
            let points, transformPoints
            if (data.points.length) {
              points = data.points.map((n) => ({
                x: n.x + data.left,
                y: n.y + data.top,
              }))
              transformPoints = points.map((n) => ({
                x: _transformPixelToCoordinate(n.x, width),
                y: _transformPixelToCoordinate(n.y, height),
              }))
            }

            return {
              active: data.active,
              name: data.name,
              color: data.color,
              minRoiTime: data.minRoiTime,
              points: transformPoints != null ? transformPoints : [],
              _id: data._id ? data._id : undefined,
              hideInImage: data.hideInImage,
            }
          }

          _randomID = function () {
            const min = 1
            const max = 999999
            return `tag${Math.floor(Math.random() * (max - min + 1)) + min}`
          }
        }
        constructor(cameraID, bgWidth, bgHeight) {
          this.cameraID = cameraID
          this.bgWidth = bgWidth
          this.bgHeight = bgHeight
        }

        setBackgroundSize(width, height) {
          this.bgWidth = width
          return (this.bgHeight = height)
        }

        // query option:
        // - null or { at: '2021-01-01' }: return object
        // - { all: true } or { from: '2021-01-01', to: '2021-01-02' }: return array
        getTags(query, isCache) {
          let cacheData
          if (isCache == null) {
            isCache = false
          }
          const cacheId = `Heatmaptags-${this.cameraID}-${JSON.stringify(query)}`

          if ((cacheData = cache.get(cacheId)) && isCache) {
            return Promise.resolve(cacheData)
          }

          const opt = Object.assign(
            {},
            {
              id: this.cameraID,
              item: "heatmaptags",
              _: Date.now(),
            },
            query
          )

          const callback =
            opt.all || opt.from || opt.to ? CameraConfigSrv.query : CameraConfigSrv.get

          return callback(opt).$promise.then(function (res) {
            cache.put(cacheId, res)
            return res
          })
        }

        setTags(hmtag) {
          const deffered = $q.defer()
          cache.removeAll()

          CameraConfigSrv.setAsArray(
            {
              id: this.cameraID,
              item: "heatmaptags",
            },
            hmtag,
            (res) => deffered.resolve(res),
            (err) => deffered.reject(err)
          )

          return deffered.promise
        }

        deleteTags(hmtag) {
          const deffered = $q.defer()
          cache.removeAll()

          CameraConfigSrv.delete(
            {
              id: this.cameraID,
              item: "heatmaptags",
              _id: hmtag._id,
            },
            (res) => deffered.resolve(res),
            (err) => deffered.reject(err)
          )

          return deffered.promise
        }

        transformDrawTags(tags) {
          if (!tags) {
            return []
          }
          return tags.map((tag) => _transformDrawTag(tag, this.bgWidth, this.bgHeight))
        }

        transformTags(drawTags) {
          if (!drawTags) {
            return []
          }
          return drawTags.map((tag) => _transformTag(tag, this.bgWidth, this.bgHeight))
        }

        getDefaultDrawTag(index) {
          const newTag = {
            active: false,
            color: getColors(index, "zonetraffic"),
            name: "",
            minRoiTime: kDEFAULT_MINROITIME,
            points: [],
            id: _randomID(),
            hideInImage: false,
          }
          newTag.rgbcolor = `rgb(${newTag.color})`

          return newTag
        }

        getHexagonTag(index) {
          if (index == null) {
            index = 0
          }
          const radius = 65535 / 3
          const hxPoints = _regularPolygonPoints(6, radius)
          const points = _moveCenterObject(hxPoints)
          return _.merge(this.getDefaultDrawTag(index), {
            active: true,
            name: `tag${index + 1}`,
            points,
          })
        }
      }
      Heatmaptag.initClass()
      return Heatmaptag
    })())
  })
