/** @jsx jsx */
import { format as d3Format } from "d3-format"
import Plotly from "react-plotly.js"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react" // eslint-disable-line no-unused-vars
import { css, jsx } from "@emotion/react"
import axios from "axios"
import { useAsync } from "react-async"
import UcLoader from "../../../components/loaders/UcLoader"
import Sensor from "../../../lib/org/sensor"
import Company from "../../../lib/org/company"
import Store from "../../../lib/org/store"
import { DateTime } from "luxon"

const fetchSensors = async ({ storeId }) => {
  const response = await axios.get(`/api/1.0/camera?storeId=${storeId}`)
  if (response.data) {
    return response.data.map((d) => new Sensor(d)).sort((a, b) => a.name.localeCompare(b.name))
  } else {
    throw new Error("No sensor")
  }
}

const fetchHealthLog = async ({ sensorId, from, to }) => {
  let preHealth = []
  {
    // get initial state
    const response = await axios.get(
      `/api/1.0/activitylog/camera/${sensorId}?name=health&to=${encodeURIComponent(
        from.toISO()
      )}&limit=1&order=desc`
    )
    if (response.data) {
      preHealth = response.data
    }
  }
  const response = await axios.get(
    `/api/1.0/activitylog/camera/${sensorId}?name=health&from=${encodeURIComponent(
      from.toISO()
    )}&to=${encodeURIComponent(to.toISO())}`
  )
  if (response.data) {
    return preHealth.concat(response.data)
  } else {
    throw new Error("No healthlog")
  }
}

const fetchTrafficLog = async ({ sensorId, from, to }) => {
  const response = await axios.get(
    `/api/1.0/activitylog/camera/${sensorId}?name=network traffic&from=${encodeURIComponent(
      from.toISO()
    )}&to=${encodeURIComponent(to.toISO())}`
  )

  if (response.data) {
    return response.data
  } else {
    throw new Error("No trafficlog")
  }
}

const fetchBizHours = (storeId, from, to) => {
  const [bizTimes, setBizTimes] = useState(null)

  useEffect(() => {
    const getBizTimes = async () => {
      const res = await axios.get(`/api/1.0/store/${storeId}/schedule/query?from=${from}&to=${to}`)
      const data = await res.data
      setBizTimes(data)
    }
    if (!bizTimes) {
      getBizTimes()
    }
  }, [])
  return bizTimes
}

const getBhDateTimes = (store, from, to) => {
  let cnt = 0
  const timeModel = (time) =>
    time.c.year.toString() +
    "-" +
    time.c.month.toString().padStart(2, "0") +
    "-" +
    time.c.day.toString().padStart(2, "0") +
    "T" +
    time.c.hour.toString().padStart(2, "0") +
    ":" +
    time.c.minute.toString().padStart(2, "0") +
    ":" +
    time.c.second.toString().padStart(2, "0")

  const bizTimes = fetchBizHours(store.id, timeModel(from), timeModel(to))

  const businessHours = store.schedule.businessHours

  const includeBhs = []
  const excludeBhs = []
  const dayOfWeek = []

  if (businessHours.active && bizTimes) {
    if (businessHours.dayOfWeek.length > 1) {
      for (let time of businessHours.dayOfWeek) {
        dayOfWeek.push(time)
      }
    } else {
      for (let day = 0; day < 7; day++) {
        dayOfWeek.push(businessHours.dayOfWeek[0])
      }
    }

    for (let iter = from; iter.diff(to, "day") <= 0; iter = iter.plus({ day: 1 })) {
      let time = bizTimes.bizHours[cnt]

      const openDt = moment.parseZone(time[0])
      const closeDt = moment.parseZone(time[1])
      let [openDate, openHour, openMinute] = [openDt.date(), openDt.hours(), openDt.minutes()]
      let [closeHour, closeMinute] = [closeDt.hours(), closeDt.minutes()]

      if (iter.c.day === openDate) {
        if (cnt < bizTimes.bizHours.length - 1) {
          cnt++
        } else {
          cnt = 0
        }

        if (openHour * 60 + openMinute > closeHour * 60 + closeMinute) {
          excludeBhs.push([
            iter.set({ hour: closeHour, minute: closeMinute }),
            iter.set({ hour: openHour, minute: openMinute }),
          ])

          includeBhs.push([iter.set({ hour: openHour, minute: openMinute }), iter.endOf("day")])
          includeBhs.push([iter.startOf("day"), iter.set({ hour: closeHour, minute: closeMinute })])
        } else {
          excludeBhs.push([iter.startOf("day"), iter.set({ hour: openHour, minute: openMinute })])
          excludeBhs.push([iter.set({ hour: closeHour, minute: closeMinute }), iter.endOf("day")])
          includeBhs.push([
            iter.set({ hour: openHour, minute: openMinute }),
            iter.set({ hour: closeHour, minute: closeMinute }),
          ])
        }
      }

      if (iter.c.day !== openDate) {
        ;[openHour, openMinute] = [0, 0][(closeHour, closeMinute)] = [23, 59]

        excludeBhs.push([iter.startOf("day"), iter.set({ hour: openHour, minute: openMinute })])
        excludeBhs.push([iter.set({ hour: closeHour, minute: closeMinute }), iter.endOf("day")])
      }
    }
  }
  return [includeBhs, excludeBhs]
}

export const List = ({ company, store, from, to, parent, drawOnBh }) => {
  const { data: sensors } = useAsync({ promiseFn: fetchSensors, storeId: store._id, watch: store })

  const bhDateTimes = getBhDateTimes(store, from, to)

  if (!sensors) {
    return null
  }

  const shapes = bhDateTimes[1].map((range) => ({
    type: "rect",
    layer: drawOnBh ? "below" : "above",
    xref: "x",
    yref: "paper",
    x0: range[0].toJSDate(),
    x1: range[1].toJSDate(),
    y0: 0,
    y1: 0.97,
    fillcolor: "#d3d3d3",
    opacity: 1,
    line: {
      width: 0,
    },
  }))

  return sensors
    .filter((s) => !s.isVirtual)
    .map((sensor) => (
      <Item
        drawOnBh={drawOnBh}
        key={sensor._id}
        company={company}
        store={store}
        sensor={sensor}
        from={from}
        to={to}
        shapes={shapes}
        parent={parent}
      />
    ))
}

List.propTypes = {
  company: PropTypes.instanceOf(Company).isRequired,
  store: PropTypes.instanceOf(Store).isRequired,
  from: PropTypes.instanceOf(DateTime).isRequired,
  to: PropTypes.instanceOf(DateTime).isRequired,
  parent: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  drawOnBh: PropTypes.bool,
}

const basicListItem = css`
  & {
    display: table-cell;
    position: relative;
  }
`

const snapshotWidth = css`
  width: 100px;
`
const snapshotElementContainer = css`
  & {
    ${basicListItem};
    ${snapshotWidth};
    vertical-align: middle;
    padding: 0 0 0 0 !important;
  }
`
const snapshotStyle = css`
  ${snapshotWidth};
  height: 75px;
`

const nameContainer = css`
  & {
    :hover {
      overflow: visible;
    }
    ${snapshotWidth}
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const plotElementContainer = css`
  & {
    ${basicListItem}
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
    width: 100%;
    height: 150px;
  }
`
export const Item = ({ company, store, sensor, from, to, shapes, parent, drawOnBh }) => {
  const watchFn = (props, prevProps) =>
    !(props.from.equals(prevProps.from) && props.to.equals(prevProps.to))
  let {
    data: healthLog,
    isInitial: isHealthLogInitial,
    isPending: isHealthLogPending,
  } = useAsync({
    promiseFn: fetchHealthLog,
    watchFn,
    sensorId: sensor._id,
    from,
    to,
  })
  let {
    data: trafficLog,
    isInitial: isTrafficLogInitial,
    isPending: isTrafficLogPending,
  } = useAsync({
    promiseFn: fetchTrafficLog,
    watchFn,
    sensorId: sensor._id,
    from,
    to,
  })

  if (!trafficLog || isTrafficLogInitial) {
    isTrafficLogPending = true
    trafficLog = []
  }
  if (!healthLog || isHealthLogInitial) {
    isHealthLogPending = true
    healthLog = []
  }

  return (
    <div
      css={css`
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: rgb(236, 236, 236);
      `}
    >
      <div css={snapshotElementContainer}>
        <img css={snapshotStyle} src={sensor.snapshotUrl()} />
        <div css={nameContainer}>{sensor.name}</div>
      </div>
      <div css={plotElementContainer}>
        <Plot
          drawOnBh={drawOnBh}
          loading={isHealthLogPending || isTrafficLogPending}
          parent={parent}
          store={store}
          sensor={sensor}
          trafficLog={trafficLog}
          healthLog={healthLog}
          from={from}
          to={to}
          shapes={shapes}
        />
        {/* <TrafficPlot */}
        {/*   loading={isTrafficLogPending} */}
        {/*   parent={parent} */}
        {/*   store={store} */}
        {/*   sensor={sensor} */}
        {/*   trafficLog={trafficLog} */}
        {/*   from={from} */}
        {/*   to={to} */}
        {/* /> */}
      </div>
    </div>
  )
}

Item.propTypes = {
  company: PropTypes.instanceOf(Company).isRequired,
  store: PropTypes.instanceOf(Store).isRequired,
  sensor: PropTypes.instanceOf(Sensor).isRequired,
  from: PropTypes.instanceOf(DateTime).isRequired,
  to: PropTypes.instanceOf(DateTime).isRequired,
  shapes: PropTypes.arrayOf(PropTypes.object),
  parent: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  drawOnBh: PropTypes.bool,
}

const NoData = () => (
  <div className="no-data-container">
    <div className="align-wrapper">
      <div className="center-wrapper">No Data</div>
    </div>
  </div>
)

const siFormatter = d3Format(".2s")
const accumulateTraffic = (overallLog, getFunc) => {
  let accum = 0
  let prevDate = null
  return overallLog.map((t) => {
    const curDate = DateTime.fromISO(t.at)
    if (prevDate && prevDate.day != curDate.day) {
      accum = 0
    }
    prevDate = curDate
    accum += getFunc(t)
    return siFormatter(accum)
  })
}

const Plot = ({
  loading,
  drawOnBh,
  store,
  sensor,
  parent,
  trafficLog,
  healthLog,
  from,
  to,
  shapes = [],
}) => {
  const [parentWidth, setParentWidth] = useState(parent.current.clientWidth)
  useEffect(() => {
    const resizeHandler = () => {
      if (parent.current.clientWidth > 0) {
        setParentWidth(parent.current.clientWidth)
      }
    }
    addEventListener("resize", resizeHandler)
    return () => removeEventListener("resize", resizeHandler)
  }, [])

  if (trafficLog.length <= 0 && healthLog.length <= 0 && !loading) {
    return <NoData />
  }

  const overallLog = trafficLog
    .filter((t) => t.what.name === "overall")
    .sort((a, b) => a.at.localeCompare(b.at))
    .map((l) => ({
      ...l,
      at: DateTime.fromISO(l.at).setZone(store.timezone).toISO(),
    }))

  const retracheLog = trafficLog
    .filter((t) => t.what.name === "retrache")
    .sort((a, b) => a.at.localeCompare(b.at))
    .map((l) => ({
      ...l,
      at: DateTime.fromISO(l.at).setZone(store.timezone).toISO(),
    }))
  healthLog = healthLog
    .map((l) => ({
      ...l,
      at: DateTime.fromISO(l.at).setZone(store.timezone).toISO(),
    }))
    .sort((a, b) => a.at.localeCompare(b.at))

  if (healthLog.length > 0) {
    // 시작 끝 부분을 그래프로 그리기 위해서 더미 데이터 삽입
    if (DateTime.fromISO(healthLog[0].at).diff(from, "hour") < 0) {
      healthLog[0].at = from.toISO()
    }

    const lastLog = healthLog[healthLog.length - 1]
    const lastDateTime = DateTime.now().diff(to, "hour") < 0 ? DateTime.now() : to
    if (lastLog.what.retracheConnection) {
      healthLog.push({
        ...lastLog,
        at: lastDateTime.setZone(store.timezone).toISO(),
      })
    }
  }

  const powerTrace = {
    type: "scatter",
    mode: "lines",
    opacity: 1,
    name: "Power",
    fill: "tozeroy",
    fillcolor: "#D9534F70",
    x: healthLog.map((l) => l.at),
    y: healthLog.map((l) => (l.what.power ? 1 : 0)),
    line: {
      width: 2,
      shape: "hv",
      color: "#fa6641",
    },
    yaxis: "y2",
  }

  const internetTrace = {
    type: "scatter",
    opacity: 1,
    mode: "lines",
    name: "Internet",
    fill: "tozeroy",
    fillcolor: "#005e7e70",
    x: healthLog.map((l) => l.at),
    y: healthLog.map((l) => (l.what.internet ? 1 : 0)),
    line: {
      width: 2,
      shape: "hv",
      color: "#005e7e",
      // dash: "dot"
    },
    yaxis: "y2",
  }

  const txTrace = {
    visible: false,
    hovertext: accumulateTraffic(overallLog, (t) => t.what.txBytes),
    type: "bar",
    showlegend: true,
    name: "Tx",
    x: overallLog.map((t) => t.at),
    y: overallLog.map((t) => t.what.txBytes),
    xperiod: 3600000,
  }

  const rxTrace = {
    type: "bar",
    visible: false,
    hovertext: accumulateTraffic(overallLog, (t) => t.what.rxBytes),
    showlegend: true,
    name: "Rx",
    x: overallLog.map((t) => t.at),
    y: overallLog.map((t) => t.what.rxBytes),
    xperiod: 3600000,
  }

  const totalTrace = {
    visible: "legendonly",
    type: "bar",
    hovertext: accumulateTraffic(overallLog, (t) => t.what.rxBytes + t.what.txBytes),
    hovertemplate: "%{y}, Daily: %{hovertext}",
    showlegend: true,
    name: "Tx+Rx",
    x: overallLog.map((t) => t.at),
    y: overallLog.map((t) => t.what.rxBytes + t.what.txBytes),
    xperiod: 3600000,
  }

  const rtTxTrace = {
    visible: false,
    hovertext: accumulateTraffic(retracheLog, (t) => t.what.txBytes),
    type: "bar",
    showlegend: true,
    name: "RT Tx",
    x: retracheLog.map((t) => t.at),
    y: retracheLog.map((t) => t.what.txBytes),
    xperiod: 3600000,
  }

  const rtRxTrace = {
    type: "bar",
    visible: false,
    hovertext: accumulateTraffic(retracheLog, (t) => t.what.rxBytes),
    showlegend: true,
    name: "RT Rx",
    x: retracheLog.map((t) => t.at),
    y: retracheLog.map((t) => t.what.rxBytes),
    xperiod: 3600000,
  }

  const rtTotalTrace = {
    type: "bar",
    hovertemplate: "%{y}, Daily: %{hovertext}",
    hovertext: accumulateTraffic(retracheLog, (t) => t.what.rxBytes + t.what.txBytes),
    showlegend: true,
    name: "RT Tx+Rx",
    x: retracheLog.map((t) => t.at),
    y: retracheLog.map((t) => t.what.rxBytes + t.what.txBytes),
    xperiod: 3600000,
  }

  const data = [
    powerTrace,
    internetTrace,
    totalTrace,
    rtTotalTrace,
    txTrace,
    rxTrace,
    rtTxTrace,
    rtRxTrace,
  ]

  const layout = {
    width: parentWidth - 30 - 100 - 20,
    margin: {
      l: 35,
      r: 10,
      t: 10,
      b: 35,
    },
    hovermode: "x unified",
    xaxis: {
      range: [from.toJSDate(), to.toJSDate()],
      type: "date",
      tickformat: "%m-%d %a",
      hoverformat: "%m-%d %H:%M %a",
      tick0: from.toJSDate(),
      ticklabeloverflow: "allow",
      //tickwidth: 1,
      gridwidth: 1,
      gridcolor: "#bbbbbb",
      dtick: 86400000,
    },
    yaxis2: {
      //visible: false,
      domain: [0.6, 1],
      showline: false,
      hoverformat: "[0]",
      rangemode: "nonnegative",
      tickmode: "array",
      ticktext: ["Off", "On"],
      tickvals: [0, 1],
      showticklabels: true,
      //zerolinecolor: "#eee"
    },
    yaxis: {
      domain: [0, 0.5],
      anchor: "x1",
      hoverformat: ".2s",
    },
    grid: { rows: 2, columns: 1, subplots: ["xy", "xy2"], roworder: "bottom to top" },
    shapes,
  }

  const config = {
    displayModeBar: false,
  }

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
      `}
    >
      <div
        css={css`
          position: absolute;
          left: 44%;
          top: 40%;
          height: 100%;
        `}
      >
        <UcLoader loading={loading} />
      </div>
      <Plotly
        data={data}
        style={{ width: "100%", height: "100%", opacity: loading ? 0.2 : 1 }}
        layout={layout}
        config={config}
        useResizeHandler={true}
      />
    </div>
  )
}

Plot.propTypes = {
  loading: PropTypes.bool,
  drawOnBh: PropTypes.bool,
  healthLog: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      what: PropTypes.shape({
        id: PropTypes.string.isRequired,
        internet: PropTypes.bool.isRequired,
        power: PropTypes.bool.isRequired,
        retracheConnection: PropTypes.bool,
      }),
      at: PropTypes.string.isRequired,
    })
  ),
  trafficLog: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      what: PropTypes.shape({
        id: PropTypes.string.isRequired,
        rxBytes: PropTypes.number.isRequired,
        txBytes: PropTypes.number.isRequired,
      }),
      at: PropTypes.string.isRequired,
    })
  ),
  store: PropTypes.instanceOf(Store).isRequired,
  sensor: PropTypes.instanceOf(Sensor).isRequired,
  from: PropTypes.instanceOf(DateTime).isRequired,
  to: PropTypes.instanceOf(DateTime).isRequired,
  shapes: PropTypes.arrayOf(PropTypes.object),
  parent: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
}
