"use strict"

export const featuresTooltip = [
  "APPR:Appearance, GA:Gender/Age, FD:Face Dashboard",
  "FF:Legacy Footfall, mFF:Meta Footfall, tFF:Track Footfall, fFF:Face Footfall",
  "ZT:Zone Traffic, HM:Heatmap, DM:Direction Map, OC: Occupancy, Q:Queue",
].join("\n")

export const parseFeatures = function (f) {
  const rs = []
  if (!f) return rs
  if (f.groupFace) {
    rs.push("APPR")
  }
  if (f.rtFace) {
    rs.push("FD")
  }
  if (f.face) {
    rs.push("GA")
  }
  if (f.counter && !f.trackcounter && !f.metacounter && !f.facecounter) {
    rs.push("FF")
  }
  if (f.counter && f.trackcounter) {
    rs.push("tFF")
  }
  if (f.counter && f.metacounter) {
    rs.push("mFF")
  }
  if (f.counter && f.facecounter) {
    rs.push("fFF")
  }
  if (f.zonetraffic) {
    rs.push("ZT")
  }
  if (f.occupancy) {
    rs.push("OC")
  }
  if (f.heatmaps && !f.trafficmap) {
    rs.push("HM")
  }
  if (f.heatmaps && f.trafficmap) {
    rs.push("tHM")
  }
  if (f.directionmap) {
    rs.push("DM")
  }
  if (f.flowmap) {
    rs.push("FM")
  }
  if (f.queue) {
    rs.push("Q")
  }
  return rs
}

//INFO GA 파트와 나머지 파트를 구분하여 표시기 위해서 분리함.
export const parseFeaturesTwoLine = function (f) {
  const allfs = parseFeatures(f)
  let fga = []
  let fothers = allfs
  const ga = allfs.indexOf("GA")
  if (ga >= 0) {
    fga = allfs.slice(0, ga + 1)
    fothers = allfs.slice(ga + 1)
  }
  return [fga, fothers]
}
