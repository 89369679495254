/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

import Promise from "bluebird"

import { defaultWeight, kStandardLabels } from "../../../components/counter-label"
import { getColors } from "../../../components/util/colors"
import env from "../../../../config/environment"

const storeFormCtrl = function ($scope, QueueSrv, Locale, HeaderSrv) {
  return {
    abnTimePeriod: {
      calMinute(time) {
        return parseInt(time.hour) * 60 + parseInt(time.minute)
      },

      isWithinBizHours() {
        const { abnTimePeriod } = $scope.form.schedule
        if (!abnTimePeriod.active) {
          return true
        }
        if (!$scope.form.schedule.businessHours.active) {
          return true
        }

        let times = [
          { id: 0, name: "BO", minute: this.calMinute($scope.open) },
          { id: 1, name: "MO", minute: this.calMinute(abnTimePeriod.open) },
          { id: 2, name: "MC", minute: this.calMinute(abnTimePeriod.close) },
          { id: 3, name: "BC", minute: this.calMinute($scope.close) },
        ]
        times = _.sortBy(times, ["minute", "id"])

        let inHours = true
        times.forEach(function (t, i, array) {
          if (array[(i + 1) % array.length].id !== (t.id + 1) % array.length) {
            return (inHours = false)
          }
        })

        return inHours
      },

      isValid() {
        const { abnTimePeriod } = $scope.form.schedule
        const { active } = abnTimePeriod
        const openHour = parseInt(abnTimePeriod.open.hour)
        const openMinute = parseInt(abnTimePeriod.open.minute)
        const closeHour = parseInt(abnTimePeriod.close.hour)
        const closeMinute = parseInt(abnTimePeriod.close.minute)
        if (
          (active && openHour === 24 && openMinute > 0) ||
          (closeHour === 24 && closeMinute > 0) ||
          (openHour === closeHour && openMinute === closeMinute)
        ) {
          return false
        } else {
          return true
        }
      },

      handleChange() {
        const { abnTimePeriod } = $scope.form.schedule
        if (abnTimePeriod.open.hour === "24") {
          abnTimePeriod.open.minute = "00"
        }
        if (abnTimePeriod.close.hour === "24") {
          abnTimePeriod.close.minute = "00"
        }
        if (
          abnTimePeriod.open.hour === abnTimePeriod.close.hour &&
          abnTimePeriod.open.minute === abnTimePeriod.close.minute
        ) {
          return $scope.form_create.abnTimePeriod.$setValidity("AbnTimePeriod", false)
        } else if (!this.isWithinBizHours()) {
          return $scope.form_create.abnTimePeriod.$setValidity("AbnTimePeriod", false)
        } else {
          return $scope.form_create.abnTimePeriod.$setValidity("AbnTimePeriod", true)
        }
      },

      toForm(value) {
        let closeMinute, openMinute
        if (!$scope.form) {
          $scope.form = {}
        }
        let openHour = "11"
        let closeHour = "17"
        if (!value || !value.active || value.dayOfWeek.length <= 0) {
          const { schedule } = $scope.form
          if (
            schedule &&
            schedule.businessHours.active &&
            schedule.businessHours.dayOfWeek &&
            schedule.businessHours.dayOfWeek.length > 0
          ) {
            openHour = parseInt(schedule.businessHours.dayOfWeek[0].open.split(":")[0]) + 1
            closeHour = parseInt(schedule.businessHours.dayOfWeek[0].close.split(":")[0]) - 1
            if (openHour > 24) {
              openHour = 0
            }
            if (closeHour < 0) {
              closeHour = 24
            }
            openHour = `${openHour}`.padStart(2, "0")
            closeHour = `${closeHour}`.padStart(2, "0")
          }

          return {
            active: false,
            open: {
              hour: openHour,
              minute: "00",
            },
            close: {
              hour: closeHour,
              minute: "00",
            },
          }
        }
        ;[openHour, openMinute] = Array.from(value.dayOfWeek[0].open.split(":"))
        ;[closeHour, closeMinute] = Array.from(value.dayOfWeek[0].close.split(":"))
        return {
          active: value.active,
          open: {
            hour: openHour,
            minute: openMinute,
          },
          close: {
            hour: closeHour,
            minute: closeMinute,
          },
        }
      },

      toValue(form) {
        return {
          active: form.active,
          dayOfWeek: [
            {
              open: `${form.open.hour}:${form.open.minute}`,
              close: `${form.close.hour}:${form.close.minute}`,
            },
          ],
        }
      },

      beforeSubmit() {
        const { form } = $scope
        const { abnTimePeriod } = form.schedule
        const isValid = this.isValid()
        if (isValid) {
          try {
            form.schedule.abnTimePeriod = this.toValue(abnTimePeriod)
          } catch (err) {
            console.warn(err)
            return false
          }
        } else {
          $scope.form.$valid = false
        }

        return isValid
      },

      afterSubmit() {
        const { form } = $scope
        const { abnTimePeriod } = form.schedule
        return (form.schedule.abnTimePeriod = this.toForm(abnTimePeriod))
      },
    },

    gates: {
      removedQueue: [],
      addField: "",

      _isDuplicate(name) {
        if (_.find($scope.form.gates, (i) => i.name === name)) {
          return true
        }
        return false
      },

      add() {
        if (this.addField == null || this.addField.length <= 0) {
          return
        }
        if (this._isDuplicate(this.addField)) {
          return
        }
        $scope.form.gates.push({ name: this.addField, dirty: true })
        return (this.addField = "")
      },

      activeModify(gate) {
        if (!_.find($scope.form.gates, (i) => i.activeEdit)) {
          gate.editField = gate.name
          return (gate.activeEdit = true)
        }
      },

      modify(gate) {
        if (!this._isDuplicate(gate.editField) || gate.editField === gate.name) {
          gate.name = gate.editField
          gate.activeEdit = false
          return (gate.dirty = true)
        }
      },

      remove(gate) {
        const { gates } = $scope.form
        this.removedQueue.push(gate)
        return _.remove(gates, (i) => i.name === gate.name)
      },

      beforeSubmit() {
        // AddField가 활성화되어 있을 때는 Submit이 안되도록 한다.
        if (document.activeElement.getAttribute("ng-model") === "storeFormCtrl.gates.addField") {
          this.add()
          return false
        }
        return true
      },

      afterSubmit() {
        const { gates } = $scope.form
        for (var gate of Array.from(gates)) {
          if (gate.activeEdit) {
            this.modify(gate)
            return false
          }
        }
        _.remove(gates, (gate) => gate.activeEdit)

        const self = this
        _.forEach(self.removedQueue, (gate) =>
          QueueSrv.Gate().delete({ storeId: $scope.form._id, gateId: gate.rowId })
        )
        self.removedQueue = []
        _.forEach(gates, function (gate) {
          if (gate.dirty) {
            delete gate.activeEdit
            delete gate.dirty
            delete gate.editField
            gate.storeId = $scope.form._id
            const params = {
              storeId: $scope.form._id,
            }
            return QueueSrv.Gate().save(params, gate)
          }
        })
        return true
      },
    },
  }
}

const App = angular.module("uCountitUiApp")
App.controller(
  "StoreCtrl",
  function (
    $scope,
    $rootScope,
    $filter,
    $window,
    $cookieStore,
    Auth,
    StoreAdminSrv,
    ApiSrv,
    Locale,
    Upload,
    NgTableParams,
    ngDialog,
    FaceDeviceSrv,
    StoreSalesSrv,
    QueueSrv,
    StoreNotificationFormCtrl,
    DateTimeSrv,
    StoreGroupSrv,
    HeaderSrv,
    me,
    SeedCounter,
    UtilSrv,
    DataOperationSrv,
    CameraConfigSrv,
    CameraAdminSrv,
    CameraDeleteSrv,
    usSpinnerService
  ) {
    let hideLoading
    $scope.me = me
    if (me.isStore()) {
      return
    }

    $scope.isLocalgrey = env.isLocalgrey
    $scope.storeFormCtrl = storeFormCtrl($scope, QueueSrv)
    $scope.createNotiForm = function (store) {
      $scope.isOpenInputDlg = false
      $scope.notiFormCtrl = new StoreNotificationFormCtrl(store)
      return $scope.notiFormCtrl.openDialog()
    }

    $scope.openVirtualFootfall = function (store) {
      $scope.isOpenInputDlg = false
      $scope.isOpenVirtualFootfall = true
      return ($scope.selectedStore = store)
    }

    $scope.openScheduleForm = function (store) {
      $scope.isOpenInputDlg = false
      $scope.isOpenScheduleForm = true
      return ($scope.selectedStore = store)
    }
    $scope.apprModes = ["none", "appr", "algoA", "algoB"]
    $scope.banNameList = _.join(_.map(kStandardLabels, "i18nKey"), ",")
    $scope.kDefaultStoreGroupName = UtilSrv.getDefaultStoreGroupName()

    $scope.itemName = Locale.string("Store")
    $scope.companyPlaceholder = Locale.string("choose a company")
    $scope.labelTooltip = Locale.string("first label is always active")
    const strActive = Locale.string("Active")
    const strInactive = Locale.string("Inactive")
    $scope.inputDlgTitle = "Create"
    const defaultImagePath = "/assets/images/thumbnail"
    let original = null

    const config = $rootScope.globalConfig
    $scope.uploadUrl = `${config.apipath}/store/thumbnail`

    $scope.MIN_LABEL = 5
    $scope.hours = _.range(25).map(function (num) {
      if (num < 10) {
        return "0" + num.toString()
      } else {
        return num.toString()
      }
    })

    $scope.minutes = ["00", "10", "20", "30", "40", "50"]
    $scope.open = {}
    $scope.close = {}
    $scope.seedCounterList = []
    $scope.companySelector = { selected: {} }

    const getLabelColors = (index) => getColors(index, "label")

    const setDatetimeConfiguration = function (pageOptions, pickerOptions) {
      if (!pageOptions) {
        return
      }
      if (!pickerOptions) {
        return
      }

      const configuration = DateTimeSrv.getThisYearConfiguration()
      pageOptions.dt = configuration.dt
      return (pickerOptions.rangeMaxDate = configuration.maxDate)
    }

    // Default Configuration
    $scope.pageOptions = {}
    $scope.pickerOptions = {
      sampling: "day",
      rangeMaxDate: moment(),
    }
    setDatetimeConfiguration($scope.pageOptions, $scope.pickerOptions)

    const DEFAULT_TIMEZONE = "Europe/London"

    $scope.$watch("showSelectedOrganization", (value) => $scope.reload())

    const allSalesDriverPromisify = new Promise(function (resolve, reject) {
      if ($scope.isLocalgrey) {
        return resolve([])
      } else {
        return StoreSalesSrv.getAllDrivers(function (err, data) {
          if (err) {
            return reject(err)
          } else {
            return resolve(data)
          }
        })
      }
    })

    const setSalesDriver = function (active, driverName) {
      const sales = { active: active != null ? active : false }

      return allSalesDriverPromisify.then(function (drivers) {
        const name = active ? (driverName != null ? driverName : "standard") : "NONE"
        sales.driver = _.find(drivers, { name: name })
        sales.driverName = sales.driver != null ? sales.driver.name : undefined
        return sales
      })
    }

    $scope.checkSalesActive = () =>
      setSalesDriver(
        $scope.form.sales.active,
        $scope.companySelector.selected.features.sales.driverName
      ).then((sales) => ($scope.form.sales = sales))

    $scope.selectSalesDriver = (selectedDriver) =>
      setSalesDriver(true, selectedDriver.name).then((sales) => ($scope.form.sales = sales))

    $scope.selectCompany = function (selectedCompany) {
      if ($scope.inputDlgTitle === "Create") {
        $scope.form.heatmapWeight.pass =
          (selectedCompany.heatmapWeight != null
            ? selectedCompany.heatmapWeight.pass
            : undefined) != null
            ? selectedCompany.heatmapWeight != null
              ? selectedCompany.heatmapWeight.pass
              : undefined
            : 1
        $scope.form.heatmapWeight.dwell =
          (selectedCompany.heatmapWeight != null
            ? selectedCompany.heatmapWeight.dwell
            : undefined) != null
            ? selectedCompany.heatmapWeight != null
              ? selectedCompany.heatmapWeight.dwell
              : undefined
            : 1
      }

      const isActive =
        selectedCompany.features.sales.active &&
        ($scope.form.sales != null ? $scope.form.sales.active : undefined)
      return setSalesDriver(isActive, selectedCompany.features.sales.driverName).then(
        (sales) => ($scope.form.sales = sales)
      )
    }

    const initialForm = function () {
      const form = {
        name: "",
        timezone: "",
        location: "",
        tel: "",
        businessType: "store",
        company: "",
        companyVirtualFF: false,
        active: true,
        thumbnail: {
          useSnapshot: false,
          path: "store01.gif",
        },
        thumbnailUrl: "",
        labels: [],
        specialLabels: [],
        activeForm: false,
        gates: [],
        schedule: {
          businessHours: {
            active: false,
            dayOfWeek: [
              {
                open: "00:00",
                close: "24:00",
              },
            ],
          },
          convert: {
            footfall: false,
            queue: false,
            trafficHeatmap: false,
          },
          abnTimePeriod: $scope.storeFormCtrl.abnTimePeriod.toForm(),
        },
        queue: {
          dwellTime: 8,
          enableWalkoff: true,
        },
        seedCounterOfGA: "Footfall Entrance",
        addDefaultStoreGroup: true,
        heatmapWeight: {
          pass: 1,
          dwell: 1,
        },
        maintenanceMode: {
          agency: false,
          company: false,
        },
        weather: {
          locationId: "",
        },
        apprAnalysis: {
          mode: "none",
          minSimilarity: 74,
          staffRule: "longStay",
          staffStay: 3, // 3 hours
        },
      }

      kStandardLabels.forEach(
        (slabel, idx) =>
          (form.specialLabels[idx] = {
            name: slabel.name,
            color: slabel.color,
            rgbcolor: `rgb(${slabel.color})`,
            weights: slabel.weights,
          })
      )

      for (
        let i = 0, end = $scope.MIN_LABEL, asc = 0 <= end;
        asc ? i < end : i > end;
        asc ? i++ : i--
      ) {
        var labelColor = getLabelColors(i)
        form.labels[i] = {
          name: `Label ${i + 1}`,
          color: labelColor,
          active: false,
          rgbcolor: `rgb(${labelColor})`,
          weights: defaultWeight,
        }
      }

      $scope.thumbnailUrl = ""
      angular.element("#thumbnail").attr("src", `${defaultImagePath}/${form.thumbnail.path}`)
      $scope.companySelector.selected =
        ($scope.companies != null ? $scope.companies.length : undefined) === 1
          ? $scope.companies[0]
          : _.find($scope.companies, { _id: Auth.clientInfo("admin.stores.lastSelectedCompanyId") })
      $scope.form_create.$setPristine()
      $scope.errorMessage = ""
      $scope.messageOfSubsampling = {}
      $scope.showConvertMessage = false

      return form
    }

    const getTimezone = function () {
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? DEFAULT_TIMEZONE
      if (timezone == "UTC") {
        timezone = DEFAULT_TIMEZONE
      }
      return timezone
    }

    $scope.canSubmit = function () {
      if (!$scope.isOpenInputDlg) {
        return false
      }

      $scope.form._companyId =
        $scope.companySelector.selected != null ? $scope.companySelector.selected._id : undefined
      if ($scope.isCopyStore()) {
        return $scope.form._companyId !== original._companyId
      } else {
        return $scope.form_create.$valid && !angular.equals($scope.form, original)
      }
    }

    $scope.submitForm = function ($event) {
      let field, value
      for (field in $scope.storeFormCtrl) {
        value = $scope.storeFormCtrl[field]
        if (value.beforeSubmit != null && !value.beforeSubmit($event)) {
          $event.preventDefault()
          return
        }
      }

      const dbColorPattern = /\d+,\d+,\d+/
      $scope.form.labels.forEach(function (label) {
        label.color = dbColorPattern.exec(label.rgbcolor)
        if (label.color) {
          label.color = label.color[0]
        }
      })

      $scope.form.specialLabels.forEach(function (slabel) {
        slabel.color = dbColorPattern.exec(slabel.rgbcolor)
        if (slabel.color) {
          slabel.color = slabel.color[0]
        }
      })

      if ($scope.form.weather.locationId === "") {
        $scope.form.weather.locationId = null
      }

      let doSubmit = null
      if ($scope.inputDlgTitle === "Create") {
        doSubmit = $scope.createItem.bind(null, $scope.form)
      } else if ($scope.isCopyStore()) {
        delete $scope.form._id
        delete $scope.form.id
        doSubmit = $scope.createItem.bind(null, $scope.form)
      } else {
        doSubmit = $scope.updateItem.bind(null, $scope.form)
      }

      return doSubmit().then((store) =>
        (() => {
          const result = []
          for (field in $scope.storeFormCtrl) {
            value = $scope.storeFormCtrl[field]
            if (value.afterSubmit != null) {
              $scope.form._id = store._id
              result.push(value.afterSubmit())
            } else {
              result.push(undefined)
            }
          }
          return result
        })()
      )
    }

    const makeErrorMessage = function (errorObj) {
      if (!errorObj.body) {
        return errorObj.statusText
      } else if (errorObj.data.code) {
        switch (errorObj.data.code) {
          case 11000:
            return Locale.string("msg_error_storename_duplicated")
          default:
            return errorObj
        }
      } else {
        return "Unknwon error"
      }
    }

    $scope.createItem = (item) =>
      new Promise((resolve, reject) =>
        StoreAdminSrv.save(
          item,
          (sobj, res) =>
            _uploadThumbnail(sobj._id, function (err, data) {
              if (err) {
                console.error(err.message)
              }

              $scope.reload(false).then(() => HeaderSrv.emitter.reload())
              $scope.isOpenInputDlg = false
              Auth.clientInfo(
                "admin.stores.lastSelectedCompanyId",
                $scope.companySelector.selected != null
                  ? $scope.companySelector.selected._id
                  : undefined
              )

              if ($scope.form.addDefaultStoreGroup) {
                return getDefaultStoreGroup(item._companyId)
                  .then((group) => addDefaultStoreGroup(group._id, sobj._id))
                  .then(function () {
                    $scope.$broadcast("reload_storegroup")
                    return resolve(sobj)
                  })
                  .catch((err) => reject(err))
              } else {
                return resolve(sobj)
              }
            }),

          function (error) {
            if (error) {
              $scope.errorMessage = makeErrorMessage(error)
            }
            return reject(error)
          }
        )
      )

    var getDefaultStoreGroup = (companyId) =>
      ApiSrv.getAllStoreGroup({ isCache: false, companyId }).then((groups) =>
        _.find(groups, (group) => UtilSrv.isDefaultStoreGroup(group.name))
      )

    var addDefaultStoreGroup = (groupId, storeId) =>
      new Promise((resolve, reject) =>
        StoreGroupSrv.addMember(
          {
            id: groupId,
            storeId,
          },
          (res) => resolve(),
          (err) => reject(err)
        )
      )

    $scope.showAdditionalOperation = () =>
      $scope.inputDlgTitle === "Create" && $scope.companySelector.selected

    $scope.updateItem = function (item) {
      if (!$scope.thumbnailFiles) {
        delete item.thumbnail
      }
      return new Promise((resolve, reject) =>
        async.waterfall(
          [
            (step) =>
              StoreAdminSrv.update(
                { id: item._id },
                item,
                function (sobj, res) {
                  if (Array.from(me.favorite.stores).includes(item._id)) {
                    ApiSrv.getFavoriteStores({ isCache: false })
                  }
                  return step(null, sobj)
                },
                function (error) {
                  if (error) {
                    return step(makeErrorMessage(error))
                  } else {
                    return step(null, null)
                  }
                }
              ),
          ],
          function (error, sobj) {
            if (error) {
              reject(error)
              return ($scope.errorMessage = error)
            }

            return _uploadThumbnail(sobj._id, function (err, data) {
              $scope.reload(false).then(() => HeaderSrv.emitter.reload())
              const store = ApiSrv.getStore({ id: item._id })
              resolve(store)
              $scope.isOpenInputDlg = false
              if (err) {
                return console.error(err.message)
              }
            })
          }
        )
      )
    }

    $scope.isCompleteList = (list) => list && (list != null ? list.length : undefined) > 1

    $scope.addCustomLabel = function () {
      const { length } = $scope.form.labels
      const labelColor = getLabelColors(length)

      return $scope.form.labels.push({
        name: `Label ${length + 1}`,
        color: labelColor,
        active: true,
        rgbcolor: `rgb(${labelColor})`,
        weights: defaultWeight,
      })
    }

    $scope.sortConf = {
      group: "customLabels",
      animation: 150,
      handle: ".drag-handle",
    }

    $scope.isSavedCustomLabel = (label) => !_.isUndefined(label != null ? label._id : undefined)

    $scope.cancelCustomLabel = (index) => _.pullAt($scope.form.labels, index)

    $scope.openInputDlg = function (item, copyStore) {
      if (copyStore == null) {
        copyStore = false
      }
      if (
        !$scope.companies ||
        ($scope.companies != null ? $scope.companies.length : undefined) === 0
      ) {
        $window.alert(Locale.string("company list is empty"))
      }

      if ($scope.notiFormCtrl != null ? $scope.notiFormCtrl.isOpened : undefined) {
        $scope.notiFormCtrl.closeDialog()
      }
      if ($scope.isOpenVirtualFootfall) {
        $scope.isOpenVirtualFootfall = false
      }
      if ($scope.isOpenScheduleForm) {
        $scope.isOpenScheduleForm = false
      }

      $scope.isOpenInputDlg = true
      if (item != null) {
        $scope.form = _.cloneDeep(item.data)
        $scope.form.schedule.abnTimePeriod = $scope.storeFormCtrl.abnTimePeriod.toForm(
          $scope.form.schedule.abnTimePeriod
        )
        setSalesDriver(
          item.sales != null ? item.sales.active : undefined,
          item.sales != null ? item.sales.driverName : undefined
        ).then(function (sales) {
          $scope.form.sales = sales
          return (original = _.cloneDeep($scope.form))
        })
        $scope.thumbnailUrl = `${item.thumbnailUrl("small")}`
        $scope.companySelector.selected = _.find($scope.companies, { _id: item._companyId })
        delete $scope.form.createdAt
        $scope.form_create.$setDirty()
        $scope.errorMessage = ""
        $scope.messageOfSubsampling = {}
        $scope.showConvertMessage = false
        $scope.form.labels.forEach(function (label) {
          if (label.color) {
            label.rgbcolor = `rgb(${label.color})`
          }
          if (!label.weights) {
            label.weights = defaultWeight
          }
        })

        $scope.form.specialLabels.forEach(function (slabel) {
          if (slabel.color) {
            slabel.rgbcolor = `rgb(${slabel.color})`
          }
          if (!slabel.weights) {
            slabel.weights = defaultWeight
          }
        })

        if (($scope.form.weather != null ? $scope.form.weather.locationId : undefined) == null) {
          $scope.form.weather = { locationId: "" }
        }

        $scope.form.gates = QueueSrv.Gate().query({ storeId: item._id, _: Date.now() })
        $scope.updateSeedCounterList()
        if (copyStore) {
          $scope.inputDlgTitle = "Copy Store"
        } else {
          $scope.inputDlgTitle = "Update"
        }
      } else {
        $scope.inputDlgTitle = "Create"
        $scope.form = initialForm()
        $scope.form.timezone = getTimezone()
      }

      $scope.getStandardLabelName = function (name) {
        const label = _.find(kStandardLabels, ["name", name])
        return Locale.string(label.i18nKey)
      }

      $scope.open = _parseTime(
        ($scope.form.schedule.businessHours.dayOfWeek[0] != null
          ? $scope.form.schedule.businessHours.dayOfWeek[0].open
          : undefined) != null
          ? $scope.form.schedule.businessHours.dayOfWeek[0] != null
            ? $scope.form.schedule.businessHours.dayOfWeek[0].open
            : undefined
          : "00:00"
      )
      $scope.close = _parseTime(
        ($scope.form.schedule.businessHours.dayOfWeek[0] != null
          ? $scope.form.schedule.businessHours.dayOfWeek[0].close
          : undefined) != null
          ? $scope.form.schedule.businessHours.dayOfWeek[0] != null
            ? $scope.form.schedule.businessHours.dayOfWeek[0].close
            : undefined
          : "24:00"
      )

      original = _.cloneDeep($scope.form) //angular.copy $scope.form

      setDatetimeConfiguration($scope.pageOptions, $scope.pickerOptions)
    }

    var _parseTime = function (tm) {
      const _tm = tm.split(":")
      return {
        timeFunc() {
          return this.hour + ":" + this.minute
        },
        hour: _tm[0],
        minute: _tm[1],
      }
    }

    const deleteStore = (storeId) =>
      StoreAdminSrv.delete({ id: storeId }).$promise.catch(function (err) {
        if (err.data != null) {
          return ($scope.errorMessage = err.data)
        }
      })

    // Todo
    // Need to add removing data by storeId
    $scope.removeItem = (item) =>
      ApiSrv.getCameraOfStore({ id: item._id, isCache: false }).then(
        function (cameras) {
          const opt = {
            template: "components/popup/popup-confirm.html",
            closeByEscape: true,
            closeByDocument: true,
            showClose: false,
            className: "ngdialog-theme-default popup-confirm",
            controller: "ucPopupConfirmCtrl",
          }

          if ((cameras != null ? cameras.length : undefined) > 0) {
            opt.data = {
              title: Locale.string("Warning") + "!",
              message: Locale.string("msg_confirm_body_remove_store"),
            }
          } else {
            opt.data = { title: Locale.string("msg_confirm_remove_store") }
          }

          return ngDialog
            .openConfirm(opt)
            .then(function () {
              showLoading("delete_store")

              if ((cameras != null ? cameras.length : undefined) > 0) {
                return Promise.mapSeries(cameras, (camera) =>
                  CameraDeleteSrv.deleteData(camera).then(() =>
                    CameraDeleteSrv.deleteCamera(camera._id)
                  )
                )
              }
            })
            .then(() => deleteStore(item._id))
            .then(function () {
              $scope.reload(false).then(() => HeaderSrv.emitter.reload())
              $scope.$broadcast("reload_storegroup")
              return hideLoading("delete_store")
            })
        },
        function (err) {
          if (err.data != null) {
            return ($scope.errorMessage = err.data)
          }
        }
      )

    $scope.reload = function (isCache) {
      if (isCache == null) {
        isCache = true
      }
      allSalesDriverPromisify.then(
        (drivers) => ($scope.salesdrivers = _.filter(drivers, (driver) => driver.name !== "NONE"))
      )

      return ApiSrv.getAllCompany({ isCache })
        .then((companies) =>
          Promise.all([
            Promise.all(
              _.map(companies, (element) =>
                setSalesDriver(
                  element.sales != null ? element.sales.active : undefined,
                  element.sales != null ? element.sales.driverName : undefined
                ).then(function (sales) {
                  element = element.clone()
                  if (!element.features) {
                    element.features = {}
                  }
                  if (!element.features.sales) {
                    element.features.sales = {}
                  }
                  _.merge(element.features.sales, sales)
                  return element
                })
              )
            ),
            ApiSrv.getAllStore({ isCache: isCache }),
            ApiSrv.getAllAgency({ isCache: isCache }),
          ])
        )
        .then((params) =>
          HeaderSrv.fetchCurrentStore().then(function (orgInfo) {
            const companies = params[0]
            const stores = params[1]
            const agencies = params[2]
            $scope.agencies = agencies
            $scope.companies = companies.map(function (c) {
              c.indexName = `${c.name} [${c.index}]`
              return c
            })
            let storeList
            if ($scope.showSelectedOrganization) {
              storeList = $filter("filter")(stores, function (store) {
                switch (orgInfo.orgType) {
                  case "company":
                    return orgInfo._id === store._companyId
                  case "storegroup":
                    return orgInfo.storeList.indexOf(store._id) !== -1
                  case "store":
                    return orgInfo._id === store._id
                  default:
                    return false
                }
              })
            } else {
              storeList = stores
            }
            for (var store of Array.from(storeList)) {
              store.company = _.find($scope.companies, { _id: store._companyId })
              store.agency = _.find($scope.agencies, { _id: store.company._agencyId })
              if (store.timezone) {
                store.tzstr = moment().tz(store.timezone).format("z Z")
              }
              store.indexName = `${store.name} [${store.index}]`
            }
            return loadItems(storeList)
          })
        )
        .then(() => ApiSrv.getWeatherLocations())
        .then(function (locations) {
          $scope.weatherLocations = [{ address: Locale.string("None"), locationId: "", group: "" }]
          return ($scope.weatherLocations = $scope.weatherLocations.concat(locations))
        })
        .catch((err) => console.error(err.toString()))
    }

    var loadItems = function (items) {
      $scope.Items = items
      const _getData = function ($defer, params) {
        const sorting = $scope.row
        const count = params.count()
        $scope.pagecount = count

        $scope.filterItems = $filter("filter")($scope.Items, function (val) {
          const str = new RegExp(`.*${$scope.searchKeywords}.*`, "i")

          return (
            __guard__(
              __guard__(val != null ? val.company : undefined, (x1) => x1.name),
              (x) => x.match(str)
            ) != null ||
            __guard__(val != null ? val.address : undefined, (x2) => x2.match(str)) != null ||
            __guard__(val != null ? val.name : undefined, (x3) => x3.match(str)) != null ||
            __guard__(val != null ? val.tel : undefined, (x4) => x4.match(str)) != null
          )
        })

        params.total($scope.filterItems.length)
        const orderItems = $filter("orderBy")($scope.filterItems, sorting)

        const start = (params.page() - 1) * params.count()
        const end = start + params.count()
        $scope.currentPageItems = orderItems.slice(start, end)
        return $defer.resolve($scope.currentPageItems)
      }

      return ($scope.tableParams = new NgTableParams(
        { count: $scope.pagecount != null ? $scope.pagecount : 25 },
        { counts: [10, 25, 50, 100], getData: _getData }
      ))
    }

    // filter
    $scope.searchKeywords = ""
    $scope.row = "company.name"

    $scope.enterKeyword = function (keyword) {
      $scope.searchKeywords = keyword
      return loadItems($scope.Items)
    }

    $scope.order = function (rowName) {
      if ($scope.currentPageItems.length < 1) {
        return
      }
      $scope.row = rowName
      return loadItems($scope.Items)
    }

    $scope.selectImage = function (files) {
      if (!files) {
        return
      }

      const reader = new FileReader()
      reader.onload = (e) => angular.element("#preview").attr("src", e.target.result)

      reader.readAsDataURL(files)
      return ($scope.form.activeForm = true)
    }

    var _uploadThumbnail = function (storeId, callback) {
      if (!storeId) {
        return callback({ message: "no storeId" })
      }

      if ($scope.thumbnailFiles && $scope.thumbnailFiles.type.indexOf("image/") > -1) {
        const uploadUrl = `${$scope.uploadUrl}?storeId=${storeId}`
        return Upload.upload({
          url: uploadUrl,
          file: $scope.thumbnailFiles,
        }).then(
          (resp) => callback(null),
          function (resp) {
            const err = { message: `Fail to upload thumbnail(httpcode: ${resp.status})` }
            return callback(err)
          }
        )
      } else {
        return callback(null)
      }
    }

    $scope.initializeColor = (labels) =>
      (() => {
        const result = []
        for (
          let idx = 0, end = labels.length, asc = 0 <= end;
          asc ? idx < end : idx > end;
          asc ? idx++ : idx--
        ) {
          var labelColor = getLabelColors(idx)
          result.push((labels[idx].rgbcolor = `rgb(${labelColor})`))
        }
        return result
      })()

    $scope.reload()

    $scope.updateSeedCounterList = () =>
      ($scope.seedCounterList = SeedCounter.getList(
        $scope.form.labels,
        $scope.form.features.pathtree
      ))

    $scope.changePathTreeFeature = function () {
      if (
        ["PathTree Guest", "PathTree Staff"].includes($scope.form.seedCounterOfGA) &&
        !$scope.form.features.pathtree
      ) {
        $scope.form.seedCounterOfGA = "Footfall Entrance"
      }
    }

    $scope.unCheckMaintenanceMode = function (value) {
      if (!value) {
        $scope.form.maintenanceMode.company = false
        return ($scope.form.maintenanceMode.agency = false)
      }
    }

    $scope.getMaintenanceModeStatus = function () {
      if (
        __guard__(
          $scope.form != null ? $scope.form.maintenanceMode : undefined,
          (x) => x.company
        ) &&
        __guard__($scope.form != null ? $scope.form.maintenanceMode : undefined, (x1) => x1.agency)
      ) {
        return strActive
      } else {
        return strInactive
      }
    }

    $scope.isCopyStore = () => $scope.inputDlgTitle === "Copy Store"

    $scope.getSourceStoreName = function () {
      if (original == null) {
        return
      }

      const company = _.find($scope.companies, { _id: original._companyId })
      return `${company.name} > ${original.name}`
    }

    var showLoading = function (key) {
      angular.element(".splash").show()
      usSpinnerService.spin("spinner")
      return angular.element(".spinner").css("position", "fixed")
    }

    $scope.hideWeatherObservatory = () => {
      return $scope.isLocalgrey || ($scope.form?.timezone && $scope.form.timezone !== "Asia/Seoul")
    }

    return (hideLoading = function (key) {
      angular.element(".splash").hide()
      return usSpinnerService.stop("spinner")
    })
  }
)

App.controller("StoreAccessCtrl", function ($scope, $stateParams, $window, ApiSrv) {
  $scope.ablePermissions = ["read"]
  $scope.back = () => $window.history.back()

  return ApiSrv.getStore({ id: $stateParams.storeId }).then(
    (res) => ($scope.store = res),
    (err) => {
      throw err
    }
  )
})

App.controller(
  "StoreAccessAccountsCtrl",
  function ($scope, $stateParams, ApiSrv, NgTableParams, Locale, StoreAccessSrv) {
    const vm = this
    vm.selectedPermission = $scope.ablePermissions[0]
    vm.header = {
      account: Locale.string("Account Name"),
      email: Locale.string("Email Address"),
    }

    const _reloadList = function () {
      if (!vm.store) {
        return
      }
      return ApiSrv.getAllAccount({ isCache: false }).then(
        function (accounts) {
          const ableList = _.reduce(
            accounts,
            function (result, item) {
              if (
                item.role.orgs.indexOf(vm.store._companyId) >= 0 &&
                (item.role != null ? item.role.group : undefined) === "store" &&
                _.findIndex(vm.store.policy.access.accounts, { _accountId: item._id }) < 0
              ) {
                result.push({
                  _id: item._id,
                  email: item.email,
                  name: Locale.fullName(item),
                })
              }
              return result
            },
            []
          )

          vm.ableList = _.sortBy(ableList, "name")
          if (!_.isEmpty(vm.ableList)) {
            return (vm.selectedAccount = vm.ableList[0])
          }
        },
        (err) => {
          throw err
        }
      )
    }

    const _getData = function ($defer, params) {
      StoreAccessSrv.get(
        {
          _: Date.now(),
          storeId: $stateParams.storeId,
        },
        function (access) {
          __guard__(vm.store != null ? vm.store.policy : undefined, (x) => (x.access = access))
          _reloadList()
          __guard__(access != null ? access.accounts : undefined, (x1) =>
            x1.forEach((item) => (item["fullName"] = Locale.fullName(item)))
          )
          return $defer.resolve(
            _.sortBy(access != null ? access.accounts : undefined, ["firstName", "lastName"])
          )
        },
        function (err) {
          console.error(`Failed get accounts from store access: ${err}`)
          throw err
        }
      )
    }

    vm.add = function () {
      if (!vm.selectedAccount) {
        return
      }

      return StoreAccessSrv.add(
        {
          storeId: $stateParams.storeId,
          accountId: vm.selectedAccount._id,
          permission: vm.selectedPermission,
          item: "account",
        },
        function (res) {
          _.remove(vm.ableList, (item) => vm.selectedAccount === item)
          vm.selectedAccount = _.first(vm.ableList)

          return vm.tableParams.reload()
        },
        (err) => {
          throw err
        }
      )
    }

    vm.setPermission = function (account, permission) {
      if (!account) {
        return
      }
      if (account.permission === permission) {
        return
      }
      return StoreAccessSrv.setPermission(
        {
          storeId: $stateParams.storeId,
          id: account._accountId,
          permission,
          item: "account",
        },
        (res) => (account.permission = res.permission),
        (err) => {
          throw err
        }
      )
    }

    vm.remove = (accountId) =>
      StoreAccessSrv.remove(
        {
          storeId: $stateParams.storeId,
          id: accountId,
          item: "account",
        },
        (res) => vm.tableParams.reload()
      )

    $scope.$watch("store", function (store) {
      if (!store) {
        return
      }
      vm.store = store
      return (vm.tableParams = new NgTableParams({}, { counts: [], getData: _getData }))
    })
  }
)

App.controller(
  "StoreAccessGroupsCtrl",
  function ($scope, $stateParams, ApiSrv, NgTableParams, Locale, StoreAccessSrv) {
    const vm = this
    vm.selectedPermission = $scope.ablePermissions[0]
    vm.header = { group: Locale.string("Group Name") }

    const _reloadList = function () {
      if (!vm.store) {
        return
      }

      return ApiSrv.getAllAccountGroup({
        isCache: false,
        companyId: vm.store._companyId,
      }).then(
        function (groups) {
          const ableList = _.reduce(
            groups,
            function (result, item) {
              if (
                _.findIndex(
                  __guard__(vm.store != null ? vm.store.policy : undefined, (x) => x.access.groups),
                  { _groupId: item._id }
                ) < 0
              ) {
                result.push({
                  _id: item._id,
                  name: item.name,
                  accountList: item.accountList,
                })
              }
              return result
            },
            []
          )

          vm.ableList = _.sortBy(ableList, "name")
          if (!_.isEmpty(vm.ableList)) {
            return (vm.selectedGroup = vm.ableList[0])
          }
        },
        (err) => {
          throw err
        }
      )
    }

    const _getData = function ($defer, params) {
      StoreAccessSrv.get(
        {
          _: Date.now(),
          storeId: $stateParams.storeId,
        },
        function (access) {
          __guard__(vm.store != null ? vm.store.policy : undefined, (x) => (x.access = access))
          _reloadList()
          return $defer.resolve(_.sortBy(access != null ? access.groups : undefined, "name"))
        },
        (err) => {
          throw err
        }
      )
    }

    vm.add = function () {
      if (!vm.selectedGroup) {
        return
      }

      return StoreAccessSrv.add(
        {
          storeId: $stateParams.storeId,
          groupId: vm.selectedGroup._id,
          permission: vm.selectedPermission,
          item: "group",
        },
        function (res) {
          _.remove(vm.ableList, (item) => vm.selectedGroup === item)
          vm.selectedGroup = _.first(vm.ableList)

          return vm.tableParams.reload()
        },
        (err) => {
          throw err
        }
      )
    }

    vm.setPermission = function (group, permission) {
      if (!group) {
        return
      }
      if (group.permission === permission) {
        return
      }
      return StoreAccessSrv.setPermission(
        {
          storeId: $stateParams.storeId,
          id: group._groupId,
          permission,
          item: "group",
        },
        (res) => (group.permission = res.permission),
        (err) => {
          throw err
        }
      )
    }

    vm.remove = (groupId) =>
      StoreAccessSrv.remove(
        {
          storeId: $stateParams.storeId,
          id: groupId,
          item: "group",
        },
        (res) => vm.tableParams.reload()
      )

    $scope.$watch("store", function (store) {
      if (!store) {
        return
      }
      vm.store = store
      return (vm.tableParams = new NgTableParams({}, { counts: [], getData: _getData }))
    })
  }
)

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
