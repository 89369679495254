import angular from "angular"

angular.module("uCountitUiApp").controller("uiV2EmbedCtrl", ($scope, $element, $window) => {
  return (() => {
    const iframe = $element.find("iframe")[0]

    $scope.$watch("onLoad", (onLoad) => {
      iframe.onload = onLoad
    })

    const messageHandler = (event) => {
      if (event.source === iframe.contentWindow && $scope.messageHandler) {
        $scope.messageHandler(event)
      }
    }

    $window.addEventListener("message", messageHandler)

    $scope.$on("$destroy", () => {
      $window.removeEventListener("message", messageHandler)
    })
  })()
})
