/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

import Promise from "bluebird"

angular.module("uCountitUiApp").config(($stateProvider) =>
  $stateProvider
    .state("app.overviews", {
      url: "/overview",
      controller($state, HeaderSrv) {
        return Promise.all([HeaderSrv.fetchCurrentCompany(), HeaderSrv.fetchCurrentStore()])
          .spread(function (company, store) {
            let org = company
            if (store) {
              org = store
            }
            return $state.go("app.overview", { orgType: org.orgType, id: org._id })
          })
          .catch((err) => $state.go("default"))
      },
    })
    .state("app.overview", {
      url: "/overview/:orgType/:id",
      templateUrl($stateParams) {
        const templateUrl =
          $stateParams.orgType === "store"
            ? "app/overview/store-overview.html"
            : "app/overview/storegroup-overview.html"
        return templateUrl
      },
      controllerProvider($stateParams) {
        const ctrlName =
          $stateParams.orgType === "store" ? "StoreOverviewCtrl" : "StoreGroupOverviewCtrl"
        return ctrlName
      },
      resolve: {
        currentCompany($q, HeaderSrv) {
          return HeaderSrv.fetchCurrentCompany()
        },
      },
    })
)
