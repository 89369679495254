/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")

  .controller("InfomationCtrl", function ($scope, Auth, VersionSrv, me) {
    if (!me.gteSpace()) {
      return
    }
    $scope.me = me

    return VersionSrv.getVersions().then(function (services) {
      $scope.ucountit = services["uCountIt"]
      delete services["uCountIt"]

      return ($scope.services = Object.keys(services)
        .map((k) => _.merge({ name: k }, services[k]))
        .sort((a, b) => a.name.localeCompare(b.name)))
    })
  })
