/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory("VersionSrv", function ($rootScope, $http, $q) {
    let versions = null

    const getVersions = function () {
      const deferred = $q.defer()
      if (versions) {
        deferred.resolve(versions)
      } else {
        $http.get("/api/1.0/service/version").then(
          function (res) {
            versions = res.data
            return deferred.resolve(res.data)
          },
          (err) => deferred.reject(err)
        )
      }

      return deferred.promise
    }

    return { getVersions }
  })
