import React from "react"
import propTypes from "prop-types"
import SelectField from "../../../../components/react/SelectField"
import { Text } from "informed"

const WebhookActionParameter = (props) => {
  const { error } = props

  return (
    <>
      <div className={`form-group ${error && error.method ? "has-error" : null}`}>
        <label className="control-label col-sm-3">Method</label>
        <div className="col-sm-9">
          <SelectField
            field="action.parameter.method"
            options={[
              { label: "GET", value: "GET" },
              { label: "POST", value: "POST" },
              { label: "PUT", value: "PUT" },
              { label: "DELETE", value: "DELETE" },
              { label: "PATCH", value: "PATCH" },
            ]}
          />
          {error && error.method ? <span className="help-block">{error.method}</span> : null}
        </div>
      </div>
      <div className={`form-group ${error && error.url ? "has-error" : null}`}>
        <label className="control-label col-sm-3">Url</label>
        <div className="col-sm-9">
          <Text className="form-control" field="action.parameter.url" />
          {error && error.url ? <span className="help-block">{error.url}</span> : null}
        </div>
      </div>
    </>
  )
}

WebhookActionParameter.propTypes = {
  error: propTypes.object,
}

export default WebhookActionParameter
