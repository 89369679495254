/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

import env from "../../../../config/environment"

angular
  .module("uCountitUiApp")

  .controller(
    "AgencyCtrl",
    function (
      $scope,
      $rootScope,
      $filter,
      $window,
      $anchorScroll,
      $q,
      $state,
      Auth,
      ApiSrv,
      AgencySrv,
      Locale,
      Upload,
      NgTableParams,
      CustomUISrv,
      BrandSrv,
      ngDialog,
      HeaderSrv,
      RestApi,
      me
    ) {
      $scope.me = me
      if (!me.gteAgency()) {
        return
      }

      $scope.isOpenInputDlg = false
      $scope.itemName = Locale.string("Agency")
      $scope.spacePlaceholder = Locale.string("choose a space")
      $scope.inputDlgTitle = "Create"
      $scope.defaultBrand = BrandSrv.getDefaultBrand()

      $scope.maxFileSize = "5MB"

      $scope.themeList = CustomUISrv.getThemeList()
      $scope.previewSignin = "/assets/images/signin_page.png"
      $scope.previewMaster = "/assets/images/master_page.png"

      $scope.domainList = []
      $scope.customNavMenuList = {
        footfall: Locale.string("nav_footfall"),
        zonetraffic: Locale.string("nav_zonetraffic"),
        occupancy: Locale.string("nav_occupancy"),
        directionmap: Locale.string("nav_flowmap"),
        heatmap: Locale.string("nav_heatmap"),
        genderAge: Locale.string("nav_genderage"),
        sales: Locale.string("Sales"),
        queue: Locale.string("Queue"),
        dashboardQueue: Locale.string("Q-Dashboard"),
        dashboardFace: Locale.string("Face Dashboard"),
      }
      if (env.isLocalgrey) {
        delete $scope.customNavMenuList.genderAge
        delete $scope.customNavMenuList.queue
        delete $scope.customNavMenuList.dashboardQueue
        delete $scope.customNavMenuList.dashboardFace
        delete $scope.customNavMenuList.sales
      }
      $scope.isLocalgrey = env.isLocalgrey

      let original = null
      let originalDomainList = null

      const getLocale = function (languageObj) {
        //default language / locale : "en-us" / "en-gb"
        if (languageObj.code.toLowerCase() === Locale.getDefaultLanguage()) {
          return Locale.getDefaultLocale()
        }

        return {
          region: _.find($scope.languageList, { lang: languageObj.lang }).code.toLowerCase(),
        }
      }

      $scope.revert = function () {
        $scope.selectedLang =
          _.find($scope.languageList, { lang: $scope.currentAccount.language }) ??
          _.find($scope.languageList, { lang: "en" })
        const locale = $scope.currentAccount.locale?.region
          ? $scope.currentAccount.locale
          : getLocale($scope.selectedLang)

        $scope.form = {
          name: "",
          email: "",
          default: {
            language: $scope.selectedLang.lang,
            locale: locale,
          },
          active: true,
          activeForm: false,
          policy: {
            noPersonalData: false,
          },
        }

        if ($scope.spaces && $scope.spaces.length > 0) {
          const lastSelected = Auth.clientInfo("admin.agencied.lastSelectedSpaceId")
          $scope.selectedSpace =
            $scope.spaces.find((space) => {
              if (space._id == lastSelected) {
                return space
              }
            }) ?? $scope.spaces[0]
        } else {
          $scope.errorMessage = "No space exists"
        }
        $scope.form_create.$setPristine()
        return ($scope.errorMessage = "")
      }

      $scope.languageList = Locale.getLangList()

      $scope.changeLanguage = function (selectedLang) {
        if (!selectedLang) {
          return
        }

        $scope.form.default.language = selectedLang.lang
        $scope.form.default.locale = getLocale(selectedLang)
      }

      $scope.canSubmit = function () {
        if (!$scope.isOpenInputDlg) {
          return
        }

        $scope.form._spaceId = $scope.selectedSpace != null ? $scope.selectedSpace._id : undefined

        return (
          $scope.form_create.$valid &&
          (!angular.equals($scope.form, original) ||
            !angular.equals($scope.domainList, originalDomainList)) &&
          $scope.isValidColor(
            __guard__(
              $scope.form.customSettings != null ? $scope.form.customSettings.signin : undefined,
              (x) => x.hexColor
            )
          )
        )
      }

      $scope.submitForm = function () {
        if ($scope.inputDlgTitle === "Create") {
          return $scope.createItem($scope.form)
        } else {
          return $scope.updateItem($scope.form)
        }
      }

      $scope.createItem = (item) =>
        AgencySrv.save(
          item,
          function (sobj, res) {
            $scope.reload(false, () => HeaderSrv.emitter.reload())
            $scope.isOpenInputDlg = false
            return Auth.clientInfo("admin.agencied.lastSelectedSpaceId", $scope.selectedSpace._id)
          },

          function (error) {
            if (error.status === 409) {
              return ($scope.errorMessage = Locale.string("msg_error_name_duplicated"))
            } else {
              return ($scope.errorMessage = Locale.string("msg_error_create"))
            }
          }
        )

      $scope.updateItem = function (item) {
        item.customSettings.domains = _.map($scope.domainList, (i) => i.name)

        return async.waterfall(
          [
            (callback) =>
              _uploadImage(item._id, "emblem", function (err, data) {
                if (__guard__(data != null ? data.emblem : undefined, (x) => x.url)) {
                  item.customSettings.emblem.url = data.emblem.url
                }
                return callback(err, data)
              }),
            (result, callback) =>
              _uploadImage(item._id, "favicon", function (err, data) {
                if (
                  __guard__(data != null ? data.favicon : undefined, (x) => x.url) &&
                  __guard__(data != null ? data.favicon : undefined, (x1) => x1.imgurl)
                ) {
                  item.customSettings.favicon.imgurl = data.favicon.imgurl
                  item.customSettings.favicon.url = data.favicon.url
                }
                return callback(err, data)
              }),
            (result, callback) =>
              _uploadImage(item._id, "logo", function (err, data) {
                if (__guard__(data != null ? data.logo : undefined, (x) => x.url)) {
                  item.customSettings.logo.url = data.logo.url
                }
                return callback(err, data)
              }),
            (result, callback) =>
              _uploadImage(item._id, "signin", function (err, data) {
                if (__guard__(data != null ? data.signin : undefined, (x) => x.url)) {
                  item.customSettings.signin.url = data.signin.url
                }
                return callback(err, data)
              }),
            (result, callback) =>
              _uploadText(item._id, "terms", function (err, data) {
                if (__guard__(data != null ? data.terms : undefined, (x) => x.path)) {
                  item.customSettings.terms.path = data.terms.path
                }
                return callback(err, data)
              }),
            (result, callback) =>
              _uploadText(item._id, "privacy", function (err, data) {
                if (__guard__(data != null ? data.privacy : undefined, (x) => x.path)) {
                  item.customSettings.privacy.path = data.privacy.path
                }
                return callback(err, data)
              }),
          ],
          (err, result) =>
            RestApi.Agency.update(item._id, item)
              .then(function () {
                $scope.isOpenInputDlg = false
                $state.reload()
                // always return because the res is not defined
                return
                // if (!res) {
                //   return
                // }
                // return ($scope.form = angular.copy(res.data))
              })
              .catch(function (error) {
                if (error.status === 409) {
                  return ($scope.errorMessage = Locale.string("msg_error_name_duplicated"))
                } else {
                  return ($scope.errorMessage = Locale.string("msg_error_update"))
                }
              })
        )
      }

      $scope.isCompleteList = (list) => list && (list != null ? list.length : undefined) > 1

      const setDefaultCustomSettings = function (formData) {
        let form = _.cloneDeep(formData)
        _.defaultsDeep(form, $scope.defaultBrand)
        form = _.omit(form, ["email", "language", "region", "navMenu"])

        if (!(form != null ? form.use : undefined)) {
          form.use = false
        }
        if (!__guard__(form != null ? form.favicon : undefined, (x) => x.imgurl)) {
          _.set(form, "favicon.imgurl", $scope.defaultBrand.emblem.url)
        }

        return form
      }

      //# List Table ##
      $scope.openInputDlg = function (item) {
        if (!$scope.spaces || ($scope.spaces != null ? $scope.spaces.length : undefined) === 0) {
          $window.alert(Locale.string("msg_agency_spacelist_empty"))
        }

        $scope.form_create.$error = {}
        clearFormCtrl("emblem", false)
        clearFormCtrl("logo", false)
        clearFormCtrl("favicon", false)
        clearFormCtrl("signin", false)
        clearFormCtrl("terms", false)
        clearFormCtrl("privacy", false)
        $scope.form_create.$setPristine()

        $scope.isOpenInputDlg = true

        if (item != null) {
          let options
          $scope.emblemImage = null
          $scope.logoImage = null
          $scope.faviconImage = null
          $scope.signinImage = null
          $scope.terms = null
          $scope.previewTerms = ""
          $scope.privacy = null
          $scope.previewPrivacy = ""
          $scope.inputDlgTitle = "Update"
          $scope.form = angular.copy(item)
          $scope.selectedSpace = _.find($scope.spaces, { _id: item._spaceId })

          if (!$scope.form?.default?.language) {
            _.set($scope.form, "default.language", $scope.currentAccount.language)
          }
          $scope.selectedLang = _.find($scope.languageList, { lang: $scope.form.default.language })
          if (!$scope.form?.default?.locale?.resion) {
            const locale = $scope.currentAccount.locale?.region
              ? $scope.currentAccount.locale
              : getLocale($scope.selectedLang)
            _.set($scope.form, "default.locale", locale)
          }

          $scope.domainList = _.map($scope.form.customSettings.domains, (name) =>
            defaultDomainObj(name)
          )
          originalDomainList = _.cloneDeep($scope.domainList)

          if (!(item != null ? item.customNav : undefined)) {
            $scope.form.customNav = {
              active: false,
              menu: $scope.defaultBrand.navMenu,
            }
          }

          $scope.form.customSettings = setDefaultCustomSettings($scope.form.customSettings)

          $scope.logoUrl = `${$scope.form.customSettings.logo.url}` + `?_=${new Date().getTime()}`
          $scope.emblemUrl =
            `${$scope.form.customSettings.emblem.url}` + `?_=${new Date().getTime()}`
          $scope.faviconUrl =
            `${$scope.form.customSettings.favicon.imgurl}` + `?_=${new Date().getTime()}`
          $scope.signinUrl =
            `${$scope.form.customSettings.signin.url}` + `?_=${new Date().getTime()}`

          if (
            __guard__(
              $scope.form.customSettings != null ? $scope.form.customSettings.terms : undefined,
              (x) => x.path
            )
          ) {
            options = { path: $scope.form.customSettings.terms.path }
            ApiSrv.getCustomPolicy(options).then((data) => ($scope.previewTerms = data))
          }
          if (
            __guard__(
              $scope.form.customSettings != null ? $scope.form.customSettings.privacy : undefined,
              (x1) => x1.path
            )
          ) {
            options = { path: $scope.form.customSettings.privacy.path }
            ApiSrv.getCustomPolicy(options).then((data) => ($scope.previewPrivacy = data))
          }

          $scope.form_create.$setDirty()
          $scope.errorMessage = ""
        } else {
          $scope.inputDlgTitle = "Create"
          $scope.revert()
        }

        original = angular.copy($scope.form)
        return $anchorScroll()
      }

      $scope.removeItem = function (item) {
        const _confirmDlg = () =>
          ngDialog
            .openConfirm({
              template: "components/popup/popup-confirm.html",
              data: {
                title: Locale.string("msg_confirm_remove_agency"),
              },
              closeByEscape: true,
              closeByDocument: true,
              showClose: false,
              className: "ngdialog-theme-default popup-confirm",
              controller: "ucPopupConfirmCtrl",
            })
            .then(() =>
              RestApi.Agency.delete(item._id)
                .then(() => $scope.reload(false, () => HeaderSrv.emitter.reload()))
                .catch(function (error) {
                  if (error.data != null) {
                    return ($scope.errorMessage = error.data)
                  }
                })
            )

        const _alertDlg = () =>
          ngDialog.open({
            template: "components/popup/popup-alert.html",
            data: {
              title: Locale.string("msg_alert_head_remove_agency"),
              message: Locale.string("msg_alert_body_remove_agency"),
            },
            closeByEscape: true,
            closeByDocument: true,
            showClose: false,
            className: "ngdialog-theme-default popup-alert",
            controller: "ucPopupAlertCtrl",
          })

        return ApiSrv.getCompanies({ _agencyId: item._id, isCache: false }).then(
          function (companies) {
            if (companies != null ? companies.length : undefined) {
              return _alertDlg()
            } else {
              return _confirmDlg()
            }
          },

          (err) => $window.confirm(`Internal error ${err}`)
        )
      }

      $scope.reload = (isCache, callback = null) =>
        async.series(
          [
            (callback) =>
              ApiSrv.getAllSpace().then(function (res) {
                $scope.spaces = _.map(res, (element) => ({
                  _id: element._id,
                  name: element.name,
                  active: element.active,
                }))
                return callback(null)
              }),
          ],
          (err, results) =>
            ApiSrv.getAllAgency({ isCache: isCache }).then(function (res) {
              for (var agency of Array.from(res)) {
                agency.space = _.find($scope.spaces, { _id: agency._spaceId })
              }
              if (callback) {
                callback(res)
              }
              return loadItems(res)
            })
        )

      var loadItems = function (items) {
        $scope.Items = items
        const _getData = function ($defer, params) {
          const sorting = $scope.row
          const count = params.count()
          $scope.pagecount = count

          $scope.filterItems = $filter("filter")($scope.Items, function (val) {
            const str = new RegExp(`.*${$scope.searchKeywords}.*`, "i")

            return __guard__(val != null ? val.name : undefined, (x) => x.match(str)) != null
          })

          params.total($scope.filterItems.length)
          const orderItems = $filter("orderBy")($scope.filterItems, sorting)

          const start = (params.page() - 1) * params.count()
          const end = start + params.count()
          $scope.currentPageItems = orderItems.slice(start, end)
          return $defer.resolve($scope.currentPageItems)
        }

        return ($scope.tableParams = new NgTableParams(
          { count: $scope.pagecount != null ? $scope.pagecount : 25 },
          { counts: [10, 25, 50, 100], getData: _getData }
        ))
      }

      // filter
      $scope.searchKeywords = ""
      $scope.row = "name"

      $scope.enterKeyword = function (keyword) {
        $scope.searchKeywords = keyword
        return loadItems($scope.Items)
      }

      $scope.order = function (rowName) {
        if ($scope.currentPageItems.length < 1) {
          return
        }
        $scope.row = rowName
        return loadItems($scope.Items)
      }

      $scope.clear = function (type) {
        clearFormCtrl(type)

        switch (type) {
          case "emblem":
            $scope.form.customSettings.emblem.url = $scope.defaultBrand.emblem.url
            $scope.emblemUrl = $scope.defaultBrand.emblem.url
            return ($scope.emblemImage = null)
          case "favicon":
            $scope.form.customSettings.favicon.url = $scope.defaultBrand.favicon.url
            $scope.form.customSettings.favicon.imgurl = $scope.defaultBrand.emblem.url
            $scope.faviconUrl = $scope.defaultBrand.emblem.url
            return ($scope.faviconImage = null)
          case "signin":
            $scope.form.customSettings.signin.url = $scope.defaultBrand.signin.url
            $scope.signinUrl = $scope.defaultBrand.signin.url
            return ($scope.signinImage = null)
          case "logo":
            $scope.form.customSettings.logo.url = $scope.defaultBrand.logo.url
            $scope.logoUrl = $scope.defaultBrand.logo.url
            return ($scope.logoImage = null)
          case "terms":
            $scope.terms = null
            $scope.previewTerms = ""
            return ($scope.form.customSettings.terms.path = "")
          case "privacy":
            $scope.privacy = null
            $scope.previewPrivacy = ""
            return ($scope.form.customSettings.privacy.path = "")
          default:
            return
        }
      }

      $scope.selectImage = function (id, file) {
        if (!file) {
          return
        }
        const reader = new FileReader()
        reader.onload = (e) => angular.element("#" + id).attr("src", e.target.result)

        reader.readAsDataURL(file)
        return ($scope.form.activeForm = true)
      }

      $scope.isInvalidFile = function (type) {
        switch (type) {
          case "emblem":
            return __guard__(
              $scope.form_create != null ? $scope.form_create.emblemFile : undefined,
              (x) => x.$error.maxSize
            )
          case "favicon":
            return __guard__(
              $scope.form_create != null ? $scope.form_create.faviconFile : undefined,
              (x1) => x1.$error.maxSize
            )
          case "signin":
            return __guard__(
              $scope.form_create != null ? $scope.form_create.signinFile : undefined,
              (x2) => x2.$error.maxSize
            )
          case "logo":
            return __guard__(
              $scope.form_create != null ? $scope.form_create.logoFile : undefined,
              (x3) => x3.$error.maxSize
            )
          default:
            return false
        }
      }

      $scope.isValidColor = function (val) {
        if (val === undefined) {
          return true
        }
        return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(val)
      }

      var _uploadImage = function (agencyId, kind, callback) {
        let imageFile
        if (!agencyId) {
          return callback({ message: "no agencyId" })
        }

        const uploadUrl = `${$rootScope.globalConfig.apipath}/agency/${agencyId}/${kind}`

        if (kind === "emblem") {
          imageFile = $scope.emblemImage
        } else if (kind === "logo") {
          imageFile = $scope.logoImage
        } else if (kind === "favicon") {
          imageFile = $scope.faviconImage
        } else if (kind === "signin") {
          imageFile = $scope.signinImage
        } else {
          return callback({ message: "wrong image type" })
        }

        if (imageFile && imageFile.type.indexOf("image/") > -1) {
          return Upload.upload({
            url: uploadUrl,
            file: imageFile,
          }).then(
            (resp) => callback(null, resp.data),
            function (resp) {
              const err = { message: `Fail to upload image(httpcode: ${resp.status})` }
              return callback(err)
            }
          )
        } else {
          return callback(null)
        }
      }

      var defaultDomainObj = (name) => ({
        name,
        editField: "",
        activeEdit: false,
      })

      const isDulicateDomain = function (name) {
        if (_.find($scope.domainList, (i) => i.name === name)) {
          return true
        } else {
          return false
        }
      }

      $scope.addDomain = function (domainStr) {
        if (!domainStr) {
          return
        }
        if (isDulicateDomain(domainStr)) {
          return
        }

        $scope.domainList.push(defaultDomainObj(domainStr))
        return ($scope.newDomain = "")
      }

      $scope.modifyDomain = function (domain) {
        if (!domain.editField) {
          return (domain.activeEdit = false)
        }

        if (domain.editField === domain.name || !isDulicateDomain(domain.editField)) {
          domain.name = domain.editField
          return (domain.activeEdit = false)
        }
      }

      $scope.activeModifyDomain = function (domain) {
        if (!_.find($scope.domainList, (i) => i.activeEdit)) {
          domain.editField = domain.name
          return (domain.activeEdit = true)
        }
      }

      $scope.removeDomain = (domain) =>
        _.remove($scope.domainList, (raw) => raw.name === domain.name)

      var _uploadText = function (agencyId, kind, callback) {
        let textFile
        if (!agencyId) {
          return callback({ message: "no agencyId" })
        }

        const uploadUrl = `${$rootScope.globalConfig.apipath}/agency/${agencyId}/${kind}`

        switch (kind) {
          case "terms":
            textFile = $scope.terms
            break
          case "privacy":
            textFile = $scope.privacy
            break
          default:
            return callback({ message: "wrong terms and service type" })
        }

        if (textFile) {
          return Upload.upload({
            url: uploadUrl,
            file: textFile,
          }).then(
            (resp) => callback(null, resp.data),
            function (resp) {
              const err = { message: `Fail to upload file(httpcode: ${resp.status})` }
              return callback(err)
            }
          )
        } else {
          return callback(null)
        }
      }

      const _getTextFromFile = function (file) {
        const deffered = $q.defer()

        const reader = new FileReader()
        reader.onload = () => deffered.resolve(reader.result)
        reader.onerror = () => deffered.reject(reader.error)

        reader.readAsText(file)

        return deffered.promise
      }

      var clearFormCtrl = function (type, pristine) {
        if (pristine == null) {
          pristine = true
        }
        switch (type) {
          case "emblem":
            $scope.form_create.emblemFile.$setValidity("maxSize", null)
            break
          case "favicon":
            $scope.form_create.faviconFile.$setValidity("maxSize", null)
            break
          case "signin":
            $scope.form_create.signinFile.$setValidity("maxSize", null)
            break
          case "logo":
            $scope.form_create.logoFile.$setValidity("maxSize", null)
            break
          case "terms":
            $scope.form_create.termsFile.$setValidity("pattern", null)
            break
          case "privacy":
            $scope.form_create.privacyFile.$setValidity("pattern", null)
            break
        }

        if (pristine) {
          return $scope.form_create.$setPristine()
        }
      }

      $scope.selectPolicy = function (type, file) {
        if (!file) {
          return
        }

        if (file != null ? file.$error : undefined) {
          ngDialog.open({
            template: "components/popup/popup-alert.html",
            data: {
              title: Locale.string("Select File"),
              message: Locale.string("the file type is not supported") + `\n(${file.name})`,
            },
            closeByEscape: true,
            closeByDocument: true,
            showClose: false,
            className: "ngdialog-theme-default popup-alert",
            controller: "ucPopupAlertCtrl",
          })

          return clearFormCtrl(type)
        }

        return _getTextFromFile(file).then(
          function (res) {
            switch (type) {
              case "terms":
                $scope.previewTerms = res
                break
              case "privacy":
                $scope.previewPrivacy = res
                break
            }

            return ($scope.form.activeForm = true)
          },
          (err) => console.error(err)
        )
      }

      // start reload account
      Auth.fetchCurrentAccount().then((account) => {
        $scope.currentAccount = account

        $scope.reload(false)
      })
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
