"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("weightInput", () => ({
    templateUrl: "components/weight-input/weight-input.html",
    controller: "weightInputCtrl",

    scope: {
      weight: "=",
    },
  }))
