"use strict"

import angular from "angular"

angular.module("uCountitUiApp").config(($stateProvider) => {
  $stateProvider
    .state("app.admin.s3fileapi", {
      url: "/s3fileapi",
      templateUrl: "app/admin/s3fileapi/list.html",
      controller: "S3FileApiListCtrl",
    })
    .state("app.admin.s3fileapi.new", {
      url: "/new",
      templateUrl: "app/admin/s3fileapi/form.html",
      controller: "S3FileApiNewCtrl",
    })
    .state("app.admin.s3fileapi.edit", {
      url: "/:id/edit",
      templateUrl: "app/admin/s3fileapi/form.html",
      controller: "S3FileApiEditCtrl",
    })
})
