/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory("RecoverySrv", ($resource) =>
    $resource("/api/1.0/recovery/password/:key", { key: "@key" }, { update: { method: "PUT" } })
  )
