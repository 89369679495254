/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("heatmaptagConfig", () => ({
    templateUrl: "components/heatmaptag-config/heatmaptag-config.html",
    controller: "heatmaptagConfigCtrl",

    scope: {
      isOpen: "=",
      selectedItem: "=",
    },
  }))
