import React from "react"
import FormEventContainer from "./FormEvent"
import Async from "react-async"
import propTypes from "prop-types"
import axios from "axios"

const NewEvent = (props) => {
  const handleSubmit = (data) => {
    if (props.onSubmit) props.onSubmit(data)
  }
  return (
    <section className="panel panel-default">
      <div className="panel-heading">
        <strong>
          <span className="glyphicon glyphicon-th" />
          &nbsp; New Event
        </strong>
        <a className="btn-util pull-right" href="/admin/event">
          <i className="mdi mdi-window-close" />
        </a>
      </div>
      <div className="panel-body">
        {props.error ? (
          <div className="col-xs-12">
            <div className="alert alert-danger" role="alert">
              {props.error.message}
            </div>
          </div>
        ) : null}
        <div className="col-xs-12">
          <FormEventContainer onSubmit={handleSubmit} />
        </div>
      </div>
    </section>
  )
}

NewEvent.propTypes = {
  onSubmit: propTypes.func,
  error: propTypes.object,
  disableSubmit: propTypes.bool,
}

const postEvent = ([data]) => {
  return axios.post("/api/1.0/event", data)
}

export const NewEventAsync = (props) => {
  return (
    <Async deferFn={postEvent} defer={true} onResolve={() => props.onResolve && props.onResolve()}>
      {({ isLoading, data, error, run }) => {
        return <NewEvent onSubmit={run} disableSubmit={isLoading} error={error} />
      }}
    </Async>
  )
}

NewEventAsync.propTypes = {
  onResolve: propTypes.func,
}

export default NewEventAsync
