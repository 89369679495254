/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS104: Avoid inline assignments
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

import Promise from "bluebird"
import getRateValue from "../../../components/util/newRateValue"
const heatmapCommon = require("../../../components/heatmappanel/heatmapCommon")
const mng = require("common-library/product-core/heatmapData")
import customBtnTemplate from "./button-colormode.html"

angular
  .module("uCountitUiApp")
  .controller(
    "DirectionmapCtrl",
    function (
      $scope,
      $element,
      $stateParams,
      $state,
      $filter,
      $timeout,
      $window,
      SelectorSrv,
      GridOptionSrv,
      ApiSrv,
      Locale,
      SamplingSrv,
      usSpinnerService,
      HeaderSrv,
      hotkeys,
      $cacheFactory
    ) {
      let left
      const SAMPLING = {
        HOUR: "hour",
        DAY: "day",
        MONTH: "month",
      }
      const kColors = "colors"
      const kSingleColor = "single-color"
      const STANDARD_COLOR = "rgb(255,0,0)"
      const cache =
        (left = $cacheFactory.get("directionmapCache")) != null
          ? left
          : $cacheFactory("directionmapCache")
      const cacheCMKey = "ColorMode"

      $scope.COLORMODE = {
        COLORS: kColors,
        SINGLECOLOR: kSingleColor,
      }
      $scope.shortcuts = [
        {
          combo: "]",
          description: "Move the time bar forward",
          callback() {
            return $scope.$emit("heatmap_timebar_dx_update", 1)
          },
        },
        {
          combo: "[",
          description: "Move the time bar backward",
          callback() {
            return $scope.$emit("heatmap_timebar_dx_update", -1)
          },
        },
      ]
      $scope.tooltip = {
        colors: Locale.string("btn_multi_color"),
        singlecolor: Locale.string("btn_single_color"),
      }

      $scope.curTimeBarIdx = 0
      $scope.validTime = false
      $scope.isCamera = false
      $scope.colorMode = kSingleColor
      $scope.customBtnTemplate = null

      $scope.pageOptions = {
        storeId: $stateParams.id,
        sampling: SAMPLING.DAY,
        displayOption: {
          showData: "pass",
          hideFrame: {
            linkBtn: true,
            exportBtn: false,
            scaleBar: true,
          },
        },
        content: "directionmap",
      }

      let prevOptions = {}
      $scope.chartOption = null
      $scope.chartData = null
      $scope.gridOption = null
      $scope.isGridData = false

      const gridOption = _.merge(
        {
          onRegisterApi(gridApi) {
            return (this.gridApi = gridApi)
          },
          getGridApi() {
            return this.gridApi
          },
        },
        GridOptionSrv.gridExportOption
      )
      let snapshotRatio = undefined

      const samplingKey = function (val) {
        switch (val) {
          case "hour":
            return "1h"
          case "day":
            return "1d"
          case "week":
            return "1w"
          case "month":
            return "1M"
          default:
            return "1d"
        }
      }

      const showLoading = function () {
        angular.element(".splash").show()
        usSpinnerService.spin("spinner")
        return angular.element(".spinner").css("position", "fixed")
      }

      const hideLoading = function () {
        angular.element(".splash").hide()
        return usSpinnerService.stop("spinner")
      }

      const isValidPeriod = function (start, end, sampling) {
        const limitDate = SamplingSrv[sampling].getLimitDate()
        const diffNum = moment(end).diff(start, limitDate.key)

        if (diffNum > limitDate.value) {
          return false
        } else {
          return true
        }
      }

      const isValidTimeBarIdx = (index) =>
        _.gte(index, 0) &&
        _.lt(
          index,
          __guard__(
            __guard__($scope.dataSet != null ? $scope.dataSet[0] : undefined, (x1) => x1.data),
            (x) => x.length
          )
        )

      const getDriectionConfig = (opt) =>
        ApiSrv.getDirectionConfigOfCamera(opt)
          .then((data) => data)
          .catch((err) => console.warn("getDriectionConfig err", err))

      const getDirectionmapData = function (dataSet, options, startDate, sampling) {
        const _addOuts = function (dir) {
          const inCounts = []
          const outCounts = []
          const outVertices = []
          dir.vertices.forEach(function (v) {
            if (v.inout === "O") {
              outCounts.push(v.count != null ? v.count : 0)
              return outVertices.push(v)
            } else {
              return inCounts.push(v.count != null ? v.count : 0)
            }
          })
          const totalInCount = _.sum(inCounts)
          const rsCounts = getRateValue(totalInCount, outCounts)

          return (dir.outs = rsCounts.map(function (count, i) {
            const ratio = totalInCount ? 100 * (count / totalInCount) : 0
            return {
              idx: outVertices[i].idx,
              ratio: parseFloat(ratio.toFixed(1)),
              count,
            }
          }))
        }

        return ApiSrv.getDirectionmapOfCamera(options)
          .then((data) =>
            data.forEach(function (item) {
              const idx = moment
                .utc(item.datetime)
                .startOf(sampling)
                .diff(startDate.startOf(sampling), sampling)
              return item.directions.forEach(function (dir, dirIdx) {
                if (dir.idx == null) {
                  dir.idx = dirIdx + 1
                }
                if (dir.vertices.length && !dir.outs.length) {
                  _addOuts(dir)
                }
                const totalInCount = _.sumBy(dir.vertices, function (v) {
                  if (v.count && v.inout === "I") {
                    return v.count
                  } else {
                    return 0
                  }
                })
                $scope.dataRefCounts[idx] = Math.max($scope.dataRefCounts[idx], totalInCount)
                return dir.outs.forEach(function (out, outIndex) {
                  const dataIdx = _.findIndex(
                    dataSet,
                    (d) =>
                      d.key ===
                      `${item._cameraId}_P${dir.idx}-${String.fromCharCode(65 + outIndex)}`
                  )
                  if (dataIdx < 0) {
                    return
                  }

                  if (dataSet[dataIdx].data[idx]) {
                    dataSet[dataIdx].data[idx][1] = out.count
                    dataSet[dataIdx].ratio[idx] = out.ratio
                    return (dataSet[dataIdx].totalCount += out.count)
                  }
                })
              })
            })
          )
          .catch((err) => console.warn("directionmap err", options, err))
      }

      const getDefaultData = function (start, end, sampling) {
        const data = []
        const ratio = []
        const samplingStart = moment(start).startOf(sampling)
        const samplingEnd = moment(end).startOf(sampling)
        let dataLength = samplingEnd.diff(samplingStart, sampling)
        if (sampling === "week" || sampling === "month") {
          dataLength++
        }

        for (
          let i = 0, end1 = dataLength, asc = 0 <= end1;
          asc ? i < end1 : i > end1;
          asc ? i++ : i--
        ) {
          if (i === 0 && samplingStart < start) {
            data.push([start, null])
            ratio.push(null)
          } else {
            var newDate = moment(start).startOf(sampling).add(i, sampling)
            if (newDate < end) {
              data.push([newDate, null])
              ratio.push(null)
            }
          }
        }

        return {
          data: data,
          ratio: ratio,
          totalCount: 0,
          startDate: start,
          endDate: end,
          sampling: sampling,
        }
      }

      const makeTimeRange = function (start, end = null, isISO) {
        if (isISO == null) {
          isISO = false
        }
        const { sampling } = $scope.pageOptions
        const format = isISO
          ? SamplingSrv[sampling].getIsoDayFormat()
          : SamplingSrv[sampling].getFormat()
        switch (sampling) {
          case "hour":
          case "day":
            return Locale.dateTime(start, format)
          case "week":
          case "month":
            return `${Locale.dateTime(start, format)} ~ ${Locale.dateTime(end, format)}`
        }
      }

      const makeChartData = function (index = null) {
        if (_.isNull(index)) {
          const dataLength = $scope.dataSet[0].ratio.length
          if ($scope.pageOptions.sampling === "hour" && dataLength > 12) {
            index = 12 + Math.floor((dataLength - 12) / 24) * 24 //get last noon
          } else {
            index = dataLength - 1
          }
        }
        $scope.curTimeBarIdx = index

        const chartData = []
        const refCount = $scope.dataRefCounts[index]
        $scope.dataSet.forEach((data) =>
          chartData.push({
            name: data.directionName,
            gateIdx: data.gateIdx,
            label: data.label,
            idxCode: data.idxCode,
            ratio: Math.round(data.ratio[index]),
            count: data.data[index][1],
            thickness: refCount ? Math.round(100 * (data.data[index][1] / refCount)) : 0,
            color: data.color,
            chartColor: $scope.colorMode === kColors ? `rgb(${data.color})` : STANDARD_COLOR,
            showCountingLine: $scope.colorMode === kColors ? true : false,
          })
        )
        return ($scope.chartData = chartData)
      }

      const isEmptyRow = (item, idx) => _.isNull(item.data[idx][1]) && _.isNull(item.ratio[idx])

      const load = function () {
        if ($scope.directionmapCameras.length === 0) {
          return
        }

        const { sampling } = $scope.pageOptions
        const startDate = moment($scope.pageOptions.dt.startDate)
        const endDate = moment($scope.pageOptions.dt.endDate)
        const loadGridOption = _.cloneDeep(gridOption)

        const period = startDate.format("YYYYMMDD") + "-" + endDate.format("YYYYMMDD")
        loadGridOption.filename = `${$scope.filePrefix}_${period}`
        loadGridOption.exporterCsvFilename = loadGridOption.filename + ".csv"

        const options = {
          from: startDate.format(),
          to: endDate.add(1, "days").startOf("day").format(),
          sampling: samplingKey(sampling),
        }

        if (_.isEqual(prevOptions, options)) {
          return
        }
        if (!isValidPeriod(startDate, endDate, sampling)) {
          return
        }

        prevOptions = options

        showLoading()

        $scope.dataSet = []
        $scope.cfgSet = []
        let dataLength = endDate.diff(startDate, sampling)
        $scope.dataRefCounts = _.fill(Array(dataLength), 0)

        return Promise.map($scope.directionmapCameras, function (camera) {
          const store = _.find($scope.storeList, (s) => s._id === camera._storeId)
          const newOptions = _.merge({}, options, {
            id: camera._id,
            sampling: options.sampling.toUpperCase(),
          })
          camera.storeName = store.name

          return getDriectionConfig({ id: camera._id }).then(function (cfg) {
            if (_.isEmpty(cfg.directions)) {
              return
            }

            $scope.cfgSet.push(cfg)
            cfg.directions.forEach(function (dir) {
              if (_.isEmpty(dir.gates)) {
                return
              }

              let idx = 0
              return dir.gates.forEach(function (gate, i) {
                if (gate.inout === "I") {
                  return
                }

                const label = `${dir.name}-` + String.fromCharCode(65 + idx)
                $scope.dataSet.push(
                  _.merge(
                    {
                      key: `${camera._id}_${label}`,
                      storeName: store.name,
                      sensorName: camera.name,
                      directionName: dir.name,
                      label,
                      idxCode: String.fromCharCode(65 + idx),
                      gateIdx: i,
                      color: dir.color,
                    },
                    getDefaultData(startDate, endDate, sampling)
                  )
                )
                return idx++
              })
            })
            return getDirectionmapData($scope.dataSet, newOptions, startDate, sampling)
          })
        })
          .then(function () {
            let isNoData = true
            $scope.dataSet.forEach((row) =>
              row.data.forEach(function (item) {
                if (item[1] != null) {
                  return (isNoData = false)
                }
              })
            )

            if (isNoData) {
              $scope.chartOption = null
              $scope.chartData = null
              $scope.isGridData = false
              $scope.gridOption = null
              hideLoading()
              return
            }

            if ($scope.isCamera) {
              $scope.chartOption = $scope.cfgSet[0]
              makeChartData()
            }

            const firstDataSet = _.first($scope.dataSet)
            dataLength = firstDataSet.data.length
            const showGridSet = []
            const gridSet = []
            const columnSet = []

            const timeFieldName =
              sampling === "hour" ? Locale.string("time from") : Locale.string("time")

            columnSet.push({
              field: "time",
              minWidth: 200,
              cellClass: "text-center",
              displayName: timeFieldName,
            })
            columnSet.push({
              field: "storeName",
              minWidth: 150,
              cellClass: "text-left",
              displayName: Locale.string("Store Name"),
            })
            columnSet.push({
              field: "sensorName",
              minWidth: 150,
              cellClass: "text-left",
              displayName: Locale.string("Sensor Name"),
            })
            columnSet.push({
              field: "label",
              minWidth: 150,
              cellClass: "text-left",
              displayName: Locale.string("Direction Name"),
            })
            columnSet.push({
              field: "count",
              minWidth: 110,
              cellClass: "text-right",
              displayName: Locale.string("Count"),
            })
            columnSet.push({
              field: "ratio",
              minWidth: 100,
              cellClass: "text-right",
              displayName: Locale.string("Rate") + "(%)",
            })

            loadGridOption.columnDefs = columnSet
            loadGridOption.minRowsToShow = $scope.minRowsToShowVal

            for (
              let i = 0, end1 = dataLength - 1, asc = 0 <= end1;
              asc ? i <= end1 : i >= end1;
              asc ? i++ : i--
            ) {
              var currentDate = moment(firstDataSet.data[i][0])
              var end =
                i === dataLength - 1
                  ? moment(endDate).subtract(1, "days")
                  : moment(firstDataSet.data[i + 1][0]).subtract(1, "days")

              for (
                var j = 0, end2 = $scope.dataSet.length, asc1 = 0 <= end2;
                asc1 ? j < end2 : j > end2;
                asc1 ? j++ : j--
              ) {
                var item = $scope.dataSet[j]
                if (isEmptyRow(item, i)) {
                  continue
                }

                var gridItem = []
                gridItem["storeName"] = item.storeName
                gridItem["sensorName"] = item.sensorName
                gridItem["label"] = item.label
                gridItem["count"] = item.data[i][1] != null ? item.data[i][1] : ""
                gridItem["ratio"] = item.ratio[i] != null ? item.ratio[i] : ""

                gridSet.push(_.merge({ time: makeTimeRange(currentDate, end, true) }, gridItem))
                showGridSet.push(
                  _.merge({ time: makeTimeRange(currentDate, end, false) }, gridItem)
                )
              }
            }

            loadGridOption.data = showGridSet
            loadGridOption.exportData = gridSet
            $scope.gridOption = loadGridOption
            if (gridSet.length > 0) {
              $scope.isGridData = true
            }
            hideLoading()
            return $scope.$apply()
          })
          .catch(function (err) {
            hideLoading()
            return console.warn("err", err)
          })
      }

      $scope.reload = function (fromInit) {
        if (fromInit == null) {
          fromInit = false
        }
        if (fromInit) {
          $scope.validTime = true
        }

        $scope.heatmapParam = {
          start: $scope.pageOptions.dt.startDate,
          end: $scope.pageOptions.dt.endDate,
          sampling: $scope.pageOptions.sampling,
        }
        return load()
      }

      const init = function () {
        heatmapCommon.changeGridSize("init")

        if ($stateParams.orgType === "sensor") {
          $scope.isCamera = true
          $scope.minRowsToShowVal = 10
          const colorMode = cache.get(cacheCMKey)
          if (colorMode) {
            $scope.colorMode = colorMode
          }
          $scope.customBtnTemplate = _.clone(customBtnTemplate)
        } else {
          $scope.minRowsToShowVal = 20
        }

        HeaderSrv.fetchCurrentCompany()
          .then(function (company) {
            $scope.currentCompany = company

            return SelectorSrv.getOrganizationInfo($stateParams.orgType, $stateParams.id)
              .then(function (orgInfo) {
                $scope.storeList = (() => {
                  switch ($stateParams.orgType) {
                    case "company":
                    case "storegroup":
                      return orgInfo.stores
                    case "store":
                    case "sensor":
                      return [orgInfo.store]
                  }
                })()
                $scope.filePrefix = (() => {
                  switch ($stateParams.orgType) {
                    case "company":
                      return $scope.currentCompany.name
                    case "storegroup":
                    case "store":
                      return $scope.currentCompany.name + "_" + orgInfo.store.name
                    case "sensor":
                      return (
                        $scope.currentCompany.name +
                        "_" +
                        orgInfo.store.name +
                        "_" +
                        orgInfo.sensor.name
                      )
                  }
                })()
                $scope.timezone = orgInfo.timezone
                $scope.heatmapWeight = orgInfo.store.heatmapWeight
                $scope.heatmapStats = orgInfo.store.stats

                $scope.directionmapCameras =
                  orgInfo.sensors != null
                    ? orgInfo.sensors.filter((camera) =>
                        __guard__(
                          camera.functions != null ? camera.functions.use : undefined,
                          (x) => x.directionmap
                        )
                      )
                    : undefined
                $scope.directionmapCameras = $filter("orderBy")($scope.directionmapCameras, "name")

                return new Promise(function (resolve, reject) {
                  if ($scope.isCamera) {
                    const image = new Image()
                    image.onload = function () {
                      snapshotRatio = this.height / this.width
                      setWidth()
                      return resolve()
                    }
                    return (image.src = $scope.directionmapCameras[0].heatmapUrl())
                  } else {
                    return resolve()
                  }
                })
              })
              .then(() => $scope.$broadcast("init-range-search", $scope.timezone))
          })
          .catch(function (err) {
            console.error("directionamp init error", err)
            return $state.go("default")
          })

        return $scope.shortcuts.forEach(hotkeys.bindTo($scope).add)
      }

      init()

      $scope.changeColorMode = function (mode) {
        const newchartData = _.map($scope.chartData, function (dir) {
          if (mode === kColors) {
            dir.chartColor = `rgb(${dir.color})`
            dir.showCountingLine = true
          } else {
            dir.chartColor = STANDARD_COLOR
            dir.showCountingLine = false
          }
          return dir
        })
        $scope.chartData = newchartData

        if ($scope.isCamera) {
          return cache.put(cacheCMKey, mode)
        }
      }

      $scope.$on("heatmap_timebar_update", function (e, index) {
        makeChartData(index)
        return $timeout(() => $scope.$apply())
      })

      $scope.$on("heatmap_timebar_dx_update", function (event, dx) {
        const index = $scope.curTimeBarIdx + dx
        if (!isValidTimeBarIdx(index)) {
          return
        }

        makeChartData(index)
        return $scope.$broadcast("move_timebar_dx_global", dx)
      })

      const totalStatsList = {}

      const calcTotalStats = function (stats) {
        let id
        if (!(id = heatmapCommon.findStatId(stats))) {
          return
        }

        if (!totalStatsList[id]) {
          totalStatsList[id] = {}
        }
        for (var mapname in stats) {
          totalStatsList[id][mapname] = stats[mapname]
        }
        const tstats = []
        $scope.directionmapCameras.map(function (cam) {
          if (totalStatsList[cam.accessKey] != null) {
            return tstats.push(totalStatsList[cam.accessKey])
          }
        })

        const gstats = mng.calcTotalStats(stats, tstats)
        heatmapCommon.setGlobalStats(gstats)

        return gstats
      }

      $scope.$on("heatmap_stats", (event, stats) =>
        $scope.$broadcast("heatmap_stats_global", calcTotalStats(stats))
      )

      $scope.isExtendPanorama = () =>
        $scope.isCamera &&
        __guard__(
          $scope.directionmapCameras != null ? $scope.directionmapCameras[0] : undefined,
          (x) => x.model
        ) === "panorama"

      var setWidth = function () {
        const height = window.innerHeight * 0.8
        let width = height / snapshotRatio
        const formWidth = $element.find(".directionmap-container").width()
        if (width > formWidth) {
          width = formWidth
        }

        return $element.find(".directionmap-container > .row").css("width", width)
      }

      return angular.element($window).on("resize", function () {
        if (!(snapshotRatio && $scope.isCamera)) {
          return
        }

        return setWidth()
      })
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
