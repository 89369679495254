/** @jsx jsx */
import { useState, useEffect } from "react"
import "react-calendar/dist/Calendar.css"
import { css, jsx } from "@emotion/react"
import Calendar from "react-calendar"
import { DateTime } from "luxon"
import PropTypes from "prop-types"

const datePickerWrapper = css`
  position: absolute;
  min-width: 220px;
  z-index: 999;
  display: block;
  font-size: 14px;
`

const DAY = "day"
const HOUR = "hour"

const dateEquals = (oldDate, newDate) => {
  return oldDate[0].equals(newDate[0]) && oldDate[1].equals(newDate[1])
}

const samplingEquals = (oldSampling, newSampling) => {
  return oldSampling == newSampling
}

export const DatePicker = ({
  date: propDate,
  sampling,
  minDate: propMinDate,
  maxDate: propMaxDate,
  maxRange,
  noSampling,
  onChangeDate,
  onChangeSampling,
  onChange,
}) => {
  const [maxDate, setMaxDate] = useState(propMaxDate)
  const [minDate, setMinDate] = useState(propMinDate)
  const [openCalendar, setOpenCalendar] = useState(false)
  const [calendarView, setCalendarView] = useState("month")

  const transformValidValue = (newDate, newSampling = sampling) => {
    let [startDate, endDate] = newDate
    if (minDate) {
      if (startDate.diff(minDate, "day").days < 0) {
        startDate = minDate
      }
      if (endDate.diff(minDate, "day").days < 0) {
        endDate = minDate.endOf("day")
      }
    }
    if (maxDate) {
      if (startDate.diff(maxDate, "day").days > 0) {
        startDate = maxDate.startOf("day")
      }
      if (endDate.diff(maxDate, "day").days > 0) {
        endDate = maxDate
      }
    }
    if (maxRange) {
      if (endDate.diff(startDate, "day").days > maxRange) {
        startDate = endDate.minus({ day: maxRange }).startOf("day")
      }
    }

    return [[startDate, endDate], newSampling]
  }

  const [date, _setDate] = useState(propDate)

  useEffect(() => {
    if (!dateEquals(propDate, date)) {
      _setDate(propDate)
    }
  }, [propDate])
  useEffect(() => {
    if (propMinDate && propMinDate.equals(minDate)) {
      setMinDate(propMinDate)
    }
  }, [propMinDate])
  useEffect(() => {
    if (propMaxDate && propMaxDate.equals(maxDate)) {
      setMaxDate(propMaxDate)
    }
  }, [propMaxDate])

  const setValidValue = (newDate, newSampling) => {
    const [validDate, validSampling] = transformValidValue(newDate, newSampling)

    if (!dateEquals(date, validDate)) {
      _setDate(validDate)
    }

    return [validDate, validSampling]
  }

  const handleOnChange = (newDate, newSampling) => {
    if (onChange) {
      if (noSampling) {
        if (!dateEquals(propDate, newDate)) {
          onChange(newDate)
        }
      } else {
        if (!dateEquals(propDate, newDate) || !samplingEquals(sampling, newSampling)) {
          onChange(newDate, newSampling)
        }
      }
    }
    if (onChangeDate) {
      if (!dateEquals(propDate, newDate)) {
        onChangeDate(newDate)
      }
    }
    if (onChangeSampling && !noSampling) {
      if (!samplingEquals(sampling, newSampling)) {
        onChangeSampling(newSampling)
      }
    }
  }

  const handleCloseCalendar = (event) => {
    event.preventDefault()
    // Restore to default date
    setValidValue(propDate, sampling)
    setOpenCalendar(false)
  }

  const handleOpenCalendar = (event) => {
    event.preventDefault()
    setOpenCalendar(true)
  }

  const handleApplyCalendar = (event) => {
    event.preventDefault()
    handleOnChange(date, sampling)
    setOpenCalendar(false)
  }

  const handleChangeDate = (date) => {
    // User select start date
    if (date.length == 1) {
      const startDate = DateTime.fromJSDate(date[0])
      if (maxRange) {
        let nextMaxDate = startDate.plus({ day: maxRange })
        let nextMinDate = startDate.minus({ day: maxRange })
        if (propMaxDate && nextMaxDate.diff(propMaxDate, "day").days > 0) {
          nextMaxDate = propMaxDate
        }
        if (propMinDate && nextMinDate.diff(propMinDate, "day").days < 0) {
          nextMinDate = propMinDate
        }
        setMaxDate(nextMaxDate)
        setMinDate(nextMinDate)
      }
    }
    // User select end date
    else {
      if (maxRange) {
        if (propMinDate) {
          setMinDate(propMinDate)
        } else {
          setMinDate(null)
        }

        if (propMaxDate) {
          setMaxDate(propMaxDate)
        } else {
          setMaxDate(null)
        }
      }
      setValidValue(
        date.map((v) => DateTime.fromJSDate(v)),
        sampling
      )
    }
  }

  const handleChangeView = ({ view: newView }) => {
    setCalendarView(newView)
  }

  const handleBegin = () => {
    if (minDate) {
      let unit = "day"
      // 현재 선택된 날짜 갭만큼 이동한다.
      const diff = parseInt(minDate.diff(date[0], unit).days)
      if (diff != 0) {
        const [newValue, newSampling] = transformValidValue(
          [minDate, date[1].plus({ day: diff })],
          sampling
        )
        handleOnChange(newValue, newSampling)
      }
    }
  }
  const handlePrev = () => {
    let startDate
    let endDate
    const range = Math.ceil(date[1].diff(date[0], "day").days)
    startDate = date[0].minus({ days: range })
    endDate = date[1].minus({ days: range })

    if (minDate) {
      if (startDate.diff(minDate, "day").days < 0) {
        return
      }
    }

    const [newValue, newSampling] = transformValidValue([startDate, endDate], sampling)
    handleOnChange(newValue, newSampling)
  }

  const handleNext = () => {
    let startDate
    let endDate
    const range = Math.ceil(date[1].diff(date[0], "day").days)
    startDate = date[0].plus({ days: range })
    endDate = date[1].plus({ days: range })

    if (maxDate) {
      if (endDate.diff(maxDate, "day").days > 0) {
        return
      }
    }

    const [newValue, newSampling] = transformValidValue([startDate, endDate], sampling)
    handleOnChange(newValue, newSampling)
  }

  const handleEnd = () => {
    if (maxDate) {
      let unit = "day"
      // 현재 선택된 날짜 갭만큼 이동한다.
      const diff = parseInt(maxDate.diff(date[1], unit).days)
      if (diff != 0) {
        const [newValue, newSampling] = transformValidValue(
          [date[0].plus({ day: diff }), maxDate],
          sampling
        )
        handleOnChange(newValue, newSampling)
      }
    }
  }

  const createSamplingHandler = (sampl) => () => {
    const [newDate, newSampling] = setValidValue(date, sampl)
    handleOnChange(newDate, newSampling)
  }
  const handleHourSampling = createSamplingHandler(HOUR)
  const handleDaySampling = createSamplingHandler(DAY)

  const handle24H = () => {
    const [newValue, newSampling] = transformValidValue(
      [date[1].startOf("day"), date[1].endOf("day")],
      sampling
    )
    handleOnChange(newValue, newSampling)
  }

  const handle7D = () => {
    const [newValue, newSampling] = transformValidValue(
      [date[1].minus({ week: 1 }).startOf("day"), date[1].endOf("day")],
      sampling
    )
    handleOnChange(newValue, newSampling)
  }

  const renderDate = (date) => {
    if (sampling == DAY || sampling == HOUR) {
      return date.toLocaleString({
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "2-digit",
      })
    } else {
      return date.toLocaleString({
        year: "numeric",
        month: "short",
      })
    }
  }

  const renderCalendar = () => {
    if (!openCalendar) {
      return null
    }

    return (
      <div css={datePickerWrapper}>
        <div className="date-picker-popup range">
          <Calendar
            onChange={handleChangeDate}
            onViewChange={handleChangeView}
            allowPartialRange={true}
            selectRange={true}
            value={date.map((v) => v.toJSDate())}
            minDate={minDate ? minDate.toJSDate() : null}
            maxDate={maxDate ? maxDate.toJSDate() : null}
            view={calendarView}
          />
          <div className="side">
            <button className="btn btn-primary btn-sm" onClick={handleApplyCalendar}>
              Apply
            </button>
            <button className="btn btn-default btn-sm" onClick={handleCloseCalendar}>
              Cancel
            </button>
            <div className="alert alert-warning help" role="alert">
              <div className="help-header">Max Period</div>
              <div className="help-content">{maxRange} Days</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="date-select-container">
      <div>
        <div>
          {renderCalendar()}
          <div className="uc-picker">
            <ul className="list-unstyled picker-container">
              {minDate ? (
                <li title="Start Date of Data" onClick={handleBegin}>
                  <i className="mdi mdi-skip-previous-circle date-move color-info left-btn enable" />
                </li>
              ) : null}
              <li onClick={handlePrev}>
                <i className="mdi mdi-arrow-left-drop-circle color-info date-move left-btn" />
              </li>
              <li>
                <ul className="calendar-box" onClick={handleOpenCalendar}>
                  <li>
                    <i className="mdi mdi-calendar" />
                  </li>
                  <li>{renderDate(date[0])}</li>
                  <li>{renderDate(date[1])}</li>
                </ul>
              </li>
              <li onClick={handleNext}>
                <i className="mdi mdi-arrow-right-drop-circle color-info date-move right-btn" />
              </li>
              {maxDate ? (
                <li title="End Date of Data" onClick={handleEnd}>
                  <i className="mdi mdi-skip-next-circle color-info date-move right-btn enable" />
                </li>
              ) : null}
            </ul>
          </div>
        </div>
        <div>
          <div className="term-btn-group">
            <label
              className="btn btn-info btn-sm btn-term"
              title="Date Range: 24 Hours"
              onClick={handle24H}
            >
              24H
            </label>
            <label
              className="btn btn-info btn-sm btn-term"
              title="Date Range: 7 Days"
              onClick={handle7D}
            >
              7D
            </label>
          </div>
        </div>
        {noSampling ? null : (
          <div>
            <div className="btn-group sampling-btn-group">
              <label
                className={`btn btn-default btn-sm ${sampling == HOUR ? "active" : undefined}`}
                onClick={handleHourSampling}
              >
                By Hours
              </label>
              <label
                className={`btn btn-default btn-sm ${sampling == DAY ? "active" : undefined}`}
                onClick={handleDaySampling}
              >
                By Day
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

DatePicker.propTypes = {
  date: PropTypes.arrayOf(PropTypes.instanceOf(DateTime)).isRequired,
  noSampling: PropTypes.bool,
  sampling: PropTypes.oneOf([DAY, HOUR]).isRequired,
  onChange: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeSampling: PropTypes.func,
  minDate: PropTypes.instanceOf(DateTime),
  maxDate: PropTypes.instanceOf(DateTime),
  maxRange: PropTypes.number,
}

export default DatePicker
