/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

angular
  .module("uCountitUiApp")

  .factory("CompanyReportSrv", function ($filter, $q, ApiSrv, SamplingSrv, UtilSrv) {
    const _calculateCompareCount = function (src, dest) {
      if (!src) {
        return 0
      }
      return Math.floor(((dest - src) / src) * 100 * 10) / 10
    }

    const _requestOptions = function (sampling) {
      if (sampling == null) {
        sampling = "day"
      }
      const datetime = moment().endOf("day").format("YYYY-MM-DDTHH:00:00")
      const cacheTime = moment().add(1, "hours").startOf("hour").format()

      return {
        datetime,
        cacheTime,
        sampling: SamplingSrv[sampling].getRequestOption(),
        entrance: true,
      }
    }

    const _getSamplingString = function (key) {
      const regExp = /(\d+)(\D)/
      const parser = regExp.exec(key)
      return SamplingSrv.getSamplingKey(parser[2])
    }

    const _makeChartData = function (resource) {
      if (!resource) {
        return
      }

      let chartArr = []
      let total = 0
      const sampling = _getSamplingString(resource.sampling)
      const dataLength = moment(resource.to).diff(moment(resource.from), sampling)
      ;(function () {
        let i = 0
        return (chartArr = __range__(0, dataLength, false).map(function () {
          if (i < dataLength) {
            i++
          }
          return [moment(resource.from).add(i - 1, sampling), 0]
        }))
      })()

      const theDay = moment(resource.from)
      _.forEach(resource.data, function (raw) {
        const rawDate = moment.utc(raw.statisticFor).startOf(sampling)
        const idx = rawDate.diff(theDay, sampling)
        if (sampling === "hour") {
          if (chartArr.beforeDay !== moment.utc(raw.statisticFor).date()) {
            chartArr[idx][1] = raw.count
          } else {
            chartArr[idx][1] = raw.count - chartArr.beforeCount
          }
          chartArr.beforeCount = raw.count
          chartArr.beforeDay = moment.utc(raw.statisticFor).date()
        } else {
          chartArr[idx][1] = raw.count
        }

        return (total += chartArr[idx][1])
      })

      return {
        chart: chartArr,
        total,
      }
    }

    const getCompareData = function (options, stores, period, sampling) {
      const deffered = $q.defer()
      let params = _requestOptions(sampling)

      async.eachSeries(
        stores,
        function (store, callback) {
          params = _.merge(params, {
            id: store._id,
          })

          const _getOriginalData = function (callback) {
            const dateFormat = "YYYYMMDD0000"
            const orgStart = moment(options.from).format(dateFormat)
            const orgEnd = moment(options.to).add(1, "days").format(dateFormat)
            params = _.merge(params, {
              from: orgStart,
              to: orgEnd,
            })

            return ApiSrv.getCountOfStore(params)
              .then((response) => callback(null, _makeChartData(response)))
              .catch((err) => callback(err))
          }

          const _getCompareData = function (callback) {
            let compareEnd, subtrackNum
            const dateFormat = "YYYYMMDD0000"

            switch (period) {
              case "1D":
              case "7D":
                subtrackNum = 7
                break
              case "3M":
                subtrackNum = 3
                break
              default:
                subtrackNum = 1
            }

            const compareStart = moment(options.from)
              .subtract(subtrackNum, options.sampling)
              .format(dateFormat)

            if (period === "1D") {
              compareEnd = moment(compareStart, dateFormat)
                .add(1, options.sampling)
                .format(dateFormat)
            } else {
              compareEnd = moment(options.from).format(dateFormat)
            }

            params = _.merge(params, {
              from: compareStart,
              to: compareEnd,
            })

            return ApiSrv.getCountOfStore(params)
              .then((response) => callback(null, _makeChartData(response)))
              .catch((err) => callback(err))
          }

          return async.series([_getOriginalData, _getCompareData], function (err, result) {
            store.count = result[0]
            store.compareCount = result[1]
            return callback(null, store)
          })
        },

        function (err) {
          if (err) {
            console.warn(err)
          }
          return deffered.resolve(stores)
        }
      )

      return deffered.promise
    }

    const getRankingsByRisingVisits = function (byPeriod, options, chartSampling) {
      if (chartSampling == null) {
        chartSampling = "day"
      }
      const parsingTimeRange = UtilSrv.parseTimeRange(byPeriod, options.referenceDay)
      if (byPeriod === "1D" || byPeriod === "24H") {
        chartSampling = "hour"
      }

      return getCompareData(parsingTimeRange, options.storeList, byPeriod, chartSampling).then(
        function (stores) {
          const diffDays = parsingTimeRange.to.diff(parsingTimeRange.from, "day") + 1

          const list = []

          for (var store of Array.from(stores)) {
            list.push({
              _id: store._id,
              name: store.name,
              count: store.count.total,
              avg: byPeriod !== "1D" ? store.count.total / diffDays : undefined,
              percent: _calculateCompareCount(store.compareCount.total, store.count.total),
              thumbnailUrl: store.thumbnailUrl(),
              chartData: store.count.chart,
              compareChartData: store.compareCount.chart,
            })
          }

          const orderedList = _.orderBy(list, "percent", "desc")
          let i = 1
          for (var rankingItem of Array.from(orderedList)) {
            rankingItem.ranking = i++
          }

          return {
            list: orderedList,
            from: parsingTimeRange.from,
            to: parsingTimeRange.to,
          }
        }
      )
    }

    const getCompanyVisitsCount = function (options) {
      const deffered = $q.defer()

      const dateFormat = "YYYYMMDD0000"
      const parsingTimeRange = UtilSrv.parseTimeRange(
        options.timeType != null ? options.timeType : "24H",
        options.referenceDay
      )
      let params = _requestOptions(parsingTimeRange.sampling)
      const start = moment(parsingTimeRange.from).format(dateFormat)
      const end = moment(parsingTimeRange.to).add(1, "days").format(dateFormat)
      const dataLength = moment(end, dateFormat).diff(
        moment(start, dateFormat),
        parsingTimeRange.sampling
      )
      params = _.merge(params, {
        from: start,
        to: end,
      })
      let chartArr = []

      ;(() => (chartArr = __range__(0, dataLength, false).map((i) => [i, 0])))()
      chartArr.noData = true

      async.eachSeries(
        options.storeList,
        function (store, callback) {
          params = _.merge(params, {
            id: store._id,
          })

          return ApiSrv.getCountOfStore(params).then(function (response) {
            if (_.isEmpty(response.data)) {
              return callback(null, chartArr)
            } else {
              chartArr.noData = false
              const curDate = moment.utc(params.from, dateFormat)
              chartArr.beforeCount = 0
              _.forEach(response.data, function (row) {
                const rowDate = moment.utc(row.statisticFor).startOf(parsingTimeRange.sampling)
                const idx = rowDate.diff(curDate, parsingTimeRange.sampling)
                if (parsingTimeRange.sampling === "hour") {
                  if (chartArr.beforeDay !== moment.utc(row.statisticFor).date()) {
                    chartArr[idx][1] += row.count
                  } else {
                    chartArr[idx][1] += row.count - chartArr.beforeCount
                  }
                  chartArr.beforeCount = row.count
                  return (chartArr.beforeDay = moment.utc(row.statisticFor).date())
                } else {
                  return (chartArr[idx][1] += row.count)
                }
              })
              return callback(null, chartArr)
            }
          })
        },
        (err) => deffered.resolve(chartArr)
      )

      return deffered.promise
    }

    return {
      getRankingsByRisingVisits,
      getCompanyVisitsCount,
    }
  })

function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
