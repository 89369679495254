"use strict"

import _ from "lodash"
import { getColors } from "./util/colors"
import { getSourceTypeFromFeature } from "./util/footfall"

let getStandardLabelColor = (index) => {
  return getColors(index, "standardlabel")
}

export const kMAX_COUNTERS = 6

export const weightFunctions = [
  {
    function: "byDoW",
    i18nKey: "weight_bydow",
    tooltip: "[[A1,B1], [A2,B2], ...], f(x)=Ax+B",
    defaultParams: [{ A: 1, B: 0 }],
    defaultStrParams: "[[1]]",
    isDefaultParams(params) {
      return _.isEqual(this.defaultParams, params)
    },
    paramsPattern: /^\[(\[\d+(\.\d+)?(,-?\d+)?])(,\[\d+(\.\d+)?(,-?\d+)?]){0,6}]$/,
    paramsToString(params) {
      try {
        return `[${params.map((w) => (w.B ? `[${w.A},${w.B}]` : `[${w.A}]`)).join(",")}]`
      } catch {
        return this.defaultStrParams
      }
    },
    stringToParams(strParams) {
      try {
        return JSON.parse(strParams).map((w) => ({ A: w[0], B: w[1] || 0 }))
      } catch {
        return this.defaultParams
      }
    },
  },
  {
    function: "byCount",
    i18nKey: "weight_bycount",
    tooltip: "[[count1, ratio1], [count2, ratio2], ...]",
    defaultParams: [
      { count: 100, ratio: 1 },
      { count: 200, ratio: 1 },
    ],
    defaultStrParams: "[[100,1],[200,1]]",
    isDefaultParams(params) {
      return params.every((p) => p.ratio === 1)
    },
    paramsPattern: /^\[(\[\d+,\d+(\.\d+)?])(,\[\d+,\d+(\.\d+)?])*]$/,
    paramsToString(params) {
      try {
        return `[${params.map((p) => `[${p.count},${p.ratio}]`).join(",")}]`
      } catch {
        return this.defaultStrParams
      }
    },
    stringToParams(strParams) {
      try {
        return JSON.parse(strParams).map((p) => ({ count: p[0], ratio: p[1] }))
      } catch {
        return this.defaultParams
      }
    },
  },
]

export const defaultWeight = {
  function: weightFunctions[0].function,
  params: weightFunctions[0].defaultParams,
}

export const kStandardLabels = [
  {
    key: "nolabel",
    _id: "f00000000000000000000000",
    name: "No Label",
    color: getStandardLabelColor(0),
    active: true,
    weights: defaultWeight,
    seedCounter: "No Label",
    i18nKey: "slabel_nolabel",
  },
  {
    key: "ot",
    _id: "f00000000000000000000001",
    name: "Outside Traffic",
    color: getStandardLabelColor(1),
    active: true,
    weights: defaultWeight,
    seedCounter: "Outside Traffic",
    i18nKey: "slabel_outsidetraffic",
  },
  {
    key: "entrance",
    _id: "f00000000000000000000002",
    name: "Entrance",
    color: getStandardLabelColor(2),
    active: true,
    weights: defaultWeight,
    seedCounter: "Footfall Entrance",
    i18nKey: "slabel_entrance",
  },
  {
    key: "exit",
    _id: "f00000000000000000000003",
    name: "Exit",
    color: getStandardLabelColor(3),
    active: true,
    weights: defaultWeight,
    seedCounter: "Exit",
    i18nKey: "slabel_exit",
  },
  {
    key: "nv",
    _id: "f00000000000000000000004",
    name: "Net Visits",
    color: getStandardLabelColor(4),
    active: true,
    weights: defaultWeight,
    seedCounter: "Net Visits",
    i18nKey: "slabel_netvisits",
  },
]

export const getStandardLabel = (id) => _.find(kStandardLabels, (d) => d._id == id)

export const getLabelName = (counter) => {
  const label = getStandardLabel(counter._labelId)
  return label ? label.name : counter.label ? counter.label.name : "Label 1"
}

export const getCounterColor = (counter) => {
  const label = getStandardLabel(counter._labelId)
  return label ? label.color : counter.label ? counter.label.color : "0, 0, 0"
}

export const kDEFAULT_COUNTERLABEL = kStandardLabels[0]

export const makePointString = (points) => {
  if (!points) return
  return points.map((p) => `${p.x}:${p.y}`).join(",")
}

export const counterSchema = (i, label, sourceType) => {
  return {
    active: false,
    _labelId: label._id,
    sourceType: sourceType | "VCA",
    source: `Ct${i}`,
    id: i,
    name: `Ct${i + 1}`,
    displayName: `Ct${i + 1}`,
    color: label.color,
    rgbcolor: `rgb(${label.color})`,
    label: label,
  }
}

export const addLabelInfo = (footfall, store, isMaxCounter = true) => {
  const length = isMaxCounter ? kMAX_COUNTERS : footfall.counters.length
  for (let i = 0; i < length; ++i) {
    let counter = footfall.counters[i]
    if (!counter) footfall.counters[i] = counter = counterSchema(i, kDEFAULT_COUNTERLABEL)

    let label = _.find(store.labels.concat(kStandardLabels), { _id: counter._labelId })
    if (!label) label = kDEFAULT_COUNTERLABEL
    counter._labelId = label._id
    counter.label = _.clone(label)
    counter.color = label.color
    counter.rgbcolor = `rgb(${label.color})`
  }
}

export const createDefaultFootfall = (footfalltype = "FF") => {
  const sourceType = getSourceTypeFromFeature(footfalltype)
  return {
    counters: Array(kMAX_COUNTERS)
      .fill(0)
      .map((v, i) => counterSchema(i, kDEFAULT_COUNTERLABEL, sourceType)),
  }
}
