"use strict"
const _ = require("lodash")

class PassingLine {
  // PassingLine([2,3,4], 6) means line[2,3,4] is selected on lines[0..6]
  constructor(linevalues = [], length = 0) {
    this.create(linevalues, length)
  }

  create(linevalues, length, verbose = false) {
    this.verbose = verbose
    this.setValues(linevalues, length)
    this.arrange()
  }

  setValues(linevalues, length) {
    // lines length
    this.maxValue = length
    // original linevalues
    this.values = linevalues.slice()
    // array of line created by linevalues
    this.lines = new Array(length).fill(0)
    linevalues.forEach((v) => {
      this.lines[v] = 1
    })
    if (this.verbose) console.info("lines", linevalues, this.lines)
    return this
  }

  getValues() {
    return this.values
  }

  toString() {
    return `{${this.maxValue}}[${this.values}]`
  }

  print() {
    console.info(this.toString())
  }

  incValue(v) {
    return (v + this.maxValue + 1) % this.maxValue
  }

  decValue(v) {
    return (v + this.maxValue - 1) % this.maxValue
  }

  isArranged(values = this.values) {
    for (let i = 0; i < values.length - 1; i++) {
      if (this.incValue(values[i]) !== values[i + 1]) {
        return false
      }
    }
    return true
  }

  // make arranged values from values(=lines)
  // [0,1,6,7] -> [6,7,0,1]
  // [0,2,1] -> [0,1,2]
  arrange() {
    let vs = []
    // if tail and head are connected
    if (_.last(this.lines) && this.lines[0]) {
      //find the broken pos and push the selected values after the position
      let pos = this.lines.indexOf(0)
      for (let i = 0; i < this.maxValue; i++) {
        pos = this.incValue(pos)
        if (this.lines[pos]) {
          vs.push(pos)
        }
      }
    } else {
      this.lines.forEach((v, i) => {
        if (v) {
          vs.push(i)
        }
      })
    }
    this.values = vs
    this.maxValue = this.lines.length
    if (this.verbose) console.info(`arranged values [${this.values}]`)
    return this
  }

  removePoint(pt) {
    if (this.maxValue <= 2) {
      throw new Error(`maxValue error ${this.maxValue}`)
    }
    let olines = this.lines.slice()

    const curSelected = this.lines[pt]
    const prevPos = this.decValue(pt)
    const prevSelected = this.lines[prevPos]
    if (!prevSelected && curSelected) {
      // select prev
      this.lines[prevPos] = 1
      // remove current
      this.lines.splice(pt, 1)
    } else {
      this.lines.splice(pt, 1)
    }
    if (this.verbose) console.info(`remove pos{${pt}} [${olines}] -> [${this.lines}]`)

    this.maxValue = this.maxValue - 1
    this.arrange()
    return this
  }

  addPoint(pt) {
    let olines = this.lines.slice()

    const isSelected = this.lines[pt]
    this.lines.splice(pt, 0, isSelected)
    if (this.verbose) console.info(`add pos{${pt}} [${olines}] -> [${this.lines}]`)

    this.maxValue = this.maxValue + 1
    this.arrange()
    return this
  }
}

module.exports = PassingLine
