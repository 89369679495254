"use strict"

import Promise from "bluebird"
import angular from "angular"
import { createFormClass } from "./form"

const RestfulApiNewCtrl = ($scope, $http, $state, Auth, Locale, RestApi) => {
  const Form = createFormClass($http, Locale)
  $scope.orgTypes = [
    {
      name: "Agency",
      value: "agency",
    },
    {
      name: "Company",
      value: "company",
    },
  ]

  Promise.all([RestApi.Agency.index(), RestApi.Company.index(), RestApi.Store.index()]).spread(
    (agencies, companies, stores) => {
      const form = new Form(agencies, companies, stores)
      const submitFunc = form.submit.bind(form)
      form.submit = (event) =>
        submitFunc(event).then((res) => {
          $state.go("app.admin.restfulapi", {}, { reload: true })
        })
      $scope.form = form
    }
  )
}

angular.module("uCountitUiApp").controller("RestfulApiNewCtrl", RestfulApiNewCtrl)
