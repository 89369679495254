/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory("Face", function ($q, ApiSrv, SamplingSrv, RateValue) {
    const requestDateFormat = "YYYYMMDD0000"

    const genderList = [
      { name: "female", text: "Female", short: "F", color: "#f7c4d0" },
      { name: "male", text: "Male", short: "M", color: "#7adbe5" },
    ]

    const colorList = [
      "#3b71d1",
      "#a4eaea",
      "#4887aa",
      "#b8ddf4",
      "#7171ea",
      "#00c2c7",
      "#74aacc",
      "#098ec1",
      "#1f6aa3",
      "#9d76c1",
    ]
    const femaleColorlist = [
      "#E8C856",
      "#FFCF6B",
      "#E8AB56",
      "#FFA65E",
      "#FFB278",
      "#E88861",
      "#FF560B",
      "#E85756",
      "#FF5EA8",
    ]

    const defaultAgeType = {
      name: "",
      text: "",
      color: null,
      chartList: [],
      total: 0,
      avg: 0,
      avgTotal: 0,
    }

    const getGenderData = function (options, useFaceCount) {
      if (useFaceCount == null) {
        useFaceCount = false
      }
      const deferred = $q.defer()

      const requestOptions = _.merge({}, options, {
        from: moment(options.from).format(requestDateFormat),
        to: moment(options.to).format(requestDateFormat),
        sampling: SamplingSrv[options.selectedSampling].getRequestOption(),
        contentsType: "age",
        usage: "gender",
      })

      if (useFaceCount) {
        requestOptions.face = true
      }

      ApiSrv.getContentsData(requestOptions).then(
        function (response) {
          if (response == null || _.isEmpty(response != null ? response.data : undefined)) {
            return deferred.resolve([])
          } else {
            return deferred.resolve(_newGenerateGenderData(requestOptions, response.data))
          }
        },
        (err) => deferred.reject(err)
      )

      return deferred.promise
    }

    var _newGenerateGenderData = function (options, genderData) {
      let genderArr = []
      const sampling = options.selectedSampling
      const startDate = moment(options.from, requestDateFormat).startOf(sampling)
      const endDate = moment(options.to, requestDateFormat).startOf(sampling)
      let dataLength = endDate.diff(startDate, sampling)
      if (sampling === "week" || sampling === "month") {
        dataLength++
      }

      ;(function () {
        let i = 0
        return (genderArr = __range__(0, dataLength, false).map(function () {
          if (i < dataLength) {
            i++
          }
          return {
            date: moment.utc(options.from, requestDateFormat).add(i - 1, sampling),
            female: null,
            male: null,
            percent: {
              female: null,
              male: null,
            },
          }
        }))
      })()

      const curDate = moment.utc(options.from, requestDateFormat)

      _.forEach(genderData, function (row) {
        const rowDate = moment.utc(row.statisticFor).startOf(sampling)
        const idx = rowDate.diff(curDate, sampling)

        if (row.isValid) {
          genderArr[idx].female = row.female
          genderArr[idx].male = row.male
          genderArr[idx].percent.female = row.femaleRate
          return (genderArr[idx].percent.male = row.maleRate)
        } else {
          genderArr[idx].female = "-"
          genderArr[idx].male = "-"
          genderArr[idx].percent.female = "-"
          return (genderArr[idx].percent.male = "-")
        }
      })

      return genderArr
    }

    const getAgeGroupData = function (options, ageTypeList, useFaceCount, classifyGender) {
      if (useFaceCount == null) {
        useFaceCount = false
      }
      if (classifyGender == null) {
        classifyGender = false
      }
      const deferred = $q.defer()

      const requestOptions = _.merge({}, options, {
        from: moment(options.from).format(requestDateFormat),
        to: moment(options.to).format(requestDateFormat),
        sampling: SamplingSrv[options.selectedSampling].getRequestOption(),
        contentsType: "age",
        usage: "ageGroup",
      })

      if (useFaceCount) {
        requestOptions.face = true
      }

      ApiSrv.getContentsData(requestOptions).then(
        function (response) {
          if (response == null || _.isEmpty(response != null ? response.data : undefined)) {
            return deferred.resolve([])
          } else {
            const generateFunc = classifyGender
              ? _newGenerateGenderAgeData
              : _newGenerateAgeGroupData

            return deferred.resolve(generateFunc(requestOptions, ageTypeList, response.data))
          }
        },
        (err) => deferred.reject(err)
      )

      return deferred.promise
    }

    var _newGenerateAgeGroupData = function (options, ageTypeList, ageGroupData) {
      let ageArr = []
      const sampling = options.selectedSampling
      const startDate = moment(options.from, requestDateFormat).startOf(sampling)
      const endDate = moment(options.to, requestDateFormat).startOf(sampling)
      let dataLength = endDate.diff(startDate, sampling)
      if (sampling === "week" || sampling === "month") {
        dataLength++
      }

      ;(function () {
        let i = 0
        return (ageArr = __range__(0, dataLength, false).map(function () {
          if (i < dataLength) {
            i++
          }

          const row = {
            date: moment.utc(options.from, requestDateFormat).add(i - 1, sampling),
            percent: {},
            total: null,
          }

          ageTypeList.forEach(function (ageType) {
            row[ageType.name] = null
            return (row.percent[ageType.name] = null)
          })

          return row
        }))
      })()

      const curDate = moment.utc(options.from, requestDateFormat)

      _.forEach(ageGroupData, function (row) {
        const rowDate = moment.utc(row.statisticFor).startOf(sampling)
        const idx = rowDate.diff(curDate, sampling)
        return (() => {
          const result = []
          for (let i = 0; i < ageTypeList.length; i++) {
            var ageType = ageTypeList[i]
            if (row.isValid) {
              ageArr[idx][ageType.name] = row.ageGroup[i]
              ageArr[idx].percent[ageType.name] = row.ageGroupRate[i]
              result.push((ageArr[idx].total += ageArr[idx][ageType.name]))
            } else {
              ageArr[idx][ageType.name] = "-"
              ageArr[idx].percent[ageType.name] = "-"
              result.push((ageArr[idx].total = "-"))
            }
          }
          return result
        })()
      })

      return ageArr
    }

    var _newGenerateGenderAgeData = function (options, ageTypeList, ageGroupData) {
      let ageArr = []
      const sampling = options.selectedSampling
      const startDate = moment(options.from, requestDateFormat).startOf(sampling)
      const endDate = moment(options.to, requestDateFormat).startOf(sampling)
      let dataLength = endDate.diff(startDate, sampling)
      if (sampling === "week" || sampling === "month") {
        dataLength++
      }

      ;(function () {
        let i = 0
        return (ageArr = __range__(0, dataLength, false).map(function () {
          if (i < dataLength) {
            i++
          }

          const row = {
            date: moment.utc(options.from, requestDateFormat).add(i - 1, sampling),
            percent: {},
            total: _initGenderArr(null),
          }

          ageTypeList.forEach(function (ageType) {
            row[ageType.name] = _initGenderArr(null)
            return (row.percent[ageType.name] = _initGenderArr(null))
          })

          return row
        }))
      })()

      const curDate = moment.utc(options.from, requestDateFormat)

      _.forEach(ageGroupData, function (row) {
        const rowDate = moment.utc(row.statisticFor).startOf(sampling)
        const idx = rowDate.diff(curDate, sampling)
        const total = _.sum(row.ageGroup)

        return (() => {
          const result = []
          for (let i = 0; i < ageTypeList.length; i++) {
            var ageType = ageTypeList[i]
            if (row.isValid) {
              ageArr[idx][ageType.name].female = row.ageGroupFemale[i]
              ageArr[idx][ageType.name].male = row.ageGroupMale[i]
              ageArr[idx].percent[ageType.name].female = _getRate(row.ageGroupFemale[i], total)
              ageArr[idx].percent[ageType.name].male = _getRate(row.ageGroupMale[i], total)
              ageArr[idx].total.female += row.ageGroupFemale[i]
              result.push((ageArr[idx].total.male += row.ageGroupMale[i]))
            } else {
              ageArr[idx][ageType.name] = _initGenderArr("-")
              ageArr[idx].percent[ageType.name] = _initGenderArr("-")
              result.push((ageArr[idx].total = _initGenderArr("-")))
            }
          }
          return result
        })()
      })

      return ageArr
    }

    var _initGenderArr = (val) => ({
      female: val,
      male: val,
    })

    var _getRate = function (value, total) {
      if (total <= 0) {
        return 0
      }
      return parseFloat(((value / total) * 100).toFixed(1))
    }

    const makeAgeTypeList = function (ageGroup, classifyGender) {
      if (classifyGender == null) {
        classifyGender = false
      }
      if (classifyGender) {
        defaultAgeType.total = _initGenderArr(0)
        defaultAgeType.avg = _initGenderArr(0)
        defaultAgeType.avgTotal = _initGenderArr(0)
      }

      const lastIndex = ageGroup.length - 1
      const ageTypeList = ageGroup.map(function (row, idx) {
        const name = `group-${idx}`
        const color = classifyGender
          ? {
              male: colorList[idx],
              female: femaleColorlist[idx],
            }
          : colorList[idx]

        const text = (() => {
          switch (idx) {
            case 0:
              return `~${row[1]}`
            case lastIndex:
              return `${row[0]}~`
            default:
              return `${row[0]}~${row[1]}`
          }
        })()

        return _.merge({}, defaultAgeType, { name, color, text })
      })

      return ageTypeList
    }

    const getAgeData = function (options, useFaceCount) {
      if (useFaceCount == null) {
        useFaceCount = false
      }
      const deferred = $q.defer()

      const requestOptions = _.merge({}, options, {
        from: moment(options.from).format(requestDateFormat),
        to: moment(options.to).format(requestDateFormat),
        sampling: SamplingSrv[options.selectedSampling].getRequestOption(),
        contentsType: "age",
        usage: "ages",
      })

      if (useFaceCount) {
        requestOptions.face = true
      }

      ApiSrv.getContentsData(requestOptions).then(
        function (response) {
          if (response == null || _.isEmpty(response != null ? response.data : undefined)) {
            return deferred.resolve([])
          } else {
            return deferred.resolve(response.data)
          }
        },
        (err) => deferred.reject(err)
      )

      return deferred.promise
    }

    return {
      genderList,
      getGenderData,
      getAgeGroupData,
      getAgeData,
      makeAgeTypeList,
    }
  })

function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
