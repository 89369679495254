/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

angular
  .module("uCountitUiApp")

  .factory("ServiceStatsSrv", function ($rootScope, $resource, $q, $cacheFactory, Auth, ApiSrv) {
    const cache = $cacheFactory("serviceApiCache")
    let allNames = {}

    const _ClearAllName = () => (allNames = {})

    const _AddName = (id, name) => (allNames[id] = name)

    const _getName = (id) => allNames[id]

    const _mergeData = function (tree, ci, name, value) {
      if (tree[ci.company] == null) {
        tree[ci.company] = { name: _getName(ci.company) }
      }
      if (tree[ci.company][name] == null) {
        tree[ci.company][name] = 0
      }
      tree[ci.company][name] += value

      if (tree[ci.company][ci.store] == null) {
        tree[ci.company][ci.store] = { name: _getName(ci.store) }
      }
      if (tree[ci.company][ci.store][name] == null) {
        tree[ci.company][ci.store][name] = 0
      }
      tree[ci.company][ci.store][name] += value

      if (tree[ci.company][ci.store][ci.camera] == null) {
        tree[ci.company][ci.store][ci.camera] = { name: _getName(ci.camera) }
      }
      if (tree[ci.company][ci.store][ci.camera][name] == null) {
        tree[ci.company][ci.store][ci.camera][name] = 0
      }
      return (tree[ci.company][ci.store][ci.camera][name] = value)
    }

    const _makeTreeData = function (res) {
      const tree = {}
      res.counter.forEach(function (d) {
        const ci = d._id
        const cameratotalhours = Math.floor((d.count * d.sampling) / 3600 / 24)
        return _mergeData(tree, ci, "counter", cameratotalhours)
      })
      res.heatmap.forEach(function (d) {
        const ci = d._id
        const cameratotalhours = Math.floor((d.count * d.sampling) / 3600 / 24)
        return _mergeData(tree, ci, "heatmap", cameratotalhours)
      })
      return tree
    }

    const _makeTableData = function (tree) {
      let camera, company, store
      const companies = []
      const stores = []
      const cameras = []
      for (var ckey in tree) {
        company = tree[ckey]
        if (typeof company !== "object") {
          continue
        }
        companies.push({
          company: company.name,
          counter: company.counter,
          heatmap: company.heatmap,
        })
        for (var skey in company) {
          store = company[skey]
          if (typeof store !== "object") {
            continue
          }
          stores.push({
            company: company.name,
            store: store.name,
            counter: store.counter,
            heatmap: store.heatmap,
          })
          for (var akey in store) {
            camera = store[akey]
            if (typeof camera !== "object") {
              continue
            }
            cameras.push({
              company: company.name,
              store: store.name,
              camera: camera.name,
              counter: camera.counter,
              heatmap: camera.heatmap,
            })
          }
        }
      }
      return { date: tree.date, company: companies, store: stores, camera: cameras }
    }

    const _statsForTargets = function (group, node, array) {
      // e.g. group:comapny, node:servicetime
      if (array == null) {
        array = true
      }
      return $resource(
        $rootScope.globalConfig.apipath + `/${group}/:id/stats/${node}`,
        { id: "@id" },
        {
          get: {
            isArray: array,
          },
        }
      )
    }

    const getServiceTimes = function (group, options) {
      if (options) {
        options._ = Date.now()
      }
      const deffered = $q.defer()
      const cacheId = "getServiceTimeStats-" + JSON.stringify(options)
      _statsForTargets(group, "servicetime", false).get(options, function (res) {
        const tree = _makeTreeData(res)
        const table = _makeTableData(tree)
        cache.put(cacheId, table)
        return deffered.resolve(table)
      })
      return deffered.promise
    }

    const getEnabledTimes = function (group, options) {
      const deffered = $q.defer()
      const cacheId = "getEnabledTimes-" + JSON.stringify(options)
      _statsForTargets(group, "enabledtime").get(options, function (res) {
        res.forEach(function (cam) {
          cam.cameraname = _getName(cam.cameraid)
          return (cam.enabledtime = Math.ceil(moment.duration(cam.totalenabledtime).asDays()))
        })
        cache.put(cacheId, res)
        return deffered.resolve(res)
      })
      return deffered.promise
    }

    const getLogs = function (group, query) {
      const deffered = $q.defer()
      const cacheId = "getServiceAcitvityLogs-" + JSON.stringify(query)
      _statsForTargets(group, "activitylog").get(query, function (res) {
        const logs = []
        for (var l of Array.from(res)) {
          logs.push({
            date: l.at,
            name: l.who.name,
            target: `${l.what.name} (${l.what.dtype})`,
            activity: l.how,
            misc: l.desc,
          })
        }
        cache.put(cacheId, logs)
        return deffered.resolve(logs)
      })
      return deffered.promise
    }

    const readNames = function (callback) {
      _ClearAllName()
      const names = {}
      return async.parallel(
        [
          (callback) =>
            ApiSrv.getAllSpace().then(function (res) {
              names.space = _.map(res, function (el) {
                _AddName(el._id, el.name)
                return {
                  _id: el._id,
                  name: el.name,
                }
              })
              return callback(null)
            }),
          (callback) =>
            ApiSrv.getAllAgency().then(function (res) {
              names.agency = _.map(res, function (el) {
                _AddName(el._id, el.name)
                return {
                  _id: el._id,
                  name: el.name,
                }
              })
              return callback(null)
            }),
          (callback) =>
            ApiSrv.getAllCompany().then(function (res) {
              names.company = _.map(res, function (el) {
                _AddName(el._id, el.name)
                return {
                  _id: el._id,
                  name: el.name,
                }
              })
              return callback(null)
            }),
          (callback) =>
            ApiSrv.getAllStore().then(function (res) {
              names.store = _.map(res, function (el) {
                _AddName(el._id, el.name)
                return {
                  _id: el._id,
                  name: el.name,
                }
              })
              return callback(null)
            }),
          (callback) =>
            ApiSrv.getAllCamera().then(function (res) {
              names.camera = _.map(res, function (el) {
                _AddName(el._id, el.name)
                return {
                  _id: el._id,
                  name: el.name,
                }
              })
              return callback(null)
            }),
        ],
        (err, results) => callback(names)
      )
    }

    return {
      getServiceTimes,
      getEnabledTimes,
      getLogs,
      readNames,
    }
  })
