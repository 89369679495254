/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

import config from "./config"

angular
  .module("uCountitUiApp")

  .factory(
    "InitializeSrv",
    (
      $http,
      $q // AngularJS will instantiate a singleton by calling 'new' on this function
    ) =>
      function () {
        const defer = $q.defer()
        const globalConfig = config
        // Todo: Get from account
        $http
          .get(globalConfig.apipath + "/company")
          .success(function (data) {
            globalConfig.companyId = data[0]._id
            return defer.resolve(globalConfig)
          })
          .error(function (data) {
            console.error(data)
            return defer.reject(globalConfig)
          })

        return defer.promise
      }
  )
