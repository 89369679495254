// only min file compatibility with windows
// Some ngModules need those modules
const requireExport = require.context("!file-loader?name=[path][name].[ext]!../vendor", true, /.*/)
require("lodash")
window.XLSX = require("xlsx")
window.moment = require("moment-timezone")
window.async = require("async")
window.$ = window.jQuery = require("jquery")
window.fabric = require("fabric").fabric
$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
  options.async = true
})
require("bootstrap-daterangepicker")
require("bootstrap")
require("jquery-slimscroll")
require("chosen-js")
require("table-export")
require("jstz")
require("pdfmake/build/pdfmake.min")

// https://github.com/cowboy/jquery-resize is not comptablity on webpack.
requireExport("./scripts/jquery.flot.resize.min")

require("flot")
require("flot/jquery.flot.canvas.js")
require("flot/jquery.flot.image.js")
require("../vendor/scripts/jquery.flot.orderBars.min") // it should load before stack plugin
require("../vendor/scripts/jquery.flot.animator.min")
require("flot/jquery.flot.stack.js")
require("flot/jquery.flot.categories.js")
require("flot/jquery.flot.navigate.js")
require("flot/jquery.flot.symbol.js")
require("flot/jquery.flot.crosshair.js")
require("flot/jquery.flot.pie.js")
require("flot/jquery.flot.threshold.js")
require("flot/jquery.flot.errorbars.js")
require("flot/jquery.flot.time.js")
require("flot/jquery.flot.fillbetween.js")
require("flot/jquery.flot.selection.js")
// flot.tooltip is only comptable with flot 0.8.5
require("jquery.flot.tooltip/js/jquery.flot.tooltip.js")
require("flot-axislabels/jquery.flot.axislabels.js")

require("../components/daterangepicker2")

// missing bower packages:
// flot-animator
// flot-curvedlines - not used anywhere
import axios from "axios"
import { authInterceptor } from "./interceptors"

axios.interceptors.request.use(authInterceptor.request)
axios.interceptors.response.use(authInterceptor.response, authInterceptor.responseError)
