/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("focusMe", ($timeout) => ({
    restrict: "A",

    scope: { trigger: "=focusMe" },

    link(scope, element) {
      return scope.$watch("trigger", function (value) {
        if (value) {
          return $timeout(() => element[0].focus())
        }
      })
    },
  }))
