/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

// kRoleSymbol = {
//   "dev": "🅓",
//   "system": "🅢",
//   "agency": "🅐",
//   "company": "🅒",
//   "store": "🅣",
// }
const kRoleSymbol = {
  dev: "Ⓓ",
  system: "Ⓢ",
  agency: "Ⓐ",
  company: "Ⓒ",
  store: "Ⓣ",
}
angular
  .module("uCountitUiApp")

  .directive("roleMark", (Auth, Account) => ({
    restrict: "A",

    //['role', 'type']
    scope: {
      roleMark: "=",
    },

    link(scope, element) {
      const getRoleId = function (role) {
        role = role.toLowerCase()
        switch (role) {
          case Account.DEV_ADMIN:
            return "dev"
          case Account.SPACE:
            return "system"
          default:
            return role
        }
      }
      const setRoleMarkClass = function (role, type, color) {
        if (!role) {
          return
        }
        role = getRoleId(role)

        type = (type != null ? type.toLowerCase() : undefined) || "section"
        if (type === "ti") {
          type = "tableicon"
        }
        if (type === "sc") {
          type = "section"
        }

        element.addClass("role-mark")
        if (type === "section") {
          return element.append(
            '<sup class="section ' + role + ' ">' + kRoleSymbol[role] + "</sup>"
          )
        } else if (type === "menu") {
          return element.append("<sup>" + kRoleSymbol[role] + "</sup>")
        } else if (type === "ms") {
          element.addClass(role)
          return element.append("<sup>" + kRoleSymbol[role] + "</sup>")
        } else {
          return element.addClass(role)
        }
      }

      scope.$watch("roleMark", function (value) {
        if (!_.isArray(value)) {
          return
        }

        return Auth.fetchCurrentAccount().then(function (account) {
          if (account.gteAgency()) {
            return setRoleMarkClass(value[0], value[1])
          }
        })
      })
    },
  }))
