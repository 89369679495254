/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

const ss = require("simple-statistics")
const h337 = require("common-library/vendor/heatmap").default
const mng = require("common-library/product-core/heatmapData")
const heatmapCommon = require("../../components/heatmappanel/heatmapCommon")
const Promise = require("bluebird")
import chroma from "chroma-js"

angular
  .module("uCountitUiApp")
  .directive(
    "heatmapPanel",
    (
      $compile,
      $timeout,
      $window,
      ApiSrv,
      uiGridExporterService,
      Auth,
      usSpinnerService,
      Heatmaptag,
      Locale,
      HeaderSrv
    ) => ({
      restrict: "E",
      templateUrl: "components/heatmappanel/heatmapPanel.html",
      replace: true,

      scope: {
        camera: "=data",
        param: "=",
        imageRatio: "=",
        timezone: "=", // camera timezone
        ready: "=?",
        hideFrame: "=?", //Boolean or Object({linkBtn: false, exportBtn: false, timeBar: false, scaleBar: false, statusbar: false})
        heatmapType: "=?showData", // {"pass", "dwell"}
        titlePrefix: "=",
        heatmapWeight: "=", // heatmap weight of store
        canvasInfo: "=?",
        backgroundMode: "=?", // true : use it as a background image.
        //        no display "No Data", hide heatmap type icon
        heatmapStats: "=", // heatmap stats of store
        pageName: "@",
        customBtnTemplate: "=",
        mouseAction: "=?", // mouse action(over/zoom) to show infobox. default is true
      },

      controller($scope, $element, $cookieStore, $templateCache, hotkeys) {
        let getActivityColorList
        const kPASS = "pass"
        const kDWELL = "dwell"
        $scope.HEATMAPLIST = {
          PASS: kPASS,
          DWELL: kDWELL,
        }
        $scope.panoramaHM = kDWELL

        const kFLOORPLAN = "floorplan"
        const kPANORAMA = "panorama"
        $scope.PANORAMABGI = {
          FLOORPLAN: kFLOORPLAN,
          PANORAMA: kPANORAMA,
        }
        $scope.panoramaBGI = kFLOORPLAN
        const MIN_WIDTH = 496

        $scope.startDatetime = null
        $scope.endDatetime = null
        $scope.period = null
        $scope.linkedHeatmap = $scope.linkedHeatmap != null ? $scope.linkedHeatmap : true
        $scope.infobox = { show: false }
        $scope.totalStats = {}
        $scope.heatmapType =
          $scope.heatmapType != null ? $scope.heatmapType : $scope.HEATMAPLIST.PASS
        $scope.heatmapWeight =
          $scope.heatmapWeight != null ? $scope.heatmapWeight : { pass: 1, dwell: 1 }
        $scope.canvasInfo = $scope.canvasInfo != null ? $scope.canvasInfo : { width: 0, height: 0 }
        $scope.hideFrame = $scope.hideFrame != null ? $scope.hideFrame : false
        $scope.backgroundMode = $scope.backgroundMode != null ? $scope.backgroundMode : false
        $scope.isNoData = false
        $scope.mouseAction = $scope.mouseAction != null ? $scope.mouseAction : true

        var isPanormaSensor = () => $scope.camera.model == "panorama"
        var supportDirectionmap = () =>
          $scope.camera.functions.use.directionmap && $scope.backgroundMode
        //isPanormaSensor
        // if ($scope.camera.functions.use.flowmap) {
        //   if ($scope.backgroundMode) {
        //     $scope.heatmapType = $scope.panoramaHM
        //     $scope.background = $scope.camera.backgroundImageUrl($scope.panoramaBGI)
        //   } else {
        //     $scope.background = $scope.camera.heatmapUrl()
        //   }
        // } else {
        //   $scope.background = $scope.camera.heatmapUrl()
        // }
        if (isPanormaSensor()) {
          if (supportDirectionmap()) {
            $scope.heatmapType = $scope.panoramaHM
          }

          $scope.background = $scope.camera.backgroundImageUrl($scope.panoramaBGI)
        } else {
          $scope.background = $scope.camera.heatmapUrl()
        }

        const kDefaultScalebar = 250 // 250 / 1000
        const heatmapElement = $element.find("#heatmapContainer")
        let heatmapInstance = null
        const gridAreaCanvas = __guard__($element.find("#gridArea"), (x) => x[0])
        let gridAreaCtx = null
        let timebarHandler = null
        let scaleHandler = null
        $scope.firstFlag = false
        const kErrorImgeUrl = "/assets/images/no-image.svg"
        const kBlockedImageUrl = "/assets/images/blocked-image.svg"
        let oldTimeSliderValue = null
        const kSnapshotRatio = 9 / 16
        const link = document.createElement("a")
        link.style.display = "none"
        document.body.appendChild(link)
        const context = document.createElement("canvas").getContext("2d")
        const gaugeCanvas = __guard__($element.find("#gauge"), (x1) => x1[0])
        let gaugeCtx = null
        let gaugeBox = {}

        let savedTags = []

        $scope.tooltip = {
          pass: Locale.string("Traffic Heatmap"),
          dwell: Locale.string("Dwell Heatmap"),
          panorama: Locale.string("btn_panoramic_img"),
          floorplan: Locale.string("btn_floorplan"),
          download: Locale.string("Download"),
          link: Locale.string("Link"),
        }

        const isShowScaleBubble = function (state) {
          const element = $element.find(".scalebar .rz-bubble")
          if (!element) {
            return
          }

          if (state) {
            return element.css("display", "inline-block")
          } else {
            return element.css("display", "none")
          }
        }

        $scope.scalebarOption = {
          vertical: true,
          floor: 0,
          ceil: 1000,
          step: 10,
          hideLimitLabels: true,
          showSelectionBar: true,
          onStart(sliderId) {
            return isShowScaleBubble(true)
          },
          onChange(sliderId, modelValue) {
            $scope.$emit("scalebar_move", modelValue)
            if ($scope.linkedHeatmap) {
              return $scope.$emit("heatmap_scale_update", modelValue)
            }
          },
          onEnd(sliderId) {
            return isShowScaleBubble(false)
          },
          translate(value) {
            return $scope.getScalePos(value) + "×" + $scope.getHeatmapWeight()
          },
        }

        $scope.timebarData = {
          options: {
            floor: 0,
            ceil: 24,
            hideLimitLabels: true,
            showSelectionBar: true,
            draggableRange: true,
            draggableRangeOnly: true,
            noSwitching: true,
            translate(val, sliderId, label) {
              if (label === "high") {
                return ""
              }

              const sampling = getSampling($scope.period)
              const date = (() => {
                switch ($scope.period) {
                  case "hour":
                    if ($scope.timebarData.options.ceil > 24) {
                      return Locale.dateTime(
                        moment($scope.startDatetime).add(val, sampling),
                        "fullDateTime"
                      )
                    } else {
                      return Locale.dateTime(moment({ hour: val % 24 }), "hour")
                    }
                  case "day":
                    return Locale.dateTime(
                      moment($scope.startDatetime).add(val, sampling),
                      "fullDate"
                    )
                  case "week":
                    var startWeek = moment($scope.startDatetime).add(val, sampling)
                    var endWeek = moment(startWeek).endOf("week")
                    return (
                      Locale.dateTime(startWeek, "fullDate") +
                      "~" +
                      Locale.dateTime(endWeek, "fullDate")
                    )
                  case "month":
                    return Locale.dateTime(moment($scope.startDatetime).add(val, sampling), "month")
                  default:
                    return Locale.dateTime(moment({ hour: val % 24 }), "hour")
                }
              })()
              return `${date}`
            },

            onChange(sliderId, modelValue) {
              if (oldTimeSliderValue !== modelValue) {
                oldTimeSliderValue = modelValue
                $scope.$emit("timebar_move", modelValue)
                if ($scope.linkedHeatmap) {
                  return $scope.$emit("heatmap_timebar_update", modelValue)
                }
              }
            },
            onEnd(sliderId, modelValue) {
              const start = $scope.startDatetime || moment().startOf("day")
              return $scope.$emit(
                "heatmap_img_refresh",
                moment(start).add(modelValue, getSampling($scope.period))
              )
            },
          },
        }

        const getStoreIsoTime = function (tm) {
          if (tm == null) {
            tm = moment()
          }
          return tm.tz($scope.timezone)
        }

        const time2StoreIsoTime = function (lc) {
          const tm = lc.format("YYYY-MM-DDTHH:mm:ss")
          const z = $scope.timezone != null ? moment(lc).tz($scope.timezone).format("Z") : ""
          const iso = moment.parseZone(tm + z)
          return iso
        }

        const isoTime2DBTime = (iso) => moment(iso).add(iso.utcOffset(), "minutes").utc()

        const getHeatmapData = function () {
          const data =
            $scope.heatmapType === $scope.HEATMAPLIST.PASS ? $scope.dataSet : $scope.dwellSet
          return data
        }

        const getCurrentHeatmapData = () =>
          __guard__(getHeatmapData(), (x2) => x2[$scope.timebarData.min])

        const getScale = function (hmData) {
          const scale =
            $scope.heatmapType === $scope.HEATMAPLIST.PASS
              ? hmData.customScale
                ? $scope.heatmapWeight.pass * 0.7
                : $scope.heatmapWeight.pass * 0.5
              : $scope.heatmapWeight.dwell
          return ($scope.selectedScale / 1000) * scale
        }

        const getRatio = () =>
          $scope.imageRatio != null ? $scope.imageRatio : $scope.snapshotRatio || kSnapshotRatio

        const makeAlignedHeatmapData = function (heatmaps, from, to, period, cameraAccessKey) {
          // from 00:00, to 59:59
          let first, newHeatmaps
          if (!(heatmaps != null ? heatmaps.length : undefined)) {
            $scope.totalStats.pass = mng.makeTotalStats(
              $scope.HEATMAPLIST.PASS,
              cameraAccessKey,
              []
            )
            return []
          }

          $scope.totalStats.pass = mng.makeTotalStats(
            $scope.HEATMAPLIST.PASS,
            cameraAccessKey,
            heatmaps
          )
          const sampling = getSampling($scope.period)

          if (isAccumulated(period)) {
            heatmaps = mng.accumulateHeatmap(heatmaps, from, to)
            newHeatmaps = [heatmaps]
          } else {
            const maxDataLength = to.diff(from, sampling) + 1
            newHeatmaps = __range__(0, maxDataLength, false).map((idx) => ({}))
            first = null
            // 날짜순으로 배열하기위해서
            heatmaps.forEach(function (hm, idx) {
              if ((hm != null ? hm.from : undefined) != null) {
                hm.from = moment.utc(hm.from)
                hm.to = moment.utc(hm.to)
                const targetIndex = hm.from.diff(from, sampling)
                newHeatmaps[targetIndex] = hm
                if (!first) {
                  return (first = hm)
                }
              }
            })
          }
          //TODO: hm.average = _average(hm) if not hm.average?
          //TODO: hm.stdev = _stdev(hm) if not hm.stdev?

          // 없는 경우 채워넣기
          for (let idx = 0; idx < newHeatmaps.length; idx++) {
            var hm = newHeatmaps[idx]
            if ((hm != null ? hm.from : undefined) != null) {
              continue
            }
            newHeatmaps[idx] = {
              // balnk data
              _companyId: first._companyId,
              _storeId: first._storeId,
              _cameraId: first._cameraId,
              cols: first.cols,
              rows: first.rows,
              sampling: first.sampling,
              from: moment(from).add(idx, sampling),
              to: moment(from).add(idx + 1, sampling),
              data: [],
            }
          }
          return newHeatmaps
        }

        const makeAlignedDwellmapData = function (maps, from, to, period, cameraAccessKey) {
          let first, newMaps
          if (!(maps != null ? maps.length : undefined)) {
            $scope.totalStats.dwell = mng.makeTotalStats(
              $scope.HEATMAPLIST.DWELL,
              cameraAccessKey,
              []
            )
            return []
          }

          $scope.totalStats.dwell = mng.makeTotalStats(
            $scope.HEATMAPLIST.DWELL,
            cameraAccessKey,
            maps
          )
          const sampling = getSampling($scope.period)

          //delete $scope.heatmapIndex
          if (isAccumulated(period)) {
            maps = mng.accumulateDwellmap(maps, from, to)
            newMaps = [maps]
          } else {
            const maxDataLength = to.diff(from, sampling) + 1
            newMaps = __range__(0, maxDataLength, false).map((idx) => ({}))
            first = null
            const dbFrom = moment.parseZone(from.format("YYYYMMDDHHmm"), "YYYYMMDDHHmm")
            // localtime이 있는건 newMaps에 바로 배열한다.
            maps.forEach(function (m, idx) {
              if (m != null ? m.localtime : undefined) {
                //INFO UCNT-4703 use dbtime because localtime causes date bug for monthly data
                m.from = moment.parseZone(m.localtime).utc(true)
                m.to = m.from.clone().add(1, sampling)
                const targetIndex = m.from.diff(dbFrom, sampling)
                if (targetIndex < maxDataLength) {
                  newMaps[targetIndex] = m
                }
                if (!first) {
                  return (first = m)
                }
              }
            })
          }

          for (let idx = 0; idx < newMaps.length; idx++) {
            var m = newMaps[idx]
            if ((m != null ? m.from : undefined) != null) {
              mng.addDwellHeatmap(m)
            } else {
              m = newMaps[idx] = {
                // balnk data
                // '_companyId': first._companyId
                // '_storeId': first._storeId
                // '_cameraId': first._cameraId
                width: first.width,
                height: first.height,
                sampling: first.sampling,
                from: moment(from).add(idx, sampling),
                to: moment(from).add(idx + 1, sampling),
                points: [],
                data: [],
              }
            }
            m.cols = m.width
            m.rows = m.height
          }

          return newMaps
        }

        const _getTextSize = function (width) {
          switch (false) {
            case !(width > 768):
              return 52
            case !(width > 320):
              return 32
            default:
              return 18
          }
        }

        let bizHoursCache = {
          promise: Promise.resolve(),
          startDate: moment(0),
          endDate: moment(0),
        }
        const getBizHours = async () => {
          const { startDate, endDate } = bizHoursCache
          if (!startDate.isSame($scope.startDatetime) || !endDate.isSame($scope.endDatetime)) {
            bizHoursCache = {
              promise: (
                await ApiSrv.getStoreBizHours({
                  id: $scope.camera._storeId,
                  from: time2StoreIsoTime($scope.startDatetime).format(),
                  to: time2StoreIsoTime($scope.endDatetime).format(),
                })
              )?.bizHours,
              startDate: $scope.startDatetime,
              endDate: $scope.endDatetime,
            }
          }

          return await bizHoursCache.promise
        }

        const checkBizHours = async (hmDate) => {
          let isBiz = true

          const bizHours = await getBizHours()

          if (bizHours && bizHours.length > 0) {
            isBiz = false
            for (let i = 0; i < bizHours.length; i++) {
              isBiz = hmDate.isSameOrAfter(bizHours[i][0]) && hmDate.isBefore(bizHours[i][1])
              if (isBiz) {
                break
              }
            }
          }

          return isBiz
        }

        const drawNodataHeatmap = async function () {
          if (!heatmapInstance) {
            return
          }
          //TODO: show the effective no heatmap data sign!!!
          gaugeCtx.clearRect(0, 0, gaugeCanvas.width, gaugeCanvas.height)
          if ($scope.backgroundMode) {
            const widthEach = 10
            const minValue = 0
            const maxValue = 10
            const heatmapPoints = []
            heatmapPoints.push({ x: 0, y: 0, value: maxValue, radius: widthEach })
            return heatmapInstance.setData({
              min: minValue,
              max: maxValue,
              data: heatmapPoints,
            })
          } else {
            heatmapInstance.setData({ data: [] })
            let text
            if ($scope.period == "hour" && (await checkBizHours($scope.startDatetime)) == false) {
              text = Locale.string("Non-Business Hours")
            } else {
              text = Locale.string("No Data")
            }

            const ctx = heatmapInstance._renderer.canvas.getContext("2d")
            const textSize = _getTextSize($scope.canvasInfo.width)
            const x = $scope.canvasInfo.width * 0.5
            const y = $scope.canvasInfo.height * 0.5

            ctx.fillStyle = "white"
            ctx.strokeStyle = "#737373"
            ctx.globalAlpha = 0.7
            ctx.textAlign = "center"
            ctx.font = `bold ${textSize}px roboto`
            ctx.fillText(text, x, y)
            ctx.strokeText(text, x, y)
          }
        }

        const _generateStoreHeatmapStats = (heatmapStats) => ({
          pass: {
            average:
              __guard__(
                heatmapStats != null ? heatmapStats.trafficmap : undefined,
                (x2) => x2.avg
              ) != null
                ? __guard__(
                    heatmapStats != null ? heatmapStats.trafficmap : undefined,
                    (x2) => x2.avg
                  )
                : 0,
            stdev:
              __guard__(
                heatmapStats != null ? heatmapStats.trafficmap : undefined,
                (x3) => x3.stdev
              ) != null
                ? __guard__(
                    heatmapStats != null ? heatmapStats.trafficmap : undefined,
                    (x3) => x3.stdev
                  )
                : 0,
          },

          dwell: {
            average:
              __guard__(heatmapStats != null ? heatmapStats.dwellmap : undefined, (x4) => x4.avg) !=
              null
                ? __guard__(
                    heatmapStats != null ? heatmapStats.dwellmap : undefined,
                    (x4) => x4.avg
                  )
                : 0,
            stdev:
              __guard__(
                heatmapStats != null ? heatmapStats.dwellmap : undefined,
                (x5) => x5.stdev
              ) != null
                ? __guard__(
                    heatmapStats != null ? heatmapStats.dwellmap : undefined,
                    (x5) => x5.stdev
                  )
                : 0,
          },
        })

        const _isValidStats = function (stats) {
          if (_.isEmpty(stats != null ? stats[$scope.heatmapType] : undefined)) {
            return false
          }

          let isValid = true
          for (var key in stats[$scope.heatmapType]) {
            var value = stats[$scope.heatmapType][key]
            if (!value) {
              isValid = false
            }
          }

          return isValid
        }

        const getStats = function () {
          if ($scope.linkedHeatmap) {
            const storeStats = _generateStoreHeatmapStats($scope.heatmapStats)
            const globalStats = heatmapCommon.getGlobalStats()

            if (getSampling($scope.period) === "day" && _isValidStats(storeStats)) {
              return storeStats
            } else if (_isValidStats(globalStats)) {
              return globalStats
            } else {
              return $scope.totalStats
            }
          } else {
            return $scope.totalStats
          }
        }

        const drawHeatmap = async function () {
          if (!heatmapInstance) {
            return
          }

          const hmData = getCurrentHeatmapData()
          if (
            !(
              __guard__(hmData != null ? hmData.data : undefined, (x2) => x2.length) &&
              hmData.cols * hmData.rows
            )
          ) {
            $scope.isNoData = true

            await drawNodataHeatmap()
            return
          }

          $scope.isNoData = false

          const { average, stdev } = getStats()[$scope.heatmapType]
          const scale = getScale(hmData)
          const result = mng.calcBypassHeatmap(
            hmData,
            average,
            stdev,
            scale,
            $scope.canvasInfo.width,
            $scope.canvasInfo.height,
            $scope.heatmapType
          )
          heatmapInstance.setData(result)
        }

        const setTimebarIndex = function (index) {
          $scope.heatmapIndex = index
          $scope.timebarData.min = index
          return ($scope.timebarData.max = index + 1)
        }

        const getTimebarIndex = () => $scope.heatmapIndex

        const setTimebar = function (data, setLast) {
          let index
          if (setLast == null) {
            setLast = false
          }
          data = data != null ? data : getHeatmapData()
          if (!data) {
            return
          }

          if (setLast) {
            index = getLastIndexofData(data, getSampling($scope.period))
          } else {
            index = getTimebarIndex()
          }
          setTimebarIndex(index)
          return ($scope.timebarData.options.ceil = data.length)
        }

        var getLastIndexofData = function (data, period) {
          let idx
          if (period === "hour") {
            idx = 12 + Math.floor((data.length - 12) / 24) * 24
          } else {
            // 'day','others'
            idx = data.length - 1
          }
          if (!__guard__(data != null ? data[idx] : undefined, (x2) => x2.data.length)) {
            const validLast = _.findLastIndex(data, (d) =>
              d.data != null ? d.data.length : undefined
            )
            idx = validLast
          }
          return Math.max(0, idx)
        }

        //draw : false, true, "force"
        const showSnapshot = async function (hmDate, draw, callback) {
          let tmFormat
          if (hmDate == null) {
            hmDate = getStoreIsoTime()
          }
          if (draw == null) {
            draw = true
          }
          if (callback == null) {
            callback = function () {}
          }
          if (typeof draw === "function") {
            callback = draw
            draw = true
          }

          const date = hmDate.clone()
          date.startOf(getSampling($scope.period))
          // info: add 10 min to get on the time image
          if ($scope.period === "hour") {
            tmFormat = "YYYY-MM-DDTHH:10"
          } else {
            // day, week, month
            tmFormat = "YYYY-MM-DDT12:10"
          }
          if (
            draw !== "force" &&
            date.unix() === ($scope.heatmapDate != null ? $scope.heatmapDate.unix() : undefined)
          ) {
            callback()
            return Promise.resolve()
          }
          $scope.heatmapDate = draw ? date : null
          await drawHeatmap()

          return Promise.all([Auth.fetchCurrentAccount(), HeaderSrv.fetchCurrentCompany()]).spread(
            function (account, company) {
              let backgroundImage
              if (company.isBlockedSnapshot(account)) {
                backgroundImage = kBlockedImageUrl
              } else {
                const parsed = new URL($scope.background, location.origin)
                const sep = parsed.search ? "&" : "?"
                backgroundImage = `${$scope.background}${sep}to=${encodeURI(date.format(tmFormat))}`
              }

              if (draw) {
                showLoading("load_snapshot")
                if ($scope.supportHMBackground()) {
                  setHeatmapImageSize($element.width())
                } else {
                  setBlackImageSize($element.width())
                }
              }

              const _showElement = function (imgurl) {
                if (draw) {
                  heatmapElement.css({
                    background: `url(${imgurl})`,
                    "background-size":
                      $scope.canvasInfo.width + "px " + $scope.canvasInfo.height + "px",
                  })
                  return hideLoading("load_snapshot")
                }
              }

              _showElement(backgroundImage)
              const image = new Image()
              image.onload = function () {
                $scope.snapshotRatio = this.height / this.width
                return callback()
              }
              image.onerror = function () {
                _showElement(kErrorImgeUrl)
                return callback()
              }
              return (image.src = backgroundImage)
            }
          )
        }

        const supportPassHeatmap = function () {
          if (isPanormaSensor()) {
            return true
          }
          return (
            $scope.camera.functions.use.heatmap && $scope.heatmapType === $scope.HEATMAPLIST.PASS
          )
        }

        const supportDwellHeatmap = function () {
          if (isPanormaSensor()) {
            return true
          }
          return (
            $scope.camera.functions.use.dwellmap && $scope.heatmapType === $scope.HEATMAPLIST.DWELL
          )
        }

        const load = function () {
          if (!$scope.ready) {
            return
          }
          $scope.activityStatusList = []

          // current startDatetime is browertime, it needs to convert store localtime
          const mStartTime = time2StoreIsoTime($scope.startDatetime)
          const mEndTime = time2StoreIsoTime($scope.endDatetime)
          const dbStartTime = isoTime2DBTime(mStartTime)
          const dbEndTime = isoTime2DBTime(mEndTime)

          $scope.selectedScale = kDefaultScalebar
          $scope.timebarData.min = 0
          $scope.timebarData.max = 0
          $scope.timebarData.options.ceil = 0
          const sampling = getSampling($scope.period)

          showLoading("load_data")

          return async.parallel(
            [
              function (next) {
                $scope.dataSet = []
                if (!supportPassHeatmap()) {
                  return next()
                }

                const options = {
                  id: $scope.camera._id,
                  from: dbStartTime.format("YYYYMMDDHHmm"),
                  to: moment(dbEndTime).add(1, sampling).startOf(sampling).format("YYYYMMDDHHmm"),
                  sampling: sampling || 3600,
                }
                return ApiSrv.getHeatmapOfCamera(options)
                  .then(function (data) {
                    $scope.dataSet = makeAlignedHeatmapData(
                      data,
                      dbStartTime,
                      dbEndTime,
                      $scope.period,
                      $scope.camera.accessKey
                    )
                    delete $scope.heatmapIndex
                    return next()
                  })
                  .catch(function (err) {
                    console.warn(err)
                    $scope.dataSet = []
                    return next()
                  })
              },
              function (next) {
                $scope.dwellSet = []
                if (!supportDwellHeatmap()) {
                  return next()
                }

                const options = {
                  id: $scope.camera._id,
                  from: mStartTime.format(),
                  to: moment(mEndTime).add(1, sampling).startOf(sampling).format(),
                  sampling: "1" + sampling[0].toUpperCase(),
                }
                return ApiSrv.getDwellmapOfCamera(options)
                  .then(function (data) {
                    $scope.dwellSet = makeAlignedDwellmapData(
                      data,
                      mStartTime,
                      mEndTime,
                      $scope.period,
                      $scope.camera.accessKey
                    )
                    delete $scope.heatmapIndex
                    return next()
                  })
                  .catch(function (err) {
                    console.warn(err)
                    $scope.dwellSet = []
                    return next()
                  })
              },
              (next) => showSnapshot(mEndTime, false, next),
              function (next) {
                $scope.heatmaptag = new Heatmaptag($scope.camera._id)
                return $scope.heatmaptag
                  .getTags({ all: true })
                  .then(function (res) {
                    savedTags = res
                    return next()
                  })
                  .catch(next)
              },
            ],
            function (err, _results) {
              if (err || !$element.width()) {
                if (err) {
                  console.warn("load data error", err)
                }
                hideLoading("load_data")
                return false
              }

              let showSnapshotPromise = null
              if (isAccumulated($scope.period)) {
                showSnapshotPromise = showSnapshot(moment(mEndTime))
              } else {
                const lastIdx = getLastIndexofData(getHeatmapData(), sampling)
                showSnapshotPromise = showSnapshot(moment(mStartTime).add(lastIdx, sampling))
              }

              return showSnapshotPromise.then(function () {
                try {
                  $scope.activityList = getActivityColorList()
                  if ($scope.supportHMBackground()) {
                    createSliderBars()
                  }
                  setTimebar(getHeatmapData(), true)

                  const _drawHeatmapbyIndex = function (index, force) {
                    if (force == null) {
                      force = false
                    }
                    if (!force && index === getTimebarIndex()) {
                      return
                    }
                    setTimebarIndex(index)
                    return drawHeatmap()
                  }

                  if ($scope.supportHeatmap()) {
                    if (!heatmapInstance) {
                      heatmapInstance = h337.create({
                        container: heatmapElement[0],
                        maxOpacity: 0.4,
                        blur: 0.4,
                        onExtremaChange(data) {
                          return updateGauge(data)
                        },
                      })
                    }

                    _drawHeatmapbyIndex($scope.timebarData.min, true)
                    drawHeatmaptag()
                    $scope.$emit("heatmap_stats", $scope.totalStats) // send totalStats
                  }

                  const _removeEventHandler = function (func) {
                    if (func) {
                      return func()
                    }
                  }
                  _removeEventHandler(scaleHandler)
                  scaleHandler = $scope.$on("scalebar_move", function (_event, val) {
                    if (val < 0) {
                      val = 0
                    }
                    if (val > 1000) {
                      val = 1000
                    }
                    if (val !== $scope.selectedScale) {
                      $scope.selectedScale = val
                    } else {
                      isShowScaleBubble(true)
                    }

                    return drawHeatmap()
                  })

                  _removeEventHandler(timebarHandler)
                  timebarHandler = $scope.$on("timebar_move", function (_event, min) {
                    _drawHeatmapbyIndex(min)
                    return drawHeatmaptag()
                  })

                  _removeEventHandler(heatmapImgHandler)
                  var heatmapImgHandler = $scope.$on("heatmap_img_refresh", (_event, val) =>
                    showSnapshot(val)
                  )

                  angular.element($window).on("resize", function () {
                    if (!$element.width()) {
                      return
                    }
                    return drawHeatmapContents()
                  })

                  $scope.firstFlag = true
                  hideLoading("load_data")
                  $scope.$apply()

                  //INFO The external directive does not know whether lastIdx is changed,
                  //     call heatmap_timebar_update to update the data index of outside heatmapPanel.
                  //     ex) Directionmap, Zone Traffic directive
                  return $scope.$emit("heatmap_timebar_update", getTimebarIndex())
                } catch (err) {
                  return console.error(err)
                }
              })
            }
          )
        }

        //# end of load()

        const _3DigitNumber = function (num, digit) {
          if (digit == null) {
            digit = 0
          }
          if (!_.isNumber(num)) {
            return
          }

          const key = (Math.log10(num) / 3) | 0
          if (key === 0) {
            return num.toFixed(digit)
          }

          const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"]
          const suffix = SI_SYMBOL[key]
          const scale = Math.pow(10, key * 3)
          const scaled = num / scale

          return scaled.toFixed(digit) + suffix
        }

        const _drawStrokedText = function (text, pos) {
          const x = gaugeBox.x + gaugeBox.width + 5
          const y = gaugeBox.y + gaugeBox.height - pos

          const fontSize = $scope.canvasInfo.width > MIN_WIDTH ? 10 : 7
          gaugeCtx.font = `bold ${fontSize}px Sans-serif`
          gaugeCtx.strokeStyle = "#000"
          gaugeCtx.lineWidth = 1
          gaugeCtx.strokeText(text, x, y)
          gaugeCtx.fillStyle = "#fff"
          return gaugeCtx.fillText(text, x, y)
        }

        const _drawRoundRect = function ({ x, y, width, height }, fillStyle, radius, fill, stroke) {
          if (radius == null) {
            radius = 5
          }
          if (fill == null) {
            fill = true
          }
          if (stroke == null) {
            stroke = true
          }
          gaugeCtx.strokeStyle = "#fff"
          gaugeCtx.lineWidth = 1
          gaugeCtx.fillStyle = fillStyle

          gaugeCtx.beginPath()
          gaugeCtx.moveTo(x + radius, y)
          gaugeCtx.lineTo(x + width - radius, y)
          gaugeCtx.quadraticCurveTo(x + width, y, x + width, y + radius)
          gaugeCtx.lineTo(x + width, y + height - radius)
          gaugeCtx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
          gaugeCtx.lineTo(x + radius, y + height)
          gaugeCtx.quadraticCurveTo(x, y + height, x, y + height - radius)
          gaugeCtx.lineTo(x, y + radius)
          gaugeCtx.quadraticCurveTo(x, y, x + radius, y)
          gaugeCtx.closePath()
          if (stroke) {
            gaugeCtx.stroke()
          }
          gaugeCtx.globalAlpha = 0.8
          if (fill) {
            gaugeCtx.fill()
          }
          return (gaugeCtx.globalAlpha = 1)
        }

        const _drawPointer = function (pos) {
          const lineWidth = $scope.canvasInfo.width > MIN_WIDTH ? 5 : 3
          gaugeCtx.strokeStyle = "#fff"
          gaugeCtx.lineWidth = 2

          gaugeCtx.beginPath()
          gaugeCtx.moveTo(gaugeBox.x + gaugeBox.width, gaugeBox.y + pos)
          gaugeCtx.lineTo(gaugeBox.x + gaugeBox.width - lineWidth, gaugeBox.y + pos)
          return gaugeCtx.stroke()
        }

        var updateGauge = function (data) {
          let value
          if ($scope.isNoData) {
            return
          }

          const ptList = [0, 0.25, 0.5, 0.75, 1]
          gaugeCtx.clearRect(0, 0, gaugeCanvas.width, gaugeCanvas.height)
          const gradient = gaugeCtx.createLinearGradient(
            gaugeBox.x,
            gaugeBox.y + gaugeBox.height,
            gaugeBox.x,
            gaugeBox.y
          )

          if (_.isUndefined(data.gradient != null ? data.gradient["0"] : undefined)) {
            gradient.addColorStop(0, "rgb(255,255,255)")
          }

          for (var key in data.gradient) {
            value = data.gradient[key]
            gradient.addColorStop(key, value)
          }
          ptList.sort((a, b) => a - b)
          if (!(_.isNil(data.min) || _.isNil(data.max) || data.max === 0)) {
            _drawRoundRect(gaugeBox, gradient, 3, true, false)

            gaugeCtx.textBaseLine = "bottom"
            let { min } = data
            let { max } = data
            if ($scope.heatmapType === $scope.HEATMAPLIST.DWELL) {
              min = data.min / 1000 // miliseconds -> seconds
              max = data.max / 1000
            }
            return ptList.forEach(function (pt, index) {
              const pos = gaugeBox.height * pt
              value = min + (max - min) * pt
              if (index !== 0 && index !== ptList.length - 1) {
                _drawPointer(pos)
              }
              gaugeCtx.textAlign = "start"
              return _drawStrokedText(_3DigitNumber(value), pos)
            })
          }
        }

        $scope.isShowFixed = () => $scope.camera.snapshot.kind == "fixed"

        $scope.isShowFrame = function (type) {
          if (typeof $scope.hideFrame === "boolean") {
            return !$scope.hideFrame
          } else {
            return !$scope.hideFrame[type] != null ? !$scope.hideFrame[type] : true
          }
        }

        $scope.changePanoramaHM = (type) => _setHeatmapType(type)

        $scope.changePanoramaBGI = function (type) {
          $scope.background = $scope.camera.backgroundImageUrl(type)

          const idx = $scope.timebarData.min
          const tm = moment($scope.startDatetime).add(idx, getSampling($scope.period))
          return showSnapshot(tm, "force")
        }

        const createCustomBtn = function () {
          if ($element.find(".custom-btn .btn-group").length) {
            $element.find(".custom-btn .btn-group").remove()
          }

          return $element
            .find(".custom-btn")
            .append($compile($scope.customBtnTemplate)($scope.$parent))
        }

        const createPanormaOption = function () {
          if ($element.find(".panorama .btn-group").length) {
            $element.find(".panorama .btn-group").remove()
          }

          return $element
            .find(".panorama")
            .append(
              $compile(
                [
                  '<div class="btn-group">',
                  '<label class="btn btn-default btn-xs" uib-btn-radio="PANORAMABGI.PANORAMA" data-ng-model="panoramaBGI" data-ng-click="changePanoramaBGI(PANORAMABGI.PANORAMA)"  data-ng-attr-title="{{tooltip.panorama}}">',
                  '<i class="mdi mdi-image"></i>',
                  "</label>",
                  '<label class="btn btn-default btn-xs" uib-btn-radio="PANORAMABGI.FLOORPLAN" data-ng-model="panoramaBGI" data-ng-click="changePanoramaBGI(PANORAMABGI.FLOORPLAN)"  data-ng-attr-title="{{tooltip.floorplan}}">',
                  '<i class="mdi mdi-map"></i>',
                  "</label>",
                  "</div>",
                ].join("")
              )($scope)
            )
        }

        const createBackgroundDataOption = function () {
          if ($element.find(".switch-background-data .btn-group").length) {
            $element.find(".switch-background-data .btn-group").remove()
          }

          return $element
            .find(".switch-background-data")
            .append(
              $compile(
                [
                  '<div class="btn-group">',
                  '<label class="btn btn-default btn-xs" uib-btn-radio="HEATMAPLIST.PASS" data-ng-model="panoramaHM" data-ng-click="changePanoramaHM(HEATMAPLIST.PASS)" data-ng-attr-title="{{tooltip.pass}}">',
                  '<i class="mdi mdi-run-fast"></i>',
                  "</label>",
                  '<label class="btn btn-default btn-xs" uib-btn-radio="HEATMAPLIST.DWELL" data-ng-model="panoramaHM" data-ng-click="changePanoramaHM(HEATMAPLIST.DWELL)"  data-ng-attr-title="{{tooltip.dwell}}">',
                  '<i class="mdi mdi-clock-fast"></i>',
                  "</label>",
                  "</div>",
                ].join("")
              )($scope)
            )
        }

        var createSliderBars = function () {
          if (isPanormaSensor()) {
            createPanormaOption()
          }

          if (isPanormaSensor() && supportDirectionmap()) {
            createBackgroundDataOption()
          }

          if ($scope.customBtnTemplate) {
            createCustomBtn()
          }

          if ($element.find(".scalebar i").length) {
            $element.find(".scalebar i").remove()
          }
          if ($element.find(".scalebar rzslider").length) {
            $element.find(".scalebar rzslider").remove()
          }
          if ($element.find(".timebar rzslider").length) {
            $element.find(".timebar rzslider").remove()
          }
          if ($element.find(".export .btn-group").length) {
            $element.find(".export .btn-group").remove()
          }
          if ($element.find(".linked-heatmap .btn-group").length) {
            $element.find(".linked-heatmap .btn-group").remove()
          }
          if ($element.find(".heatmap-type .btn-group").length) {
            $element.find(".heatmap-type .btn-group").remove()
          }

          if ($scope.isShowFrame("scaleBar")) {
            $element
              .find(".scalebar")
              .append(
                $compile(
                  [
                    "<rzslider",
                    ' rz-slider-model="selectedScale"',
                    ' rz-slider-options="scalebarOption">',
                    "</rzslider>",
                  ].join("")
                )($scope)
              )
          }

          if ($scope.period !== "accumulated" && $scope.isShowFrame("timeBar")) {
            $element
              .find(".timebar")
              .append(
                $compile(
                  [
                    "<rzslider",
                    ' rz-slider-model="timebarData.min"',
                    ' rz-slider-high="timebarData.max"',
                    ' rz-slider-options="timebarData.options">',
                    "</rzslider>",
                  ].join("")
                )($scope)
              )
          }

          if ($scope.isShowFrame("exportBtn")) {
            $element
              .find(".export")
              .append(
                $compile(
                  [
                    '<div class="btn-group" uib-dropdown>',
                    '<button id="single-button" class="btn btn-line-default btn-xs btn-export" data-ng-attr-title="{{tooltip.download}}" uib-dropdown-toggle>',
                    '<i class="mdi mdi-download"></i>',
                    "</button>",
                    '<ul class="pull-right" role="menu" aria-labelledby="single-button" uib-dropdown-menu>',
                    '<li role="menuitem">',
                    '<a href="#" data-ng-show="isShowExportCSV()" data-ng-click="heatmapExport(\'csv\')">CSV</a>',
                    '<a href="#" data-ng-click="saveAsImage()"><span data-i18n="Image"></span></a>',
                    "</li>",
                    "</ul>",
                    "</div>",
                  ].join("")
                )($scope)
              )
          }

          if ($scope.isShowFrame("linkBtn")) {
            return $element.find(".linked-heatmap").append(
              $compile(
                [
                  '<div class="btn-group">',
                  '<label class="btn btn-default btn-xs" data-ng-click="toggleLinkedHeatmap()" data-ng-attr-title="{{tooltip.link}}">',
                  `<i class="mdi" data-ng-class="{ true : 'mdi-link-variant color-primary', \
false : 'mdi-link-variant-off color-gray-light'}[linkedHeatmap]"></i>`,
                  "</label>",
                  "</div>",
                ].join("")
              )($scope)
            )
          }
        }

        $scope.isShowExportCSV = () =>
          $scope.heatmapType === $scope.HEATMAPLIST.PASS && _.isEmpty($scope.pageName)

        $scope.toggleLinkedHeatmap = function () {
          $scope.linkedHeatmap = !$scope.linkedHeatmap
          return drawHeatmap()
        }

        const _toggleHeatmpTypeValue = function () {
          if ($scope.heatmapType === $scope.HEATMAPLIST.DWELL) {
            return $scope.HEATMAPLIST.PASS
          } else {
            return $scope.HEATMAPLIST.DWELL
          }
        }

        var _setHeatmapType = function (value) {
          $scope.heatmapType = value
          setTimebar()
          return drawHeatmap()
        }

        $scope.toggleHeatmapType = function (value) {
          if ($scope.toggleHeatmapTypeDisabled) {
            return
          }
          value = value != null ? value : _toggleHeatmpTypeValue()
          _setHeatmapType(value)
          if ($scope.linkedHeatmap) {
            return $scope.$emit("heatmap_type_update", value)
          }
        }

        $scope.heatmapExport = function (type) {
          if (type !== "csv") {
            return false
          }

          let csvData = "Time,Cols,Rows,Data\n"
          _.forEach(
            $scope.dataSet,
            (row) =>
              (csvData += [
                `"${moment.utc(row.from).format("YYYY-MM-DD HH:mm")} ~ `,
                `${moment.utc(row.to).format("YYYY-MM-DD HH:mm")}",`,
                `"${row.cols}",`,
                `"${row.rows}",`,
                `"${row.data}"`,
                "\n",
              ].join(""))
          )

          const period =
            $scope.endDatetime
              .clone()
              .add(1, "d")
              .startOf("day")
              .diff($scope.startDatetime, getSampling($scope.period)) +
            getSampling($scope.period)[0].toUpperCase()
          const contentsName =
            $scope.pageName === "flowmap"
              ? "flowmap"
              : `${getHeatmapContentStr($scope.HEATMAPLIST.PASS)}heatmap`
          const filename = makeExportFileName(
            $scope.camera.storeName,
            $scope.camera.name,
            period,
            $scope.startDatetime,
            `${contentsName}`,
            "csv"
          )
          return uiGridExporterService.downloadFile(filename, csvData, true)
        }

        var makeExportFileName = function (
          store,
          sensor,
          period,
          from,
          content,
          extension,
          panoramaName
        ) {
          let filename = `${store}_${sensor}_${period}_${moment(from).format(
            "YYYYMMDDHHmm"
          )}_${content}`
          if (panoramaName) {
            filename += panoramaName
          }
          filename += `.${extension}`
          return filename
        }

        var getHeatmapContentStr = function (type) {
          if (type === $scope.HEATMAPLIST.DWELL) {
            return "dwell"
          } else {
            return "traffic"
          }
        }

        $scope.saveAsImage = function () {
          let directionCanvas, from, panoramaName, period
          const tagCanvas = $element.find("#heatmaptagCanvas")[0]
          let infoCanvas = $element.find("#drawMDICanvas")[0]
          let contentsName = `${getHeatmapContentStr($scope.heatmapType)}heatmap`

          if ($scope.pageName === "flowmap") {
            directionCanvas = angular.element("#directionmapCanvas")[0]
            infoCanvas = angular.element(".directionmap-info-container").find("#drawMDICanvas")[0]
            contentsName = "flowmap"
            if (isPanormaSensor()) {
              panoramaName = `_${getHeatmapContentStr($scope.heatmapType)}_${$scope.panoramaBGI}`
            }
          }

          const sampling = getSampling($scope.period)
          if (isAccumulated($scope.period)) {
            period =
              $scope.endDatetime
                .clone()
                .add(1, "d")
                .startOf("day")
                .diff($scope.startDatetime, sampling) + sampling[0].toUpperCase()
            from = moment($scope.startDatetime)
          } else {
            period = "1" + sampling[0].toUpperCase()
            from = moment($scope.startDatetime).add($scope.heatmapIndex, sampling)
          }

          const filename = makeExportFileName(
            $scope.camera.storeName,
            $scope.camera.name,
            period,
            from,
            `${contentsName}`,
            "jpg",
            panoramaName
          )

          const backgroundImage = new Image()
          backgroundImage.onload = function () {
            context.canvas.width = backgroundImage.width
            context.canvas.height = backgroundImage.height

            context.drawImage(backgroundImage, 0, 0, backgroundImage.width, backgroundImage.height)
            context.drawImage(tagCanvas, 0, 0, backgroundImage.width, backgroundImage.height)
            context.drawImage(infoCanvas, 0, 0, backgroundImage.width, backgroundImage.height)
            context.drawImage(gaugeCanvas, 0, 0, backgroundImage.width, backgroundImage.height)
            context.drawImage(
              heatmapInstance._renderer.canvas,
              0,
              0,
              backgroundImage.width,
              backgroundImage.height
            )
            if ($scope.pageName === "flowmap") {
              context.drawImage(
                directionCanvas,
                0,
                0,
                backgroundImage.width,
                backgroundImage.height
              )
            }

            if (window.navigator != null ? window.navigator.msSaveOrOpenBlob : undefined) {
              const blob = context.canvas.msToBlob()
              return window.navigator.msSaveOrOpenBlob(blob, filename)
            } else {
              link.href = context.canvas
                .toDataURL("image/jpeg")
                .replace("image/jpeg", "image/octet-stream")
              link.download = filename
              return link.click()
            }
          }

          const downloadUrl = heatmapElement
            .css("background-image")
            .replace(/^url\(['"](.+)['"]\)/, "$1")
          backgroundImage.width = heatmapElement.width()
          backgroundImage.height = heatmapElement.height()
          return (backgroundImage.src = downloadUrl)
        }

        // moveTimeSlider is called when clicking sliderbar
        // onChange is called when dragging sliderbar
        $scope.moveTimeSlider = function ($event) {
          if ($event.target.className !== "rz-bar") {
            return
          }
          const timebarWidth = $element.find(".timebar").width()
          const width = $scope.timebarData.options.ceil - $scope.timebarData.options.floor
          const idx = Math.floor(($event.offsetX / timebarWidth) * width)
          moveTimebar(idx)
          if ($scope.linkedHeatmap) {
            return $scope.$emit("heatmap_timebar_update", idx)
          }
        }

        const loadingQueue = []

        var showLoading = function (key) {
          if (key) {
            loadingQueue.push(key)
          }
          angular.element(`#splash${$scope.camera._id}${$scope.heatmapType}`).show()
          return usSpinnerService.spin(`spinner${$scope.camera._id}${$scope.heatmapType}`)
        }

        var hideLoading = function (key) {
          if (key) {
            _.remove(loadingQueue, (raw) => raw === key)
            if (loadingQueue.length) {
              return
            }
          }

          angular.element(`#splash${$scope.camera._id}${$scope.heatmapType}`).hide()
          return usSpinnerService.stop(`spinner${$scope.camera._id}${$scope.heatmapType}`)
        }

        var setBlackImageSize = function (elementWidth) {
          const originWidth = Math.floor(elementWidth)
          const width = $scope.isShowFrame("scaleBar") ? originWidth - 5 : originWidth
          const height = Math.floor(width * getRatio())

          $element.find(".not-support-feature").height(height)
          return $element.find(".not-support-feature").width(originWidth)
        }

        var setHeatmapImageSize = function (elementWidth) {
          let width = Math.floor(elementWidth)
          if ($scope.isShowFrame("scaleBar")) {
            width -= 5
          }
          const height = Math.floor(width * getRatio())
          if (width === $scope.canvasInfo.width && height === $scope.canvasInfo.height) {
            return
          }

          const timebarWidth = width
          $scope.canvasInfo.width = width
          $scope.canvasInfo.height = height
          $element.find(".scalebar").height(height)
          $element.find(".timebar").width(timebarWidth)
          $element.find(".heatmap-canvas").height(height)
          $element.find(".heatmap-canvas").width(width)
          gridAreaCanvas.height = height
          gridAreaCanvas.width = width
          gridAreaCtx = gridAreaCanvas.getContext("2d")
          gaugeCanvas.width = width
          gaugeCanvas.height = height
          gaugeCtx = gaugeCanvas.getContext("2d")
          gaugeBox = {
            x: parseInt(width * 0.02),
            y: parseInt(height * 0.83),
            width: parseInt(width * 0.02),
            height: parseInt((height * 0.15) / 4) * 4,
          }

          heatmapElement.css({
            width: $scope.canvasInfo.width,
            height: $scope.canvasInfo.height,
            "background-size": $scope.canvasInfo.width + "px " + $scope.canvasInfo.height + "px",
          })

          if (heatmapInstance) {
            return heatmapInstance._renderer.setDimensions(
              $scope.canvasInfo.width,
              $scope.canvasInfo.height
            )
          }
        }

        const _printNumber = function (num, digits) {
          if (digits == null) {
            digits = 1
          }
          if (num === parseInt(num)) {
            return num
          } else {
            return num.toFixed(digits)
          }
        }

        const _getDwellPointInfo = function (x, y, hmData, gridAreaSize) {
          let index, p, pos
          const tgrange = hmData.timegroup
          if (gridAreaSize.width > 1 || gridAreaSize.height > 1) {
            p = null
            let beginX = x - (gridAreaSize.width - 1)
            let beginY = y - (gridAreaSize.height - 1)
            if (beginX < 0) {
              beginX = 0
            }
            if (beginY < 0) {
              beginY = 0
            }

            pos = `[${beginX},${beginY}] - [${x},${y}]`
            for (
              let i = beginX, end = x, asc = beginX <= end;
              asc ? i <= end : i >= end;
              asc ? i++ : i--
            ) {
              for (
                var j = beginY, end1 = y, asc1 = beginY <= end1;
                asc1 ? j <= end1 : j >= end1;
                asc1 ? j++ : j--
              ) {
                index = i + j * hmData.cols
                var tmp = hmData.datapoint[index] != null ? hmData.datapoint[index] : null
                if (tmp) {
                  if (!p) {
                    p = _.cloneDeep(tmp)
                    p.total = p.average * p.count
                  } else {
                    p.count += tmp.count
                    p.timegroup = _.zipWith(p.timegroup, tmp.timegroup, (a, b) => a + b)
                    p.total += tmp.average * tmp.count
                  }
                }
              }
            }
            if (p) {
              p.average = p.total / p.count
            }
          } else {
            index = x + y * hmData.cols
            p = hmData.datapoint[index]
            pos = `[${x},${y}]`
          }

          if (!p) {
            return ""
          }
          let info = [
            `<td colspan='2'>${pos}</td>`,
            "<td colspan='2'>Area Stat</td>",
            `<td>People</td><td>${_printNumber(p.count)}</td>`,
            `<td>Avg</td><td>${_printNumber(p.average / 1000)}s</td>`,
          ]
          info = info.concat(
            p.timegroup.map(function (tm, i) {
              if (i === 0) {
                return `<td>~ ${tgrange[i][1]}s</td><td>${_printNumber(p.timegroup[i])}</td>`
              } else if (i === p.timegroup.length - 1) {
                return `<td>${tgrange[i][0]}s ~</td><td>${_printNumber(p.timegroup[i])}</td>`
              } else {
                return `<td>${tgrange[i][0]}~${tgrange[i][1]}s</td><td>${_printNumber(tm)}</td>`
              }
            })
          )
          info = info.map((l) => `<tr>${l}</tr>`)
          return `<table>${info.join("")}</table>`
        }

        const _getPassPointInfo = function (x, y, hmData, gridAreaSize) {
          let index, pos, v
          let cnt = 0
          if (gridAreaSize.width > 1 || gridAreaSize.height > 1) {
            v = 0
            let beginX = x - (gridAreaSize.width - 1)
            let beginY = y - (gridAreaSize.height - 1)
            if (beginX < 0) {
              beginX = 0
            }
            if (beginY < 0) {
              beginY = 0
            }

            pos = `[${beginX},${beginY}] - [${x},${y}]`
            for (
              let i = beginX, end = x, asc = beginX <= end;
              asc ? i <= end : i >= end;
              asc ? i++ : i--
            ) {
              for (
                var j = beginY, end1 = y, asc1 = beginY <= end1;
                asc1 ? j <= end1 : j >= end1;
                asc1 ? j++ : j--
              ) {
                index = i + j * hmData.cols
                if (hmData.data[index] != null) {
                  cnt++
                  v += hmData.data[index]
                }
              }
            }
            if (!cnt) {
              cnt = 1
            }
          } else {
            cnt = 1
            pos = `[${x},${y}]`
            index = x + y * hmData.cols
            v = hmData.data[index]
          }
          const avg = v / cnt
          if (!v) {
            return ""
          }
          return [
            "<table>",
            `<tr><td colspan='2'>${pos}</td></tr>`,
            "<tr><td colspan='2'>Grid Stat</td></tr>",
            `<tr><td>Sum</td><td>${_printNumber(v)}</td></tr>`,
            `<tr><td>Avg</td><td>${_printNumber(avg)}</td></tr>`,
            "</table>",
          ].join("")
        }

        const updateInfoBox = function (infobox, hmData) {
          const gridAreaSize = heatmapCommon.getGridSize()
          const cols =
            (hmData != null ? hmData.cols : undefined) != null
              ? hmData != null
                ? hmData.cols
                : undefined
              : 80
          const rows =
            (hmData != null ? hmData.rows : undefined) != null
              ? hmData != null
                ? hmData.rows
                : undefined
              : 45

          let ix = parseInt((cols * infobox.x) / $scope.canvasInfo.width)
          let iy = parseInt((rows * infobox.y) / $scope.canvasInfo.height)
          if (ix !== infobox.ix || iy !== infobox.iy) {
            let body = null
            infobox.ix = ix
            infobox.iy = iy
            if (__guard__(hmData != null ? hmData.data : undefined, (x2) => x2.length)) {
              if ($scope.heatmapType === $scope.HEATMAPLIST.DWELL) {
                body = _getDwellPointInfo(ix, iy, hmData, gridAreaSize)
              } else {
                if (!hmData.customScale) {
                  ;[ix, iy] = Array.from(mng.getPassHeatmapPosition(ix, iy, rows - 1))
                }
                body = _getPassPointInfo(ix, iy, hmData, gridAreaSize)
              }
            }

            if (body) {
              const kTooltipOffset = 10
              $(".infobox-body").html(body)
              $(".infobox-container").css({
                display: "inline-block",
                left: `${infobox.x + kTooltipOffset}px`,
                top: `${infobox.y}px`,
              })
            } else {
              $(".infobox-container").css("display", "none")
            }

            const width = $scope.canvasInfo.width / cols
            const height = $scope.canvasInfo.height / rows
            return drawGridArea(infobox.ix, infobox.iy, width, height, gridAreaSize)
          }
        }

        const getMouseOffset = function (event) {
          if (event.offsetX != null) {
            $scope.infobox.x = event.offsetX
            return ($scope.infobox.y = event.offsetY)
          } else if (event.layerX != null) {
            $scope.infobox.x = event.layerX
            return ($scope.infobox.y = event.layerY)
          } else {
            $scope.infobox.x = event.pageX
            return ($scope.infobox.y = event.pageY)
          }
        }

        const roundRect = function (ctx, x, y, width, height, radius, fill, stroke) {
          if (typeof stroke === "undefined") {
            stroke = true
          }
          if (typeof radius === "undefined") {
            radius = 5
          }
          if (typeof radius === "number") {
            radius = {
              tl: radius,
              tr: radius,
              br: radius,
              bl: radius,
            }
          } else {
            const defaultRadius = {
              tl: 0,
              tr: 0,
              br: 0,
              bl: 0,
            }
            for (var side in defaultRadius) {
              radius[side] = radius[side] || defaultRadius[side]
            }
          }
          ctx.beginPath()
          ctx.moveTo(x + radius.tl, y)
          ctx.lineTo(x + width - radius.tr, y)
          ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr)
          ctx.lineTo(x + width, y + height - radius.br)
          ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height)
          ctx.lineTo(x + radius.bl, y + height)
          ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl)
          ctx.lineTo(x, y + radius.tl)
          ctx.quadraticCurveTo(x, y, x + radius.tl, y)
          ctx.closePath()
          if (fill) {
            ctx.fill()
          }
          if (stroke) {
            ctx.stroke()
          }
        }

        var drawGridArea = function (x, y, width, height, gridAreaSize) {
          if (gridAreaCtx === null) {
            return
          }
          gridAreaCtx.clearRect(0, 0, $scope.canvasInfo.width, $scope.canvasInfo.height)

          const xCoord = (x - gridAreaSize.width + 1) * width
          const yCoord = (y - gridAreaSize.height + 1) * height

          gridAreaCtx.setLineDash([5, 5])
          gridAreaCtx.strokeStyle = "white"
          gridAreaCtx.lineWidth = 2
          return roundRect(
            gridAreaCtx,
            xCoord,
            yCoord,
            width * gridAreaSize.width,
            height * gridAreaSize.height
          )
        }

        const getGridSizeType = function (event) {
          switch (false) {
            case event.shiftKey !== true:
              return "vertical"
            case event.ctrlKey !== true:
              return "horizontal"
            default:
              return "both"
          }
        }

        $scope.upGridArea = function (event) {
          if (!$scope.mouseAction || $scope.isNoData) {
            return
          }

          heatmapCommon.changeGridSize("up", getGridSizeType(event))
          $scope.infobox.ix = undefined
          $scope.infobox.iy = undefined
          handleMouseMove(event)
          return true
        }

        $scope.downGridArea = function (event) {
          if (!$scope.mouseAction || $scope.isNoData) {
            return
          }

          heatmapCommon.changeGridSize("down", getGridSizeType(event))
          $scope.infobox.ix = undefined
          $scope.infobox.iy = undefined
          handleMouseMove(event)
          return true
        }

        var handleMouseMove = function (event) {
          const hmData = getCurrentHeatmapData()
          getMouseOffset(event)
          return updateInfoBox($scope.infobox, hmData, event)
        }

        $scope.showInfoBox = function (show) {
          if (!$scope.mouseAction) {
            return
          }
          $scope.infobox.show = show
          if (show) {
            document.onmousemove = handleMouseMove
          } else {
            document.onmousemove = null
          }
        }

        $scope.$watch(
          "param",
          function (param, _old) {
            if (!param) {
              return
            }

            $scope.startDatetime = param.start
            $scope.endDatetime = param.end
            $scope.period = param.sampling
            return load()
          },
          true
        )

        $scope.$watch("imageRatio", function (_val) {
          if (!$scope.firstFlag) {
            return
          }

          return drawHeatmapContents()
        })

        $scope.$watch("heatmapType", function (val) {
          switch (val) {
            case $scope.HEATMAPLIST.PASS:
              return ($scope.tagMode = "hide")
            case $scope.HEATMAPLIST.DWELL:
              if ($scope.camera.functions.use.zonetraffic) {
                return ($scope.tagMode = "show")
              } else {
                return ($scope.tagMode = "hide")
              }
          }
        })

        var moveTimebar = function (idx) {
          if (idx < 0) {
            idx = 0
          }
          if (idx > $scope.timebarData.options.ceil - 1) {
            idx = $scope.timebarData.options.ceil - 1
          }
          $scope.timebarData.min = idx
          $scope.timebarData.max = idx + 1
          $scope.$emit("timebar_move", idx)
          return $scope.$emit(
            "heatmap_img_refresh",
            moment($scope.startDatetime).add(idx, getSampling($scope.period))
          )
        }

        $scope.$on("move_timebar_global", function (_event, idx) {
          if (idx === $scope.timebarData.min || !$scope.linkedHeatmap) {
            return
          }
          return moveTimebar(idx)
        })

        $scope.$on("move_timebar_dx_global", function (_event, dx) {
          const idx = $scope.timebarData.min + dx
          if (idx === $scope.timebarData.min || !$scope.linkedHeatmap) {
            return
          }
          return moveTimebar(idx)
        })

        $scope.$on("move_scalebar_global", function (_event, val) {
          if (val === $scope.selectedScale || !$scope.linkedHeatmap) {
            return
          }
          return $scope.$emit("scalebar_move", val)
        })

        $scope.$on("move_scalebar_dx_global", function (_event, dx) {
          const val = $scope.selectedScale + dx
          if (val === $scope.selectedScale || !$scope.linkedHeatmap) {
            return
          }
          return $scope.$emit("scalebar_move", val)
        })

        $scope.$on("heatmap_stats_global", (_event, _gstats) => drawHeatmap())

        $scope.$on("heatmap_type_global", function (_event, val) {
          if ($scope.toggleHeatmapTypeDisabled || !$scope.linkedHeatmap) {
            return
          }
          return _setHeatmapType(val)
        })

        var drawHeatmaptag = function () {
          $scope.heatmaptag.setBackgroundSize($scope.canvasInfo.width, $scope.canvasInfo.height)

          const idx = $scope.timebarData.min
          const tm = moment($scope.startDatetime).add(idx, getSampling($scope.period)).utc(true)

          const hmtag = savedTags.find((tag) => tm.isBetween(tag.from, tag.to, null, "[)"))
          return ($scope.drawTags = hmtag
            ? $scope.heatmaptag.transformDrawTags(hmtag.heatmaptags)
            : [])
        }

        var drawHeatmapContents = function () {
          if ($scope.supportHMBackground()) {
            setHeatmapImageSize($element.width())
            drawHeatmap()
            return drawHeatmaptag()
          } else {
            return setBlackImageSize($element.width())
          }
        }

        var getSampling = function (period) {
          if (isAccumulated(period)) {
            return "day"
          } else {
            return period
          }
        }

        var isAccumulated = (period) => period === "accumulated"

        $scope.supportHeatmap = () =>
          $scope.camera.functions.use.heatmap || $scope.camera.functions.use.dwellmap

        $scope.supportHMBackground = function (type) {
          if (type == null) {
            type = $scope.heatmapType
          }
          if ($scope.camera.functions.use.flowmap) {
            return true
          }

          switch (type) {
            case $scope.HEATMAPLIST.PASS:
              return $scope.camera.functions.use.heatmap
            case $scope.HEATMAPLIST.DWELL:
              return $scope.camera.functions.use.dwellmap
            default:
              return false
          }
        }

        $scope.getHeatmapWeight = () => $scope.heatmapWeight[$scope.heatmapType].toFixed(1)

        $scope.getScalePos = function (val) {
          if (!val) {
            val = $scope.selectedScale
          }
          return ((val / 1000) * 100).toFixed(0)
        }

        return (getActivityColorList = function () {
          const hmData = getHeatmapData()
          if (_.isEmpty(hmData)) {
            return []
          }

          const avgs = hmData.map((d) => (d.average != null ? d.average : 0))
          const avg = ss.mean(avgs)
          const stdev = ss.standardDeviation(avgs)
          const max = avg + stdev * 3
          const colorScale = chroma
            .scale(["white", "blue", "green", "yellow", "red"])
            .domain([0, max])
          const clist = hmData.map(function (d) {
            if ((d != null ? d.average : undefined) != null) {
              return colorScale(d.average).hex()
            } else {
              return "#ffffff"
            }
          }) // white
          return clist
        })
      },
    })
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
