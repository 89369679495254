import React from "react"
import { useMemo } from "react"
import { useField } from "informed"
import ReactSelect from "react-select"
import propTypes from "prop-types"

export const SelectField = (props) => {
  const { fieldState, fieldApi, render, ref, userProps } = useField({ ...props })

  const { value } = fieldState
  const { setValue, setTouched } = fieldApi
  // eslint-disable-next-line no-unused-vars
  const { className, actionFn, loading, onChange, onBlur, options, styles, ...rest } = userProps

  const handleChange = (option) => {
    setValue(option.value)
    if (onChange) onChange(option)
  }

  const handleBlur = (evt) => {
    setTouched(true)
    if (onBlur) return onBlur(evt)
  }

  const optionValue = useMemo(() => options.find((o) => o.value == value), [value, options])

  return render(
    <>
      <ReactSelect
        loading={loading}
        onBlur={handleBlur}
        onChange={handleChange}
        styles={styles}
        value={optionValue}
        options={options}
      />
      <input className={className} ref={ref} value={value || ""} type="hidden" />
    </>
  )
}

SelectField.propTypes = {
  field: propTypes.string,
  options: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string.isRequired,
      value: propTypes.isRequired,
    })
  ),
  validate: propTypes.func,
  styles: propTypes.object,
  onChange: propTypes.func,
  loading: propTypes.bool,
}

export default SelectField
