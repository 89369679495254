/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS202: Simplify dynamic range loops
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"
import moment from "moment-timezone"

import Promise from "bluebird"
import { kStandardLabels, getStandardLabel } from "../../../components/counter-label"

angular
  .module("uCountitUiApp")
  .controller(
    "StoreComparisonCtrl",
    function ($scope, usSpinnerService, Locale, DateTimeSrv, HeaderSrv, RestApi) {
      const SAMPLING = {
        HOUR: "hour",
        DAY: "day",
        MONTH: "month",
      }
      $scope.SPECIALLABEL = kStandardLabels

      $scope.chartOpenList = [{ key: "label", name: Locale.string("All Labels") }]

      $scope.pageOptions = {
        type: $scope.chartOpenList[0],
        with: null,
        sampling: SAMPLING.DAY,
        content: "footfall",
      }

      const getDefaultData = function (start, end, sampling) {
        const data = []

        // if (sampling is 'week')
        //   samp = 'isoWeek'
        // else
        //   samp = sampling
        const samp = sampling

        const samplingStart = moment(start).startOf(samp)
        const samplingEnd = moment(end).startOf(samp)

        let dataLength = samplingEnd.diff(samplingStart, sampling)
        if (sampling === "week" || sampling === "month") {
          dataLength++
        }

        for (
          let i = 0, end1 = dataLength, asc = 0 <= end1;
          asc ? i < end1 : i > end1;
          asc ? i++ : i--
        ) {
          if (i === 0 && samplingStart < start) {
            data.push([start, null])
          } else {
            var newDate = moment(start).startOf(samp).add(i, sampling)
            if (newDate < end) {
              data.push([newDate, null])
            }
          }
        }

        return {
          data: data,
          totalCount: 0,
          startDate: start,
          endDate: end,
          sampling: sampling,
        }
      }

      const loadingQueue = []
      const showLoading = function (key) {
        if (key) {
          loadingQueue.push(key)
        }
        angular.element(".splash").show()
        usSpinnerService.spin("spinner")
        return angular.element(".spinner").css("position", "fixed")
      }

      const hideLoading = function (key) {
        if (key) {
          _.remove(loadingQueue, (raw) => raw === key)
          if (loadingQueue.length) {
            return
          }
        }

        angular.element(".splash").hide()
        usSpinnerService.stop("spinner")
      }

      const samplingKey = function (val) {
        switch (val) {
          case "hour":
            return "1h"
          case "day":
            return "1d"
          case "week":
            return "1w"
          case "month":
            return "1M"
          default:
            return "1d"
        }
      }

      $scope.showLoading = showLoading
      $scope.hideLoading = hideLoading
      $scope.samplingKey = samplingKey
      $scope.getDefaultData = getDefaultData

      $scope.reload = () => $scope.$broadcast("reload_chart")

      const isRangeSearchLoad = new Promise(
        (resolve, _reject) => ($scope.handleRangeSearchLoad = () => resolve())
      )

      const init = function () {
        $scope.stores = []
        return HeaderSrv.fetchCurrentCompany()
          .then((company) => {
            $scope.currentCompany = company
            return HeaderSrv.fetchCurrentStore().then(function (storeGroup) {
              $scope.currentStoreGroup = storeGroup

              return Promise.each(
                RestApi.Store.index(true, { params: { companyId: company._id } }),
                (store) => {
                  if (
                    storeGroup.orgType === "company" ||
                    (storeGroup.orgType === "storegroup" &&
                      storeGroup.storeList.includes(store._id))
                  )
                    return store.getFunctionality().then(function (contents) {
                      if (contents != null ? contents.counter : undefined) {
                        $scope.stores.push(store)
                        return
                      }
                    })
                }
              )
            })
          })
          .then(() => isRangeSearchLoad)
          .then(function () {
            $scope.stores = _.sortBy($scope.stores, ["name"])
            return $scope.$broadcast(
              "init-range-search",
              DateTimeSrv.getTimezoneofStores($scope.stores)
            )
          })
      }

      return init()
    }
  )
  .controller(
    "StoreComparisonChartCtrl",
    function (
      $scope,
      $q,
      $location,
      ChartOptionSrv,
      GridOptionSrv,
      ApiSrv,
      Locale,
      SamplingSrv,
      DateTimeSrv
    ) {
      const chartOption = _.merge(
        {
          yaxis: { min: 0 },
        },
        ChartOptionSrv.lineChartOption
      )
      const miniChartOption = ChartOptionSrv.miniLineChartOption

      const gridOption = _.merge(
        {
          onRegisterApi(gridApi) {
            return (this.gridApi = gridApi)
          },
          getGridApi() {
            return this.gridApi
          },
        },
        GridOptionSrv.gridExportOption
      )

      const getStoreLabelName = function (store, labelId) {
        let labelName
        const sLabelIdx = _.findIndex($scope.SPECIALLABEL, ["_id", labelId])
        if (sLabelIdx >= 0) {
          labelName = Locale.string($scope.SPECIALLABEL[sLabelIdx].i18nKey)
        } else {
          labelName = __guard__(_.find(store.labels, { _id: labelId }), (x) => x.name)
        }

        return labelName
      }

      const getLabelSettings = function (storeId, labelId) {
        const store = _.find($scope.stores, { _id: storeId })
        return {
          storeId: storeId,
          labelId: labelId,
          key: storeId + labelId,
          store: store.name,
          label: getStoreLabelName(store, labelId),
          customCode: store.customCode,
        }
      }

      const getCompanyCountData = function (dataSet, options, byLabel) {
        if (byLabel == null) {
          byLabel = false
        }
        const deffered = $q.defer()
        const { sampling } = $scope.pageOptions
        const localStartDate = moment.utc(options.from, "YYYYMMDDHHmm")
        const localEndDate = moment.utc(options.to, "YYYYMMDDHHmm")
        ApiSrv.getCompanyCountData(options).then(
          function (response) {
            let dataIdx = 0

            if (!response.data.length) {
              dataSet = null
            }

            _.forEach(response.data, function (raw) {
              if (byLabel) {
                dataIdx = _.findIndex(dataSet, (n) => n.key === raw.storeId + raw.labelId)
                if (dataIdx < 0) {
                  if (!_.find($scope.SPECIALLABEL, { _id: raw.labelId })) {
                    return
                  }

                  const store = _.find($scope.stores, { _id: raw.storeId })
                  if (!store) {
                    return
                  }

                  const len = dataSet.push(
                    _.merge(
                      getLabelSettings(raw.storeId, raw.labelId),
                      $scope.getDefaultData(localStartDate, localEndDate, sampling)
                    )
                  )
                  dataIdx = len - 1
                }
              } else {
                if (dataSet.length > 1) {
                  dataIdx = _.findIndex(dataSet, function (n) {
                    if (!n.key) {
                      return 0
                    }
                    return n.key === raw.storeId
                  })
                  if (dataIdx < 0) {
                    return
                  }
                }
              }

              const selectedData = dataSet[dataIdx]
              const idx = moment
                .utc(raw.statisticFor)
                .startOf(sampling)
                .diff(moment(localStartDate).startOf(sampling), sampling)

              if (selectedData.data[idx]) {
                selectedData.data[idx][1] = raw.count

                if (sampling === "hour") {
                  if (selectedData.beforeDay !== moment.utc(raw.statisticFor).date()) {
                    selectedData.beforeCount = 0
                  }

                  selectedData.data[idx][1] -= selectedData.beforeCount
                  selectedData.beforeCount = raw.count
                  selectedData.beforeDay = moment.utc(raw.statisticFor).date()
                }

                if (selectedData.data[idx][1] < 0) {
                  selectedData.data[idx][1] = 0
                }
                selectedData.totalCount += selectedData.data[idx][1]
              }
            })

            return deffered.resolve(dataSet)
          },

          function (err) {
            console.error(err.toString())
            return deffered.reject(err)
          }
        )

        return deffered.promise
      }

      const setChart = function (dataSet) {
        const legendEle = angular.element("#storeLegend")
        const dataList = []

        $scope.countList.forEach((data, idx) => {
          if (dataSet.has(data.key)) {
            const cData = angular.copy(data)
            cData.lines = { fill: false }
            cData.highlightColor = cData.color ? cData.color : ChartOptionSrv.chartColorList[idx]

            dataList.push(cData)
          }
        })

        _.merge(chartOption, {
          legend: {
            container: legendEle,
            noColumns: 0,
          },
        })

        if (!dataList.length) {
          legendEle.addClass("invisible")
        } else {
          dataList[0].lines.fill = true
          if (legendEle.hasClass("invisible")) {
            legendEle.removeClass("invisible")
          }
        }

        $scope.chartOption = chartOption
        $scope.chartData = dataList
      }

      const selectChart = function (countData) {
        if ($scope.pageOptions.with.has(countData.key)) {
          $scope.pageOptions.with.delete(countData.key)
        } else {
          $scope.pageOptions.with.add(countData.key)
        }

        if ($scope.pageOptions.with.size === 0) {
          $location.search("storeComparison", "none")
        } else if ($scope.countList.every((data) => $scope.pageOptions.with.has(data.key))) {
          $location.search("storeComparison", "all")
        } else {
          $location.search("storeComparison", [...$scope.pageOptions.with].join(","))
        }

        return setChart($scope.pageOptions.with)
      }

      const isValidPeriod = function (start, end, sampling) {
        const limitDate = SamplingSrv[sampling].getLimitDate()
        const diffNum = moment(end).diff(start, limitDate.key)

        if (diffNum > limitDate.value) {
          return false
        } else {
          return true
        }
      }

      const makeTimeRange = function (start, end = null, isISO) {
        if (isISO == null) {
          isISO = false
        }
        const { sampling } = $scope.pageOptions
        const format = isISO
          ? SamplingSrv[sampling].getIsoDayFormat()
          : SamplingSrv[sampling].getFormat()
        switch (sampling) {
          case "hour":
          case "day":
            return Locale.dateTime(start, format)
          case "week":
          case "month":
            return `${Locale.dateTime(start, format)} ~ ${Locale.dateTime(end, format)}`
        }
      }

      const sortWeight = {}
      kStandardLabels.forEach(
        (label, idx) => (sortWeight[label.name] = -10 * (kStandardLabels.length - idx))
      )

      // const getWeight = (item) => sortWeight[item.label] || item.label
      // const labelSort = function (a, b) {
      //   a = getWeight(a)
      //   b = getWeight(b)
      //   if (_.isString(a) && _.isNumber(b)) {
      //     return 1
      //   } else if (_.isNumber(a) && _.isString(b)) {
      //     return -1
      //   } else if (_.isNumber(a) && _.isNumber(b)) {
      //     return a - b
      //   } else {
      //     return a.localeCompare(b)
      //   }
      // }

      $scope.showChart = function () {
        if ($scope.pageOptions.type.key === "label") {
          if ($scope.gridOptions != null ? $scope.gridOptions.data : undefined) {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      }

      let options = {}

      const initData = function () {
        $scope.gridOptions = {}
        $scope.chartData = []
        $scope.chartOption = []
        $scope.countList = []
        angular.element(".tooltip").remove()
        return setChart($scope.pageOptions.with)
      }

      const createDefaultColumn = (displayName) => [
        { field: "time", minWidth: 300, cellClass: "text-center", displayName },
      ]

      const isEmptyRow = function (dataSet, idx) {
        let result = true
        _.forEach(dataSet, function (data) {
          if (!_.isNull(data.data[idx][1])) {
            result = false
          }
        })

        return result
      }

      const addTabsWithUsingLabels = function (data) {
        let labels = []
        data.forEach(function (row) {
          const label = getStandardLabel(row.labelId)
          if (label && !labels.find((l) => l._id === label._id)) {
            labels.push(label)
          }
        })

        labels = _.sortBy(labels, "_id")
        $scope.chartOpenList.splice(1)
        return labels.forEach((label) =>
          $scope.chartOpenList.push({
            key: label.key,
            name: Locale.string(label.i18nKey),
          })
        )
      }

      const getExportFileName = (type, period) => {
        const orgName =
          $scope.currentStoreGroup.orgType === "company"
            ? $scope.currentCompany.name
            : `${$scope.currentCompany.name}_${$scope.currentStoreGroup.name}`

        return `${orgName}_${type}_${period}`
      }

      const load = function () {
        let filename
        if (!$scope.currentCompany) {
          return
        }
        if (!$scope.stores) {
          return
        }
        const { sampling } = $scope.pageOptions
        const startDate = moment($scope.pageOptions.dt.startDate)
        const endDate = moment($scope.pageOptions.dt.endDate)
        const type = $scope.pageOptions.type.key.toLowerCase()
        const loadGridOption = _.cloneDeep(gridOption)
        options = {
          companyId: $scope.currentCompany._id,
          from: startDate.format("YYYYMMDDHHmm"),
          to: endDate.add(1, "days").startOf("day").format("YYYYMMDDHHmm"),
          sampling: $scope.samplingKey(sampling),
        }

        if (!isValidPeriod(startDate, endDate, sampling)) {
          return
        }

        if (type === "label") {
          options.item = "label"
        } else {
          options[type] = true
        }

        $scope.isGridData = false
        initData()
        let dataSet = []
        const gridSet = []
        const showGridSet = []
        const timeFieldName =
          sampling === "hour" ? Locale.string("time from") : Locale.string("time")
        const columnSet = createDefaultColumn(timeFieldName)
        $scope.showLoading("chart")

        const period = startDate.format("YYYYMMDD") + "-" + endDate.format("YYYYMMDD")
        loadGridOption.filename = filename = getExportFileName(type, period)
        loadGridOption.exporterCsvFilename = filename + ".csv"
        loadGridOption.exporterPdfFilename = filename + ".pdf"
        if (type === "label") {
          const labelsOrder = []
          _.forEach($scope.stores, function (store, _n) {
            _.forEach($scope.SPECIALLABEL, (label) => labelsOrder.push(`${store._id + label._id}`))

            return _.forEach(store.labels, function (label) {
              if (label.active) {
                labelsOrder.push(`${store._id + label._id}`)
                dataSet.push(
                  _.merge(
                    getLabelSettings(store._id, label._id),
                    $scope.getDefaultData(startDate, endDate, sampling)
                  )
                )
              }
            })
          })
          getCompanyCountData(dataSet, options, true).then(function (response) {
            if (response == null || response.length === 0) {
              $scope.hideLoading("chart")
              return initData()
            }

            addTabsWithUsingLabels(response)

            response.sort((a, b) => labelsOrder.indexOf(a.key) - labelsOrder.indexOf(b.key))
            let customCodeColumn
            if (response.find((r) => r.customCode)) {
              customCodeColumn = [
                { field: "time", minWidth: 300, cellClass: "text-center", displayName: "" },
              ]
            }
            _.forEach(response, (res) => {
              if (customCodeColumn) {
                customCodeColumn.push({
                  field: res.key,
                  minWidth: 100,
                  displayName: res.customCode || "",
                  cellClass: "text-right",
                  cellFilter: "number : 0",
                  exporterPdfAlign: "right",
                })
              }
              columnSet.push({
                field: res.key,
                minWidth: 100,
                displayName: res.store + "/" + res.label,
                cellClass: "text-right",
                cellFilter: "number : 0",
                exporterPdfAlign: "right",
              })
            })

            loadGridOption.columnDefs = columnSet
            loadGridOption.columnDefsForLabel = customCodeColumn
            loadGridOption.minRowsToShow = 20
            loadGridOption.exporterPdfHeader = {
              text: "Company Name : " + $scope.currentCompany.name + " (Labels Data)",
              style: "headerStyle",
            }

            const dataLength = _.first(response).data.length

            for (
              var i = 0, end1 = dataLength - 1, asc = 0 <= end1;
              asc ? i <= end1 : i >= end1;
              asc ? i++ : i--
            ) {
              if (isEmptyRow(dataSet, i)) {
                continue
              }

              var currentDate = moment(_.first(response).data[i][0])
              var end =
                i === dataLength - 1
                  ? moment(endDate).subtract(1, "days")
                  : moment(_.first(response).data[i + 1][0]).subtract(1, "days")

              var gridItem = { time: makeTimeRange(currentDate, end, true) }
              var showGridItem = { time: makeTimeRange(currentDate, end, false) }

              _.forEach(response, function (data) {
                gridItem[data.key] = data.data[i][1] != null ? data.data[i][1] : ""
                return (showGridItem[data.key] = data.data[i][1])
              })

              gridSet.push(gridItem)
              showGridSet.push(showGridItem)
            }

            loadGridOption.data = showGridSet
            loadGridOption.exportData = gridSet

            loadGridOption.exporterPdfCustomFormatter = function (docDefinition) {
              docDefinition.content[0].table.widths = GridOptionSrv.getColumnWidths(
                loadGridOption.columnDefs.length
              )
              return docDefinition
            }
            $scope.gridOptions = loadGridOption
            if (gridSet.length > 0) {
              $scope.isGridData = true
            }
            return $scope.hideLoading("chart")
          })
        } else {
          async.waterfall(
            [
              function (cback) {
                dataSet = [$scope.getDefaultData(startDate, endDate, sampling)]
                dataSet[0].label = Locale.string("My Stores")
                dataSet[0].startDate = startDate
                dataSet[0].endDate = endDate
                dataSet[0].color = ChartOptionSrv.chartColorList[0]

                return getCompanyCountData(dataSet, options).then((response) =>
                  cback(null, angular.copy(response))
                )
              },
            ],
            function (_err, dataSet) {
              if (dataSet == null) {
                $scope.hideLoading("chart")
                return initData()
              }

              let customCodeColumn
              if ($scope.stores.find((s) => s.customCode)) {
                customCodeColumn = [
                  { field: "time", minWidth: 300, cellClass: "text-center", displayName: "" },
                ]
              }

              _.forEach($scope.stores, function (store, n) {
                dataSet.push(
                  _.merge(
                    {
                      key: store._id,
                      label: store.name,
                      color: ChartOptionSrv.chartColorList[(n % 5) + 1],
                    },
                    { customCode: store.customCore },
                    $scope.getDefaultData(startDate, endDate, sampling)
                  )
                )
                if (customCodeColumn) {
                  customCodeColumn.push({
                    field: store._id,
                    minWidth: 100,
                    displayName: store.customCode || "",
                    cellClass: "text-right",
                    cellFilter: "number : 0",
                    exporterPdfAlign: "right",
                  })
                }

                // for column definition
                return columnSet.push({
                  field: store._id,
                  minWidth: 100,
                  displayName: store.name,
                  cellClass: "text-right",
                  cellFilter: "number : 0",
                  exporterPdfAlign: "right",
                })
              })

              loadGridOption.columnDefs = columnSet
              loadGridOption.columnDefsForLabel = customCodeColumn
              loadGridOption.minRowsToShow = 10
              loadGridOption.exporterPdfHeader = {
                text: "Company Name : " + $scope.currentCompany.name + " (Stores Data)",
                style: "headerStyle",
              }
              // const storeTimezone = DateTimeSrv.getTimezoneofStores($scope.stores)
              return getCompanyCountData(dataSet, _.merge({}, options, { item: "store" })).then(
                function (response) {
                  chartOption.xaxis.minTickSize = [1, "day"]
                  delete chartOption.xaxis.tickFormatter

                  const dataLength = _.first(dataSet).data.length
                  if (dataLength > 70) {
                    chartOption.lines.lineWidth = 1
                    chartOption.points.show = false
                  } else {
                    chartOption.lines.lineWidth = 3
                    chartOption.points.show = true
                    if (sampling === "hour") {
                      chartOption.xaxis.minTickSize = [1, sampling]
                      chartOption.xaxis.tickFormatter = function (val) {
                        const dbtime = moment.utc(val)
                        return `${Locale.dateTime(dbtime, "hour")}`
                      }
                    }
                  }

                  $scope.countList = response
                  const selectedStores = $location.search().storeComparison

                  if (selectedStores && typeof selectedStores === "string") {
                    if (selectedStores === "none") {
                      $scope.pageOptions.with = new Set()
                    } else if (selectedStores === "all") {
                      $scope.pageOptions.with = new Set(dataSet.map((data) => data.key))
                    } else {
                      $scope.pageOptions.with = new Set(selectedStores.split(","))
                    }
                  } else {
                    $scope.pageOptions.with = new Set(dataSet.slice(1, 3).map((data) => data.key))
                  }
                  setChart($scope.pageOptions.with)

                  for (
                    var i = 0, end1 = dataLength - 1, asc = 0 <= end1;
                    asc ? i <= end1 : i >= end1;
                    asc ? i++ : i--
                  ) {
                    if (isEmptyRow(dataSet, i)) {
                      continue
                    }

                    var currentDate = moment(_.first(response).data[i][0])
                    var end =
                      i === dataLength - 1
                        ? moment(endDate).subtract(1, "days")
                        : moment(_.first(response).data[i + 1][0]).subtract(1, "days")

                    var gridItem = { time: makeTimeRange(currentDate, end, true) }
                    var showGridItem = { time: makeTimeRange(currentDate, end, false) }

                    _.forEach(dataSet, function (data) {
                      gridItem[data.key] = data.data[i][1] != null ? data.data[i][1] : ""
                      return (showGridItem[data.key] = data.data[i][1])
                    })

                    gridSet.push(gridItem)
                    showGridSet.push(showGridItem)
                  }

                  loadGridOption.data = showGridSet
                  loadGridOption.exportData = gridSet

                  loadGridOption.exporterPdfCustomFormatter = function (docDefinition) {
                    docDefinition.content[0].table.widths = GridOptionSrv.getColumnWidths(
                      loadGridOption.columnDefs.length
                    )
                    return docDefinition
                  }

                  $scope.chartOption = chartOption
                  $scope.miniChartOption = miniChartOption
                  $scope.selectChart = selectChart
                  $scope.gridOptions = loadGridOption
                  if (gridSet.length > 0) {
                    $scope.isGridData = true
                  }
                  return $scope.hideLoading("chart")
                },

                (_err) => $scope.hideLoading("chart")
              )
            }
          )
        }
      }

      $scope.chartOption = chartOption
      $scope.miniChartOption = miniChartOption
      $scope.selectChart = selectChart
      $scope.gridOptions = {}

      $scope.$on("reload_chart", () => load())

      $scope.changeChartType = function (options) {
        if (!$scope.pageOptions.dt) {
          return
        }
        if (options.key === $scope.pageOptions.type.key) {
          return
        }

        $scope.pageOptions.type = options
        $scope.pageOptions.with = null
        load()
      }

      return ($scope.selectAllCountBy = function (selection) {
        if (selection) {
          $scope.pageOptions.with = new Set($scope.countList.map((data) => data.key))
          $location.search("storeComparison", "all")
        } else {
          $scope.pageOptions.with = new Set()
          $location.search("storeComparison", "none")
        }

        return setChart($scope.pageOptions.with)
      })
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
