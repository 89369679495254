/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .controller("ParamScriptCtrl", function ($scope, $window, IotAdminSrv) {
    $scope.accessKey = $scope.ngDialogData.accessKey
    $scope.angular = angular

    // item is not defined and this func is not used
    // const getParamConfig = () =>
    //   IotAdminSrv.Group("rawconfig/param").get(
    //     { uid: $scope.accessKey, _: Date.now() },
    //     function (res) {
    //       if (res.error != null) {
    //         return ($scope.errorMessage = res.error)
    //       }
    //       if (res.data == null) {
    //         return ($scope.errorMessage = "no data")
    //       }
    //       const params = {}
    //       res.data.split("\r\n").forEach(function (pair) {
    //         const [name, value] = Array.from(pair.split("="))
    //         return (params[name].value = value)
    //       })
    //       return (item.orgParamConfig = params)
    //     }
    //   )

    const updateParamConfig = (keyvalues) =>
      IotAdminSrv.Group("rawconfig/param").update(
        { uid: $scope.accessKey },
        keyvalues,
        function (res) {
          if (res.error != null) {
            return ($scope.errorMessage = res.error)
          }
          return ($scope.errorMessage = "Done")
        },
        function (error) {
          if (error.data != null) {
            return ($scope.errorMessage = error.data)
          }
        }
      )

    $scope.file_changed = (element) =>
      $scope.$apply(function (scope) {
        const file = element.files[0]
        const reader = new FileReader()
        reader.onloadend = function () {
          $scope.textscript = reader.result
          return checkScriptValidity()
        }

        if (file) {
          return reader.readAsText(file)
        }
      })

    const convertText2JSON = function (script) {
      //script = "" if not script
      if (script == null) {
        script = ""
      }
      let line = null
      try {
        return script
          .replace("\r\n", "\n")
          .split("\n")
          .filter((l) => l.trim().length && l[0] !== "#")
          .map(function (l) {
            let k, v
            l = l.trim()
            line = l
            const sp = l.indexOf("=")
            if (sp < 0) {
              if (l[0] === "$") {
                k = l
                v = ""
              }
            } else {
              k = l.slice(0, sp)
              v = l.slice(sp + 1)
            }
            return { name: k.trim(), value: v.trim() }
          })
      } catch (e) {
        $scope.errorMessage = `[${line}] =>` + e.message
        return []
      }
    }

    var checkScriptValidity = function () {
      $scope.errorMessage = ""
      $scope.keyvalues = convertText2JSON($scope.textscript)
      if ($scope.keyvalues != null) {
        $scope.keyvalues.length
      }
      return ($scope.isValidScript = $scope.keyvalues != null ? $scope.keyvalues.length : undefined)
    }

    $scope.canSubmit = () => $scope.isValidScript

    $scope.loadfile = function (file) {
      const reader = new FileReader()
      reader.readAsBinaryString($scope.uploadFile[0].slice())
      $scope.textscript = reader.result
      return ($scope.errorMessage = "")
    }

    $scope.scriptChanged = () => checkScriptValidity()

    return ($scope.runScript = function () {
      if (checkScriptValidity()) {
        $scope.errorMessage = "Correct Script"
        return updateParamConfig($scope.keyvalues)
      }
    })
  })
