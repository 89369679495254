/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("statIcon", () => ({
    restrict: "E",

    scope: {
      data: "=",
    },

    template: [
      `<i class="btn-icon-round btn-icon btn-icon-md" \
data-ng-class="{ \
'ti-stats-up bg-info': data > 0, \
'ti-stats-down bg-danger': data < 0, \
'ti-line-double bg-gray-light': !(data > 0 || data < 0) \
}" \
style = "margin-left: auto; margin-right: auto; font-size: 32px">`,
      "</i>",
    ].join(""),
  }))
  .directive("miniStatIcon", () => ({
    restrict: "E",

    scope: {
      value: "=",
    },

    replace: true,

    template: [
      `<i class="mdi" data-ng-class="{ \
'mdi-arrow-up-bold color-danger': value > 0, \
'mdi-arrow-down-bold color-primary': value < 0, \
'mdi-minus color-gray-light': !(value > 0 || value < 0) \
}">`,
      "</i>",
    ].join(""),
  }))
