/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS202: Simplify dynamic range loops
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

import { fabric } from "fabric"

angular
  .module("uCountitUiApp")

  .controller(
    "directionViewerCtrl",
    function ($scope, $element, $compile, $timeout, $window, Locale, ngDialog, DirectionUtil) {
      // const MAX_DIRECTION = 4
      const INITIAL_GATE_LENGTH = 3

      const canvasElement = $element[0].querySelector("#directionCanvas")
      const fCanvasObj = new fabric.Canvas(canvasElement, {
        selection: false,
        preserveObjectStacking: true,
      })
      canvasElement.fabric = fCanvasObj
      let prevSelectedState = ""
      const dbColorPattern = /\d+,\d+,\d+/
      $scope.rgbPattern = /rgb\(\d+,\d+,\d+\)/
      $scope.selectedItem = null
      $scope.selectedGate = null
      $scope.completeFlag = true

      const load = function (data) {
        fCanvasObj.clear()
        $scope.selectedItem = null
        $scope.selectedGate = null
        $scope.editMode = false
        $scope.readonly = $scope.mode === "flowdirection" ? true : false

        insertIdxforLegacyData(data)
        checkLinkedGateIntegrity(data)

        _.forEach(data, drawDirection)
        if ($scope.readonly) {
          return setEditFlag(false)
        }
      }

      var checkLinkedGateIntegrity = function (data) {
        let errorMsg = ""
        _.forEach(data, (dir, dIdx) =>
          _.forEach(dir.gates, function (g, gIdx) {
            if (g.linked) {
              const [ldir, lg] = Array.from(findOriginalDirGate(g.linked))
              if (!lg) {
                errorMsg += `linked gate[${g.linked}] does not exist\n`
              } else {
                if (lg.linked !== g.idx) {
                  errorMsg += `linked gate[${dir.idx}.${g.idx}:${g.linked}] is mismatched with gate[${ldir.idx}.${lg.idx}:${lg.linked}]\n`
                }
              }
              if (errorMsg) {
                return console.warn(errorMsg)
              }
            }
          })
        )
        if (errorMsg.length) {
          return $window.alert(errorMsg)
        }
      }

      var insertIdxforLegacyData = (data) =>
        _.forEach(data, function (dir, j) {
          if (dir.idx == null) {
            dir.idx = j + 1
            dir.name = `P${dir.idx}`
          }
          return _.forEach(dir.gates, function (g, i) {
            if (g.idx == null) {
              return (g.idx = dir.idx * 10 + i + 1)
            }
          })
        })

      const setBackgroundSnapshot = function (url, width, height) {
        const element = $element.find("#directionCanvas")
        return element.css({
          width: width,
          height: height,
          background: `url(${url})`,
          "background-size": width + "px " + height + "px",
        })
      }

      const bringToFrontCanvas = function (obj) {
        fCanvasObj.bringToFront(obj)
        if ($scope.editMode && obj.type === "polyline") {
          return $scope.editPointCircles.forEach((point) => fCanvasObj.bringToFront(point))
        }
      }

      const getFontSize = function (width) {
        if (width > 768) {
          return 18
        } else if (width > 320) {
          return 15
        } else {
          return 12
        }
      }

      const setFontCenterPosition = function (polyline, text) {
        const center = polyline.getCenterPoint()
        text.left = center.x - text.width / 2
        return (text.top = center.y - text.height / 2)
      }

      const getAngleBetweenPos = (p1, p2) => (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI

      const getDestinationPos = function (originPos, angle, distance) {
        const destinationPos = { left: 0, top: 0 }
        destinationPos.left = Math.round(
          Math.cos((angle * Math.PI) / 180) * distance + originPos.left
        )
        destinationPos.top = Math.round(
          Math.sin((angle * Math.PI) / 180) * distance + originPos.top
        )
        return destinationPos
      }

      const drawGate = function (direction, gate) {
        const polyline = new fabric.Polyline(gate.points, {
          stroke: direction.rgbcolor,
          strokeWidth: 1,
          strokeDashArray: direction.active ? [] : [5, 5],
          fill: "transparent",
          left: gate.left,
          top: gate.top,
        })

        const firstPoint = new fabric.Circle({
          radius: 3,
          fill: direction.rgbcolor,
          stroke: direction.rgbcolor,
          left: gate.points[0].x + gate.left,
          top: gate.points[0].y + gate.top,
          originX: "center",
          originY: "center",
        })

        const { name } = gate
        const text = new fabric.Text(name, {
          fontSize: getFontSize($scope.width),
          fontFamily: "roboto",
          fontWeight: 600,
          stroke: direction.rgbcolor,
          strokeWidth: 1,
          fill: "white",
        })
        setFontCenterPosition(polyline, text)

        const arrow = new fabric.Triangle({
          width: 20,
          height: 20,
          fill: direction.rgbcolor,
          left: polyline.getCenterPoint().x,
          top: polyline.getCenterPoint().y,
          angle: getAngleBetweenPos(gate.points[gate.points.length - 1], gate.points[0]),
        })

        let pos = getDestinationPos(arrow, arrow.angle, -text.width)
        pos = getDestinationPos(pos, arrow.angle - 90, arrow.height)
        arrow.left = pos.left
        arrow.top = pos.top

        const objs = [polyline, firstPoint, arrow, text]
        if (gate.linked) {
          const [linkedDir, linkedGate] = Array.from(findOriginalDirGate(gate.linked))
          if (linkedGate) {
            const linkedPoint = new fabric.Circle({
              radius: 3,
              fill: linkedDir.rgbcolor,
              stroke: linkedDir.rgbcolor,
              left: gate.points[0].x + gate.left,
              top: gate.points[0].y + gate.top,
              originX: "center",
              originY: "center",
            })
            objs.push(linkedPoint)
          }
        }

        const group = new fabric.Group(objs, {
          hasControls: false,
          dirIdx: direction.idx,
          gateIdx: gate.idx,
        })

        return fCanvasObj.add(group)
      }

      const cleanGate = function (gate) {
        const obj = getCanvasGate(gate.idx)
        if (obj) {
          return fCanvasObj.remove(obj)
        }
      }

      const makeGateName = (dir, outIdx) => dir.name + "-" + String.fromCharCode(65 + outIdx)

      var drawDirection = function (dir) {
        let outCnt = 0
        return _.forEach(dir.gates, function (g) {
          if (g.points.length) {
            g.name = g.inout === "I" ? dir.name : makeGateName(dir, outCnt++)
            return drawGate(dir, g)
          }
        })
      }

      const cleanDirection = (dir) =>
        _.forEach(getCanvasDirGates(dir.idx), (obj) => fCanvasObj.remove(obj))

      var getCanvasDirGates = (idx) => _.filter(fCanvasObj.getObjects(), ["dirIdx", idx])

      var getCanvasGate = (idx) => _.find(fCanvasObj.getObjects(), ["gateIdx", idx])

      const getOriginalGate = (dIdx, gIdx) => _.find($scope.list[dIdx - 1].gates, ["idx", gIdx])

      const findOriginalGate = function (gateIdx) {
        for (var dir of Array.from($scope.list)) {
          var gate = _.find(dir.gates, ["idx", gateIdx])
          if (gate) {
            return gate
          }
        }
        return null
      }

      var findOriginalDirGate = function (gateIdx) {
        for (var dir of Array.from($scope.list)) {
          var gate = _.find(dir.gates, ["idx", gateIdx])
          if (gate) {
            return [dir, gate]
          }
        }
        return [null, null]
      }

      const initEdit = function () {
        removeEditPoints()
        fCanvasObj.remove($scope.editPolyline)
        $scope.editPolyline = null
        return (prevSelectedState = "")
      }

      var setEditFlag = function (flag) {
        fCanvasObj.discardActiveObject().renderAll()
        return fCanvasObj.forEachObject(function (obj) {
          obj.selectable = flag
          return (obj.evented = flag)
        })
      }

      const makeEditPoints = function (points) {
        const editPoints = []
        _.forEach(points, function (point, i) {
          point.index = i
          editPoints.push(point)

          if (i === points.length - 1) {
            return false
          }
          const midPoint = point.midPointFrom(points[i + 1])
          return editPoints.push(midPoint)
        })

        return editPoints
      }

      var removeEditPoints = function () {
        _.forEach($scope.editPointCircles, (circle) => fCanvasObj.remove(circle))
        $scope.editPointCircles = []
        return ($scope.editPoints = [])
      }

      const getEditArray = function (state) {
        if (state == null) {
          state = "real"
        }
        if (state === "virtual") {
          return $scope.editPoints
        } else {
          return _.filter($scope.editPoints, (o) => Number.isInteger(o.index))
        }
      }

      const getEditGatePos = function () {
        const { left } = $scope.editPolyline
        const { top } = $scope.editPolyline

        return {
          left,
          top,
          points: getEditArray("real").map((point) => ({
            x: point.x - left,
            y: point.y - top,
          })),
        }
      }

      const drawEditableGate = function (points = null, type) {
        if (type == null) {
          type = "real"
        }
        if (!$scope.selectedItem) {
          return
        }
        const dInfo = $scope.selectedItem
        const gInfo = _.find(dInfo.gates, ["idx", $scope.selectedGate.gateIdx])
        if (points === null) {
          points = gInfo.points.map((p) => new fabric.Point(p.x + gInfo.left, p.y + gInfo.top))
        }

        $scope.editPoints = makeEditPoints(points)

        $scope.editPolyline = new fabric.Polyline(getEditArray(type), {
          stroke: dInfo.rgbcolor,
          strokeWidth: 2,
          strokeDashArray: dInfo.active ? [] : [5, 5],
          fill: "transparent",
          hasControls: false,
          objectCaching: false,
        })
        fCanvasObj.add($scope.editPolyline)

        $scope.editPointCircles = []
        $scope.editPoints.forEach(function (point, i) {
          const circle = new fabric.Circle({
            radius: 5,
            fill: i === 0 ? "#ff0000" : "#ffffff",
            stroke: "#333333",
            left: point.x,
            top: point.y,
            originX: "center",
            originY: "center",
            hasBorders: false,
            hasControls: false,
            hoverCursor: "pointer",
            opacity: point.index === undefined ? 0.3 : 1,
            state: point.index === undefined ? "virtual" : "real",
            name: point.index, // if 'real' original point index else undefined
            extendName: i, // point array index
          })
          fCanvasObj.add(circle)
          return $scope.editPointCircles.push(circle)
        })

        return _.assign(gInfo, getEditGatePos())
      }

      const removeGateMenu = function () {
        if ($element.find(".gate-menu").length) {
          return $element.find(".gate-menu").remove()
        }
      }

      const createGateMenu = function (x, y) {
        let menuList
        let btnLeft = x
        let btnTop = y - 22

        if ($scope.editMode) {
          btnTop -= 5
          menuList =
            '<li class="pull-left">' +
            '<p class="gate-item shortkey-icon" data-ng-click="editGate(\'save\')">✔</p>' +
            "</li>" +
            '<li class="pull-left">' +
            '<p class="gate-item shortkey-icon" data-ng-click="editGate(\'cancel\')">✘</p>' +
            "</li>"
        } else {
          menuList =
            '<li class="pull-left">' +
            '<p class="gate-item" data-ng-click="addGate()">➕</p>' +
            "</li>" +
            '<li class="pull-left">' +
            '<p class="gate-item" data-ng-click="editGate(\'edit\')">✏️</p>' +
            "</li>" +
            '<li class="pull-left">' +
            '<p class="gate-item" data-ng-click="deleteGate()">❌</p>' +
            "</li>"
        }

        const src = [
          '<div class="gate-menu" style="position:absolute;top:' +
            btnTop +
            "px;left:" +
            btnLeft +
            'px;">',
          '<ul class="list-unstyled clearfix">',
          menuList,
          "</ul>",
          "</div>",
        ].join("")

        return $element.find(".direction-container").append($compile(src)($scope))
      }

      const removeDeletePt = function () {
        if ($element.find(".deletePt").length) {
          return $element.find(".deletePt").remove()
        }
      }

      const createDeletePt = function (x, y) {
        const btnLeft = x
        const btnTop = y - 13

        return $element
          .find(".direction-container")
          .append(
            $compile(
              [
                '<p class="deletePt" title="' +
                  Locale.string("delete") +
                  '" style="color:black;position:absolute;top:' +
                  btnTop +
                  "px;left:" +
                  btnLeft +
                  'px;cursor:pointer;font-size:12px;" data-ng-click="deleteSelectedPoint()">❌</p>',
              ].join("")
            )($scope)
          )
      }

      $scope.deleteSelectedPoint = function () {
        const selected = fCanvasObj.getActiveObject()
        if (!selected || selected.state === "virtual") {
          return
        }

        const points = _.cloneDeep(getEditArray())
        points.splice(selected.name, 1)
        initEdit()
        drawEditableGate(points)
        selectTarget($scope.editPolyline)
        return fCanvasObj.setActiveObject($scope.editPolyline)
      }

      const getOrignalDirGate = function () {
        const direction = $scope.list[$scope.selectedGate.dirIdx - 1]
        const gate = _.find(direction.gates, ["idx", $scope.selectedGate.gateIdx])
        return [direction, gate]
      }

      const newGateNumber = function (dir) {
        const blankIdx = _.indexOf(dir.gates)
        return blankIdx >= 0 ? blankIdx : dir.gates.length
      }

      const newGateIdx = (dir) => dir.idx * 10 + newGateNumber(dir) + 1

      const addNewGate = function (dir, gate) {
        const number = newGateNumber(dir)
        const idx = newGateIdx(dir)
        const newGate = _.assign({}, DirectionUtil.getDefaultGate(idx), {
          left: gate.left,
          top: gate.top + 20,
        })
        newGate.name = makeGateName(dir, number - 1)
        dir.gates[number] = newGate
        return newGate
      }

      $scope.checkGate = function (inout) {
        if (!$scope.selectedGate) {
          return false
        }
        // eslint-disable-next-line no-unused-vars
        const [dir, gate] = Array.from(getOrignalDirGate())
        return gate.inout === inout
      }

      $scope.addLinkedDir = function () {
        $scope.completeFlag = false
        // eslint-disable-next-line no-unused-vars
        const [dir, gate] = Array.from(getOrignalDirGate())
        if (gate.inout === "O") {
          $scope.addDirection(gate)
        }
        return ($scope.completeFlag = true)
      }

      $scope.addGate = function () {
        $scope.completeFlag = false
        const [dir, gate] = Array.from(getOrignalDirGate())
        const newGate = addNewGate(dir, gate)
        drawGate(dir, newGate)
        return ($scope.completeFlag = true)
      }

      const syncLinkedGate = function (gate) {
        const [linkedDir, linkedGate] = Array.from(findOriginalDirGate(gate.linked))
        if (!linkedGate) {
          return
        }

        _.merge(linkedGate, _.pick(gate, ["points", "left", "top"]))
        cleanGate(linkedGate)
        return drawGate(linkedDir, linkedGate)
      }

      $scope.editGate = function (mode) {
        let dir, gate
        switch (mode) {
          case "edit":
            $scope.editMode = true
            $scope.completeFlag = false
            setEditFlag(false)
            fCanvasObj.remove($scope.selectedGate)
            drawEditableGate()
            selectTarget($scope.editPolyline)
            return fCanvasObj.setActiveObject($scope.editPolyline)
          case "save":
          case "cancel":
            if (mode === "save") {
              $scope.list[$scope.selectedGate.dirIdx - 1] = _.cloneDeep($scope.selectedItem)
              ;[dir, gate] = Array.from(getOrignalDirGate())
              if (gate.linked) {
                syncLinkedGate(gate)
              }
            } else {
              ;[dir, gate] = Array.from(getOrignalDirGate())
            }
            setEditFlag(true)
            initEdit()
            drawGate(dir, gate)
            $scope.editMode = false
            $scope.completeFlag = true
            $scope.selectedItem = null
            return ($scope.selectedGate = null)
        }
      }

      const unlinkGate = function (gate) {
        if (gate != null ? gate.linked : undefined) {
          const linkedGate = findOriginalGate(gate.linked)
          return (linkedGate.linked = null)
        }
      }

      $scope.deleteGate = function () {
        if (!$scope.selectedGate) {
          return
        }

        $scope.completeFlag = false
        const [dir, gate] = Array.from(getOrignalDirGate())
        unlinkGate(gate)
        if (gate.inout === "I") {
          $scope.deleteDirection(dir)
        } else {
          cleanDirection(dir)
          _.remove(dir.gates, (g) => g.idx === $scope.selectedGate.gateIdx)
          drawDirection(dir)
          $scope.selectedItem = null
          $scope.selectedGate = null
        }
        return ($scope.completeFlag = true)
      }

      const getNewDirection = function () {
        let newDir = _.find($scope.list, (d) => _.isEmpty(d.gates))

        if (!newDir && $scope.mode === "flowmap" && $scope.list.length < 99) {
          newDir = DirectionUtil.getDefaultItem($scope.list.length + 1)
          $scope.list.push(newDir)
        }

        if (newDir) {
          newDir.active = true
        } else {
          $window.alert(
            Locale.string("Cannot create new direction. Maximum number of directions are 4.")
          )
        }
        return newDir
      }

      $scope.addDirection = function (linkedGate) {
        let bound
        if ($scope.editMode && !$scope.readonly) {
          return
        }

        const dir = getNewDirection()
        if (!dir) {
          return
        }

        const _defaultGatePos = function (i) {
          const offset = [
            [2, 1],
            [1, 3],
            [3, 3],
          ]
          return {
            left: ($scope.width / 8) * offset[i][0],
            top: ($scope.height / 8) * offset[i][1],
          }
        }

        let defaultPos = _defaultGatePos(0)

        if (linkedGate) {
          bound = fCanvasObj.getActiveObject().getBoundingRect()
        } else {
          bound = _.assign({}, defaultPos, { width: 100, height: 0 })
        }

        for (
          let i = 0, end = INITIAL_GATE_LENGTH, asc = 0 <= end;
          asc ? i < end : i > end;
          asc ? i++ : i--
        ) {
          var type = i === 0 ? "I" : "O"
          var pos =
            i === 0
              ? defaultPos
              : _.assign({}, defaultPos, {
                  left: bound.left + bound.width + 10,
                  top: bound.top + (i - 1) * 50,
                })
          var gate = _.assign({}, DirectionUtil.getDefaultGate(newGateIdx(dir), type), pos)
          if (type === "I" && linkedGate) {
            _.merge(gate, _.pick(linkedGate, ["left", "top", "points"]))
            gate.linked = linkedGate.idx
            linkedGate.linked = gate.idx
            defaultPos = _.pick(gate, ["left", "top"])
          }
          dir.gates.push(gate)
        }

        return drawDirection(dir)
      }

      $scope.deleteDirection = function (item) {
        if (!item || $scope.editMode) {
          return
        }
        item.gates.forEach(unlinkGate)
        cleanDirection(item)
        $scope.list[item.idx - 1] = DirectionUtil.getDefaultItem(item.idx)
        $scope.selectedItem = null
        return ($scope.selectedGate = null)
      }

      $scope.setColor = function (item) {
        if (!item || $scope.editMode) {
          return
        }

        const gates = getCanvasDirGates(item.idx)
        _.forEach(gates, function (gate) {
          // line color
          gate._objects[0].set("stroke", item.rgbcolor)
          // first point(circle) color
          gate._objects[1].set("fill", item.rgbcolor)
          gate._objects[1].set("stroke", item.rgbcolor)
          // name color
          return gate._objects[2].set("stroke", item.rgbcolor)
        })
        fCanvasObj.renderAll()
        item.color = dbColorPattern.exec(item.rgbcolor)
        if (item.color) {
          item.color = item.color[0]
        }
        return ($scope.list[item.idx - 1] = item)
      }

      $scope.setActive = function (item) {
        if (!item || $scope.editMode) {
          return
        }

        const value = item.active ? [] : [5, 5]
        const gates = getCanvasDirGates(item.idx)
        _.forEach(gates, (gate) => gate._objects[0].set("strokeDashArray", value))
        fCanvasObj.renderAll()
        return ($scope.list[item.idx - 1] = item)
      }

      $scope.$watch("list", function (data) {
        if (!data) {
          return
        }
        return load(data)
      })

      $scope.$watch("mode", (data) => ($scope.readonly = data === "flowdirection" ? true : false))

      $scope.$watch(
        () =>
          JSON.stringify({
            width: $scope.width,
            height: $scope.height,
            snapshotUrl: $scope.snapshotUrl,
          }),
        function () {
          fCanvasObj.setWidth($scope.width)
          fCanvasObj.setHeight($scope.height)

          if ($scope.snapshotUrl) {
            setBackgroundSnapshot($scope.snapshotUrl, $scope.width, $scope.height)
          }

          $element.find(".direction-menu").width($scope.width)
          $element.find(".direction-container").width($scope.width)

          return
        }
      )

      fCanvasObj.on("mouse:down", function (options) {
        const { target } = options
        if (target) {
          bringToFrontCanvas(target)
          if ($scope.editMode && target.type === "circle") {
            if ((target != null ? target.state : undefined) !== prevSelectedState) {
              $scope.editPolyline.points = _.cloneDeep(getEditArray(target.state))
              fCanvasObj.renderAll()
              return (prevSelectedState = target.state)
            }
          }
        }
      })

      fCanvasObj.on("mouse:over", function (options) {
        const target = options != null ? options.target : undefined
        if (target) {
          if ($scope.editMode && target.type === "circle") {
            target.set("fill", "#5C5B5B")
            return fCanvasObj.renderAll()
          }
        }
      })

      fCanvasObj.on("mouse:out", function (options) {
        const target = options != null ? options.target : undefined
        if (target) {
          if ($scope.editMode && target.type === "circle") {
            if (target.extendName === 0) {
              target.set("fill", "#ff0000")
            } else {
              target.set("fill", "#ffffff")
            }

            return fCanvasObj.renderAll()
          }
        }
      })

      fCanvasObj.on("object:modified", function (options) {
        const { target } = options
        if ($scope.editMode) {
          if (target.type === "circle") {
            const origin = $scope.editPoints[target.extendName]
            if (origin.x === target.left && origin.y === target.top) {
              return
            }

            let count = 0
            const newPoints = []
            $scope.editPolyline.points.forEach(function (point) {
              if (
                (point != null ? point.index : undefined) !== undefined ||
                point.eq(target.getCenterPoint())
              ) {
                point.index = count++
                return newPoints.push(point)
              }
            })
            initEdit()
            drawEditableGate(newPoints, target.state)
          } else if (target.type === "polyline") {
            const gInfo = _.find($scope.selectedItem.gates, ["idx", $scope.selectedGate.gateIdx])
            gInfo.left = target.left
            gInfo.top = target.top

            initEdit()
            drawEditableGate()
          }

          selectTarget($scope.editPolyline)
          return fCanvasObj.setActiveObject($scope.editPolyline)
        } else {
          const gate = getOriginalGate(target.dirIdx, target.gateIdx)
          if (!gate) {
            return
          }

          gate.left = target.left
          gate.top = target.top
          fCanvasObj.discardActiveObject()
          fCanvasObj.setActiveObject($scope.selectedGate)
          return fCanvasObj.renderAll()
        }
      })

      fCanvasObj.on("object:moving", function (options) {
        let x, y
        const { target } = options

        // canvas boundary limit
        if (
          target.currentHeight > target.canvas.height ||
          target.currentWidth > target.canvas.width
        ) {
          return
        }

        target.setCoords()
        const bound = target.getBoundingRect(true)
        const { width } = target.canvas
        const { height } = target.canvas

        if (target.type === "circle") {
          const curCenter = target.getCenterPoint()
          x = Math.min(Math.max(0, curCenter.x), width - 2)
          y = Math.min(Math.max(0, curCenter.y), height - 2)
          const pos = new fabric.Point(x, y)
          target.setPositionByOrigin(pos, "center", "center")
        } else {
          target.left = Math.min(Math.max(0, bound.left), width - bound.width)
          target.top = Math.min(Math.max(0, bound.top), height - bound.height)
        }

        // to do when in edit mode
        removeGateMenu()
        if (!$scope.editMode) {
          return
        }

        if (target.type === "circle") {
          let index
          removeDeletePt()
          if (target.state === "real") {
            index = target.name
            if (target.extendName > 0) {
              fCanvasObj.remove(_.nth($scope.editPointCircles, target.extendName - 1))
            }
            if (target.extendName < $scope.editPointCircles.length) {
              fCanvasObj.remove(_.nth($scope.editPointCircles, target.extendName + 1))
            }
          } else {
            index = target.extendName
          }

          const newPoint = new fabric.Point(target.getCenterPoint().x, target.getCenterPoint().y)
          $scope.editPolyline.points[index] = newPoint
          return fCanvasObj.renderAll()
        } else {
          return removeEditPoints()
        }
      })

      const selectTargetItem = (
        target //INFO: https://stackoverflow.com/questions/25196722/error-rootscopeinprog-digest-already-in-progress
      ) =>
        $timeout(() =>
          //INFO: https://stackoverflow.com/questions/38763460/angular-scope-variable-not-updating
          $scope.$apply(function () {
            if (target) {
              $scope.selectedItem = _.cloneDeep($scope.list[target.dirIdx - 1])
              return ($scope.selectedGate = target)
            } else {
              $scope.selectedItem = null
              return ($scope.selectedGate = null)
            }
          })
        )

      var selectTarget = function (target) {
        removeDeletePt()
        removeGateMenu()
        if ($scope.editMode) {
          if (target.type === "circle") {
            if (target.state === "real") {
              if (getEditArray().length > 2)
                createDeletePt(target.oCoords.tr.x, target.oCoords.tr.y)
              return
            }
          } else if (target.type === "polyline") {
            return createGateMenu(target.oCoords.tr.x, target.oCoords.tr.y)
          }
        } else {
          selectTargetItem(target)
          return createGateMenu(target.oCoords.tr.x, target.oCoords.tr.y)
        }
      }

      fCanvasObj.on("selection:created", (options) => selectTarget(options.target))

      fCanvasObj.on("selection:updated", (options) => selectTarget(options.target))

      fCanvasObj.on("selection:cleared", function (options) {
        removeDeletePt()
        removeGateMenu()
        if (!$scope.editMode && $scope.completeFlag) {
          return selectTargetItem()
        }
      })

      fCanvasObj.on("event:dragleave", function (options) {
        console("event:dragleave", options)
      })

      $scope.$on("gate_edit", function () {
        if ($scope.selectedGate && !$scope.editMode && !$scope.readonly) {
          return $scope.editGate("edit")
        }
      })

      $scope.$on("gate_save", function () {
        if ($scope.editMode) {
          return $scope.editGate("save")
        }
      })

      $scope.$on("gate_add_linked_dir", function () {
        if ($scope.selectedGate && !$scope.editMode && !$scope.readonly) {
          return $scope.addLinkedDir()
        }
      })

      $scope.$on("gate_add", function () {
        if ($scope.selectedGate && !$scope.editMode && !$scope.readonly) {
          return $scope.addGate()
        }
      })

      $scope.$on("gate_cancel", function () {
        if ($scope.editMode) {
          return $scope.editGate("cancel")
        }
      })

      return $scope.$on("gate_delete", function () {
        if (!$scope.editMode && !$scope.readonly) {
          return $scope.deleteGate()
        }
      })
    }
  )
