/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")

  .controller(
    "SummaryStoresCtrl",
    function ($scope, $element, $location, ApiSrv, ChartOptionSrv, Locale, DateTimeSrv, UtilSrv) {
      if (!$scope.data || !$scope.data._id) {
        return
      }

      $scope.chartOption = ChartOptionSrv.lineChartOption
      $scope.chartOption.yaxis.min = 0
      $scope.chartOption.legend = {
        container: angular.element("#legendContainer", $element),
        noColumns: 0,
      }

      const datetime = DateTimeSrv.getLocalTimeByTimezone(
        $scope.data != null ? $scope.data.timezone : undefined
      )
      const theDay = moment(datetime).subtract(1, "days").format("YYYY-MM-DDT00:00:00")

      if (!$scope.data.group) {
        ApiSrv.getFaceCameraOfStore({
          id: $scope.data._id,
        }).then(function (response) {
          if (!_.isEmpty(response)) {
            return ($scope.useFace = true)
          }
        })
      }

      const params = {
        id: $scope.data._id,
        from: moment(theDay).subtract(3, "weeks").format("YYYYMMDD0000"),
        to: moment(theDay).add(1, "days").startOf("day").format("YYYYMMDD0000"),
        sampling: "1d",
        entrance: true,
      }

      if ($scope.data.group) {
        params["group"] = "group"
      }

      return ApiSrv.getStoreCountData(params).then(function (response) {
        let left, left1, left2
        $scope.compare = {
          day: {
            aWeekBefore: UtilSrv.getComparisonResult(),
            twoWeeksBefore: UtilSrv.getComparisonResult(),
          },
          sevenDay: {
            aSevenDayBefore: UtilSrv.getComparisonResult(),
            twoSevenDaysBefore: UtilSrv.getComparisonResult(),
          },
        }

        const resData = _.map(response.data, function (raw) {
          raw["localeDate"] = moment(moment.utc(raw.statisticFor).format("YYYY-MM-DD"))
          return raw
        })

        const _getWeekBeforeCount = function (countDatas, refDay, amount) {
          const dateCountData = _.find(countDatas, (raw) =>
            raw.localeDate.startOf("day").isSame(moment(refDay).subtract(amount, "weeks"))
          )
          return dateCountData != null ? dateCountData.count : undefined
        }

        $scope.yesterdayCount = (left = _getWeekBeforeCount(resData, theDay, 0)) != null ? left : 0
        $scope.aWeekBeforeCount =
          (left1 = _getWeekBeforeCount(resData, theDay, 1)) != null ? left1 : 0
        $scope.twoWeeksBeforeCount =
          (left2 = _getWeekBeforeCount(resData, theDay, 2)) != null ? left2 : 0

        const _getDefaultData = function (start, length) {
          let idx = 0
          length++

          return {
            label: "",
            lines: {
              fill: false,
            },
            data: (() => {
              const result = []
              while ((length -= 1)) {
                result.push([moment(start).add(idx++, "days"), 0])
              }
              return result
            })(),
          }
        }

        $scope.weeklyChartData = [
          _.merge(_getDefaultData(moment(theDay).subtract(1, "weeks").add(1, "days"), 7), {
            label: Locale.string("Last 7 days"),
            lines: {
              fill: true,
            },
          }),

          _.merge(_getDefaultData(moment(theDay).subtract(1, "weeks").add(1, "days"), 7), {
            label: Locale.string("1 week before"),
          }),

          _.merge(_getDefaultData(moment(theDay).subtract(1, "weeks").add(1, "days"), 7), {
            label: Locale.string("2 weeks before"),
          }),
        ]

        $scope.sevenDaysCount = 0
        $scope.aSevenDayBeforeCount = 0
        $scope.twoSevenDaysBeforeCount = 0

        const aWeekAgoDate = moment(theDay).subtract(1, "weeks")
        const twoWeekAgoDate = moment(theDay).subtract(2, "weeks")
        const threeWeekAgoDate = moment(theDay).subtract(3, "weeks")
        _.forEach(resData, function (raw) {
          let idx
          if (raw.localeDate.isAfter(aWeekAgoDate)) {
            $scope.sevenDaysCount += raw.count
            idx = raw.localeDate.diff(moment(aWeekAgoDate).add(1, "days"), "day")
            return ($scope.weeklyChartData[0].data[idx][1] = raw.count)
          } else if (raw.localeDate.isAfter(twoWeekAgoDate)) {
            $scope.aSevenDayBeforeCount += raw.count
            idx = raw.localeDate.diff(moment(twoWeekAgoDate).add(1, "days"), "day")
            return ($scope.weeklyChartData[1].data[idx][1] = raw.count)
          } else if (raw.localeDate.isAfter(threeWeekAgoDate)) {
            $scope.twoSevenDaysBeforeCount += raw.count
            idx = raw.localeDate.diff(moment(threeWeekAgoDate).add(1, "days"), "day")
            return ($scope.weeklyChartData[2].data[idx][1] = raw.count)
          }
        })

        $scope.compare = {
          day: {
            aWeekBefore: UtilSrv.getComparisonResult(
              $scope.aWeekBeforeCount,
              $scope.yesterdayCount
            ),
            twoWeeksBefore: UtilSrv.getComparisonResult(
              $scope.twoWeeksBeforeCount,
              $scope.yesterdayCount
            ),
          },
          sevenDay: {
            aSevenDayBefore: UtilSrv.getComparisonResult(
              $scope.aSevenDayBeforeCount,
              $scope.sevenDaysCount
            ),
            twoSevenDaysBefore: UtilSrv.getComparisonResult(
              $scope.twoSevenDaysBeforeCount,
              $scope.sevenDaysCount
            ),
          },
        }

        if (!$scope.useFace) {
          return
        }

        return ApiSrv.getFaceOfStore({
          id: $scope.data._id,
          from: moment(theDay).subtract(6, "days").format("YYYYMMDD0000"),
          to: moment(theDay).add(1, "days").startOf("day").format("YYYYMMDD0000"),
          sampling: "1d",
        }).then(function (response) {
          let yesterdayFemale = 0
          let yesterdayMale = 0
          let sevenDaysFemale = 0
          let sevenDaysMale = 0
          _.forEach(response.data, function (row) {
            const localeDate = moment(moment.utc(row.statisticFor).format("YYYY-MM-DD"))
            sevenDaysFemale += row.female || 0
            sevenDaysMale += row.male || 0
            if (localeDate.isSame(theDay)) {
              yesterdayFemale = row.female || 0
              return (yesterdayMale = row.male || 0)
            }
          })

          if (yesterdayFemale || yesterdayMale) {
            $scope.yesterdayFemale = Math.round(
              (yesterdayFemale / (yesterdayFemale + yesterdayMale)) * 100
            )
            $scope.yesterdayMale = 100 - $scope.yesterdayFemale
            $scope.yesterdayGenderUnit = "%"
          }

          if (sevenDaysFemale || sevenDaysMale) {
            $scope.sevenDaysFemale = Math.round(
              (sevenDaysFemale / (sevenDaysFemale + sevenDaysMale)) * 100
            )
            $scope.sevenDaysMale = 100 - $scope.sevenDaysFemale
            return ($scope.sevenDaysGenderUnit = "%")
          }
        })
      })
      /*
  datetime = moment().add(1, 'hours').format('YYYY-MM-DDTHH:00:00')
  $scope.todayString = moment(datetime).format("YYYYMMDD0000")
  $scope.thisWeekString = moment(datetime).startOf('isoWeek').format('YYYYMMDD0000')
  $scope.lastWeekString = moment(datetime).startOf('isoWeek').subtract(1, 'weeks').format('YYYYMMDD0000')
  $scope.twoWeeksAgoString = moment(datetime).startOf('isoWeek').subtract(2, 'weeks').format('YYYYMMDD0000')

  options = {
    id: $scope.data._id
    datetime: datetime
  }

  ApiSrv
  .getStoreCompareYesterday(options)
  .then (response) ->
    $scope.compare.yesterday = response

  addOptions =
    from: $scope.twoWeeksAgoString
    to: $scope.tomorrowString
    sampling: '1d'
    entrance: true

  ApiSrv
  .getCountOfStore(_.merge({}, options, addOptions))
  .then (response) ->
    count =
      thisWeek: 0
      lastWeek: 0
      twoWeeksAgo: 0
    response.data.forEach (res) ->
      res.week = 0 if res.week is 52
      switch moment(res.statisticFor).isoWeek(res.week+1).utc().startOf('isoWeek').format("YYYYMMDD0000")
        when $scope.thisWeekString
          count.thisWeek += res.count
        when $scope.lastWeekString
          count.lastWeek += res.count
        when $scope.twoWeeksAgoString
          count.twoWeeksAgo += res.count
    if count.lastWeek is 0 or count.twoWeeksAgo is 0
      $scope.compare.lastweek = '-'
      $scope.compare.lastweek = '-100' if count.twoWeeksAgo
      $scope.compare.lastweek = '∞' if count.lastWeek
    else
      $scope.compare.lastweek = Math.round(count.lastWeek / count.twoWeeksAgo * 100) - 100

  $scope.chartOption = ChartOptionSrv.weeklyLineChart
  ApiSrv
  .getStoreWeeklyCount(options)
  .then (response) ->
    $scope.chartData = response

  ApiSrv
  .getCameraOfStore(options)
  .then (response) ->
    $scope.cameras = response
    $scope.cameras.forEach (camera) ->
      camera.thumbnail = ApiSrv.getDefaultPath() + '/camera/' + camera._id + '/thumbnail'
  */
    }
  )
