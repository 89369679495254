/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import alasql from "alasql"

angular
  .module("uCountitUiApp")

  .factory("GridOptionSrv", function () {
    const exportOption = {
      exporterPdfTableHeaderStyle: {
        fontSize: 13,
        bold: true,
        italics: true,
        color: "#000953",
        "background-color": "#cccccc",
        alignment: "center",
      },
      exporterPdfFooter(currentPage, pageCount) {
        return { text: currentPage.toString() + " of " + pageCount.toString() }
      },
      enableColumnResizing: true,
      enableSorting: false,
      enableColumnMenus: false,
      enableRowHashing: false,
    }

    const getColumnWidths = function (colLength) {
      const widths = __range__(0, colLength - 1, true).map((num) => "auto")
      return widths
    }

    return {
      gridExportOption: exportOption,
      getColumnWidths,
    }
  })

  .factory("GridExportSrv", function (uiGridExporterService) {
    const gridExport = function (gridData, type) {
      let headerInfo = {}

      _.forEach(gridData.columnDefs, (column) => (headerInfo[column.field] = column.displayName))

      let exportData = [headerInfo].concat(gridData.exportData)

      if (gridData.columnDefsForLabel != null) {
        headerInfo = {}
        _.forEach(
          gridData.columnDefsForLabel,
          (column) => (headerInfo[column.field] = column.displayName)
        )

        exportData = [headerInfo].concat(exportData)
      }

      switch (type) {
        case "csv":
          return alasql(
            'SELECT * INTO CSV("' +
              gridData.filename +
              '.csv",{headers: false, utf8Bom: true, separator: ","}) FROM ?',
            [exportData]
          )
        case "pdf":
          return gridData.getGridApi().exporter.pdfExport("all", "all")
        case "excel":
          return alasql(
            'SELECT * INTO XLSX("' + gridData.filename + '.xlsx",{headers: false}) FROM ?',
            [exportData]
          )
      }
    }

    return {
      gridExport,
    }
  })

function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
