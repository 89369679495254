/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

import Promise from "bluebird"
import { BrowserTokenModel } from "common-library/token-model"
const tokenModel = new BrowserTokenModel()

angular
  .module("uCountitUiApp")
  .controller(
    "QueueDashboardCtrl",
    function ($scope, $stateParams, Auth, ApiSrv, $q, QueueSrv, HeaderSrv) {
      $scope.handleDashboardOpen = function (evt) {
        if (!$scope.queueurl) {
          return evt.preventDefault()
        }
      }

      return Promise.all([
        HeaderSrv.fetchCurrentCompany(),
        HeaderSrv.fetchCurrentStore(),
        ApiSrv.getServiceEnvironment(),
      ])
        .spread((company, store, env) =>
          QueueSrv.Gate()
            .query({ storeId: store._id, _: Date.now() })
            .$promise.then(function (gates) {
              $scope.gates = gates
              $scope.store = store
              if (gates.length > 0) {
                $scope.queueurl = `${env.services.queue.url}/dashboard/companies/${
                  company._id
                }/stores/${store._id}?accessToken=${tokenModel.getToken()}`
              }
              return ($scope.prepared = true)
            })
        )
        .catch((err) => console.error(err))
    }
  )
