/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular.module("uCountitUiApp").directive("dateSearchContainer", () => ({
  restrict: "E",
  templateUrl: "components/range-search-container/date-search-container.html",

  scope: {
    searchOptions: "=",
    loadCallback: "=",
  },

  controller($scope, $stateParams, DateTimeSrv, rangePickerCacheSrv, ApiSrv, contentSrv) {
    const DEFAULT_PICKER = {
      minView: "date",
      maxView: false,
      view: "date", // year, month, date, hours, minutes
      sampling: "day",
      maxDate: moment(),
    }
    $scope.cachedTime = {}
    $scope.pickerOptions = {}

    $scope.gotoDate = function (type) {
      let diffDays
      const { dt } = $scope.cachedTime
      const { sampling } = $scope.pickerOptions
      if (["hour", "day"].indexOf(sampling) < 0) {
        diffDays = 0
      } else {
        diffDays = moment(dt.endDate).diff(dt.startDate, sampling)
      }
      const options = {
        orgType: $stateParams.orgType,
        orgId: $stateParams.id,
        content: $scope.searchOptions.content,
      }

      const getDateFunc = type === "first" ? contentSrv.getFirstDate : contentSrv.getLastDate

      return getDateFunc(options)
        .then(function (date) {
          const theDateTime = moment(date).utc(true).endOf(sampling)
          const newdt = {
            startDate: moment(theDateTime).subtract(diffDays, sampling).startOf(sampling),
            endDate: theDateTime,
          }
          $scope.searchOptions.referenceDay = theDateTime
          rangePickerCacheSrv.putCacheTime(newdt, $scope.cachedTime.sampling, "search")
          if ($scope.loadCallback) {
            return $scope.loadCallback()
          }
        })
        .catch((err) => console.error((err != null ? err.message : undefined) || err))
    }

    $scope.changeDate = function (date) {
      let diffDays
      const { dt } = $scope.cachedTime
      const { sampling } = $scope.pickerOptions
      if (["hour", "day"].indexOf(sampling) < 0) {
        diffDays = 0
      } else {
        diffDays = moment(dt.endDate).diff(dt.startDate, sampling)
      }

      const newdt = {
        startDate: moment(date).subtract(diffDays, sampling).startOf(sampling),
        endDate: moment(date).endOf(sampling),
      }

      rangePickerCacheSrv.putCacheTime(newdt, $scope.cachedTime.sampling, "search")
      $scope.cachedTime.dt = newdt
      $scope.searchOptions.referenceDay = date
      if ($scope.loadCallback) {
        return $scope.loadCallback()
      }
    }

    const setDatetimeConfiguration = function (timezone) {
      const today = DateTimeSrv.getLocalTimeByTimezone(timezone).utc(true)
      const configuration = DateTimeSrv.getDateTimeConfiguration(today)
      $scope.pickerOptions.maxDate = configuration.maxDate

      const res = rangePickerCacheSrv.getCachedTimeByTimezone("UTC", "search")
      if (res) {
        if ($scope.pickerOptions.maxDate.isBefore(res.dt.endDate)) {
          res.dt.endDate = $scope.pickerOptions.maxDate
        }
        $scope.searchOptions.referenceDay = res.dt.endDate
        $scope.cachedTime = res
        return
      } else {
        $scope.searchOptions.referenceDay = configuration.dt.endDate
        $scope.cachedTime = {
          dt: configuration.dt,
          sampling: $scope.pickerOptions.sampling,
        }
        return
      }
    }

    const initDateSearch = function () {
      $scope.pickerOptions = {}
      for (var key in DEFAULT_PICKER) {
        var value = DEFAULT_PICKER[key]
        $scope.pickerOptions[key] =
          $scope.searchOptions[key] != null ? $scope.searchOptions[key] : value
      }
      setDatetimeConfiguration($scope.timezone, $scope.searchOptions)
      if ($scope.loadCallback) {
        return $scope.loadCallback(true)
      }
    }

    $scope.$watch("searchOptions.content", function (newVal, oldVal) {
      if (oldVal && newVal !== oldVal) {
        return ($scope.isGotoDate = contentSrv.isSupportGotoDate(newVal, $stateParams.orgType))
      }
    })

    $scope.$on("init-date-search", function (e, timezone) {
      $scope.timezone = timezone
      $scope.isGotoDate = contentSrv.isSupportGotoDate(
        $scope.searchOptions.content,
        $stateParams.orgType
      )
      return initDateSearch()
    })

    return
  },
}))
