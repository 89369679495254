/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular.module("uCountitUiApp").config(($stateProvider) =>
  $stateProvider
    .state("app.sensor", {
      url: "/sensor",
      template: "<div ui-view></div>",
      controller($scope, me) {
        return ($scope.me = me)
      },
    })
    .state("app.sensor.list", {
      url: "/list/:orgType/:id",
      templateUrl: "app/camera/list/cameralist.html",
    })
)
