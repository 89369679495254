/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")

  .controller("ServciceStatsCtrl", ($scope, Auth, ServiceStatsSrv, DateTimeSrv) =>
    Auth.fetchCurrentCompany().then(function (me) {
      if (!me.gteSpace()) {
        return
      }
      $scope.pageOptions = {}
      $scope.pickerOptions = {
        sampling: "day",
        rangeMaxDate: moment(),
      }

      $scope.Init = function () {
        $scope.targetGroup = "company"

        const configuration = DateTimeSrv.getDateTimeConfiguration()
        $scope.pageOptions.dt = {
          startDate: configuration.ranges["Last Month"][0],
          endDate: configuration.ranges["Last Month"][1],
        }
        return ($scope.pickerOptions.rangeMaxDate = configuration.maxDate)
      }

      $scope.selectGroup = function (group) {
        $scope.targets = $scope[group]
        if ($scope.targets != null) {
          return ($scope.selectedTarget = $scope.targets[0])
        }
      }

      $scope.loadTargets = () =>
        ServiceStatsSrv.readNames(function (names) {
          $scope.space = names.space
          $scope.agency = names.agency
          $scope.company = names.company
          $scope.store = names.store
          $scope.camera = names.camera
          return $scope.selectGroup($scope.targetGroup)
        })

      $scope.makeCsvFile = function (table, enabled) {
        let c
        $scope.csvFilename = `${table.target.group}_${table.target.name}_${table.date.from}_${table.date.to}.csv`
        $scope.csvRawdataHeader = ["Company", "Store", "Sensor", "Counter(days)", "Heatmap(days)"]
        $scope.csvRawdata = table.camera

        const csv = []
        csv.push({ company: "#Target", store: table.target.group, camera: table.target.name })
        csv.push({ company: "#Service Date", store: table.date.from, camera: table.date.to })

        csv.push({})
        csv.push({ company: "#Company", store: "Counter(days)", camera: "Heatmap(days)" })
        for (var k of Array.from(table.company)) {
          csv.push(k)
        }

        csv.push({})
        csv.push({
          company: "#Company",
          store: "Store",
          camera: "Counter(days)",
          counter: "Heatmap(days)",
        })
        for (var s of Array.from(table.store)) {
          csv.push(s)
        }

        csv.push({})
        csv.push({
          company: "#Company",
          store: "Store",
          camera: "Sensor",
          counter: "Counter(days)",
          heatmap: "Heatmap(days)",
        })
        for (c of Array.from(table.camera)) {
          csv.push(c)
        }

        csv.push({})
        csv.push({ camera: "Sensor", counter: "Active(days)" })
        for (c of Array.from(enabled)) {
          csv.push({ camera: c.cameraname, counter: c.enabledtime })
        }

        return ($scope.csvSummary = csv)
      }

      $scope.submitForm = function () {
        const startDate = moment($scope.pageOptions.dt.startDate)
        const endDate = moment($scope.pageOptions.dt.endDate)

        const options = {
          id: $scope.selectedTarget._id,
          from: startDate.format("YYYYMMDD0000"),
          to: endDate.format("YYYYMMDD0000"),
        }
        $scope.table = null
        ServiceStatsSrv.getServiceTimes($scope.targetGroup, options).then(function (res) {
          $scope.table = res
          $scope.table.date = {
            from: startDate.format("YYYY-MM-DD"),
            to: endDate.format("YYYY-MM-DD"),
          }
          $scope.table.target = {
            group: $scope.targetGroup,
            name: $scope.selectedTarget.name,
          }

          $scope.enalbedTime = null
          return ServiceStatsSrv.getEnabledTimes($scope.targetGroup, options).then(function (res) {
            $scope.enalbedTime = res
            return $scope.makeCsvFile($scope.table, $scope.enalbedTime)
          })
        })

        $scope.serviceLog = null
        return ServiceStatsSrv.getLogs($scope.targetGroup, options).then(function (res) {
          _.forEach(
            res,
            (data, idx) => (data.date = moment(data.date).format("YYYY-MM-DD HH:mm:ss"))
          )

          $scope.csvLogHeader = ["Date", "Name", "Target", "Activity", "Misc"]
          return ($scope.serviceLog = res)
        })
      }

      $scope.Init()
      return $scope.loadTargets()
    })
  )
