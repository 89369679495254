/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory("AccountGroupSrv", ($rootScope, $resource) =>
    $resource(
      "/api/1.0/account/group/:id/:item/:accountId",
      { id: "@id" },
      {
        get: {
          method: "GET",
        },

        update: {
          method: "PUT",
        },

        getMember: {
          method: "GET",
          isArray: true,
          params: {
            item: "member",
          },
        },

        addMember: {
          method: "POST",
          isArray: true,
          params: {
            item: "member",
          },
        },

        removeMember: {
          method: "DELETE",
          params: {
            item: "member",
          },
        },
      }
    )
  )
