/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"
import "./commons"
import { authInterceptor } from "./interceptors"
import { createCacheInterceptor } from "common-library/angular-http-cache-interceptor"
import { routerConfig, routerRun, createRootState } from "./routes"
import { hotkeysTemplate } from "./hotkeys-template"
import angular from "angular"

// Angular Components and modules
import "bootstrap-daterangepicker/daterangepicker.css"
import "angular-cookies"
import "angular-sanitize"
import "angular-resource"
import "angular-loading-bar"
import "angular-loading-bar/build/loading-bar.css"
import "@uirouter/angularjs"
import "angular-ui-bootstrap"
import "angular-ui-validate"
import "ng-file-upload"
import "ng-csv"
// Require ng-table v0.83 manually. because npm build failed due to
// depenedency.
import "../vendor/scripts/ng-table.min.js"
import "../vendor/stylesheets/ng-table.min.css"
import "ng-dialog"
import "ng-dialog/css/ngDialog.css"
import "ng-dialog/css/ngDialog-theme-default.css"
import "re-tree" // It is one of dependency of ng-device-detector
import "ng-device-detector"
import "ns-popover"
import "angular-daterangepicker/js/angular-daterangepicker"
import "angular-spinner"
import "angular-ui-grid"
import "angular-ui-grid/ui-grid.css"
import "ui-select"
import "ui-select/dist/select.css"
import "select2"
import "select2/select2.css"
import "angular-bootstrap-colorpicker"
import "angular-bootstrap-colorpicker/css/colorpicker.css"
import "angular-timezone-selector"
import "angular-timezone-selector/dist/angular-timezone-selector.css"
import "angular-datepicker"
import "angular-datepicker/dist/angular-datepicker.css"
import "angularjs-slider" //rzModule
import "angularjs-slider/dist/rzslider.css"
import "angular-hotkeys"
import "./app.scss"
import "../styles/main.scss"
import "../styles/ui.scss"
import "angular-legacy-sortablejs-maintained"
// import { react2angular } from "react2angular"
// import Chart from "chart.js"

const init = function () {
  const App = angular.module("uCountitUiApp", [
    "ngCookies",
    "ngResource",
    "ngSanitize",
    "angular-loading-bar",
    "ui.router",
    "ui.bootstrap",
    "ui.validate",
    "ngFileUpload",
    "ngCsv",
    "ngTable",
    "ngDialog",
    "reTree",
    "ng.deviceDetector",
    "nsPopover",
    "daterangepicker",
    "angularSpinner",
    "ui.grid",
    "ui.grid.exporter",
    "ui.grid.resizeColumns",
    "ui.select",
    "ui.grid.autoResize",
    "colorpicker.module",
    "rzModule",
    "angular-timezone-selector",
    "datePicker",
    "cfp.hotkeys",
    "ng-sortable",
  ])

  // Load all html files
  const htmlReq = require.context("../", true, /\.html$/)
  App.run(
    (
      $templateCache // Add Template Cache for templates
    ) =>
      htmlReq.keys().map(function (k) {
        const path = k.slice(2)
        const html = htmlReq(k)
        return $templateCache.put(path, html.default)
      })
  )
    .run(
      ($rootScope) =>
        ($rootScope.globalConfig = {
          apipath: "/api/1.0",
          haveBrandByAccount: false,
        })
    )
    .factory("authInterceptor", authInterceptor)
    .factory("cacheInterceptor", ($cacheFactory) =>
      createCacheInterceptor($cacheFactory, { expire: 360 })
    )
    .config(($httpProvider) => $httpProvider.interceptors.push("authInterceptor"))
    .config(($resourceProvider) => ($resourceProvider.defaults.actions.update = { method: "PUT" }))
    .config(createRootState)
    .config(routerConfig)
    .config(hotkeysTemplate)
    .config(($sceDelegateProvider) => $sceDelegateProvider.resourceUrlWhitelist([/.+/]))
    .run(routerRun)
    .run(($transitions, ApiSrv) => {
      ApiSrv.getServiceConfig().then(function (config) {
        window.gtag("js", new Date())
        window.gtag("config", config.log.analyticTrackingId)
        return $transitions.onSuccess({}, function (trans) {
          window.scrollTo(0, 0)
          if (config.log?.analyticTrackingId) {
            return window.gtag("event", "page_view", {
              page_location: window.location.href,
              page_path: window.location.pathname,
            })
          }
        })
      })
    })
    .directive("customPage", () => ({
      restrict: "A",

      controller($scope, $element, $location) {
        const path = () => $location.path()

        return $scope.$watch(path, function (newVal, oldVal) {
          $element.removeClass("body-wide body-err body-lock body-auth")
          if (newVal === "/login" || newVal.indexOf("/account/recovery") === 0) {
            return $element.addClass("body-wide")
          }
        })
      },
    }))
    .factory(
      "focus",
      ($timeout, $window) => (id) =>
        $timeout(function () {
          const element = $window.document.getElementById(id)
          if (element) {
            return element.focus()
          }
        })
    )
    .directive(
      "eventFocus",
      (focus) =>
        function (scope, elem, attr) {
          elem.on(attr.eventFocus, () => focus(attr.eventFocusId))

          return scope.$on("$destroy", () => elem.off(attr.eventFocus))
        }
    )

  const requireAll = (r) =>
    r.keys().forEach(function (k) {
      if (k === "./app/app.js" || k === "./app/commons.js") {
        return
      }
      return r(k)
    })

  requireAll(require.context("../", true, /^([^/]+\/)+[^.][^#/]+(?<!\.spec)\.js$/))
  requireAll(
    require.context(
      "../",
      true,
      /^([^/]+\/)+[^.][^#/]+(?<!\.spec)\.(controller|service|directive)\.[jt]sx?$/
    )
  )
  // for Backward comptability. load all file except spec

  return App
}

const App = init()

export default App
