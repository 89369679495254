/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

// import _ from "lodash"
import angular from "angular"

import Promise from "bluebird"
import { BrowserTokenModel } from "common-library/token-model"
const tokenModel = new BrowserTokenModel()
import Config from "../../../../config/environment"

angular
  .module("uCountitUiApp")
  .controller("FaceDashboardCtrl", function ($scope, $stateParams, Auth, ApiSrv, $q, HeaderSrv) {
    $scope.handleDashboardOpen = function (evt) {
      if (!$scope.faceDashboardUrl) {
        return evt.preventDefault()
      }
    }

    return Promise.all([
      HeaderSrv.fetchCurrentCompany(),
      HeaderSrv.fetchCurrentStore(),
      HeaderSrv.fetchCurrentSensor(),
    ])
      .spread(function (company, store, sensor) {
        $scope.sensor = sensor
        return ($scope.faceDashboardUrl = `${Config.web.faceurl}/sensor/${
          sensor._id
        }/accessToken=${tokenModel.getToken()}`)
      })
      .catch((err) => console.error(err))
  })
