/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

angular
  .module("uCountitUiApp")
  .directive(
    "genderOverview",
    ($compile, $filter, Face, ChartOptionSrv, Locale, SamplingSrv, UtilSrv, usSpinnerService) => ({
      restrict: "E",
      templateUrl: "components/overview/gender/gender-overview.html",

      scope: {
        orgType: "=",
        orgId: "=",
        referenceDate: "=",
        useFaceCountOnly: "=",
      },

      replace: true,

      link(scope, element, attrs) {
        let tomorrow = ""
        let lastWeekAgo = ""
        let twoWeeksAgo = ""
        let threeMonthsAgo = ""
        const loadingQueue = []

        const pieChartOption = {
          series: {
            pie: {
              show: true,
              radius: 45,
              label: {
                show: true,
                radius: 0.5,
              },
            },
          },
          legend: {
            show: false,
          },
          grid: {
            hoverable: true,
          },
        }

        scope.twoWeeksAgoChartOption = {}
        scope.oneWeekAgoChartOption = {}
        scope.lastWeekChartOption = {}
        scope.refDateChartOption = {}
        scope.lineChartOption = {}

        const isNumber = (num) => !isNaN(parseFloat(num)) && isFinite(num)

        const showLoading = function (key) {
          if (key) {
            loadingQueue.push(key)
          }
          angular.element(".splash").show()
          return usSpinnerService.spin("spinner")
        }

        const hideLoading = function (key) {
          if (key) {
            _.remove(loadingQueue, (raw) => raw === key)
            if (loadingQueue.length) {
              return
            }
          }

          angular.element(".splash").hide()
          return usSpinnerService.stop("spinner")
        }

        const setPieChartOptions = function (chartData) {
          const unit = scope.useFaceCountOnly ? "%" : ""
          const chartOption = _.merge({}, pieChartOption)
          chartOption.series.pie.label.formatter = function (label, series) {
            label = $filter("number")(series.data[0][1]) + unit
            if (!scope.useFaceCountOnly) {
              label += "<br /> (" + series.percent.toFixed(1) + "%)"
            }

            return label
          }

          return chartOption
        }

        const setLineChartOptions = function (from, to, data) {
          scope.lineChartOption = _.merge({}, ChartOptionSrv.contentChartOption, {
            series: {
              lines: {
                show: true,
              },
              points: {
                radius: 0.5,
                fill: true,
                show: true,
              },
              shadowSize: 0,
            },
            yaxis: {
              autoscaleMargin: 0,
            },
          })

          const sampling = "month"
          const tickSize = SamplingSrv[sampling].getTickSize(from, to)
          const minTickSize = SamplingSrv[sampling].getMinTickSize()

          const maxVal = _.maxBy(
            scope.threeMonthChartData.map((value) => _.maxBy(value.data, (o) => o[1])[1])
          )
          const unit = scope.useFaceCountOnly ? "%" : ""
          const decimal = scope.useFaceCountOnly ? 1 : 0

          scope.lineChartOption.yaxis.ticks = [[0, "0"], [maxVal / 2, ""], [maxVal]]
          scope.lineChartOption.yaxis.tickFormatter = (val, axis) => `${val}${unit}`

          scope.lineChartOption.xaxis.tickFormatter = (val, axis) =>
            Locale.dateTime(moment.utc(val), "month")

          scope.lineChartOption.xaxis.tickSize = [tickSize, sampling]
          scope.lineChartOption.xaxis.minTickSize = [minTickSize, sampling]

          return (scope.lineChartOption.tooltip.content = function (label, x, y, item) {
            const tooltipDateFormat = SamplingSrv.day.getFormat()
            const dataIdx = item.dataIndex
            let contents = `${Locale.dateTime(moment.utc(x), tooltipDateFormat)}<br>`

            scope.threeMonthChartData.forEach(function (chart, idx) {
              const value = scope.useFaceCountOnly
                ? data[dataIdx].percent[chart.label]
                : data[dataIdx][chart.label]

              const str = isNumber(value) ? $filter("number")(value, decimal) + unit : value
              return (contents += [
                `<svg width=10 height=10><rect width=5 height=10 fill=${chart.color}></svg>`,
                `${Locale.string(chart.label)} : `,
                `<b>${str || ""}</b><br>`,
              ].join(""))
            })

            return contents
          })
        }

        const drawStartMonthLineOnChart = function (from, to) {
          const startLines = []
          let i = 1

          for (;;) {
            var date = moment.utc(from).startOf("month").add(i, "month")

            if (date.isAfter(to)) {
              break
            }
            var startLine = {
              color: "#ddd",
              lineWidth: 1,
              xaxis: {
                from: date,
                to: date,
              },
            }

            startLines.push(startLine)
            i++
          }

          return (scope.lineChartOption.grid.markings = startLines)
        }

        const generateCountDataByGender = function (genderData) {
          if (!(genderData != null ? genderData.length : undefined)) {
            return null
          }

          const femaleList = []
          const maleList = []
          let femaleTotal = 0
          let maleTotal = 0
          let validDataCount = 0

          genderData.forEach(function (gender, idx) {
            let female = scope.useFaceCountOnly ? gender.percent.female : gender.female
            let male = scope.useFaceCountOnly ? gender.percent.male : gender.male

            if (isNumber(female) && isNumber(male)) {
              femaleTotal += female
              maleTotal += male
              validDataCount += 1
            } else {
              female = 0
              male = 0
            }

            femaleList.push([gender.date, female])
            return maleList.push([gender.date, male])
          })

          const femaleAvg = (femaleTotal / validDataCount).toFixed(1)
          const maleAvg = (maleTotal / validDataCount).toFixed(1)

          return {
            femaleList,
            maleList,
            femaleTotal,
            maleTotal,
            femaleAvg,
            maleAvg,
          }
        }

        const getValidationData = function (list) {
          const sum = _.sumBy(list, "data")
          if (sum !== 0) {
            list.reverse()
            return list
          } else {
            return []
          }
        }

        const initData = function () {
          scope.twoWeeksAgoChartData = []
          scope.twoWeeksAgoChartOption = {}
          scope.oneWeekAgoChartData = []
          scope.oneWeekAgoChartOption = {}
          scope.lastWeekChartData = []
          scope.lastWeekChartOption = {}
          scope.refDateChartData = []
          scope.refDateChartOption = {}
          scope.threeMonthChartData = []
          scope.lineChartOption = {}

          scope.compare = {
            weekly: {
              female: "-",
              male: "-",
            },
            ref: {
              female: "-",
              male: "-",
            },
          }
          scope.showComparisonWeeklyRatio = false
          return (scope.showComparisonRatio = false)
        }

        const updateData = function () {
          showLoading("gender_data")

          const options = {
            id: scope.orgId,
            selectedSampling: "day",
            orgType: scope.orgType,
          }

          const lastWeekAgoOption = _.merge({}, options, { from: lastWeekAgo, to: tomorrow })
          const twoWeeksAgoOption = _.merge({}, options, { from: twoWeeksAgo, to: lastWeekAgo })
          const threeMonthsAgOption = _.merge({}, options, { from: threeMonthsAgo, to: tomorrow })

          return async.parallel(
            {
              lastWeekAgoData(callback) {
                return Face.getGenderData(lastWeekAgoOption, scope.useFaceCountOnly).then(
                  (counters) => callback(null, counters),
                  (err) => callback(err)
                )
              },
              twoWeeksAgoData(callback) {
                return Face.getGenderData(twoWeeksAgoOption, scope.useFaceCountOnly).then(
                  (counters) => callback(null, counters),
                  (err) => callback(err)
                )
              },
              threeMonthsAgoData(callback) {
                return Face.getGenderData(threeMonthsAgOption, scope.useFaceCountOnly).then(
                  (counters) => callback(null, counters),
                  (err) => callback(err)
                )
              },
            },
            function (err, results) {
              if (
                err ||
                !(results.threeMonthsAgoData != null
                  ? results.threeMonthsAgoData.length
                  : undefined)
              ) {
                hideLoading("gender_data")
                initData()
                return
              }

              const lastWeekAgoData = generateCountDataByGender(results.lastWeekAgoData)

              const twoWeeksAgoData = generateCountDataByGender(results.twoWeeksAgoData)

              const threeMonthsData = results.threeMonthsAgoData
              const threeMonthsAgoData = generateCountDataByGender(results.threeMonthsAgoData)

              const twoWeeksAgoChartData = []
              const oneWeekAgoChartData = []
              const refDateChartData = []
              const lastWeekChartData = []
              const threeMonthChartData = []
              const compare = {
                weekly: {
                  female: 0,
                  male: 0,
                },
                ref: {
                  female: 0,
                  male: 0,
                },
              }
              Face.genderList.forEach(function (gender) {
                let lastWeekAvg, lastWeekCnt, refDateCnt, twoWeeksAgoAvg
                const genderType = gender.name
                if (twoWeeksAgoData) {
                  twoWeeksAgoAvg = parseFloat(twoWeeksAgoData[`${genderType}Avg`])
                  lastWeekCnt = _.last(twoWeeksAgoData[`${genderType}List`])[1]

                  if (isNumber(twoWeeksAgoAvg)) {
                    twoWeeksAgoChartData.push({
                      label: genderType,
                      data: twoWeeksAgoAvg,
                      color: gender.color,
                    })
                  }

                  if (isNumber(lastWeekCnt)) {
                    lastWeekChartData.push({
                      label: genderType,
                      data: lastWeekCnt,
                      color: gender.color,
                    })
                  }
                }

                if (lastWeekAgoData) {
                  lastWeekAvg = parseFloat(lastWeekAgoData[`${genderType}Avg`])
                  refDateCnt = _.last(lastWeekAgoData[`${genderType}List`])[1]

                  if (isNumber(lastWeekAvg)) {
                    oneWeekAgoChartData.push({
                      label: genderType,
                      data: lastWeekAvg,
                      color: gender.color,
                    })
                  }

                  if (isNumber(refDateCnt)) {
                    refDateChartData.push({
                      label: genderType,
                      data: refDateCnt,
                      color: gender.color,
                    })
                  }
                }

                if (threeMonthsAgoData) {
                  threeMonthChartData.push({
                    label: genderType,
                    data: threeMonthsAgoData[`${genderType}List`],
                    color: gender.color,
                  })
                }

                compare.weekly[genderType] = __guard__(
                  UtilSrv.getComparisonResult(twoWeeksAgoAvg, lastWeekAvg),
                  (x) => x.comparisonRatio
                )
                return (compare.ref[genderType] = __guard__(
                  UtilSrv.getComparisonResult(lastWeekCnt, refDateCnt),
                  (x1) => x1.comparisonRatio
                ))
              })

              scope.compare = compare

              scope.twoWeeksAgoChartData = getValidationData(twoWeeksAgoChartData)
              scope.oneWeekAgoChartData = getValidationData(oneWeekAgoChartData)
              scope.lastWeekChartData = getValidationData(lastWeekChartData)
              scope.refDateChartData = getValidationData(refDateChartData)
              scope.threeMonthChartData = threeMonthChartData

              scope.showComparisonWeeklyRatio =
                scope.twoWeeksAgoChartData.length && scope.oneWeekAgoChartData.length
              scope.showComparisonRatio =
                scope.lastWeekChartData.length && scope.refDateChartData.length

              const from = threeMonthsAgo
              const to = scope.referenceDate

              scope.twoWeeksAgoChartOption = setPieChartOptions(scope.twoWeeksAgoChartData)
              scope.oneWeekAgoChartOption = setPieChartOptions(scope.oneWeekAgoChartData)
              scope.lastWeekChartOption = setPieChartOptions(scope.lastWeekChartData)
              scope.refDateChartOption = setPieChartOptions(scope.refDateChartData)
              setLineChartOptions(from, to, threeMonthsData)
              drawStartMonthLineOnChart(from, to)

              return hideLoading("gender_data")
            }
          )
        }

        return scope.$watch("referenceDate", function (newDate) {
          if (newDate) {
            lastWeekAgo = moment(newDate).subtract(1, "week").add(1, "day")
            twoWeeksAgo = moment(newDate).subtract(2, "week").add(1, "day")
            tomorrow = moment(newDate).add(1, "day")
            threeMonthsAgo = moment(newDate).subtract(12, "week").add(1, "day")

            scope.oneWeekAgoStr = Locale.dateTime(moment(newDate).subtract(1, "week"), "date")
            scope.refDateStr = Locale.dateTime(moment(newDate), "date")

            scope.twoWeeksAgoRangeStr = `${Locale.dateTime(moment(twoWeeksAgo), "date")} ~ ${
              scope.oneWeekAgoStr
            }`
            scope.oneWeekAgoRangeStr = `${Locale.dateTime(moment(lastWeekAgo), "date")} ~ ${
              scope.refDateStr
            }`
            return updateData()
          }
        })
      },
    })
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
