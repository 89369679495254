/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("selectedOrganizationOnly", () => ({
    restrict: "E",

    template: [
      '<label class="ui-checkbox pull-right" data-ng-show="me.gteAgency()">',
      '<input type="checkbox" ng-model="showSelectedOrganization" data-ng-change="onCheck()">',
      '<span data-i18n="Selected Organization Only"></span>',
      "</label>",
    ].join(""),

    controller($scope, Auth) {
      $scope.showSelectedOrganization = Auth.clientInfo("admin.showSelectedOrganization")
      if ($scope.showSelectedOrganization === undefined) {
        $scope.showSelectedOrganization = false
      }

      return Auth.fetchCurrentAccount().then(function (me) {
        $scope.me = me
        return ($scope.onCheck = () =>
          Auth.clientInfo("admin.showSelectedOrganization", $scope.showSelectedOrganization))
      })
    },
  }))
