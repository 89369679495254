/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

import { getSnapshotUrl } from "../../../components/snapshot"

angular
  .module("uCountitUiApp")

  .controller(
    "DataLogCtrl",
    function (
      $filter,
      $resource,
      $window,
      $rootScope,
      $cookieStore,
      $scope,
      Auth,
      IotAdminSrv,
      ApiSrv,
      Locale,
      ngDialog,
      NgTableParams,
      me,
      ServiceTokenSrv
    ) {
      if (!me.gteSpace()) {
        return
      }
      $scope.me = me
      $scope.itemName = Locale.string("Sensor")
      $scope.original = null
      $scope.isAbnormalCameras = false
      $scope.nAbnormalCameras = "?"
      $scope.nLoadCameras = 0
      $scope.detailDlg = {}
      $scope.isOpenDetailDlg = false

      $scope.header = {
        snapshot: Locale.string("Snapshot"),
        camera: Locale.string("Sensor"),
        accessKey: Locale.string("Access Key"),
        online: Locale.string("Online"),
        lastAccess: Locale.string("Last Access"),
        uptime: Locale.string("Uptime"),
        counters48h: `${Locale.string("Counters")}\n(48H)`,
        heatmaps48h: `${Locale.string("Heatmaps")}\n(48H)`,
        lastNotification: Locale.string("Last Notification"),
        statusChanged: Locale.string("Status Changed"),
      }

      $scope.filterAbnormalCameras = function (isAbnormal) {
        $scope.isAbnormalCameras = isAbnormal

        if ($scope.isAbnormalCameras) {
          $scope.tableParams.sorting({ online: "asc" })
        }

        return loadItems()
      }

      $scope.showDetails = function (item) {
        $scope.isOpenDetailDlg = true
        $scope.detailDlg.title = `${item.name} ( ${item.accessKey} )`
        $scope.detailDlg.data = "loading..."
        return IotAdminSrv.Group().get(
          { uid: item.accessKey, _: Date.now() },
          function (res) {
            if (
              __guard__(
                __guard__(
                  __guard__(res != null ? res.data : undefined, (x2) => x2.rawconfig),
                  (x1) => x1.vca
                ),
                (x) => x.data
              ) != null
            ) {
              delete res.data.rawconfig.vca.data
            }
            if (
              __guard__(
                __guard__(
                  __guard__(res != null ? res.data : undefined, (x5) => x5.rawconfig),
                  (x4) => x4.system
                ),
                (x3) => x3.data
              ) != null
            ) {
              delete res.data.rawconfig.system.data
            }
            return ($scope.detailDlg.data = $filter("json")(res.data, 2))
          },
          (error) => ($scope.detailDlg.data = $filter("json")(angular.copy(item), 2))
        )
      }

      $scope.closeDetails = () => ($scope.isOpenDetailDlg = false)

      $scope.showDataLog = (camera) =>
        IotAdminSrv.getAllCameraDataLog(camera).then((data) =>
          ngDialog.open({
            template: "app/system/monitoring/datalogdlg.html",
            data,
            closeByEscape: true,
            className: "ngdialog-theme-default datalogdialog",
            controller: "DataLogDlgCtrl",
          })
        )

      $scope.openMemo = (camera) =>
        ngDialog.open({
          template: "components/cameranote/cameranote.html",
          data: camera,
          closeByEscape: false,
          closeByDocument: false,
          className: "ngdialog-theme-default cameranote",
          controller: "CameraNoteCtrl",
        })

      $scope.showHealthLog = (camera) =>
        ngDialog.open({
          template: "components/camerahealth/index.html",
          data: camera,
          closeByEscape: true,
          closeByDocument: true,
          className: "ngdialog-theme-default camerahealth",
          controller: "CameraHealthCtrl",
        })
      //#########################################################################
      // table
      const _isRegistered = (group) => group.indexOf("ucountit") >= 0

      const _getLocalTime = (
        at,
        offset //moment(at).utcOffset(offset).format('YYYY-MM-DD HH:mm Z')
      ) => moment(at).utcOffset(offset).format("YYYY-MM-DD HH:mm")

      const _setDurationString = function (item) {
        if (
          __guard__(item.status != null ? item.status.uptime : undefined, (x) => x.duration) == null
        ) {
          return
        }

        const _pad = (num) => _.padStart(num, 2, "0")

        const _GetDurationString = function (msDuration) {
          const _d = moment.duration(msDuration)
          let str = ""
          str = `${Math.floor(_d.asDays())}D `
          return (str += `${_pad(_d.hours())}:${_pad(_d.minutes())}`)
        }

        item.uptime = item.status.uptime.duration
        return (item.uptimestr = _GetDurationString(item.status.uptime.duration))
      }

      const _makeOrganizationName = function (item) {
        if (!item) {
          return
        }
        const store = _.find($scope.stores, (d) => d._id === item.sid)
        item.storename = store != null ? store.name : undefined

        item.companyid = store != null ? store._companyId : undefined
        const company = _.find($scope.companies, (d) => d._id === item.companyid)
        item.companyname = company != null ? company.name : undefined

        item.agencyid = company != null ? company._agencyId : undefined
        const agency = _.find($scope.agencies, (d) => d._id === item.agencyid)
        item.agencyname = agency != null ? agency.name : undefined

        return (item.orgname = `${item.agencyname} > ${item.companyname} > ${item.storename} > ${item.name}`)
      }

      const _setAbnormalStatus = function (item, camera) {
        item.abnormal = camera.abnormal
        if (
          __guard__(item.abnormal != null ? item.abnormal.heatmap : undefined, (x) => x.count) < 0
        ) {
          item.abnormal.heatmap.count = "-"
        }
        if (
          __guard__(item.abnormal != null ? item.abnormal.counter : undefined, (x1) => x1.count) < 0
        ) {
          item.abnormal.counter.count = "-"
        }
        //remove Tash camera from abnormal list
        if (item.companyname === "TRASH") {
          item.abnormal.status = false
        }

        if (item.offset != null) {
          if (item.abnormal != null ? item.abnormal.lastNotification : undefined) {
            item.lastNotification = _getLocalTime(
              item.abnormal != null ? item.abnormal.lastNotification : undefined,
              item.offset
            )
          }
          if (item.abnormal != null ? item.abnormal.updated : undefined) {
            return (item.statusChanged = _getLocalTime(
              item.abnormal != null ? item.abnormal.updated : undefined,
              item.offset
            ))
          }
        }
      }

      const setCameraInfo = function (item, callback) {
        if (item.accessKey == null) {
          item.accessKey = item.uid
          item.registered = _isRegistered(item.group)
          item.fwversion = __guard__(
            __guard__(
              item.description != null ? item.description.properties : undefined,
              (x1) => x1.firmware
            ),
            (x) => x.version
          )
          item.offset = __guard__(
            item.description != null ? item.description.properties : undefined,
            (x2) => x2.timezone.offset
          )
          if (item.offset) {
            item.lastaccesstime = _getLocalTime(
              __guard__(item.status != null ? item.status.heartbeat : undefined, (x3) => x3.at),
              item.offset
            )
          }

          const camera = _.find($scope.cameras, { accessKey: item.accessKey })
          if (camera != null) {
            const store = _.find($scope.stores, {
              _id: camera != null ? camera._storeId : undefined,
            })
            if (store != null) {
              const company = _.find($scope.companies, {
                _id: store != null ? store._companyId : undefined,
              })
              if (company != null) {
                item.blockSnapshot = company.blockSnapshot != null ? company.blockSnapshot : false
              }
            }
            item.name = camera.name
            item._id = item.cid = camera._id
            item.sid = camera._storeId
            item.hasLicense = camera.hasLicense
            item.functionalities = camera.functions.use
            _makeOrganizationName(item)
            _setAbnormalStatus(item, camera)
          }

          _setDurationString(item)
          if (!item.snapshotUrl) {
            item.snapshotUrl = getSnapshotUrl(item, ServiceTokenSrv, $rootScope.globalConfig)
          }
        }

        return callback()
      }

      var loadItems = function (items) {
        if (items != null) {
          $scope.Items = items
        }

        async.each(
          $scope.Items,
          function (item, callback) {
            delete item.rawconfig
            return setCameraInfo(item, callback)
          },
          function (err) {
            if ($scope.tableParams != null) {
              return $scope.tableParams.reload()
            }
          }
        )

        if ($scope.tableParams != null) {
          return
        }

        const _getData = function ($defer, params) {
          let filter = params.filter()
          const sorting = params.sorting()

          const _removeNonamefilter = function (orgfilter) {
            const newfilter = angular.copy(orgfilter)
            for (var f in newfilter) {
              var v = newfilter[f]
              if (v === "") {
                delete newfilter[f]
              }
            }
            return newfilter
          }

          filter = _removeNonamefilter(filter)

          const firstItems = $scope.Items.filter(function (item) {
            if (!item.orgname) {
              return false
            }
            if ($scope.isAbnormalCameras) {
              return item.abnormal != null ? item.abnormal.status : undefined
            } else {
              return item.registered
            }
          })

          $scope.nAbnormalCameras = $scope.isAbnormalCameras ? firstItems.length : "?"

          const filterItems = filter ? $filter("filter")(firstItems, filter) : firstItems

          params.total(filterItems.length)
          const sortingkey = Object.keys(sorting)
          const sortingvalue = sorting[sortingkey]
          const orderItems = $filter("orderBy")(filterItems, sortingkey, sortingvalue === "desc")

          const start = (params.page() - 1) * params.count()
          const end = start + params.count()
          return $defer.resolve(orderItems.slice(start, end))
        }

        const params = { count: 25, sorting: { orgname: "asc" } }
        const settings = { counts: [10, 25, 50, 100], getData: _getData }
        return ($scope.tableParams = new NgTableParams(params, settings))
      }

      $scope.reload = function (isCache) {
        if (isCache == null) {
          isCache = false
        }
        let devices = []
        return async.parallel(
          [
            (callback) =>
              ApiSrv.getAllAgency({ isCache: isCache }).then(function (res) {
                $scope.agencies = res
                return callback(null)
              }),

            (callback) =>
              ApiSrv.getAllCompany({ isCache: isCache }).then(function (res) {
                $scope.companies = res
                _.map(res, (element) => (element.blockSnapshot = element.checkBlockedSnapshot(me)))
                return callback(null)
              }),

            (callback) =>
              ApiSrv.getAllStore({ isCache: isCache }).then(function (res) {
                $scope.stores = res
                return callback(null)
              }),

            (callback) =>
              ApiSrv.getAllCamera({ isCache: isCache }).then(function (res) {
                $scope.cameras = res
                return callback(null)
              }),

            (callback) =>
              IotAdminSrv.getAllDevice().then(function (res) {
                devices = res
                return callback(null)
              }),
          ],
          (err, results) => loadItems(devices)
        )
      }

      return $scope.reload(true)
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
