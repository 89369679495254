/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .filter("activeNumber", function ($filter) {
    const number = $filter("number")
    return function (value) {
      if (value % 1 === 0) {
        return number(value, 0)
      } else if (Math.abs(value) < 10) {
        return number(value, 1)
      } else {
        return number(value, 0)
      }
    }
  })
