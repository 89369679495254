/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory("NotificationSrv", function ($rootScope, $resource) {
    const Id = $resource(
      $rootScope.globalConfig.apipath + "/notification/:id",
      { id: this.id },
      {
        update: {
          method: "POST",
        },
      }
    )

    const Comapny = $resource(
      $rootScope.globalConfig.apipath + "/company/:id/notification",
      { id: this.id },
      {
        update: {
          method: "POST",
        },
      }
    )

    const Store = $resource(
      $rootScope.globalConfig.apipath + "/store/:id/notification",
      { id: this.id },
      {
        show: {
          method: "GET",
        },
        update: {
          method: "POST",
        },
      }
    )

    // Camera = $resource $rootScope.globalConfig.apipath + '/camera/:id/notification',
    //   id: @id
    // , update:
    //     method: 'POST'

    return {
      Id,
      Company: Comapny,
      Store,
      //Camera : Camera
    }
  })
