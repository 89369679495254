/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

import Promise from "bluebird"

const App = angular.module("uCountitUiApp")
App.controller(
  "StoreGroupCtrl",
  function (
    $scope,
    $uibModal,
    $filter,
    $window,
    Auth,
    ApiSrv,
    Locale,
    NgTableParams,
    StoreGroupSrv,
    HeaderSrv,
    RestApi,
    UtilSrv
  ) {
    const vm = this
    Auth.fetchCurrentAccount().then((me) => (vm.me = me))
    vm.header = {
      company: Locale.string("Company"),
      groups: Locale.string("Store Group"),
      stores: Locale.string("Stores"),
    }
    vm.searchKeywords = ""
    vm.Items = ""
    vm.orderItems = ""

    vm.isDefaultStoreGroup = UtilSrv.isDefaultStoreGroup
    vm.displayGroupName = UtilSrv.getDisplayStoreGroupName

    vm.enterKeyword = function (keyword) {
      vm.searchKeywords = keyword
      return $scope.tableReload()
    }

    $scope.dataReload = (isCache, callback) =>
      Promise.all([HeaderSrv.fetchCurrentCompany(), ApiSrv.getAllStoreGroup({ isCache })])
        .spread(function (company, storeGroups) {
          storeGroups = $filter("filter")(
            storeGroups,
            (group) => $scope.showSelectedOrganization === false || company._id === group._companyId
          )
          return Promise.all(
            storeGroups.map((group) =>
              RestApi.Company.get(group._companyId).then(function (company) {
                group.companyName = company.name
                vm.Items = storeGroups
                vm.orderItems = ""
                if (callback) {
                  callback()
                }
                return $scope.tableReload()
              })
            )
          )
        })
        .catch((err) => console.warn("getAllStoreGroup:", err))

    $scope.$watch("showSelectedOrganization", (newData) => $scope.dataReload(true))

    $scope.$on("reload_storegroup", () => $scope.dataReload(false))

    const _getData = function ($defer, params) {
      if (vm.orderItems === "") {
        vm.orderItems = vm.Items
      }
      const sorting = params.sorting()
      for (var key in sorting) {
        var reverse = sorting[key] === "desc"
        vm.orderItems = $filter("orderBy")(vm.orderItems, key, reverse)
      }

      vm.filterItems = $filter("filter")(vm.orderItems, function (val) {
        const str = new RegExp(`.*${vm.searchKeywords}.*`, "i")

        return (
          __guard__(val != null ? val.companyName : undefined, (x) => x.match(str)) != null ||
          __guard__(val != null ? val.name : undefined, (x1) => x1.match(str)) != null
        )
      })

      params.total(vm.filterItems.length)

      const start = (params.page() - 1) * params.count()
      const end = start + params.count()
      const resultItems = vm.filterItems.slice(start, end)
      return $defer.resolve(resultItems)
    }

    $scope.tableReload = () => vm.tableParams.reload()

    $scope.openForm = (group) =>
      $uibModal.open({
        animation: false,
        templateUrl: "app/admin/store/group/group-form.html",
        controller: "StoreGroupFormCtrl",
        backdrop: "static",
        windowClass: "modal-window",
        scope: $scope,
      })

    vm.removeGroup = function (item) {
      if ($window.confirm(Locale.string("msg_confirm_remove_group"))) {
        return StoreGroupSrv.remove(
          { id: item._id },
          (res) => $scope.dataReload(false),
          function (err) {
            console.error(`removeGroup Error: ${err}`)
            throw err
          }
        )
      }
    }

    vm.tableParams = new NgTableParams(
      { page: 1, count: 25, sorting: { name: "asc", companyName: "asc" } },
      { counts: [10, 25, 50, 100], getData: _getData }
    )
    $scope.dataReload()
  }
)

App.controller(
  "StoreGroupFormCtrl",
  function (
    $scope,
    $uibModalInstance,
    $window,
    orderByFilter,
    StoreGroupSrv,
    RestApi,
    HeaderSrv,
    UtilSrv
  ) {
    $scope.UtilSrv = UtilSrv
    let prepare = Promise.resolve()
    if (!$scope.companies) {
      prepare = RestApi.Company.index().then(
        (companies) => ($scope.companies = companies.map((c) => c.data))
      )
    }

    return prepare.then(function () {
      $scope.companies = orderByFilter($scope.companies, "name")
      $scope.form = {}
      if ($scope.currentGroup) {
        $scope.form.name = $scope.currentGroup.name
      } else {
        $scope.form.selectedCompany = $scope.companies[0]
      }

      const original = angular.copy($scope.form)

      $scope.canSubmit = () => $scope.form_create.$valid && !angular.equals($scope.form, original)

      $scope.cancel = () => $uibModalInstance.dismiss("cancel")

      $scope.add = () =>
        StoreGroupSrv.save(
          _.merge($scope.form, { companyId: $scope.form.selectedCompany }),
          function (group, res) {
            $scope.dataReload(false, () => HeaderSrv.emitter.reload())
            return $uibModalInstance.close("success")
          },
          function (err) {
            if (err.status === 409) {
              return console.error("Duplicate Error")
            } else {
              return console.error("internal Error")
            }
          }
        )

      return ($scope.modify = function () {
        const params = angular.copy($scope.form)
        params.id = $scope.currentGroup._id

        return StoreGroupSrv.update(
          params,
          function (group, res) {
            $uibModalInstance.close("success")
            return ($scope.currentGroup.name = params.name)
          },

          function (err) {
            if (err.status === 409) {
              return console.error("Duplicate Error")
            } else {
              return console.error("internal Error")
            }
          }
        )
      })
    })
  }
)

App.controller(
  "StoreGroupManageCtrl",
  function (
    $scope,
    $stateParams,
    $uibModal,
    $window,
    Locale,
    StoreGroupSrv,
    NgTableParams,
    ApiSrv,
    UtilSrv
  ) {
    const vm = this
    vm.companyId = $stateParams.companyId
    vm.groupId = $stateParams.groupId
    vm.header = {
      store: Locale.string("Store Name"),
      address: Locale.string("Address"),
      timezone: Locale.string("Timezone"),
    }

    vm.isDefaultStoreGroup = UtilSrv.isDefaultStoreGroup
    vm.displayGroupName = UtilSrv.getDisplayStoreGroupName

    vm.back = () => $window.history.back()

    const _getData = function ($defer, params) {
      StoreGroupSrv.get(
        {
          _: Date.now(),
          id: vm.groupId,
        },
        function (group) {
          vm.currentGroup = group
          _reloadStore()
          return StoreGroupSrv.getMember(
            {
              _: Date.now(),
              id: vm.groupId,
            },
            (members) => $defer.resolve(_.sortBy(members, "name"))
          )
        },
        function (err) {
          if (err) {
            return $defer.reject(err)
          }
        }
      )
    }

    var _reloadStore = () =>
      ApiSrv.getAllStore({ isCache: false }).then(
        function (stores) {
          const ableStores = _.reduce(
            stores,
            function (result, item) {
              if (
                item._companyId === vm.companyId &&
                (vm.currentGroup.storeList != null
                  ? vm.currentGroup.storeList.indexOf(item._id)
                  : undefined) < 0
              ) {
                result.push({
                  _id: item._id,
                  name: item.name,
                  address: item.address,
                  timezone: item.timezone,
                })
              }
              return result
            },
            []
          )

          vm.ableStores = _.sortBy(ableStores, "name")
          if (!_.isEmpty(vm.ableStores)) {
            return (vm.selectedStore = vm.ableStores[0])
          }
        },

        (err) => {
          throw err
        }
      )

    vm.addMember = function () {
      if (!vm.selectedStore) {
        return
      }

      return StoreGroupSrv.addMember(
        {
          id: vm.groupId,
          storeId: vm.selectedStore._id,
        },
        function (res) {
          _.remove(vm.ableStores, (item) => vm.selectedStore === item)
          vm.selectedStore = _.first(vm.ableStores)

          return vm.tableParams.reload()
        },
        function (err) {
          throw err
        }
      )
    }

    vm.removeMember = function (storeId) {
      if (!storeId) {
        return
      }
      return StoreGroupSrv.removeMember(
        {
          id: vm.groupId,
          storeId,
        },
        (res) => vm.tableParams.reload()
      )
    }

    vm.editGroup = function () {
      $scope.currentGroup = _.merge(vm.currentGroup, { companyName: vm.company.name })

      return $uibModal.open({
        animation: false,
        templateUrl: "app/admin/store/group/group-form.html",
        controller: "StoreGroupFormCtrl",
        backdrop: "static",
        windowClass: "modal-window",
        scope: $scope,
      })
    }

    return async.waterfall(
      [
        (callback) =>
          ApiSrv.getCompany({ id: vm.companyId }).then(
            (res) => callback(null, res),
            (err) => callback(err)
          ),
      ],
      function (err, company) {
        if (err) {
          console.error(`getCompany Error: ${err}`)
          return
        }

        vm.company = company
        return (vm.tableParams = new NgTableParams({}, { counts: [], getData: _getData }))
      }
    )
  }
)

App.controller("StoreGroupAccessCtrl", function ($scope, $stateParams, $window, ApiSrv) {
  //  $scope.ablePermissions = [ 'read', 'admin' ]
  $scope.ablePermissions = ["read"]
  $scope.back = () => $window.history.back()

  return ApiSrv.getStoreGroup({ id: $stateParams.groupId })
    .then((res) => ($scope.store = res))
    .catch(function (err) {
      console.error(err)
      return Promise.reject(err)
    })
})

App.controller(
  "StoreGroupAccessAccountsCtrl",
  function ($scope, $stateParams, ApiSrv, NgTableParams, Locale, StoreGroupAccessSrv) {
    const vm = this
    vm.selectedPermission = $scope.ablePermissions[0]
    vm.header = {
      account: Locale.string("Account Name"),
      email: Locale.string("Email Address"),
    }
    vm.accountPlaceholder = Locale.string("choose an account")

    const _reloadList = function () {
      if (!vm.store) {
        return
      }
      return ApiSrv.getAllAccount({
        isCache: false,
      }).then(
        function (accounts) {
          const ableList = _.reduce(
            accounts,
            function (result, item) {
              if (
                item.role.orgs.indexOf(vm.store._companyId) >= 0 &&
                (item.role != null ? item.role.group : undefined) === "store" &&
                _.findIndex(vm.store.policy.access.accounts, { _accountId: item._id }) < 0
              ) {
                result.push({
                  _id: item._id,
                  email: item.email,
                  name: Locale.fullName(item),
                })
              }
              return result
            },
            []
          )

          vm.ableList = _.sortBy(ableList, "name")
          if (!_.isEmpty(vm.ableList)) {
            return (vm.selectedAccount = vm.ableList[0])
          }
        },
        (err) => {
          throw err
        }
      )
    }

    const _getData = function ($defer, params) {
      StoreGroupAccessSrv.get(
        {
          _: Date.now(),
          storeId: $stateParams.groupId,
        },
        function (access) {
          __guard__(vm.store != null ? vm.store.policy : undefined, (x) => (x.access = access))
          _reloadList()
          __guard__(access != null ? access.accounts : undefined, (x1) =>
            x1.forEach((item) => (item["fullName"] = Locale.fullName(item)))
          )
          return $defer.resolve(
            _.sortBy(access != null ? access.accounts : undefined, ["firstName", "lastName"])
          )
        },
        function (err) {
          console.error(`Failed get accounts from store access: ${err}`)
          //      $defer.reject err
          throw err
        }
      )
    }

    vm.add = function () {
      if (!vm.selectedAccount) {
        return
      }

      return StoreGroupAccessSrv.add(
        {
          storeId: $stateParams.groupId,
          accountId: vm.selectedAccount._id,
          permission: vm.selectedPermission,
          item: "account",
        },
        function (res) {
          _.remove(vm.ableList, (item) => vm.selectedAccount === item)
          vm.selectedAccount = _.first(vm.ableList)

          return vm.tableParams.reload()
        },
        (err) => {
          throw err
        }
      )
    }

    vm.setPermission = function (account, permission) {
      if (!account) {
        return
      }
      if (account.permission === permission) {
        return
      }
      return StoreGroupAccessSrv.setPermission(
        {
          storeId: $stateParams.groupId,
          id: account._accountId,
          permission,
          item: "account",
        },
        (res) => (account.permission = res.permission),
        (err) => {
          throw err
        }
      )
    }

    vm.remove = (accountId) =>
      StoreGroupAccessSrv.remove(
        {
          storeId: $stateParams.groupId,
          id: accountId,
          item: "account",
        },
        (res) => vm.tableParams.reload()
      )

    $scope.$watch("store", function (store) {
      if (!store) {
        return
      }
      vm.store = store
      return (vm.tableParams = new NgTableParams({}, { counts: [], getData: _getData }))
    })
  }
)

App.controller(
  "StoreGroupAccessGroupsCtrl",
  function ($scope, $stateParams, ApiSrv, NgTableParams, Locale, StoreGroupAccessSrv) {
    const vm = this
    vm.selectedPermission = $scope.ablePermissions[0]
    vm.header = { group: Locale.string("Group Name") }

    const _reloadList = function () {
      if (!vm.store) {
        return
      }

      return ApiSrv.getAllAccountGroup({
        isCache: false,
        companyId: vm.store._companyId,
      }).then(
        function (groups) {
          const ableList = _.reduce(
            groups,
            function (result, item) {
              if (
                _.findIndex(
                  __guard__(vm.store != null ? vm.store.policy : undefined, (x) => x.access.groups),
                  { _groupId: item._id }
                ) < 0
              ) {
                result.push({
                  _id: item._id,
                  name: item.name,
                  accountList: item.accountList,
                })
              }
              return result
            },
            []
          )

          vm.ableList = _.sortBy(ableList, "name")
          if (!_.isEmpty(vm.ableList)) {
            return (vm.selectedGroup = vm.ableList[0])
          }
        },
        (err) => {
          throw err
        }
      )
    }

    const _getData = function ($defer, params) {
      StoreGroupAccessSrv.get(
        {
          _: Date.now(),
          storeId: $stateParams.groupId,
        },
        function (access) {
          __guard__(vm.store != null ? vm.store.policy : undefined, (x) => (x.access = access))
          _reloadList()
          return $defer.resolve(_.sortBy(access != null ? access.groups : undefined, "name"))
        },
        (err) => {
          throw err
        }
      )
    }

    vm.add = function () {
      if (!vm.selectedGroup) {
        return
      }

      return StoreGroupAccessSrv.add(
        {
          storeId: $stateParams.groupId,
          groupId: vm.selectedGroup._id,
          permission: vm.selectedPermission,
          item: "group",
        },
        function (res) {
          _.remove(vm.ableList, (item) => vm.selectedGroup === item)
          vm.selectedGroup = _.first(vm.ableList)

          return vm.tableParams.reload()
        },
        (err) => {
          throw err
        }
      )
    }

    vm.setPermission = function (group, permission) {
      if (!group) {
        return
      }
      if (group.permission === permission) {
        return
      }
      return StoreGroupAccessSrv.setPermission(
        {
          storeId: $stateParams.groupId,
          id: group._groupId,
          permission,
          item: "group",
        },
        (res) => (group.permission = res.permission),
        (err) => {
          throw err
        }
      )
    }

    vm.remove = (groupId) =>
      StoreGroupAccessSrv.remove(
        {
          storeId: $stateParams.groupId,
          id: groupId,
          item: "group",
        },
        (res) => vm.tableParams.reload()
      )

    $scope.$watch("store", function (store) {
      if (!store) {
        return
      }
      vm.store = store
      return (vm.tableParams = new NgTableParams({}, { counts: [], getData: _getData }))
    })
  }
)

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
