import IoT from "common-library/types/IoT"

type Feature =
  | "counter"
  | "directionmap"
  | "dwellmap"
  | "edgeFace"
  | "face"
  | "heatmap"
  | "heatmaps"
  | "metacounter"
  | "occupancy"
  | "queue"
  | "rtFace"
  | "trackcounter"
  | "facecounter"
  | "trafficmap"
  | "zonetraffic"
  | "trackingtree"
  | "groupFace"

type Functionality =
  | "counter"
  | "meta"
  | "heatmap"
  | "face"
  | "edgeFace"
  | "queue"
  | "virtual"
  | "occupancy"

type FeatureDependency = {
  dependsFunctionality?: Functionality[] // OR conditions
  depends?: Feature[] // AND conditions.
  conflicts?: Feature[] // OR conditions

  dependsTrackingEngines?: IoT.TrackingEngine.Id[] // OR Condition, Empty for any tracking engine
}

const nonMetaConflicts: Feature[] = [
  "counter",
  "trackcounter",
  "metacounter",
  "heatmap",
  "heatmaps",
  "trafficmap",
  "dwellmap",
  "zonetraffic",
  "directionmap",
]

const metaConflicts: Feature[] = ["face", "occupancy", "queue"]

const CommonDependencies: {
  [k in
    | "flowmap"
    | "trackingtree"
    | "metacounter"
    | "trackcounter"
    | "facecounter"
    | "edgeFace"
    | "groupFace"]: FeatureDependency
} = {
  flowmap: {
    dependsFunctionality: ["virtual"],
  },
  trackingtree: {
    dependsFunctionality: ["virtual"],
  },
  metacounter: {
    dependsFunctionality: ["meta"],
    conflicts: ["facecounter", "trackcounter"],
    depends: ["counter"],
  },
  trackcounter: {
    dependsFunctionality: ["meta"],
    conflicts: ["facecounter", "metacounter"],
    depends: ["counter"],
  },
  facecounter: {
    dependsFunctionality: ["edgeFace"],
    conflicts: ["metacounter", "trackcounter"],
    depends: ["counter", "groupFace"],
  },
  edgeFace: {
    dependsFunctionality: ["edgeFace"],
    depends: ["face"],
    dependsTrackingEngines: ["dl_people_tracker"],
  },
  groupFace: {
    dependsFunctionality: ["edgeFace"],
    depends: ["edgeFace"],
  },
}

export const IPNDependencies: {
  [k in Feature]: FeatureDependency
} = {
  ...CommonDependencies,
  counter: {
    dependsFunctionality: ["counter"],
    conflicts: metaConflicts,
  },
  directionmap: {
    dependsFunctionality: ["meta"],
    conflicts: metaConflicts,
  },
  dwellmap: {
    dependsFunctionality: ["meta"],
    conflicts: metaConflicts,
  },
  face: {
    dependsFunctionality: ["face"],
    depends: [],
    conflicts: nonMetaConflicts.concat(["occupancy", "queue"]),
  },
  rtFace: {
    dependsFunctionality: ["face"],
    depends: ["face"],
  },
  heatmap: {
    dependsFunctionality: ["meta", "heatmap"],
    conflicts: metaConflicts,
  },
  heatmaps: {
    dependsFunctionality: ["meta"],
    conflicts: metaConflicts,
  },
  occupancy: {
    dependsFunctionality: ["queue", "occupancy"],
    conflicts: nonMetaConflicts.concat(["face", "queue"]),
  },
  queue: {
    dependsFunctionality: ["queue"],
    conflicts: nonMetaConflicts.concat(["face", "occupancy"]),
  },
  trafficmap: {
    dependsFunctionality: ["meta"],
    conflicts: metaConflicts,
  },
  zonetraffic: {
    dependsFunctionality: ["meta"],
    conflicts: metaConflicts,
  },
}

export const IPMDependencies: {
  [k in Feature]: FeatureDependency
} = {
  ...CommonDependencies,
  counter: {
    dependsFunctionality: ["counter"],
  },
  directionmap: {
    dependsFunctionality: ["meta"],
  },
  dwellmap: {
    dependsFunctionality: ["meta"],
  },
  face: {
    dependsFunctionality: ["face"],
  },
  heatmap: {
    dependsFunctionality: ["meta"],
  },
  heatmaps: {
    dependsFunctionality: ["meta"],
  },
  trafficmap: {
    dependsFunctionality: ["meta"],
  },
  zonetraffic: {
    dependsFunctionality: ["meta"],
  },
  occupancy: {
    dependsFunctionality: ["occupancy"],
  },
  queue: {
    dependsFunctionality: ["queue"],
  },
  rtFace: {
    dependsFunctionality: ["edgeFace"],
    depends: ["edgeFace"],
  },
}

export const VCAServerDependencies: {
  [k in Feature]: FeatureDependency
} = IPMDependencies
