/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory("StoreSalesSrv", function ($rootScope, $http) {
    const config = $rootScope.globalConfig
    const getSalesdata = function (options, callback) {
      if (!options.to || !options.from) {
        return callback("no date")
      }

      return $http
        .get(`${config.apipath}/sales/data/query`, {
          params: {
            storeId: options.storeId,
            storeGroupId: options.storeGroupId,
            companyId: options.companyId,
            from: options.from,
            to: options.to,
          },
        })
        .success((data) => callback(null, data))
    }

    const getWaiting = function (options, callback) {
      if (!options.workId) {
        return callback("no workId")
      }

      return $http
        .get(`${config.apipath}/sales/waitingdata/${options.workId}`)
        .success((data) => callback(null, data))
        .error((err) => callback(err))
    }

    const destroy = function (options, callback) {
      let filter
      if (!options.workId) {
        return callback("no workId")
      }
      switch (options.result) {
        case "ok":
          filter = "done"
          break
        case "cancel":
          filter = "waiting"
          break
        default:
          filter = "all"
      }

      return $http
        .delete(`${config.apipath}/sales/waitingdata/${options.workId}`, {
          params: {
            filter: filter,
          },
        })
        .success((data) => callback(null, data))
        .error((err) => callback(err))
    }

    const confirmWaiting = function (options, callback) {
      if (!options.workId) {
        return callback("no work id")
      }
      return $http
        .post(`${config.apipath}/sales/waitingdata/${options.workId}`, {
          dropItems: options.dropItems,
        })
        .success((data) => callback(null, data))
        .error((err) => callback(err))
    }

    const getCurrentStatus = function (options, callback) {
      if (!options.workId) {
        return callback("no workId")
      }

      return $http
        .get(`${config.apipath}/sales/waitingdata/${options.workId}/current`)
        .success((data) => callback(null, data))
        .error((err) => callback(err))
    }

    const getAllDrivers = (callback) =>
      $http
        .get(`${config.apipath}/sales/posdriver/`)
        .success((data) => callback(null, data))
        .error((err) => callback(err))

    return {
      getSalesdata,
      getWaiting,
      confirmWaiting,
      getCurrentStatus,
      getAllDrivers,
      destroy,
    }
  })
