"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

angular
  .module("uCountitUiApp")
  .directive(
    "genderOverviewPathtree",
    (
      $compile,
      $filter,
      Face,
      ChartOptionSrv,
      Locale,
      SamplingSrv,
      UtilSrv,
      usSpinnerService,
      ApiSrv
    ) => ({
      restrict: "E",
      templateUrl: "components/overview/gender/gender-overview-pathtree.html",

      scope: {
        orgType: "=",
        orgId: "=",
        referenceDate: "=",
        useFaceCountOnly: "=",
        viewTemplate: "=?", // default ""
      },

      replace: true,

      link(scope, element, attrs) {
        let refDate = ""
        let tomorrow = ""
        let lastWeekAgo = ""
        let lastWeekAgoPlus = ""
        let threeMonthsAgo = ""
        const loadingQueue = []

        const pieChartOption = {
          series: {
            pie: {
              show: true,
              radius: 45,
              label: {
                show: true,
                radius: 0.5,
              },
            },
          },
          legend: {
            show: false,
          },
          grid: {
            hoverable: true,
          },
        }
        const isNumber = (num) => !isNaN(parseFloat(num)) && isFinite(num)

        const showLoading = function (key) {
          if (key) {
            loadingQueue.push(key)
          }
          angular.element(".splash").show()
          return usSpinnerService.spin("spinner")
        }

        const hideLoading = function (key) {
          if (key) {
            _.remove(loadingQueue, (raw) => raw === key)
            if (loadingQueue.length) {
              return
            }
          }

          angular.element(".splash").hide()
          return usSpinnerService.stop("spinner")
        }

        const setPieChartOptions = function (chartData) {
          const unit = scope.useFaceCountOnly ? "%" : ""
          const chartOption = _.merge({}, pieChartOption)
          chartOption.series.pie.label.formatter = function (label, series) {
            label = $filter("number")(series.data[0][1]) + unit
            if (!scope.useFaceCountOnly) {
              label += "<br /> (" + series.percent.toFixed(1) + "%)"
            }

            return label
          }

          return chartOption
        }

        const setLineChartOptions = function (from, to, data) {
          scope.lineChartOption = _.merge({}, ChartOptionSrv.contentChartOption, {
            series: {
              lines: {
                show: true,
              },
              points: {
                radius: 0.5,
                fill: true,
                show: true,
              },
              shadowSize: 0,
            },
            yaxis: {
              autoscaleMargin: 0,
            },
          })

          const sampling = "month"
          const tickSize = SamplingSrv[sampling].getTickSize(from, to)
          const minTickSize = SamplingSrv[sampling].getMinTickSize()

          const maxVal = _.maxBy(
            scope.threeMonthChartData.map((value) => _.maxBy(value.data, (o) => o[1])[1])
          )
          const unit = scope.useFaceCountOnly ? "%" : ""
          const decimal = scope.useFaceCountOnly ? 1 : 0

          scope.lineChartOption.yaxis.ticks = [[0, "0"], [maxVal / 2, ""], [maxVal]]
          scope.lineChartOption.yaxis.tickFormatter = (val, axis) => `${val}${unit}`

          scope.lineChartOption.xaxis.tickFormatter = (val, axis) =>
            Locale.dateTime(moment.utc(val), "month")

          scope.lineChartOption.xaxis.tickSize = [tickSize, sampling]
          scope.lineChartOption.xaxis.minTickSize = [minTickSize, sampling]

          return (scope.lineChartOption.tooltip.content = function (label, x, y, item) {
            const tooltipDateFormat = SamplingSrv.day.getFormat()
            const dataIdx = item.dataIndex
            let contents = `${Locale.dateTime(moment.utc(x), tooltipDateFormat)}<br>`

            scope.threeMonthChartData.forEach(function (chart, idx) {
              const value = scope.useFaceCountOnly
                ? data[dataIdx].percent[chart.label]
                : data[dataIdx][chart.label]

              const str = isNumber(value) ? $filter("number")(value, decimal) + unit : value
              return (contents += [
                `<svg width=10 height=10><rect width=5 height=10 fill=${chart.color}></svg>`,
                `${Locale.string(chart.label)} : `,
                `<b>${str || ""}</b><br>`,
              ].join(""))
            })

            return contents
          })
        }

        const drawStartMonthLineOnChart = function (from, to) {
          const startLines = []
          let i = 1

          for (;;) {
            var date = moment.utc(from).startOf("month").add(i, "month")

            if (date.isAfter(to)) {
              break
            }
            var startLine = {
              color: "#ddd",
              lineWidth: 1,
              xaxis: {
                from: date,
                to: date,
              },
            }

            startLines.push(startLine)
            i++
          }

          return (scope.lineChartOption.grid.markings = startLines)
        }

        const generateCountDataByGender = function (genderData) {
          if (!(genderData != null ? genderData.length : undefined)) {
            return null
          }

          const femaleList = []
          const maleList = []
          let femaleTotal = 0
          let maleTotal = 0
          let validDataCount = 0

          genderData.forEach(function (gender, idx) {
            let female = scope.useFaceCountOnly ? gender.percent.female : gender.female
            let male = scope.useFaceCountOnly ? gender.percent.male : gender.male

            if (isNumber(female) && isNumber(male)) {
              femaleTotal += female
              maleTotal += male
              validDataCount += 1
            } else {
              female = 0
              male = 0
            }

            femaleList.push([gender.date, female])
            return maleList.push([gender.date, male])
          })

          const femaleAvg = (femaleTotal / validDataCount).toFixed(1)
          const maleAvg = (maleTotal / validDataCount).toFixed(1)

          return {
            femaleList,
            maleList,
            femaleTotal,
            maleTotal,
            femaleAvg,
            maleAvg,
          }
        }

        const getValidationData = function (list) {
          const sum = _.sumBy(list, "data")
          if (sum !== 0) {
            list.reverse()
            return list
          } else {
            return []
          }
        }

        const initData = function () {
          scope.lastDayofWeekChartData = []
          scope.lastDayofWeekChartOption = {}
          scope.refDateChartData = []
          scope.refDateChartOption = {}
          scope.threeMonthChartData = []
          scope.lineChartOption = {}

          scope.compare = {
            weekly: {
              female: "-",
              male: "-",
            },
            ref: {
              female: "-",
              male: "-",
            },
          }
          scope.showComparisonWeeklyRatio = false
          return (scope.showComparisonRatio = false)
        }

        const DT12Format = "YYYYMMDD0000"
        const getDateStayTime = function (arrStayTime, date) {
          const dtStr = date.format(DT12Format)
          const dt = arrStayTime?.find((d) => d.at === dtStr)
          return dt?.data
        }

        const updateData = function () {
          showLoading("gender_data")

          const options = {
            id: scope.orgId,
            selectedSampling: "day",
            orgType: scope.orgType,
          }

          return async.parallel(
            {
              lastDayofWeekData(callback) {
                const opt = _.merge({}, options, {
                  from: lastWeekAgo,
                  to: lastWeekAgoPlus,
                })
                return Face.getGenderData(opt, scope.useFaceCountOnly).then(
                  (counters) => callback(null, counters),
                  (err) => callback(err)
                )
              },
              refDateData(callback) {
                const opt = _.merge({}, options, {
                  from: refDate,
                  to: tomorrow,
                })
                return Face.getGenderData(opt, scope.useFaceCountOnly).then(
                  (counters) => callback(null, counters),
                  (err) => callback(err)
                )
              },
              threeMonthsAgoData(callback) {
                const opt = _.merge({}, options, { from: threeMonthsAgo, to: tomorrow })
                return Face.getGenderData(opt, scope.useFaceCountOnly).then(
                  (counters) => callback(null, counters),
                  (err) => callback(err)
                )
              },
              lastDayofWeekStayData(callback) {
                if (scope.viewTemplate !== "pathtree") {
                  return callback(null, null)
                }
                const option = {
                  orgType: scope.orgType,
                  id: scope.orgId,
                  sampling: "1d",
                  from: lastWeekAgo.format(DT12Format),
                  to: lastWeekAgoPlus.format(DT12Format),
                }
                ApiSrv.getPathTreeStayData(option)
                  .then((d) => callback(null, d))
                  .catch((err) => callback(err))
              },
              refStayData(callback) {
                if (scope.viewTemplate !== "pathtree") {
                  return callback(null, null)
                }
                const option = {
                  orgType: scope.orgType,
                  id: scope.orgId,
                  sampling: "1d",
                  from: refDate.format(DT12Format),
                  to: tomorrow.format(DT12Format),
                }
                ApiSrv.getPathTreeStayData(option)
                  .then((d) => callback(null, d))
                  .catch((err) => callback(err))
              },
            },
            function (err, results) {
              if (err || !results?.threeMonthsAgoData?.length) {
                hideLoading("gender_data")
                initData()
                return
              }
              const ldowStayTime = getDateStayTime(results.lastDayofWeekStayData?.data, lastWeekAgo)
              scope.lastDoWDateFemaleStayTime = Locale.duration(
                ldowStayTime?.female.stayTime,
                "short"
              )
              scope.lastDowDateMaleStayTime = Locale.duration(ldowStayTime?.male.stayTime, "short")
              const refStayTime = getDateStayTime(results.refStayData?.data, refDate)
              scope.refDateFemaleStayTime = Locale.duration(refStayTime?.female.stayTime, "short")
              scope.refDateMaleStayTime = Locale.duration(refStayTime?.male.stayTime, "short")

              const lastDayofWeekData = generateCountDataByGender(results.lastDayofWeekData)
              const refDateData = generateCountDataByGender(results.refDateData)

              const threeMonthsData = results.threeMonthsAgoData
              const threeMonthsAgoData = generateCountDataByGender(results.threeMonthsAgoData)

              const lastDayofWeekChartData = []
              const refDateChartData = []
              const threeMonthChartData = []
              const compare = {
                ref: {
                  female: 0,
                  male: 0,
                },
              }
              Face.genderList.forEach(function (gender) {
                let dowCnt = 0
                const genderType = gender.name
                if (lastDayofWeekData) {
                  dowCnt = _.last(lastDayofWeekData[`${genderType}List`])[1]
                  if (isNumber(dowCnt)) {
                    lastDayofWeekChartData.push({
                      label: genderType,
                      data: dowCnt,
                      color: gender.color,
                    })
                  }
                }
                let refCnt = 0
                if (refDateData) {
                  refCnt = _.last(refDateData[`${genderType}List`])[1]
                  if (isNumber(refCnt)) {
                    refDateChartData.push({
                      label: genderType,
                      data: refCnt,
                      color: gender.color,
                    })
                  }
                }
                const crs = UtilSrv.getComparisonResult(dowCnt, refCnt)
                compare.ref[genderType] = crs?.comparisonRatio

                if (threeMonthsAgoData) {
                  threeMonthChartData.push({
                    label: genderType,
                    data: threeMonthsAgoData[`${genderType}List`],
                    color: gender.color,
                  })
                }
              })

              scope.compare = compare

              scope.lastDayofWeekChartData = getValidationData(lastDayofWeekChartData)
              scope.refDateChartData = getValidationData(refDateChartData)
              scope.threeMonthChartData = threeMonthChartData

              scope.showComparisonRatio =
                scope.lastDayofWeekChartData.length && scope.refDateChartData.length

              scope.lastDayofWeekChartOption = setPieChartOptions(scope.lastDayofWeekChartData)
              scope.refDateChartOption = setPieChartOptions(scope.refDateChartData)
              const from = threeMonthsAgo
              const to = scope.referenceDate
              setLineChartOptions(from, to, threeMonthsData)
              drawStartMonthLineOnChart(from, to)

              return hideLoading("gender_data")
            }
          )
        }

        return scope.$watch("referenceDate", function (newDate) {
          if (newDate) {
            refDate = moment(newDate)
            tomorrow = moment(newDate).add(1, "day")
            lastWeekAgo = moment(newDate).subtract(1, "week")
            lastWeekAgoPlus = lastWeekAgo.clone().add(1, "day")
            threeMonthsAgo = moment(newDate).subtract(4, "week").add(1, "day")

            scope.refDateStr = Locale.dateTime(refDate, "date")
            scope.oneWeekAgoStr = Locale.dateTime(lastWeekAgo, "date")
            return updateData()
          }
        })
      },
    })
  )
