/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS201: Simplify complex destructure assignments
 * DS202: Simplify dynamic range loops
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"

import Promise from "bluebird"
import { addLabelInfo, kStandardLabels } from "../counter-label"
import env from "../../../config/environment"

angular
  .module("uCountitUiApp")
  .factory(
    "ApiSrv",
    function ($rootScope, $resource, $q, $cacheFactory, $http, Auth, RestApi, HeaderSrv) {
      const cache = $cacheFactory("apiCache")
      const config = $rootScope.globalConfig
      const uiApiBase = config.apipath

      const _getWeekType = function () {
        const firstDay = moment().startOf("week").isoWeekday()
        if (firstDay === 1) {
          return "isoWeek"
        } else {
          return "week"
        }
      }

      const _getSamplingString = function (key) {
        let sampling, startOfString
        switch (key.toLowerCase()) {
          case "1m":
            startOfString = "month"
            sampling = 30 * 7 * 24 * 3600
            break
          case "1w":
            startOfString = _getWeekType()
            sampling = 7 * 24 * 3600
            break
          case "1d":
            startOfString = "day"
            sampling = 24 * 3600
            break
          case "1h":
            startOfString = "hour"
            sampling = 3600
            break
          default:
            return
        }
        return [startOfString, sampling]
      }

      const _adjustCounterTimebySampling = function (res, header, status) {
        if (status !== 200) {
          return $q.reject(res)
        }
        const jdata = angular.fromJson(res)
        if ((jdata != null ? jdata.data : undefined) == null || !jdata.data.length) {
          return jdata
        }

        // eslint-disable-next-line no-unused-vars
        const [samplingString, samplingTime] = Array.from(_getSamplingString(jdata.sampling))

        jdata.data.forEach(
          (od) => (od.statisticFor = moment(od.statisticFor).utc().startOf(samplingString).format())
        )
        return jdata
      }

      const _countForCompany = (companyId) =>
        HeaderSrv.fetchCurrentCompany().then(function (company) {
          config.companyId = company._id
          return $resource(uiApiBase + "/company/" + config.companyId + "/:item/query", {
            item: "@item",
          })
        })

      const _countForStore = () =>
        $resource(
          uiApiBase + "/store/:group/:id/:item/query",
          {
            group: "@group",
            id: "@id",
            item: "@item",
          },
          {
            get: {
              transformResponse: _adjustCounterTimebySampling,
            },
          }
        )

      const _countForStoreGroup = () =>
        $resource(uiApiBase + "/store/group/:id/:item/query", {
          id: "@id",
          item: "@item",
        })

      const _countForCamera = () =>
        $resource(
          uiApiBase + "/camera/:id/:item/query",
          {
            id: "@id",
            item: "@item",
          },
          {
            get: {
              transformResponse: _adjustCounterTimebySampling,
            },
          }
        )

      const _faceForStore = () => $resource(uiApiBase + "/store/:id/age/query", { id: "@id" })

      const _faceCameraForStore = () =>
        $resource(
          uiApiBase + "/store/:id/camera",
          {
            id: "@id",
            features: "face",
          },
          {
            get: {
              isArray: true,
            },
          }
        )

      const FootfallConfigOfCamera = () =>
        $resource(
          uiApiBase + "/camera/:id/footfall",
          { id: "@id" },
          { set: { method: "PUT", isArray: true } }
        )

      const _heatmapOfCamera = () =>
        $resource(
          uiApiBase + "/camera/:id/heatmap",
          { id: "@id" },
          {
            get: {
              isArray: true,
            },
          }
        )

      const _getHeatmapOfCamera = (
        params,
        callback // add zero filled data if d.data has zero data
      ) =>
        _heatmapOfCamera().get(params, (res) =>
          callback(
            res != null
              ? res.map(function (d) {
                  if (d.cols * d.rows && !d.average && !d.data.length) {
                    d.data = new Array(d.cols * d.rows).fill(0)
                  }
                  return d
                })
              : undefined
          )
        )

      const _dwellmapOfCamera = () =>
        $resource(
          uiApiBase + "/camera/:id/heatmap/dwell",
          { id: "@id" },
          {
            get: {
              isArray: true,
            },
          }
        )

      const _roiHeatmapOfCamera = () =>
        $resource(
          uiApiBase + "/camera/:id/heatmap/roi",
          { id: "@id" },
          {
            get: {
              isArray: true,
            },
          }
        )

      const _directionmapOfCamera = () =>
        $resource(uiApiBase + "/camera/:id/directionmap", { id: "@id" }, { get: { isArray: true } })

      const _directionConfigOfCamera = () =>
        $resource(uiApiBase + "/camera/:id/directions", { id: "@id" }, { set: { method: "PUT" } })

      const _occupancyOfCamera = () =>
        $resource(uiApiBase + "/camera/:id/occupancy", { id: "@id" }, { get: { isArray: false } })

      const _occupancyOfStore = () =>
        $resource(uiApiBase + "/store/:id/occupancy", { id: "@id" }, { get: { isArray: false } })

      const _getSpaces = () =>
        $resource(
          uiApiBase + "/me/space",
          {},
          {
            get: {
              isArray: true,
            },
          }
        )

      const _getReportList = () =>
        $resource(
          uiApiBase + "/reports?companyId=:companyId&from=:from&to=:to&period=:period",
          {},
          {
            get: {
              isArray: true,
            },
          }
        )

      const _getReportBuildStatus = () =>
        $resource(
          uiApiBase + "/reports/buildStatus",
          {},
          {
            get: {
              isArray: true,
            },
          }
        )

      const _findSpace = () => $resource(uiApiBase + "/space/:id", { id: "@id" })

      const _totalOfCamera = () =>
        $resource(uiApiBase + "/camera/:id/contents/:item/total", {
          id: "@id",
          item: "@item",
        })

      const clearCache = function () {
        cache.removeAll()
        if ($cacheFactory.get("$http")) {
          return $cacheFactory.get("$http").removeAll()
        }
      }
      //    cache.destroy()

      const getDefaultPath = () => $rootScope.globalConfig.apipath

      // getValue() is not defined
      // const sampleFunction = function (options) {
      //   if (options == null) {
      //     options = { isCache: true }
      //   }
      //   const deffered = $q.defer()
      //   const params = _.clone(options || {})

      //   delete params["isCache"]
      //   const cacheId = "SampleFunction-" + JSON.stringify(params)
      //   const cacheData = cache.get(cacheId)
      //   if (cacheData && (!options || options.isCache !== false)) {
      //     deffered.resolve(cacheData)
      //     return deffered.promise
      //   }

      //   if (options.isCache === false) {
      //     params._ = Date.now()
      //   }
      //   getValue(params, function (res) {
      //     const result = 1
      //     cache.put(cacheId, result)
      //     return deffered.resolve(result)
      //   })
      //   return deffered.promise
      // }

      const getAllSpace = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        return Auth.fetchCurrentAccount().then(function (me) {
          const params = _.clone(options || {})

          delete params["isCache"]
          const cacheId = "getAllSpace-" + JSON.stringify(params) + me._id
          const cacheData = cache.get(cacheId)
          if (cacheData && (!options || options.isCache !== false)) {
            return cacheData
          }

          if (options.isCache === false) {
            params._ = Date.now()
          }
          return _getSpaces()
            .get(params)
            .$promise.then(function (res) {
              cache.put(cacheId, res)
              return res
            })
            .catch((err) => Promise.reject(err))
        })
      }

      const getReportList = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        const deffered = $q.defer()
        const params = _.clone(options || {})
        params.from = params.from.format("YYYY-MM-DD")
        params.to = params.to.format("YYYY-MM-DD")

        _getReportList().get(
          params,
          (
            res //cache.put cacheId, res
          ) => deffered.resolve(res),

          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const createIndexFunc = (Api) =>
        function (options) {
          if (options == null) {
            options = { isCache: true }
          }
          const { isCache } = options
          delete options["isCache"]
          return Api.index(isCache, { params: options })
        }

      const getAllAgency = createIndexFunc(RestApi.Agency)
      const getAllCompany = createIndexFunc(RestApi.Company)
      const getCompanies = createIndexFunc(RestApi.Company)
      const getAllStore = createIndexFunc(RestApi.Store)
      const getAllCamera = createIndexFunc(RestApi.Sensor)
      const getAllAccount = createIndexFunc(RestApi.Account)
      const getAllStoreGroup = createIndexFunc(RestApi.StoreGroup)
      const getAllAccountGroup = createIndexFunc(RestApi.AccountGroup)

      const getReportBuildStatus = function () {
        const deffered = $q.defer()

        const params = {}
        _getReportBuildStatus().get(
          params,
          (
            res //cache.put cacheId, res
          ) => deffered.resolve(res),

          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getSpace = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        const deffered = $q.defer()
        const params = _.clone(options || {})

        delete params["isCache"]
        const cacheId = "getSpace-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        if (options.isCache === false) {
          params._ = Date.now()
        }
        _findSpace().get(
          params,
          function (res) {
            cache.put(cacheId, res)
            return deffered.resolve(res)
          },

          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getAgency = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        return RestApi.Agency.get(options.id, options.isCache)
      }
      const getCompany = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        return RestApi.Company.get(options.id, options.isCache)
      }
      const getStore = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        return RestApi.Store.get(options.id, options.isCache)
      }
      const getStoreGroup = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        return RestApi.StoreGroup.get(options.id, options.isCache)
      }
      const getCamera = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        return RestApi.Sensor.get(options.id, options.isCache)
      }
      const getAccount = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        return RestApi.Account.get(options.id, options.isCache)
      }

      const getCameraOfCompany = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        const opt = _.merge({}, options)
        delete opt.attachStore
        return getAllCamera(opt)
          .then(function (sensors) {
            if (options.attachStore) {
              return getAllStore(opt).then((stores) => [sensors, stores])
            } else {
              return [sensors, []]
            }
          })
          .then(function (...args) {
            const [sensors, stores] = Array.from(args[0])
            return sensors
              .filter((c) => c._companyId === options.companyId)
              .map(function (c) {
                c.store = _.find(stores, { _id: c._storeId })
                return c
              })
          })
      }

      const getCameraOfStore = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        return RestApi.Sensor.index(options.isCache).then((sensors) =>
          sensors.filter((s) => s._storeId === options.id)
        )
      }

      const getCameraOfStoreGroup = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        return Promise.all([
          RestApi.Sensor.index(options.isCache),
          RestApi.StoreGroup.get(options.id, options.isCache),
        ]).spread((sensors, sg) => sensors.filter((s) => sg.storeList.indexOf(s._storeId) >= 0))
      }

      const _getCrGroup = () =>
        $resource(uiApiBase + "/crgroup", {
          id: "@id",
        })

      const getCrGroup = () => _getCrGroup().query().$promise

      const _getCrGroupOfCompany = (options) =>
        $resource(uiApiBase + `/crgroup`, {
          companyId: options.id,
          _: options.isCache ? undefined : Date.now(),
        })

      const getCrGroupOfCompany = (options) => {
        if (!options || !options.id) {
          throw new Error("options.id is required")
        }
        if (options.isCache === undefined) options.isCache = true
        const deffered = $q.defer()
        const params = _.clone(options || {})
        delete params["isCache"]
        const cacheId = "getCrGroupOfCompany-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && options.isCache) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        if (options.isCache === false) params._ = Date.now()
        _getCrGroupOfCompany(options).query(function (res) {
          const dataArray = res.map((item) => item)
          cache.put(cacheId, dataArray)
          deffered.resolve(dataArray)
        })

        return deffered.promise
      }

      const getFootfallsOfCamera = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        const deffered = $q.defer()
        const params = _.clone(options || {})

        delete params["isCache"]
        delete params["datetime"]
        const cacheId = "getFootfallsOfCamera-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        if (options.isCache === false) {
          params._ = Date.now()
        }
        const callback =
          params.all || params.from || params.to
            ? FootfallConfigOfCamera().query
            : FootfallConfigOfCamera().get

        callback(
          params,
          function (res) {
            cache.put(cacheId, res)
            return deffered.resolve(res)
          },

          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      // query option:
      // - null or { at: '2021-01-01' }: return object
      // - { all: true } or { from: '2021-01-01', to: '2021-01-02' }: return array
      const getFootfallsConfigOfCamera = function (cameraId, storeId, query, isCache) {
        if (isCache == null) {
          isCache = true
        }
        return Promise.all([
          getFootfallsOfCamera(_.merge({}, query, { id: cameraId, isCache })),
          getStore({ id: storeId, isCache }),
        ]).spread(function (data, store) {
          if (Array.isArray(data)) {
            data.forEach((footfall) => addLabelInfo(footfall, store, false))
          } else {
            addLabelInfo(data, store)
          }

          return data
        })
      }

      const getFavoriteStores = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        return Auth.fetchCurrentAccount().then((account) =>
          Promise.map(account.favorite.stores, (storeId) =>
            RestApi.Store.get(storeId, options.isCache)
          )
        )
      }

      const getFavoriteCameras = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        return Auth.fetchCurrentAccount().then((me) =>
          Promise.map(me.favorite.cameras, (camId) => RestApi.Sensor.get(camId, options.isCache))
        )
      }

      const getCompanyCountData = function (options) {
        // params = {
        //   from: moment(datetime).subtract(1, 'weeks').startOf('day').format('YYYYMMDD0000')
        //   to: moment(datetime).add(1, 'days').startOf('day').format('YYYYMMDD0000')
        //   from: options.from
        //   to: options.to
        //   sampling: options.sampling || '1d'
        //   entrance: options.entrance
        // }
        if (options == null) {
          options = { isCache: true }
        }
        delete options["isCache"]
        if (options.sampling === "1w") {
          options.isoWeek = _getWeekType() === "isoWeek"
        }

        const cacheId = "getCompanyCountData-" + JSON.stringify(options)
        const cacheData = cache.get(cacheId)
        if (cacheData) {
          return Promise.resolve(cacheData)
        } else {
          return _countForCompany(options.companyId)
            .then((resource) =>
              resource.get(options).$promise.then(function (countData) {
                cache.put(cacheId, countData)
                return countData
              })
            )
            .catch(function (err) {
              console.warn(err.toString())
              return Promise.reject(err)
            })
        }
      }

      const getCompanyCompareYesterday = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        let datetime = undefined
        if (options && options.datetime) {
          ;({ datetime } = options)
        }

        const params = {
          from: moment(datetime).subtract(2, "days").format("YYYYMMDD0000"),
          to: moment(datetime).add(1, "days").format("YYYYMMDD0000"),
          sampling: "1d",
          entrance: "true",
        }

        const cacheId = "getCompanyCompareYesterday-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          return Promise.resolve(cacheData)
        }

        if (options.isCache === false) {
          params._ = Date.now()
        }
        return _countForCompany().then((resource) =>
          resource.get(params).$promise.then(function (res) {
            let result
            if (res.data.length < 3) {
              result = "-"
            } else {
              result = Math.round((res.data[1].count / res.data[0].count) * 100) - 100
            }
            cache.put(cacheId, result)
            return result
          })
        )
      }

      const getCompanyCompareLastWeekly = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        let datetime = undefined
        if (options && options.datetime) {
          ;({ datetime } = options)
        }

        const params = {
          from: moment(datetime)
            .startOf("isoWeek")
            .subtract(7 * 2, "days")
            .format("YYYYMMDD0000"),
          to: moment(datetime).add(1, "days").format("YYYYMMDD0000"),
          sampling: "1w",
          entrance: "true",
        }

        const cacheId = "getCompanyCompareLastWeekly-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          Promise.resolve(cacheData)
        }

        if (options.isCache === false) {
          params._ = Date.now()
        }
        return _countForCompany().$promise.then((resource) =>
          resource.get(params).then(function (res) {
            let result
            if (res.data.length < 3) {
              result = "-"
            } else {
              result = Math.round((res.data[1].count / res.data[0].count) * 100) - 100
            }

            cache.put(cacheId, result)
            return result
          })
        )
      }

      const getChartCount = function (options) {
        let datetime
        if (options == null) {
          options = { isCache: true }
        }
        const source =
          (options != null ? options.source : undefined) != null ? options.source : "company"
        const interval =
          (options != null ? options.interval : undefined) != null ? options.interval : "week"

        let dxTime = 1000 * 24 * 3600
        let xInterval = 7
        let startOfString = "isoWeek"
        let samplingString = "1d"
        let labels = ["Two week ago", "Last week", "This week"]
        if (interval === "day") {
          dxTime = 1000 * 3600
          xInterval = 1
          startOfString = "day"
          samplingString = "1h"
          labels = ["Two days ago", "Yesterday", "Today"]
        }

        const compareCount = 2
        if ((options != null ? options.datetime : undefined) != null) {
          ;({ datetime } = options)
        }

        const startLastDay = moment(datetime)
          .startOf(startOfString)
          .subtract(xInterval * compareCount, "days")
          .format("YYYYMMDDHHmm")
        const startDay = moment.utc(startLastDay, "YYYYMMDDHHmm")

        const params = {
          from: startLastDay,
          to: moment(datetime).add(10, "m").format("YYYYMMDDHH00"),
          sampling: samplingString,
          entrance: "true",
        }
        if (source === "store" && (options != null ? options.datetime : undefined) != null) {
          params.id = options.id
        }

        const cacheId = "getChartCount-" + source + interval + "-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          Promise.resolve(cacheData)
        }

        const _dbCall =
          source === "company" ? _countForCompany : () => Promise.resolve(_countForStore)
        if (options.isCache === false) {
          params._ = Date.now()
        }
        return _dbCall().then((resource) =>
          resource.get(params).$promise.then(function (res) {
            const countdata = []
            let rowNum = false
            res.data.forEach(function (row, idx) {
              const theday = moment(row.statisticFor).utc()
              const rawWeekday = moment(row.statisticFor).utc().isoWeekday()
              rowNum = Math.floor((theday - startDay) / dxTime)
              return (countdata[rowNum] = [rawWeekday, row.count])
            })

            let n = 0
            while (n < rowNum) {
              var idx = interval === "day" ? n : (n % 7) + 1
              if (!countdata[n]) {
                countdata[n] = [idx, 0]
              }
              n++
            }

            const result = []
            for (
              let i = 0, end = countdata.length - 1, step = xInterval, asc = step > 0;
              asc ? i <= end : i >= end;
              i += step
            ) {
              var graph = {
                data: countdata.slice(i, i + xInterval),
                label: labels[Math.floor(i / xInterval)],
              }
              if (Math.floor(i / xInterval !== Math.floor((countdata.length - 1) / xInterval))) {
                graph.lines = { fill: true }
              } else {
                graph.points = { show: true }
              }
              result.push(graph)
            }

            cache.put(cacheId, result)
            return result
          })
        )
      }

      const getCompanyWeeklyCount = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        options = options != null ? options : {}
        options.source = "company"
        options.interval = "week"
        return getChartCount(options)
      }

      /* replaced
  getCompanyWeeklyCount = (options = {isCache: true}) ->
    datetime = undefined
    datetime = options.datetime if options and options.datetime
    deffered = $q.defer()

    startLastWeekDay = moment(datetime).startOf('isoWeek').subtract(7, 'days').format('YYYYMMDD0000')
    startThisWeekDay = moment(datetime).startOf('isoWeek').add(7, 'days').format('YYYYMMDD0000')

    params = {
      from: startLastWeekDay
      to: moment(datetime).add(1, 'hours').format('YYYYMMDDHH00')
      sampling: '1d'
      entrance: 'true'
    }

    cacheId = 'getCompanyWeeklyCount' + '-' + JSON.stringify(params)
    cacheData = cache.get cacheId
    if cacheData and (not options or options.isCache isnt false)
      deffered.resolve cacheData
      return deffered.promise

    _countForCompany().get(params, (res) ->
      countdata = []
      rowNum = false
      res.data.forEach (row, idx) ->
        rawWeekday = moment(row.statisticFor).utc().isoWeekday()
        if rowNum is false
          rowNum = rawWeekday - 1
          rowNum += 7 if moment(row.statisticFor).utc().format('YYYYMMDDHHmm') >= startThisWeekDay
        countdata[rowNum] = [
          rawWeekday
          row.count
        ]
        return if res.data.length <= idx + 1
        align = (moment(res.data[idx+1].statisticFor).utc().isoWeekday() - rawWeekday)
        align += 7 if align <= 0
        rowNum += align
        return

      result = [
        data: countdata.slice(0, 7)
        label: "Last Week"
        lines:
          fill: true
      ,
        data: countdata.slice(7)
        label: "This Week"
        points:
          show: true
      ]
      cache.put cacheId, result
      deffered.resolve result
    )

    deffered.promise
  */

      const calcExpectations = function (data, today) {
        const index = today.isoWeekday() - 1
        const expectation = { today: 0, thisweek: 0 }
        if (data.length < 7) {
          return expectation
        }
        expectation.today = (function () {
          let sum = 0
          let cnt = 0
          for (let i = index, end = data.length - 1; i <= end; i += 7) {
            sum += data[i].count
            cnt++
          }
          const average = sum / (cnt > 0 ? cnt : 1)
          const prevweek = data[data.length - 7 + index].count
          return Math.round((average / prevweek) * 100 - 100)
        })()
        expectation.thisweek = (function () {
          let sum = 0
          data.forEach((d) => (sum += d.count))
          const averageofweek = sum / (data.length / 7)
          let wsum = 0
          data.slice(data.length - 7).forEach((d) => (wsum += d.count))
          return Math.round((averageofweek / wsum) * 100 - 100)
        })()
        return expectation
      }

      const getCompanyExpectations = function (options) {
        let result
        if (options == null) {
          options = { isCache: true }
        }
        const today = moment(options != null ? options.datetime : undefined).startOf("day")
        const startdayofweek = moment(today).startOf("isoWeek")

        const params = {
          from: moment(startdayofweek)
            .subtract(4 * 7, "days")
            .format("YYYYMMDD0000"),
          to: moment(startdayofweek).format("YYYYMMDD0000"),
          sampling: "1d",
          entrance: "true",
        }

        const cacheId = "_countForCompany-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData) {
          if (cacheData.data.length <= 1) {
            result = { today: 0, thisweek: 0 }
          } else {
            result = calcExpectations(cacheData.data, today)
          }
          return Promise.resolve(result)
        } else {
          if (options.isCache === false) {
            params._ = Date.now()
          }
          return _countForCompany().then((resource) =>
            resource.get(params).$promise.then(function (res) {
              if (res.data.length <= 1) {
                result = { today: 0, thisweek: 0 }
              } else {
                result = calcExpectations(res.data, today)
              }
              cache.put(cacheId, res)
              return result
            })
          )
        }
      }

      const getAllStoreCompareYesterday = function (options) {
        let datetime
        if (options == null) {
          options = { isCache: true }
        }
        if ((options != null ? options.datetime : undefined) != null) {
          ;({ datetime } = options)
        }
        const deffered = $q.defer()

        const params = {
          item: "store",
          from: moment(datetime).subtract(2, "days").format("YYYYMMDD0000"),
          to: moment(datetime).add(1, "days").format("YYYYMMDD0000"),
          sampling: "1d",
          entrance: "true",
        }

        let cacheId = "getAllStoreCompareYesterday-" + JSON.stringify(params)
        if (options != null ? options.companyId : undefined) {
          cacheId += "-" + options.companyId
        }

        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        async.waterfall(
          [
            function (cback) {
              if (options != null ? options.companyId : undefined) {
                const companies = [{ _id: options.companyId }]
                return cback(null, companies)
              } else {
                return getAllCompany().then((companies) => cback(null, companies))
              }
            },
          ],
          function (err, companies) {
            const result = {}
            const today = moment(datetime).format("YYYYMMDD")
            const yesterday = moment(datetime).subtract(1, "days").format("YYYYMMDD")
            const desterday = moment(datetime).subtract(2, "days").format("YYYYMMDD")

            return async.eachSeries(
              companies,
              function (company, callback) {
                if (options.isCache === false) {
                  params._ = Date.now()
                }
                return _countForCompany(company._id)
                  .then((resource) =>
                    resource.get(params).$promise.then(function (res) {
                      if (!res.data) {
                        return callback(null)
                      }
                      res.data.forEach(function (raw) {
                        if (!result[raw.storeId]) {
                          result[raw.storeId] = {
                            today: 0,
                            yesterday: 0,
                            desterday: 0,
                          }
                        }
                        switch (moment.utc(raw.statisticFor).format("YYYYMMDD")) {
                          case today:
                            return (result[raw.storeId].today = raw.count)
                          case yesterday:
                            return (result[raw.storeId].yesterday = raw.count)
                          case desterday:
                            return (result[raw.storeId].desterday = raw.count)
                        }
                      })

                      return callback(null)
                    })
                  )
                  .catch((err) => callback(err))
              },
              function (err) {
                cache.put(cacheId, result)
                return deffered.resolve(result)
              }
            )
          }
        )

        return deffered.promise
      }

      const getAllCameraCompareYesterday = function (options) {
        let datetime
        if (options == null) {
          options = { isCache: true }
        }
        if ((options != null ? options.datetime : undefined) != null) {
          ;({ datetime } = options)
        }
        const deffered = $q.defer()

        const params = {
          item: "camera",
          from: moment(datetime).subtract(2, "days").format("YYYYMMDD0000"),
          to: moment(datetime).add(1, "days").format("YYYYMMDD0000"),
          sampling: "1d",
          entrance: "all",
        }

        let cacheId = "getAllCameraCompareYesterday-" + JSON.stringify(params)
        if (options != null ? options.companyId : undefined) {
          cacheId += "-" + options.companyId
        }

        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        async.waterfall(
          [
            function (cback) {
              if (options != null ? options.companyId : undefined) {
                const companies = [{ _id: options.companyId }]
                return cback(null, companies)
              } else {
                return getAllCompany().then((companies) => cback(null, companies))
              }
            },
          ],
          function (err, companies) {
            const result = {}
            const today = moment(datetime).format("YYYYMMDD")
            const yesterday = moment(datetime).subtract(1, "days").format("YYYYMMDD")
            const desterday = moment(datetime).subtract(2, "days").format("YYYYMMDD")

            return async.eachSeries(
              companies,
              function (company, callback) {
                if (options.isCache === false) {
                  params._ = Date.now()
                }
                return _countForCompany(company._id)
                  .then((resource) =>
                    resource.get(params).$promise.then(function (res) {
                      if (!res.data) {
                        return callback(null)
                      }
                      res.data.forEach(function (raw) {
                        if (!result[raw.cameraId]) {
                          result[raw.cameraId] = {
                            today: 0,
                            yesterday: 0,
                            desterday: 0,
                          }
                        }
                        switch (moment.utc(raw.statisticFor).format("YYYYMMDD")) {
                          case today:
                            return (result[raw.cameraId].today = raw.count)
                          case yesterday:
                            return (result[raw.cameraId].yesterday = raw.count)
                          case desterday:
                            return (result[raw.cameraId].desterday = raw.count)
                        }
                      })
                      return callback(null)
                    })
                  )
                  .catch((err) => callback(err))
              },
              function (err) {
                cache.put(cacheId, result)
                return deffered.resolve(result)
              }
            )
          }
        )

        return deffered.promise
      }

      const getStoreCompareYesterday = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        let datetime = undefined
        if (options && options.datetime) {
          ;({ datetime } = options)
        }
        const deffered = $q.defer()

        const params = {
          id: options.id,
          from: moment(datetime).subtract(2, "days").format("YYYYMMDD0000"),
          to: moment(datetime).add(1, "days").format("YYYYMMDD0000"),
          sampling: "1d",
          entrance: "true",
        }

        const cacheId = "getStoreCompareYesterday-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        if (options.isCache === false) {
          params._ = Date.now()
        }
        _countForStore().get(
          params,
          function (res) {
            let result
            if (res.data.length < 3) {
              result = "-"
            } else {
              result = Math.round((res.data[1].count / res.data[0].count) * 100) - 100
            }

            cache.put(cacheId, result)
            return deffered.resolve(result)
          },

          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getStoreGroupCompareYesterday = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        let datetime = undefined
        if (options && options.datetime) {
          ;({ datetime } = options)
        }
        const deffered = $q.defer()

        const params = {
          id: options.id,
          from: moment(datetime).subtract(2, "days").format("YYYYMMDD0000"),
          to: moment(datetime).add(1, "days").format("YYYYMMDD0000"),
          sampling: "1d",
          entrance: "true",
        }

        const cacheId = "getStoreGroupCompareYesterday-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        if (options.isCache === false) {
          params._ = Date.now()
        }
        _countForStoreGroup().get(
          params,
          function (res) {
            const today = moment(datetime).format("YYYYMMDD")
            const yesterday = moment(datetime).subtract(1, "days").format("YYYYMMDD")
            const desterday = moment(datetime).subtract(2, "days").format("YYYYMMDD")
            const result = {
              today: 0,
              yesterday: 0,
              desterday: 0,
            }

            if (!res.data) {
              return deffered.resolve(result)
            }

            res.data.forEach(function (raw) {
              switch (moment.utc(raw.statisticFor).format("YYYYMMDD")) {
                case today:
                  return (result.today = raw.count)
                case yesterday:
                  return (result.yesterday = raw.count)
                case desterday:
                  return (result.desterday = raw.count)
              }
            })

            cache.put(cacheId, result)
            return deffered.resolve(result)
          },

          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getStoreCompareLastWeekly = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        let datetime = undefined
        if (options && options.datetime) {
          ;({ datetime } = options)
        }
        const deffered = $q.defer()

        const params = {
          id: options.id,
          from: moment(datetime)
            .startOf("isoWeek")
            .subtract(7 * 2, "days")
            .format("YYYYMMDD0000"),
          to: moment(datetime).add(1, "days").format("YYYYMMDD0000"),
          sampling: "1w",
          entrance: "true",
        }

        const cacheId = "getStoreCompareLastWeekly-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        if (options.isCache === false) {
          params._ = Date.now()
        }
        _countForStore().get(
          params,
          function (res) {
            let result
            if (res.data.length < 3) {
              result = "-"
            } else {
              result = Math.round((res.data[1].count / res.data[0].count) * 100) - 100
            }

            cache.put(cacheId, result)
            return deffered.resolve(result)
          },

          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getStoreWeeklyCount = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        options = options != null ? options : {}
        options.source = "store"
        options.interval = "week"
        return getChartCount(options)
      }

      /* replaced
  getStoreWeeklyCount = (options = {isCache: true}) ->
    datetime = undefined
    datetime = options.datetime if options and options.datetime
    deffered = $q.defer()

    startLastWeekDay = moment(datetime).startOf('isoWeek').subtract(7*2, 'days').format('YYYYMMDD0000')
    startDay = moment.utc(startLastWeekDay,'YYYYMMDD0000');
    startThisWeekDay = moment(datetime).startOf('isoWeek').add(7, 'days').format('YYYYMMDD0000')

    params = {
      id: options.id
      from: startLastWeekDay
      to: moment(datetime).add(1, 'hours').format('YYYYMMDDHH00')
      sampling: '1d'
      entrance: 'true'
    }

    cacheId = 'getStoreWeeklyCount' + '-' + JSON.stringify(params)
    cacheData = cache.get cacheId
    if cacheData and (not options or options.isCache isnt false)
      deffered.resolve cacheData
      return deffered.promise

    _countForStore().get(params, (res) ->
      countdata = []
      rowNum = false
      res.data.forEach (row, idx) ->
        theday = moment(row.statisticFor).utc();
        rawWeekday = theday.isoWeekday()
        rowNum = Math.floor (theday-startDay)/(1000*24*3600)
*        if rowNum is false
*          rowNum = rawWeekday - 1
*          rowNum += 7 if moment(row.statisticFor).utc().format('YYYYMMDDHHmm') >= startThisWeekDay
        countdata[rowNum] = [
          rawWeekday
          row.count
        ]
        return if res.data.length <= idx + 1
*        align = (moment(res.data[idx+1].statisticFor).utc().isoWeekday() - rawWeekday)
*        align += 7 if align <= 0
*        rowNum += align
        return

      result = [
        data: countdata.slice(0, 7)
        label: "Two Weeks ago"
        lines:
          fill: true
      ,
        data: countdata.slice(7, 14)
        label: "Last Week"
        lines:
          fill: true
      ,
        data: countdata.slice(14)
        label: "This Week"
        points:
          show: true
      ]
      cache.put cacheId, result
      deffered.resolve result
    )

    deffered.promise
  */

      const getCounterDailyCount = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        let datetime = undefined
        if ((options != null ? options.datetime : undefined) != null) {
          ;({ datetime } = options)
        }
        const deffered = $q.defer()

        const params = {
          id: options.id,
          item: "counter",
          from: moment(datetime).subtract(7, "days").format("YYYYMMDDHHmm"),
          //      to: moment(datetime).add(1, 'days').format('YYYYMMDD0000')
          to: moment(datetime).format("YYYYMMDD0000"),
          sampling: "1d",
        }

        const cacheId = "getCounterDailyCount-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        if (options.isCache === false) {
          params._ = Date.now()
        }
        _countForCamera().get(
          params,
          function (res) {
            cache.put(cacheId, res)
            return deffered.resolve(res)
          },

          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getContentsData = function (options) {
        if (options == null) {
          options = { isCache: true }
        }

        const params = {
          id: options.id,
          from: options.from,
          to: options.to,
          sampling: options.sampling || "1d",
          entrance: options.entrance != null ? options.entrance : "all",
          exit: options.exit != null ? options.exit : "all",
        }

        if (options.contentsType) {
          params.item = options.contentsType
        }
        if (params.sampling === "1w") {
          params.isoWeek = _getWeekType() === "isoWeek"
        }
        if (options.face) {
          params.face = options.face
        }
        if (options.usage) {
          params.usage = options.usage
        }

        const cacheId = "getContentsData-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          Promise.resolve(cacheData)
        }

        let contentsResource = null
        switch (options.orgType) {
          case "company":
            contentsResource = _countForCompany(options.id)
            break
          case "storegroup":
            contentsResource = Promise.resolve(_countForStoreGroup())
            break
          case "store":
            contentsResource = Promise.resolve(_countForStore())
            break
          case "sensor":
            contentsResource = Promise.resolve(_countForCamera())
            break
        }

        if (options.isCache === false) {
          params._ = Date.now()
        }
        return contentsResource.then((resource) =>
          resource.get(params).$promise.then(function (res) {
            cache.put(cacheId, res)
            return res
          })
        )
      }

      const getCountOfStore = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        const deffered = $q.defer()

        const params = {
          id: options.id,
          from: options.from,
          to: options.to,
          sampling: options.sampling || "1d",
        }

        kStandardLabels.forEach((label) => (params[label.key] = options[label.key] || "all"))

        if (options.item) {
          params.item = options.item
        }
        if (options.group) {
          params.group = options.group
        }
        if (params.sampling === "1w") {
          params.isoWeek = _getWeekType() === "isoWeek"
        }

        const cacheId = "getCountOfStore-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        if (options.isCache === false) {
          params._ = Date.now()
        }
        _countForStore().get(
          params,
          function (res) {
            cache.put(cacheId, res)
            return deffered.resolve(res)
          },

          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getCountOfCamera = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        const deffered = $q.defer()

        const params = {
          id: options.id,
          from: options.from,
          to: options.to,
          sampling: options.sampling,
          bh: options.bh != null ? options.bh : "true",
        }

        kStandardLabels.forEach((label) => (params[label.key] = options[label.key] || "all"))

        if (options.item) {
          params.item = options.item
        }
        if (params.sampling === "1w") {
          params.isoWeek = _getWeekType() === "isoWeek"
        }

        const cacheId = "getCountOfCamera-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        if (options.isCache === false) {
          params._ = Date.now()
        }
        _countForCamera().get(
          params,
          function (res) {
            cache.put(cacheId, res)
            return deffered.resolve(res)
          },

          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getFaceOfStore = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        const deffered = $q.defer()

        const params = {
          id: options.id,
          from: options.from,
          to: options.to,
          sampling: options.sampling || "1d",
          datetime: options.datetime,
        }

        const cacheId = "getFaceOfStore-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        _faceForStore().get(
          params,
          function (res) {
            cache.put(cacheId, res)
            return deffered.resolve(res)
          },

          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getFaceCameraOfStore = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        const deffered = $q.defer()

        const params = {
          id: options.id,
          datetime: options.datetime,
        }

        const cacheId = "getFaceCameraOfStore-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        if (options.isCache === false) {
          params._ = Date.now()
        }
        _faceCameraForStore().get(
          params,
          function (res) {
            cache.put(cacheId, res)
            return deffered.resolve(res)
          },

          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getHeatmapOfCamera = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        const deffered = $q.defer()

        const params = {
          id: options.id,
          from: options.from,
          to: options.to,
          sampling: options.sampling,
          datetime: options.datetime,
        }

        const cacheId = "getHeatmapOfCamera-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        if (options.isCache === false) {
          params._ = Date.now()
        }
        _getHeatmapOfCamera(
          params,
          function (res) {
            cache.put(cacheId, res)
            return deffered.resolve(res)
          },

          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getHeatmapHistogramOfCamera = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        options.totalsum = true
        const deffered = $q.defer()
        const cacheId = "getHeatmapHistogramOfCamera-" + JSON.stringify(options)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        _getHeatmapOfCamera(
          options,
          function (res) {
            const result = res
            cache.put(cacheId, result)
            return deffered.resolve(result)
          },

          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getDwellmapOfCamera = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        const deffered = $q.defer()

        const params = _.pick(options, ["id", "from", "to", "sampling"])
        // params =
        //   id: options.id
        //   from: options.from
        //   to: options.to
        //   sampling: options.sampling

        const cacheId = "getDwellmapOfCamera-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        if (options.isCache === false) {
          params._ = Date.now()
        }
        _dwellmapOfCamera().get(
          params,
          function (res) {
            cache.put(cacheId, res)
            return deffered.resolve(res)
          },
          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getCustomSettingsbyUrl = function () {
        const deffered = $q.defer()

        $resource("/api/1.0/custom/url", { id: "@id" }).get(
          {},
          (res) => deffered.resolve(res),

          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getServiceConfig = function () {
        const deffered = $q.defer()

        $resource("/api/1.0/service/config").get(
          (res) => deffered.resolve(res),
          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getServiceEnvironment = function () {
        const deffered = $q.defer()

        $resource("/api/1.0/service/environment").get(
          (data) => deffered.resolve(data),
          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getCustomSettingsbyAgency = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        const deffered = $q.defer()

        if (options.id) {
          $resource("/api/1.0/custom/agency/:id", { id: "@id" }).get(
            { id: options.id },
            (res) => deffered.resolve(res),

            (err) => deffered.reject(err)
          )
        } else {
          deffered.reject("undefined id")
        }

        return deffered.promise
      }

      const getTotalOfCamera = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        const deffered = $q.defer()
        const params = _.clone(options || {})

        delete params["isCache"]
        const cacheId = "getTotalOfCamera-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
        }

        if (options.isCache === false) {
          params._ = Date.now()
        }
        _totalOfCamera().get(
          params,
          function (res) {
            cache.put(cacheId, res)
            return deffered.resolve(res)
          },

          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getLastData = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        const deffered = $q.defer()
        const params = _.clone(options || {})

        delete params["isCache"]
        const cacheId = "getLastData-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        if (options.isCache === false) {
          params._ = Date.now()
        }

        const url =
          options.content === "track"
            ? `/api/1.0/camera/${options.id}/trackAnalysis`
            : `/api/1.0/camera/${options.id}/${options.content}/last`

        $resource(url).get(
          params,
          function (res) {
            cache.put(cacheId, res)
            return deffered.resolve(res)
          },
          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getTimezone = function () {
        const deffered = $q.defer()

        $resource(uiApiBase + "/store/timezone/geoip").get(
          (res) => deffered.resolve(res),
          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const ServerGlobalConfig = function (node, obj) {
        const deffered = $q.defer()
        const api = $resource($rootScope.globalConfig.apipath + `/globalconfig/${node}`)
        if (obj == null) {
          //get
          api.get(
            (res) => deffered.resolve(res),
            (err) => deffered.reject(err)
          )
        } else {
          //set
          api.save(
            obj,
            (res) => deffered.resolve(res),
            (err) => deffered.reject(err)
          )
        }
        return deffered.promise
      }

      const queueSubsamplingByStore = function (storeId, startDate, endDate) {
        const deffered = $q.defer()
        $resource(uiApiBase + `/queue/stores/${storeId}/updateHourData?from=:from&to=:to`).get(
          { from: startDate, to: endDate },
          (res) => deffered.resolve(res),
          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const subsamplingByStore = function (options) {
        const deffered = $q.defer()
        options._ = Date.now()

        $resource(
          uiApiBase + `/store/${options.id}/subsampling?from=:from&to=:to&sampling=:sampling`
        ).get(
          options,
          (res) => deffered.resolve(res),
          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const trafficHeatmapSubsamplingByStore = function (options) {
        const deffered = $q.defer()
        options._ = Date.now()

        $resource(
          uiApiBase + `/store/${options.id}/heatmap/downsampling?from=:from&to=:to&bh=true`
        ).query(
          options,
          (res) => deffered.resolve(res),
          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const getCustomPolicy = function (options) {
        const url = uiApiBase + "/custom/policy"
        options._ = Date.now()

        return $http.get(url, { method: "get", params: options }).then((res) => res.data)
      }

      const getRoiHeatmapOfCamera = function (options) {
        if (options == null) {
          options = { isCache: true }
        }
        const deffered = $q.defer()

        const params = {
          id: options.id,
          from: options.from,
          to: options.to,
          sampling: options.sampling,
          datetime: options.datetime,
        }

        const cacheId = "getRoiHeatmapOfCamera-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && (!options || options.isCache !== false)) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        if (options.isCache === false) {
          params._ = Date.now()
        }
        _roiHeatmapOfCamera().get(
          params,
          function (res) {
            cache.put(cacheId, res)
            return deffered.resolve(res)
          },

          (err) => deffered.reject(err)
        )

        return deffered.promise
      }

      const cachedApiGet = function (name, rcApi, params, isCache) {
        if (isCache == null) {
          isCache = true
        }
        const cacheId = name + "-" + JSON.stringify(params)
        const cacheData = cache.get(cacheId)
        if (cacheData && isCache) {
          return $q.resolve(cacheData)
        }

        if (isCache === false) {
          params._ = Date.now()
        }
        return rcApi.get(params).$promise.then(function (res) {
          cache.put(cacheId, res)
          return res
        })
      }

      const getDirectionConfigOfCamera = function (opt) {
        if (opt == null) {
          opt = { isCache: true }
        }
        return cachedApiGet(
          "getDirectionConfigOfCamera",
          _directionConfigOfCamera(),
          { id: opt.id },
          opt.isCache
        )
      }

      const setDirectionConfigOfCamera = function (opt, data) {
        if (opt == null) {
          opt = { isCache: true }
        }
        return _directionConfigOfCamera().set({ id: opt.id }, data).$promise
      }

      const getDirectionmapOfCamera = function (opt) {
        if (opt == null) {
          opt = { isCache: true }
        }
        const params = _.pick(opt, ["id", "from", "to", "sampling"])
        return cachedApiGet("getDirectionmapOfCamera", _directionmapOfCamera(), params, opt.isCache)
      }

      const getOccupancyOfCamera = function (opt) {
        if (opt == null) {
          opt = { isCache: true }
        }
        const params = _.pick(opt, ["id", "from", "to", "sampling"])
        return cachedApiGet("getOccOfCamera", _occupancyOfCamera(), params, opt.isCache)
      }
      const getOccupancyOfStore = function (opt) {
        if (opt == null) {
          opt = { isCache: true }
        }
        const params = _.pick(opt, ["id", "from", "to", "sampling"])
        return cachedApiGet("getOccOfStore", _occupancyOfStore(), params, opt.isCache)
      }

      const getFacelog = (opt) => $resource(uiApiBase + "/camera/:id/face/log").get(opt).$promise

      const getTrackStatus = (opt) =>
        $resource(uiApiBase + "/track-analysis/status").get(opt).$promise

      const requestTrackAnalsis = (item) =>
        $resource(uiApiBase + "/track-analysis/analysistrack").update(item, {}).$promise

      const addCommandQueue = (item) => $resource(uiApiBase + "/commandqueue").save(item).$promise

      const tunnel = {
        url: uiApiBase + "/jobTunnels",

        getAll(item) {
          return $resource(this.url).query(item).$promise
        },

        post(camId, durationMins) {
          const item = {
            cameraId: camId,
            durationMins,
          }
          return $resource(this.url).save(item).$promise
        },

        update(camId, durationMins, status) {
          const item = { durationMins }
          if (status !== null) {
            item.status = status
          }
          return $resource(this.url + "/:camId").update({ camId }, item).$promise
        },

        del(camId) {
          return $resource(this.url + "/:camId").remove({ camId }).$promise
        },
      }

      const getFirstLocalTime = function (opt) {
        opt._ = Date.now()
        return $resource(
          uiApiBase + "/content/:content/:orgType/:orgId/first?localtimeonly=true"
        ).get(opt).$promise
      }

      const getLastLocalTime = function (opt) {
        opt._ = Date.now()
        return $resource(
          uiApiBase + "/content/:content/:orgType/:orgId/last?localtimeonly=true"
        ).get(opt).$promise
      }

      const getWeatherLocations = function () {
        if (env.isLocalgrey) {
          return Promise.resolve([])
        } else {
          return $resource(uiApiBase + "/weather/location").query().$promise
        }
      }

      const getWeatherObservationData = function (opt) {
        const deffered = $q.defer()
        if (opt.sampling === "month") {
          return deffered.resolve()
        }

        return $resource(uiApiBase + "/weather/observation/query").query(opt).$promise
      }

      const getWeatherObservationSafeData = (opt) =>
        getWeatherObservationData(opt)
          .then((weather) =>
            weather.reduce(
              function (obj, item) {
                const sampling = item.sampling === 3600 ? "hour" : "day"
                const dbtime = moment.parseZone(item.localtime).utc(true).format()
                obj[sampling][dbtime] = item
                return obj
              },
              {
                hasData: true,
                hour: {},
                day: {},
              }
            )
          )
          .catch(function (er) {
            console.warn("getWeatherObservationData error", opt, er)
            return {}
          })

      const getPathTreeStayData = function (options) {
        const isCache = options?.isCache == null ? true : options?.isCache
        const deffered = $q.defer()
        const cacheId = "getPathTreeStayTime-" + JSON.stringify(options)
        const cacheData = cache.get(cacheId)
        if (cacheData && isCache) {
          deffered.resolve(cacheData)
          return deffered.promise
        }

        if (isCache === false) {
          options._ = Date.now()
        }

        $resource(uiApiBase + "/store/:id/zonetrace/staytime", { id: "@id" }).get(
          options,
          function (res) {
            cache.put(cacheId, res)
            return deffered.resolve(res)
          },
          (err) => deffered.reject(err)
        )
        return deffered.promise
      }

      const estimateContentsData = (opt) =>
        $resource(uiApiBase + "/estimator").get(opt, {}).$promise

      const checkDataDownload = (opt) =>
        $http.get(`${uiApiBase}/datadownload/check`, { params: opt })

      const deleteS3Folder = (opt) => $resource(uiApiBase + "/exportdata/s3").delete(opt).$promise

      const updateIcalendar = (opt) => $resource(uiApiBase + "/icalendar").save(opt).$promise

      const getIcalendars = (opt) => $resource(uiApiBase + "/icalendar/query").query(opt).$promise

      const deleteIcalendar = (opt) =>
        $resource(uiApiBase + "/icalendar/:id", { id: "@id" }).delete(opt).$promise

      const getStoreBizSchedule = (opt) =>
        $resource(uiApiBase + "/store/:id/schedule").get(opt).$promise

      const getStoreBizHours = (opt) =>
        $resource(uiApiBase + "/store/:id/schedule/query").get(opt).$promise

      const getRebuildStatusCount = (opt) =>
        $resource(uiApiBase + "/rebuilds/count").get(opt).$promise

      const getRebuildStatus = (opt) => $resource(uiApiBase + "/rebuilds/query").query(opt).$promise

      const createRebuildStatus = (opt) => $resource(uiApiBase + "/rebuilds").save(opt).$promise

      const deleteRebuildStatus = (opt) =>
        $resource(uiApiBase + "/rebuilds/:id", { id: "@id" }).delete(opt).$promise

      const cancelRebuildStatus = (opt) =>
        $resource(uiApiBase + "/rebuilds/:id", { id: "@id" }).update(opt).$promise

      const getRebuildProgress = () => $resource(uiApiBase + "/rebuilds/progress").get().$promise

      return {
        clearCache,
        getDefaultPath,
        getSpace,
        getAgency,
        getCompany,
        getStore,
        getStoreGroup,
        getCamera,
        getAccount,
        getAllSpace,
        getAllAgency,
        getAllCompany,
        getCompanies,
        getReportList,
        getReportBuildStatus,
        getAllStore,
        getAllCamera,
        getAllAccount,
        getAllAccountGroup,
        getAllStoreGroup,
        getAllStoreCompareYesterday,
        getAllCameraCompareYesterday,
        getCompanyCountData,
        getCompanyCompareYesterday,
        getCompanyCompareLastWeekly,
        getCompanyWeeklyCount,
        getCompanyExpectations,
        getStoreCountData: getCountOfStore,
        getStoreCompareYesterday,
        getStoreGroupCompareYesterday,
        getStoreCompareLastWeekly,
        getStoreWeeklyCount,
        getCounterDailyCount,
        getCameraOfCompany,
        getCameraOfStore,
        getCameraOfStoreGroup,
        getCrGroup,
        getCrGroupOfCompany,
        FootfallConfigOfCamera,
        getFootfallsConfigOfCamera,
        getCountOfStore,
        getCountOfCamera,
        getFaceOfStore,
        getContentsData,
        getFaceCameraOfStore,
        getHeatmapOfCamera,
        getHeatmapHistogramOfCamera,
        getDwellmapOfCamera,
        getFavoriteStores,
        getFavoriteCameras,
        getCustomSettingsbyUrl,
        getCustomSettingsbyAgency,
        getServiceConfig,
        getServiceEnvironment,
        getTotalOfCamera,
        getLastData,
        getTimezone,
        ServerGlobalConfig,
        subsamplingByStore,
        queueSubsamplingByStore,
        getCustomPolicy,
        getRoiHeatmapOfCamera,
        getDirectionmapOfCamera,
        getDirectionConfigOfCamera,
        setDirectionConfigOfCamera,
        getOccupancyOfCamera,
        getOccupancyOfStore,
        trafficHeatmapSubsamplingByStore,
        getFacelog,
        getTrackStatus,
        requestTrackAnalsis,
        addCommandQueue,
        getFirstLocalTime,
        getLastLocalTime,
        getWeatherLocations,
        tunnel,
        getWeatherObservationData,
        getWeatherObservationSafeData,
        estimateContentsData,
        checkDataDownload,
        deleteS3Folder,
        updateIcalendar,
        getIcalendars,
        deleteIcalendar,
        getStoreBizSchedule,
        getStoreBizHours,
        getRebuildStatusCount,
        getRebuildStatus,
        createRebuildStatus,
        deleteRebuildStatus,
        cancelRebuildStatus,
        getRebuildProgress,
        getPathTreeStayData,
        dummy() {},
      }
    }
  )
