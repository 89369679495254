"use strict"

import { EnvStage } from "common-library/ucountit-env"
import { createApiUrls } from "./apiurls"
import { Required, Optional } from "./Environment"

const endpoint = "http://172.31.1.1:9000"

const config: EnvStage<Required, Optional> = {
  api: Object.assign(createApiUrls("localhost"), {}),
  host: ["localhost", "127.0.0.1"],
  cloudFront: {
    bucket: "ucount-it.static",
    region: "us-west-1",
    prefix: "ui-local",
    basePath: "https://cdn.ucount.it",
  },

  web: {
    queueurl: "http://localhost:19581",
    faceurl: "/facedashboard",
    tunnelUrl: "",
  },

  sqs: {
    sales: {
      region: "us-west-1",
      requestParsingRawQueue: "",
      confirmWaitingdataQueue: "",
    },
  },

  log: {
    analyticTrackingId: "",
  },

  email: { from: "postmaster@ucountit.com" },

  s3: {
    static: {
      bucket: "localgrey",
      destination: "static",
      endpoint,
      cloudfront: "",
    },
    firmware: {
      bucket: "localgrey",
      destination: "firmware",
      endpoint,
    },
    customAgency: {
      bucket: "localgrey",
      destination: "customAgency",
      endpoint,
    },
    face: {
      bucket: "localgrey",
      destination: "face",
      endpoint,
    },
    sales: {
      bucket: "localgrey",
      destination: "sales",
      endpoint,
    },
  },
  redis: {
    host: "172.31.1.3",
    port: "6379",
  },

  brand: "localgrey",
  isLocalgrey: true,
}

export default config
