/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

angular
  .module("uCountitUiApp")

  .directive("banName", (Locale) => ({
    restrict: "A",
    require: "ngModel",

    link(scope, element, attrs, ctrl) {
      let banList = []
      const banNames = attrs.banName.split(",")
      banNames.forEach(function (n) {
        const multiLangs = Locale.getMultiLanguages(n)
        if (_.isEmpty(multiLangs)) {
          return banList.push(n)
        } else {
          return (banList = banList.concat(multiLangs))
        }
      })

      return (ctrl.$validators.banName = function (modelValue, viewValue) {
        if (ctrl.$isEmpty(modelValue) || ctrl.$isEmpty(viewValue)) {
          return true
        }

        const names = _.filter(
          banList,
          (name) => name.toLowerCase() === (viewValue != null ? viewValue.toLowerCase() : undefined)
        )
        if (names.length > 0) {
          ctrl.$setDirty()
        }
        return names.length === 0
      })
    },
  }))
