/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular.module("uCountitUiApp").config(($stateProvider) =>
  $stateProvider
    .state("app.dashboard", {
      url: "/dashboard",
      abstract: true,
      template: "<div ui-view></div>",
    })
    .state("app.dashboard.company", {
      url: "/company",
      templateUrl: "app/dashboard/company/dashboard.html",
      controller: "DashboardCtrl",
    })
    .state("app.dashboard.queue", {
      url: "/queue/:orgType/:id",
      params: {
        orgType: null,
      },
      templateUrl($stateParams) {
        const templateUrl =
          $stateParams.orgType === "store"
            ? "app/dashboard/queue/queuedashboard.html"
            : "app/content/store-only.html"

        return templateUrl
      },
      controllerProvider($stateParams) {
        const ctrlName = $stateParams.orgType === "store" ? "QueueDashboardCtrl" : null
        return ctrlName
      },
    })
    .state("app.dashboard.face", {
      url: "/face/:orgType/:id",
      params: {
        orgType: null,
      },
      templateUrl($stateParams) {
        const templateUrl =
          $stateParams.orgType === "sensor"
            ? "app/dashboard/face/facedashboard.html"
            : "app/content/sensor-only.html"

        return templateUrl
      },
      controllerProvider($stateParams) {
        const ctrlName = $stateParams.orgType === "sensor" ? "FaceDashboardCtrl" : null
        return ctrlName
      },
    })
)
