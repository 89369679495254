"usr strict"

export const getFontSize = function (width) {
  if (width > 768) return 20
  if (width > 320) return 16
  return 12
}

export const setFontCenterPosition = function (obj, text) {
  var center = obj.getCenterPoint()
  text.left = center.x - text.width / 2
  text.top = center.y - text.height / 2
}

export const getBlankZone = function (index, width, height, name) {
  const offsetArray = [
    [0, 1],
    [2, 1],
    [3, 1],
    [5, 1],
    [0, 3],
    [2, 3],
    [3, 3],
    [5, 3],
    [0, 5],
    [2, 5],
    [3, 5],
    [5, 5],
  ]
  const offset = offsetArray[index % offsetArray.length]
  const w = width / 4
  const h = height / 4
  if (name) {
    //name = name
  } else {
    name = "tag" + (index + 1)
  }
  return {
    active: true,
    name: name,
    left: (w * offset[0]) / 2,
    top: (h * offset[1]) / 2,
    points: [
      {
        x: 0,
        y: 0,
      },
      {
        x: w,
        y: 0,
      },
      {
        x: w,
        y: h,
      },
      {
        x: 0,
        y: h,
      },
    ],
  }
}
