/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"
import async from "async"
import alasql from "alasql"

angular
  .module("uCountitUiApp")
  .controller(
    "StoreFootfallCtrl",
    function (
      $scope,
      $state,
      $stateParams,
      $window,
      $q,
      $element,
      ApiSrv,
      usSpinnerService,
      ChartOptionSrv,
      GridOptionSrv,
      Locale,
      NgTableParams,
      StoreGroupSrv,
      Auth,
      DateTimeSrv,
      SamplingSrv,
      SelectorSrv,
      uiGridExporterService,
      UtilSrv,
      rangePickerCacheSrv,
      HeaderSrv
    ) {
      let key, sampling
      const SAMPLING = {
        HOUR: "hour",
        DAY: "day",
        MONTH: "month",
      }

      $scope.timeShortcuts = ["24H", "7D", "3M", "6M", "12M"]

      $scope.samplingList = (() => {
        const result = []
        for (key in SAMPLING) {
          sampling = SAMPLING[key]
          result.push({ key: sampling, title: `By ${sampling}` })
        }
        return result
      })()

      $scope.header = {
        timefrom: Locale.string("Time from"),
        entrance: Locale.string("Entrance"),
      }

      $scope.validTime = false

      const loadingQueue = []
      const samplingList = ["hour", "day", "week", "month"]

      $scope.pageOptions = {
        storeId: $stateParams.id,
        sampling: SAMPLING.DAY,
        entrance: {
          with: [0],
        },
      }

      $scope.pickerOptions = {
        sampling: $scope.pageOptions.sampling,
        rangeMaxDate: moment(),
      }

      $scope.tableParams = new NgTableParams({}, { data: [] })

      let startFlag = false

      const initialize = function () {
        $scope.gridData = []
        $scope.chartData = []
        $scope.countList = []
        $scope.tableParams = new NgTableParams({}, { data: [] })

        return (startFlag = true)
      }

      const setDatetimeConfiguration = function (timezone) {
        if (!$scope.pageOptions) {
          return
        }
        if (!$scope.pickerOptions) {
          return
        }

        const today = DateTimeSrv.getLocalTimeByTimezone(timezone)
        const configuration = DateTimeSrv.getDateTimeConfiguration(today)

        $scope.pageOptions.dt = configuration.dt
        $scope.pickerOptions.rangeMaxDate = configuration.maxDate

        const res = rangePickerCacheSrv.getCachedTimeByTimezone(timezone, "footfall")
        if (res) {
          $scope.pageOptions.dt = res.dt
          $scope.pickerOptions.sampling = res.sampling
          return ($scope.pageOptions.sampling = res.sampling)
        }
      }

      const setDateRangeByShortcut = function (timeType) {
        const parsingTimeRange = UtilSrv.parseTimeRange(timeType, $scope.pageOptions.dt.endDate)
        ;({ sampling } = parsingTimeRange)
        const dt = angular.copy($scope.pageOptions.dt)
        dt.startDate = parsingTimeRange.from
        dt.endDate = parsingTimeRange.to
        $scope.pickerOptions.sampling = sampling
        $scope.pageOptions.sampling = sampling
        return ($scope.pageOptions.dt = dt)
      }

      const showLoading = function (key) {
        if (key) {
          loadingQueue.push(key)
        }
        angular.element(".splash").show()
        usSpinnerService.spin("spinner")
        return angular.element(".spinner").css("position", "fixed")
      }

      const hideLoading = function (key) {
        if (key) {
          _.remove(loadingQueue, (raw) => raw === key)
          if (loadingQueue.length) {
            return
          }
        }

        angular.element(".splash").hide()
        return usSpinnerService.stop("spinner")
      }

      const samplingKey = function (val) {
        switch (val) {
          case "hour":
            return "1h"
          case "day":
            return "1d"
          case "week":
            return "1w"
          case "month":
            return "1M"
          default:
            return "1d"
        }
      }

      const getDefaultData = function (start, end, sampling) {
        const data = []

        const dateFormat = "YYYYMMDD"
        const from = moment(start).format(dateFormat)

        const samplingStart = moment(start).startOf(sampling)
        const samplingEnd = moment(end).startOf(sampling)

        const dataLength = samplingEnd.diff(samplingStart, sampling)

        __range__(0, dataLength, false).forEach(function (idx) {
          const newDate = moment.utc(from, dateFormat).add(idx, sampling)
          return data.push([newDate, 0])
        })

        return {
          data: data,
          totalCount: 0,
          startDate: start,
          endDate: end,
          sampling: sampling,
        }
      }

      const getStoreCountData = function (chartData, options) {
        const deffered = $q.defer()
        const localStartDate = moment.utc(options.from, "YYYYMMDDHHmm")
        const localEndDate = moment.utc(options.to, "YYYYMMDDHHmm")
        const splitJobLength = 30 // 1 month = 30day
        const splitJobCount = Math.ceil(localEndDate.diff(localStartDate, "day") / splitJobLength)

        async.times(
          splitJobCount,
          function (n, callback) {
            const start = moment(localStartDate).add(n * splitJobLength, "days")
            let end = moment(start).add(splitJobLength, "days")
            if (end.isAfter(localEndDate)) {
              end = localEndDate
            }
            options.from = start.format("YYYYMMDDHHmm")
            options.to = end.format("YYYYMMDDHHmm")
            return ApiSrv.getContentsData(options).then((response) =>
              callback(
                null,
                !__guard__(response != null ? response.data : undefined, (x) => x.length)
                  ? null
                  : response
              )
            )
          },
          function (error, responses) {
            if (error) {
              return deffered.reject(error)
            }

            let validResponseCount = 0

            for (var res of Array.from(responses)) {
              if (!res) {
                continue
              }
              validResponseCount += 1
              _.forEach(res.data, function (raw) {
                ;({ sampling } = chartData)
                const rowDate = moment.utc(raw.statisticFor).startOf(sampling)
                const idx = rowDate.diff(localStartDate, sampling)

                if (chartData.data[idx]) {
                  if (sampling === "hour") {
                    chartData.data[idx][1] = raw.count
                    if (chartData.beforeDay !== moment.utc(raw.statisticFor).date()) {
                      chartData.beforeCount = 0
                    }

                    chartData.data[idx][1] -= chartData.beforeCount
                    chartData.beforeCount = raw.count
                    chartData.beforeDay = moment.utc(raw.statisticFor).date()

                    if (chartData.data[idx][1] < 0) {
                      chartData.data[idx][1] = 0
                    }
                    return (chartData.totalCount += chartData.data[idx][1])
                  } else {
                    chartData.data[idx][1] += raw.count
                    return (chartData.totalCount += raw.count)
                  }
                }
              })
            }
            if (!validResponseCount) {
              chartData.data = null
            }
            return deffered.resolve(chartData)
          }
        )

        return deffered.promise
      }

      const adjustPeriod = function () {
        let samplingIdx
        const { dt } = $scope.pageOptions
        const dayRange = moment(dt.endDate).diff(dt.startDate, "day") + 1

        const samplingDom = angular.element(".chart-sampling")
        const optionName = "entrance"

        samplingDom.find("label").css("display", "inline")

        if (dayRange > 7) {
          samplingIdx = _.indexOf(samplingList, "hour")
          samplingDom.find("label").eq(samplingIdx).css("display", "none")
          if (_.indexOf(samplingList, $scope.pageOptions[optionName].sampling) <= samplingIdx) {
            $scope.pageOptions[optionName].sampling = "day"
          }
        }

        if (dayRange > 185) {
          samplingIdx = _.indexOf(samplingList, "day")
          samplingDom.find("label").eq(samplingIdx).css("display", "none")
          if (_.indexOf(samplingList, $scope.pageOptions[optionName].sampling) <= samplingIdx) {
            return ($scope.pageOptions[optionName].sampling = "week")
          }
        }
      }

      const setChartOptions = function () {
        ;({ sampling } = $scope.pageOptions)
        const { dt } = $scope.pageOptions
        const tickSize = SamplingSrv[sampling].getTickSize(dt.startDate, dt.endDate)
        const minTickSize = SamplingSrv[sampling].getMinTickSize()

        $scope.chartOption.xaxis.tickFormatter = tickFormatterFunc
        $scope.chartOption.xaxis.tickSize = [tickSize, sampling]
        return ($scope.chartOption.xaxis.minTickSize = [minTickSize, sampling])
      }

      var tickFormatterFunc = function (val, axis) {
        ;({ sampling } = $scope.pageOptions)
        const date = moment.utc(val)
        return Locale.dateTime(date, SamplingSrv[sampling].getTickFormat(val))
      }

      const setChart = function (dataList) {
        const dataSet = []
        _.forEach(dataList, function (idx, n) {
          if (!$scope.countList[idx]) {
            return
          }

          const data = angular.copy($scope.countList[idx])
          data["lines"] = { fill: n === 0 }
          data.highlightColor = ChartOptionSrv.chartColorList[n]
          return dataSet.push(data)
        })

        _.merge(chartOption, {
          legend: {
            container: angular.element("#entranceLegend"),
            noColumns: 0,
          },
        })

        if (!dataList.length || !dataSet.length) {
          legendEle.addClass("invisible")
        } else {
          if (legendEle.hasClass("invisible")) {
            legendEle.removeClass("invisible")
          }
        }

        $scope.chartOption = chartOption
        return ($scope.chartData = dataSet)
      }

      let options = {}

      const load = function () {
        if (!$scope.pageOptions.dt) {
          return
        }
        adjustPeriod()
        ;({ sampling } = $scope.pageOptions)
        const startDate = moment($scope.pageOptions.dt.startDate)
        const endDate = moment($scope.pageOptions.dt.endDate)

        const newOptions = {
          id: $scope.pageOptions.storeId,
          from: startDate.format("YYYYMMDDHHmm"),
          to: endDate.add(1, "days").startOf("day").format("YYYYMMDDHHmm"),
          sampling: samplingKey(sampling),
        }

        if (_.isEqual(options, newOptions)) {
          return
        }

        options = newOptions
        const entranceChartData = _.merge(
          {
            label: Locale.string("Entrance"),
          },
          getDefaultData(startDate, endDate, sampling)
        )

        const gridSet = []
        if (startFlag) {
          showLoading("entrance")
        }

        const params = {
          entrance: true,
          orgType: $stateParams.orgType,
        }

        return getStoreCountData(entranceChartData, _.merge({}, options, params)).then(
          function (result) {
            if (!result.data || !(result.data != null ? result.data.length : undefined)) {
              console.warn("No Data")
              initialize()
              hideLoading("entrance")
              return
            }

            const entranceData = entranceChartData.data

            entranceData.forEach(function (data, idx) {
              const date = moment.utc(data[0])
              return gridSet.push({
                timefrom: SamplingSrv[sampling].getFormatDate(date),
                isoDate: SamplingSrv[sampling].getIsoDayDate(date),
                entrance: data[1],
              })
            })

            $scope.gridData = gridSet
            const pageRowsArr = SamplingSrv[sampling].getPageRowType()
            $scope.tableParams = new NgTableParams(
              { count: pageRowsArr[0] },
              { counts: pageRowsArr, data: $scope.gridData }
            )

            $scope.countList = [entranceChartData]
            setChart($scope.pageOptions.entrance.with)

            if (entranceData.length > 70) {
              $scope.chartOption.lines.lineWidth = 1
              $scope.chartOption.points.show = false
            } else {
              chartOption.lines.lineWidth = 3
              chartOption.points.show = true
            }

            setChartOptions()

            startFlag = true
            return hideLoading("entrance")
          },

          function (err) {
            if (err) {
              console.error(err)
              initialize()
              hideLoading("entrance")
              return
            }
          }
        )
      }

      var legendEle = angular.element("#entranceLegend", $element)

      var chartOption = _.merge(
        {
          yaxis: { min: 0 },
        },
        ChartOptionSrv.lineChartOption
      )

      $scope.chartOption = chartOption

      $scope.searchHandler = function (buttonType, options = null) {
        if (!$scope.pageOptions.dt) {
          return
        }
        rangePickerCacheSrv.putCacheTime(
          $scope.pageOptions.dt,
          $scope.pageOptions.sampling,
          "footfall"
        )

        switch (buttonType) {
          case "shortcut":
            setDateRangeByShortcut(options)
            break
          case "sampling":
            $scope.pickerOptions.sampling = options
            break
        }

        return load()
      }

      const init = () =>
        SelectorSrv.getOrganizationInfo($stateParams.orgType, $stateParams.id).then(
          function (orgInfo) {
            $scope.currentOrg = orgInfo.store
            $scope.currentOrg.type = $stateParams.orgType
            $scope.storetimezone = orgInfo.timezone
            setDatetimeConfiguration(orgInfo.timezone)
            $scope.validTime = true

            HeaderSrv.fetchCurrentCompany().then(
              (company) =>
                ($scope.export = function (type) {
                  const { dt } = $scope.pageOptions
                  ;({ sampling } = $scope.pageOptions)
                  const companyName = company.name

                  const period = `${dt.startDate.format("YYYYMMDD")}_${dt.endDate.format(
                    "YYYYMMDD"
                  )}`
                  let filename = `[footfall ${$stateParams.orgType}]_[${companyName}]_[${$scope.currentOrg.name}]_[${period}]_[${sampling}]`

                  switch (type) {
                    case "csv":
                      var csvData = "isoDate,entrance\n"
                      _.forEach(
                        $scope.gridData,
                        (row) => (csvData += [`${row.isoDate}`, `${row.entrance}`, "\n"].join(","))
                      )

                      filename += ".csv"
                      return uiGridExporterService.downloadFile(filename, csvData, true)
                    case "excel":
                      filename += ".xlsx"
                      var headerInfo = {
                        isoDate: "isoDate",
                        entrance: "entrance",
                      }
                      var excelData = [headerInfo].concat($scope.gridData)
                      return alasql(
                        'SELECT * INTO XLSX("' + filename + '",{headers: false}) FROM ?',
                        [excelData]
                      )
                    default:
                      return false
                  }
                })
            )

            return load()
          },
          function (err) {
            console.error(err)
            return $state.go("default")
          }
        )

      return init()
    }
  )

function __range__(left, right, inclusive) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}
function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
