/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

import env from "../../../config/environment"

angular
  .module("uCountitUiApp")
  .controller(
    "StoreOverviewCtrl",
    function ($scope, $state, $stateParams, DateTimeSrv, SelectorSrv, HeaderSrv, me, contentSrv) {
      $scope.SHOWDATA = {
        PASS: "pass",
        DWELL: "dwell",
        DUAL: "dual",
      }
      $scope.me = me
      HeaderSrv.fetchCurrentCompany().then((company) => ($scope.company = company))

      $scope.orgType = $stateParams.orgType
      $scope.orgId = $stateParams.id
      $scope.pageOptions = {
        sampling: "day",
        referenceDay: undefined,
        content: "overview",
      }

      $scope.useFootfall = false
      $scope.useFace = false
      $scope.useHeatmap = false
      $scope.useSales = false

      const init = () =>
        SelectorSrv.getOrganizationInfo($stateParams.orgType, $stateParams.id)
          .then(function (orgInfo) {
            const { features } = orgInfo
            $scope.useFootfall = features.footfall
            $scope.useFace = features.face && !env.isLocalgrey
            $scope.useHeatmap = features.heatmap || features.dwellmap
            $scope.useSales = features.sales && !env.isLocalgrey
            $scope.useFaceCountOnly = orgInfo.useFaceCountOnly
            $scope.heatmapType =
              features.heatmap && !features.dwellmap
                ? $scope.SHOWDATA.PASS
                : !features.heatmap && features.dwellmap
                ? $scope.SHOWDATA.DWELL
                : $scope.SHOWDATA.DUAL

            $scope.currentOrg = orgInfo.store
            if ($scope.currentOrg?.features?.pathtree) {
              $scope.viewTemplate = "pathtree"
            } else {
              $scope.viewTemplate = "std"
            }
            $scope.timezone = orgInfo.timezone
            $scope.cameras = orgInfo.sensors
            $scope.heatmapWeight = orgInfo.store.heatmapWeight
            $scope.heatmapStats = orgInfo.store.stats

            $scope.noFeatures = true
            return _.forEach(features, function (feature) {
              if (feature === true) {
                return ($scope.noFeatures = false)
              }
            })
          })
          .then(
            () => $scope.$broadcast("init-date-search", $scope.timezone),

            function (err) {
              if (err) {
                console.error(err)
              }
              return $state.go("default")
            }
          )

      return angular.element(() => init())
    }
  )
