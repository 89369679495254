/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

import Promise from "bluebird"
import moment from "moment-timezone"

angular
  .module("uCountitUiApp")

  .controller(
    "ReportStatusCtrl",
    function ($rootScope, $cookieStore, $scope, $filter, ApiSrv, Locale, NgTableParams) {
      $scope.Items = []

      $scope.header = {
        report: Locale.string("Report Name"),
        reportDate: Locale.string("Report Date"),
        startedAt: Locale.string("Started At"),
        builtAt: Locale.string("Built At"),
        buildingTime: Locale.string("Build Time"),
        status: Locale.string("Status"),
      }

      const isActiveCompanyReport = function (id) {
        const company = $scope.Companies.find((c) => c._id === id)
        return (
          company &&
          company.active &&
          company.exportData.active &&
          _.some(company.reports, { active: true })
        )
      }

      const getStatusCode = function (status) {
        switch (status) {
          case "building":
            return 1
          case "waiting":
            return 2
          case "built":
            return 3
          case "sent":
            return 4
          default: //unknown
            return 0
        }
      }

      //#########################################################################
      // table
      $scope.order = function (rowName) {
        if ($scope.Items.length < 1) {
          return
        }
        $scope.sorting = rowName
        return $scope.tableParams.reload()
      }

      const getData = function (params) {
        if ($scope.sorting) {
          return $filter("orderBy")($scope.Items, $scope.sorting)
        } else {
          return $filter("orderBy")($scope.Items, ["statusCode", "-builtAt"])
        }
      }

      const loadItems = function (items) {
        if (items != null) {
          $scope.Items = items
            .filter((item) => item.reportType === "excel")
            .filter((item) => isActiveCompanyReport(item._companyId))
        }

        for (var v of Array.from($scope.Items)) {
          v.buildingTimeStr = moment.utc(v.buildingTime * 1000).format("HH:mm:ss")
          v.reportDateStr = moment(v.date).format("YYYY-MM-DD")
          v.builtAtStr = v.builtAt ? moment(v.builtAt).format("MM-DD HH:mm") : "-"
          v.startedAtStr = v.startedAt ? moment(v.startedAt).format("MM-DD HH:mm") : "-"
          v.statusCode = getStatusCode(v.status)
          v.statusStr = _.capitalize(v.status)
        }

        if ($scope.tableParams != null) {
          $scope.tableParams.reload()
        }

        if ($scope.tableParams == null) {
          const params = {}
          const settings = { counts: [], getData }
          return ($scope.tableParams = new NgTableParams(params, settings))
        }
      }

      $scope.reload = function (isCache) {
        if (isCache == null) {
          isCache = true
        }
        $scope.sorting = null
        return Promise.all([ApiSrv.getCompanies(), ApiSrv.getReportBuildStatus()])
          .spread(function (companies, buildStatus) {
            $scope.Companies = companies
            return loadItems(buildStatus)
          })
          .catch((ex) => console.error("reload error", ex))
      }

      return $scope.reload()
    }
  )
