import angular from "angular"
import CompanyClass from "../../lib/org/company"
import StoreClass from "../../lib/org/store"
import StoreGroupClass from "../../lib/org/store-group"
import {
  getCompanyId,
  setCompanyId,
  getStoreId,
  setStoreId,
  getSensorId,
  setSensorId,
} from "../../lib/header"
import Promise from "bluebird"
import _ from "lodash"
import EventEmitter from "events"

angular.module("uCountitUiApp").service("HeaderSrv", (Locale, RestApi, Auth, UtilSrv) => {
  const { Company, Store, StoreGroup, Sensor } = RestApi

  const sortByName = (collection) => {
    return _.sortBy(collection, ["name"])
  }

  const companyMap = (company) => {
    return RestApi.Agency.get(company._agencyId)
      .then((agency) => {
        company.agency = agency
        return company
      })
      .catch((err) => {
        console.warn(`Company ${company.name} has no agency`, err)
        return null
      })
  }

  const fetchAllCompany = (cache = true) => {
    return Promise.filter(
      Promise.map(Company.index(cache).then(sortByName), companyMap),
      (company) => company != null
    )
  }

  const getFallbackCompany = (cache = true) => {
    return fetchAllCompany(cache).then((companies) => {
      if (companies.length < 1) return Promise.reject(new Error("No Company"))
      setCompanyId(companies[0]._id)
      return companies[0]
    })
  }
  const fetchCurrentCompany = (cache = true) => {
    return Auth.fetchCurrentAccount().then((account) => {
      const companyId = getCompanyId(account)
      if (companyId) {
        return Company.get(companyId, cache)
          .then(companyMap)
          .catch((err) => {
            console.warn(err)
            return getFallbackCompany(cache)
          })
      } else {
        return getFallbackCompany(cache)
      }
    })
  }

  const fetchAllStore = () => {
    return fetchCurrentCompany().then((company) => {
      const data = _.clone(company.data)
      data.name = Locale.string("My Stores")
      data._companyId = data._id
      company = new CompanyClass(data, RestApi)
      return companyMap(company).then((company) => {
        return Promise.all([
          Store.index(true, { params: { companyId: company._id } }),
          StoreGroup.index(true, { params: { companyId: company._id } }),
        ]).spread((stores, storeGroups) => {
          let ret = [company]
          const groups = storeGroups.filter((obj) => !UtilSrv.isDefaultStoreGroup(obj.name))
          let defaultGroup = storeGroups.find((obj) => UtilSrv.isDefaultStoreGroup(obj.name))
          if (defaultGroup) {
            defaultGroup = _.clone(defaultGroup)
            defaultGroup.name = UtilSrv.getDefaultStoreGroupName()
            ret.push(defaultGroup)
          }

          ret = ret.concat(sortByName(groups)).concat(sortByName(stores))
          return ret
        })
      })
    })
  }

  const fetchCurrentStore = () => {
    return fetchCurrentCompany().then((company) => {
      const companyId = company._id
      const storeId = getStoreId()
      const alt = () => {
        return fetchAllStore().then((stores) => {
          let store = null
          if (storeId) {
            store = stores.find((s) => s._id == storeId)
          }
          if (!store) store = stores[0]
          return store
        })
      }
      if (companyId == storeId) return alt()
      return Store.get(storeId)
        .then((store) => {
          if (store._companyId != companyId) throw new Error("Not match company with current store")
          return store
        })
        .catch((err) => alt())
    })
  }

  const fetchAllSensor = () => {
    return fetchCurrentStore().then((store) => {
      const data = _.clone(store.data)
      data.name = Locale.string("All Sensors")
      data._storeId = store._id
      if (store.orgType == StoreClass.ORG_TYPE) {
        store = new StoreClass(data, RestApi)
      } else if (store.orgType == StoreGroupClass.ORG_TYPE) {
        store = new StoreGroupClass(data, RestApi)
      } else if (store.orgType == CompanyClass.ORG_TYPE) {
        store = new CompanyClass(data, RestApi)
      } else {
        return Promise.reject(new Error(`Invalid store ${store.name}`))
      }
      return Sensor.index(true, { params: { companyId: store._companyId } })
        .then(sortByName)
        .then((sensors) => {
          if (store.orgType == StoreClass.ORG_TYPE) {
            sensors = sensors.filter((s) => s._storeId == store._id)
          } else if (store.orgType == StoreGroupClass.ORG_TYPE) {
            sensors = sensors.filter((s) => store.storeList.indexOf(s._id) >= 0)
          }
          return [store].concat(sensors)
        })
    })
  }

  const fetchCurrentSensor = () => {
    const sensorId = getSensorId()

    return fetchAllSensor()
      .then(sortByName)
      .then((sensors) => {
        let sensor = null
        sensor = sensors.find((s) => s._id == sensorId)
        if (!sensor) sensor = sensors[0]

        return sensor
      })
  }

  class ReloadEmitter extends EventEmitter {
    reload() {
      this.emit("reload")
    }
  }

  const emitter = new ReloadEmitter()

  return {
    fetchAllCompany,
    fetchAllStore,
    fetchAllSensor,
    setCompanyId,
    getCompanyId,
    setStoreId,
    getStoreId,
    getSensorId,
    setSensorId,
    fetchCurrentCompany,
    fetchCurrentStore,
    fetchCurrentSensor,
    emitter,
  }
})
