"use strict"

import Promise from "bluebird"
import angular from "angular"
import { createFormClass } from "./form"

const S3FileApiNewCtrl = ($scope, $http, $state, Auth, RestApi) => {
  const Form = createFormClass($http)

  Promise.all([RestApi.Company.index(), RestApi.Store.index()]).spread((companies, stores) => {
    const form = new Form(companies, stores)
    const submitFunc = form.submit.bind(form)
    form.submit = (event) =>
      submitFunc(event).then((res) => {
        $state.go("app.admin.s3fileapi", {}, { reload: true })
      })
    $scope.form = form
  })
}

angular.module("uCountitUiApp").controller("S3FileApiNewCtrl", S3FileApiNewCtrl)
