/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .controller("OperationCtrl", function ($rootScope, $scope, Auth, ApiSrv, me) {
    $scope.me = me
    if (!me.gteDevAdmin()) {
      return
    }

    $scope.isEmergencyStop = false

    ApiSrv.ServerGlobalConfig("emergencystop").then(
      function (res) {
        if (res.value) {
          return ($scope.isEmergencyStop = true)
        }
      },
      (err) => console.error("err: ", err)
    )

    return ($scope.emergencyStop = function () {
      if ($scope.isEmergencyStop) {
        return
      }

      return ApiSrv.ServerGlobalConfig("emergencystop", { value: true }).then(
        (res) => ($scope.isEmergencyStop = true),
        (err) => console.error("err: ", err)
      )
    })
  })
