"use strict"

import Promise from "bluebird"
import { getToken } from "../servicetoken"
import { clone } from "../class-utils"

function Sensor(sensorData, Api) {
  this.kFeatures = [
    "counter",
    "zonetraffic",
    "heatmap",
    "dwellmap",
    "face",
    "rtFace",
    "queue",
    "directionmap",
    "flowmap",
    "occupancy",
    "trackingtree",
    "groupFace",
  ]
  this.data = sensorData
  this.orgType = Sensor.ORG_TYPE
  this.Api = Api
  this.data = sensorData
  for (const k in sensorData) {
    this[k] = sensorData[k]
  }

  this.functionality = null

  return this
}

Sensor.prototype.clone = function () {
  return clone(this)
}

const apipath = (id) => {
  return `/api/1.0/camera/${id}`
}

Sensor.prototype.thumbnailUrl = function (size = "") {
  let query = ""
  let params = ""
  const serviceToken = getToken()
  if (serviceToken) query = `?service_token=${serviceToken}`
  if (size) params = `/${size}`
  return `${apipath(this.data._id)}/thumbnail${params}${query}`
}

Sensor.prototype.snapshotUrl = function () {
  let query = ""
  const serviceToken = getToken()
  if (serviceToken) query = `?service_token=${serviceToken}`
  return `${apipath(this.data._id)}/snapshot${query}`
}

Sensor.prototype.heatmapUrl = function () {
  let query = ""
  const serviceToken = getToken()
  if (serviceToken) query = `?service_token=${serviceToken}`
  return `${apipath(this.data._id)}/heatmap/snapshot${query}`
}

Sensor.prototype.backgroundImageUrl = function (mode = "snapshot") {
  let query = `&mode=${mode}`
  const serviceToken = getToken()
  if (serviceToken) query = `&service_token=${serviceToken}`
  return `${apipath(this.data._id)}/image?${query}`
}

Sensor.prototype.getOwnFunctionality = function (functionality = {}) {
  this.kFeatures.forEach((feature) => {
    if (this.data.functions.use[feature]) functionality[feature] = true
  })
  return functionality
}

Sensor.prototype.getFunctionality = function (defaultFunctionality = {}) {
  let ret = null
  if (this.functionality == null) {
    if (this.Api) {
      ret = this.functionality = Promise.all([
        this.Api.Company.get(this.data._companyId),
        this.Api.Store.get(this.data._storeId),
      ]).spread((company, store) => {
        let functionality = company.getOwnFunctionality()
        functionality = store.getOwnFunctionality(functionality)

        return this.getOwnFunctionality(functionality)
      })
    } else {
      ret = Promise.resolve(this.getOwnFunctionality())
    }
  } else {
    ret = this.functionality
  }

  return ret.then((ownFunctionality) => {
    return Object.assign({}, ownFunctionality, defaultFunctionality)
  })
}

Sensor.ORG_TYPE = "sensor"

export default Sensor
