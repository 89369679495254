import DbApi from "common-library/types/DbApi"
import angular, { IScope } from "angular"
import _ from "lodash"
import IoT from "common-library/types/IoT"
import { Nullable } from "common-library/utils/types"

type VcaConfigFormDetectionPoint = {
  name: "Center" | "Bottom"
  value: IoT.Tracker.DetectionPoint
}

const ENGINE_TR: { [k in IoT.TrackingEngine.Id]: string } = {
  dl_object_tracker: "DLOT",
  dl_people_tracker: "DLPT",
  object_tracker: "OT",
  dl_skeleton_tracker: "DLST",
  unknown_tracker: "Unknown Tracker",
}

type VcaConfigFormTrackingEngine = {
  name: string
  value?: IoT.TrackingEngine.Id
}

type VcaConfigForm = Nullable<{
  detectionPoint?: VcaConfigFormDetectionPoint
  trackingEngine?: VcaConfigFormTrackingEngine
}>

export type VcaConfigFormData = Nullable<Omit<DbApi.VcaConfig.Data, "_cameraId" | "_id">>

interface ControllerScope extends IScope {
  data?: VcaConfigFormData
  deviceVca?: IoT.Device.Description["properties"]["vca"]
  licenses?: IoT.Device.License[]
  noLicenseValidation?: boolean
  availTrackingEngines: IoT.TrackingEngine.Id[]
  isNew: boolean
  onChange: (data: VcaConfigFormData) => void

  form: VcaConfigForm
  detectionPoints: VcaConfigFormDetectionPoint[]
  trackingEngines: VcaConfigFormTrackingEngine[]
  sensorsAsDefault?: boolean
}

angular.module("uCountitUiApp").directive("vcaConfigForm", () => ({
  restrict: "E",
  templateUrl: "components/vcaconfig/VcaConfigForm.html",
  scope: {
    data: "=",
    onChange: "=",
    licenses: "=",
    deviceVca: "=",
    noLicenseValidation: "=",
    availTrackingEngines: "=",
    isNew: "=",
    sensorsAsDefault: "=?",
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controller: ($scope: ControllerScope, Locale: any) => {
    let didSetDefault = false
    $scope.$watchGroup(
      ["data", "deviceVca", "license", "noLicenseValidation", "availTrackingEngines", "isNew"],
      () => {
        if ($scope.data) {
          updateForm($scope.data)
        }
      }
    )

    $scope.$watch<VcaConfigForm>(
      "form",
      (newVals: VcaConfigForm, oldVals: VcaConfigForm) => {
        if (!angular.equals(newVals, oldVals)) {
          $scope.handleChange()
        }
      },
      true
    )

    function updateForm(data: VcaConfigFormData) {
      if (!$scope.form) {
        $scope.form = {}
      }

      updateTrackingEngineOptions()

      if ($scope.isNew && !didSetDefault) {
        $scope.form.trackingEngine = $scope.trackingEngines[0]
        updateDpOptions()
        didSetDefault = true
      } else {
        $scope.form.trackingEngine = getTrackingEngineOption(data.trackingEngine)
        updateDpOptions()
        $scope.form.detectionPoint = getDetectionPointOption(data.detectionPoint)
      }
    }

    function getTrackingEngineOption(
      trackingEngine?: IoT.TrackingEngine.Id | null
    ): VcaConfigFormTrackingEngine {
      return (
        $scope.trackingEngines.find((te) => te.value == trackingEngine) ??
        $scope.trackingEngines[$scope.trackingEngines.length - 1]
      )
    }

    function getDetectionPointOption(
      dpValue?: IoT.Tracker.DetectionPoint | null
    ): VcaConfigFormDetectionPoint {
      return $scope.detectionPoints.find((dp) => dp.value == dpValue) ?? $scope.detectionPoints[0]
    }

    function updateTrackingEngineOptions() {
      let trackingEngines: IoT.TrackingEngine.Id[] = []
      if ($scope.noLicenseValidation) {
        trackingEngines = $scope.deviceVca?.trackingEngines.map((te) => te.id) ?? []
      } else {
        trackingEngines = _.intersection(
          _.uniq($scope.licenses?.map((l) => l.trackingEngines).flat() ?? []),
          $scope.deviceVca?.trackingEngines.map((te) => te.id) ?? ([] as IoT.TrackingEngine.Id[])
        )
      }

      $scope.trackingEngines = trackingEngines.map((v) => ({
        name: ENGINE_TR[v],
        value: v,
      })) as VcaConfigFormTrackingEngine[]

      const sensorsSetting = {
        name: Locale.string("t18n use sensor's setting"),
        value: undefined,
      }

      if ($scope.sensorsAsDefault) {
        $scope.trackingEngines.unshift(sensorsSetting)
      } else {
        $scope.trackingEngines.push(sensorsSetting)
      }

      if ($scope.availTrackingEngines && $scope.availTrackingEngines.length > 0) {
        $scope.trackingEngines = $scope.trackingEngines.filter(
          (te) => te.value && $scope.availTrackingEngines.includes(te.value)
        )
      }
    }

    function updateDpOptions() {
      const teId = $scope.form?.trackingEngine?.value
      let dps: IoT.Tracker.DetectionPoint[] | undefined = undefined
      if (teId) {
        dps = $scope.deviceVca?.trackingEngines.find((te) => te.id == teId)?.dps
      }
      if (teId && dps) {
        $scope.detectionPoints = dps.map((v) => ({
          name: Locale.string(v.charAt(0).toUpperCase() + v.slice(1)),
          value: v,
        })) as VcaConfigFormDetectionPoint[]
      } else {
        $scope.detectionPoints = []
      }
    }

    function form2data(form: Partial<VcaConfigForm>): VcaConfigFormData {
      return {
        detectionPoint: form.detectionPoint?.value ?? null,
        trackingEngine: form.trackingEngine?.value ?? null,
      }
    }

    $scope.handleChange = () => {
      $scope.onChange(form2data($scope.form))
    }
  },
}))
