"use strict"

import _ from "lodash"

export const createFormClass = ($http, Locale) => {
  class Form {
    constructor(agencies, companies, stores, value = null) {
      companies.forEach((c) => {
        const agency = agencies.find((a) => a._id == c._agencyId)
        if (agency) {
          c.agency = agency
          c.agencyName = c.agency.name
          c.display = `${c.agencyName} > ${c.name}`
        }
      })
      agencies.forEach((a) => {
        a.display = a.name
      })

      this.organizations = {
        agency: agencies,
        company: companies,
        store: stores,
      }
      this.orgTypes = [
        {
          name: Locale.string("Agency"),
          value: "agency",
        },
        {
          name: Locale.string("Company"),
          value: "company",
        },
      ]
      this.setValue(value)
    }

    onSelectOrgType(type) {
      if (
        !this.organizations[this.value.orgType.value].find(
          (o) => o._id == this.value.organization._id
        )
      ) {
        this.value.organization = this.organizations[this.value.orgType.value][0]
      }
    }

    setValue(value) {
      this.originalValue = value
      if (value) {
        // Transform db data to form data
        this.value = _.cloneDeep(value)
        this.value.orgType = this.orgTypes.find((ot) => ot.value == value.orgType)
        this.value.organization = this.organizations[this.value.orgType.value].find(
          (o) => o._id == value.organization
        )
      } else {
        this.value = {
          active: true,
          key: null,
          orgType: this.orgTypes[0],
          organization: this.organizations["agency"][0],
        }
      }

      return this.value
    }

    isEdit() {
      return this.originalValue ? true : false
    }

    isChanged() {
      if (!this.originalValue) return true
      return !_.isEqual(
        {
          ...this.value,
          organization: this.value.organization._id,
        },
        this.originalValue
      )
    }

    isValid() {
      return this.value.organization ? true : false
    }

    submit = (event) => {
      event.preventDefault()
      if (!this.isValid()) return
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      }
      let submitPromise
      if (!this.value._id) {
        submitPromise = $http.post(
          "/api/1.0/restfulapi",
          {
            orgType: this.value.orgType.value,
            organization: this.value.organization._id,
            active: this.value.active,
            memo: this.value.memo,
          },
          options
        )
      } else {
        submitPromise = $http.patch(
          `/api/1.0/restfulapi/${this.value._id}`,
          {
            active: this.value.active,
            memo: this.value.memo,
          },
          options
        )
      }
      return submitPromise.then((res) => this.setValue(res.data))
    }
  }

  return Form
}
