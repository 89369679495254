/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import _ from "lodash"
import angular from "angular"

import Promise from "bluebird"

angular
  .module("uCountitUiApp")

  .controller(
    "PremiumReportSettingCtrl",
    function ($scope, $uibModal, Auth, RestApi, NotificationSrv, Locale) {
      $scope.sendDelayList = [{ val: 0, title: "0" }]
      for (let i = 1; i < 13; i++) {
        $scope.sendDelayList.push({ val: i * 60, title: `+${i} Hour(s)` })
      }
      let original = null
      let originalNt = null
      const removedReport = []

      Auth.fetchCurrentAccount().then((auth) => ($scope.auth = auth))

      const defaultPremiumReport = function () {
        $scope.form = {
          premiumReport: {
            active: false,
          },
        }
        $scope.notificationForm = {
          report: {
            active: true,
            content: {
              premiumReport: false,
            },
            sendDelay: {
              premiumReport: 0,
            },
            useConfirmation: {
              premiumReport: true,
            },
          },
        }

        $scope.delayPremium = _.find($scope.sendDelayList, {
          val: $scope.notificationForm.report.sendDelay.premiumReport,
        })

        $scope.form_pr.$setPristine()
        return ($scope.errorMessage = "")
      }

      $scope.delayPremiumUpdate = function () {
        const tt = moment.tz(
          "02:00",
          "HH:mm",
          $scope.lastTimezoneStore != null ? $scope.lastTimezoneStore.timezone : undefined
        )
        const oldDay = tt.get("date")
        tt.add($scope.delayPremium.val, "minutes")
        const newDay = tt.get("date")
        let ss = ""
        if (oldDay !== newDay) {
          ss += "+1D "
        }

        return ($scope.premiumexpectTime = ss + tt.format("HH:mm Z"))
      }

      $scope.getReportName = function (report) {
        if (report.type === "excel" && !_.isEmpty(report != null ? report.excelName : undefined)) {
          const templateFileName = report.excelName.slice(0, report.excelName.lastIndexOf("."))
          return templateFileName
        } else {
          return report.name
        }
      }

      $scope.isShowContents = (config) => (config != null ? config : true)

      const updatePremiumReport = () => {
        setActiveReport()

        return RestApi.Company.update($scope.form._id, $scope.form).then(() => {
          original = _.cloneDeep($scope.form)
          $scope.onApply(true)
        })
      }

      const openPremiumReportForm = function (report = null) {
        const adReportFormModal = $uibModal.open({
          animation: false,
          templateUrl: "app/admin/company/premium-report/ad-report-form.html",
          controller: "AdReportFormCtrl",
          backdrop: "static",
          size: "lg",
          scope: $scope,
          resolve: {
            currentReport() {
              return report
            },
            reportList() {
              return $scope.form.reports
            },
            storeList() {
              return $scope.storeList
            },
            companyEmailPremiumReportStatus() {
              return $scope.notificationForm.report.content.premiumReport
            },
          },
        })

        return adReportFormModal.result.then(function (result) {
          if (result.action === "modify") {
            const item = _.find($scope.form.reports, (i) => i.name === result.original.name)
            if (item) {
              const newItem = result.data
              item.type = newItem.type
              item.name = newItem.name
              item.url = newItem.url
              item.sheetcount = newItem.sheetcount
              item.layout = newItem.layout
              item.period = newItem.period
              item.reportday = newItem.reportday
              item.excelName = newItem.excelName
              item.excelContent = newItem.excelContent
              item.emails = newItem.emails
              item.companyFlag = newItem.companyFlag
              item.storeFlag = newItem.storeFlag
              item.storeEmails = newItem.storeEmails
            }
          } else {
            $scope.form.reports.push(result.data)
          }

          updatePremiumReport()

          return
        })
      }

      $scope.addPremiumReport = () => openPremiumReportForm()

      $scope.adReportActiveModify = (report) => openPremiumReportForm(report)

      $scope.removePremiumReport = function (report) {
        const useRemove = confirm(Locale.string("msg_confirm_remove"))
        if (useRemove) {
          const { reports } = $scope.form
          removedReport.push(report)
          _.remove(reports, (item) => item.name === report.name)
          updatePremiumReport()

          return
        }
      }

      $scope.canSubmit = function () {
        if (!$scope.isOpen) {
          return
        }

        return (
          $scope.form_pr.$valid &&
          !(
            angular.equals($scope.form, original) &&
            angular.equals($scope.notificationForm, originalNt)
          )
        )
      }

      const setActiveReport = () =>
        $scope.form.reports.forEach(
          (element) => (element.active = $scope.form.premiumReport.active)
        )

      $scope.submitForm = function () {
        setActiveReport()
        $scope.notificationForm.report.sendDelay.premiumReport =
          $scope.delayPremium != null ? $scope.delayPremium.val : undefined

        return Promise.all([
          RestApi.Company.update($scope.form._id, $scope.form),
          NotificationSrv.Company.update(
            { id: $scope.notificationForm._parentId },
            $scope.notificationForm
          ),
        ]).then(() => $scope.onApply())
      }

      $scope.changePRActive = function () {
        if ($scope.form.premiumReport.active === false) {
          return ($scope.notificationForm.report.content.premiumReport = false)
        }
      }

      const init = function (item) {
        original = null
        originalNt = null
        $scope.premiumexpectTime = ""
        $scope.lastTimezoneStore = _.minBy($scope.storeList, "timeOffset")
        $scope.companyName = item.name
        defaultPremiumReport()

        return NotificationSrv.Company.get({ id: item._id, _: Date.now() }, function (nt) {
          _.merge($scope.notificationForm, nt)
          $scope.notificationForm.report.active = true //UCNT-4050, always set to True
          originalNt = _.cloneDeep($scope.notificationForm)
          $scope.delayPremium = _.find($scope.sendDelayList, {
            val: $scope.notificationForm.report.sendDelay.premiumReport,
          })
          $scope.delayPremiumUpdate()

          _.merge($scope.form, item.data)
          $scope.form.premiumReport = {
            active: __guard__(_.first(item.data.reports), (x) => x.active),
          }
          return (original = _.cloneDeep($scope.form))
        })
      }

      return $scope.$watch("selectedItem", function (item) {
        if (!item) {
          return
        }

        return init(item)
      })
    }
  )

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}
