"use strict"

import _ from "lodash"

export const createFormClass = ($http) => {
  class Form {
    constructor(companies, stores, value = null) {
      companies.forEach((c) => {
        if (c.agency) c.agencyName = c.agency.name
      })
      this.companies = companies
      this.stores = stores
      this.setValue(value)
    }

    setValue(value) {
      this.originalValue = value
      if (value) {
        this.value = _.cloneDeep(value)
        this.value.organization = this.companies.find((c) => c._id == value.organization)
      } else {
        this.value = {
          active: true,
          key: null,
          secret: null,
          organization: this.companies[0],
          orgType: this.companies[0].orgType,
        }
      }
      return this.value
    }

    isEdit() {
      return this.originalValue ? true : false
    }

    isChanged() {
      if (!this.originalValue) return true
      return !_.isEqual(
        {
          ...this.value,
          organization: this.value.organization._id,
        },
        this.originalValue
      )
    }

    isValid() {
      return this.value.organization ? true : false
    }

    submit = (event) => {
      event.preventDefault()
      if (!this.isValid()) return
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      }
      let submitPromise
      if (!this.value._id) {
        submitPromise = $http.post(
          "/api/1.0/s3fileapi",
          {
            orgType: this.value.orgType,
            organization: this.value.organization._id,
            active: this.value.active,
            memo: this.value.memo,
          },
          options
        )
      } else {
        submitPromise = $http.patch(
          `/api/1.0/s3fileapi/${this.value._id}`,
          {
            active: this.value.active,
            memo: this.value.memo,
          },
          options
        )
      }
      return submitPromise
        .then((res) => this.setValue(res.data))
        .catch((err) => {
          this.alertMessage(err && err.data ? err.data : err)
          throw err
        })
    }

    alertMessage = (msg) => {
      this.message = msg
      setTimeout(() => (this.message = null), 3000)
    }
  }

  return Form
}
