"use strict"

import angular from "angular"
import moment from "moment"

angular
  .module("uCountitUiApp")
  .controller(
    "periodConfigCtrl",
    ($scope, $uibModalInstance, $timeout, periods, ngDialog, $window) => {
      const dateFormat = "YYYY-MM-DD"

      const load = () => {
        $scope.periods = periods.map((period) => ({ ...period }))
        const activePeriods = $scope.periods.filter((period) => !period.deleted)
        activePeriods[0].from = "2000-01-01"
        activePeriods[activePeriods.length - 1].to = "8999-12-31"
      }

      load()

      angular.element(document).ready(function () {
        setDateTimePicker()
      })

      const setDateTimePicker = () => {
        const options = {
          autoUpdateInput: true,
          locale: { format: dateFormat },
          singleDatePicker: true,
          minDate: new Date(2000, 0, 1),
          maxDate: new Date(8999, 11, 31),
        }

        for (let i = 0; i < $scope.periods.length; i++) {
          const period = $scope.periods[i]
          $(`input[name=date-from-${i}]`).daterangepicker({ ...options, startDate: period.from })
          $(`input[name=date-to-${i}]`).daterangepicker({ ...options, startDate: period.to })
        }
      }

      $scope.changeDate = (type, idx) => {
        if (!isValidDate($scope.periods[idx][type])) {
          return
        }

        let date = ""
        let datePicker = null

        const activePeriods = $scope.periods
          .map((period, idx) => ({ ...period, idx }))
          .filter((period) => !period.deleted)

        const activeIdx = activePeriods.findIndex((period) => period.idx === idx)

        if (type === "from") {
          if (activeIdx !== 0) {
            date = moment.utc($scope.periods[idx].from).add(-1, "day").format(dateFormat)
            activePeriods[activeIdx - 1].to = date
            datePicker = $(`input[name='date-to-${activePeriods[activeIdx - 1].idx}']`)
          }
        } else {
          if (activeIdx !== activeIdx.length - 1) {
            date = moment.utc($scope.periods[idx].to).add(1, "day").format(dateFormat)
            activePeriods[activeIdx + 1].from = date
            datePicker = $(`input[name='date-from-${activePeriods[activeIdx + 1].idx}']`)
          }
        }

        $timeout(() => {
          if (datePicker) {
            datePicker.data("daterangepicker").setStartDate(date)
            datePicker.data("daterangepicker").setEndDate(date)
          }
        })

        validatePeriods()
      }

      const validatePeriods = () => {
        $scope.periods.forEach((period, idx) => {
          const isValid = moment(period.from) <= moment(period.to)
          $scope.periodConfigForm[`date-from-${idx}`].$setValidity("datePicker", isValid)
        })
      }

      $scope.isDeletable = (idx) => {
        if ($scope.periods[idx].deleted) {
          return false
        }

        return $scope.periods.filter((period) => !period.deleted).length > 1
      }

      $scope.isFromEditable = (idx) => {
        if ($scope.periods[idx].deleted) return false

        for (let i = 0; i <= idx; i++) {
          if ($scope.periods[i].deleted) continue

          if (i === idx) return false
          return true
        }

        return true
      }

      $scope.isToEditable = (idx) => {
        if ($scope.periods[idx].deleted) return false

        for (let i = $scope.periods.length - 1; i >= idx; i--) {
          if ($scope.periods[i].deleted) continue

          if (i === idx) return false
          return true
        }

        return true
      }

      $scope.deletePeriod = (idx) => {
        $timeout(async () => {
          if (await showConfirmMsg(idx)) {
            const removed = $scope.periods[idx]
            if (removed.isNew) {
              $scope.periods.splice(idx, 1)
            } else {
              removed.deleted = true
            }

            const activePeriods = $scope.periods.filter((period) => !period.deleted)

            activePeriods[0].from = "2000-01-01"
            activePeriods[activePeriods.length - 1].to = "8999-12-31"

            $scope.$apply()

            $timeout(() => {
              setDateTimePicker()
            })
          }
        })
      }

      const showConfirmMsg = async (key) => {
        try {
          await ngDialog.openConfirm({
            className: "ngdialog-theme-default popup-confirm",
            controller: "ucPopupConfirmCtrl",
            data: {
              title: `Period #${key + 1} will be deleted. Are you sure?`,
            },
            showClose: false,
            template: "components/popup/popup-confirm.html",
          })
          return true
        } catch (err) {
          return false
        }
      }

      const isValidDate = (date) => {
        return moment(date, dateFormat, true).isValid()
      }

      const validate = () => {
        const activePeriods = $scope.periods
          .map((period, idx) => ({ idx, ...period }))
          .filter((period) => !period.deleted)
        for (let i = 0; i < activePeriods.length; i++) {
          const period = activePeriods[i]

          if (!isValidDate(period.from) || !isValidDate(period.to)) {
            $window.alert("Date is invalid.")
            return false
          }
          if (moment(period.from) > moment(period.to)) {
            $window.alert(`'To' date must be after 'From' date in #${period.idx + 1}.`)
            return false
          }

          if (i > 0) {
            const before = moment
              .utc(activePeriods[i - 1].to)
              .add(1, "day")
              .format("YYYY-MM-DD")
            const after = moment.utc(period.from).format("YYYY-MM-DD")

            if (before !== after) {
              $window.alert(
                `#${activePeriods[i - 1].idx + 1} and #${period.idx + 1} dates are not continuous.`
              )
              return false
            }
          }
        }

        return true
      }

      $scope.addPeriod = () => {
        const activePeriods = $scope.periods.filter((period) => !period.deleted)
        const lastPeriod = activePeriods[activePeriods.length - 1]
        lastPeriod.to = moment().utc().format(dateFormat)

        const newPeriod = {
          from: moment.utc(lastPeriod.to).add(1, "day").format(dateFormat),
          to: "8999-12-31",
          _id: Date.now(),
          isNew: true,
          deleted: false,
        }
        $scope.periods.push(newPeriod)

        $timeout(() => {
          setDateTimePicker()
        })
      }

      $scope.cancel = () => {
        $uibModalInstance.dismiss("cancel")
      }

      $scope.submit = () => {
        if (validate())
          $uibModalInstance.close({
            result: $scope.periods,
          })
      }
    }
  )
