/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict"

import angular from "angular"

angular
  .module("uCountitUiApp")

  .factory(
    "AccountSrv",
    (
      $rootScope,
      $resource //  $resource $rootScope.globalConfig.localapipath + '/account/:id/:controller',
    ) =>
      $resource(
        "/api/1.0/account/:id/:controller",
        { id: "@id" },
        {
          changePassword: {
            method: "PUT",
            params: {
              controller: "password",
            },
          },

          get: {
            method: "GET",
            params: {
              id: "me",
            },
          },

          update: {
            method: "PUT",
          },
        }
      )
  )
