"use strict"

import Promise from "bluebird"
import { clone } from "../class-utils"

function Company(companyData, Api) {
  this.data = companyData
  this.orgType = Company.ORG_TYPE
  this.Api = Api
  this.data = companyData
  for (const k in companyData) {
    this[k] = companyData[k]
  }
  this._companyId = this.data._id
  this.functionalityPromise = null

  return this
}

Company.prototype.clone = function () {
  return clone(this)
}

Company.prototype.getOwnFunctionality = function (functionality = {}) {
  if (this.data.sales) functionality.sales = this.data.sales.active == true
  // report는 premiumReport 활성화 여부이다. Standard는 포함되지 않는다.
  if (this.data.reports) functionality.report = this.data.reports.some((report) => report.active)

  return functionality
}

Company.prototype.getFunctionality = async function (defaultFunctionality = {}) {
  if (this.functionalityPromise == null) {
    this.functionalityPromise = await Promise.all([
      this.Api.Sensor.index(true, { params: { companyId: this.data._id } }),
      this.Api.Store.index(true, { params: { companyId: this.data._id } }),
    ]).spread((sensors, stores) => {
      let functionality = sensors.reduce((functionality, sensor) => {
        return sensor.getOwnFunctionality(functionality)
      }, {})
      functionality = stores.reduce((functionality, store) => {
        return store.getOwnFunctionality(functionality)
      }, functionality)
      return functionality
    })
  }

  return this.getOwnFunctionality(await this.functionalityPromise)
}

Company.prototype.isAllowedBlockSnapshot = function (account) {
  let blockSnapshot = false

  if (account.gteAgency()) {
    if (this.data.policy) blockSnapshot = this.data.policy.disallowAdminSnapshot
  }

  return blockSnapshot
}

Company.prototype.isHideOverviewMenu = function (account) {
  return (account.isStore() || account.isCompany()) && this.data.policy.hideOverviewMenu
}

Company.prototype.isHideStandardReportMenu = function (account) {
  return (account.isStore() || account.isCompany()) && this.data.policy.hideStandardReportMenu
}

Company.prototype.isHideAdminMenu = function (account) {
  return account.isStore() || (account.isCompany() && this.data.policy.hideAdminMenu)
}

Company.prototype.isHideSensorsMenu = function (account) {
  if (account.isStore()) return this.data.policy.hideCamerasMenuStore
  return account.isCompany() && this.data.policy.hideCamerasMenu
}

Company.prototype.isBlockedSnapshot = function (account) {
  this.isAllowedBlockSnapshot(account)
}

Company.prototype.checkBlockedSnapshot = function (account) {
  this.isAllowedBlockSnapshot(account)
}

Company.ORG_TYPE = "company"

export default Company
